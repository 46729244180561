import {KTIcon} from '../../../../_metronic/helpers'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import {useState, useEffect} from 'react'
import usePostApi from '../../custom_hooks/usePostApi'
import {FindPodIdByCode} from '../../FindPodIdByPodCode'
import TextEditor from '../QuillEditor'
import {UseToast} from '../../useToast'

function PolicyInfo() {
  const podId = FindPodIdByCode()
  const {execute, loading} = usePostApi()
  const {id, policy_id} = useParams()

  const [policyData, setPolicyData] = useState<any>(null)

  const getPolicyData = async () => {
    try {
      const url = '/pod/policy/get'
      const formData = {
        policy_id,
      }

      const response = await execute(url, 'POST', formData)
      setPolicyData(response.data[0])
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getPolicyData()
  }, [policy_id])

  const policyBC = [
    {
      title: `(${id})`,
      path: `/${id}/policies`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: ``,
      path: `/${id}/policies`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `Policies`,
      path: `/${id}/policies`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: ``,
      path: `/${id}/policies`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `${policyData?.policy_name || ''} ${
        policyData?.policy_code ? `(${policyData.policy_code})` : ''
      }`,
      path: `/${id}/policies/wordings/${policy_id}`,
      isSeparator: false,
      isActive: false,
    },
  ]

  const [policyEdit, setPolicyEdit] = useState(false)

  const [wordingData, setWordingData] = useState('')
  useEffect(() => {
    setWordingData(policyData?.policy_wordings)
  }, [policyData])

  const handleUpdatePolicyWordings = () => {
    const updateWordings = async () => {
      try {
        const url = '/pod/policy/wordings/update'
        const formData = {
          pod_id: podId,
          policy_id: policy_id,
          policy_wordings: wordingData,
        }

        const response = await execute(url, 'POST', formData)
        if (response?.status === true) {
          getPolicyData()
          setPolicyEdit(false)
        }
        UseToast(
          response?.status_message,
          {
            position: 'bottom-right',
            autoClose: false,
            theme: 'colored',
          },
          'success'
        )
      } catch (error: any) {
        console.error('Error:', error)

        UseToast(
          error.response?.data?.status_message,
          {
            position: 'bottom-right',
            autoClose: false,
            theme: 'colored',
          },
          'error'
        )
      }
    }
    updateWordings()
  }

  return (
    <>
      <PageTitle breadcrumbs={policyBC}>Wordings</PageTitle>
      <div className='d-flex flex-column justify-content-between'>
        {loading ? (
          <div className='card w-100 h-250px mb-5'>
            <div className='m-auto d-flex flex-column align-items-center'>
              <div className='spinner-border spinner-primary mr-15'></div>
            </div>
          </div>
        ) : policyData ? (
          <>
            <div className='card w-100'>
              <div className='card-header'>
                <div className='card-title m-0'>
                  <h3 className='fw-semibold m-0 default-cursor'>
                    Policy Wordings Latest{' '}
                    <span className='text-muted fw-normal fs-6'>{`(Version: ${policyData?.policy_version})`}</span>
                  </h3>
                </div>

                {policyEdit ? (
                  <div className='text-end'>
                    <button
                      type='submit'
                      className='btn btn-light-primary btn-sm mt-5 fw-normal'
                      onClick={handleUpdatePolicyWordings}
                    >
                      <KTIcon iconName='check' className='fs-7' />
                      Save
                    </button>
                    <button
                      className='btn btn-icon btn-sm btn-active-light-danger mt-5 ms-3'
                      onClick={() => {
                        setPolicyEdit(false)
                      }}
                    >
                      <KTIcon iconName='cross' className='fs-2' />
                    </button>
                  </div>
                ) : (
                  <button
                    className='btn btn-light-primary btn-sm align-self-center btn-ripple fw-normal'
                    onClick={() => {
                      setPolicyEdit(true)
                    }}
                  >
                    <KTIcon iconName='pencil' className='fs-7' />
                    Edit
                  </button>
                )}
              </div>

              <div className='p-10 py-5'>
                {policyEdit ? (
                  <TextEditor wordings={wordingData} onChangeWordings={setWordingData} />
                ) : (
                  <>
                    {wordingData ? (
                      <div dangerouslySetInnerHTML={{__html: wordingData}} />
                    ) : (
                      <h5 className='fw-normal'>No wordings found.</h5>
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <div className='card h-250px d-flex flex-column justify-content-center align-items-center'>
            <h3 className='fs-3 fw-normal'>Wordings not found</h3>
            <button className='btn btn-light-primary btn-sm' onClick={() => getPolicyData()}>
              Retry
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default PolicyInfo
