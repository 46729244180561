/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import './index.css'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100 bg-image'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-1 bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed overflow-auto min-h-100'>
        {/* begin::Form */}
        {/* <div className='d-flex flex-center flex-column flex-lg-row-fluid'> */}
        <div className='d-flex justify-content-center align-items-center h-100'>
          {/* begin::Wrapper */}
          {/* <div className='p-10 p-lg-15 mx-auto mt-7'> */}
          {/* <div className='h-100 d-flex flex-column justify-content-center'> */}
          <Outlet />
          {/* </div> */}
          {/* </div> */}
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}

        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div className='d-flex justify-content-center flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2 min-h-100'>
        {/* begin::Content */}
        {/* <div className='row min-h-100 bg-linear'> */}
        <div className='min-h-100 w-100 col-lg-6 d-flex order-1 order-lg-2'>
          <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/custom-assets/logo-blue.png')}
              // className='h-md-55px mb-5'
              className='img-fluid mx-auto d-block max-width-md-100 max-width-lg-75 h-md-55px mb-5'
            />

            <h1 className='fw-bolder text-center mb-7' style={{fontSize: '18px', color: '#506bec'}}>
              Fast, Efficient and Streamlined Digital Employee Onboarding
            </h1>
          </div>
        </div>
        {/* </div> */}
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

export {AuthLayout}
