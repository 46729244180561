import SearchIcon from '../team_members_KM/search.png'
import ExploreIcon from './adventurer.png'
import ActionIcon from './action.png'

function InvitesContent() {
  return (
    <div className='row'>
      <div className='col-lg-4 p-0'>
        <div className='w-100 h-100 p-5'>
          <div className='shadow-sm p-3 rounded h-100 d-flex flex-column justify-content-center align-items-center'>
            <img src={ExploreIcon} className='track-req-icon mb-5' alt='Add Friend' />
            <p className='text-center'>
              Explore the "Pending," "Approved," and "Rejected" tabs to easily filter and view
              invite requests based on their status. This allows you to quickly assess the current
              state of all requests and take necessary actions.
            </p>
          </div>
        </div>
      </div>
      <div className='col-lg-4 p-0'>
        <div className='w-100 h-100 p-5'>
          <div className='shadow-sm p-3 rounded h-100 d-flex flex-column justify-content-center align-items-center'>
            <img src={ActionIcon} alt='Track Request' className='track-req-icon mb-5' />
            <p className='text-center'>
              For pending requests, simply click on the "Accept" or "Reject" buttons next to each
              request. This empowers you to efficiently manage your team's membership by responding
              to join invitations promptly.
            </p>
          </div>
        </div>
      </div>
      <div className='col-lg-4 p-0'>
        <div className='w-100 h-100 p-5'>
          <div className='shadow-sm p-3 rounded h-100 d-flex flex-column justify-content-center align-items-center'>
            <img src={SearchIcon} className='track-req-icon mb-5' alt='Add Friend' />
            <p className='text-center'>
              Use the search feature to quickly find specific invitations. Search by name, email,
              POD name or role to locate the invitation you need, simplifying your invitation
              management process for your POD organization.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvitesContent
