import {MenuItem} from './MenuItem'
import {useLocation} from 'react-router-dom'
import {
  FindBrandnameByCode,
  FindOrgNameByCode,
  FindOrgPrefColorByCode,
  FindOrgPodLogoByCode,
} from '../../../../../app/modules/FindPodIdByPodCode'
import {useAuth} from '../../../../../app/modules/auth'

export function MenuInner() {
  const {pathname} = useLocation()
  const brandName = FindBrandnameByCode()
  const orgName = FindOrgNameByCode()
  const prefColor = FindOrgPrefColorByCode()
  const podLogo = FindOrgPodLogoByCode()

  const name = encodeURIComponent(brandName)
  const {currentUser} = useAuth()

  function getWishesMessage() {
    const now = new Date()
    const hours = now.getHours()

    if (hours < 12) {
      return 'Good Morning'
    } else if (hours < 18) {
      return 'Good Afternoon'
    } else {
      return 'Good Evening'
    }
  }

  const wishMsg = getWishesMessage()

  return pathname !== '/my-pods' && pathname !== '/my-invites' && pathname !== '/my-profile' ? (
    // <MenuItem
    //   title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
    //   to={to ? to : id ? `/${id}/dashboard` : `/${firstPodCode}/dashboard`}
    // />
    <div className='h-100 d-flex py-5'>
      <img
        src={
          podLogo
            ? podLogo
            : `${process.env.REACT_APP_API_BASE_URL}/get/profile?name=${name}&color=${
                prefColor ? prefColor.substring(1) : ''
              }&size=128}`
        }
        className='me-2'
        style={{maxWidth: '50px', borderRadius: '3px'}}
        alt='logo'
      />
      <div>
        <h6 className='fw-semibold mb-0'>{orgName}</h6>
        <p className='fw-normal text-muted fs-7'>{brandName}</p>
      </div>
    </div>
  ) : pathname === '/my-pods' ? (
    <h3 className='fs-3 fw-semibold m-auto'>
      {wishMsg}, <span className='text-primary'>{currentUser?.first_name || ''}</span>
    </h3>
  ) : pathname === '/my-invites' ? (
    <h3 className='fs-3 fw-semibold m-auto'>
      {wishMsg}, <span className='text-primary'>{currentUser?.first_name || ''}</span>
    </h3>
  ) : (
    <h3 className='fs-3 fw-semibold m-auto'>
      {wishMsg}, <span className='text-primary'>{currentUser?.first_name || ''}</span>
    </h3>
  )
}
