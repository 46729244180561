import {Modal} from 'react-bootstrap'
import '../../../../modules/auth/components/index.css'
import {KTIcon} from '../../../../../_metronic/helpers'
import VacanciesKnowMoreCards from './VacanciiesKnowMoreCards'

interface Props {
  showModal: boolean
  setShowModal(val: boolean): void
}

function VacanciesKnowMoreModal(props: Props) {
  return (
    <Modal
      show={props.showModal}
      onHide={() => props.setShowModal(false)}
      centered
      dialogClassName='custom-modal modal-dialog-centered'
      contentClassName='custom-modal-content'
    >
      <Modal.Body className='overflow-auto w-100'>
        <div className='d-flex justify-content-between align-items-center'>
          <h3 className='mb-4'>Manage Vacancies</h3>
          <button
            type='button'
            className='d-block btn btn-sm btn-icon btn-light'
            onClick={() => props.setShowModal(false)}
          >
            <KTIcon iconName='cross' className='fs-1' />
          </button>
        </div>

        <p className=''>
          Welcome to the Vacancies page! This section is designed to help you manage vacancies
          inside the organization. Here, you can create new vacancies, track their status, and
          ensure efficient recruitment.
        </p>
        <VacanciesKnowMoreCards />
      </Modal.Body>
    </Modal>
  )
}

export default VacanciesKnowMoreModal
