import {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import usePostApi from '../../custom_hooks/usePostApi'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'
import clsx from 'clsx'

const PolicyWordings = () => {
  const {execute, loading} = usePostApi()
  const {id, policy_id} = useParams()
  const [policyDetails, setPolicyDetails] = useState({policy_code: '', policy_name: ''})

  const [policyData, setPolicyData] = useState<any>(null)

  const getPolicyData = async () => {
    try {
      const url = '/pod/policy/wordings/list'
      const formData = {
        policy_id,
      }

      const response = await execute(url, 'POST', formData)
      setPolicyData(response.data?.data)
      setPolicyDetails({
        policy_code: response.data?.policy_code,
        policy_name: response.data?.policy_name,
      })
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getPolicyData()
  }, [policy_id])

  const [versionIndex, setVersionIndex] = useState<number>(0)

  const policyBC = [
    {
      title: `(${id})`,
      path: `/${id}/policies`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: ``,
      path: `/${id}/policies`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `Policies`,
      path: `/${id}/policies`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: ``,
      path: `/${id}/policies`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `${policyDetails?.policy_name} ${
        policyDetails.policy_name && `(${policyDetails?.policy_code})`
      }`,
      path: `/${id}/policies/archive/${policy_id}`,
      isSeparator: false,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={policyBC}>Policy Archive</PageTitle>
      <div className='d-none d-flex flex-wrap flex-stack mb-6'>
        <div>
          <h3 className='fw-semibold my-2'>Policy Wordings</h3>
        </div>
        <div className='d-flex'>
          <Link className='btn btn-sm btn-secondary ms-4 fw-normal' to={`/${id}/policies`}>
            <KTIcon iconName='arrow-left' className='fs-3' />
            Back
          </Link>
        </div>
      </div>

      {loading ? (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : (
        <div className='card p-5'>
          <div className='mb-5 hover-scroll-x'>
            <div className='d-grid'>
              <ul className='nav nav-tabs flex-nowrap text-nowrap'>
                {Array.isArray(policyData) &&
                  policyData
                    .slice()
                    .reverse()
                    .map((each: any, index: number) => (
                      <li key={index} className='nav-item'>
                        <a
                          className={clsx(
                            'nav-link btn btn-active-light btn-color-gray-600 btn-active-color-primary rounded-bottom-0 me-1',
                            {
                              active: index === versionIndex,
                            }
                          )}
                          href='#'
                          onClick={() => setVersionIndex(index)}
                        >
                          Version: {each.policy_version}
                        </a>
                      </li>
                    ))}
              </ul>
            </div>
          </div>

          <div className='px-5'>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  (policyData &&
                    policyData[policyData.length - 1 - versionIndex]?.policy_wordings) ||
                  '<h5 class="fw-normal">No wordings found.</h5>',
              }}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default PolicyWordings
