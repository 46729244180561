import React from 'react'
import AddFriendIcon from './user.png'
import TrackReqImg from './time-tracking.png'
import SearchIcon from './search.png'

function TeamMembersKM() {
  return (
    <div className='row'>
      <div className='col-lg-4 p-0'>
        <div className='w-100 h-100 p-5'>
          <div className='shadow-sm p-3 rounded h-100 d-flex flex-column justify-content-center align-items-center'>
            <img src={AddFriendIcon} className='track-req-icon mb-5' alt='Add Friend' />
            <p className='text-center'>
              Click on the "+ New Member" button to send invitations to team members. You can invite
              individuals from within the organization or even external partners and collaborators.
            </p>
          </div>
        </div>
      </div>
      <div className='col-lg-4 p-0'>
        <div className='w-100 h-100 p-5'>
          <div className='shadow-sm p-3 rounded h-100 d-flex flex-column justify-content-center align-items-center'>
            <img src={TrackReqImg} alt='Track Request' className='track-req-icon mb-5' />
            <p className='text-center'>
              Keep track of the status of your invites. See who has accepted, who is pending, and
              resend invites if needed.
            </p>
          </div>
        </div>
      </div>
      <div className='col-lg-4 p-0'>
        <div className='w-100 h-100 p-5'>
          <div className='shadow-sm p-3 rounded h-100 d-flex flex-column justify-content-center align-items-center'>
            <img src={SearchIcon} className='track-req-icon mb-5' alt='Add Friend' />
            <p className='text-center'>
              Use the search option to narrow down your search. You can search by name, department,
              or status to find the team members you need quickly.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeamMembersKM
