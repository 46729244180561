import {useEffect, useRef, useState} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'

import clsx from 'clsx'
import Select, {components} from 'react-select'
import {KTIcon} from '../../../../../_metronic/helpers'
import usePostApi from '../../../custom_hooks/usePostApi'
import {useThemeMode} from '../../../../../_metronic/partials'
import ThemeColors from '../../../create-new-pod-wizard/ThemePref'
import {UseToast} from '../../../useToast'
import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'
import {FindPodIdByCode} from '../../../FindPodIdByPodCode'
import {useParams} from 'react-router-dom'
import Tagify from '@yaireo/tagify'
import '@yaireo/tagify/dist/tagify.css'

const registrationSchema = Yup.object().shape({
  job_role_id: Yup.string().required('Job Role is required'),
  job_title_id: Yup.string().required('Job title is required'),
  job_description: Yup.string().required('Description is required'),
  department_id: Yup.string().required('Department is required'),
  hiring_manager_id: Yup.string().required('Hiring Manager is required'),
  number_of_vacancies: Yup.string().required('No. of Vacancies is required'),
})

type Data = {
  job_role_id: number | string
  job_title_id: number | string
  department_id: number | string
  qualifications: any[]
  experience_in_yrs: number | string
  number_of_vacancies: number
  salary_start: number | string
  salary_end: number | string
  currency_id: number | string
  location_id: number | string
  skills: string
  job_description: string
  pod_id: number
  employment_type_id: string
  emp_type_id: string | number
  user_id: string | number
  hiring_manager_id: string
  vacancy_status: string
  service_agreement_in_yrs: number | string
}

function EditVacancy2({closeModal = () => {}, vacanciesgetData, getvacanciesData = () => {}}: any) {
  const {
    job_role_id,
    job_title_id,
    department_id,
    qualifications,
    experience_in_yrs,
    number_of_vacancies,
    salary_start,
    salary_end,
    salary_currency_id,
    location_id,
    skills,
    job_description,
    pod_id,
    employment_type_id,
    emp_type_id,
    user_id,
    hiring_manager_id,
    vacancy_status,
    service_agreement_in_yrs,
  } = vacanciesgetData
  const intl = useIntl()
  const {id, vacancy_id} = useParams()
  const {execute} = usePostApi()
  const podId: any | null = FindPodIdByCode()
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [data, setData] = useState<Data>({
    job_role_id: '' || job_role_id,
    job_title_id: '' || job_title_id,
    department_id: '' || department_id,
    qualifications: '' || qualifications,
    experience_in_yrs: '' || experience_in_yrs,
    number_of_vacancies: '' || number_of_vacancies,
    salary_start: '' || salary_start,
    salary_end: '' || salary_end,
    currency_id: '' || salary_currency_id,
    location_id: '' || location_id,
    skills: '' || skills,
    job_description: '' || job_description,
    pod_id: podId,
    employment_type_id: '' || employment_type_id,
    emp_type_id: '' || employment_type_id,
    user_id: '' || hiring_manager_id,
    hiring_manager_id: '' || hiring_manager_id,
    vacancy_status: '' || vacancy_status,
    service_agreement_in_yrs: '' || service_agreement_in_yrs,
  })
  const set2ndIndex = () => {
    setCurrentStep(1)
  }
  const set3rdIndex = () => {
    setCurrentStep(2)
  }

  const handleSaveClick = async () => {
    try {
      const url = `/pod/vacancy/update`
      const result = await Swal.fire({
        title: 'Updating the Vacancy Information',
        text: 'Press the Confirm button to update the Vacancy Information.',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: intl.formatMessage({id: 'BTN.CONFIRM'}),
        cancelButtonText: intl.formatMessage({id: 'LABEL.CANCEL'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
          cancelButton: 'btn btn-sm btn-secondary',
        },
      })
      if (result.isConfirmed) {
        const formdata = {
          job_role_id: data.job_role_id,
          job_title_id: data.job_title_id,
          pod_id: podId,
          location_id: data.location_id,
          department_id: data.department_id,
          hiring_manager_id: data.hiring_manager_id,
          number_of_vacancies: data.number_of_vacancies,
          job_description: data.job_description,
          vacancy_id: vacancy_id,
          salary_start: data.salary_start,
          salary_end: data.salary_end,
          currency_id: data.currency_id,
          qualifications: data.qualifications,
          skills: data.skills,
          experience_in_yrs: data.experience_in_yrs,
          vacancy_status: data.vacancy_status,
          employment_type_id: data.employment_type_id,
          service_agreement_in_yrs: data.service_agreement_in_yrs,
        }
        const response = await execute(url, 'POST', formdata)
        closeModal()
        getvacanciesData()
        // refreshData()
        UseToast(
          response.status_message,
          {
            position: 'bottom-right',
            theme: 'colored',
            autoClose: false,
          },
          'success'
        )
      }
    } catch (error: any) {
      console.error(error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }

  const steps = [
    <Step1 set2ndIndex={set2ndIndex} data={data} setData={setData} key='step_1' />,
    <Step2 set3rdIndex={set3rdIndex} data={data} setData={setData} key='step_2' />,
    <Step3 data={data} setData={setData} handleSaveClick={handleSaveClick} key='step_3' />,
  ]
  const nextStep = async () => {
    if (currentStep === steps.length - 1) {
      const element = document.getElementById('step_3_submit')
      if (element) {
        element.click()
      }
    } else if (currentStep === 0) {
      const element = document.getElementById('verify-sign-up-btn')
      if (element) {
        element.click()
      }
    } else if (currentStep === 1) {
      const element = document.getElementById('step_2_submit')
      if (element) {
        element.click()
      }
    } else {
      const totalSteps = steps.length - 1
      if (currentStep < totalSteps) {
        setCurrentStep(currentStep + 1)
      }
    }
  }

  const prevStep = (): void => {
    if (currentStep !== 0) {
      setCurrentStep(currentStep - 1)
    }
  }

  let buttonText: string
  if (currentStep === 2) {
    buttonText = 'Confirm'
  } else {
    buttonText = 'Continue'
  }

  return (
    <>
      <div className='card py-0 min-w-650px'>
        <div className='w-100 m-auto'>
          <div className='d-flex justify-content-between mb-10'>
            <h4 className='fw-semibold'>Create New Vacancy</h4>
            <button
              type='button'
              className='d-block btn btn-sm btn-icon btn-light'
              onClick={closeModal}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </button>
          </div>
          {/* <div className='w-100 d-flex flex-column flex-lg-row justify-content-between'> */}

          <>
            <div className='w-100 row m-0'>
              <div className='col-lg-5 p-0'>
                <div className=''>
                  <div className='d-flex'>
                    <div
                      style={{borderRadius: '10px'}}
                      className={clsx(
                        'w-40px h-40px btn d-flex justify-content-center align-items-center fw-semibold',
                        {
                          'btn-primary': currentStep === 0,
                          'btn-light-primary': currentStep !== 0,
                        }
                      )}
                    >
                      {currentStep === 0 ? 1 : <i className='stepper-check fas fa-check'></i>}
                    </div>
                    <div className='ms-5'>
                      <h6
                        className={clsx('mb-0 text-muted', {
                          'text-muted fw-semibold': currentStep >= 0,
                        })}
                      >
                        Step 1
                      </h6>
                      <p
                        className={clsx('fs-6 text-muted mb-0', {
                          'text-muted fw-semibold': currentStep >= 0,
                        })}
                      >
                        Vacancy Info
                      </p>
                    </div>
                  </div>
                  <div className='ms-6 h-30px border-left border-dashed border-muted border-top-0 border-bottom-0 border-right-0 border-left-1'></div>
                  <div className='d-flex'>
                    <div
                      style={{borderRadius: '10px'}}
                      className={clsx(
                        'w-40px h-40px btn d-flex justify-content-center align-items-center fw-semibold',
                        {
                          'btn-primary': currentStep === 1,
                          'btn-light-primary': currentStep !== 1,
                        }
                      )}
                    >
                      {currentStep > 1 ? <i className='stepper-check fas fa-check'></i> : 2}
                    </div>
                    <div className='ms-5'>
                      <h6
                        className={clsx('mb-0 text-muted', {
                          'text-muted fw-semibold': currentStep >= 1,
                        })}
                      >
                        Step 2
                      </h6>
                      <p
                        className={clsx('fs-6 text-muted mb-0', {
                          'text-muted': currentStep >= 1,
                        })}
                      >
                        Education
                      </p>
                    </div>
                  </div>
                  {/* Add more steps here */}
                  <div className='ms-6 h-30px border-left border-dashed border-muted border-top-0 border-bottom-0 border-right-0 border-left-1'></div>

                  <div className='d-flex'>
                    <div
                      style={{borderRadius: '10px'}}
                      className={clsx(
                        'w-40px h-40px btn d-flex justify-content-center align-items-center fw-semibold',
                        {
                          'btn-primary': currentStep === 2,
                          'btn-light-primary': currentStep !== 2,
                        }
                      )}
                    >
                      {currentStep > 2 ? <i className='stepper-check fas fa-check'></i> : 3}
                    </div>
                    <div className='ms-5'>
                      <h6
                        className={clsx('mb-0 text-muted', {
                          'text-muted fw-semibold': currentStep >= 2,
                        })}
                      >
                        Step 3
                      </h6>
                      <p
                        className={clsx('fs-6 text-muted', {
                          'text-muted fw-semibold': currentStep > 1,
                        })}
                      >
                        Location & Salary
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-7 p-0 m-0'>{steps[currentStep]}</div>
            </div>
          </>

          <div className='w-100 d-flex flex-stack mt-5'>
            <div className='mr-2'>
              {currentStep > 0 && (
                <button onClick={prevStep} type='button' className='btn btn-sm btn-light me-3'>
                  <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                  Back
                </button>
              )}
            </div>

            <div>
              <button type='submit' className='btn btn-sm btn-light-primary' onClick={nextStep}>
                {currentStep !== steps.length - 1 ? 'Continue' : 'Create Vacancy'}
                <KTIcon iconName='arrow-right' className='fs-3 ms-1' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

interface Step1Props {
  set2ndIndex(): void
  data: Data
  setData: (values: Data) => void
}

const Step1 = (props: Step1Props) => {
  const {execute} = usePostApi()
  const podId: any | null = FindPodIdByCode()
  const [jobTitles, setjobtitleData] = useState([])
  const [departments, setdepartmentData] = useState([])
  const [jobRoles, setjobroleData] = useState([])
  const [hiringManager, sethiringManager] = useState([])
  const {mode} = useThemeMode()
  const getDepartments = async () => {
    try {
      const url = `/pod/department/active`
      const formData = {
        pod_id: podId,
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.dep_id,
        label: each.dep_name,
      }))
      setdepartmentData(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getDepartments()
  }, [])

  const getjobRoles = async () => {
    try {
      const url = `/pod/job_roles/active`
      const formData = {
        pod_id: podId,
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.job_role_id,
        label: each.job_role_name,
      }))

      setjobroleData(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getjobRoles()
  }, [])

  const getjobtitles = async () => {
    try {
      const url = `/pod/job_titles/active`
      const formData = {
        pod_id: podId,
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.job_title_id,
        label: each.job_title_name,
      }))
      setjobtitleData(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getjobtitles()
  }, [])

  const getManager = async () => {
    try {
      const url = `/pod/interviewers`
      const formData = {
        pod_id: podId,
        type: 'ANY',
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.user_id,
        label: `${each.first_name} ${each.last_name}`,
      }))
      sethiringManager(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getManager()
  }, [])

  const handlejobTitleChange = (setFieldValue: any, selectedOption: any) => {
    setFieldValue('job_title_id', selectedOption ? selectedOption.value : '')
  }

  const handlejobRoleChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('job_role_id', selectedOption.value)
    } else {
      setFieldValue('job_role_id', '')
    }
  }

  const handledepartmentChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('department_id', selectedOption.value)
    } else {
      setFieldValue('department_id', '') // Handle case where no option is selected
    }
  }

  const handledManagerChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('hiring_manager_id', selectedOption.value)
    } else {
      setFieldValue('hiring_manager_id', '') // Handle case where no option is selected
    }
  }

  const onSubmit = async (Values: Data) => {
    props.setData({...Values})
    props.set2ndIndex()
  }

  return (
    <Formik validationSchema={registrationSchema} initialValues={props.data} onSubmit={onSubmit}>
      {({values, setFieldValue}: any) => {
        return (
          <Form className='w-100'>
            <div className='d-flex'>
              <div className='w-100 mb-5 me-2'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Job Role</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Select Job Role'
                  ></i>
                </label>

                <Select
                  value={jobRoles.find((option: any) => option.value === values?.job_role_id)}
                  styles={{
                    control: (baseStyles: any, state: any) => ({
                      ...baseStyles,
                      backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                      color: '#ffffff',
                      borderWidth: '0px',
                    }),
                    menu: (baseStyles: any) => ({
                      ...baseStyles,
                      backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                    }),
                    singleValue: (baseStyles: any, state: any) => ({
                      ...baseStyles,
                      color: mode === 'dark' ? '#92929f' : '#000000',
                    }),
                    input: (baseStyles: any) => ({
                      ...baseStyles,
                      color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                    }),
                    option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                      ...baseStyles,
                      backgroundColor: isSelected
                        ? '#C2C2C2'
                        : isFocused
                        ? '#e0e0e0'
                        : baseStyles.backgroundColor,
                      color: isSelected ? '#000000' : isFocused ? '#000000' : baseStyles.color,
                    }),
                  }}
                  theme={(theme: any) => ({
                    ...theme,
                    borderRadius: 5,
                    backgroundColor: '#000000',
                    colors: {
                      ...theme.colors,
                      primary25: 'rgba(80, 107, 236, 0.5)',
                      primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                    },
                  })}
                  className='react-select-container w-500'
                  classNamePrefix='react-select'
                  options={jobRoles}
                  isSearchable
                  placeholder='Select Job Role'
                  onChange={(val: any) => handlejobRoleChange(setFieldValue, val)}
                />

                <div className='text-danger mt-2'>
                  <ErrorMessage name='job_role_id' />
                </div>
              </div>

              <div className='w-100 mb-5'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required ms-1 '>Job title</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Select Job title'
                  ></i>
                </label>

                <Select
                  value={jobTitles.find((option: any) => option.value === values?.job_title_id)}
                  styles={{
                    control: (baseStyles: any, state: any) => ({
                      ...baseStyles,
                      backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                      color: '#ffffff',
                      borderWidth: '0px',
                    }),
                    menu: (baseStyles: any) => ({
                      ...baseStyles,
                      backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                    }),
                    singleValue: (baseStyles: any, state: any) => ({
                      ...baseStyles,
                      color: mode === 'dark' ? '#92929f' : '#000000',
                    }),
                    input: (baseStyles: any) => ({
                      ...baseStyles,
                      color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                    }),
                    option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                      ...baseStyles,
                      backgroundColor: isSelected
                        ? '#C2C2C2'
                        : isFocused
                        ? '#e0e0e0'
                        : baseStyles.backgroundColor,
                      color: isSelected ? '#000000' : isFocused ? '#000000' : baseStyles.color,
                    }),
                  }}
                  theme={(theme: any) => ({
                    ...theme,
                    borderRadius: 5,
                    backgroundColor: '#000000',
                    colors: {
                      ...theme.colors,
                      primary25: 'rgba(80, 107, 236, 0.5)',
                      primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                    },
                  })}
                  className='react-select-container'
                  classNamePrefix='react-select'
                  options={jobTitles}
                  isSearchable
                  placeholder='Select Job Title'
                  onChange={(val: any) => handlejobTitleChange(setFieldValue, val)}
                />

                <div className='text-danger mt-2'>
                  <ErrorMessage name='job_title_id' />
                </div>
              </div>
            </div>
            <div className='d-flex'>
              <div className='w-100 mb-5 me-2'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Department</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Select Department'
                  ></i>
                </label>
                <Select
                  value={departments.find((option: any) => option.value === values?.department_id)}
                  styles={{
                    control: (baseStyles: any, state: any) => ({
                      ...baseStyles,
                      backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                      color: '#ffffff',
                      borderWidth: '0px',
                    }),
                    menu: (baseStyles: any) => ({
                      ...baseStyles,
                      backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                    }),
                    singleValue: (baseStyles: any, state: any) => ({
                      ...baseStyles,
                      color: mode === 'dark' ? '#92929f' : '#000000',
                    }),
                    input: (baseStyles: any) => ({
                      ...baseStyles,
                      color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                    }),
                    option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                      ...baseStyles,
                      backgroundColor: isSelected
                        ? '#C2C2C2'
                        : isFocused
                        ? '#e0e0e0'
                        : baseStyles.backgroundColor,
                      color: isSelected ? '#000000' : isFocused ? '#000000' : baseStyles.color,
                    }),
                  }}
                  theme={(theme: any) => ({
                    ...theme,
                    borderRadius: 5,
                    backgroundColor: '#000000',
                    colors: {
                      ...theme.colors,
                      primary25: 'rgba(80, 107, 236, 0.5)',
                      primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                    },
                  })}
                  className='react-select-container'
                  classNamePrefix='react-select'
                  options={departments}
                  isSearchable
                  placeholder='Select Department'
                  onChange={(val: any) => handledepartmentChange(setFieldValue, val)}
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='department_id' />
                </div>
              </div>

              <div className='w-100 mb-5 me-2'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required ms-1'>Select Manager</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Select the manager'
                  ></i>
                </label>
                <Select
                  value={hiringManager.find((option: any) => option.value === values?.user_id)}
                  styles={{
                    control: (baseStyles: any, state: any) => ({
                      ...baseStyles,
                      backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                      color: '#ffffff',
                      borderWidth: '0px',
                    }),
                    menu: (baseStyles: any) => ({
                      ...baseStyles,
                      backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                    }),
                    singleValue: (baseStyles: any, state: any) => ({
                      ...baseStyles,
                      color: mode === 'dark' ? '#92929f' : '#000000',
                    }),
                    input: (baseStyles: any) => ({
                      ...baseStyles,
                      color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                    }),
                    option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                      ...baseStyles,
                      backgroundColor: isSelected
                        ? '#C2C2C2'
                        : isFocused
                        ? '#e0e0e0'
                        : baseStyles.backgroundColor,
                      color: isSelected ? '#000000' : isFocused ? '#000000' : baseStyles.color,
                    }),
                  }}
                  theme={(theme: any) => ({
                    ...theme,
                    borderRadius: 5,
                    backgroundColor: '#000000',
                    colors: {
                      ...theme.colors,
                      primary25: 'rgba(80, 107, 236, 0.5)',
                      primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                    },
                  })}
                  className='react-select-container'
                  classNamePrefix='react-select'
                  options={hiringManager}
                  isSearchable
                  placeholder='Select Manager'
                  onChange={(val: any) => handledManagerChange(setFieldValue, val)}
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='hiring_manager_id' />
                </div>
              </div>
            </div>
            <div className='w-100 d-flex flex-column mb-4 fv-row'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Description</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Describe the vacancy in few words'
                ></i>
              </label>
              <Field
                as='textarea'
                className={`form-control form-control form-control-solid`}
                name='job_description'
                placeholder='Add the vacancy description here.For Eg:Software Developer designs, develops, and maintains software solutions, utilizing programming languages to meet specific business or user needs.'
                rows={3}
                value={values?.job_description}
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='job_description' />
              </div>
            </div>

            <div className='w-100 d-flex flex-column mb-4 fv-row ms-2'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Number of Vacancies</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Specify the No. of Vacancies'
                ></i>
              </label>
              <Field
                type='number'
                className='form-control form-control form-control-solid'
                name='number_of_vacancies'
                placeholder='Enter No. of Vacancies'
                value={values?.number_of_vacancies}
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='number_of_vacancies' />
              </div>
            </div>

            <button
              type='submit'
              className='d-none btn btn-lg btn-primary w-100'
              id='verify-sign-up-btn'
            >
              Submit
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}

const step2Schema = Yup.object().shape({
  experience_in_yrs: Yup.number()
    .required('Experience is required')
    .min(0, 'Experience must be between 0 and 60')
    .max(60, 'Experience must be between 0 and 60'),
  service_agreement_in_yrs: Yup.number()
    .required('Service Agreement is required')
    .min(0, 'Service Agreement must be between 0 and 10')
    .max(60, 'Service Agreement must be between 0 and 10'),
})

const Step2 = ({set3rdIndex, data, setData}: any) => {
  const {execute} = usePostApi()
  const {mode} = useThemeMode()
  const [qualifications, setqualifications] = useState([])
  const podId: any | null = FindPodIdByCode()

  const getqualifications = async () => {
    try {
      const url = `/pod/qualifications/list`
      const formData = {
        pod_id: podId,
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.qualification_name,
        label: each.qualification_name,
      }))

      setqualifications(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getqualifications()
  }, [])

  const handleIndustryChange = (setFieldValue: any, selectedOptions: any) => {
    const values = selectedOptions
      ? selectedOptions.map((option: {value: any}) => option.value)
      : []

    setFieldValue('qualifications', values)
  }

  const onSubmit = (values: any) => {
    setData({...data, ...values})
    set3rdIndex()
  }

  return (
    <Formik validationSchema={step2Schema} initialValues={data} onSubmit={onSubmit}>
      {({setFieldValue, values}: any) => {
        return (
          <Form className='w-100'>
            <div className='w-100 mb-5'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className=''>Qualifications (Optional)</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Select the Qualifications'
                ></i>
              </label>
              <Select
                value={qualifications.find((option: any) => option.value === values.qualifications)}
                styles={{
                  control: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                    color: '#ffffff',
                    borderWidth: '0px',
                  }),
                  menu: (baseStyles: any) => ({
                    ...baseStyles,
                    backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                  }),
                  singleValue: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    color: mode === 'dark' ? '#92929f' : '#000000',
                  }),
                  input: (baseStyles: any) => ({
                    ...baseStyles,
                    color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                  }),
                  option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                    ...baseStyles,
                    backgroundColor: isSelected
                      ? '#C2C2C2'
                      : isFocused
                      ? '#e0e0e0'
                      : baseStyles.backgroundColor,
                    color: isSelected ? '#000000' : isFocused ? '#000000' : baseStyles.color,
                  }),
                }}
                theme={(theme: any) => ({
                  ...theme,
                  borderRadius: 5,
                  backgroundColor: '#000000',
                  colors: {
                    ...theme.colors,
                    primary25: 'rgba(80, 107, 236, 0.5)',
                    primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                  },
                })}
                className='react-select-container'
                classNamePrefix='react-select'
                options={qualifications}
                isSearchable
                isMulti
                placeholder='Select Qualifications'
                onChange={(val: any) => handleIndustryChange(setFieldValue, val)}
              />

              <div className='text-danger mt-2'>
                <ErrorMessage name='qualifications' />
              </div>
            </div>

            <div className='w-100 mb-5'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Skills</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Specify the Salary you want to Offer'
                ></i>
              </label>
              <Field
                type='text'
                name='skills'
                className='form-control form-control-solid'
                placeholder='Enter Skills'
                // onChange={(e) => updateData(e.target.name, e.target.value)}
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='skills' />
              </div>
            </div>

            <div className='w-100 mb-5 me-2'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Experience (in yrs)</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Specify the Experience(in yrs)'
                ></i>
              </label>
              <Field
                type='number'
                name='experience_in_yrs'
                className='form-control form-control-solid mb-2'
                placeholder='Experience'
                min='0'
                max='60'
              />
              <div className='alert alert-primary d-flex align-items-center p-1'>
                <KTIcon iconName='information' className='fs-2 text-primary me-2' />
                <div className='d-flex flex-column'>
                  <h6 className='mb-0 fw-semibold fs-7 text-dark'>Note:</h6>
                  <span className='fw-semibold text-dark'>
                    For freshers, the experience maybe entered as 0 (Zero)
                  </span>
                </div>
              </div>

              <div className='text-danger mt-2'>
                <ErrorMessage name='experience_in_yrs' />
              </div>
            </div>

            <div className='w-100 mb-5 me-2'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Service agreement (in yrs)</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Service agreement(in yrs)'
                ></i>
              </label>
              <Field
                type='number'
                name='service_agreement_in_yrs'
                className='form-control form-control-solid mb-2'
                placeholder='Service agreement'
                min='0'
                max='10'
              />

              <div className='text-danger mt-2'>
                <ErrorMessage name='service_agreement_in_yrs' />
              </div>
            </div>

            <button
              type='submit'
              className='d-none btn btn-lg btn-primary w-100'
              id='step_2_submit'
            >
              Submit
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}

interface Step3Props {
  data: Data
  setData(data: any): void
  handleSaveClick(): void
}

const step3Schema = Yup.object().shape({
  location_id: Yup.string().required('Location is required'),
  currency_id: Yup.string().required('Currency is required'),
  salary_start: Yup.number().required('Min. Salary is required'),
  salary_end: Yup.number()
    .required('Max. Salary is required')
    .min(Yup.ref('salary_start'), 'Max. Salary should be greater than or equal to Min. Salary'),
  employment_type_id: Yup.string().required('Employment type is required'),
})

const Step3 = ({data, setData, handleSaveClick}: Step3Props) => {
  const {mode} = useThemeMode()
  const {execute} = usePostApi()
  const podId: any | null = FindPodIdByCode()
  const [currencies, setcurrencies] = useState([])
  const [locations, setlocations] = useState([])
  const [employmentTypes, setemploymentTypes] = useState([])

  const getEmploymenttypes = async () => {
    try {
      const url = `/pod/employment_type/active`
      const formData = {
        pod_id: podId,
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.emp_type_id,
        label: each.emp_type_name,
      }))

      setemploymentTypes(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getEmploymenttypes()
  }, [])

  const getlocations = async () => {
    try {
      const url = `/pod/locations/active`
      const formData = {
        pod_id: podId,
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.location_id,
        label: each.location_name,
      }))
      setlocations(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getlocations()
  }, [])

  const getCurrencies = async () => {
    try {
      const url = `/master/currency`
      const formData = {
        pod_id: podId,
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.currency_id,
        label: each.currency_name,
      }))
      setcurrencies(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getCurrencies()
  }, [])
  const updateData = (value: string, key: string) => {
    setData({...data, [key]: value})
  }

  const submitStep3 = (values: any) => {
    handleSaveClick()
  }

  const handleIndustryChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('location_id', selectedOption.value)
    } else {
      setFieldValue('location_id', '') // Handle case where no option is selected
    }
  }

  const handleCurrencyChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('currency_id', selectedOption.value)
    } else {
      setFieldValue('currency_id', '') // Handle case where no option is selected
    }
  }

  const handleEmploymenttypeChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('employment_type_id', selectedOption.value)
    } else {
      setFieldValue('employment_type_id', '') // Handle case where no option is selected
    }
  }
  return (
    <>
      <Formik
        validationSchema={step3Schema}
        initialValues={data}
        onSubmit={submitStep3}
        enableReinitialize={true}
      >
        {({setFieldValue, values, handleChange}: any) => {
          return (
            <Form className='w-100'>
              <div className='d-flex'>
                <div className='w-100 mb-5 me-2'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Location</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Select the location'
                    ></i>
                  </label>
                  <Select
                    value={locations.find((option: any) => option.value === values.location_id)}
                    styles={{
                      control: (baseStyles: any, state: any) => ({
                        ...baseStyles,
                        backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                        color: '#ffffff',
                        borderWidth: '0px',
                      }),
                      menu: (baseStyles: any) => ({
                        ...baseStyles,
                        backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                      }),
                      singleValue: (baseStyles: any, state: any) => ({
                        ...baseStyles,
                        color: mode === 'dark' ? '#92929f' : '#000000',
                      }),
                      input: (baseStyles: any) => ({
                        ...baseStyles,
                        color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                      }),
                      option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                        ...baseStyles,
                        backgroundColor: isSelected
                          ? '#C2C2C2'
                          : isFocused
                          ? '#e0e0e0'
                          : baseStyles.backgroundColor,
                        color: isSelected ? '#000000' : isFocused ? '#000000' : baseStyles.color,
                      }),
                    }}
                    theme={(theme: any) => ({
                      ...theme,
                      borderRadius: 5,
                      backgroundColor: '#000000',
                      colors: {
                        ...theme.colors,
                        primary25: 'rgba(80, 107, 236, 0.5)',
                        primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                      },
                    })}
                    className='react-select-container'
                    classNamePrefix='react-select'
                    options={locations}
                    isSearchable
                    placeholder='Select Location'
                    onChange={(val: any) => handleIndustryChange(setFieldValue, val)}
                  />

                  <div className='text-danger mt-2'>
                    <ErrorMessage name='location_id' />
                  </div>
                </div>

                <div className='w-100 mb-5 me-2'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Salary</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Enter the Currency type'
                    ></i>
                  </label>

                  <Select
                    value={currencies.find((option: any) => option.value === values.currency_id)}
                    styles={{
                      control: (baseStyles: any, state: any) => ({
                        ...baseStyles,
                        backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                        color: '#ffffff',
                        borderWidth: '0px',
                      }),
                      menu: (baseStyles: any) => ({
                        ...baseStyles,
                        backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                      }),
                      singleValue: (baseStyles: any, state: any) => ({
                        ...baseStyles,
                        color: mode === 'dark' ? '#92929f' : '#000000',
                      }),
                      input: (baseStyles: any) => ({
                        ...baseStyles,
                        color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                      }),
                      option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                        ...baseStyles,
                        backgroundColor: isSelected
                          ? '#C2C2C2'
                          : isFocused
                          ? '#e0e0e0'
                          : baseStyles.backgroundColor,
                        color: isSelected ? '#000000' : isFocused ? '#000000' : baseStyles.color,
                      }),
                    }}
                    theme={(theme: any) => ({
                      ...theme,
                      borderRadius: 5,
                      backgroundColor: '#000000',
                      colors: {
                        ...theme.colors,
                        primary25: 'rgba(80, 107, 236, 0.5)',
                        primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                      },
                    })}
                    className='react-select-container'
                    classNamePrefix='react-select'
                    options={currencies}
                    isSearchable
                    placeholder='Select Currency'
                    onChange={(val: any) => handleCurrencyChange(setFieldValue, val)}
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='currency_id' />
                  </div>
                </div>
              </div>
              <div className='d-flex'>
                <div className='w-100 mb-5 me-2'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Min. Salary</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Specify the Salary you want to Offer'
                    ></i>
                  </label>
                  <Field
                    type='number'
                    name='salary_start'
                    className='form-control form-control-solid'
                    placeholder='Enter Salary'
                    // onChange={(e) => updateData(e.target.name, e.target.value)}
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='salary_start' />
                  </div>
                </div>

                <div className='w-100 mb-5 ms-2'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Max. Salary</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Specify the Salary you want to Offer'
                    ></i>
                  </label>
                  <Field
                    type='number'
                    name='salary_end'
                    className='form-control form-control-solid'
                    placeholder='Enter Salary'
                    // onChange={(e) => updateData(e.target.name, e.target.value)}
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='salary_end' />
                  </div>
                </div>
              </div>
              <div className='w-100 mb-5'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Employment type</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Select Employment type'
                  ></i>
                </label>
                <Select
                  value={employmentTypes.find((option: any) => option.value === values.emp_type_id)}
                  styles={{
                    control: (baseStyles: any, state: any) => ({
                      ...baseStyles,
                      backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                      color: '#ffffff',
                      borderWidth: '0px',
                    }),
                    menu: (baseStyles: any) => ({
                      ...baseStyles,
                      backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                    }),
                    singleValue: (baseStyles: any, state: any) => ({
                      ...baseStyles,
                      color: mode === 'dark' ? '#92929f' : '#000000',
                    }),
                    input: (baseStyles: any) => ({
                      ...baseStyles,
                      color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                    }),
                    option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                      ...baseStyles,
                      backgroundColor: isSelected
                        ? '#C2C2C2'
                        : isFocused
                        ? '#e0e0e0'
                        : baseStyles.backgroundColor,
                      color: isSelected ? '#000000' : isFocused ? '#000000' : baseStyles.color,
                    }),
                  }}
                  theme={(theme: any) => ({
                    ...theme,
                    borderRadius: 5,
                    backgroundColor: '#000000',
                    colors: {
                      ...theme.colors,
                      primary25: 'rgba(80, 107, 236, 0.5)',
                      primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                    },
                  })}
                  className='react-select-container'
                  classNamePrefix='react-select'
                  options={employmentTypes}
                  isSearchable
                  placeholder='Select Employment type'
                  onChange={(val: any) => handleEmploymenttypeChange(setFieldValue, val)}
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='employment_type_id' />
                </div>
              </div>
              {/* 
              <div className='form-floating flex-fill'>
                <div className='form-check form-check-solid form-switch mb-0'>
                  <Field
                    className='form-check-input w-40px h-20px'
                    type='checkbox'
                    name='vacancy_status'
                    id='toggle'
                    checked={values.vacancy_status}
                    style={{
                      backgroundColor: values.vacancy_status === 'Open' ? '#009688' : '#f4f4f4',
                    }}
                  />
                  <span
                    className={`me-4 fw-semibold ${
                      values.vacancy_status === 'Open' ? 'text-success' : 'text-danger'
                    }`}
                  >
                    {values.vacancy_status}
                  </span>
                  <ErrorMessage component='div' className='text-danger' name='vacancy_status' />
                </div>
              </div> */}

              {/* <div className='form-floating flex-fill'>
                <div className='form-check form-check-solid form-switch mb-0'>
                  <Field
                    className='form-check-input w-40px h-20px'
                    type='checkbox'
                    name='vacancy_status'
                    id='toggle'
                    checked={values.vacancy_status}
                    onChange={() => setFieldValue('vacancy_status', !values.vacancy_status)}
                    style={{
                      backgroundColor: values.vacancy_status === 'Open' ? '#009688' : '#f4f4f4',
                    }}
                  />
                  <span
                    className={`me-4 fw-semibold ${
                      values.vacancy_status === 'Open' ? 'text-success' : 'text-danger'
                    }`}
                  >
                    {values.vacancy_status}
                  </span>
                  <ErrorMessage component='div' className='text-danger' name='vacancy_status' />
                </div>
              </div> */}

              <button className='d-none btn btn-light-primary' type='submit' id='step_3_submit'>
                Submit
              </button>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export {EditVacancy2}
