import {useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import * as Yup from 'yup'
import {defaultProfileImage} from '../core'
import usePostApi from '../custom_hooks/usePostApi'
import {UseToast} from '../useToast'
import {useIntl} from 'react-intl'
import Swal from 'sweetalert2'
import secureLocalStorage from 'react-secure-storage'
import {useAuth} from '../auth'
import usePageTitle from '../custom_hooks/usePageTitle'

const myProfile = [
  {
    title: 'My Account',
    path: `/my-profile`,
    isSeparator: false,
    isActive: false,
  },
  {
    title: ``,
    path: `/my-profile`,
    isSeparator: true,
    isActive: false,
  },
  {
    title: `My Profile`,
    path: `/my-profile`,
    isSeparator: false,
    isActive: false,
  },
]

const MyProfile = () => {
  usePageTitle('My Profile')
  const intl = useIntl()
  const {logout} = useAuth()
  const {execute, loading} = usePostApi()
  const [myProfileData, setMyProfileData] = useState(null)

  let user_id
  let auth_token
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({user_id, auth_token} = userInfo)
  }

  const getMyProfile = async () => {
    try {
      const url = '/auth/profile/get'
      const formData = {}

      const response = await execute(url, 'POST', formData)
      setMyProfileData(response.data[0])
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getMyProfile()
  }, [])
  const [editMode, setEditMode] = useState(false)

  const handleEditBtn = () => {
    setEditMode(true)
  }

  const initialValues = {
    firstName: myProfileData?.first_name || '',
    lastName: myProfileData?.last_name || '',
    themeMode: myProfileData?.theme_mode || 'light',
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('First Name is required')
      .min(3, 'Minimum 3 characters is required')
      .max(250, 'Maximum 250 characters is required'),
    lastName: Yup.string()
      .required('Last Name is required')
      .min(3, 'Minimum 3 characters is required')
      .max(250, 'Maximum 250 characters is required'),
  })

  const formSubmit = () => {
    const element = document.getElementById('submit_btn')
    if (element) {
      element.click()
    }
  }

  const [showOptions, setShowOptions] = useState(false)
  const [previewImage, setPreviewImage] = useState(null)

  const [profileImage, setProfileImage] = useState(
    (myProfileData && myProfileData?.profile_pic) || defaultProfileImage
  )

  useEffect(() => {
    setProfileImage((myProfileData && myProfileData?.profile_pic) || defaultProfileImage)
  }, [myProfileData])

  const dataURLtoFile = (dataURI, fileName) => {
    if (!dataURI) {
      return ''
    }
    const byteString = atob(dataURI.split(',')[1])
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    return new File([ab], fileName, {type: mimeString})
  }

  const onSubmit = async (values) => {
    const formData = new FormData()
    formData.append('first_name', values.firstName)
    formData.append('last_name', values.lastName)

    try {
      const url = '/auth/profile/update'

      const response = await execute(url, 'POST', formData)
      if (response?.status === true) {
        getMyProfile()
      }
      UseToast(
        response?.status_message,
        {
          position: 'bottom-right',
          autoClose: false,
          theme: 'colored',
        },
        'success'
      )
    } catch (error) {
      console.error('Error:', error)

      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          autoClose: false,
          theme: 'colored',
        },
        'error'
      )
    }
    setEditMode(false)
  }

  const passwordChangeSchema = Yup.object().shape({
    currentPassword: Yup.string().required(
      intl.formatMessage({id: 'WARNING.CURRENT_PASSWORD_IS_REQUIRED'})
    ),

    newPassword: Yup.string()
      .required(intl.formatMessage({id: 'WARNING.NEW_PASSWORD_IS_REQUIRED'}))
      .min(8, intl.formatMessage({id: 'ERR.PASSWORD_LEN'})),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref('newPassword'), null],
        intl.formatMessage({id: 'WARNING.PASSWORD_MUST_MATCH'})
      )
      .required(intl.formatMessage({id: 'WARNING.CONFIRM_NEW_PASSWORD'})),
  })

  let passwordChangeInitialVals = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  }

  const [currentPWVisible, setCurrentPWVisible] = useState(false)
  const [newPWVisible, setNewPWVisible] = useState(false)
  const [confirmPWVisible, setConfirmPWVisible] = useState(false)

  const togglecurrentPWVisibility = () => {
    setCurrentPWVisible((prevVisible) => !prevVisible)
  }
  const toggleNewPWVisibility = () => {
    setNewPWVisible((prevVisible) => !prevVisible)
  }
  const toggleConfirmPWVisibility = () => {
    setConfirmPWVisible((prevVisible) => !prevVisible)
  }

  const updatePassword = async (formData) => {
    try {
      const url = '/auth/change'
      const response = await execute(url, 'POST', formData)
      // if (response.status === true) {
      //   handleClose()
      // }

      Swal.fire({
        title: `Congratulations!`,
        text: `${response?.status_message}`,
        icon: 'success',
        confirmButtonText: intl.formatMessage({id: 'BTN.OK'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
        allowOutsideClick: false, // Prevents closing the modal by clicking outside
      }).then((result) => {
        if (result.isConfirmed || result.dismiss === Swal.DismissReason.overlay) {
          logout()
        }
      })
    } catch (error) {
      console.error('Error:', error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          autoClose: false,
          theme: 'colored',
        },
        'error'
      )
    }
  }

  const scrambleX = (str, numTimes = 2) => {
    let encoded = str
    for (let i = 0; i < numTimes; i++) {
      encoded = btoa(encoded)
    }
    return encoded
  }

  const handleSubmit = (values, {ChangeForm}) => {
    if (values.newPassword !== values.confirmPassword) {
      return
    }
    const formData = {
      user_id,
      token: auth_token,
      old_password: scrambleX(values.currentPassword),
      new_password: scrambleX(values.newPassword),
    }
    updatePassword(formData)
  }

  const makeApiCall = async (previewImage) => {
    try {
      const url = `/auth/profile_pic/upload`
      const formData = new FormData()
      const documentName = `${Date.now()}`
      // formData.append('organization_id', organization_id)

      if (previewImage) {
        const file = dataURLtoFile(previewImage, `profile_image_${documentName}`)
        formData.append('profile_pic', file)
      }

      const response = await execute(url, 'POST', formData)
      if (response?.status === true) {
        getMyProfile()
      }

      UseToast(
        response?.status_message,
        {
          position: 'bottom-right',
          autoClose: false,
          theme: 'colored',
        },
        'success'
      )
    } catch (error) {
      console.error('Error:', error)
      getMyProfile()
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          autoClose: false,
          theme: 'colored',
        },
        'error'
      )
    }
  }

  const removeProfilePic = async () => {
    try {
      const url = `/auth/profile_pic/delete`
      const formData = {
        profile_pic_url:
          'https://kites8.s3.ap-south-1.amazonaws.com/profile_pics/user_pic__8__6662bd7243f433',
      }

      const response = await execute(url, 'POST', formData)
      if (response?.status === true) {
        getMyProfile()
      }

      UseToast(
        response?.status_message,
        {
          position: 'bottom-right',
          autoClose: false,
          theme: 'colored',
        },
        'success'
      )
    } catch (error) {
      console.error('Error:', error)
      getMyProfile()

      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          autoClose: false,
          theme: 'colored',
        },
        'error'
      )
    }
  }

  const handleRemoveImage = () => {
    Swal.fire({
      title: intl.formatMessage({id: 'WARNING.ARE_YOU_SURE?'}),
      text: intl.formatMessage({id: "WARNING.YOU_WON'T_ABLE_TO_REVERT_THIS!"}),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({id: 'BTN.YES_REMOVE_IT'}),
      cancelButtonText: intl.formatMessage({id: 'BTN.NO_KEEP_IT'}),
      customClass: {
        confirmButton: 'btn btn-light-primary btn-sm',
        cancelButton: 'btn btn-light btn-sm',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setShowOptions(false)
        removeProfilePic()
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        setShowOptions(false)
        // Swal.fire({
        //   title: intl.formatMessage({id: 'MSG.CANCELLED'}),
        //   text: intl.formatMessage({id: 'MSG.IMAGE_SAFE_MSG'}),
        //   icon: 'error',
        //   confirmButtonText: intl.formatMessage({id: 'BTN.OK'}),
        //   customClass: {
        //     confirmButton: 'btn btn-light-primary btn-sm',
        //   },
        // })
      }
    })
  }

  const handleImageUpload = (e) => {
    const file = e.target.files[0]
    const reader = new FileReader()

    reader.onloadend = () => {
      setPreviewImage(reader.result)
    }

    if (file) {
      reader.readAsDataURL(file)
    }
    Swal.fire({
      title: intl.formatMessage({id: 'WARNING.ARE_YOU_SURE?'}),
      text: intl.formatMessage({id: 'LABEL.UPLOAD_PROFILE_IMAGE'}),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({id: 'VALUE.YES'}),
      cancelButtonText: intl.formatMessage({id: 'VALUE.NO'}),
      customClass: {
        confirmButton: 'btn btn-light-primary btn-sm',
        cancelButton: 'btn btn-light btn-sm',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setShowOptions(false)
        makeApiCall(reader.result)
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        setShowOptions(false)
        setPreviewImage(profileImage)
        // Swal.fire({
        //   title: intl.formatMessage({id: 'MSG.CANCELLED'}),
        //   text: intl.formatMessage({id: 'MSG.IMAGE_NOT_UPDATED'}),
        //   icon: 'error',
        //   confirmButtonText: intl.formatMessage({id: 'BTN.OK'}),
        //   customClass: {
        //     confirmButton: 'btn btn-light-primary btn-sm',
        //   },
        // })
      }
    })
  }

  return (
    <>
      <PageTitle breadcrumbs={myProfile}>My Profile</PageTitle>

      {loading && !myProfileData ? (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : (
        <>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-0'>
              <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                <div className='me-7 mb-4'>
                  {/* <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'> */}
                  <div className='symbol symbol-100px symbol-lg-150px symbol-fixed position-relative'>
                    <img
                      src={
                        showOptions
                          ? showOptions && !previewImage
                            ? profileImage
                            : showOptions && previewImage
                            ? previewImage
                            : profileImage
                          : profileImage
                          ? profileImage
                          : defaultProfileImage
                      }
                      alt='img'
                    />
                    {!showOptions ? (
                      <div
                        className='btn btn-icon btn-bg-light btn-color-primary btn-sm text-primary cursor-pointer position-absolute translate-middle bottom-0 start-100 top-0 bg-light rounded-circle h-30px w-30px d-flex justify-content-center'
                        onClick={() => {
                          setPreviewImage(profileImage)
                          setShowOptions(!showOptions)
                        }}
                      >
                        <span className='m-auto'>
                          <KTIcon iconName='pencil' className='fs-6 mt-1' />
                        </span>
                      </div>
                    ) : (
                      <div
                        className='btn btn-icon btn-bg-light btn-color-primary btn-sm text-hover-primary cursor-pointer position-absolute translate-middle bottom-0 start-100 top-0 bg-light rounded-circle h-30px w-30px d-flex justify-content-center'
                        onClick={() => {
                          setPreviewImage(profileImage)
                          setShowOptions(!showOptions)
                        }}
                      >
                        <span className='m-auto'>
                          <KTIcon iconName='cross' className='fs-6 mt-1' />
                        </span>
                      </div>
                    )}
                    {showOptions && (
                      <>
                        {profileImage !== defaultProfileImage && (
                          <div
                            className='btn btn-icon btn-bg-light btn-color-primary btn-sm text-hover-primary cursor-pointer position-absolute translate-middle bottom-0 start-100 top-100 bg-light rounded-circle h-30px w-30px d-flex justify-content-center'
                            onClick={handleRemoveImage}
                          >
                            <span className='m-auto'>
                              <KTIcon iconName='trash' className='fs-6 mt-1' />
                            </span>
                          </div>
                        )}
                        <div
                          className='btn btn-icon btn-bg-light btn-color-primary btn-sm text-hover-primary cursor-pointer position-absolute translate-middle bottom-0 start-99 top-100 bg-light rounded-circle h-30px w-30px d-flex justify-content-center'
                          htmlFor='upload-photo'
                        >
                          <label className='cursor-pointer' htmlFor='upload-photo'>
                            <input
                              type='file'
                              id='upload-photo'
                              style={{display: 'none'}}
                              onChange={handleImageUpload}
                            />
                            <span className='m-auto'>
                              <KTIcon
                                iconName='exit-up'
                                className='fs-6 mt-1'
                                onClick={() => document.getElementById('upload-photo').click()}
                              />
                            </span>
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center mb-2'>
                        <p className='text-gray-800 text-hover-primary fs-2 fw-semibold me-1 mb-0'>
                          {myProfileData?.first_name} {myProfileData?.last_name}
                        </p>
                      </div>

                      <div className='d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2'>
                        <p className='d-flex align-items-center text-gray-400 text-hover-primary m-0'>
                          <KTIcon iconName='sms' className='fs-4 me-1' />
                          {myProfileData?.email || ''}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className='d-none d-flex flex-wrap flex-stack'>
                    <div className='d-flex flex-column flex-grow-1 pe-8'>
                      <div className='d-flex flex-wrap'>
                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                          <div className='d-flex align-items-center'>
                            <KTIcon iconName='arrow-down' className='fs-3 text-danger me-2' />
                            <div className='fs-2 fw-semibold'>75</div>
                          </div>

                          <div className='fw-semibold fs-6 text-gray-400'>Projects</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex flex-column flex-lg-row'>
            <div className='w-lg-50 card mb-5 mb-xl-10 me-lg-3' id='kt_profile_details_view'>
              <div className='card-header cursor-pointer'>
                <div className='card-title m-0'>
                  <h3 className='fw-semibold m-0'>Profile Details</h3>
                </div>
                {editMode ? (
                  <div className='d-flex align-items-center'>
                    <button
                      className='btn btn-light-danger btn-sm'
                      onClick={() => {
                        setEditMode(false)
                        setPreviewImage(profileImage)
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className='btn btn-light-primary btn-sm align-self-center ms-5'
                      type='submit'
                      onClick={formSubmit}
                    >
                      <KTIcon iconName='check' className='fs-3' />
                      Save
                    </button>
                  </div>
                ) : (
                  <button
                    className='btn btn-light-primary btn-sm align-self-center'
                    onClick={handleEditBtn}
                  >
                    <KTIcon iconName='pencil' className='fs-6' />
                    Edit
                  </button>
                )}
              </div>
              <div className='card-body p-9'>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                  enableReinitialize={true}
                >
                  {({values, setFieldValue}) => {
                    const handleToggle = () => {
                      const newThemeMode = values.themeMode === 'light' ? 'dark' : 'light'
                      setFieldValue('themeMode', newThemeMode)
                    }
                    return (
                      <Form>
                        <div className='row mb-7'>
                          <label className='col-lg-4 fw-semibold text-muted m-auto'>
                            Full Name
                          </label>
                          <div className='col-lg-8'>
                            {editMode ? (
                              <div className='row'>
                                <div className='col-12 col-md-6'>
                                  <Field
                                    type='text'
                                    name='firstName'
                                    className='form-control form-control-solid'
                                    placeholder='First Name'
                                  />
                                  <ErrorMessage
                                    name='firstName'
                                    component='div'
                                    className='text-danger'
                                  />
                                </div>
                                <div className='col-12 col-md-6 mt-5 mt-md-0'>
                                  <Field
                                    type='text'
                                    name='lastName'
                                    className='form-control form-control-solid'
                                    placeholder='Last Name'
                                  />
                                  <ErrorMessage
                                    name='lastName'
                                    component='div'
                                    className='text-danger'
                                  />
                                </div>
                              </div>
                            ) : (
                              <span className='fs-6 text-dark'>
                                {myProfileData?.first_name} {myProfileData?.last_name}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className='row mb-7'>
                          <label className='col-lg-4 fw-semibold text-muted m-auto'>Email</label>

                          <div className='col-lg-8'>
                            <span className='fs-6 text-dark'>{myProfileData?.email || ''}</span>
                          </div>
                        </div>

                        <div className='row mb-7'>
                          <label className='col-lg-4 fw-semibold text-muted m-auto'>
                            Preferred Theme Mode
                          </label>

                          <div className='col-lg-8'>
                            <div className='form-floating flex-fill'>
                              <div className='form-check form-check-solid form-switch mb-0'>
                                <Field
                                  className='form-check-input w-40px h-20px'
                                  type='checkbox'
                                  name='themeMode'
                                  id='toggle'
                                  checked={values.themeMode === 'dark'}
                                  onChange={handleToggle}
                                  disabled={!editMode}
                                  style={{
                                    backgroundColor:
                                      values.themeMode === 'light' ? '#f1f1f1' : '#000',
                                  }}
                                />
                                <span className={`me-4 fw-semibold text-dark`}>
                                  {values.themeMode === 'dark' ? 'Dark Mode' : 'Light Mode'}
                                </span>
                                <ErrorMessage
                                  component='div'
                                  className='text-danger'
                                  name='themeMode'
                                />
                              </div>
                            </div>

                            {/* <span className='fs-6 text-dark'>{myProfileData?.email || ''}</span> */}
                          </div>
                        </div>

                        <button
                          type='submit'
                          className='d-none btn btn-light-primary btn-sm'
                          id='submit_btn'
                        >
                          Save
                        </button>
                      </Form>
                    )
                  }}
                </Formik>
              </div>
            </div>

            <div className='w-lg-50 card mb-5 mb-xl-10 ms-lg-3'>
              <div className=''>
                <Formik
                  initialValues={passwordChangeInitialVals}
                  validationSchema={passwordChangeSchema}
                  onSubmit={handleSubmit}
                >
                  {({ChangeForm, errors, touched}) => (
                    <Form>
                      <div className='card-header cursor-pointer'>
                        <div className='card-title m-0'>
                          <h3 className='fw-semibold m-0'>Change Password</h3>
                        </div>

                        <button
                          className='btn btn-light-success btn-sm align-self-center'
                          // onClick={handleEditBtn}
                          type='submit'
                        >
                          <KTIcon iconName='check' className='fs-6' />
                          Save
                        </button>
                      </div>

                      <div className='card-body p-9'>
                        <div className=''>
                          <div className='w-100'>
                            <label className='' htmlFor='doc-name'>
                              {intl.formatMessage({id: 'LABEL.CURRENT_PASSWORD'})}
                            </label>

                            <div style={{position: 'relative'}}>
                              <Field
                                type={currentPWVisible ? 'text' : 'password'}
                                name='currentPassword'
                                placeholder={intl.formatMessage({id: 'LABEL.CURRENT_PASSWORD'})}
                                autoComplete='off'
                                className='form-control bg-transparent'
                              />
                              <span
                                onClick={togglecurrentPWVisibility}
                                style={{
                                  position: 'absolute',
                                  right: '0px',
                                  top: '50%',
                                  transform: 'translateY(-50%)',
                                  cursor: 'pointer',
                                }}
                              >
                                <KTIcon
                                  iconName={currentPWVisible ? 'eye-slash' : 'eye'}
                                  className='fs-2 icon'
                                />
                              </span>
                            </div>
                            <ErrorMessage
                              name='currentPassword'
                              component='div'
                              className='text-danger'
                            />
                          </div>
                          <div className='w-100'>
                            <label className='mt-5' htmlFor='doc-name'>
                              {intl.formatMessage({id: 'BTN.NEW_PASSWORD'})}
                            </label>

                            <div style={{position: 'relative'}}>
                              <Field
                                type={newPWVisible ? 'text' : 'password'}
                                name='newPassword'
                                placeholder={intl.formatMessage({id: 'BTN.NEW_PASSWORD'})}
                                autoComplete='off'
                                className='form-control bg-transparent'
                              />
                              <span
                                onClick={toggleNewPWVisibility}
                                style={{
                                  position: 'absolute',
                                  right: '0px',
                                  top: '50%',
                                  transform: 'translateY(-50%)',
                                  cursor: 'pointer',
                                }}
                              >
                                <KTIcon
                                  iconName={newPWVisible ? 'eye-slash' : 'eye'}
                                  className='fs-2 icon'
                                />
                              </span>
                            </div>

                            <ErrorMessage
                              name='newPassword'
                              component='div'
                              className='text-danger'
                            />
                          </div>
                          <div className='w-100'>
                            <label className='mt-5' htmlFor='doc-name'>
                              {intl.formatMessage({id: 'BTN.CONFIRM_NEW_PASSWORD'})}
                            </label>
                            <div style={{position: 'relative'}}>
                              <Field
                                type={confirmPWVisible ? 'text' : 'password'}
                                name='confirmPassword'
                                placeholder={intl.formatMessage({id: 'BTN.CONFIRM_NEW_PASSWORD'})}
                                autoComplete='off'
                                className='form-control bg-transparent'
                              />
                              <span
                                onClick={toggleConfirmPWVisibility}
                                style={{
                                  position: 'absolute',
                                  right: '0px',
                                  top: '50%',
                                  transform: 'translateY(-50%)',
                                  cursor: 'pointer',
                                }}
                              >
                                <KTIcon
                                  iconName={confirmPWVisible ? 'eye-slash' : 'eye'}
                                  className='fs-2 icon'
                                />
                              </span>
                            </div>
                            <ErrorMessage
                              name='confirmPassword'
                              component='div'
                              className='text-danger'
                            />
                          </div>
                        </div>

                        {/* <div className='card-footer p-0 py-5 text-end d-none'>
                          <button type='button' className='btn btn-light btn-sm me-2'>
                            {intl.formatMessage({id: 'BTN.CLOSE'})}
                          </button>
                          <button
                            type='submit'
                            className='btn btn-light-primary btn-sm ms-2'
                            id='Change-password-btn'
                          >
                            {intl.formatMessage({id: 'BTN.ADD'})}
                          </button>
                        </div> */}
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default MyProfile
