import React from 'react'
import DocumentsTable from './DocsTable'
import {useParams} from 'react-router-dom'
import usePageTitle from '../custom_hooks/usePageTitle'
import {PageTitle} from '../../../_metronic/layout/core'

const documentsData = [
  {
    id: 1,
    document_type_name: 'Invoice',
    file_id: 'inv-12345',
    document_url: 'https://example.com/documents/invoice-12345.pdf',
  },
  {
    id: 2,
    document_type_name: 'Receipt',
    file_id: 'rec-67890',
    document_url: 'https://example.com/documents/receipt-67890.pdf',
  },
  {
    id: 3,
    document_type_name: 'Contract',
    file_id: 'con-11223',
    document_url: 'https://example.com/documents/contract-11223.pdf',
  },
  {
    id: 4,
    document_type_name: 'Report',
    file_id: 'rep-44556',
    document_url: 'https://example.com/documents/report-44556.pdf',
  },
  {
    id: 5,
    document_type_name: 'Statement',
    file_id: 'sta-78901',
    document_url: 'https://example.com/documents/statement-78901.pdf',
  },
]

function OnboardingDocuments() {
  const {id} = useParams()
  const documents = [
    {
      title: `(${id})`,
      path: `/${id}/documents`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: ``,
      path: `/${id}/documents`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `Onboarding Documents`,
      path: `/${id}/documents/onboarding-docs`,
      isSeparator: false,
      isActive: false,
    },
  ]
  return (
    <>
      <PageTitle breadcrumbs={documents}>Onboarding Documents</PageTitle>
      <DocumentsTable data={documentsData} />
    </>
  )
}

export default OnboardingDocuments
