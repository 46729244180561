const SideDrawer = (props) => {
  const {value} = props
  const sideDrawerMethod = () => {
    if (value && value.type) {
      switch (value.type) {
        // case 'add_candidate':
        //   return <AddNewCandidateDrawer props={value} />
        // case 'add_interview':
        //   return <ScheduleInterview props={value} />
        // case 'add_vacancies':
        //   return <AddVacancyDrawer props={value} />
        // case 'edit_vacancies':
        //   return <EditVacancyDrawer props={value} />
        // case 'edit_candidates':
        //   return <EditCandidateDrawer props={value} />

        default:
        // return <AddNewCandidateDrawer props={value} />
      }
    }
    return null
  }
  return (
    <div
      id='side_activities'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='side-activities'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#side_activities_toggle'
      data-kt-drawer-close='#side_activities_close'
    >
      {sideDrawerMethod()}
    </div>
  )
}
export {SideDrawer}
