import {useState, useContext, useRef, useEffect} from 'react'
import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import {KTIcon} from '../../../../../../_metronic/helpers'
import AddNewQualification from './employment-education/AddQualification'
import AddExperience from './employment-education/AddExperience'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import {Modal} from 'react-bootstrap'
import AddCertification from './employment-education/AddCertification'
import {UseToast} from '../../../../useToast'
import {RenderFields} from '../RenderFields'
import CandidateInfoContext from '../../CandidateInfoContext'
import {useAuth} from '../../../../auth'

const transformNestedObject = (nestedObject: any) => {
  const result: any = {}
  try {
    Object.keys(nestedObject).forEach((key) => {
      result[key] = nestedObject[key].value
    })
    return result
  } catch {
    return {}
  }
}

const EmploymentAndEducation = ({
  formValues,
  fullFormValues,
  addQualification,
  editQualification,
  deleteQualification,
  setFormValues,
  updateIsFresher,
  updateData,
  candidateData,
  postLoading,
  structure,
  setEditMode,
  setCorrections,
  corrections,
}: any) => {
  const {currentUser} = useAuth()
  const {
    qualifications = [],
    certifications = [],
    isFresher = 'No',
    experiences = [],
    customFields,
  } = formValues || {}
  const {corrections: contextCorrections} = useContext(CandidateInfoContext)
  const {_employment_education} = contextCorrections
  const {
    qualifications: correctionQuals,
    certifications: correctionCertifications,
    experiences: correctionExperiences,
    customFields: correctionCustomFields,
  } = _employment_education
  // console.log(qualifications)
  const [qualType, setQualType] = useState('add')
  const [qualIndex, setQualIndex] = useState<null | number>(null)
  const [qualInitialVals, setQualInitialVals]: any = useState({})
  const [certificateType, setCertificateType] = useState('add')
  const [certificateIndex, setCertificateIndex] = useState<null | number>(null)
  const [certificateInitialVals, setCertificateInitialVals]: any = useState({})
  const [experienceType, setExperienceType] = useState('add')
  const [experienceIndex, setExperienceIndex] = useState<null | number>(null)
  const [experienceInitialVals, setExperienceInitialVals]: any = useState({})

  const [showModal, setShowModal] = useState(false)
  const [showCertificationModal, setShowCertificationModal] = useState(false)
  const [showExpModal, setShowExpModal] = useState(false)

  const customFieldsInitialVals = transformNestedObject(customFields)
  const initialValuesRef = useRef(customFields)

  useEffect(() => {
    // Update the ref when initialValues change
    initialValuesRef.current = customFields
  }, [customFields])

  const initialValues1 = customFieldsInitialVals || {}
  structure?.items?.forEach((item: any) => {
    if (!initialValues1[item.id]) {
      initialValues1[item.id] =
        item.field_type === null ? false : item.field_type === 'Separator' ? '_sep_a_ra_tor' : ''
      // initialValues[item.id] = item.field_type === 'Toggle' ? false : ''
    }
    if (item.require_confirmation && !initialValues1[`${item.id}_confirmation`]) {
      initialValues1[`${item.id}_confirmation`] = ''
    }

    // Update initialValues with corrections if available
    if (correctionCustomFields[item.id]) {
      initialValues1[item.id] = correctionCustomFields[item.id].value
    }
  })

  const validationSchema1 = Yup.object().shape(
    (structure?.items || [])?.reduce((schema: any, item: any) => {
      if (item.is_required) {
        switch (item.field_type) {
          case 'Single Line': {
            let validator = Yup.string()
              .min(
                item?.validations?.min || 0,
                `Minimum ${item?.validations?.min} characters required`
              )
              .max(
                item?.validations?.max || 250,
                `Maximum ${item?.validations?.max} characters allowed`
              )
              .required(`${item.field_name} is required`)

            if (item.validations?.regex) {
              validator = validator.matches(new RegExp(item.validations.regex), 'Invalid format')
            }

            schema[item.id] = validator

            if (item.require_confirmation) {
              schema[`${item.id}_confirmation`] = Yup.string()
                .oneOf([Yup.ref(item.id)], `${item.confirmation_prompt} does not match`)
                .required(`${item.confirmation_prompt} is required`)
            }
            break
          }
          case 'Multi Line': {
            let validator = Yup.string()
              .min(
                item?.validations?.min || 0,
                `Minimum ${item?.validations?.min} characters required`
              )
              .max(
                item?.validations?.max || 1000, // Adjust the max length as needed
                `Maximum ${item?.validations?.max} characters allowed`
              )
              .required(`${item.field_name} is required`)

            if (item.validations?.regex) {
              validator = validator.matches(new RegExp(item.validations.regex), 'Invalid format')
            }

            schema[item.id] = validator
            break
          }
          case 'Numeric': {
            let validator = Yup.number()
              .typeError(`${item.field_name} must be a number`)
              .required(`${item.field_name} is required`)

            if (item.validations?.min !== undefined && item.validations?.min !== null) {
              validator = validator.min(
                item.validations.min,
                `${item.field_name} must be at least ${item.validations.min}`
              )
            }

            if (item.validations?.max !== undefined && item.validations?.max !== null) {
              validator = validator.max(
                item.validations.max,
                `${item.field_name} must be at most ${item.validations.max}`
              )
            }

            schema[item.id] = validator
            break
          }
          case 'Date Time': {
            let validator = Yup.date().required(`${item.field_name} is required`)

            const {allow_past_date, allow_future_date, allow_current_date, min_date, max_date} =
              item.validations || {}

            const today = new Date()
            today.setHours(0, 0, 0, 0) // Reset hours to ensure proper comparison

            if (min_date) {
              validator = validator.min(new Date(min_date), `Date must be after ${min_date}`)
            }

            if (max_date) {
              validator = validator.max(new Date(max_date), `Date must be before ${max_date}`)
            }

            if (allow_past_date === false) {
              const minValidDate = allow_current_date ? today : new Date(today.getTime() + 86400000) // Next day if current date is not allowed
              validator = validator.min(
                minValidDate,
                allow_current_date
                  ? 'Past dates are not allowed except today'
                  : 'Past dates are not allowed'
              )
            }

            if (allow_future_date === false) {
              const maxValidDate = allow_current_date ? today : new Date(today.getTime() - 86400000) // Previous day if current date is not allowed
              validator = validator.max(
                maxValidDate,
                allow_current_date
                  ? 'Future dates are not allowed except today'
                  : 'Future dates are not allowed'
              )
            }

            schema[item.id] = validator
            break
          }
          case 'Dropdown':
            schema[item.id] = Yup.string().required(`${item.field_name} is required`)
            break
          case 'Toggle':
            schema[item.id] = Yup.string()
              .oneOf(['Yes', 'No'])
              .required(`${item.field_name} is required`)
            break
          case 'Media':
            schema[item.id] = Yup.mixed().required(`${item.field_name} is required`)
            break
          default:
            break
        }
      }
      return schema
    }, {})
  )

  const addEducationalQualification = () => {
    setQualType('add')
    setShowModal(true)
  }

  const editQualification1 = () => {
    setQualType('edit')
    setShowModal(true)
  }

  const AddNewCertification = () => {
    setCertificateType('add')
    setShowCertificationModal(true)
  }

  const editCertification1 = () => {
    setCertificateType('edit')
    setShowCertificationModal(true)
  }

  const editExperience = () => {
    setExperienceType('edit')
    setShowExpModal(true)
  }

  const onDragEnd = (result: any) => {
    if (!result.destination) return

    const items = Array.from(qualifications)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    // Here you should update the state with the new order
    // Example:
    setFormValues((prevFormValues: any) => ({
      ...prevFormValues,
      _employment_education: {
        ...prevFormValues._employment_education,
        qualifications: items,
      },
    }))
  }

  const onDragEndCertifications = (result: any) => {
    if (!result.destination) return

    const items = Array.from(certifications)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    // Here you should update the state with the new order
    // Example:
    setFormValues((prevFormValues: any) => ({
      ...prevFormValues,
      _employment_education: {
        ...prevFormValues._employment_education,
        certifications: items,
      },
    }))
  }
  const onDragEndExperiences = (result: any) => {
    if (!result.destination) return

    const items = Array.from(experiences)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    // Here you should update the state with the new order
    // Example:
    setFormValues((prevFormValues: any) => ({
      ...prevFormValues,
      _employment_education: {
        ...prevFormValues._employment_education,
        experiences: items,
      },
    }))
  }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const handleClose = () => setIsModalOpen(false)

  const checkIsExperienceAvail = () => {
    if (experiences.length > 0) {
      setIsModalOpen(true)
    } else {
      updateIsFresher('Yes')
    }
  }

  const [validationModal, setValidationModal] = useState(false)
  const handleValidationModalClose = () => setValidationModal(false)

  const getModalInfoMsg = () => {
    if (qualifications.length < 1) {
      return 'Please enter details for at least 1 qualification'
    } else if (isFresher === 'No' && !experiences.length) {
      return "If you have previous employment experience, please enter the details below. If you are a fresher, select 'Yes' for 'Are you a fresher?'"
    }
    return ''
  }

  const transformData = (values: any, items: any) => {
    const result: any = {}
    items.forEach((item: any) => {
      const {id, field_name} = item
      if (values.hasOwnProperty(id)) {
        result[id] = {
          label: field_name,
          value: values[id],
        }
      } else {
        result[id] = {
          label: field_name,
          value: '',
        }
      }
    })

    return result
  }

  const getEditedValues = (currentValues: any) => {
    const initialValues = customFieldsInitialVals
    const editedValues: any = contextCorrections._employment_education.customFields || {}
    const now = new Date()
    const year = now.getFullYear()
    const month = String(now.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
    const day = String(now.getDate()).padStart(2, '0')
    const hours = String(now.getHours()).padStart(2, '0')
    const minutes = String(now.getMinutes()).padStart(2, '0')
    const seconds = String(now.getSeconds()).padStart(2, '0')

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`

    Object.keys(currentValues).forEach((key) => {
      if (currentValues[key] !== initialValues[key]) {
        editedValues[key] = {
          value: currentValues[key],
          by: `${currentUser?.first_name} ${currentUser?.last_name} (${currentUser?.email})`,
          at: formattedDateTime,
        }
      } else if (currentValues[key] === initialValues[key] && editedValues[key]) {
        delete editedValues[key]
      }
    })

    return editedValues
  }

  const customFieldSubmit = async (values: any) => {
    // const formattedVals = transformData(values, structure.items)
    const editedValues = getEditedValues(values)
    const updatedCorrections = {
      ...contextCorrections,
      _employment_education: {
        ...contextCorrections._employment_education,
        customFields: {
          ...contextCorrections._employment_education.customFields,
          ...editedValues,
        },
      },
    }

    // console.log(initialValuesRef.current)
    // console.log(updatedCorrections)

    // return
    try {
      setFormValues({
        ...fullFormValues,
        _employment_education: {
          ...fullFormValues._employment_education,
          customFields: initialValuesRef.current,
        },
        // locked_sections: {...filledSections, employment_education: true},
      })

      // console.log({
      //   ...fullFormValues,
      //   _employment_education: {
      //     ...candidateData._employment_education,
      //     customFields: formattedVals,
      //   },
      //   corrections: contextCorrections,
      // })
      // setFormValues({
      //   ...candidateData,
      //   _employment_education: {
      //     ...candidateData._employment_education,
      //     customFields: formattedVals,
      //   },
      //   // locked_sections: {...filledSections, employment_education: true},
      // })
      // await updateData({
      //   ...candidateData,
      // })
      await updateData({
        ...fullFormValues,
        _employment_education: {
          ...candidateData._employment_education,
          customFields: initialValuesRef.current,
        },
        corrections: updatedCorrections,
      })
      // await updateData({
      //   ...candidateData,
      //   _employment_education: {
      //     ...candidateData._employment_education,
      //     customFields: formattedVals,
      //   },
      //   // locked_sections: {...filledSections, employment_education: true},
      // })
      setEditMode('educationEmployment')
      // setFilledSections((prev) => ({
      //   ...prev,
      //   employment_education: true,
      // }))
    } catch (error) {
      console.error('Error updating data:', error)
      UseToast(
        'Failed to update data. Please try again.',
        {position: 'bottom-right', theme: 'colored', autoClose: false},
        'error'
      )
    }
  }

  const getCurrentUI = () => {
    return (
      <>
        <Formik
          initialValues={initialValues1}
          validationSchema={validationSchema1}
          onSubmit={customFieldSubmit}
          enableReinitialize
        >
          {({setFieldValue, values, errors, touched, isValid}) => {
            return (
              <Form
                className={`w-100 d-flex flex-wrap ${structure?.items?.length > 0 ? 'mb-5' : ''}`}
              >
                {RenderFields(structure?.items, setFieldValue, values, errors, touched)}
                <div className='d-none w-100 d-flex justify-content-center mt-5'>
                  <button
                    id='custom_fields_submit_emp_edu'
                    type='submit'
                    className='btn btn-primary btn-md'
                    // disabled={!isValid}
                    onClick={() => {
                      if (!isValid) {
                        // setAccuracyErr(false)
                        // setIsModalOpen(true)
                        // return
                        UseToast(
                          'Please fill all the mandatory fields.',
                          {
                            position: 'bottom-right',
                            theme: 'colored',
                            autoClose: true,
                          },
                          'error'
                        )
                      }
                    }}
                  >
                    Save Information
                  </button>
                </div>
              </Form>
            )
          }}
        </Formik>

        <div className='d-flex justify-content-between'>
          <h3 className='fw-semibold'>Educational Qualifications</h3>

          <button
            className='btn btn-light-primary btn-sm'
            onClick={(e) => {
              addEducationalQualification()
            }}
          >
            <KTIcon iconName='plus' className='fs-2' />
            Add
          </button>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='qualifications'>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {qualifications.length ? (
                  qualifications.map((each: any, index: number) => {
                    if (each.status && each.status === 'deleted') {
                      return null
                    }
                    const updatedVal = correctionQuals[each.id] || {}
                    return each.id ? (
                      <Draggable key={each.id} draggableId={each.id.toString()} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`card bg-light border p-5 d-flex flex-row justify-content-between align-items-center mt-5 ${
                              snapshot.isDragging ? 'dragging' : ''
                            }`}
                          >
                            <div className='w-75 d-flex align-items-center'>
                              <svg
                                height='24px'
                                viewBox='0 -960 960 960'
                                width='24px'
                                fill='#e5e5e5'
                              >
                                <path d='M160-360v-80h640v80H160Zm0-160v-80h640v80H160Z' />
                              </svg>
                              <div className='d-flex flex-row align-items-center'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  height='54px'
                                  viewBox='0 -960 960 960'
                                  width='54px'
                                  fill='#5f6368'
                                >
                                  <path d='M480-120 200-272v-240L40-600l440-240 440 240v320h-80v-276l-80 44v240L480-120Zm0-332 274-148-274-148-274 148 274 148Zm0 241 200-108v-151L480-360 280-470v151l200 108Zm0-241Zm0 90Zm0 0Z' />
                                </svg>
                                <div className='text-muted ms-5'>
                                  <h4 className='fw-semibold m-0'>
                                    {updatedVal?.educationLevel || each.educationLevel} -{' '}
                                    {updatedVal?.discipline || each.discipline}
                                  </h4>
                                  <span className='m-0'>
                                    {updatedVal?.yearOfPassing || each.yearOfPassing},{' '}
                                    {updatedVal?.school || each.school}
                                  </span>
                                  <br />
                                  <span className='m-0'>
                                    {updatedVal?.score || each.score} (
                                    {updatedVal?.scoreType || each.scoreType}),{' '}
                                    {updatedVal?.mode || each.mode}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className=''>
                              <button
                                className='btn btn-light-primary btn-sm me-2'
                                onClick={() => {
                                  setQualIndex(index)
                                  setQualInitialVals(
                                    Object.keys(updatedVal).length > 0 ? updatedVal : each
                                  )
                                  editQualification1()
                                }}
                              >
                                <KTIcon iconName='pencil' className='fs-2' />
                                Edit
                              </button>
                              <button
                                className='btn btn-light-danger btn-sm ms-2'
                                onClick={() => deleteQualification(index, 'qualifications')}
                              >
                                <KTIcon iconName='trash' className='fs-2' />
                              </button>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ) : null
                  })
                ) : (
                  <>
                    <div className='alert alert-danger d-flex align-items-center p-2 mt-5'>
                      <KTIcon iconName='information-2' className='fs-2 text-danger me-2' />
                      <div className='d-flex flex-column'>
                        <span className='fs-6 text-dark'>
                          It is mandatory that you add at least{' '}
                          <span className='fw-semibold'>ONE</span> educational qualification.
                        </span>
                      </div>
                    </div>
                    <div className='alert alert-primary d-flex align-items-center p-2 mt-5 mb-10'>
                      <KTIcon iconName='information-2' className='fs-2 text-primary me-2' />
                      <div className='d-flex flex-column'>
                        <span className='fs-6 text-dark'>
                          To add an educational Qualification, use the{' '}
                          <span className='fw-semibold'>Add</span> button above.
                        </span>
                      </div>
                    </div>
                  </>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <div className='d-flex justify-content-between mt-10'>
          <h3 className='fw-semibold'>Certifications</h3>
          <button
            className='btn btn-light-primary btn-sm'
            // onClick={() => setShowCertificationModal(true)}
            onClick={() => {
              AddNewCertification()
            }}
          >
            <KTIcon iconName='plus' className='fs-2' />
            Add
          </button>
        </div>

        <DragDropContext onDragEnd={onDragEndCertifications}>
          <Droppable droppableId='certifications'>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {certifications.length ? (
                  certifications.map((each: any, index: number) => {
                    if (each.status && each.status === 'deleted') {
                      return null
                    }
                    const updatedVal = correctionCertifications[each.id] || {}
                    return each.id ? (
                      <Draggable key={each.id} draggableId={each.id.toString()} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`card bg-light border p-5 d-flex flex-row justify-content-between align-items-center mt-5 ${
                              snapshot.isDragging ? 'dragging' : ''
                            }`}
                          >
                            <div className='w-75 d-flex align-items-center'>
                              <svg
                                height='24px'
                                viewBox='0 -960 960 960'
                                width='24px'
                                fill='#e5e5e5'
                              >
                                <path d='M160-360v-80h640v80H160Zm0-160v-80h640v80H160Z' />
                              </svg>
                              <div className='d-flex flex-row align-items-center'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  height='54px'
                                  viewBox='0 -960 960 960'
                                  width='54px'
                                  fill='#5f6368'
                                >
                                  <path d='m387-412 35-114-92-74h114l36-112 36 112h114l-93 74 35 114-92-71-93 71ZM240-40v-309q-38-42-59-96t-21-115q0-134 93-227t227-93q134 0 227 93t93 227q0 61-21 115t-59 96v309l-240-80-240 80Zm240-280q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70ZM320-159l160-41 160 41v-124q-35 20-75.5 31.5T480-240q-44 0-84.5-11.5T320-283v124Zm160-62Z' />
                                </svg>
                                <div className='text-muted ms-5'>
                                  <h4 className='fw-semibold m-0'>
                                    {updatedVal.courseName || each.courseName}
                                  </h4>
                                  <span className='m-0'>{updatedVal.issuer || each.issuer}</span>
                                  <br />
                                  <span className='m-0'>
                                    {updatedVal.yearOfCompletion || each.yearOfCompletion},{' '}
                                    {updatedVal.mode || each.mode}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className=''>
                              <button
                                className='btn btn-light-primary btn-sm me-2'
                                onClick={() => {
                                  setCertificateIndex(index)
                                  setCertificateInitialVals(
                                    Object.keys(updatedVal).length > 0 ? updatedVal : each
                                  )
                                  editCertification1()
                                }}
                              >
                                <KTIcon iconName='pencil' className='fs-2' />
                                Edit
                              </button>
                              <button
                                className='btn btn-light-danger btn-sm ms-2'
                                onClick={() => deleteQualification(index, 'certifications')}
                              >
                                <KTIcon iconName='trash' className='fs-2' />
                              </button>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ) : null
                  })
                ) : (
                  <div className='alert alert-primary d-flex align-items-center p-2 mt-5 mb-10'>
                    <KTIcon iconName='information-2' className='fs-2 text-primary me-2' />
                    <div className='d-flex flex-column'>
                      <span className='fs-6 text-dark'>
                        To add a Certificate Course, use the above{' '}
                        <span className='fw-semibold'>Add</span> button.
                      </span>
                    </div>
                  </div>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <div className='d-flex justify-content-between mt-10'>
          <h3 className='fw-semibold'>Employment</h3>
          {/* {isFresher === 'No' && ( */}
          <button className='btn btn-light-primary btn-sm' onClick={() => setShowExpModal(true)}>
            <KTIcon iconName='plus' className='fs-2' />
            Add
          </button>
          {/* )} */}
        </div>
        <div className='card mt-5 p-3 d-flex flex-row justify-content-between align-items-center'>
          <div className=' d-flex flex-row align-items-center'>
            <KTIcon iconName='information-2' className='fs-2 me-2' />
            <div>
              <h4 className='fs-5 fw-semibold m-0'>
                Are you a Fresher/Candidate with NO experience?
              </h4>
              <span className='fs-7 text-muted'>
                Select this ONLY if this is your first employment
              </span>
            </div>
          </div>
          <div>
            <div className='d-flex form-floating flex-fill'>
              <div className='form-check form-check-solid mb-0'>
                <label className='form-check-label'>
                  <input
                    className='form-check-input'
                    type='radio'
                    // name={item.field_name}
                    value='Yes'
                    checked={isFresher === 'Yes'}
                    onChange={() => checkIsExperienceAvail()}
                    // onChange={() => updateIsFresher('Yes')}
                    style={{backgroundColor: isFresher === 'Yes' ? 'var(--bs-success)' : ''}}
                  />
                  <span className={`me-4 fw-semibold ${isFresher === 'Yes' ? 'text-success' : ''}`}>
                    Yes
                  </span>
                </label>
              </div>
              <div className='form-check form-check-solid mb-0'>
                <label className='form-check-label'>
                  <input
                    className='form-check-input'
                    type='radio'
                    // name={item.field_name}
                    value='No'
                    checked={isFresher === 'No'}
                    // onChange={handleChange}
                    onChange={() => updateIsFresher('No')}
                    style={{backgroundColor: isFresher === 'No' ? 'var(--bs-danger)' : ''}}
                  />
                  <span className={`me-4 fw-semibold ${isFresher === 'No' ? 'text-danger' : ''}`}>
                    No
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <DragDropContext onDragEnd={onDragEndExperiences}>
          <Droppable droppableId='certifications'>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {/* !isFresher && */}
                {/* experiences?.map((each: any, index: number) */}
                {experiences.length ? (
                  experiences?.map((each: any, index: number) => {
                    if (each.status && each.status === 'deleted') {
                      return null
                    }
                    const updatedVal = correctionExperiences[each.id] || {}
                    return each.id ? (
                      <Draggable key={each.id} draggableId={each.id.toString()} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`card p-5 d-flex flex-row justify-content-between align-items-center mt-5 ${
                              snapshot.isDragging ? 'dragging' : ''
                            }`}
                          >
                            <div className='w-75 d-flex align-items-center'>
                              <svg
                                height='24px'
                                viewBox='0 -960 960 960'
                                width='24px'
                                fill='#e5e5e5'
                              >
                                <path d='M160-360v-80h640v80H160Zm0-160v-80h640v80H160Z' />
                              </svg>
                              <div className='d-flex flex-row align-items-center'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  height='54px'
                                  viewBox='0 -960 960 960'
                                  width='54px'
                                  fill='#5f6368'
                                >
                                  <path d='M160-200v-440 440-15 15Zm0 80q-33 0-56.5-23.5T80-200v-440q0-33 23.5-56.5T160-720h160v-80q0-33 23.5-56.5T400-880h160q33 0 56.5 23.5T640-800v80h160q33 0 56.5 23.5T880-640v171q-18-13-38-22.5T800-508v-132H160v440h283q3 21 9 41t15 39H160Zm240-600h160v-80H400v80ZM720-40q-83 0-141.5-58.5T520-240q0-83 58.5-141.5T720-440q83 0 141.5 58.5T920-240q0 83-58.5 141.5T720-40Zm20-208v-112h-40v128l86 86 28-28-74-74Z' />
                                </svg>
                                <div className='text-muted ms-5'>
                                  <span className='m-0'>
                                    {updatedVal.designation || each.designation},{' '}
                                    {updatedVal.workType || each.workType}
                                  </span>
                                  {/* <br />
                            <span className='m-0'>{each.workType}</span> */}
                                  <h4 className='fw-semibold m-0'>
                                    {updatedVal.company || each.company}
                                  </h4>
                                  <span className='m-0'>
                                    {updatedVal.startDate || each.startDate}{' '}
                                    {updatedVal.isCurrentJob || each.isCurrentJob
                                      ? '(Active)'
                                      : `to ${updatedVal.endDate || each.endDate}`}
                                  </span>
                                  <br />
                                  <span className='m-0'>
                                    CTC: {updatedVal.currency || each?.currency} (
                                    {decodeURI(updatedVal.currencyIcon || each?.currencyIcon)}){' '}
                                    {updatedVal.annualCTC || each.annualCTC} per annum
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className=''>
                              <button
                                className='btn btn-light-primary btn-sm me-2'
                                onClick={() => {
                                  setExperienceIndex(index)
                                  setExperienceInitialVals(
                                    Object.keys(updatedVal).length > 0 ? updatedVal : each
                                  )
                                  editExperience()
                                }}
                              >
                                <KTIcon iconName='pencil' className='fs-2' />
                                Edit
                              </button>
                              <button
                                className='btn btn-light-danger btn-sm ms-2'
                                onClick={() => deleteQualification(index, 'experiences')}
                              >
                                <KTIcon iconName='trash' className='fs-2' />
                              </button>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ) : null
                  })
                ) : !experiences.length && isFresher === 'No' ? (
                  <div className='alert alert-primary d-flex align-items-center p-2 mt-5'>
                    <KTIcon iconName='information-2' className='fs-2 text-primary me-2' />
                    <div className='d-flex flex-column'>
                      <span className='fs-6 text-dark'>
                        To add your employment information, use the{' '}
                        <span className='fw-semibold'>Add</span> button above.
                      </span>
                    </div>
                  </div>
                ) : null}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <div className='w-100 d-flex flex-row justify-content-center mt-5'>
          <button
            className='btn btn-light btn-md me-2'
            onClick={() => {
              setEditMode('educationEmployment')
            }}
          >
            Cancel
          </button>
          <button
            className='btn btn-primary btn-md'
            type='submit'
            onClick={async () => {
              if (qualifications.length < 1 || (isFresher === 'No' && !experiences.length)) {
                setValidationModal(true)
              } else {
                const submitBtn = document.getElementById('custom_fields_submit_emp_edu')
                if (submitBtn) {
                  submitBtn.click()
                }
                // try {
                //   await updateData({
                //     ...candidateData,
                //     locked_sections: {...filledSections, employment_education: true},
                //   })
                //   setFilledSections((prev) => ({
                //     ...prev,
                //     employment_education: true,
                //   }))
                // } catch (error) {
                //   console.error('Error updating data:', error)
                //   UseToast(
                //     'Failed to update data. Please try again.',
                //     {position: 'bottom-right', theme: 'colored', autoClose: false},
                //     'error'
                //   )
                // }
              }
            }}
          >
            {postLoading ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : (
              'Save Information'
            )}
          </button>
        </div>
      </>
    )
  }

  return (
    <div>
      <AddNewQualification
        showModal={showModal}
        setShowModal={setShowModal}
        addQualification={addQualification}
        editQualification={editQualification}
        type={qualType}
        index={qualIndex}
        initialVals={qualInitialVals}
      />
      <AddCertification
        showModal={showCertificationModal}
        setShowModal={setShowCertificationModal}
        addQualification={addQualification}
        editCertification={editQualification}
        type={certificateType}
        index={certificateIndex}
        initialVals={certificateInitialVals}
        timeStamp={Date.now()}
      />
      <AddExperience
        showModal={showExpModal}
        setShowModal={setShowExpModal}
        addQualification={addQualification}
        editQualification={editQualification}
        type={experienceType}
        index={experienceIndex}
        initialVals={experienceInitialVals}
        isFresher={isFresher}
        timeStamp={Date.now()}
      />

      {getCurrentUI()}
      <Modal show={validationModal} onHide={handleValidationModalClose} centered>
        <Modal.Header className='py-5'>
          <div className='w-100 d-flex justify-content-between align-items-center'>
            <h4 className='fw-semibold'>Warning</h4>
            <button
              type='button'
              className='d-block btn btn-sm btn-icon btn-light'
              onClick={handleValidationModalClose}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className='py-3'>
          <h4 className='fw-normal my-1'>{getModalInfoMsg()}</h4>
        </Modal.Body>
        <Modal.Footer className='py-3'>
          <button
            className='btn btn-light-primary btn-sm'
            onClick={() => {
              handleValidationModalClose()
            }}
          >
            Ok
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={isModalOpen} onHide={handleClose} centered>
        <Modal.Header className='py-5'>
          <div className='w-100 d-flex justify-content-between align-items-center'>
            <h4 className='fw-semibold'>Warning</h4>
            <button
              type='button'
              className='d-block btn btn-sm btn-icon btn-light'
              onClick={handleClose}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className='py-3'>
          <h5 className='fw-normal my-1'>You have already added work experience.</h5>
          <h5 className='fw-normal my-1'>
            You will not be able to switch to a fresher candidate. To proceed further, please delete
            all work experience entries.
          </h5>
        </Modal.Body>
        <Modal.Footer className='py-3'>
          <button className='btn btn-light btn-sm' onClick={handleClose}>
            Ok
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export {EmploymentAndEducation}
