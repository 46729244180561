import {Field, ErrorMessage} from 'formik'
import GetLookupDD from './GetLookupDD'

export const RenderFields = (
  dummyData: any = [],
  setFieldValue: any,
  values: any,
  errors: any,
  touched: any
) => {
  return dummyData.map((item: any, index: number) => {
    const isValid = touched[item.id] && !errors[item.id] && item.is_required
    const isInvalid = touched[item.id] && errors[item.id] && item.is_required

    const confirmValidation =
      touched[`${item.id}_confirmation`] && !errors[`${item.id}_confirmation`] && item.is_required
    const confirmInvalidation =
      touched[`${item.id}_confirmation`] && errors[`${item.id}_confirmation`] && item.is_required

    switch (item.field_type) {
      case 'Separator':
        return (
          <h3 key={item.id} className={`w-100 mb-3 ${index === 0 ? '' : 'mt-3'}`}>
            <p
              className='alert bg-light text-center text-muted'
              style={{
                width: '100%',
                verticalAlign: 'middle',
                fontSize: '12px',
                textTransform: 'uppercase',
              }}
            >
              <span className='fw-semibold'>{item.field_name}</span>
            </p>
          </h3>
        )
      case 'Single Line':
        return item.require_confirmation ? (
          <div key={item.id} className='w-100 d-flex flex-column flex-md-row mb-4'>
            <div className='w-100 w-md-50 px-2'>
              <label
                htmlFor={item.id}
                className={`fs-6 fw-semibold form-label ${item.is_required ? 'required' : ''}`}
              >
                <span>{item.field_name}</span>
              </label>
              <Field
                type='password'
                // type='text'
                className={`form-control ${isInvalid ? 'is-invalid' : ''} ${
                  isValid ? 'is-valid' : ''
                }`}
                id={item.id}
                name={item.id}
                placeholder={item.prompt}
              />
              <div className='text-danger'>
                <ErrorMessage name={item.id} />
              </div>
            </div>

            {item.require_confirmation && (
              <div className='w-100 w-md-50 px-2'>
                <label
                  htmlFor={`${item.id}_confirmation`}
                  className={`fs-6 fw-semibold form-label ${item.is_required ? 'required' : ''}`}
                >
                  <span>{item.confirmation_prompt}</span>
                </label>
                <Field
                  type='text'
                  className={`form-control ${confirmInvalidation ? 'is-invalid' : ''} ${
                    confirmValidation ? 'is-valid' : ''
                  }`}
                  id={`${item.id}_confirmation`}
                  name={`${item.id}_confirmation`}
                  placeholder={item.confirmation_prompt}
                />
                <div className='text-danger'>
                  <ErrorMessage name={`${item.id}_confirmation`} />
                </div>
              </div>
            )}
          </div>
        ) : (
          <div key={item.id} className='w-100 w-md-50 px-2 mb-4'>
            <label
              htmlFor={item.id}
              className={`fs-6 fw-semibold form-label ${item.is_required ? 'required' : ''}`}
            >
              <span className=''>{item.field_name}</span>
            </label>
            <Field
              type='text'
              className={`form-control ${isInvalid ? 'is-invalid' : ''} ${
                isValid ? 'is-valid' : ''
              }`}
              id={item.id}
              name={item.id}
              placeholder={item.prompt}
            />
            <div className='text-danger'>
              <ErrorMessage name={item.id} />
            </div>
          </div>
        )
      case 'Multi Line':
        return (
          <div key={item.id} className='w-100 w-md-50 d-flex flex-column mb-4 fv-row px-2'>
            <label
              htmlFor={item.id}
              className={`fs-6 fw-semibold form-label ${item.is_required ? 'required' : ''}`}
            >
              <span className=''>{item.field_name}</span>
            </label>
            <Field
              as='textarea'
              className={`form-control ${isInvalid ? 'is-invalid' : ''} ${
                isValid ? 'is-valid' : ''
              }`}
              id={item.id}
              name={item.id}
              placeholder={item.prompt}
              row={3}
            />
            <div className='text-danger'>
              <ErrorMessage name={item.id} />
            </div>
          </div>
        )
      case 'Numeric':
        return (
          <div key={item.id} className='w-100 w-md-50 d-flex flex-column mb-4 fv-row px-2'>
            <label
              htmlFor={item.id}
              className={`fs-6 fw-semibold form-label ${item.is_required ? 'required' : ''}`}
            >
              <span className=''>{item.field_name}</span>
            </label>
            <Field
              type='number'
              className={`form-control ${isInvalid ? 'is-invalid' : ''} ${
                isValid ? 'is-valid' : ''
              }`}
              id={item.id}
              name={item.id}
              placeholder={item.prompt}
              onKeyDown={(e: any) => {
                if (e.key === 'e' || e.key === 'E') {
                  e.preventDefault()
                }
              }}
            />
            <div className='text-danger'>
              <ErrorMessage name={item.id} />
            </div>
          </div>
        )
      case 'Date Time':
        return (
          <div key={item.id} className='w-100 w-md-50 mb-4 px-2'>
            <label
              htmlFor={item.id}
              className={`fs-6 fw-semibold form-label ${item.is_required ? 'required' : ''}`}
            >
              {item.prompt}
            </label>
            <Field
              type='date'
              id={item.id}
              name={item.id}
              className={`form-control ${isInvalid ? 'is-invalid' : ''} ${
                isValid ? 'is-valid' : ''
              }`}
            />
            <div className='text-danger'>
              <ErrorMessage name={item.id} />
            </div>
          </div>
        )
      case 'Dropdown':
        return (
          <GetLookupDD
            id={item?.validations?.dropdown_type}
            item={item}
            isInvalid={isInvalid}
            isValid={isValid}
            key={item.id}
          />
        )
      case 'Toggle':
        return (
          <div key={item.id} className='w-100 w-md-50 mb-4 px-2'>
            <label className={`fs-6 fw-semibold form-label ${item.is_required ? 'required' : ''}`}>
              <span className=''>{item.prompt}</span>
            </label>
            <div className='d-flex form-floating flex-fill'>
              <div className='form-check form-check-solid mb-0'>
                <label className='form-check-label'>
                  <Field
                    className='form-check-input'
                    type='radio'
                    name={item.id}
                    value='Yes'
                    checked={values[item.id] === 'Yes'}
                    style={{backgroundColor: values[item.id] === 'Yes' ? 'var(--bs-success)' : ''}}
                  />
                  <span
                    className={`me-4 fw-semibold ${
                      values[item.id] === 'Yes' ? 'text-success' : ''
                    }`}
                  >
                    {item.validations.label_1}
                  </span>
                </label>
              </div>
              <div className='form-check form-check-solid mb-0'>
                <label className='form-check-label'>
                  <Field
                    className='form-check-input'
                    type='radio'
                    name={item.id}
                    value='No'
                    checked={values[item.id] === 'No'}
                    style={{backgroundColor: values[item.id] === 'No' ? 'var(--bs-danger)' : ''}}
                  />
                  <span
                    className={`me-4 fw-semibold ${values[item.id] === 'No' ? 'text-danger' : ''}`}
                  >
                    {item.validations.label_2}
                  </span>
                </label>
              </div>
            </div>
            <div className='text-danger'>
              <ErrorMessage name={item.id} />
            </div>
          </div>
        )
      case 'Media':
        return (
          <div key={item.id} className='w-100 w-md-50 mb-4 px-2'>
            <label
              htmlFor={item.id}
              className={`fs-6 fw-semibold form-label ${item.is_required ? 'required' : ''}`}
            >
              {item.prompt}
            </label>
            <input
              id={item.id}
              name={item.id}
              type='file'
              className={`form-control ${isInvalid ? 'is-invalid' : ''} ${
                isValid ? 'is-valid' : ''
              }`}
              onChange={(event) => {
                const files = event.currentTarget.files
                if (files && files.length > 0) {
                  const file = files[0]
                  setFieldValue(item.id, file)
                }
              }}
            />
            <div className='text-danger'>
              <ErrorMessage name={item.id} />
            </div>
          </div>
        )
      default:
        return null
    }
  })
}
