import {useState} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {motion, AnimatePresence} from 'framer-motion'
import {KTIcon} from '../../../../_metronic/helpers'
import {Link, useLocation} from 'react-router-dom'
import usePostApi from '../../custom_hooks/usePostApi'
import secureLocalStorage from 'react-secure-storage'
import {useAuth} from '../core/Auth'

const step3Schema = Yup.object().shape({
  newPassword: Yup.string()
    .required('New Password is required')
    .min(8, 'Password must be 8 characters'),
  confirmPassword: Yup.string()
    .test('passwords-match', 'Passwords must match', function (this: any, value: any) {
      return this.parent.newPassword === value
    })
    .required('Confirm New Password is required'),
})

const initialValues = {
  newPassword: '',
  confirmPassword: '',
}

const InviteAccept = () => {
  const {saveAuth, setCurrentUser} = useAuth()
  const {execute} = usePostApi()
  const [showNewPW, setNewPW] = useState(false)
  const [showConfirmPW, setConfirNewPW] = useState(false)
  const [loginFailed, setLoginFailed] = useState(false)
  const [loginError, setLoginError] = useState(null)

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const u: string = searchParams?.get('u') || ''
  const t: string = searchParams?.get('t') || ''
  const b: string = searchParams?.get('b') || ''

  const toggleNewPWVisibility = () => {
    setNewPW(!showNewPW)
  }

  const toggleConfirmPWVisibility = () => {
    setConfirNewPW(!showConfirmPW)
  }

  const scrambleX = (str: string, numTimes = 2) => {
    let encoded = str
    for (let i = 0; i < numTimes; i++) {
      encoded = btoa(encoded)
    }
    return encoded
  }

  const onSubmit = async (values: any) => {
    try {
      const url = `/lead/verify/invite`
      const formData = {
        verify_user: u,
        verify_token: t,
        verify_type: 'LNK',
        password: scrambleX(values.confirmPassword),
      }

      const response = await execute(url, 'POST', formData)
      const userInfo = {
        auth_token: response.data.token,
        user_id: response.data.user_id,
      }

      secureLocalStorage.setItem('user_info', userInfo)

      saveAuth(response)
      setCurrentUser(response.data)

      //window.location.reload()
    } catch (error: any) {
      if (error.response) {
        setLoginFailed(error.response.data.status === false && true)
        setLoginError(error.response.data.status_message)
      }

      saveAuth(undefined)
    }
  }

  const decodedUrl = decodeURIComponent(b || '')
  return (
    <AnimatePresence mode='wait'>
      <motion.div
        initial={{y: 10, opacity: 0}}
        animate={{y: 0, opacity: 1}}
        exit={{y: -10, opacity: 0}}
        transition={{duration: 0.5}}
      >
        <Formik validationSchema={step3Schema} initialValues={initialValues} onSubmit={onSubmit}>
          <Form className='w-350px d-flex flex-column justify-content-center align-items-center'>
            <h3 className='text-center text-dark fw-semibold mb-3'>
              Accept Invite <span className='text-muted fs-5'>from</span>{' '}
              <span className='text-primary'>{decodedUrl}</span>
            </h3>

            <div className='text-center w-350px text-gray-500 t ext-center fw-semibold fs-6 mt-5'>
              For security reasons, you need to set a password before you access the POD.
            </div>

            <div className='w-100 fv-row mb-8 mt-3'>
              <p className='mb-0 form-label fs-6 fw-semibold text-dark'>Password</p>
              <div className='position-relative'>
                <Field
                  name='newPassword'
                  type={showNewPW ? 'text' : 'password'}
                  autoFocus
                  className='form-control pr-10'
                  placeholder='Password'
                />
                <span onClick={toggleNewPWVisibility}>
                  <KTIcon iconName={showNewPW ? 'eye-slash' : 'eye'} className='fs-2 icon' />
                </span>
              </div>
              <ErrorMessage name='newPassword' component='div' className='text-danger' />
            </div>

            <div className='w-100 fv-row mb-8'>
              <p className='mb-0 form-label fs-6 fw-semibold text-dark'>Confirm Password</p>
              <div className='position-relative'>
                <Field
                  name='confirmPassword'
                  type={showConfirmPW ? 'text' : 'password'}
                  className='form-control pr-10'
                  placeholder='Confirm Password'
                />
                <span onClick={toggleConfirmPWVisibility}>
                  <KTIcon iconName={showConfirmPW ? 'eye-slash' : 'eye'} className='fs-2 icon' />
                </span>
              </div>
              <ErrorMessage name='confirmPassword' component='div' className='text-danger' />
            </div>

            <div className='d-flex flex-wrap justify-content-center pb-lg-0 mt-10'>
              <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
                <span className='indicator-label'>Submit</span>
              </button>
              <Link to='/auth/login'>
                <button type='button' className='btn btn-light'>
                  Cancel
                </button>
              </Link>
            </div>
            {loginFailed && (
              <div className='w-350px alert border border-dashed border-gray-300 rounded px-5 py-3 badge-light-danger d-flex align-items-center p-2 mt-4 d-flex flex-row align-items-center'>
                <KTIcon iconName='information' className='fs-3 text-danger me-1' />
                <span className='fs-6 text-dark'>{loginError}</span>
              </div>
            )}
          </Form>
        </Formik>
      </motion.div>
    </AnimatePresence>
  )
}

export default InviteAccept
