import React, {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import clsx from 'clsx'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import TinyEditor from './TInyEditor'
import './index.css'
import usePostApi from '../custom_hooks/usePostApi'
import {FindPodIdByCode} from '../FindPodIdByPodCode'
import TextEditor from './QuillEditor'
import {UseToast} from '../useToast'
import {useThemeMode} from '../../../_metronic/partials'

type Data = {
  policy_name: string
  policy_url: string
  policy_code: string
  policy_description: string
  status: boolean
  policy_wordings: string
  showLinkField: boolean
  email_id: string
  policy_declaration: string
}

interface Props {
  showModal: boolean
  setShowModal(val: boolean): void
  fetchData(): void
}

function AddNewPolicyModal(props: Props) {
  const {execute, postLoading} = usePostApi()
  const podId = FindPodIdByCode()
  const [currentStep, setCurrentStep] = useState(0)
  const [data, setData] = useState<Data>({
    policy_name: '',
    policy_url: '',
    policy_code: '',
    policy_description: '',
    status: true,
    policy_wordings: '',
    showLinkField: true,
    email_id: '',
    policy_declaration: '',
  })

  const [urlOrEmailValidation, setUrlOrEmailValidation] = useState(true)
  const [validationErrMsg, setValidationErrMsg] = useState('')

  const set2ndIndex = () => {
    setCurrentStep(1)
  }

  const set3rIndex = () => {
    // setCurrentStep(2)
  }

  const regex =
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/

  const isValidUrl = (url: string) => {
    return regex.test(url)
  }

  // Function to validate email
  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleValidation = () => {
    if (data.showLinkField) {
      // If showLinkField is true, validate policy_url
      if (data.policy_url !== '') {
        if (!isValidUrl(data.policy_url)) {
          // alert('Please enter a valid URL for the policy.')
          setUrlOrEmailValidation(false)
          setValidationErrMsg('Please enter a valid URL for the policy.')
          return false
        }
      }
    } else {
      // If showLinkField is false, validate email_id
      if (data.email_id !== '') {
        if (!isValidEmail(data.email_id)) {
          setUrlOrEmailValidation(false)
          setValidationErrMsg('Please enter a valid email address.')
          // alert('Please enter a valid email address.')
          return false
        }
      }
    }
    setValidationErrMsg('')
    setUrlOrEmailValidation(true)
    return true
  }

  const [policyLinkIdErr, setPolicyLinkIdErr] = useState(false)

  useEffect(() => {
    if (data.showLinkField) {
      if (data.policy_url !== '') {
        setPolicyLinkIdErr(false)
      }
    } else {
      if (data.email_id !== '') {
        setPolicyLinkIdErr(false)
      }
    }
  }, [data.policy_url, data.email_id])

  const steps = [
    <Step1 data={data} setData={setData} set2ndIndex={set2ndIndex} />,
    <Step2
      data={data}
      setData={setData}
      set3rdIndex={set3rIndex}
      validErr={urlOrEmailValidation}
      errMsg={validationErrMsg}
      setValidErr={setUrlOrEmailValidation}
      handleValidation={handleValidation}
      policyLinkIdErr={policyLinkIdErr}
      setPolicyLinkIdErr={setPolicyLinkIdErr}
    />,
  ]

  const prevStep = (): void => {
    if (currentStep !== 0) {
      setCurrentStep(currentStep - 1)
    }
  }

  const addNewPolicy = async (formData: Data) => {
    if (formData.policy_url === '' && formData.email_id === '') {
      setPolicyLinkIdErr(true)
      return
    }
    const updatedData = {
      pod_id: podId,
      policy_name: formData.policy_name,
      policy_url: formData.showLinkField ? formData.policy_url : formData.email_id,
      policy_description: formData.policy_description,
      policy_wordings: formData.policy_wordings,
      status: formData.status === true ? 1 : 0,
      policy_code: formData.policy_code,
      declaration: formData.policy_declaration
        ? formData.policy_declaration
        : `I hereby declare that I have read, understood and agree to the terms & policy wordings provided in the ${formData.policy_name}`,
    }

    try {
      const url = '/pod/policy/add'

      const response = await execute(url, 'POST', updatedData, 'post')
      if (response?.status === true) {
        // setAddNewMemberPopover(null)
        props.fetchData()
        props.setShowModal(false)
        setData({
          policy_name: '',
          policy_url: '',
          policy_code: '',
          policy_description: '',
          status: true,
          policy_wordings: '',
          showLinkField: true,
          email_id: '',
          policy_declaration: '',
        })
        setCurrentStep(0)
      }
      UseToast(
        response?.status_message,
        {
          position: 'bottom-right',
          autoClose: false,
          theme: 'colored',
        },
        'success'
      )
    } catch (error: any) {
      console.error('Error:', error)

      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          autoClose: false,
          theme: 'colored',
        },
        'error'
      )
    }
  }

  const nextStep = () => {
    if (currentStep === steps.length - 1) {
      const isValid = handleValidation()
      if (isValid) {
        addNewPolicy(data)
      }
    }
    if (currentStep === 0) {
      const step1Submit = document.getElementById('step1_submit_btn')
      if (step1Submit) {
        step1Submit.click()
      }
    }
    // else if (currentStep === steps.length - 1) {
    //   const step1Submit = document.getElementById('step1_submit_btn')
    //   if (step1Submit) {
    //     step1Submit.click()
    //   }
    // }
  }

  const getButtonContent = () => {
    if (postLoading && currentStep === steps.length - 1) {
      return (
        <span className='indicator-progress' style={{display: 'block'}}>
          Please wait...{' '}
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      )
    } else if (currentStep === steps.length - 1) {
      return (
        <>
          Create Policy
          <KTIcon iconName='arrow-right' className='fs-3 ms-1' />
        </>
      )
    } else {
      return (
        <>
          Continue
          <KTIcon iconName='arrow-right' className='fs-3 ms-1' />
        </>
      )
    }
  }

  return (
    <Modal
      show={props.showModal}
      onHide={() => props.setShowModal(false)}
      centered
      dialogClassName='custom-modal modal-dialog-centered'
      contentClassName='custom-modal-content'
    >
      <Modal.Body className='overflow-auto w-100'>
        <div className='card py-0 overflow-auto'>
          <div className='w-100 m-auto'>
            <div className='d-flex justify-content-between mb-10'>
              <h3 className='fs-3 fw-semibold'>Create New Policy</h3>
              <button
                type='button'
                className='d-block btn btn-sm btn-icon btn-light'
                onClick={() => props.setShowModal(false)}
              >
                <KTIcon iconName='cross' className='fs-1' />
              </button>
            </div>
            {/* <div className='w-100 d-flex flex-column flex-lg-row justify-content-between'> */}
            <div className='w-100 row m-0'>
              <div className='col-lg-5 p-0 mb-5 mb-lg-0'>
                <div className=''>
                  <div className='d-flex'>
                    <div
                      style={{borderRadius: '10px'}}
                      className={clsx(
                        'w-40px h-40px btn d-flex justify-content-center align-items-center',
                        {
                          'btn-primary': currentStep === 0,
                          'btn-light-primary': currentStep !== 0,
                        }
                      )}
                    >
                      {currentStep === 0 ? 1 : <i className='stepper-check fas fa-check'></i>}
                    </div>
                    <div className='ms-5'>
                      <h6
                        className={clsx('mb-0 fw-semibold', {
                          'text-muted': currentStep >= 0,
                        })}
                      >
                        Step 1
                      </h6>
                      <p
                        className={clsx('fs-6 mb-0', {
                          'text-muted': currentStep >= 0,
                        })}
                      >
                        About Policy
                      </p>
                    </div>
                  </div>
                  <div className='ms-6 h-30px border-left border-dashed border-muted border-top-0 border-bottom-0 border-right-0 border-left-1'></div>
                  <div className='d-flex'>
                    <div
                      style={{borderRadius: '10px'}}
                      className={clsx(
                        'w-40px h-40px btn d-flex justify-content-center align-items-center',
                        {
                          'btn-primary': currentStep === 1,
                          'btn-light-primary': currentStep !== 1,
                        }
                      )}
                    >
                      {currentStep > 1 ? <i className='stepper-check fas fa-check'></i> : 2}
                    </div>
                    <div className='ms-5'>
                      <h6
                        className={clsx('mb-0 fw-semibold', {
                          'text-muted': currentStep >= 1,
                        })}
                      >
                        Step 2
                      </h6>
                      <p
                        className={clsx('fs-6', {
                          'text-muted': currentStep >= 1,
                        })}
                      >
                        Link or Wordings
                      </p>
                    </div>
                  </div>
                  {/* Add more steps here */}
                </div>
              </div>
              <div className='col-lg-7 p-0 m-0'>{steps[currentStep]}</div>
              {/* <div className='col-lg-7 p-0 m-0'>{steps[currentStep]}</div> */}
            </div>

            <div className='w-100 d-flex flex-stack mt-5'>
              <div className='mr-2'>
                {currentStep > 0 && (
                  <button onClick={prevStep} type='button' className='btn btn-sm btn-light me-3'>
                    <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                    Back
                  </button>
                )}
              </div>

              <div>
                <button
                  type='submit'
                  className='btn btn-sm btn-light-primary'
                  onClick={postLoading ? () => {} : nextStep}
                >
                  {getButtonContent()}
                  {/* {currentStep === steps.length - 1 ? 'Create Policy' : 'Continue'} */}
                  {/* <KTIcon iconName='arrow-right' className='fs-3 ms-1' /> */}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const registrationSchema = Yup.object().shape({
  policy_name: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(250, 'Maximum 250 characters')
    .required('Policy Name is required'),
  policy_description: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(250, 'Maximum 250 characters')
    .required('Policy Description is required'),
  policy_code: Yup.string()
    .min(2, 'Minimum 2 characters')
    .max(250, 'Maximum 250 characters')
    .required('Policy Code is required'),
})

interface Step1Props {
  set2ndIndex(): void
  data: Data
  setData: (values: Data) => void
}

const Step1 = (props: Step1Props) => {
  const {mode} = useThemeMode()
  const onSubmit = (values: Partial<Data>) => {
    if (values.policy_declaration === '') {
      props.setData({
        ...props.data,
        ...values,
        policy_declaration: `I hereby declare that I have read, understood and agree to the terms & policy wordings provided in the ${values.policy_name}`,
      })
    } else {
      props.setData({...props.data, ...values})
    }

    props.set2ndIndex()
  }

  return (
    <Formik validationSchema={registrationSchema} initialValues={props.data} onSubmit={onSubmit}>
      {({setFieldValue, values}: any) => {
        return (
          <Form className='w-100'>
            {/* <div className='w-100 d-flex flex-column justify-content-between mb-7 fv-row'> */}
            <div className='w-100 mb-5'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Policy Name</span>
                <i
                  className='fas fa-info-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='The name/title of the policy.'
                ></i>
              </label>
              <Field
                type='text'
                name='policy_name'
                className='form-control form-control-solid'
                placeholder='Enter policy name (e.g., "Privacy Policy")'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='policy_name' />
              </div>
            </div>

            <div className='w-100 d-flex flex-column mb-4 fv-row'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>About {values?.policy_name}</span>
                <i
                  className='fas fa-info-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Provide a short description of the policy and its purpose.'
                ></i>
              </label>
              <Field
                as='textarea'
                className={`form-control form-control-solid`}
                name='policy_description'
                placeholder='Briefly describe the policy'
                rows={3}
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='policy_description' />
              </div>
            </div>

            <div className='w-100 d-flex flex-column flex-lg-row'>
              <div className='me-lg-2 w-100 w-lg-50'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Policy Code</span>
                  <i
                    className='fas fa-info-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='The unique code or identifier for the policy.'
                  ></i>
                </label>
                <Field
                  type='text'
                  name='policy_code'
                  className='form-control form-control-solid'
                  placeholder='Enter policy code'
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='policy_code' />
                </div>
              </div>

              <div className='ms-lg-2 w-100 w-lg-50'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className=''>Policy Status</span>
                  <i
                    className='fas fa-info-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Choose the current status of the policy (Active, Inactive).'
                  ></i>
                </label>

                <div className='form-floating flex-fill'>
                  <div className='form-check form-check-solid form-switch mb-0'>
                    <Field
                      className='form-check-input w-40px h-20px'
                      type='checkbox'
                      name='status'
                      id='toggle'
                      style={{
                        backgroundColor: values.status
                          ? '#009688'
                          : mode === 'dark'
                          ? '#1b1b29'
                          : '#f4f4f4',
                      }}
                    />
                    <span
                      className={`me-4 fw-semibold ${
                        values.status ? 'text-success' : 'text-danger'
                      }`}
                    >
                      {values.status ? 'Active' : 'Inactive'}
                    </span>
                    <ErrorMessage component='div' className='text-danger' name='status' />
                  </div>
                </div>
              </div>
            </div>

            <button
              type='submit'
              className='d-none btn btn-lg btn-primary w-100'
              id='step1_submit_btn'
            >
              Submit
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}

interface Step2Props {
  set3rdIndex(): void
  data: Data
  setData: (values: Data) => void
  validErr: boolean
  errMsg: string
  setValidErr: Dispatch<SetStateAction<boolean>>
  handleValidation(): void
  policyLinkIdErr: boolean
  setPolicyLinkIdErr: Dispatch<SetStateAction<boolean>>
}

const Step2 = (props: Step2Props) => {
  const onChangeWordings = (content: any) => {
    props.setData({...props.data, policy_wordings: content})
  }

  const updateData = (key: any, val: any) => {
    props.setData({...props.data, [key]: val})
  }

  const updateDeclaration = (key: any, val: any) => {
    props.setData({...props.data, [key]: val})
  }

  const [declarationText, setDelarationText] = useState(props.data.policy_declaration)

  // useEffect(() => {
  //   props.handleValidation()
  // }, [props.data.policy_url, props.data.email_id])

  return (
    <div className='w-100'>
      <div className='w-100 d-flex flex-column mb-4 fv-row'>
        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
          <span className=''>Policy Declaration Text</span>
          <i
            className='fas fa-info-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Please provide the policy declaration text for this policy, as it will be used in the confirmation label for employee onboarding.'
          ></i>
        </label>
        <textarea
          className={`form-control form-control-solid`}
          name='policy_declaration'
          value={props.data.policy_declaration}
          onChange={(e: any) => updateData(e.target.name, e.target.value)}
          placeholder='Briefly describe about the policy declaration'
          rows={3}
        />
      </div>
      <div className='mb-5'>
        {props.data.showLinkField ? (
          <div className='w-100'>
            <div className='d-flex justify-content-between align-items-center'>
              <div>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Policy Link (URL)</span>
                  <i
                    className='fas fa-info-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='If the policy is available online, enter the URL here.'
                  ></i>
                </label>
              </div>
              <span
                className='text-primary cursor-pointer'
                onClick={() => {
                  props.setValidErr(true)
                  props.setData({...props.data, showLinkField: false})
                }}
              >
                I don't have a Link?
              </span>
            </div>
            <input
              type='text'
              name='policy_url'
              value={props.data.policy_url}
              className='form-control form-control-solid'
              placeholder='Enter policy link (if available)'
              onChange={(e) => updateData(e.target.name, e.target.value)}
            />
          </div>
        ) : (
          <>
            <div className='d-flex justify-content-between align-items-center'>
              <div>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Legal Email ID</span>
                  <i
                    className='fas fa-info-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Provide legal manager email ID.'
                  ></i>
                </label>
              </div>
              <span
                className='text-primary cursor-pointer'
                onClick={() => {
                  props.setValidErr(true)
                  props.setData({...props.data, showLinkField: true})
                }}
              >
                I have a Link?
              </span>
            </div>
            <input
              type='text'
              name='email_id'
              value={props.data.email_id}
              className='form-control form-control-solid'
              placeholder='Enter Email ID (if policy link is not available)'
              onChange={(e) => updateData(e.target.name, e.target.value)}
            />
          </>
        )}
        {props.validErr === false && <p className='text-danger'>{props.errMsg}</p>}
        {props.policyLinkIdErr === true && (
          <p className='text-danger'>Policy Link/Legal Email ID is Required</p>
        )}
      </div>
      <>
        <div>
          <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
            <span className=''>Policy Wordings</span>
            <i
              className='fas fa-info-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Provide the full content or text of the policy.'
            ></i>
          </label>
        </div>
        <TextEditor wordings={props.data.policy_wordings} onChangeWordings={onChangeWordings} />
      </>
    </div>
  )
}

export default AddNewPolicyModal
