import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import usePostApi from '../../../custom_hooks/usePostApi'
import {KTIcon} from '../../../../../_metronic/helpers'
import {UseToast} from '../../../useToast'
import {useThemeMode} from '../../../../../_metronic/partials'
const EditQualification = (props) => {
  const {id, refreshData, ClosePopover, name, description, status, qualification_pod_id} =
    props.props
  const intl = useIntl()
  const {execute, postLoading} = usePostApi()
  const {mode} = useThemeMode()
  const [qualificationName, setQualificationName] = useState('')
  const [qualificationDescription, setQualificationDescription] = useState('')
  const [isChecked, setIsChecked] = useState(true)
  useEffect(() => {
    if (props.props) {
      setQualificationName(name)
      setQualificationDescription(description)
      setIsChecked(status === 1)
    }
  }, [])

  const validationSchemaEdit = Yup.object().shape({
    qualificationName: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .required('Qualification Name is required'),
    qualificationDescription: Yup.string()
      .min(3, 'Minimum 3 characters')
      .required('Qualification Description is required'),
    status: Yup.string().required(intl.formatMessage({id: 'LABEL.STATUS_REQUIRED'})),
  })

  const initialValues = {
    qualificationName: qualificationName || '',
    qualificationDescription: qualificationDescription || '',
    status: isChecked || '',
  }

  const handleEditSubmit = async (values) => {
    const url = `/pod/qualifications/update`
    try {
      const requestData = {
        qualification_pod_id: qualification_pod_id,
        qualification_id: id,
        qualification_name: values.qualificationName,
        qualification_description: values.qualificationDescription,
        is_active: values.status ? 1 : 0,
      }
      const response = await execute(url, 'POST', requestData, 'post')
      refreshData()
      ClosePopover()
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
    } catch (error) {
      console.error(error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchemaEdit}
        onSubmit={handleEditSubmit}
      >
        {({values, handleChange}) => {
          return (
            <Form>
              <div className='card'>
                <div className='card-header'>
                  <h3 className='card-title fw-semibold fs-3 text-dark'>Edit Qualification</h3>
                  <div className='card-toolbar me-2'>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-active-light me-n5'
                      onClick={() => ClosePopover()}
                    >
                      <KTIcon iconName='cross' className='fs-1' />
                    </button>
                  </div>
                </div>
                <div className='px-7 py-5'>
                  <div className='modal-body position-relative'>
                    <div>
                      <>
                        <div className='mb-4'>
                          <label
                            htmlFor='floatingInput'
                            className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 required'
                          >
                            Qualification Name
                          </label>
                          <Field
                            type='text'
                            className='form-control form-control-solid mb-1'
                            id='floatingInput'
                            placeholder='Enter Qualification Name'
                            name='qualificationName'
                            value={values.qualificationName}
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            component='div'
                            className='text-danger'
                            name='qualificationName'
                          />
                        </div>
                        <div className='mb-4'>
                          <label
                            htmlFor='floatingInput'
                            className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 required'
                          >
                            Qualification Description
                          </label>
                          <Field
                            type='text'
                            className='form-control form-control-solid mb-1'
                            id='floatingInput'
                            placeholder='Enter Qualification Description'
                            name='qualificationDescription'
                            value={values.qualificationDescription}
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            component='div'
                            className='text-danger'
                            name='qualificationDescription'
                          />
                        </div>
                        <div className='form-floating mb-4'>
                          <div className='form-check form-check-solid form-switch'>
                            <Field
                              className='form-check-input w-40px h-20px'
                              type='checkbox'
                              id='toggle'
                              name='status'
                              checked={values.status}
                              onChange={handleChange}
                              style={{
                                backgroundColor: values.status
                                  ? '#009688'
                                  : mode === 'dark'
                                  ? '#1b1b29'
                                  : '#f4f4f4',
                              }}
                            />
                            <span
                              className={`me-4 fw-semibold ${
                                values.status ? 'text-success' : 'text-danger'
                              }`}
                            >
                              {values.status ? 'Active' : 'Inactive'}
                            </span>
                          </div>
                          <ErrorMessage component='div' className='text-danger' name='status' />
                        </div>
                      </>
                    </div>
                  </div>
                  <div className='d-flex justify-content-end'>
                    <button
                      type='reset'
                      className='btn btn-sm btn-light me-2'
                      onClick={() => {
                        ClosePopover()
                      }}
                    >
                      {intl.formatMessage({id: 'LABEL.CANCEL'})}
                    </button>

                    <button type='submit' className='btn btn-sm btn-light-primary'>
                      {postLoading ? (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      ) : (
                        <>
                          <KTIcon iconName='check' className='fs-7' />
                          Save
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export {EditQualification}
