import React from 'react'
import CreateIcon from './add-folder.png'
import PodsListIcon from './create.png'
import ChoosePodIcon from './choice.png'

const PodCreateKM = () => {
  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        <h3 className='fs-3 fw-semibold mb-4'>Create a POD to Start Your Application</h3>
      </div>

      <p className=''>
        Creating a POD to kickstart your application is straightforward! Follow these simple steps
        to get started:
      </p>
      <div className='row'>
        <div className='col-lg-4 p-0'>
          <div className='w-100 h-100 p-5'>
            <div className='shadow-sm p-3 rounded h-100 d-flex flex-column justify-content-center align-items-center'>
              <img src={CreateIcon} className='track-req-icon mb-5' alt='Add Friend' />
              <p className='text-center'>
                Begin by clicking the "+ Create New POD" button located prominently on the
                interface. This initiates the process of creating a new POD for your application.
              </p>
            </div>
          </div>
        </div>
        <div className='col-lg-4 p-0'>
          <div className='w-100 h-100 p-5'>
            <div className='shadow-sm p-3 rounded h-100 d-flex flex-column justify-content-center align-items-center'>
              <img src={PodsListIcon} alt='Track Request' className='track-req-icon mb-5' />
              <p className='text-center'>
                Once you've created your POD, you'll see all available PODs. Here, you can easily
                find the POD you just created among others.
              </p>
            </div>
          </div>
        </div>
        <div className='col-lg-4 p-0'>
          <div className='w-100 h-100 p-5'>
            <div className='shadow-sm p-3 rounded h-100 d-flex flex-column justify-content-center align-items-center'>
              <img src={ChoosePodIcon} className='track-req-icon mb-5' alt='Add Friend' />
              <p className='text-center'>
                In the list of PODs, simply click on the desired POD to navigate directly to its
                details. This allows you to access, manage, and interact with the specific POD and
                its associated resources.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PodCreateKM
