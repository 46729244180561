import React, {useState} from 'react'
import {useParams} from 'react-router-dom'
import {useLocation} from 'react-router-dom'

import {PageTitle} from '../../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import VacanciesTable from './VacanciesTable'
import Context from '../../side-drawer/context'
function VacanciesDataRouting() {
  const intl = useIntl()
  const {pathname} = useLocation()
  const [SideDrawerId, updateId] = useState('0')
  const UpdatingId = (id) => {
    updateId(id)
  }
  const {id, jobJoinCode} = useParams()

  const vacancyAttachments = [
    {
      title: `(${id})`,
      path: `/${id}/vacancies`,
      isSeparator: false,
      isActive: false,
    },
  ]
  const switchMethod = () => {
    switch (pathname) {
      case `/${id}/vacancies`:
        return (
          <>
            <PageTitle breadcrumbs={vacancyAttachments}>Vacancies</PageTitle>
            <VacanciesTable />
          </>
        )

      default:
        return (
          <>
            <PageTitle breadcrumbs={vacancyAttachments}>Vacancies</PageTitle>
            <VacanciesTable />
          </>
        )
    }
  }

  return (
    <>
      <Context.Provider value={{id: SideDrawerId, updateId: UpdatingId}}>
        {switchMethod()}
      </Context.Provider>
    </>
  )
}

export default VacanciesDataRouting
