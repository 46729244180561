import {Modal} from 'react-bootstrap'
import './index.css'
import {EditPOD} from './EditPOD'

function EditPodModal(props: any) {
  const handleClose = () => {
    props.closeModal()
  }

  return (
    <Modal
      show={props.showModal}
      onHide={handleClose}
      centered
      dialogClassName='custom-modal modal-dialog-centered'
      contentClassName='custom-modal-content'
      backdrop='static'
    >
      <Modal.Body className='overflow-auto'>
        <EditPOD
          closeModal={props.closeModal}
          getPodsData={props.getPodsData}
          podgetData={props.data}
          refreshData={props.fetchData}
        />
      </Modal.Body>
    </Modal>
  )
}

export default EditPodModal
