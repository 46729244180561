import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import clsx from 'clsx'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {useIntl} from 'react-intl'
import usePostApi from '../../custom_hooks/usePostApi'
import {FindPodIdByCode} from '../../FindPodIdByPodCode'
import {KTIcon} from '../../../../_metronic/helpers'
import {UseToast} from '../../useToast'
import {useThemeMode} from '../../../../_metronic/partials'
import {Link, useParams} from 'react-router-dom'
import Select from 'react-select'

function CreateSenders(props: any) {
  const {execute} = usePostApi()
  const intl = useIntl()
  const podId = FindPodIdByCode()
  const [currentStep, setCurrentStep] = useState(0)
  const [verified, setVerified] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [domainOption, setDomainOption] = useState([])
  const [domainOptions, setDomainOptions] = useState([])
  const [domainName, setTableData]: any = useState([])
  const [data, setData]: any = useState<any>({
    sender_name: '',
    sender_email: '',
    domainParentId: '',
    domainParentname: '',
    host: '',
    port_number: '',
    user_name: '',
    user_password: '',
    ssl: '',
    status: true,
  })

  const set2ndIndex = () => {
    setCurrentStep(1)
  }
  const scrambleX = (str: string, numTimes = 2) => {
    let encoded = str
    for (let i = 0; i < numTimes; i++) {
      encoded = btoa(encoded)
    }
    return encoded
  }

  const domainfetchData = async () => {
    try {
      const url = `/pod/domains/active`
      const formData = {pod_id: podId}
      const response = await execute(url, 'POST', formData)
      setTableData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }
  useEffect(() => {
    domainfetchData()
  }, [])
  useEffect(() => {
    const options: any = (domainName || []).map((each: any) => ({
      value: each.domain_id,
      label: each.domain_name,
    }))
    if (options) {
      setDomainOptions(options)
      if (options.length === 1) {
        setDomainOption(options[0])
      }
    }
  }, [domainName])
  const handleSubmit = async (data: any) => {
    const url = `/pod/domain/senders/create`
    const requestData = {
      pod_id: podId,
      parent_domain_id: data.domainParentId,
      sender_name: data.sender_name,
      sender_email: data.sender_email + '@' + data.domainParentname,
      smtp_configuration: {
        is_smtp: true,
        host: data.host,
        auth: true,
        user: data.user_name,
        password: scrambleX(data.user_password),
        ssl: data.ssl,
        port: data.port_number,
        from_email: data.sender_email,
        from_name: data.sender_name,
      },
      is_active: 1,
    }
    try {
      const response = await execute(url, 'POST', requestData)
      props.fetchData()
      props.closeModal()
      setData({
        sender_name: '',
        sender_email: '',
        domainParentId: '',
        domainParentname: '',
        host: '',
        port_number: '',
        user_name: '',
        user_password: '',
        ssl: '',
        status: true,
      })
      setCurrentStep(0)
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
    } catch (error: any) {
      console.error(error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }
  const verifcation = (value: any) => {
    setVerified(value)
  }
  const handleValidationSubmit = async (data: any, ssl: any) => {
    const url = `/pod/domain/smtp-validation`
    const requestData = {
      host: data.host,
      user_name: data.user_name,
      password: scrambleX(data.user_password),
      ssl: ssl,
      port: data.port_number,
    }
    try {
      const response = await execute(url, 'POST', requestData)
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
      verifcation(true)
    } catch (error: any) {
      console.error(error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }
  const steps = [
    <Step1
      data={data}
      setData={setData}
      set2ndIndex={set2ndIndex}
      domainOptions={domainOptions}
      domainOption={domainOption}
    />,
    <Step2 data={data} setData={setData} handleValidationSubmit={handleValidationSubmit} />,
  ]

  const prevStep = (): void => {
    if (currentStep !== 0) {
      setCurrentStep(currentStep - 1)
    }
  }

  const nextStep = () => {
    if (currentStep === steps.length - 1) {
      const step2Submit = document.getElementById('step2_submit_btn')
      if (step2Submit && !verified) {
        step2Submit.click()
        setSubmit(true)
      }

      if (verified && submit) {
        handleSubmit(data)
      }
    }
    if (currentStep === 0) {
      const step1Submit = document.getElementById('step1_submit_btn')
      if (step1Submit) {
        step1Submit.click()
      }
    }
  }

  return (
    <Modal
      show={props.showModal}
      onHide={() => props.setShowModal(false)}
      centered
      dialogClassName='custom-modal modal-dialog-centered'
      contentClassName='custom-modal-content'
    >
      <Modal.Body className='overflow-auto w-100'>
        <div className='card py-0 overflow-auto'>
          <div className='w-100 m-auto'>
            <div className='d-flex justify-content-between mb-10'>
              <h4 className='fw-semibold'>Add Senders</h4>
              <button
                type='button'
                className='d-block btn btn-sm btn-icon btn-light'
                onClick={() => {
                  props.setShowModal(false)
                  setCurrentStep(0)
                }}
              >
                <KTIcon iconName='cross' className='fs-1' />
              </button>
            </div>
            <div className='w-100 row m-0'>
              <div className='col-lg-5 p-0 mb-5 mb-lg-0'>
                <div className=''>
                  <div className='d-flex'>
                    <div
                      style={{borderRadius: '10px'}}
                      className={clsx(
                        'w-40px h-40px btn d-flex justify-content-center align-items-center',
                        {
                          'btn-primary': currentStep === 0,
                          'btn-light-primary': currentStep !== 0,
                        }
                      )}
                    >
                      {currentStep === 0 ? 1 : <i className='stepper-check fas fa-check'></i>}
                    </div>
                    <div className='ms-5'>
                      <h6
                        className={clsx('mb-0 fw-semibold', {
                          'text-muted': currentStep >= 0,
                        })}
                      >
                        Step 1
                      </h6>
                      <p
                        className={clsx('fs-6 mb-0', {
                          'text-muted': currentStep >= 0,
                        })}
                      >
                        Basic Information
                      </p>
                    </div>
                  </div>
                  <div className='ms-6 h-30px border-left border-dashed border-muted border-top-0 border-bottom-0 border-right-0 border-left-1'></div>
                  <div className='d-flex'>
                    <div
                      style={{borderRadius: '10px'}}
                      className={clsx(
                        'w-40px h-40px btn d-flex justify-content-center align-items-center',
                        {
                          'btn-primary': currentStep === 1,
                          'btn-light-primary': currentStep !== 1,
                        }
                      )}
                    >
                      {currentStep > 1 ? <i className='stepper-check fas fa-check'></i> : 2}
                    </div>
                    <div className='ms-5'>
                      <h6
                        className={clsx('mb-0 fw-semibold', {
                          'text-muted': currentStep >= 1,
                        })}
                      >
                        Step 2
                      </h6>
                      <p
                        className={clsx('fs-6 mb-0', {
                          'text-muted': currentStep >= 1,
                        })}
                      >
                        Credentials
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-7 p-0 m-0'>{steps[currentStep]}</div>
            </div>
            <div className='w-100 d-flex flex-stack mt-5'>
              <div className='mr-2'>
                {currentStep > 0 && (
                  <button onClick={prevStep} type='button' className='btn btn-sm btn-light me-3'>
                    <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                    Back
                  </button>
                )}
              </div>
              <div>
                <button type='submit' className='btn btn-sm btn-light-primary' onClick={nextStep}>
                  {currentStep === steps.length - 1 ? (
                    verified ? (
                      <>
                        <KTIcon iconName='check' className='fs-7' />
                        {intl.formatMessage({id: 'BTN.SAVE'})}
                      </>
                    ) : (
                      'Verify'
                    )
                  ) : (
                    <>
                      Continue
                      <KTIcon iconName='arrow-right' className='fs-3 ms-1' />
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
const registrationSchema = Yup.object().shape({
  domainParentId: Yup.string().required('Domain Name is required'),
  sender_name: Yup.string()
    .matches(
      /^[a-zA-Z0-9\s\-_']+$/,
      'Only letters, numbers, spaces, hyphens, underscores, and apostrophes are allowed'
    )
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Sender Name is required'),
  sender_email: Yup.string().required('Sender Email is required'),
})

const Step1 = (props: any) => {
  const {id} = useParams()
  const {mode} = useThemeMode()
  const {domainOption, domainOptions, data, setData, set2ndIndex} = props
  const [emailAddon, setEmailAddon] = useState('example.com')
  const [initialValues, setInitialValues] = useState(data)

  // useEffect to handle the initial value of domainParentId
  useEffect(() => {
    if (domainOption) {
      setInitialValues((prevValues: any) => ({
        ...prevValues,
        domainParentId: domainOption.value,
        domainParentname: domainOption.label,
      }))
      setEmailAddon(domainOption.label)
    }
  }, [domainOption])

  const onSubmit = (values: Partial<any>) => {
    setData({...data, ...values})
    set2ndIndex()
  }

  const handleDomainChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('domainParentId', selectedOption.value)
      setFieldValue('domainParentname', selectedOption.label)
      setEmailAddon(selectedOption.label)
    } else {
      setFieldValue('domainParentId', '')
    }
  }
  return (
    <Formik
      validationSchema={registrationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({setFieldValue, values}) => {
        return (
          <Form className='w-100'>
            <div className='w-100 mb-5'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Domain Name</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Specify your Domain Name'
                ></i>
              </label>
              <Select
                value={domainOptions.find((option: any) => option.value === values.domainParentId)}
                styles={{
                  control: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                    color: '#ffffff',
                    borderWidth: '0px',
                  }),
                  menu: (baseStyles: any) => ({
                    ...baseStyles,
                    backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                  }),
                  singleValue: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    color: mode === 'dark' ? '#92929f' : '#000000',
                  }),
                  input: (baseStyles: any) => ({
                    ...baseStyles,
                    color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                  }),
                  option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                    ...baseStyles,
                    backgroundColor: isSelected
                      ? '#C2C2C2'
                      : isFocused
                      ? '#e0e0e0'
                      : baseStyles.backgroundColor,
                    color: isSelected ? '#000000' : isFocused ? '#000000' : baseStyles.color,
                  }),
                }}
                theme={(theme: any) => ({
                  ...theme,
                  borderRadius: 5,
                  backgroundColor: '#000000',
                  colors: {
                    ...theme.colors,
                    primary25: 'rgba(80, 107, 236, 0.5)',
                    primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                  },
                })}
                className='react-select-container w-100'
                classNamePrefix='react-select'
                options={domainOptions}
                isSearchable
                placeholder='Select Domain Name'
                onChange={(val: any) => handleDomainChange(setFieldValue, val)}
              />
              <ErrorMessage component='div' className='text-danger' name='domainParentId' />
            </div>
            <div className='w-100 mb-5'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Sender Name</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Specify your Sender Name'
                ></i>
              </label>
              <Field
                type='text'
                className='form-control form-control-solid mb-1'
                id='floatingInput'
                placeholder='Enter Sender Name'
                name='sender_name'
                autoComplete='off'
              />
              <ErrorMessage component='div' className='text-danger' name='sender_name' />
            </div>
            <div className='w-100 mb-5'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Sender Email</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Specify your Sender Email'
                ></i>
              </label>
              <div className='input-group'>
                <Field
                  className='form-control form-control-solid'
                  id='floatingInput'
                  placeholder='Enter Sender Email'
                  name='sender_email'
                  type='text'
                  autoComplete='off'
                />
                <span className='input-group-text' style={{border: '0'}}>
                  @{emailAddon}
                </span>
              </div>
              <ErrorMessage component='div' className='text-danger' name='sender_email' />
            </div>
            <button
              type='submit'
              className='d-none btn btn-lg btn-primary w-100'
              id='step1_submit_btn'
            >
              Submit
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}
const registrationSchemaStep2 = Yup.object().shape({
  host: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Host is required'),
  user_name: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('User name is required'),
  user_password: Yup.string()
    .min(3, 'Minimum 6 characters')
    .max(50, 'Maximum 50 characters')
    .required('User password is required'),
  port_number: Yup.number()
    .required('Port number is required')
    .min(0, 'Port number must be greater than or equal to 0')
    .max(65535, 'Port number must be less than or equal to 65535'),
})
const Step2 = (props: any) => {
  const {setData, data, handleValidationSubmit} = props
  const [sslOrTls, setSslOrTls] = useState('ssl')
  const onSubmit = (values: Partial<any>) => {
    setData({...data, ...values, ssl: sslOrTls})
    handleValidationSubmit(values, sslOrTls)
  }
  const [passwordVisible, setPasswordVisible] = useState(false)
  const showPassword = () => {
    setPasswordVisible(!passwordVisible)
  }
  return (
    <Formik validationSchema={registrationSchemaStep2} initialValues={data} onSubmit={onSubmit}>
      {() => {
        return (
          <Form className='w-100'>
            <div className='w-100 mb-5'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Host</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Specify your Location Code'
                ></i>
              </label>
              <Field
                type='text'
                className='form-control form-control-solid mb-1'
                id='host'
                placeholder='Enter Host'
                name='host'
                autoComplete='off'
              />
              <ErrorMessage component='div' className='text-danger' name='host' />
            </div>
            <div className='d-flex w-100 mb-5'>
              <div className='w-100 mb-5'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>User Name</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Specify your user name'
                  ></i>
                </label>
                <Field
                  type='text'
                  className='form-control form-control-solid mb-1'
                  id='user_name'
                  placeholder='Enter User Name'
                  name='user_name'
                  autoComplete='off'
                />
                <ErrorMessage component='div' className='text-danger' name='user_name' />
              </div>
              <div className='ms-2 w-100 mb-5'>
                <div className='fv-row'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Password</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Specify your password'
                    ></i>
                  </label>
                  <div className='position-relative'>
                    <Field
                      name='user_password'
                      type={passwordVisible ? 'text' : 'password'}
                      className='form-control form-control-solid pr-10'
                      placeholder='Password'
                      autoComplete='off'
                    />
                    <span onClick={showPassword}>
                      <KTIcon
                        iconName={passwordVisible ? 'eye-slash' : 'eye'}
                        className='fs-2 icon'
                      />
                    </span>
                  </div>
                  <ErrorMessage name='user_password' component='div' className='text-danger' />
                </div>
              </div>
            </div>
            <div className='d-flex'>
              <div className='w-100 mb-5'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Port Number</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Specify your Port Number'
                  ></i>
                </label>
                <Field
                  type='number'
                  className='form-control form-control-solid mb-1'
                  id='floatingInput'
                  placeholder='Enter Port Number'
                  name='port_number'
                  autoComplete='off'
                />
                <ErrorMessage component='div' className='text-danger' name='port_number' />
              </div>
              <div className='ms-2 w-100 mb-5'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-5'>
                  <span className='required'>SSL or TLS</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Specify your ssl or tls'
                  ></i>
                </label>
                <div className='d-flex'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='ssl'
                      value='ssl'
                      checked={sslOrTls === 'ssl'}
                      onChange={() => setSslOrTls('ssl')}
                    />
                    <label className='form-check-label' htmlFor='ssl'>
                      SSL
                    </label>
                  </div>
                  <div className='form-check form-check-custom form-check-solid form-check-sm ms-4'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='tls'
                      value='tls'
                      checked={sslOrTls === 'tls'}
                      onChange={() => setSslOrTls('tls')}
                    />
                    <label className='form-check-label' htmlFor='tls'>
                      TLS
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <button
              type='submit'
              className='d-none btn btn-lg btn-primary w-100'
              id='step2_submit_btn'
            >
              Submit
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}

export default CreateSenders
