import {useState, useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'
import {PageTitle} from '../../../../_metronic/layout/core'
import usePostApi from '../../custom_hooks/usePostApi'
import DateFormatter from '../../DateFormatter'

function PolicyHistory() {
  const {execute, loading} = usePostApi()
  const {id, policy_id} = useParams()
  const [policyDetails, setPolicyDetails] = useState({policy_code: '', policy_name: ''})

  const [policyData, setPolicyData] = useState<any>(null)

  const getPolicyData = async () => {
    try {
      const url = '/pod/policy/history/get'
      const formData = {
        policy_id,
      }

      const response = await execute(url, 'POST', formData)
      setPolicyData(response.data?.data)
      setPolicyDetails({
        policy_code: response.data?.policy_code,
        policy_name: response.data?.policy_name,
      })
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const policyBC = [
    {
      title: `(${id})`,
      path: `/${id}/policies`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: ``,
      path: `/${id}/policies`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `Policies`,
      path: `/${id}/policies`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: ``,
      path: `/${id}/policies`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `${policyDetails?.policy_name} ${
        policyDetails.policy_name && `(${policyDetails?.policy_code})`
      }`,
      path: `/${id}/policies/history/${policy_id}`,
      isSeparator: false,
      isActive: false,
    },
  ]

  useEffect(() => {
    getPolicyData()
  }, [policy_id])

  return (
    <>
      <PageTitle breadcrumbs={policyBC}>History</PageTitle>
      {loading ? (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : policyData && policyData.length > 0 ? (
        <>
          <div className='d-none d-flex flex-wrap flex-stack mb-6'>
            <div>
              <h3 className='fw-semibold my-2'>History</h3>
            </div>
            <div className='d-flex'>
              <Link className='btn btn-sm btn-light-primary ms-4 fw-normal' to={`/${id}/policies`}>
                <KTIcon iconName='arrow-left' className='fs-3' />
                Back
              </Link>
            </div>
          </div>

          <div className='card timeline'>
            {Array.isArray(policyData) &&
              policyData
                .slice()
                .reverse()
                .map((each: any, index: number) => (
                  <div key={index} className='timeline-item'>
                    <div className='timeline-line w-20px dashed-line'></div>

                    <div className='timeline-icon symbol symbol-circle symbol-20px me-4'>
                      <div className={`symbol-label bg-secondary`}></div>
                    </div>

                    <div className='timeline-content mb-12 mt-n1'>
                      <div className='d-flex justify-content-between mt-1'>
                        <p className={`mb-0 fw-semibold text-dark default-cursor`}>
                          {policyDetails.policy_name}
                          {/* <span className='text-muted fw-normal'>Test</span> */}
                        </p>
                        <div className=''>
                          <p className='designation-btn bg-secondary fs-7 m-auto default-cursor'>
                            {/* {new Date(each.action_ts).toLocaleString()} */}
                            {each.action_ts ? DateFormatter(each.action_ts) : 'N.A.'}
                            {each.action_ts && ', '}

                            {each.action_ts &&
                              each.action_ts.length >= 16 &&
                              each.action_ts.slice(11, 16)}
                          </p>
                        </div>
                      </div>
                      <p className='m-0 text-muted default-cursor'>
                        Version: {each.policy_version}
                      </p>
                    </div>
                  </div>
                ))}
          </div>
        </>
      ) : (
        <div className='card min-h-250px d-flex justify-content-center align-items-center'>
          <h3 className='fs-3 fw-normal'>Policy history not found.</h3>
        </div>
      )}
    </>
  )
}

export default PolicyHistory
