import {useState, useEffect} from 'react'
import {Link, Outlet} from 'react-router-dom'
import {KTIcon} from '../../../../../_metronic/helpers'
import {useLocation, useParams} from 'react-router-dom'
import {CheckPodAccess, FindFirstPodCode, FindPodIdByCode} from '../../../FindPodIdByPodCode'
import usePostApi from '../../../custom_hooks/usePostApi'
import EditVacancyModal2 from './EditVacancyModal2'
import {useIntl} from 'react-intl'
import {UseToast} from '../../../useToast'
import Swal from 'sweetalert2'
function VacancyCard() {
  const intl = useIntl()
  const podHaveAccess = CheckPodAccess()
  const {execute, loading} = usePostApi()
  const {id, vacancy_id} = useParams()
  const {pathname} = useLocation()
  const [showModal, setShowModal] = useState(false)
  const [selectedData, setSelectedData] = useState(null)
  const podId = FindPodIdByCode()
  const [vacanciesData, setvacancies] = useState([])

  const getVacancies = async () => {
    try {
      const url = `/pod/vacancy/get`
      const formData = {
        vacancy_id: vacancy_id,
      }

      const response = await execute(url, 'POST', formData)
      setvacancies(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getVacancies()
  }, [])

  const userData = vacanciesData ? vacanciesData[0] : null

  const handleOpenModal = () => {
    setSelectedData(userData || vacanciesData) // Set selectedData as userData or vacanciesData
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleSaveClick = async () => {
    try {
      const url = `/pod/vacancy/status/update`
      const result = await Swal.fire({
        title: 'Updating the Vacancy Status',
        text: 'Press the Confirm button to update the Vacancy Status.',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: intl.formatMessage({id: 'BTN.CONFIRM'}),
        cancelButtonText: intl.formatMessage({id: 'LABEL.CANCEL'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
          cancelButton: 'btn btn-sm btn-secondary',
        },
      })
      if (result.isConfirmed) {
        const formdata = {
          vacancy_id: parseInt(vacancy_id),
          pod_id: podId,
          vacancy_status: userData?.vacancy_status === 'Open' ? 'Closed' : 'Open',
        }
        const response = await execute(url, 'POST', formdata)
        setShowModal(false)
        getVacancies()
        // refreshData()
        UseToast(
          response.status_message,
          {
            position: 'bottom-right',
            theme: 'colored',
            autoClose: false,
          },
          'success'
        )
      }
    } catch (error) {
      console.error(error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }

  return (
    <>
      {loading ? (
        <div className='card h-200px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : userData === null || userData.length === 0 ? (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center default-cursor'>
            <h1 className='mb-5'> {intl.formatMessage({id: 'LABEL.NO_DATA'})}</h1>
          </div>
        </div>
      ) : (
        <>
          <EditVacancyModal2
            show={showModal}
            onClose={handleCloseModal}
            data={selectedData}
            getVacancies={getVacancies}
          />
          <div className='card mb-4 mb-xl-6'>
            <div className='card-body min-h-150px row'>
              <div className='d-flex align-items-start mb-5'>
                <span className='text-gray-800 text-hover-primary default-cursor fs-2 fw-semibold me-1'>
                  {userData?.job_role_name}
                </span>
                <button
                  className='ms-10 btn btn-sm btn-light-primary me-2'
                  onClick={() => handleOpenModal()}
                  data-toggle='tooltip'
                  title='Edit Vacancy'
                >
                  <KTIcon iconName='pencil' className='fs-3' />
                  Edit
                </button>

                <button
                  className='btn btn-sm btn-light-primary me-2'
                  onClick={() => handleSaveClick()}
                  data-toggle='tooltip'
                  title='Update Status'
                >
                  <KTIcon iconName='notepad-edit' className='fs-3' />
                </button>

                {/* <button
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm  me-1 mt-0'
                  onClick={() => handleOpenModal()}
                >
                  <i className='ki-duotone ki-information-4 fs-1'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                </button> */}
              </div>
              {/* <p className='fw-normal fs-6 text-gray-600 mb-4'>{userData?.job_description}</p> */}
              <div className='row col-12 col-md-6 col-lg-5'>
                <div>
                  {' '}
                  <div className='row mb-3 d-flex align-items-start'>
                    <label className='col-lg-4 col-6 fw-semibold text-muted'>Job title</label>
                    <div className='col-lg-8 col-6'>
                      <span className='fs-6 text-dark text-hover-primary default-cursor'>
                        {userData?.job_title_name || '-'}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-3 d-flex align-items-start'>
                    <label className='col-lg-4 col-6 fw-semibold text-muted'>Department</label>
                    <div className='col-lg-8 col-6'>
                      <span className='fs-6 text-dark text-hover-primary default-cursor'>
                        {userData?.department_name || '-'}
                      </span>
                    </div>
                  </div>
                  {/* <div className='row mb-3 d-flex align-items-start'>
                    <label className='col-lg-4 col-6 fw-semibold text-muted'>Description</label>
                    <div className='col-lg-8 col-6'>
                      <span className='fs-6 text-dark text-hover-primary default-cursor'>
                        {userData?.job_description || '-'}
                      </span>
                    </div>
                  </div> */}
                  <div className='row mb-3 d-flex align-items-start'>
                    <label className='col-lg-4 col-6 fw-semibold text-muted'>No.of.Vacancies</label>
                    <div className='col-lg-8 col-6'>
                      <span className='fs-6 text-dark text-hover-primary default-cursor'>
                        {userData?.number_of_vacancies}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-3 d-flex align-items-start'>
                    <label className='col-lg-4 col-6 fw-semibold text-muted'>Hiring Manager</label>
                    <div className='col-lg-8 col-6'>
                      <span className='fs-6 text-dark text-hover-primary default-cursor'>
                        {userData?.hiring_manager_name}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-3 d-flex align-items-start'>
                    <label className='col-lg-4 col-6 fw-semibold text-muted'>Qualifications</label>
                    <div className='col-lg-8 col-6'>
                      <span className='fs-6 text-dark text-hover-primary default-cursor'>
                        {userData?.qualifications}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-3 d-flex align-items-start'>
                    <label className='col-lg-4 col-6 fw-semibold text-muted'>Skills</label>
                    <div className='col-lg-8 col-6'>
                      <span className='fs-6 text-dark text-hover-primary default-cursor'>
                        {userData?.skills || '-'}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-3 d-flex align-items-start'>
                    <label className='col-lg-4 col-6 fw-semibold text-muted'>Status</label>
                    <div className='col-lg-8 col-6'>
                      <span className='fs-6 text-dark text-hover-primary default-cursor'>
                        {userData?.vacancy_status}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row col-12 col-md-6 col-lg-7'>
                <div>
                  {' '}
                  <div className='row mb-3 d-flex align-items-start'>
                    <label className='col-lg-4 col-6 fw-semibold text-muted'>
                      Experience (in yrs)
                    </label>
                    <div className='col-lg-8 col-6'>
                      <span className='fs-6 text-dark text-hover-primary default-cursor'>
                        {userData?.experience_in_yrs || '-'}
                      </span>
                    </div>
                  </div>
                  {/* <div className='row mb-3 d-flex align-items-start'>
                    <label className='col-lg-4 col-6 fw-semibold text-muted'>Skills</label>
                    <div className='col-lg-8 col-6'>
                      <span className='fs-6 text-dark text-hover-primary default-cursor'>
                        {userData?.skills || '-'}
                      </span>
                    </div>
                  </div> */}
                  <div className='row mb-3 d-flex align-items-start'>
                    <label className='col-lg-4 col-6 fw-semibold text-muted'>Location</label>
                    <div className='col-lg-8 col-6'>
                      <span className='fs-6 text-dark text-hover-primary default-cursor'>
                        {userData?.location_name || '-'}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-3 d-flex align-items-start'>
                    <label className='col-lg-4 col-6 fw-semibold text-muted'>Currency</label>
                    <div className='col-lg-8 col-6'>
                      <span className='fs-6 text-dark text-hover-primary default-cursor'>
                        {userData?.salary_currency_name || '-'}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-3 d-flex align-items-start'>
                    <label className='col-lg-4 col-6 fw-semibold text-muted'>Salary range</label>
                    <div className='col-lg-8 col-6'>
                      <span className='fs-6 text-dark text-hover-primary default-cursor'>
                        {userData?.salary_start || '-'} {''} {'-'} {''}
                        {userData?.salary_end}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-3 d-flex align-items-start'>
                    <label className='col-lg-4 col-6 fw-semibold text-muted'>Employment type</label>
                    <div className='col-lg-8 col-6'>
                      <span className='fs-6 text-dark text-hover-primary default-cursor'>
                        {userData?.employment_type || '-'}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-3 d-flex align-items-start'>
                    <label className='col-lg-4 col-6 fw-semibold text-muted'>Description</label>
                    <div className='col-lg-8 col-6'>
                      <span className='fs-6 text-dark text-hover-primary default-cursor'>
                        {userData?.job_description || '-'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className='d-flex overflow-auto h-55px'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent  fw-semibold flex-nowrap p-0'>
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (pathname.startsWith(`/${id}/vacancies/basic-info`) && 'active')
                      }
                      // className='nav-link text-active-primary me-6 active'
                      to={`/${id}/vacancies/basic-info/${vacancy_id}`}
                    >
                      Interviews
                    </Link>
                  </li>

                  {/* <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (pathname.startsWith(`/${id}/vacancies/interviews`) && 'active')
                      }
                      // className='nav-link text-active-primary me-6 active'
                      to={`/${id}/vacancies/interviews/${vacancy_id}`}
                    >
                      Interviews
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>

          <Outlet />
        </>
      )}
    </>
  )
}

export default VacancyCard
