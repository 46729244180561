import {useState, useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'
import {KTCardBody, KTIcon} from '../../../../../../../_metronic/helpers'
import {PageTitle} from '../../../../../../../_metronic/layout/core'
import usePostApi from '../../../../../custom_hooks/usePostApi'
import DateFormatter from '../../../../../DateFormatter'
import Offersknowmore from '../../../../offers/offersknowmore/Offersknowmore'
import CandidateOfferModal from './CandidateOfferModal'

function CandidateOffers() {
  const {execute, loading} = usePostApi()
  const {id, candidate_id} = useParams()
  const [showModal, setShowModal] = useState(false)

  const [candidateId, updateCandidateId] = useState('1')
  const handleOpenModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }
  const [offersData, setoffersData] = useState({
    job_title_name: '',
    location: '',
    date_of_joining: '',
  })
  const [offerData, setofferData] = useState([])
  const getoffers = async () => {
    try {
      const url = `/pod/job_offers/candidate`
      const formData = {
        candidate_id: candidate_id,
      }

      const response = await execute(url, 'POST', formData)

      setoffersData(response.data)
      setofferData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getoffers()
  }, [])

  const [interviewData, setinterviewsData] = useState([])
  const getInterviews = async () => {
    try {
      const url = `/pod/candidate/get`
      const formData = {
        candidate_id: candidate_id,
      }

      const response = await execute(url, 'POST', formData)
      setinterviewsData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getInterviews()
  }, [])
  const policyBC = [
    {
      title: `(${id})`,
      path: `/${id}/candidates`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: ``,
      path: `/${id}/candidates`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `Offers`,
      path: `/${id}/candidates`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: ``,
      path: `/${id}/candidates`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: interviewData[0]
        ? interviewData[0]?.candidate_first_name + ' ' + interviewData[0]?.candidate_last_name
        : '',
      path: `/${id}/candidates/offers/${candidate_id}`,
      isSeparator: false,
      isActive: false,
    },
  ]

  useEffect(() => {
    getoffers()
  }, [candidate_id])

  return (
    <>
      <PageTitle breadcrumbs={policyBC}>Offers</PageTitle>
      {loading ? (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : offerData && offerData.length > 0 ? (
        <>
          <div className='d-none d-flex flex-wrap flex-stack mb-6'>
            <div>
              <h3 className='fw-semibold my-2'>Offers</h3>
            </div>
            <div className='d-flex'>
              <Link
                className='btn btn-sm btn-light-primary ms-4 fw-normal'
                to={`/${id}/candidates`}
              >
                <KTIcon iconName='arrow-left' className='fs-3' />
                Back
              </Link>
            </div>
          </div>

          <div className='card timeline'>
            {Array.isArray(offersData) &&
              offersData
                .slice()
                .reverse()
                .map((each, index) => (
                  <div key={index} className='timeline-item'>
                    <div className='timeline-line w-20px dashed-line'></div>

                    <div className='timeline-icon symbol symbol-circle symbol-20px me-4'>
                      <div className={`symbol-label bg-secondary`}></div>
                    </div>

                    <div className='timeline-content mb-12 mt-n1'>
                      <div className='d-flex justify-content-between mt-1'>
                        <p className={`fw-semibold text-dark default-cursor`}>
                          {each.job_title_name}
                        </p>

                        <div className=''>
                          <p className='designation-btn bg-secondary fs-7 m-auto default-cursor'>
                            {each.offer_status === 'I'
                              ? 'Issued'
                              : each.offer_status === 'A'
                              ? 'Accepted'
                              : each.offer_status === 'R'
                              ? 'Rejected'
                              : 'Unknown Status'}
                          </p>
                        </div>
                      </div>

                      <p className='m-0 text-muted default-cursor mt-0'>
                        {DateFormatter(each.date_of_joining)}
                      </p>
                      <p className={`m-0 text-muted default-cursor`}>{each.location_name}</p>
                      <p className={`m-0 text-muted default-cursor`}>
                        {each.city},{each.state},{each.country}
                      </p>
                    </div>
                  </div>
                ))}
          </div>
        </>
      ) : (
        <>
          <div className='min-h-250px d-flex justify-content-center align-items-center'>
            <div className=''>
              <div className='m-2 p-4'>
                <h3 className='fs-3 fw-semibold'>Manage Offers</h3>

                <p className=''>
                  Welcome to the Candidate-Offers Page! Here, you have the tools to seamlessly
                  create and track offer for a Candidate, ensuring a smooth transition into your
                  organization.
                </p>
                <Offersknowmore />
                <div
                  className='d-flex justify-content-center align-items-center'
                  data-toggle='tooltip'
                  title='Create New Offer'
                >
                  <button
                    className='btn btn-sm btn-light-primary'
                    id='side_activities_toggle'
                    onClick={() => {
                      handleOpenModal()
                      updateCandidateId(candidate_id)
                    }}
                  >
                    <KTIcon iconName='plus' className='fs-3' />
                    Create Offer
                  </button>
                </div>
              </div>
            </div>
          </div>
          <CandidateOfferModal
            show={showModal}
            onClose={handleCloseModal}
            candidateId={candidateId}
            fetchData={getoffers}
          />
        </>
      )}
    </>
  )
}

export default CandidateOffers
