import {useAuth} from './auth'
import {useParams} from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'

export const FindPodIdByCode = () => {
  const {currentUser} = useAuth()
  const podsList = secureLocalStorage.getItem('jb_pods_list')
  const {id} = useParams()

  const foundPod = podsList?.find((pod) => pod.pod_code === id)

  return foundPod ? foundPod.pod_id : null
}

export const FindBrandnameByCode = () => {
  const {currentUser} = useAuth()
  const podsList = secureLocalStorage.getItem('jb_pods_list')
  const {id} = useParams()

  const foundPod = podsList?.find((pod) => pod.pod_code === id)

  return foundPod ? foundPod.pod_brand_name : ''
}

export const FindOrgNameByCode = () => {
  const {currentUser} = useAuth()
  const podsList = secureLocalStorage.getItem('jb_pods_list')
  const {id} = useParams()

  const foundPod = podsList?.find((pod) => pod.pod_code === id)

  return foundPod ? foundPod.pod_business_name : ''
}

export const FindOrgPrefColorByCode = () => {
  const {currentUser} = useAuth()
  const podsList = secureLocalStorage.getItem('jb_pods_list')
  const {id} = useParams()

  const foundPod = podsList?.find((pod) => pod.pod_code === id)

  return foundPod ? foundPod.pod_pref_color : ''
}

export const FindOrgPodLogoByCode = () => {
  const {currentUser} = useAuth()
  const podsList = secureLocalStorage.getItem('jb_pods_list')
  const {id} = useParams()

  const foundPod = podsList?.find((pod) => pod.pod_code === id)

  return foundPod ? foundPod.pod_logo : ''
}

export const CheckPodAccess = () => {
  const {currentUser} = useAuth()
  const podsList = secureLocalStorage.getItem('jb_pods_list')
  const {id} = useParams()

  const podHaveAccess = podsList?.some(
    (each) => JSON.stringify(each.pod_code) === JSON.stringify(id)
  )
  return podHaveAccess
}

export const FindFirstPodCode = () => {
  const {currentUser} = useAuth()
  const podsList = secureLocalStorage.getItem('jb_pods_list')

  let podId

  if (podsList?.length) {
    const currentPod = localStorage.getItem('currentPodCode')

    if (typeof currentPod === 'string') {
      const podExists = podsList.some((each) => JSON.stringify(each.pod_code) === currentPod)

      podId = podExists ? JSON.parse(currentPod) : podsList[0].pod_code
    } else {
      podId = podsList[0].pod_code
    }
  }

  return podId
}
