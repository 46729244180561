import {Modal} from 'react-bootstrap'

import {useIntl} from 'react-intl'
import CreateCandidateOffer from './CreateCandidateOffer'

function CandidateOfferModal(props) {
  const handleClose = () => {
    props.onClose()
  }

  const intl = useIntl()

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
      centered
      dialogClassName='custom-modal modal-dialog-centered'
      contentClassName='custom-modal-content'
      backdrop='static' // Prevents closing when clicking outside
      keyboard={false} // Prevents closing with the Escape key
    >
      <Modal.Body className='overflow-auto'>
        <CreateCandidateOffer
          closeModal={props.onClose}
          candidateId={props.candidateId}
          fetchOffersData={props.fetchData}
        />
      </Modal.Body>
    </Modal>
  )
}

export default CandidateOfferModal
