import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {FormikHelpers} from 'formik'
import usePostApi from '../../../custom_hooks/usePostApi'
import {KTIcon} from '../../../../../_metronic/helpers'
import {FindPodIdByCode} from '../../../FindPodIdByPodCode'
import {UseToast} from '../../../useToast'
import {useThemeMode} from '../../../../../_metronic/partials'

const AddBusinessUnit = (props: any) => {
  const {fetchData, BusinessUnitClosePopover} = props.props
  const podId = FindPodIdByCode()
  const {mode} = useThemeMode()
  const intl = useIntl()
  const {execute, postLoading} = usePostApi()
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .required('Business Unit Name is required'),
    buCode: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .required('BU Code is required'),
    erpId: Yup.string().min(3, 'Minimum 3 characters').max(50, 'Maximum 50 characters'),
    status: Yup.boolean().required(intl.formatMessage({id: 'LABEL.STATUS_REQUIRED'})),
  })
  const handleSubmit = async (values: any, {resetForm}: FormikHelpers<any>) => {
    const url = `/pod/business_unit/create`
    try {
      const requestData = {
        pod_id: podId,
        business_unit_name: values.name,
        bu_code: values.buCode,
        erp_id: values.erpId,
        status: values.status ? 1 : 0,
      }
      const response = await execute(url, 'POST', requestData, 'post')
      resetForm()
      fetchData()
      BusinessUnitClosePopover()
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
    } catch (error: any) {
      console.error(error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }
  const handleCancel = (resetForm: any) => {
    resetForm()
  }
  return (
    <div>
      <Formik
        initialValues={{
          name: '',
          buCode: '',
          erpId: '',
          status: true,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({values, resetForm}) => {
          return (
            <Form>
              <div className='card'>
                <div className='card-header'>
                  <h3 className='card-title fw-semibold fs-3 text-dark'>Add Business Unit</h3>
                  <div className='card-toolbar me-2'>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-active-light me-n5'
                      onClick={() => BusinessUnitClosePopover()}
                    >
                      <KTIcon iconName='cross' className='fs-1' />
                    </button>
                  </div>
                </div>
                <div className='px-7 py-5'>
                  <div className='modal-body'>
                    <div>
                      <div className='mb-4 flex-fill'>
                        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 required'>
                          Business Unit Name
                        </label>
                        <Field
                          type='text'
                          className='form-control form-control-solid mb-1'
                          id='floatingInput'
                          placeholder='Enter Business Unit Name'
                          name='name'
                        />
                        <ErrorMessage component='div' className='text-danger' name='name' />
                      </div>
                      <div className='d-flex mb-4'>
                        <div className='flex-fill'>
                          <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 required'>
                            Business Unit Code
                          </label>
                          <Field
                            type='text'
                            className='form-control form-control-solid mb-1'
                            id='buCode'
                            placeholder='Enter Business Unit Code'
                            name='buCode'
                          />
                          <ErrorMessage component='div' className='text-danger' name='buCode' />
                        </div>
                        <div className='ms-2 flex-fill'>
                          <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                            {intl.formatMessage({
                              id: 'LABEL.ERP_ID',
                            })}
                          </label>
                          <Field
                            type='text'
                            className='form-control form-control-solid mb-1'
                            id='erpId'
                            placeholder='Enter ERP ID'
                            name='erpId'
                          />
                          <ErrorMessage component='div' className='text-danger' name='erpId' />
                        </div>
                      </div>
                      <div className='form-floating flex-fill'>
                        <div className='form-check form-check-solid form-switch mb-0'>
                          <Field
                            className='form-check-input w-40px h-20px'
                            type='checkbox'
                            name='status'
                            id='toggle'
                            style={{
                              backgroundColor: values.status
                                ? '#009688'
                                : mode === 'dark'
                                ? '#1b1b29'
                                : '#f4f4f4',
                            }}
                          />
                          <span
                            className={`me-4 fw-semibold ${
                              values.status ? 'text-success' : 'text-danger'
                            }`}
                          >
                            {values.status ? 'Active' : 'Inactive'}
                          </span>
                          <ErrorMessage component='div' className='text-danger' name='status' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex justify-content-end'>
                    <button
                      type='reset'
                      onClick={() => {
                        handleCancel(resetForm)
                        BusinessUnitClosePopover()
                      }}
                      className='btn btn-sm btn-light me-2'
                    >
                      {intl.formatMessage({id: 'LABEL.CANCEL'})}
                    </button>
                    <button
                      type='submit'
                      className='btn btn-sm btn-light-primary'
                      data-kt-menu-dismiss='true'
                    >
                      {postLoading ? (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      ) : (
                        <>
                          <KTIcon iconName='check' className='fs-7' />
                          {intl.formatMessage({id: 'BTN.SAVE'})}
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export {AddBusinessUnit}
