import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import usePostApi from '../../../custom_hooks/usePostApi'
import {KTIcon} from '../../../../../_metronic/helpers'
import {FindPodIdByCode} from '../../../FindPodIdByPodCode'
import {AddDepartment} from '../departments/AddDepartment'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import {UseToast} from '../../../useToast'
import {useThemeMode} from '../../../../../_metronic/partials'
import Select from 'react-select'
const EditJobRole = (props: any) => {
  const {
    id: jobRoleId,
    refreshData,
    JobroleClosePopover,
    name,
    code,
    depID,
    getstatus,
  } = props.props
  const intl = useIntl()
  const {execute, postLoading} = usePostApi()
  const podId = FindPodIdByCode()
  const {mode} = useThemeMode()
  const [depName, setTableData]: any | null = useState([])
  const [depOptions, setDepOptions] = useState([])
  const depfetchData = async () => {
    try {
      const url = `/pod/department/active`
      const formData = {pod_id: podId}
      const response = await execute(url, 'POST', formData)
      setTableData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }
  useEffect(() => {
    depfetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [popover, setPopover]: any = useState(null)
  const [id, updateId]: any = useState('')
  const ClosePopover = () => {
    setPopover(null)
  }
  const openPopover = (id: any) => (
    <Popover placement='auto' className='min-w-400px'>
      <AddDepartment props={id} />
    </Popover>
  )

  const initialValues = {
    name: name || '',
    code: code || '',
    depName: depID || '',
    status: getstatus === 1 || false,
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .required('Job Role Name is required'),
    code: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .required('Job Role Code is required'),
    status: Yup.boolean().required(intl.formatMessage({id: 'LABEL.STATUS_REQUIRED'})),
    depName: Yup.string().required(intl.formatMessage({id: 'LABEL.DEP_NAME_REQUIRED'})),
  })
  const handleDepChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('depName', selectedOption.value)
    } else {
      setFieldValue('depName', '')
    }
  }
  const handleEditSubmit = async (values: any) => {
    const url = `/pod/job_roles/update`
    try {
      const requestData = {
        job_role_id: jobRoleId,
        pod_id: podId,
        job_role_name: values.name,
        job_role_code: values.code,
        status: values.status ? 1 : 0,
        department_id: parseInt(values.depName),
      }
      const response = await execute(url, 'POST', requestData, 'post')
      refreshData()
      JobroleClosePopover()
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
    } catch (error: any) {
      console.error(error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }
  useEffect(() => {
    const options: any = (depName || []).map((each: any) => ({
      value: each.dep_id,
      label: each.dep_name,
    }))
    if (options) {
      setDepOptions(options)
    }
  }, [depName])
  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleEditSubmit}
      >
        {({values, handleChange, setFieldValue}) => {
          return (
            <Form>
              <div className='card'>
                <div className='card-header'>
                  <h3 className='card-title fw-semibold fs-3 text-dark'>Edit Job Role</h3>
                  <div className='card-toolbar me-2'>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-active-light me-n5'
                      onClick={() => JobroleClosePopover()}
                    >
                      <KTIcon iconName='cross' className='fs-1' />
                    </button>
                  </div>
                </div>
                <div className='px-7 py-5'>
                  <div className='modal-body position-relative'>
                    <div className='d-flex mb-4'>
                      <div className='flex-fill'>
                        <label
                          htmlFor='floatingInput'
                          className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 required'
                        >
                          Job Role Name
                        </label>
                        <Field
                          type='text'
                          className='form-control form-control-solid mb-1'
                          id='floatingInput'
                          placeholder='Enter Job Role Name'
                          name='name'
                          value={values.name}
                          onChange={handleChange}
                        />
                        <ErrorMessage component='div' className='text-danger' name='name' />
                      </div>
                      <div className='ms-2 flex-fill'>
                        <label
                          htmlFor='floatingInput'
                          className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 required'
                        >
                          Job Role Code
                        </label>
                        <Field
                          type='text'
                          className='form-control form-control-solid mb-1'
                          id='floatingInput'
                          placeholder='Enter Job Role Code'
                          name='code'
                          value={values.code}
                          onChange={handleChange}
                        />
                        <ErrorMessage component='div' className='text-danger' name='code' />
                      </div>
                    </div>
                    <div className='d-flex flex-column mb-4'>
                      <label
                        htmlFor='depName'
                        className='fs-6 fw-semibold form-label mb-2 required'
                      >
                        {intl.formatMessage({id: 'LABEL.DEPARTMENT_NAME'})}
                      </label>
                      <div className='d-flex flex-row flex-fill'>
                        <Select
                          value={depOptions.find((option: any) => option.value === values.depName)}
                          styles={{
                            control: (baseStyles: any, state: any) => ({
                              ...baseStyles,
                              backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                              color: '#ffffff',
                              borderWidth: '0px',
                            }),
                            menu: (baseStyles: any) => ({
                              ...baseStyles,
                              backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                            }),
                            singleValue: (baseStyles: any, state: any) => ({
                              ...baseStyles,
                              color: mode === 'dark' ? '#92929f' : '#000000',
                            }),
                            input: (baseStyles: any) => ({
                              ...baseStyles,
                              color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                            }),
                            option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                              ...baseStyles,
                              backgroundColor: isSelected
                                ? '#C2C2C2'
                                : isFocused
                                ? '#e0e0e0'
                                : baseStyles.backgroundColor,
                              color: isSelected
                                ? '#000000'
                                : isFocused
                                ? '#000000'
                                : baseStyles.color,
                            }),
                          }}
                          theme={(theme: any) => ({
                            ...theme,
                            borderRadius: 5,
                            backgroundColor: '#000000',
                            colors: {
                              ...theme.colors,
                              primary25: 'rgba(80, 107, 236, 0.5)',
                              primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                            },
                          })}
                          className='react-select-container flex-fill'
                          classNamePrefix='react-select'
                          options={depOptions}
                          isSearchable
                          placeholder='Select Department Name'
                          onChange={(val: any) => handleDepChange(setFieldValue, val)}
                        />
                        <OverlayTrigger
                          trigger='click'
                          placement='auto'
                          overlay={openPopover(id)}
                          show={popover === 'add_department'}
                        >
                          <button
                            type='button'
                            className='btn btn-icon btn-light btn-sm ms-2'
                            data-bs-toggle='tooltip'
                            title='Create New Department'
                            onClick={(e) => {
                              e.stopPropagation()
                              setPopover('add_department')
                              updateId({fetchData: depfetchData, ClosePopover})
                            }}
                          >
                            <KTIcon iconName='plus' className='fs-2' />
                          </button>
                        </OverlayTrigger>
                      </div>
                      <ErrorMessage component='div' className='text-danger' name='depName' />
                    </div>
                    <div className='form-floating mb-4'>
                      <div className='form-check form-check-solid form-switch'>
                        <Field
                          className='form-check-input w-40px h-20px'
                          type='checkbox'
                          id='toggle'
                          name='status'
                          checked={values.status}
                          onChange={handleChange}
                          style={{
                            backgroundColor: values.status
                              ? '#009688'
                              : mode === 'dark'
                              ? '#1b1b29'
                              : '#f4f4f4',
                          }}
                        />
                        <span
                          className={`me-4 fw-semibold ${
                            values.status ? 'text-success' : 'text-danger'
                          }`}
                        >
                          {values.status ? 'Active' : 'Inactive'}
                        </span>
                      </div>
                      <ErrorMessage component='div' className='text-danger' name='status' />
                    </div>
                  </div>
                  <div className='d-flex justify-content-end'>
                    <button
                      type='reset'
                      className='btn btn-sm btn-light  me-2'
                      onClick={() => {
                        JobroleClosePopover()
                      }}
                    >
                      {intl.formatMessage({id: 'LABEL.CANCEL'})}
                    </button>
                    <button type='submit' className='btn btn-sm btn-light-primary'>
                      {postLoading ? (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      ) : (
                        <>
                          <KTIcon iconName='check' className='fs-7' />
                          Save
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export {EditJobRole}
