import React, {useEffect, useMemo, useState} from 'react'
import {useTable, usePagination} from 'react-table'
import {Pagination} from 'react-bootstrap'
import {FormattedMessage, useIntl} from 'react-intl'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import NoDataIcon from '../../master-data-management/master-data/interview_types/job-interview.png'
import usePostApi from '../../custom_hooks/usePostApi'
import {KTIcon} from '../../../../_metronic/helpers'
import TrackReqImg from '../../master-data-management/no_data_found/time-tracking.png'
import SearchIcon from '../../master-data-management/no_data_found/search.png'
import {AddLookupValue} from './AddLookupValue'
import {useThemeMode} from '../../../../_metronic/partials'
import {UseToast} from '../../useToast'
import Swal from 'sweetalert2'
import {FindPodIdByCode} from '../../FindPodIdByPodCode'
const DataTable = ({columns, data, loading, fetchData, pod_lookup_name, pod_lookup_id}) => {
  const [inputVal, setInputVal] = useState('')
  const [popover, setPopover] = useState(null)
  const [id, updateId] = useState('')
  const ClosePopover = () => {
    setPopover(null)
  }
  const openPopover = (id) => (
    <Popover flip='true' placement='top' className='min-w-400px'>
      <AddLookupValue props={id} />
    </Popover>
  )
  const intl = useIntl()
  const filteredData = useMemo(() => {
    return data?.filter((each) => each.lookup_value?.toLowerCase().includes(inputVal.toLowerCase()))
  }, [data, inputVal])

  // Create separate datasets for active and inactive records
  const activeData = useMemo(() => {
    return filteredData?.filter((record) => record.is_active === 1)
  }, [filteredData])

  const inactiveData = useMemo(() => {
    return filteredData?.filter((record) => record.is_active === 0)
  }, [filteredData])

  // Table instances for active and inactive dataset
  const activeTableInstance = useTable({columns, data: activeData}, usePagination)
  const inactiveTableInstance = useTable({columns, data: inactiveData}, usePagination)
  // Extract required values from table instances
  const activePage = activeTableInstance.page
  const activePageCount = activeTableInstance.pageCount
  const activePageIndex = activeTableInstance.state.pageIndex
  const activePageSize = activeTableInstance.state.pageSize

  const inactivePage = inactiveTableInstance.page
  const inactivePageCount = inactiveTableInstance.pageCount
  const inactivePageIndex = inactiveTableInstance.state.pageIndex
  const inactivePageSize = inactiveTableInstance.state.pageSize

  // Handle pagination for active dataset
  const gotoActivePage = (pageIndex) => {
    activeTableInstance.gotoPage(pageIndex)
  }

  const setActivePageSize = (pageSize) => {
    activeTableInstance.setPageSize(pageSize)
  }

  // Handle pagination for inactive dataset
  const gotoInactivePage = (pageIndex) => {
    inactiveTableInstance.gotoPage(pageIndex)
  }

  const setInactivePageSize = (pageSize) => {
    inactiveTableInstance.setPageSize(pageSize)
  }
  const pageOptions = [5, 10, 20, 30, 40, 50]
  // Render the component
  return (
    <div>
      {loading ? (
        <div className='mt-6 h-250px d-flex justify-content-center align-items-center'>
          <div className='spinner-border spinner-primary mr-15'></div>
        </div>
      ) : data.length > 0 ? (
        <div className='card p-10 py-5 pt-0'>
          <div className='card-header border-0 px-0'>
            <div className='d-flex flex-wrap flex-stack'>
              <div className='fw-bolder'>
                <ul className='nav'>
                  <li className='nav-item'>
                    <a
                      className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-success active fw-normal px-4 me-1'
                      data-bs-toggle='tab'
                      href='#kt_table_widget_4_tab_1'
                    >
                      {intl.formatMessage({id: 'LABEL.ACTIVE'})}
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-danger fw-normal px-4 me-1'
                      data-bs-toggle='tab'
                      href='#kt_table_widget_4_tab_2'
                    >
                      {intl.formatMessage({id: 'LABEL.INACTIVE'})}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='card-toolbar'>
              <div className='d-flex align-items-center position-relative me-4'>
                <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                <input
                  type='search'
                  id='kt_filter_search'
                  className='form-control form-control-white form-control-sm w-150px ps-9 fw-normal'
                  placeholder={intl.formatMessage({id: 'LABEL.SEARCH'})}
                  value={inputVal}
                  onChange={(e) => setInputVal(e.target.value)}
                  autoComplete='off'
                />
              </div>
              <OverlayTrigger
                trigger='click'
                placement='top'
                overlay={openPopover(id)}
                show={popover === 'add_lookup_value'}
              >
                <button
                  className='btn btn-sm btn-light-primary fw-normal'
                  onClick={(e) => {
                    e.stopPropagation()
                    setPopover('add_lookup_value')
                    updateId({fetchData, ClosePopover, pod_lookup_id, pod_lookup_name})
                  }}
                >
                  <KTIcon iconName='plus' className='fs-3' />
                  Add Lookup Value
                </button>
              </OverlayTrigger>
            </div>
          </div>
          <div className='tab-content'>
            {/* Active Tab */}
            <div
              className='tab-pane fade show active table-responsive'
              id='kt_table_widget_4_tab_1'
            >
              <table
                className='table table-row-dashed align-middle table-row-gray-300'
                {...activeTableInstance.getTableProps()}
              >
                <thead>
                  <tr className='fw-semibold text-muted default-cursor bg-light rounded'>
                    <th className='min-w-120px ps-3'>Lookup Name</th>
                    <th className='min-w-120px'>{intl.formatMessage({id: 'LABEL.STATUS'})}</th>
                    <th className='min-w-120px'>{intl.formatMessage({id: 'LABEL.ACTION'})}</th>
                  </tr>
                </thead>
                <tbody {...activeTableInstance.getTableBodyProps()}>
                  {activePage.length > 0 ? (
                    activePage.map((row) => {
                      activeTableInstance.prepareRow(row)
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => (
                            <td className=' ' {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          ))}
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td className='' colSpan={5}>
                        <div className='d-flex justify-content-center'>
                          <p className='text-dark default-cursor fs-6 mt-5'>
                            {inputVal === '' ? (
                              intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
                            ) : (
                              <FormattedMessage
                                id='MSG.NO_DATA_MATCH'
                                values={{
                                  inputVal: <span className='text-primary'>{inputVal}</span>,
                                }}
                              />
                            )}
                          </p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {activePage.length > 10 && (
                <Pagination>
                  <Pagination.First
                    onClick={() => gotoActivePage(0)}
                    disabled={!activeTableInstance.canPreviousPage}
                  />
                  <Pagination.Prev
                    onClick={activeTableInstance.previousPage}
                    disabled={!activeTableInstance.canPreviousPage}
                  />
                  {[...Array(activePageCount)].map((_, i) => (
                    <Pagination.Item
                      key={i}
                      active={i === activePageIndex}
                      onClick={() => gotoActivePage(i)}
                    >
                      {i + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={activeTableInstance.nextPage}
                    disabled={!activeTableInstance.canNextPage}
                  />
                  <Pagination.Last
                    onClick={() => gotoActivePage(activePageCount - 1)}
                    disabled={!activeTableInstance.canNextPage}
                  />
                  <div className='d-flex align-items-center mx-5'>
                    <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                    <select
                      className='form-select form-select-solid fw-semibold w-75px me-2'
                      value={activePageSize}
                      onChange={(e) => setActivePageSize(Number(e.target.value))}
                    >
                      {pageOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </Pagination>
              )}
            </div>

            {/* Inactive Tab */}
            <div className='tab-pane fade table-responsive' id='kt_table_widget_4_tab_2'>
              <table
                className='table table-row-dashed align-middle table-row-gray-300'
                {...inactiveTableInstance.getTableProps()}
              >
                <thead>
                  <tr className='fw-semibold text-muted default-cursor bg-light rounded'>
                    <th className='min-w-120px ps-3'>Lookup Name</th>
                    <th className='min-w-120px'>{intl.formatMessage({id: 'LABEL.STATUS'})}</th>
                    <th className='min-w-120px'>{intl.formatMessage({id: 'LABEL.ACTION'})}</th>
                  </tr>
                </thead>
                <tbody {...inactiveTableInstance.getTableBodyProps()}>
                  {inactivePage.length > 0 ? (
                    inactivePage.map((row) => {
                      inactiveTableInstance.prepareRow(row)
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => (
                            <td className=' ' {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          ))}
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td className='' colSpan={5}>
                        <div className='d-flex justify-content-center'>
                          <p className='text-dark default-cursor fs-6 mt-5'>
                            {inputVal === '' ? (
                              intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
                            ) : (
                              <FormattedMessage
                                id='MSG.NO_DATA_MATCH'
                                values={{
                                  inputVal: <span className='text-primary'>{inputVal}</span>,
                                }}
                              />
                            )}
                          </p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {inactivePage.length > 10 && (
                <Pagination>
                  <Pagination.First
                    onClick={() => gotoInactivePage(0)}
                    disabled={!inactiveTableInstance.canPreviousPage}
                  />
                  <Pagination.Prev
                    onClick={inactiveTableInstance.previousPage}
                    disabled={!inactiveTableInstance.canPreviousPage}
                  />
                  {[...Array(inactivePageCount)].map((_, i) => (
                    <Pagination.Item
                      key={i}
                      active={i === inactivePageIndex}
                      onClick={() => gotoInactivePage(i)}
                    >
                      {i + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={inactiveTableInstance.nextPage}
                    disabled={!inactiveTableInstance.canNextPage}
                  />
                  <Pagination.Last
                    onClick={() => gotoInactivePage(inactivePageCount - 1)}
                    disabled={!inactiveTableInstance.canNextPage}
                  />
                  <div className='d-flex align-items-center mx-5'>
                    <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                    <select
                      className='form-select form-select-solid fw-semibold w-75px me-2'
                      value={inactivePageSize}
                      onChange={(e) => setInactivePageSize(Number(e.target.value))}
                    >
                      {pageOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </Pagination>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className='m-2 p-4'>
          <h3 className='fw-semibold fs-3'>Managing {pod_lookup_name}</h3>
          <p className='fw-normal'>
            Welcome to the {pod_lookup_name} page! Here, you have the ability to create new{' '}
            {pod_lookup_name.toLowerCase()}, inactive existing ones, and modify{' '}
            {pod_lookup_name.toLowerCase()} details as needed. We've designed this section to guide
            you through the process, ensuring that your {pod_lookup_name.toLowerCase()} are
            effectively managed and up to date.
          </p>
          <div className='row mt-6'>
            <div className='col-lg-4 mb-6'>
              <div className='shadow-sm p-3 rounded h-100 d-flex flex-column justify-content-center align-items-center'>
                <img src={NoDataIcon} className='track-req-icon mb-5' alt='Add Friend' />
                <p className='text-center'>
                  Click on the '+ Add Lookup Value' button to create the
                  {pod_lookup_name}.
                </p>
              </div>
            </div>
            <div className='col-lg-4 mb-6'>
              <div className='shadow-sm p-3 rounded h-100 d-flex flex-column justify-content-center align-items-center'>
                <img src={TrackReqImg} alt='Track Request' className='track-req-icon mb-5' />
                <p className='text-center'>
                  Keep track of the status of your created {pod_lookup_name.toLowerCase()}. See what
                  is active, and inactives if needed.
                </p>
              </div>
            </div>
            <div className='col-lg-4 mb-6'>
              <div className='shadow-sm p-3 rounded h-100 d-flex flex-column justify-content-center align-items-center'>
                <img src={SearchIcon} className='track-req-icon mb-5' alt='Add Friend' />
                <p className='text-center'>
                  Use the search option to narrow down your search. You can search by name to find
                  the {pod_lookup_name.toLowerCase()} you need quickly.
                </p>
              </div>
            </div>
          </div>
          <div className='m-auto d-flex flex-column align-items-center'>
            <OverlayTrigger
              trigger='click'
              placement='top'
              overlay={openPopover(id)}
              show={popover === 'add_lookup_value'}
            >
              <button
                className='btn btn-sm btn-light-primary fw-normal'
                onClick={(e) => {
                  e.stopPropagation()
                  setPopover('add_lookup_value')
                  updateId({fetchData, ClosePopover, pod_lookup_id, pod_lookup_name})
                }}
              >
                <KTIcon iconName='plus' className='fs-3' />
                Add Lookup Value
              </button>
            </OverlayTrigger>
          </div>
        </div>
      )}
    </div>
  )
}

const LookupValues = ({props}) => {
  const {pod_lookup_id, pod_lookup_name} = props
  const podId = FindPodIdByCode()
  const intl = useIntl()
  const [data, setTableData] = useState([])
  const {execute, loading: isLoading} = usePostApi()
  const {mode} = useThemeMode()
  const fetchData = async () => {
    try {
      const url = `/pod/lookup-values/get`
      const formData = {pod_lookup_id: pod_lookup_id}
      const response = await execute(url, 'POST', formData)
      setTableData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pod_lookup_id])
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'lookup_name',
        Cell: ({row}) => {
          return (
            <div className='d-flex align-items-center'>
              <div className='d-flex justify-content-start flex-column'>
                <p className='fw-semibold text-dark text-hover-primary fs-6 default-cursor ps-3'>
                  {row.original.lookup_value}
                </p>
              </div>
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({row}) => {
          const statusColor = row.original.is_active === 1 ? 'success' : 'danger'
          return (
            <p className={`text-${statusColor} fw-normal d-block fs-6 default-cursor`}>
              {row.original.is_active === 1 ? 'Active' : 'Inactive'}
            </p>
          )
        },
      },
      {
        Header: 'Action',
        accessor: 'Action',
        Cell: ({row}) => {
          const handleSubmit = async (is_active) => {
            const url = `/pod/lookup-values/update`
            try {
              const result = await Swal.fire({
                title: `${is_active ? 'Active' : 'inactivate'} ${row.original.lookup_value}`,
                text: `Are you sure you want to ${is_active ? 'active' : 'inactive'} ${
                  row.original.lookup_value
                }?`,
                icon: 'info',
                showCancelButton: true,
                cancelButtonText: intl.formatMessage({id: 'LABEL.CANCEL'}),
                confirmButtonText: intl.formatMessage({id: 'BTN.CONFIRM'}),
                customClass: {
                  cancelButton: 'btn btn-sm btn-light order-1', // Ensure the cancel button is on the left
                  confirmButton: 'btn btn-sm btn-light-primary order-2', // Ensure the confirm button is on the right
                },
              })

              if (result.isConfirmed) {
                const requestData = {
                  plv_id: row.original.plv_id,
                  pod_id: podId,
                  is_active: is_active,
                }
                const response = await execute(url, 'POST', requestData)
                fetchData()
                UseToast(
                  response.status_message,
                  {
                    position: 'bottom-right',
                    theme: 'colored',
                    autoClose: false,
                  },
                  'success'
                )
              }
            } catch (error) {
              console.error(error)
              UseToast(
                error.response?.data?.status_message,
                {
                  position: 'bottom-right',
                  theme: 'colored',
                  autoClose: false,
                },
                'error'
              )
            }
          }

          const handleToggle = async () => {
            const newIsActive = row.original.is_active ? 0 : 1
            await handleSubmit(newIsActive)
          }

          return (
            <div className='form-floating flex-fill'>
              <div className='form-check form-check-solid form-switch mb-0'>
                <input
                  className='form-check-input w-40px h-20px'
                  type='checkbox'
                  name='status'
                  id='toggle'
                  checked={row.original.is_active === 1}
                  onChange={handleToggle}
                  style={{
                    backgroundColor: row.original.is_active
                      ? '#009688'
                      : mode === 'dark'
                      ? '#1b1b29'
                      : '#f4f4f4',
                  }}
                />
              </div>
            </div>
          )
        },
      },
    ],
    [fetchData]
  )

  return (
    <DataTable
      columns={columns}
      data={data ? data : []}
      loading={isLoading}
      pod_lookup_id={pod_lookup_id}
      pod_lookup_name={pod_lookup_name}
      fetchData={fetchData}
    />
  )
}

export default LookupValues
