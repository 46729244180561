import {useState, useEffect} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import usePostApi from '../../custom_hooks/usePostApi'

function TempInfo() {
  const {id, temp_id} = useParams()
  const {execute} = usePostApi()
  const [templateInfo, setTemplateInfo]: any = useState([])
  console.log(templateInfo)

  const getTemplatesData = async () => {
    try {
      const url = '/pod/template/get'
      const formData = {
        template_id: temp_id,
      }

      const response = await execute(url, 'POST', formData)
      setTemplateInfo(response.data[0])
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getTemplatesData()
  }, [])

  const TemplateInfo = [
    {
      title: `(${id})`,
      path: `/${id}/templates`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: ``,
      path: ``,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `Template`,
      path: `/${id}/templates`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: ``,
      path: ``,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `${templateInfo?.template_name || ''} ${
        templateInfo?.template_name ? `(${templateInfo.template_id})` : ''
      }`,
      path: `/${id}/templates/${temp_id}`,
      isSeparator: false,
      isActive: false,
    },
  ]

  const decodeFromBase64 = (encodedText: any) => {
    if (!encodedText) {
      return
    }
    try {
      return decodeURIComponent(escape(atob(encodedText)))
    } catch (e) {
      console.error('Error decoding base64 string:', e)
      return 'Invalid base64 encoded string'
    }
  }

  console.log(JSON.parse(decodeFromBase64(templateInfo?.structure) || ''))

  return (
    <div>
      <PageTitle breadcrumbs={TemplateInfo}>Template</PageTitle>
      <h1>Details</h1>
    </div>
  )
}

export default TempInfo
