import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {FormikHelpers} from 'formik'
import usePostApi from '../../../custom_hooks/usePostApi'
import {KTIcon} from '../../../../../_metronic/helpers'
import {FindPodIdByCode} from '../../../FindPodIdByPodCode'
import {UseToast} from '../../../useToast'
import {useThemeMode} from '../../../../../_metronic/partials'
const AddDesignation = (props: any) => {
  const {refreshData, ClosePopover} = props.props
  const intl = useIntl()
  const {mode} = useThemeMode()
  const {execute, postLoading} = usePostApi()
  const podId = FindPodIdByCode()
  const validationSchema = Yup.object().shape({
    designationTypeName: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .required('Designation Name is required'),
    designationTypeCode: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .required('Designation Code is required'),
    status: Yup.string().required(intl.formatMessage({id: 'LABEL.STATUS_REQUIRED'})),
  })

  const handleSubmit = async (values: any, {resetForm}: FormikHelpers<any>) => {
    const url = `/pod/designations/create`
    try {
      const requestData = {
        pod_id: podId,
        designation_name: values.designationTypeName,
        designation_code: values.designationTypeCode,
        status: values.status ? 1 : 0,
      }
      const response = await execute(url, 'POST', requestData, 'post')
      resetForm()
      refreshData()
      ClosePopover()
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
    } catch (error: any) {
      console.error(error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }
  const handleCancel = (resetForm: any) => {
    resetForm()
  }
  return (
    <div>
      <Formik
        initialValues={{
          designationTypeName: '',
          designationTypeCode: '',
          status: true,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({values, resetForm}) => {
          return (
            <Form>
              <div className='card'>
                <div className='card-header'>
                  <h3 className='card-title fw-semibold fs-3 text-dark'>Add Designation</h3>
                  <div className='card-toolbar me-2'>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-active-light me-n5'
                      onClick={() => ClosePopover()}
                    >
                      <KTIcon iconName='cross' className='fs-1' />
                    </button>
                  </div>
                </div>
                <div className='px-7 py-5'>
                  <div className='modal-body position-relative'>
                    <div>
                      <div className='mb-4'>
                        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 required'>
                          Designation Name
                        </label>
                        <Field
                          type='text'
                          className='form-control form-control-solid mb-1'
                          id='floatingInput'
                          placeholder='Enter Designation Name'
                          name='designationTypeName'
                        />
                        <ErrorMessage
                          component='div'
                          className='text-danger'
                          name='designationTypeName'
                        />
                      </div>
                      <div className='mb-4'>
                        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 required'>
                          {intl.formatMessage({id: 'LABEL.DESIGNATION_CODE'})}
                        </label>
                        <Field
                          type='text'
                          className='form-control form-control-solid mb-1'
                          id='floatingInput'
                          placeholder='Enter Designation Code'
                          name='designationTypeCode'
                        />
                        <ErrorMessage
                          component='div'
                          className='text-danger'
                          name='designationTypeCode'
                        />
                      </div>
                      <div className='form-floating flex-fill mb-4'>
                        <div className='form-check form-check-solid form-switch mb-0'>
                          <Field
                            className='form-check-input w-40px h-20px'
                            type='checkbox'
                            name='status'
                            id='toggle'
                            style={{
                              backgroundColor: values.status
                                ? '#009688'
                                : mode === 'dark'
                                ? '#1b1b29'
                                : '#f4f4f4',
                            }}
                          />
                          <span
                            className={`me-4 fw-semibold ${
                              values.status ? 'text-success' : 'text-danger'
                            }`}
                          >
                            {values.status ? 'Active' : 'Inactive'}
                          </span>
                          <ErrorMessage component='div' className='text-danger' name='status' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex justify-content-end'>
                    <button
                      type='reset'
                      onClick={() => {
                        handleCancel(resetForm)
                        ClosePopover()
                      }}
                      className='btn btn-sm btn-light me-2'
                    >
                      {intl.formatMessage({id: 'LABEL.CANCEL'})}
                    </button>
                    <button
                      type='submit'
                      className='btn btn-sm btn-light-primary'
                      data-kt-menu-dismiss='true'
                    >
                      {postLoading ? (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      ) : (
                        <>
                          <KTIcon iconName='check' className='fs-7' />
                          Save
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export {AddDesignation}
