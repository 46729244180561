import React, {useState} from 'react'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import './KanbanBoard.css'
import {KTIcon} from '../../../_metronic/helpers'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'

const KanbanBoard = () => {
  const [boards, setBoards] = useState([
    {
      id: '_board1',
      title: 'Personal Info',
      required: true,
      items: [
        {id: '_item1', name: 'First Name', required: true, type: 'text'},
        {id: '_item2', name: 'Last Name', required: true, type: 'text'},
      ],
    },
    {
      id: '_board2',
      title: 'Address',
      required: true,
      items: [
        {
          id: '_item3',
          name: 'Country',
          required: true,
          type: 'text',
        },
        {id: '_item4', name: 'State', required: true, type: 'text'},
        {id: '_item5', name: 'City', type: 'text'},
      ],
    },
    {
      id: '_board4',
      title: 'Contact',
      items: [
        {id: '_item6', name: 'Mobile No.', required: true, type: 'num'},
        {id: '_item7', name: 'Alternate No.', type: 'num'},
        {id: '_item8', name: 'Email', required: true, type: 'text'},
      ],
    },
    {
      id: '_board3',
      title: 'Education',
      items: [
        {id: '_item9', name: 'Qualification', required: true, type: 'text'},
        {id: '_item10', name: 'University/College', required: true, type: 'text'},
        {id: '_item12', name: 'Score', required: true, type: 'text'},
      ],
    },
  ])

  const [newBoardName, setNewBoardName] = useState('')

  const [editTaskId, setEditTaskId] = useState('')
  const [editTaskLabel, setEditTaskLabel] = useState('')
  const [editTaskType, setEditTaskType] = useState('')
  const [isChecked, setIsChecked] = useState(false)

  const toggleMandatorySwitch = () => {
    const newState = !isChecked
    setIsChecked(newState)
  }

  const onDragEnd = (result) => {
    const {source, destination, draggableId, type} = result

    // If dropped outside the list
    if (!destination) {
      return
    }

    // If dropped in the same list
    if (type === 'BOARD') {
      const updatedBoards = [...boards]
      const [removedBoard] = updatedBoards.splice(source.index, 1)
      updatedBoards.splice(destination.index, 0, removedBoard)
      setBoards(updatedBoards)
    }

    if (type === 'TASK') {
      const sourceBoardIndex = boards.findIndex((board) => board.id === source.droppableId)
      const destinationBoardIndex = boards.findIndex(
        (board) => board.id === destination.droppableId
      )

      // If dropped in the same board
      if (sourceBoardIndex === destinationBoardIndex) {
        const updatedBoards = [...boards]
        const sourceBoard = updatedBoards[sourceBoardIndex]
        const updatedItems = Array.from(sourceBoard.items)
        const [removed] = updatedItems.splice(source.index, 1)
        updatedItems.splice(destination.index, 0, removed)

        const updatedBoard = {
          ...sourceBoard,
          items: updatedItems,
        }

        updatedBoards[sourceBoardIndex] = updatedBoard
        setBoards(updatedBoards)
      } else {
        // If dropped in a different board
        const updatedBoards = [...boards]
        const sourceBoard = updatedBoards[sourceBoardIndex]
        const destinationBoard = updatedBoards[destinationBoardIndex]
        const [removed] = sourceBoard.items.splice(source.index, 1)
        destinationBoard.items.splice(destination.index, 0, removed)

        setBoards(updatedBoards)
      }
    }
  }

  const addBoard = () => {
    if (newBoardName) {
      const newBoard = {
        id: '_' + Math.random().toString(36).substr(2, 9),
        title: newBoardName,
        items: [],
      }
      setBoards([...boards, newBoard])
      setNewBoardName('')
    }
  }

  const editTask = () => {
    if (editTaskId && editTaskLabel) {
      const updatedBoards = boards.map((board) => {
        const updatedItems = board.items.map((item) => {
          if (item.id === editTaskId) {
            return {...item, name: editTaskLabel, type: editTaskType}
          }
          return item
        })
        return {...board, items: updatedItems}
      })
      setBoards(updatedBoards)
      setEditTaskId('')
      setEditTaskLabel('')
      setEditTaskType('')
    }
  }

  const handleEditTask = (task) => {
    setEditTaskId(task.id)
    setEditTaskLabel(task.name)
    setEditTaskType(task.type)
  }

  const removeBoard = (id) => {
    if (id) {
      const updatedBoards = boards.filter((board) => board.id !== id)
      setBoards(updatedBoards)
    }
  }

  const handleDeleteTask = (taskId) => {
    const updatedBoards = boards.map((board) => {
      const updatedItems = board.items.filter((item) => item.id !== taskId)
      return {...board, items: updatedItems}
    })

    setBoards(updatedBoards)
  }

  const registrationSchema = Yup.object().shape({
    field_name: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(250, 'Maximum 250 characters')
      .required('Field Name is required'),
    field_section: Yup.string().required('Field Section is required'),
    field_type: Yup.string().required('Field Type is required'),
  })

  const initialValues = {
    field_name: '',
    field_section: '',
    field_type: '',
  }

  const onSubmit = (values, {resetForm}) => {
    const addTask = () => {
      const updatedBoards = boards.map((board) => {
        if (board.id === values.field_section) {
          return {
            ...board,
            items: [
              ...board.items,
              {
                id: '_' + Math.random().toString(36).substr(2, 9),
                name: values.field_name,
                type: values.field_type,
                required: isChecked,
              },
            ],
          }
        }
        return board
      })
      setBoards(updatedBoards)
      setIsChecked(false)
      resetForm()
    }
    addTask()
  }

  return (
    <div className='kt-portlet'>
      <div className='kt-portlet__head'>
        <div className='kt-portlet__head-label'>
          <h3 className='kt-portlet__head-title'>Dynamic Fields & Labels</h3>
        </div>
      </div>
      <div className='kt-portlet__body'>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='boards' direction='horizontal' type='BOARD'>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className='kanban-board-list row'
              >
                {boards.map((board, index) => (
                  <Draggable key={board.id} draggableId={board.id} index={index} type='BOARD'>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className='col-lg-3 mb-5'
                      >
                        <div className='kanban-board'>
                          <header className='kanban-board-header'>
                            <div className='kanban-title-board'>
                              <h5>{board.title}</h5>
                            </div>

                            <button
                              className='btn btn-danger btn-sm'
                              onClick={() => removeBoard(board.id)}
                              disabled={board.required && board.required === true}
                            >
                              Remove
                            </button>
                          </header>
                          <Droppable droppableId={board.id} type='TASK'>
                            {(provided) => (
                              <main
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className='kanban-drag'
                              >
                                {board.items.map((task, index) => (
                                  <Draggable
                                    key={task.id}
                                    draggableId={task.id}
                                    index={index}
                                    type='TASK'
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <div className='kanban-item' style={{position: 'relative'}}>
                                          <div className='kanban-item-content'>{task.name}</div>
                                          {task.required && task.required === true ? null : (
                                            <button
                                              style={{
                                                position: 'absolute',
                                                bottom: '0',
                                                right: '25px',
                                              }}
                                              className='btn btn-icon btn-sm btn-icon-danger'
                                              onClick={() => handleDeleteTask(task.id)}
                                            >
                                              <KTIcon iconName='trash' className='fs-5' />
                                            </button>
                                          )}
                                          <button
                                            style={{position: 'absolute', bottom: '0', right: '0'}}
                                            className='btn btn-icon btn-sm btn-icon-primary'
                                            onClick={() => handleEditTask(task)}
                                          >
                                            <KTIcon iconName='pencil' className='fs-5' />
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </main>
                            )}
                          </Droppable>
                          <footer></footer>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className='kanban-toolbar'>
          <div className='row p-5'>
            <div className='col-lg-4'>
              <h3 className='mb-3 text-primary'>
                <p
                  className='alert bg-light text-center text-muted'
                  style={{
                    width: '100%',
                    verticalAlign: 'middle',
                    fontSize: '12px',
                    textTransform: 'uppercase',
                  }}
                >
                  <span>Add New Section</span>
                </p>
              </h3>

              <label className='fw-bold'>Section Name</label>
              <div className='form-group row'>
                <div className='col-12'>
                  <input
                    id='kanban-add-board'
                    className='form-control'
                    type='text'
                    placeholder='Section Name'
                    value={newBoardName}
                    onChange={(e) => setNewBoardName(e.target.value)}
                  />
                  <br />
                  <button className='btn btn-success btn-sm' onClick={addBoard}>
                    ADD
                  </button>
                </div>
              </div>
            </div>

            <div className='col-lg-4'>
              <Formik
                validationSchema={registrationSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}
              >
                <Form className='min-w-350px'>
                  <h3 className='mb-3 text-primary'>
                    <p
                      className='alert bg-light text-center text-muted'
                      style={{
                        width: '100%',
                        verticalAlign: 'middle',
                        fontSize: '12px',
                        textTransform: 'uppercase',
                      }}
                    >
                      <span>Add New Field</span>
                    </p>
                  </h3>
                  <div className='fv-row mb-8 me-1'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <label className='fw-bold'>Field Name</label>
                      <span className='d-block bg-secondary px-2 rounded'>
                        Unique Code: Auto-generated
                      </span>
                    </div>
                    <Field name='field_name' className='form-control' placeholder='Field Name' />
                    <ErrorMessage name='field_name' component='div' className='text-danger' />
                  </div>

                  <div className='fv-row mb-8 me-1'>
                    <p className='form-label fs-6 fw-bolder text-dark mb-0'>Select Section</p>
                    <Field as='select' name='field_section' className='form-select'>
                      <option value=''>Select a Section</option>
                      {boards.map((board) => (
                        <option key={board.id} value={board.id}>
                          {board.title}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name='field_section' component='div' className='text-danger' />
                  </div>
                  <div className='fv-row mb-3 me-1'>
                    <p className='form-label fs-6 fw-bolder text-dark mb-0'>Select Field Type</p>
                    <Field as='select' name='field_type' className='form-select'>
                      <option value=''>Select Field Type</option>
                      <option value='text'>Text</option>
                      <option value='num'>Num</option>
                      <option value='dropdown'>Dropdown</option>
                      <option value='date'>Date</option>
                    </Field>
                    <ErrorMessage name='field_type' component='div' className='text-danger' />
                  </div>
                  <div className='d-flex align-items-center mt-2 mb-5'>
                    <label className='switch'>
                      <input
                        type='checkbox'
                        checked={isChecked}
                        onChange={toggleMandatorySwitch}
                        id='mandatorySwitch'
                        className='d-none'
                      />
                      <span className='slider round'></span>
                    </label>
                    <label className='d-block ms-2 fw-semibold' htmlFor='mandatorySwitch'>
                      Mandatory Field
                    </label>
                  </div>
                  <button type='submit' className='btn btn-lg btn-primary w-100'>
                    ADD
                  </button>
                </Form>
              </Formik>
            </div>

            <div className='col-lg-4'>
              <h3 className='mb-3 text-primary'>
                <p
                  className='alert bg-light text-center text-muted'
                  style={{
                    width: '100%',
                    verticalAlign: 'middle',
                    fontSize: '12px',
                    textTransform: 'uppercase',
                  }}
                >
                  <span>Edit Field</span>
                </p>
              </h3>

              <label className='fw-bold'>Edit Field Name</label>
              <div className='form-group'>
                <input
                  // id='kanban-edit-task'
                  className='form-control'
                  type='text'
                  placeholder='Edit Field Name'
                  value={editTaskLabel}
                  onChange={(e) => setEditTaskLabel(e.target.value)}
                />
                <br />

                <label className='fw-bold'>Field Type</label>
                <select
                  className='form-select'
                  value={editTaskType}
                  onChange={(e) => {
                    setEditTaskType(e.target.value)
                  }}
                >
                  <option value=''>Select Field Type</option>
                  <option value='text'>Text</option>
                  <option value='num'>Num</option>
                  <option value='dropdown'>Dropdown</option>
                  <option value='date'>Date</option>
                </select>
                <br />
                <button className='btn btn-warning btn-sm' onClick={editTask}>
                  UPDATE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default KanbanBoard
