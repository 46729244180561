import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import usePostApi from '../../../custom_hooks/usePostApi'
import {KTIcon} from '../../../../../_metronic/helpers'
import {FindPodIdByCode} from '../../../FindPodIdByPodCode'
import {UseToast} from '../../../useToast'
import {useThemeMode} from '../../../../../_metronic/partials'

const EditDesignation = (props: any) => {
  const {id, refreshData, ClosePopover, name, code, getstatus} = props.props
  const podId = FindPodIdByCode()
  const intl = useIntl()
  const {mode} = useThemeMode()
  const {execute, postLoading} = usePostApi()
  const validationSchemaEdit = Yup.object().shape({
    designation_name: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .required('Designation Name is required'),
    designation_code: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .required('Designation Code is required'),
    status: Yup.string().required(intl.formatMessage({id: 'LABEL.STATUS_REQUIRED'})),
  })

  const initialValues = {
    designation_name: name || '',
    designation_code: code || '',
    status: getstatus === 1 || '',
  }

  const handleEditSubmit = async (values: any) => {
    const url = `/pod/designations/update`
    try {
      const requestData = {
        designation_id: id,
        pod_id: podId,
        designation_name: values.designation_name,
        designation_code: values.designation_code,
        status: values.status ? 1 : 0,
      }
      const response = await execute(url, 'POST', requestData, 'post')
      refreshData()
      ClosePopover()
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
    } catch (error: any) {
      console.error(error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchemaEdit}
        onSubmit={handleEditSubmit}
      >
        {({values, handleChange}) => {
          return (
            <Form>
              <div className='card'>
                <div className='card-header'>
                  <h3 className='card-title fw-semibold fs-3 text-dark'>Edit Designation</h3>
                  <div className='card-toolbar me-2'>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-active-light me-n5'
                      onClick={() => ClosePopover()}
                    >
                      <KTIcon iconName='cross' className='fs-1' />
                    </button>
                  </div>
                </div>
                <div className='px-7 py-5'>
                  <div className='modal-body position-relative'>
                    <div className='mb-4'>
                      <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 required'>
                        Designation Name
                      </label>
                      <Field
                        type='text'
                        className='form-control form-control-solid mb-1'
                        id='floatingInput'
                        placeholder='Enter Designation Name'
                        name='designation_name'
                        value={values.designation_name}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        component='div'
                        className='text-danger'
                        name='designation_name'
                      />
                    </div>
                    <div className='mb-4'>
                      <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 required'>
                        {intl.formatMessage({id: 'LABEL.DESIGNATION_CODE'})}
                      </label>
                      <Field
                        type='text'
                        className='form-control form-control-solid mb-1'
                        id='floatingInput'
                        placeholder='Enter Designation Code'
                        name='designation_code'
                        value={values.designation_code}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        component='div'
                        className='text-danger'
                        name='designation_code'
                      />
                    </div>
                    <div className='form-floating mb-4'>
                      <div className='form-check form-check-solid form-switch'>
                        <Field
                          className='form-check-input w-40px h-20px'
                          type='checkbox'
                          id='toggle'
                          name='status'
                          checked={values.status}
                          onChange={handleChange}
                          style={{
                            backgroundColor: values.status
                              ? '#009688'
                              : mode === 'dark'
                              ? '#1b1b29'
                              : '#f4f4f4',
                          }}
                        />
                        <span
                          className={`me-4 fw-semibold ${
                            values.status ? 'text-success' : 'text-danger'
                          }`}
                        >
                          {values.status ? 'Active' : 'Inactive'}
                        </span>
                      </div>
                      <ErrorMessage component='div' className='text-danger' name='status' />
                    </div>
                  </div>
                  <div className='d-flex justify-content-end'>
                    <button
                      type='reset'
                      className='btn btn-sm btn-light me-2'
                      onClick={() => {
                        ClosePopover()
                      }}
                    >
                      {intl.formatMessage({id: 'LABEL.CANCEL'})}
                    </button>

                    <button type='submit' className='btn btn-sm btn-light-primary'>
                      {postLoading ? (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      ) : (
                        <>
                          <KTIcon iconName='check' className='fs-7' />
                          Save
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export {EditDesignation}
