import {useEffect, useState} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {KTIcon} from '../../../../../../../_metronic/helpers'
import usePostApi from '../../../../../custom_hooks/usePostApi'
import clsx from 'clsx'
import DatePicker from 'react-datepicker'
import {useThemeMode} from '../../../../../../../_metronic/partials'
import {FindPodIdByCode} from '../../../../../FindPodIdByPodCode'
import {baseUrl} from '../../../../../core'
import Select, {components} from 'react-select'
import {useIntl} from 'react-intl'
import CreateLocation from '../../../../../master-data-management/master-data/locations/CreateLocation'
import {DateBasicFormat} from '../../../../../DateHelpers'
import React from 'react'
import {useLang} from '../../../../../../../_metronic/i18n/Metronici18n'
import {useParams} from 'react-router-dom'
import {UseToast} from '../../../../../useToast'
const registrationSchema = Yup.object().shape({
  candidate_id: Yup.string().required('Candidate is Required'),
  job_title_id: Yup.string().required('Job title is Required'),
  date_of_joining: Yup.string().required('Date of Joining is Required'),
})

function CreateCandidateOffer({closeModal, candidateId, fetchOffersData}) {
  const [imgData, setImgData] = useState(null)
  const [imgName, setImgName] = useState('')

  const {mode} = useThemeMode()
  const [candidates, setcandidatesData] = useState([])
  const [locations, setlocations] = useState([])
  const [jobTitles, setjobtitleData] = useState([])
  const [jobtitlesOption, setjobtitlesOption] = useState('')
  const [locationOption, setlocationOption] = useState('')
  const getCandidates = async (candidateId) => {
    try {
      const url = `/pod/candidate/list`
      const formData = {
        pod_id: podId,
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each) => ({
        value: each.candidate_id,
        label: `${each.candidate_first_name} ${each.candidate_last_name}`,
      }))
      setcandidatesData(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getCandidates()
  }, [])

  const getjobtitles = async () => {
    try {
      const url = `/pod/job_titles/active`
      const formData = {
        pod_id: podId,
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each) => ({
        value: each.job_title_id,
        label: each.job_title_name,
      }))
      setjobtitleData(options)
      if (options.length === 1) {
        setjobtitlesOption(options[0].value)
      } else {
        setjobtitlesOption('')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getjobtitles()
  }, [])

  const getlocations = async () => {
    try {
      const url = `/pod/locations/active`
      const formData = {
        pod_id: podId,
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each) => ({
        value: each.location_id,
        label: `${each.location_name} ${','} ${each.address}`,
      }))
      setlocations(options)
      if (options.length === 1) {
        setlocationOption(options[0].value)
      } else {
        setlocationOption('')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getlocations()
  }, [])
  const fetchingMissedData = async () => {
    await Promise.all([getjobtitles(), getlocations()])
  }

  const [data, setData] = useState({
    candidate_id: candidateId,
    job_title_id: jobtitlesOption || ' ',
    location_id: locationOption || '',
    date_of_joining: '',
    offer_letter: '',
    offer_status: 'Issued',
    pod_id: '',
    pod_code: '',
    auto_onboarding: '',
    template_id: '',
  })

  const intl = useIntl()
  const {id} = useParams()
  const podId = FindPodIdByCode()
  const {execute, postLoading} = usePostApi()
  const [currentStep, setCurrentStep] = useState(0)

  const set2ndIndex = () => {
    setCurrentStep(1)
  }

  const handleSubmit = async (formdata) => {
    try {
      const url = `/pod/job_offers/create`

      const formData = new FormData()
      formData.append('offer_letter', imgData)

      formData.append('pod_id', podId)
      formData.append('candidate_id', candidateId)
      formData.append('job_title_id', data.job_title_id)
      formData.append('location_id', data.location_id)
      formData.append('auto_onboarding', data.auto_onboarding ? '1' : '0')
      formData.append('template_id', data.auto_onboarding ? data.template_id : '')
      formData.append('pod_code', id)
      formData.append('date_of_joining', DateBasicFormat(data.date_of_joining))
      formData.append('rec_type', 'OFR')
      const response = await execute(url, 'POST', formData)
      setData({
        pod_id: '',
        candidate_id: ' ',
        job_title_id: ' ',
        date_of_joining: '',
        offer_letter: '',
        location_id: ' ',
        offer_status: '',
        auto_onboarding: '',
        template_id: '',
      })

      closeModal()
      fetchOffersData()
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
    } catch (error) {
      console.error('Error:', error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }

  const steps = [
    <Step1
      set2ndIndex={set2ndIndex}
      data={data}
      setData={setData}
      jobtitlesData={jobTitles}
      locationsData={locations}
      fetchjobTitles={getjobtitles}
      fetchlocations={getlocations}
      candidateId={candidateId}
      candidates={candidates}
      jobtitlesOption={jobtitlesOption}
    />,
    <Step2
      data={data}
      setData={setData}
      handleSubmit={handleSubmit}
      setImgData={setImgData}
      imgName={imgName}
      setImgName={setImgName}
      locationsData={locations}
      fetchlocations={getlocations}
      locationOption={locationOption}
    />,
  ]

  const nextStep = () => {
    if (currentStep === 1) {
      const step2Btn = document.getElementById('step2_submit_btn')
      if (step2Btn) {
        step2Btn.click()
      }
    }
    if (currentStep === 0) {
      const step1Btn = document.getElementById('step1_submit_btn')
      if (step1Btn) {
        step1Btn.click()
      }
    }
  }

  // }
  const prevStep = () => {
    if (currentStep !== 0) {
      setCurrentStep(currentStep - 1)
    }
  }
  const isDataMissing = locations.length === 0 || jobTitles.length === 0

  return (
    <>
      <div className='card py-0 min-w-650px'>
        <div className='w-100 m-auto min-h-300px'>
          <div className='d-flex justify-content-between mb-10'>
            <h4 className='fw-semibold'>Create New Offer</h4>
            <button
              type='button'
              className='d-block btn btn-sm btn-icon btn-light'
              onClick={closeModal}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </button>
          </div>
          {/* <div className='w-100 d-flex flex-column flex-lg-row justify-content-between'> */}
          {isDataMissing ? (
            <div className='w-100 row m-0'>
              <div className='col-lg-5 p-0'>
                <div className=''>
                  <div className='d-flex'>
                    <div
                      style={{borderRadius: '10px'}}
                      className={clsx(
                        'w-40px h-40px btn d-flex justify-content-center align-items-center',
                        {
                          'btn-primary': currentStep === 0,
                          'btn-light-primary': currentStep !== 0,
                        }
                      )}
                    >
                      {currentStep === 0 ? 1 : <i className='stepper-check fas fa-check'></i>}
                    </div>
                    <div className='ms-5'>
                      <h6
                        className={clsx('mb-0 text-muted', {
                          'text-muted': currentStep >= 0,
                        })}
                      >
                        Step 1
                      </h6>
                      <p
                        className={clsx('fs-6 text-muted mb-0', {
                          'text-muted': currentStep >= 0,
                        })}
                      >
                        Candidate Details
                      </p>
                    </div>
                  </div>
                  <div className='ms-6 h-30px border-left border-dashed border-muted border-top-0 border-bottom-0 border-right-0 border-left-1'></div>
                  <div className='d-flex'>
                    <div
                      style={{borderRadius: '10px'}}
                      className={clsx(
                        'w-40px h-40px btn d-flex justify-content-center align-items-center',
                        {
                          'btn-primary': currentStep === 1,
                          'btn-light-primary': currentStep !== 1,
                        }
                      )}
                    >
                      {currentStep > 1 ? <i className='stepper-check fas fa-check'></i> : 2}
                    </div>
                    <div className='ms-5 '>
                      <h6
                        className={clsx('mb-0 text-muted', {
                          'text-muted': currentStep >= 1,
                        })}
                      >
                        Step 2
                      </h6>
                      <p
                        className={clsx('fs-6 text-muted', {
                          'text-muted': currentStep >= 1,
                        })}
                      >
                        Location and Offer letter
                      </p>
                    </div>
                  </div>
                  {/* Add more steps here */}
                </div>
              </div>
              <div className='col-lg-7 p-0 m-0'>{steps[currentStep]}</div>
            </div>
          ) : (
            <div className='w-100 row m-0'>
              <div className='col-lg-5 p-0'>
                <div className=''>
                  <div className='d-flex'>
                    <div
                      style={{borderRadius: '10px'}}
                      className={clsx(
                        'w-40px h-40px btn d-flex justify-content-center align-items-center',
                        {
                          'btn-primary': currentStep === 0,
                          'btn-light-primary': currentStep !== 0,
                        }
                      )}
                    >
                      {currentStep === 0 ? 1 : <i className='stepper-check fas fa-check'></i>}
                    </div>
                    <div className='ms-5'>
                      <h6
                        className={clsx('mb-0 fw-semibold', {
                          'text-muted': currentStep >= 0,
                        })}
                      >
                        Step 1
                      </h6>
                      <p
                        className={clsx('fs-6 fw-semibold mb-0', {
                          'text-muted': currentStep >= 0,
                        })}
                      >
                        Candidate Details
                      </p>
                    </div>
                  </div>
                  <div className='ms-6 h-30px border-left border-dashed border-muted border-top-0 border-bottom-0 border-right-0 border-left-1'></div>
                  <div className='d-flex'>
                    <div
                      style={{borderRadius: '10px'}}
                      className={clsx(
                        'w-40px h-40px btn d-flex justify-content-center align-items-center',
                        {
                          'btn-primary': currentStep === 1,
                          'btn-light-primary': currentStep !== 1,
                        }
                      )}
                    >
                      {currentStep > 1 ? <i className='stepper-check fas fa-check'></i> : 2}
                    </div>
                    <div className='ms-5 '>
                      <h6
                        className={clsx('mb-0 fw-semibold', {
                          'text-muted': currentStep >= 1,
                        })}
                      >
                        Step 2
                      </h6>
                      <p
                        className={clsx('fs-6 fw-semibold', {
                          'text-muted': currentStep >= 1,
                        })}
                      >
                        Location and Offer letter
                      </p>
                    </div>
                  </div>
                  {/* Add more steps here */}
                </div>
              </div>
              <div className='col-lg-7 p-0 m-0'>{steps[currentStep]}</div>
            </div>
          )}

          <div className='w-100 d-flex flex-stack mt-5'>
            <div className='mr-2'>
              {currentStep > 0 && (
                <button onClick={prevStep} type='button' className='btn btn-sm btn-light me-3'>
                  <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                  Back
                </button>
              )}
            </div>

            <div>
              {isDataMissing ? (
                <button
                  type='button'
                  className='btn btn-sm btn-light-primary ms-3'
                  onClick={() => {
                    fetchingMissedData()
                  }}
                >
                  <i className='fas fa-refresh text-primary me-2'></i>
                  Refresh
                </button>
              ) : (
                <button type='submit' className='btn btn-sm btn-light-primary' onClick={nextStep}>
                  {currentStep === steps.length - 1 ? (
                    <>
                      {postLoading && (
                        <div
                          className='spinner-border spinner-primary mr-15'
                          style={{height: '12px', width: '12px'}}
                        ></div>
                      )}
                      Create Offer
                    </>
                  ) : (
                    'Continue'
                  )}
                  {/* {currentStep < steps.length - 1 ? 'Continue' : 'Create POD'} */}
                  <KTIcon iconName='arrow-right' className='fs-3 ms-1' />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Step1 = (props) => {
  const onSubmit = (values) => {
    props.setData({...values})
    props.set2ndIndex()
  }

  const intl = useIntl()
  const {mode} = useThemeMode()
  const [showCreateLocation, setShowCreateLocation] = useState(false)
  const locations = props.locationsData
  const jobTitles = props.jobtitlesData
  const [loading, setLoading] = useState(true)
  const {id, candidate_id} = useParams()
  const jobtitlesOption = props.jobtitlesOption
  const {candidates, candidateId} = props

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([props.fetchlocations(), props.fetchjobTitles()])
      setLoading(false)
    }

    fetchData()
  }, [])
  const [locale, setLocale] = React.useState('en')
  const handlejobTitleChange = (setFieldValue, selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('job_title_id', selectedOption.value)
    } else {
      setFieldValue('job_title_id', '') // Handle case where no option is selected
    }
  }

  const lang = useLang()

  return (
    <>
      <CreateLocation
        showModal={showCreateLocation}
        setShowModal={setShowCreateLocation}
        closeModal={() => setShowCreateLocation(false)}
      />
      <Formik
        validationSchema={registrationSchema}
        initialValues={{
          ...props.data,

          job_title_id: jobtitlesOption || props.data.job_title_id,
        }}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({setFieldValue, values, handleChange}) => {
          if (loading) {
            return (
              <div className='m-auto d-flex flex-column align-items-center'>
                <div className='spinner-border spinner-primary mr-15'></div>
                <p className='mt-6 fw-semibold'>Verifying if all prerequisites are in place…</p>
              </div>
            )
          }

          // Check if any required data is null
          const isDataMissing = locations.length === 0 || jobTitles.length === 0
          return (
            <Form className='w-100'>
              {isDataMissing ? (
                <>
                  <div className=''>
                    {/* <p className='fw-semibold fs-4'>
                          Before you create a offer, please ensure all necessary prerequisite data are
                          available
                        </p> */}
                    <div className='alert alert-primary d-flex align-items-center p-1'>
                      <KTIcon iconName='information' className='fs-2 text-primary me-2' />
                      <div className='d-flex flex-column'>
                        <h6 className='mb-0 fw-semibold fs-7 text-dark'>Note:</h6>
                        <span className='fs-8 text-dark'>
                          Before you create a offer, please ensure all necessary prerequisite data
                          are available
                        </span>
                      </div>
                    </div>
                    <div className='d-flex '>
                      <div className='flex-column w-50 me-3'>
                        <div className='text-success bg-secondary fs-4'>
                          <p className='ms-2'>Available</p>
                        </div>

                        {jobTitles.length !== 0 && (
                          <div className='d-flex align-items-center mb-1'>
                            <KTIcon iconName='check-circle' className='text-success fs-2 me-1' />
                            <div className='fs-6'>Job titles </div>
                          </div>
                        )}

                        {locations.length !== 0 && (
                          <div className='d-flex align-items-center mb-1'>
                            <KTIcon iconName='check-circle' className='text-success fs-2 me-1' />
                            <div className='fs-6'>Locations</div>
                          </div>
                        )}

                        {/* Repeat similar structure for other available data */}
                      </div>
                      <div className='flex-column w-50'>
                        <div className='text-danger bg-secondary fs-4'>
                          <p className='ms-2'>Missing</p>
                        </div>

                        {jobTitles.length === 0 && (
                          <div className='d-flex align-items-center mb-1'>
                            <KTIcon iconName='cross-circle' className='text-danger fs-2 me-1' />
                            <div className='fs-6 w-100 me-2'>Job Titles</div>
                            {/* <button
                                  className='btn btn-sm btn-light-primary ms-2'
                                  onClick={() =>
                                    window.open(`/${id}/configuration/master-data/job-titles`, '_blank')
                                  }
                                >
                                  <KTIcon iconName='plus' className='fs-3' />
                                  Add
                                </button> */}
                            <i
                              className='ki-solid ki-plus-square fs-2 hover-primary'
                              onClick={() =>
                                window.open(`/${id}/configuration/master-data/job-titles`, '_blank')
                              }
                            ></i>
                          </div>
                        )}

                        {locations.length === 0 && (
                          <div className='d-flex mb-1'>
                            <KTIcon iconName='cross-circle' className='text-danger fs-2 me-1' />
                            <div className='fs-6 w-100 me-2'>Locations</div>

                            <div className='d-flex'>
                              {/* <button
                                  className='btn  btn-sm btn-light-primary ms-2'
                                  onClick={() =>
                                    window.open(`/${id}/configuration/master-data/locations`, '_blank')
                                  }
                                >
                                  <KTIcon iconName='plus' className='fs-3' />
                                  Add
                                </button> */}
                              {/* <i
                                className='ki-solid ki-plus-square fs-2 hover-primary'
                                onClick={() => window.open(`/${id}/configuration/master-data/locations`, '_blank')}
                              ></i> */}
                              <i
                                className='ki-solid ki-plus-square fs-2 hover-primary'
                                onClick={() => setShowCreateLocation(true)}
                              ></i>
                            </div>
                          </div>
                        )}

                        {/* Repeat similar structure for other missing data */}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className='w-100 mb-5 me-2'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>Candidate</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Select Candidate'
                      ></i>
                    </label>
                    <Select
                      value={candidates?.find((option) => option.value === parseInt(candidateId))}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                          color: '#ffffff',
                          borderWidth: '0px',
                        }),
                        menu: (baseStyles) => ({
                          ...baseStyles,
                          backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                        }),
                        singleValue: (baseStyles, state) => ({
                          ...baseStyles,
                          color: mode === 'dark' ? '#92929f' : '#000000',
                        }),
                        input: (baseStyles) => ({
                          ...baseStyles,
                          color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                        }),
                        option: (baseStyles, {isFocused, isSelected}) => ({
                          ...baseStyles,
                          backgroundColor: isSelected
                            ? '#C2C2C2'
                            : isFocused
                            ? '#e0e0e0'
                            : baseStyles.backgroundColor,
                          color: isSelected ? '#000000' : isFocused ? '#000000' : baseStyles.color,
                        }),
                      }}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        backgroundColor: '#000000',
                        colors: {
                          ...theme.colors,
                          primary25: 'rgba(80, 107, 236, 0.5)',
                          primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                        },
                      })}
                      className='react-select-container'
                      classNamePrefix='react-select'
                      options={candidates}
                      isSearchable={false} // Disable search functionality
                      isDisabled={true}
                      placeholder='Select Candidate'
                    />
                  </div>
                  <div className='w-100 mb-5 ms-2'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>Job title</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Select Job title'
                      ></i>
                    </label>

                    <Select
                      value={jobTitles.find(
                        (option) => option.value === (jobtitlesOption || values.job_title_id)
                      )}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                          color: '#ffffff',
                          borderWidth: '0px',
                        }),
                        menu: (baseStyles) => ({
                          ...baseStyles,
                          backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                        }),
                        singleValue: (baseStyles, state) => ({
                          ...baseStyles,
                          color: mode === 'dark' ? '#92929f' : '#000000',
                        }),
                        input: (baseStyles) => ({
                          ...baseStyles,
                          color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                        }),
                        option: (baseStyles, {isFocused, isSelected}) => ({
                          ...baseStyles,
                          backgroundColor: isSelected
                            ? '#C2C2C2'
                            : isFocused
                            ? '#e0e0e0'
                            : baseStyles.backgroundColor,
                          color: isSelected ? '#000000' : isFocused ? '#000000' : baseStyles.color,
                        }),
                      }}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        backgroundColor: '#000000',
                        colors: {
                          ...theme.colors,
                          primary25: 'rgba(80, 107, 236, 0.5)',
                          primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                        },
                      })}
                      className='react-select-container'
                      classNamePrefix='react-select'
                      options={jobTitles}
                      isSearchable
                      placeholder='Select Job Title'
                      onChange={(val) => handlejobTitleChange(setFieldValue, val)}
                    />

                    <div className='text-danger mt-2'>
                      <ErrorMessage name='job_title_id' />
                    </div>
                  </div>
                  <div className='w-100 mb-5'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>Date of Joining</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Select Date and time'
                      ></i>
                    </label>
                    <Field name='date_of_joining'>
                      {({form, field}) => {
                        const {setFieldValue} = form
                        const {value} = field

                        const handleChange = (date) => {
                          setFieldValue('date_of_joining', date)
                        }

                        return (
                          <div
                            className={`react-datepicker-fullwidth-wrapper test ${
                              mode === 'dark' ? 'dark-mode' : 'light-mode'
                            }`}
                          >
                            <DatePicker
                              selected={value}
                              minDate={new Date()}
                              isClearable
                              showYearDropdown
                              scrollableYearDropdown
                              onChange={(date) => {
                                handleChange(date)
                              }}
                              placeholderText={intl.formatMessage({
                                id: 'LABEL.SELECT_JOINING_DATE',
                              })}
                              showMonthDropdown
                              useShortMonthInDropdown
                              dateFormat='dd/MM/yyyy'
                              className='form-control form-control-solid'
                              popperPlacement='bottom'
                              locale={locale}
                            />
                          </div>
                        )
                      }}
                    </Field>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='date_of_joining' />
                    </div>
                  </div>
                  <div className='d-none d-flex justify-content-end'>
                    <div>
                      <button type='submit' className='btn btn-sm btn-light me-2'>
                        Close
                      </button>
                      <button
                        type='submit'
                        className='btn btn-sm btn-light-primary ms-2'
                        id='step1_submit_btn'
                      >
                        ADD
                      </button>
                    </div>
                  </div>
                </>
              )}
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

const step2Schema = Yup.object().shape({
  location_id: Yup.string().required('Location is Required.'),
  offer_letter: Yup.string().required('Offer letter is Required.'),
})

const Step2 = ({
  data,
  setData,
  handleSubmit,
  setImgData,
  imgName,
  setImgName,
  locationsData,
  locationOption,
}) => {
  const intl = useIntl()
  const {execute} = usePostApi()
  const podId = FindPodIdByCode()
  const locations = locationsData
  const {mode} = useThemeMode()
  const onSubmit = (values) => {
    setData({...data, ...values})
    handleSubmit()
  }
  const locationsOption = locationOption
  const [initialValues, setInitialValues] = useState(data)
  const [templates, settemplates] = useState('')
  useEffect(() => {
    if (locationsOption) {
      setInitialValues((prevValues) => ({
        ...prevValues,
        location_id: locationOption,
      }))
      setData((prevValues) => ({
        ...prevValues,
        location_id: locationOption,
      }))
    }
  }, [locationsOption])
  const gettemplates = async () => {
    try {
      const url = `/pod/templates/active`
      const formData = {
        pod_id: podId,
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each) => ({
        value: each.template_id,
        label: each.template_name,
      }))
      settemplates(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    gettemplates()
  }, [])

  const handletemplateChange = (setFieldValue, selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('template_id', selectedOption.value)
      updateData('template_id', selectedOption.value) // Update the data state
    } else {
      setFieldValue('template_id', '')
      updateData('template_id', '') // Update the data state
    }
  }

  const handleLocationChange = (setFieldValue, selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('location_id', selectedOption.value)
      updateData('location_id', selectedOption.value) // Update the data state
    } else {
      setFieldValue('location_id', '')
      updateData('location_id', '') // Update the data state
    }
  }
  const dataURLtoFile = (dataURI, fileName) => {
    if (!dataURI) {
      return null
    }
    const byteString = atob(dataURI.split(',')[1])
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    return new File([ab], fileName, {type: mimeString})
  }

  const updateData = (key, val) => {
    setData({...data, [key]: val})
  }

  return (
    <Formik
      validationSchema={step2Schema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({setFieldValue, values, handleChange}) => {
        return (
          <Form className='w-100'>
            <div className='w-100 mb-5'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Location</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Select Loaction'
                ></i>
              </label>
              <Select
                value={locations.find(
                  (option) => option.value === (locationsOption || values.location_id)
                )}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                    color: '#ffffff',
                    borderWidth: '0px',
                  }),
                  menu: (baseStyles) => ({
                    ...baseStyles,
                    backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                  }),
                  singleValue: (baseStyles, state) => ({
                    ...baseStyles,
                    color: mode === 'dark' ? '#92929f' : '#000000',
                  }),
                  input: (baseStyles) => ({
                    ...baseStyles,
                    color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                  }),
                  option: (baseStyles, {isFocused, isSelected}) => ({
                    ...baseStyles,
                    backgroundColor: isSelected
                      ? '#C2C2C2'
                      : isFocused
                      ? '#e0e0e0'
                      : baseStyles.backgroundColor,
                    color: isSelected ? '#000000' : isFocused ? '#000000' : baseStyles.color,
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 5,
                  backgroundColor: '#000000',
                  colors: {
                    ...theme.colors,
                    primary25: 'rgba(80, 107, 236, 0.5)',
                    primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                  },
                })}
                className='react-select-container'
                classNamePrefix='react-select'
                options={locations}
                isSearchable
                placeholder='Select Location'
                onChange={(val) => handleLocationChange(setFieldValue, val)}
              />
              {/* <Field
                type='text'
                name='location'
                className='form-control form-control-solid'
                placeholder='Enter Location'
              /> */}
              <div className='text-danger mt-2'>
                <ErrorMessage name='location_id' />
              </div>
            </div>

            <div className='w-100 mb-5'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Upload Offer Letter</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Upload Offer letter'
                ></i>
              </label>
              <Field name='offer_letter'>
                {({form, field}) => {
                  const {setFieldValue} = form
                  const {value} = field

                  const handleImageChange = (file) => {
                    const reader = new FileReader()
                    reader.onloadend = () => {
                      const previewImage = reader.result
                      const documentName = `${Date.now()}`
                      const file = dataURLtoFile(previewImage, `offer_letter${documentName}`)
                      setImgData(file)
                    }
                    reader.readAsDataURL(file)
                  }
                  const handleHeaderChange = (e) => {
                    const file = e.target.files?.[0]
                    setFieldValue('offer_letter', file ? file.name : '')
                    setImgName(file ? file.name : '')
                    if (file) {
                      handleImageChange(file)
                    }
                  }
                  const handleInputClick = () => {
                    document.getElementById('get-document-from-user').click()
                  }
                  return (
                    <div>
                      <input
                        type='file'
                        onChange={handleHeaderChange}
                        id='get-document-from-user'
                        className='form-control form-control-solid d-none'
                      />
                      <p
                        className='form-control form-control-solid d-flex justify-content-between pe-10 cursor-pointer mb-0'
                        onClick={handleInputClick}
                      >
                        <span>
                          {imgName
                            ? imgName
                            : intl.formatMessage({id: 'LABEL.CHOOSE_FILE_TO_UPLOAD'})}
                        </span>
                        <span>
                          <KTIcon iconName='paper-clip' className='fs-3 position-absolute ms-3' />
                        </span>
                      </p>
                    </div>
                  )
                }}
              </Field>
              <ErrorMessage name='offer_letter' component='div' className='text-danger' />
            </div>

            <div className='d-flex'>
              <div className='w-100 mb-5'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  Auto Onboarding
                </label>

                <div className='col-lg-8 text-start'>
                  <div className='form-check form-check-solid form-switch mb-0 mt-6'>
                    <Field
                      className='form-check-input w-40px h-20px me-2'
                      type='checkbox'
                      id='toggle'
                      name='auto_onboarding'
                      style={{backgroundColor: values.auto_onboarding ? '#009688' : '#f4f4f4'}}
                      onChange={handleChange}
                    />

                    <span
                      className={`me-4 fs-5 fw-semibold ${
                        values.auto_onboarding ? 'text-success' : 'text-danger'
                      }`}
                    >
                      {values.auto_onboarding ? 'Yes' : 'No'}
                    </span>
                  </div>
                </div>
                <ErrorMessage component='div' className='text-danger' name='auto_onboarding' />
              </div>

              {values.auto_onboarding && (
                <>
                  <div className='w-100 mb-5'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                      Onboarding Template
                    </label>
                    <div>
                      <Select
                        value={templates.find((option) => option.value === values.template_id)}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                            color: '#ffffff',
                            borderWidth: '0px',
                          }),
                          menu: (baseStyles) => ({
                            ...baseStyles,
                            backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                          }),
                          singleValue: (baseStyles, state) => ({
                            ...baseStyles,
                            color: mode === 'dark' ? '#92929f' : '#000000',
                          }),
                          input: (baseStyles) => ({
                            ...baseStyles,
                            color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                          }),
                          option: (baseStyles, {isFocused, isSelected}) => ({
                            ...baseStyles,
                            backgroundColor: isSelected
                              ? '#C2C2C2'
                              : isFocused
                              ? '#e0e0e0'
                              : baseStyles.backgroundColor,
                            color: isSelected
                              ? '#000000'
                              : isFocused
                              ? '#000000'
                              : baseStyles.color,
                          }),
                        }}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 5,
                          backgroundColor: '#000000',
                          colors: {
                            ...theme.colors,
                            primary25: 'rgba(80, 107, 236, 0.5)',
                            primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                          },
                        })}
                        className='react-select-container'
                        classNamePrefix='react-select'
                        options={templates}
                        isSearchable
                        placeholder='Select template'
                        onChange={(val) => handletemplateChange(setFieldValue, val)}
                      />

                      <ErrorMessage name='template_id' component='div' className='text-danger' />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className='alert alert-primary d-flex align-items-center p-1'>
              <KTIcon iconName='information' className='fs-2 text-primary me-2' />
              <div className='d-flex flex-column'>
                <h6 className='mb-0 fw-semibold fs-7 text-dark'>Note:</h6>
                <span className='fs-8 text-dark'>
                  When you select "auto-onboarding", once the candidate accepts the offer, an
                  e-onboarding link is automatically sent to the candidate. Disable this, if you
                  want to manually send the onboarding link later.
                </span>
              </div>
            </div>
            <button type='submit' className='d-none btn btn-light-primary' id='step2_submit_btn'>
              Add
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}

export default CreateCandidateOffer
