import React, {useState} from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {useThemeMode} from '../../../_metronic/partials'

function TextEditor({wordings, onChangeWordings}) {
  const {mode} = useThemeMode()
  const [value, setValue] = useState(
    '<h1>Hello, World!</h1><p>This is some initial <strong>rich</strong> text.</p>'
  )

  return (
    <ReactQuill
      theme='snow'
      value={wordings}
      onChange={onChangeWordings}
      placeholder='Briefly describe about the policy...'
    />
  )
}

export default TextEditor
