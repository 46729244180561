/* eslint-disable react/jsx-no-target-blank */
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarHeadings} from './SidebarHeadings'
import {SidebarMenuItem} from './SidebarMenuItem'
import SidebarData from '../SidebarData.json'
import {useParams} from 'react-router-dom'
import {useLocation} from 'react-router-dom'

function sortMenuItems(menuItems: string): string {
  const parsedMenuItems = JSON.parse(menuItems)
  const sortedMenuItems = parsedMenuItems.sort(
    (a: {sortOrder: number}, b: {sortOrder: number}) => a.sortOrder - b.sortOrder
  )
  return JSON.stringify(sortedMenuItems)
}

const SidebarMenuMain = () => {
  const {id} = useParams()
  const {pathname} = useLocation()

  const sortedSidebardata3 = sortMenuItems(JSON.stringify(SidebarData))
  const sortedSidebardata3JSON = JSON.parse(sortedSidebardata3)

  const filteredModules = sortedSidebardata3JSON

  return pathname === '/my-pods' || pathname === '/my-invites' || pathname === '/my-profile' ? (
    <div>
      <SidebarMenuItem to={`/my-pods`} icon='element-11' title='My PODs' />
      <SidebarMenuItem to={`/my-invites`} icon='rocket' title='My Invites' />
      <SidebarMenuItem to={`/my-profile`} icon='profile-circle' title='My Profile' />
    </div>
  ) : (
    <>
      {filteredModules.map((menuItem: any, index: any) => {
        const subMenuItems = menuItem.menuItems
        if (menuItem.single) {
          return (
            <SidebarMenuItem
              key={menuItem.sortOrder}
              to={`/${id}${menuItem.module}`}
              icon={menuItem.icon}
              title={menuItem.name}
            />
          )
        } else if (subMenuItems === null && menuItem.icon === null) {
          return (
            <div className='menu-item' key={menuItem.sortOrder}>
              <SidebarHeadings to='/crafted/pages' title={menuItem.module} icon={menuItem.icon} />
            </div>
          )
        } else {
          return (
            <div key={menuItem.sortOrder} className='d-block py-1'>
              <SidebarMenuItemWithSub
                // to='/crafted/pages'
                to={`/${id}${menuItem.module}`}
                hasChildren={subMenuItems.length > 0}
                title={menuItem.label}
                fontIcon='bi-archive'
                icon={menuItem.icon}
              >
                {subMenuItems.map((each: any, index: any) => {
                  return (
                    <SidebarMenuItem
                      key={index}
                      to={`/${id}${each.module}`}
                      title={each.name}
                      hasBullet={true}
                    />
                  )
                })}
              </SidebarMenuItemWithSub>
            </div>
          )
        }
      })}
    </>
  )
}

export {SidebarMenuMain}
