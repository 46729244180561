import {Link, useParams} from 'react-router-dom'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'

function CandidatateHistory() {
  const {id, jobJoinCode} = useParams()

  const timelineData = [
    {
      title: 'Request Submitted',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      timestamp: '2024-03-10T08:00:00Z', // Sample ISO date format
    },
    {
      title: 'Request Updated',
      description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      timestamp: '2024-03-11T10:30:00Z',
    },
    {
      title: 'Request Approved',
      description: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.',
      timestamp: '2024-03-12T14:45:00Z',
    },
  ]

  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <div>
          <h3 className='fw-semibold my-2'>History</h3>
        </div>
        <div className='d-flex'>
          <Link className='btn btn-sm btn-light-primary ms-4' to={`/${id}/onboarding`}>
            <KTIcon iconName='arrow-left' className='fs-3' />
            Back
          </Link>
        </div>
      </div>

      <div className='card py-5'>
        <div className='position-relative' id='kt_activities_body'>
          <div className='position-relative scroll-y me-n5 pe-5'>
            <div className='timeline'>
              {/* Item 1 */}
              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>

                <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                  <div className='symbol-label bg-light'>
                    <KTIcon iconName='pointers' className='fs-2 text-gray-500' />
                  </div>
                </div>

                <div className='timeline-content mb-10 mt-n1'>
                  <div className='pe-3 mb-5'>
                    <div className='fs-5 fw-normal mb-2'>
                      Changes made in the personal information section.
                    </div>

                    <div className='d-flex align-items-center mt-1 fs-6'>
                      <div className='fw-normal text-muted me-2 fs-7'>29-07-2024 at 10:23 AM</div>

                      {/* <div
                        className='symbol symbol-circle symbol-25px'
                        data-bs-toggle='tooltip'
                        data-bs-boundary='window'
                        data-bs-placement='top'
                        title='Nina Nilson'
                      >
                        <img src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='img' />
                      </div> */}
                    </div>
                  </div>

                  {/* <div className='overflow-auto pb-5'>
                    <div className='d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-5'>
                      <a
                        href='#'
                        className='fs-5 text-dark text-hover-primary fw-normal w-375px min-w-200px'
                      >
                        Meeting with customer
                      </a>

                      <div className='min-w-175px pe-2'>
                        <span className='fw-normal badge badge-light text-muted'>
                          Application Design
                        </span>
                      </div>

                      <div className='symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px pe-2'>
                        <div className='symbol symbol-circle symbol-25px'>
                          <img src={toAbsoluteUrl('/media/avatars/300-2.jpg')} alt='img' />
                        </div>

                        <div className='symbol symbol-circle symbol-25px'>
                          <img src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='img' />
                        </div>

                        <div className='symbol symbol-circle symbol-25px'>
                          <div className='symbol-label fs-8 fw-semibold bg-primary text-inverse-primary'>
                            A
                          </div>
                        </div>
                      </div>

                      <div className='min-w-125px pe-2'>
                        <span className='fw-normal badge badge-light-primary'>In Progress</span>
                      </div>

                      <a href='#' className='btn btn-sm btn-light btn-active-light-primary'>
                        View
                      </a>
                    </div>

                    <div className='d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-0'>
                      <a
                        href='#'
                        className='fs-5 text-dark text-hover-primary fw-normal w-375px min-w-200px'
                      >
                        Project Delivery Preparation
                      </a>

                      <div className='min-w-175px'>
                        <span className='fw-normal badge badge-light text-muted'>
                          CRM System Development
                        </span>
                      </div>

                      <div className='symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px'>
                        <div className='symbol symbol-circle symbol-25px'>
                          <img src={toAbsoluteUrl('/media/avatars/300-20.jpg')} alt='img' />
                        </div>

                        <div className='symbol symbol-circle symbol-25px'>
                          <div className='symbol-label fs-8 fw-semibold bg-success text-inverse-primary'>
                            B
                          </div>
                        </div>
                      </div>

                      <div className='min-w-125px'>
                        <span className='fw-normal badge badge-light-success'>Completed</span>
                      </div>

                      <a href='#' className='btn btn-sm btn-light btn-active-light-primary'>
                        View
                      </a>
                    </div>
                  </div> */}
                </div>
              </div>

              {/* Item 2 */}
              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>

                <div className='timeline-icon symbol symbol-circle symbol-40px'>
                  <div className='symbol-label bg-light'>
                    <KTIcon iconName='pointers' className='fs-2 text-gray-500' />
                  </div>
                </div>

                <div className='timeline-content mb-10 mt-n2'>
                  <div className='overflow-auto pe-3'>
                    <div className='fs-5 fw-normal mb-2'>
                      Submitted the Address & Contact section.
                    </div>

                    <div className='d-flex align-items-center mt-1 fs-6'>
                      <div className='text-muted me-2 fs-7'>29-07-2-24 at 10: 40 AM</div>

                      {/* <div
                        className='symbol symbol-circle symbol-25px'
                        data-bs-toggle='tooltip'
                        data-bs-boundary='window'
                        data-bs-placement='top'
                        title='Alan Nilson'
                      >
                        <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='img' />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              {/* Item 3 */}
              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>

                <div className='timeline-icon symbol symbol-circle symbol-40px'>
                  <div className='symbol-label bg-light'>
                    <KTIcon iconName='disconnect' className='fs-2 text-gray-500' />
                  </div>
                </div>

                <div className='timeline-content mb-10 mt-n1'>
                  <div className='mb-5 pe-3'>
                    <a href='#' className='fs-5 fw-normal text-gray-800 text-hover-primary mb-2'>
                      Uploaded SSC Document
                    </a>

                    <div className='d-flex align-items-center mt-1 fs-6'>
                      <div className='text-muted me-2 fs-7'>29-07-2024 at 10:46 AM</div>

                      {/* <div
                        className='symbol symbol-circle symbol-25px'
                        data-bs-toggle='tooltip'
                        data-bs-boundary='window'
                        data-bs-placement='top'
                        title='Jan Hummer'
                      >
                        <img src={toAbsoluteUrl('/media/avatars/300-23.jpg')} alt='img' />
                      </div> */}
                    </div>
                  </div>

                  <div className='overflow-auto pb-5'>
                    <div className='d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-5'>
                      <div className='d-flex flex-aligns-center pe-10 pe-lg-20'>
                        <img
                          alt=''
                          className='w-30px me-3'
                          src={toAbsoluteUrl('/media/svg/files/pdf.svg')}
                        />

                        <div className='ms-1 fw-semibold'>
                          <a href='#' className='fs-6 text-hover-primary fw-semibolder'>
                            SSC Document
                          </a>

                          <div className='text-gray-400'>659.02KB</div>
                        </div>
                      </div>
                      {/* 
                      <div className='d-flex flex-aligns-center pe-10 pe-lg-20'>
                        <img
                          alt=''
                          className='w-30px me-3'
                          src={toAbsoluteUrl('/media/svg/files/doc.svg')}
                        />

                        <div className='ms-1 fw-semibold'>
                          <a href='#' className='fs-6 text-hover-primary fw-semibolder'>
                            Client UAT Testing Results
                          </a>

                          <div className='text-gray-400'>18kb</div>
                        </div>
                      </div>

                      <div className='d-flex flex-aligns-center'>
                        <img
                          alt=''
                          className='w-30px me-3'
                          src={toAbsoluteUrl('/media/svg/files/css.svg')}
                        />

                        <div className='ms-1 fw-semibold'>
                          <a href='#' className='fs-6 text-hover-primary fw-semibolder'>
                            Finance Reports
                          </a>

                          <div className='text-gray-400'>20mb</div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <Item1 />
            <Item2 />
            <Item3 /> */}
              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>

                <div className='timeline-icon symbol symbol-circle symbol-40px'>
                  <div className='symbol-label bg-light'>
                    <KTIcon iconName='pointers' className='fs-2 text-gray-500' />
                  </div>
                </div>

                <div className='timeline-content mb-10 mt-n2'>
                  <div className='overflow-auto pe-3'>
                    <div className='fs-5 fw-normal mb-2'>
                      Submitted the Address & Contact section.
                    </div>

                    <div className='d-flex align-items-center mt-1 fs-6'>
                      <div className='text-muted me-2 fs-7'>29-07-2-24 at 10: 40 AM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='timeline-item'>
                <div className='timeline-line w-40px'></div>

                <div className='timeline-icon symbol symbol-circle symbol-40px'>
                  <div className='symbol-label bg-light'>
                    <KTIcon iconName='pointers' className='fs-2 text-gray-500' />
                  </div>
                </div>

                <div className='timeline-content mb-10 mt-n2'>
                  <div className='overflow-auto pe-3'>
                    <div className='fs-5 fw-normal mb-2'>
                      Submitted the Address & Contact section.
                    </div>

                    <div className='d-flex align-items-center mt-1 fs-6'>
                      <div className='text-muted me-2 fs-7'>29-07-2-24 at 10: 40 AM</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CandidatateHistory
