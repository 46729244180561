import {Modal} from 'react-bootstrap'
import './index.css'
import usePostApi from '../custom_hooks/usePostApi'
import CustomWizardNew from './CreateNewPodNew'
import {UseToast} from '../useToast'

function CreateNewPodCustomModal(props: any) {
  const handleClose = () => {
    props.onClose()
  }

  const {execute, postLoading} = usePostApi()

  const addNewPod = async (formData: any) => {
    try {
      const url = '/pod/create'

      const response = await execute(url, 'POST', formData)

      return response
    } catch (error: any) {
      console.error('Error:', error)

      // Show error message
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          autoClose: false,
          theme: 'colored',
        },
        'error'
      )

      // Return null in case of error
      return null
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
      centered
      dialogClassName='custom-modal modal-dialog-centered'
      contentClassName='custom-modal-content'
      backdrop='static' // Prevents closing when clicking outside
      // keyboard={false} // Prevents closing with the Escape key
    >
      <Modal.Body className='overflow-auto'>
        <CustomWizardNew
          addNewPod={addNewPod}
          closeModal={props.onClose}
          fetchPODsList={props.fetchPODsList}
          postLoading={postLoading}
        />
      </Modal.Body>
    </Modal>
  )
}

export default CreateNewPodCustomModal
