/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {FindPodIdByCode} from '../../FindPodIdByPodCode'
import usePostApi from '../../custom_hooks/usePostApi'
import {Preferences} from '../preferences/Preferences'
import Information from './Information'
function OrganizationInfo() {
  const podId = FindPodIdByCode()
  const {execute, loading: isLoading} = usePostApi()
  const [data, setTableData]: any = useState([])
  const fetchData = async () => {
    try {
      const url = `/pod/get`
      const formData = {pod_id: podId}
      const response = await execute(url, 'POST', formData)
      setTableData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className='row g-5 g-xxl-8'>
      <div className='col-xl-6'>
        <Information data={data} isLoading={isLoading} fetchData={fetchData} />
      </div>
      <div className='col-xl-6'>
        <Preferences data={data} isLoading={isLoading} fetchData={fetchData} />
      </div>
    </div>
  )
}

export default OrganizationInfo
