import {Route, Routes} from 'react-router-dom'
import {PolicyCard} from './PolicyCard'
import PolicyHistory from './PolicyHistory'
import PolicyInfo from './PolicyInfo'
import PolicyWordings from './PolicyWordings'

function AboutPolicyMain() {
  return (
    <div>
      <Routes>
        <Route element={<PolicyCard />}>
          <Route path={`/wordings/:policy_id/*`} element={<PolicyInfo />} />
          <Route path={`/history/:policy_id/*`} element={<PolicyHistory />} />
          <Route path={`/archive/:policy_id/*`} element={<PolicyWordings />} />
          <Route index element={<PolicyHistory />} />
        </Route>
      </Routes>
    </div>
  )
}

export default AboutPolicyMain
