import {Suspense, useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import AppContext from './AppContext'
import usePostApi from './modules/custom_hooks/usePostApi'
import secureLocalStorage from 'react-secure-storage'
import {ToastContainer, toast, Slide} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {UseToast} from './modules/useToast'
import {useNavigate} from 'react-router-dom'
import './App.css'

const App = () => {
  const navigate = useNavigate()
  const {execute} = usePostApi()
  const [loading, setLoading] = useState(true)
  const [podsData, setPodsData] = useState(null)
  const getPodsData = async (showToaster?: boolean) => {
    try {
      setPodsData(null)
      setLoading(true)

      const url = '/pod/list'
      const formData = {}

      const response = await execute(url, 'POST', formData)
      setPodsData(response.data)
      setLoading(false)
      secureLocalStorage.setItem('token_mod_signature', response.token_mod_signature)
      secureLocalStorage.setItem('jb_pods_list', response?.data)
      if (showToaster) {
        return UseToast(
          response.status_message,
          {
            position: 'bottom-right',
            theme: 'colored',
            autoClose: true,
          },
          'success'
        )
      }
      return response
    } catch (error: any) {
      console.error('Error:', error)
      setLoading(false)
      // Show error toast
      if (showToaster) {
        return UseToast(
          error.response?.data?.status_message,
          {
            position: 'bottom-right',
            theme: 'colored',
            autoClose: true,
          },
          'error'
        )
      }

      return error.response?.data?.status_message
    }
  }

  useEffect(() => {
    return () => {
      toast.dismiss()
    }
  }, [navigate])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <AppContext.Provider
              value={{podsData: podsData, getPodsData: getPodsData, isLoading: loading}}
            >
              <Outlet />
            </AppContext.Provider>
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
      <ToastContainer
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
        transition={Slide}
      />
    </Suspense>
  )
}

export {App}
