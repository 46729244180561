import {useMemo, useState} from 'react'
import {Pagination} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import {useTable, usePagination} from 'react-table'
import {PageTitle} from '../../../../../_metronic/layout/core'
import ScheduleVacanciesInterview from '../schedule_interview/ScheduleVacanciesInterview'
import {useEffect} from 'react'
import InterviewsknowmoreModal from '../../interviews/Interviewsknowmore/InterviewknowmoreModal'
import ScheduleCandidateInterview from '../../candidates/candidate_history/candidate_interviews/ScheduleCandidateInterview'
import Interviewsknowmore from '../../interviews/Interviewsknowmore/Interviewknowmore'
import usePostApi from '../../../custom_hooks/usePostApi'

const DataTable = ({columns, data, fetchData, loading, props}) => {
  const intl = useIntl()
  const {id, vacancy_id} = useParams()
  const [showModal, setShowModal] = useState(false)
  const [showKM, setShowKM] = useState(false)
  const [addModal, setAddModal] = useState(false)
  const {execute} = usePostApi()
  const [vacancyId, updateVacancyId] = useState('1')
  const [vacancydata, setvacancyData] = useState([])

  const getVacancies = async () => {
    try {
      const url = `/pod/vacancy/get`
      const formData = {
        vacancy_id: vacancy_id,
      }

      const response = await execute(url, 'POST', formData)
      setvacancyData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getVacancies()
  }, [])

  const handleOpenModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }
  const [inputVal, setInputVal] = useState('')
  const [currentStatus, setCurrentStatus] = useState('Scheduled')
  const filteredData = useMemo(() => {
    return data
      ? data.filter((each) => {
          const nameMatches =
            each.candidate_first_name &&
            each.candidate_first_name.toLowerCase().includes(inputVal.toLowerCase())
          const currentStatusMatches =
            each.status && each.status.toLowerCase() === currentStatus.toLowerCase()

          return nameMatches
        })
      : []
  }, [inputVal, data, currentStatus])

  const tableInstance = useTable({columns, data: filteredData}, usePagination)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: {pageIndex, pageSize},
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    gotoPage,
    setPageSize,
  } = tableInstance

  const pageOptions = [10, 20, 30, 40, 50]
  const policyBC = [
    {
      title: `(${id})`,
      path: `/${id}/vacancies`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: ``,
      path: `/${id}/vacancies`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `Interviews`,
      path: `/${id}/vacancies`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: ``,
      path: `/${id}/vacancies`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: vacancydata[0] ? vacancydata[0].job_role_name : '',
      path: `/${id}/vacancies/interviews/${vacancy_id}`,
      isSeparator: false,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={policyBC}>Interviews</PageTitle>
      <InterviewsknowmoreModal showModal={showKM} setShowModal={setShowKM} />
      <>
        {loading ? (
          <div className='card h-250px'>
            <div className='m-auto d-flex flex-column align-items-center'>
              <div className='spinner-border spinner-primary mr-15'></div>
            </div>
          </div>
        ) : data.length > 0 ? (
          <>
            <div className='d-flex flex-wrap flex-stack mb-4'>
              <div className='d-flex'>
                <h3 className='mb-3 fw-semibold'>Interviews</h3>
              </div>
              <div className='d-flex'>
                <div className='d-flex'>
                  <div className='d-flex align-items-center position-relative me-4'>
                    <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                    <input
                      type='search'
                      id='kt_filter_search'
                      className='form-control form-control-white form-control-sm w-150px ps-9'
                      placeholder='Search'
                      value={inputVal}
                      onChange={(e) => setInputVal(e.target.value)}
                    />
                  </div>
                  <div
                    className='d-flex justify-content-center align-items-center'
                    data-toggle='tooltip'
                    title='Schedule Interview'
                  >
                    <button
                      className='btn btn-sm btn-light-primary me-3'
                      onClick={() => {
                        setAddModal(true)
                        updateVacancyId(vacancy_id)
                      }}
                    >
                      <KTIcon iconName='plus' className='fs-3' />
                      Schedule
                    </button>
                  </div>
                </div>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <button className='btn btn-sm btn-light' onClick={() => setShowKM(true)}>
                    <i className='ki-duotone ki-information-3 fs-3'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                    </i>
                    Know More
                  </button>
                </div>
              </div>
            </div>
            {/* <p className='fw-semibold fs-6 text-gray-600 mb-4'>
              Seamlessly create and track interviews, ensuring no details are overlooked during the
              hiring process.
            </p> */}

            <div className='card'>
              <div className='card mb-0 mb-xl-8 p-10 py-5  overflow-auto'>
                <table
                  className='table table-row-dashed table-row-gray-300 align-middle'
                  {...getTableProps()}
                >
                  <thead>
                    <tr className='fw-semibold text-muted bg-light rounded'>
                      <th className='min-w-150px default-cursor text-start ps-3'>
                        Interview Round
                      </th>

                      <th className='min-w-140px text-start default-cursor'>Interview Type</th>
                      <th className='min-w-140px text-start default-cursor'>Interviewer Name</th>

                      <th className='min-w-100px text-start default-cursor'>Interview Date</th>
                      {/* <th className='min-w-100px text-start default-cursor'>
                        Interview Start Time
                      </th> */}
                      <th className='min-w-100px text-start default-cursor'>Interview Time</th>
                      <th className='min-w-100px text-start default-cursor'>Status</th>

                      {/* <th className='min-w-100px text-start'>
                          {intl.formatMessage({id: 'MENU.ACTIONS'})}
                        </th> */}
                    </tr>
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.length > 0 ? (
                      page.map((row) => {
                        prepareRow(row)
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => (
                              <td className='text-center' {...cell.getCellProps()}>
                                {cell.render('Cell')}
                              </td>
                            ))}
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td className='card'>
                          <p className='text-dark text-hover-primary default-cursor d-block fs-6 m-0 my-5'>
                            {/* {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})} */}
                            No results found.
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {data.length > 10 && (
                <Pagination>
                  <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                  <Pagination.Prev onClick={previousPage} disabled={!canPreviousPage} />

                  {[...Array(pageCount)].map((_, i) => (
                    <Pagination.Item key={i} active={i === pageIndex} onClick={() => gotoPage(i)}>
                      {i + 1}
                    </Pagination.Item>
                  ))}

                  <Pagination.Next onClick={nextPage} disabled={!canNextPage} />
                  <Pagination.Last
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  />

                  <div className='d-flex align-items-center mx-5'>
                    <span className='me-2'>Show</span>
                    <select
                      className='form-select form-select-solid fw-semibold w-75px me-2'
                      value={pageSize}
                      onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                      {pageOptions.map((option) => (
                        <option key={option} value={option} className='select-bg'>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </Pagination>
              )}
            </div>
          </>
        ) : (
          <div className=''>
            <div className='m-2 p-4'>
              <h3 className='fs-3 fw-semibold'>Manage Interviews</h3>

              <p className=''>
                Welcome to the Vacancy-Interviews Page! Here, you have the tools to seamlessly
                schedule and track interviews for a Vacancy, ensuring a smooth transition into your
                organization.
              </p>
              <Interviewsknowmore />
              <div
                className='d-flex justify-content-center align-items-center'
                data-toggle='tooltip'
                title='Schedule Interview'
              >
                <button
                  className='btn btn-sm btn-light-primary me-3'
                  // onClick={handleOpenModal}
                  onClick={() => {
                    setAddModal(true)
                    updateVacancyId(vacancy_id)
                  }}
                >
                  <KTIcon iconName='plus' className='fs-3' />
                  Schedule
                </button>
              </div>
            </div>
          </div>
        )}
      </>
      <ScheduleVacanciesInterview
        showModal={addModal}
        setShowModal={setAddModal}
        vacancyId={vacancyId}
        fetchData={fetchData}
      />
    </>
  )
}

const VacancyInterviewsTable = () => {
  const {execute, loading} = usePostApi()
  const [interviewData, setinterviewsData] = useState([])
  const {vacancy_id} = useParams()
  const getInterviews = async () => {
    try {
      const url = `/pod/interview/vacancy`
      const formData = {
        vacancy_id: vacancy_id,
      }

      const response = await execute(url, 'POST', formData)
      setinterviewsData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getInterviews()
  }, [])

  const columns = useMemo(() => [
    {
      Header: 'Country Name',
      accessor: 'Country Name',
      Cell: ({row}) => {
        return (
          <div className='text-start ms-3'>
            <p className='text-dark fw-semibold text-hover-primary d-block fs-6 default-cursor'>
              {row.original.interview_round}
            </p>
          </div>
        )
      },
      disableSortBy: true,
    },

    {
      Header: 'Department Name',
      accessor: 'Department Name',
      Cell: ({row}) => {
        return (
          <div className='text-start'>
            <p className='text-dark  text-hover-primary d-block fs-6 default-cursor'>
              {row.original.interview_type_name}
            </p>
          </div>
        )
      },
      disableSortBy: true,
    },

    {
      Header: 'HiringManager Name',
      accessor: 'HiringManager Name',
      Cell: ({row}) => {
        return (
          <div className='text-start'>
            <p className='text-dark  text-hover-primary d-block fs-6 default-cursor'>
              {row.original.interviewer_name}
            </p>
          </div>
        )
      },
      disableSortBy: true,
    },
    // {
    //   Header: 'Interview Mode',
    //   accessor: 'Interview Mode',
    //   Cell: ({row}) => {
    //     return (
    //       <div className='text-start'>
    //         <p className='text-dark  text-hover-primary d-block fs-6 default-cursor'>
    //           {row.original.interview_coordinates.mode}
    //         </p>
    //       </div>
    //     )
    //   },
    //   disableSortBy: true,
    // },

    {
      Header: 'State Name',
      accessor: 'State Name',
      Cell: ({row}) => {
        return (
          <div className='text-start'>
            <p className='text-dark  text-hover-primary d-block fs-6 default-cursor'>
              {row.original.interview_date}
            </p>
          </div>
        )
      },
      disableSortBy: true,
    },
    {
      Header: 'Time',
      accessor: 'Time',
      Cell: ({row}) => {
        return (
          <div className='text-start'>
            <p className='text-dark fw-normal text-hover-primary d-block fs-6 default-cursor'>
              {row.original.interview_start_time?.slice(0, 5)} -{' '}
              {row.original.interview_end_time?.slice(0, 5)}
            </p>
          </div>
        )
      },
      disableSortBy: true,
    },

    // {
    //   Header: 'State',
    //   accessor: 'State',
    //   Cell: ({row}) => {
    //     return (
    //       <div className='text-start'>
    //         <p className='text-dark  text-hover-primary d-block fs-6 default-cursor'>
    //           {row.original.interview_end_time.slice(11, 19)}
    //         </p>
    //       </div>
    //     )
    //   },
    //   disableSortBy: true,
    // },

    {
      Header: 'State Nam',
      accessor: 'State Nam',
      Cell: ({row}) => {
        return (
          <div className='text-start'>
            <p className='text-dark  text-hover-primary d-block fs-6 default-cursor'>
              {row.original.status}
            </p>
          </div>
        )
      },
      disableSortBy: true,
    },
  ])

  return (
    <DataTable
      columns={columns}
      data={interviewData ? interviewData : []}
      loading={loading}
      fetchData={getInterviews}
    />
  )
}

export default VacancyInterviewsTable
