import {useEffect, useContext} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../../../../_metronic/helpers'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useThemeMode} from '../../../../../../../_metronic/partials'
import CandidateInfoContext from '../../../CandidateInfoContext'
import _ from 'lodash'
import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2'

interface Props {
  showModal: boolean
  setShowModal(val: boolean): void
  addFamilyMember: any
  editFamilyMember: any
  type: string
  index: null | number
  initialVals: any
}
const phoneRegexes: any = {
  AM: /^(\+?374|0)((10|[9|7][0-9])\d{6}$|[2-4]\d{7}$)/,
  AE: /^((\+?971)|0)?5[024568]\d{7}$/,
  BH: /^(\+?973)?(3|6)\d{7}$/,
  DZ: /^(\+?213|0)(5|6|7)\d{8}$/,
  EG: /^((\+?20)|0)?1[0125]\d{8}$/,
  IQ: /^(\+?964|0)?7[0-9]\d{8}$/,
  JO: /^(\+?962|0)?7[789]\d{7}$/,
  KW: /^(\+?965)[569]\d{7}$/,
  SA: /^(!?(\+?966)|0)?5\d{8}$/,
  SY: /^(!?(\+?963)|0)?9\d{8}$/,
  TN: /^(\+?216)?[2459]\d{7}$/,
  BY: /^(\+?375)?(24|25|29|33|44)\d{7}$/,
  BG: /^(\+?359|0)?8[789]\d{7}$/,
  BD: /^(\+?880|0)1[13456789][0-9]{8}$/,
  CZ: /^(\+?420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
  DK: /^(\+?45)?\s?\d{2}\s?\d{2}\s?\d{2}\s?\d{2}$/,
  DE: /^(\+49)?0?1(5[0-25-9]\d|6([23]|0\d?)|7([0-57-9]|6\d))\d{7}$/,
  AT: /^(\+43|0)\d{1,4}\d{3,12}$/,
  GR: /^(\+?30|0)?(69\d{8})$/,
  AU: /^(\+?61|0)4\d{8}$/,
  GB: /^(\+?44|0)7\d{9}$/,
  GG: /^(\+?44|0)1481\d{6}$/,
  GH: /^(\+233|0)(20|50|24|54|27|57|26|56|23|28)\d{7}$/,
  HK: /^(\+?852[-\s]?)?[456789]\d{3}[-\s]?\d{4}$/,
  MO: /^(\+?853[-\s]?)?[6]\d{3}[-\s]?\d{4}$/,
  IE: /^(\+?353|0)8[356789]\d{7}$/,
  IN: /^(\+?91|0)?[6789]\d{9}$/,
  KE: /^(\+?254|0)(7|1)\d{8}$/,
  MT: /^(\+?356|0)?(99|79|77|21|27|22|25)[0-9]{6}$/,
  MU: /^(\+?230|0)?\d{8}$/,
  NG: /^(\+?234|0)?[789]\d{9}$/,
  NZ: /^(\+?64|0)[28]\d{7,9}$/,
  PK: /^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/,
  RW: /^(\+?250|0)?[7]\d{8}$/,
  SG: /^(\+65)?[89]\d{7}$/,
  SL: /^(?:0|94|\+94)?(7(0|1|2|5|6|7|8)( |-)?\d)\d{6}$/,
  TZ: /^(\+?255|0)?[67]\d{8}$/,
  UG: /^(\+?256|0)?[7]\d{8}$/,
  US: /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/,
  ZA: /^(\+?27|0)\d{9}$/,
  ZM: /^(\+?26)?09[567]\d{7}$/,
  CL: /^(\+?56|0)[2-9]\d{1}\d{7}$/,
  EC: /^(\+?593|0)([2-7]|9[2-9])\d{7}$/,
  ES: /^(\+?34)?(6\d{1}|7[1234])\d{7}$/,
  MX: /^(\+?52)?(1|01)?\d{10,11}$/,
  PA: /^(\+?507)\d{7,8}$/,
  PY: /^(\+?595|0)9[9876]\d{7}$/,
  UY: /^(\+598|0)9[1-9][\d]{6}$/,
  EE: /^(\+?372)?\s?(5|8[1-4])\s?([0-9]\s?){6,7}$/,
  IR: /^(\+?98[\-\s]?|0)9[0-39]\d[\-\s]?\d{3}[\-\s]?\d{4}$/,
  FI: /^(\+?358|0)\s?(4(0|1|2|4|5|6)?|50)\s?(\d\s?){4,8}\d$/,
  FJ: /^(\+?679)?\s?\d{3}\s?\d{4}$/,
  FO: /^(\+?298)?\s?\d{2}\s?\d{2}\s?\d{2}$/,
  FR: /^(\+?33|0)[67]\d{8}$/,
  GF: /^(\+?594|0|00594)[67]\d{8}$/,
  GP: /^(\+?590|0|00590)[67]\d{8}$/,
  MQ: /^(\+?596|0|00596)[67]\d{8}$/,
  RE: /^(\+?262|0|00262)[67]\d{8}$/,
  IL: /^(\+972|0)[ ]?([23489]|5[012345689]|77)[ ]?[1-9]\d{6}$/,
  HU: /^(\+?36)(20|30|70)\d{7}$/,
  ID: /^(\+?62|0)8(1[123456789]|2[1238]|3[1238]|5[12356789]|7[78]|9[56789]|8[123456789])([\s?|\d]{5,11})$/,
  IT: /^(\+?39)?\s?3\d{2} ?\d{6,7}$/,
  JP: /^(\+81[ \-]?(\(0\))?|0)[6789]0[ \-]?\d{4}[ \-]?\d{4}$/,
  KZ: /^(\+?7|8)?7\d{9}$/,
  GL: /^(\+?299)?\s?\d{2}\s?\d{2}\s?\d{2}$/,
  KR: /^((\+?82)[ \-]?)?0?1([0|1|6|7|8|9]{1})[ \-]?\d{3,4}[ \-]?\d{4}$/,
  LT: /^(\+370|8)\d{8}$/,
  MY: /^(\+?6?01){1}(([0145]{1}(\-|\s)?\d{7,8})|([236789]{1}(\s|\-)?\d{7}))$/,
  NO: /^(\+?47)?[49]\d{7}$/,
  NP: /^(\+?977)?9[78]\d{8}$/,
  BE: /^(\+?32|0)4?\d{8}$/,
  NL: /^(\+?31|0)6?\d{8}$/,
  RS: /^(\+3816|06)[- \d]{5,9}$/,
  SE: /^(\+?46|0)[\s\-]?7[\s\-]?[02369]([\s\-]?\d){7}$/,
  TH: /^(\+66|66|0)\d{9}$/,
  TR: /^(\+?90|0)?5\d{9}$/,
  VN: /^(\+?84|0)(3|5|7|8|9)(\d{8})$/,
  UA: /^(\+380|0)\d{9}$/,
  VE: /^(\+?58)?(2|4)\d{9}$/,
  XK: /^(\+383|0)?(4)\d{7,8}$/,
  PL: /^(\+?48)? ?[5-8]\d ?\d{3} ?\d{3}$/,
  RU: /^(\+?7|8)?9\d{9}$/,
  QA: /^(\+974|0)?[3-9]\d{7}$/,
}

function formatPhoneNumber(value: any, dialCode: any, countryCode: string) {
  if (!value) {
    return {formattedNumber: '', isValid: false}
  }

  const numericValue = value.replace(/\D/g, '')
  const formattedNumber = numericValue.substring(dialCode.length)
  // const numericValue = value.replace(/\D/g, '')
  // const formattedNumber = numericValue.substring(dialCode.length)

  const formattedCountryCode = countryCode.toUpperCase()
  const regex = phoneRegexes[formattedCountryCode]

  if (!regex) {
    console.error(`Regex for country code ${countryCode} not found`)
    return {formattedNumber, isValid: true}
  }

  const isValid = regex.test(value)
  console.log(isValid)

  return {formattedNumber, isValid}
}

function AddEmpReference(props: Props) {
  const {mode} = useThemeMode()
  const {setCorrections, corrections, formValues, updateDataOnDB, setFormData} =
    useContext(CandidateInfoContext)
  // console.log(formValues)
  const formik = useFormik({
    initialValues: {
      id: '',
      name: '',
      designation: '',
      company: '',
      email: '',
      mobile: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      designation: Yup.string().required('Required'),
      company: Yup.string().required('Required'),
      email: Yup.string().required('Required').email('Invalid email format'),
      mobile: Yup.string().required('Required'),
    }),
    onSubmit: async (values, {resetForm}) => {
      if (props.type === 'edit') {
        const originalVal = formValues._family_employer_reference.employer_references?.filter(
          (each: any) => each.id === formik.values.id
        )
        delete originalVal[0].status

        const currentVal: any = values
        delete currentVal['status']

        if (!_.isEqual(originalVal[0], values)) {
          const updatedCorrections = {
            ...corrections,
            _family_employer_reference: {
              ...corrections._family_employer_reference,
              employer_references: {
                ...corrections._family_employer_reference.employer_references,
                [values.id]: {...values, status: 'edited'},
              },
            },
          }

          setCorrections(updatedCorrections)
          await updateDataOnDB({...formValues, corrections: updatedCorrections})
          // console.log({...formValues, corrections: updatedCorrections})
        } else {
          setCorrections((prevCorrections: any) => {
            const newCorrections = {...prevCorrections}
            const familyMem = newCorrections._family_employer_reference.employer_references
            const newFamilyMems = {...familyMem}

            delete newFamilyMems[values.id]

            newCorrections._family_employer_reference.employer_references = newFamilyMems
            return newCorrections
          })
          // await updateDataOnDB({...formValues, corrections: corrections})
          // console.log({...formValues, corrections: corrections})
        }
      } else if (props.type === 'editNewReference') {
        props.editFamilyMember(values)
      } else {
        const iniqueId = '_' + Math.random().toString(36).substr(2, 9)
        const updatedCorrections = {
          ...corrections,
          _family_employer_reference: {
            ...corrections._family_employer_reference,
            employer_references: {
              ...corrections._family_employer_reference.employer_references,
              [iniqueId]: {
                ...values,
                id: iniqueId,
                status: 'New',
              },
            },
          },
        }
        // console.log({...formValues, corrections: updatedCorrections})
        await updateDataOnDB({...formValues, corrections: updatedCorrections})
        // props.addFamilyMember(
        //   {...values, id: '_' + Math.random().toString(36).substr(2, 9)},
        //   'employer_references'
        // )
      }
      resetForm()
      props.setShowModal(false)
      //   props.addFamilyMember(
      //     {...values, id: '_' + Math.random().toString(36).substr(2, 9)},
      //     'family_members'
      //   )
      //   resetForm()
      //   props.setShowModal(false)
    },
  })

  useEffect(() => {
    if ((props.type === 'edit' || props.type === 'editNewReference') && props.initialVals) {
      formik.setValues(props.initialVals)
    } else {
      const initialValues = {
        id: '',
        name: '',
        designation: '',
        company: '',
        email: '',
        mobile: '',
      }
      formik.setValues(initialValues)
    }
  }, [props.type, props.initialVals])

  useEffect(() => {
    document.documentElement.classList.toggle('form-solid-dark-mode', mode === 'dark')
    document.documentElement.classList.toggle('form-solid-light-mode', mode === 'light')
    return () => {
      document.documentElement.classList.remove('form-solid-dark-mode')
      document.documentElement.classList.remove('form-solid-light-mode')
    }
  }, [mode])

  return (
    <Modal
      show={props.showModal}
      onHide={() => props.setShowModal(false)}
      centered
      dialogClassName='custom-modal modal-dialog-centered'
      contentClassName='custom-modal-content'
      backdrop='static'
    >
      <Modal.Body className='overflow-auto w-100'>
        <div className='d-flex justify-content-between mb-10'>
          <h3 className='fs-3 fw-semibold'>
            {props.type === 'edit' ? 'Edit Employer Reference' : 'Add Employer Reference'}
          </h3>
          <button
            type='button'
            className='d-block btn btn-sm btn-icon btn-light'
            onClick={() => props.setShowModal(false)}
          >
            <KTIcon iconName='cross' className='fs-1' />
          </button>
        </div>

        <form onSubmit={formik.handleSubmit}>
          {/* <div className='d-flex'> */}
          <div className=''>
            <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
              <span className='required'>Name</span>
            </label>
            <div className='mb-4'>
              <input
                id='name'
                type='text'
                name='name'
                className='form-control form-control-solid'
                placeholder='Enter name of the reference person'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className='text-danger'>{formik.errors.name}</div>
              ) : null}
            </div>
          </div>

          <div className=''>
            <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
              <span className='required'>Designation</span>
            </label>
            <div className='mb-4'>
              <input
                id='designation'
                type='text'
                name='designation'
                className='form-control form-control-solid'
                placeholder='Enter designation'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.designation}
              />
              {formik.touched.designation && formik.errors.designation ? (
                <div className='text-danger'>{formik.errors.designation}</div>
              ) : null}
            </div>
          </div>

          <div className=''>
            <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
              <span className='required'>Company</span>
            </label>
            <div className='mb-4'>
              <input
                id='company'
                type='text'
                name='company'
                className='form-control form-control-solid'
                placeholder='Enter company name'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.company}
              />
              {formik.touched.company && formik.errors.company ? (
                <div className='text-danger'>{formik.errors.company}</div>
              ) : null}
            </div>
          </div>

          <div className='d-flex flex-column flex-md-row justify-content-between'>
            <div className='w-100 w-md-50 me-md-2'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>E-mail</span>
              </label>
              <div className='mb-4'>
                <input
                  id='email'
                  type='text'
                  name='email'
                  className='form-control form-control-solid'
                  placeholder='Enter E-mail'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />

                {formik.touched.email && formik.errors.email ? (
                  <div className='text-danger'>{formik.errors.email}</div>
                ) : null}
              </div>
            </div>
            <div className='w-100 w-md-50 ms-md-2'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Phone/Mobile Number</span>
              </label>
              <div className='mb-4'>
                <PhoneInput
                  country={'in'}
                  value={formik.values['mobile']}
                  onChange={(value, country: any, event) => {
                    let sanitizedNumber = event.target.value
                    if (sanitizedNumber) {
                      sanitizedNumber = sanitizedNumber.replace(/[^+\d]/g, '')
                    }

                    const isValid = /^(\+972|0)([23489]|5[012345689]|77)[1-9]\d{6}$/.test(
                      sanitizedNumber
                    )
                    console.log('first', isValid, sanitizedNumber)

                    const {formattedNumber, isValid: test} = formatPhoneNumber(
                      sanitizedNumber,
                      country.dialCode,
                      country.countryCode
                    )
                    if (test) {
                      formik.setFieldValue('mobile', value)
                    } else {
                      formik.setFieldValue('mobile', '')
                      formik.setFieldError('mobile', 'Phone number is not valid')
                    }
                  }}
                  enableSearch={true}
                  containerClass='phone-input'
                />
                {/* <input
                  id='mobile'
                  type='number'
                  name='mobile'
                  className='form-control form-control-solid'
                  placeholder='Enter phone/mobile number'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.mobile}
                  onKeyDown={(e) => {
                    if (e.key === 'e' || e.key === 'E') {
                      e.preventDefault()
                    }
                  }}
                /> */}
                {formik.touched.mobile && formik.errors.mobile ? (
                  <div className='text-danger'>{formik.errors.mobile}</div>
                ) : null}
              </div>
            </div>
          </div>

          <div className='w-100 d-flex justify-content-end mt-5'>
            <button
              type='button'
              className='btn btn-light btn-sm me-2'
              onClick={() => props.setShowModal(false)}
            >
              Close
            </button>

            <button type='submit' className='btn btn-sm btn-light-primary ms-2'>
              <KTIcon iconName='check' className='fs-3 ms-1' />
              {props.type === 'edit' ? 'Update' : 'Add'}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default AddEmpReference
