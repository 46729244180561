import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'
import InvitesContent from './InvitesContent'

interface Props {
  showModal: boolean
  setShowModal(val: boolean): void
}

function InvitesKMModal(props: Props) {
  return (
    <Modal
      show={props.showModal}
      onHide={() => props.setShowModal(false)}
      centered
      dialogClassName='custom-modal modal-dialog-centered'
      contentClassName='custom-modal-content'
    >
      <Modal.Body className='overflow-auto w-100'>
        <div className='d-flex justify-content-between align-items-center'>
          <h3 className='fs-3 fw-semibold mb-4'>Manage Invites</h3>
          <button
            type='button'
            className='d-block btn btn-sm btn-icon btn-light'
            onClick={() => props.setShowModal(false)}
          >
            <KTIcon iconName='cross' className='fs-1' />
          </button>
        </div>

        <p className=''>
          Welcome to the Invites page! Here, you have the ability to manage all join invite requests
          for your pod. Take control by accepting or rejecting requests, ensuring that your team
          membership remains up-to-date and in line with your preferences.
        </p>
        <InvitesContent />
      </Modal.Body>
    </Modal>
  )
}

export default InvitesKMModal
