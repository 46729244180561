import {Route, Routes} from 'react-router-dom'
import EmpCard from './EmpCard'
import CandidatateHistory from './CandidatateHistory'
// import CandidateAttachments from './CandidateAttachments'
import CandidateBasicInfo from './CandidateBasicInfo'

function EmpDetailsMain() {
  return (
    <div className='d-flex flex-column'>
      <Routes>
        <Route element={<EmpCard />}>
          <Route path={`/onboarding-info/:jobJoinCode/:pin/*`} element={<CandidateBasicInfo />} />
          <Route path={`/history/:jobJoinCode/:pin/*`} element={<CandidatateHistory />} />
          {/* <Route path={`/attachments/:jobJoinCode/*`} element={<CandidateAttachments />} /> */}
          <Route index element={<CandidateBasicInfo />} />
        </Route>
      </Routes>
    </div>
  )
}

export default EmpDetailsMain
