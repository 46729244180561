import {useEffect, useState} from 'react'
import usePostApi from '../../../custom_hooks/usePostApi'
import {useAuth} from '../../../auth'
import {Field, ErrorMessage} from 'formik'
import {useParams} from 'react-router-dom'

const dropdownDataSets = {
  gender: ['Male', 'Female', 'Non-binary', 'Prefer not to say'],
  blood_group: ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-', 'Do Not Know'],
  marital_status: [
    'Single',
    'Married',
    'Divorced',
    'Widowed',
    'Living Together',
    'Civil Partnership',
    'Prefer not to say',
  ],
  relation: [
    'Father',
    'Mother',
    'Guardian',
    'Brother',
    'Sister',
    'Wife',
    'Husband',
    'Son',
    'Daughter',
    'Mother-in-Law',
    'Father-in-Law',
    'Grandmother',
    'Grandfather',
  ],
  default: [],
}

function GetLookupDD({id, item, isInvalid, isValid}: any) {
  const {currentUser}: any = useAuth()
  const {execute, postLoading: loading} = usePostApi()
  const {jobJoinCode, pin} = useParams()
  const [lookupVals, setLookupVals] = useState([])
  const getLookupVals = async () => {
    try {
      const url = id !== 'Country' ? '/onbr/lookup-values/get' : '/master/countries/get'
      const formData = {
        pod_lookup_id: id,
        onbr_code: jobJoinCode,
        onbr_pin: pin,
      }
      const response = await execute(url, 'POST', formData)
      setLookupVals(response.data)
    } catch (error: any) {
      console.error('Error:', error)
    }
  }

  const defaultIds = ['gender', 'blood_group', 'marital_status', 'relation']
  useEffect(() => {
    if (!defaultIds.includes(id)) {
      getLookupVals()
    }
  }, [id])

  if (loading) {
    return (
      <div className='w-100 w-md-50 mb-4 px-2 d-flex justify-content-center'>
        <div className='m-auto spinner-border spinner-primary' style={{color: 'grey'}}></div>
      </div>
    )
  }

  const getOptions = () => {
    switch (id) {
      case 'gender':
        return (
          <>
            {dropdownDataSets['gender'].map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </>
        )
      case 'blood_group':
        return (
          <>
            {dropdownDataSets['blood_group'].map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </>
        )
      case 'marital_status':
        return (
          <>
            {dropdownDataSets['marital_status'].map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </>
        )
      case 'relation':
        return (
          <>
            {dropdownDataSets['relation'].map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </>
        )
      case 'Country':
        return (
          <>
            {(lookupVals || []).map((each: any, index: number) => (
              <option key={index} value={each.name}>
                {each.name}
              </option>
            ))}
          </>
        )
      default:
        return (
          <>
            {(lookupVals || []).map((each: any, index: number) => (
              <option key={index} value={each.lookup_value}>
                {each.lookup_value}
              </option>
            ))}
          </>
        )
    }
  }

  return (
    <>
      <div key={item.id} className='w-100 w-md-50 mb-4 px-2'>
        <label
          htmlFor={item.id}
          className={`fs-6 fw-semibold form-label ${item.is_required ? 'required' : ''}`}
        >
          {item.field_name}
        </label>
        <Field
          as='select'
          id={item.id}
          name={item.id}
          className={`form-select ${isInvalid ? 'is-invalid' : ''} ${isValid ? 'is-valid' : ''}`}
        >
          <option value=''> {item.prompt}</option>
          {getOptions()}
        </Field>
        <div className='text-danger'>
          <ErrorMessage name={item.id} />
        </div>
      </div>
    </>
  )
}

export default GetLookupDD
