import {useEffect, useState} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {KTIcon} from '../../../../../_metronic/helpers'
import usePostApi from '../../../custom_hooks/usePostApi'
import clsx from 'clsx'

import {useThemeMode} from '../../../../../_metronic/partials'
import {FindPodIdByCode} from '../../../FindPodIdByPodCode'
import {PhoneNumberUtil} from 'google-libphonenumber'
import {useIntl} from 'react-intl'

import {useLang} from '../../../../../_metronic/i18n/Metronici18n'
import {useParams} from 'react-router-dom'
import {UseToast} from '../../../useToast'
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

const registrationSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('First Name is required'),
  last_name: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Last Name is required'),
  //   role_id: Yup.string().required('Role is required'),

  email: Yup.string()
    .matches(emailRegex, 'Please Enter a Valid E-mail Address')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
  gender: Yup.string().required('Gender is Required'),
})

function CreateNewCandidates({closeModal, fetchData}) {
  const [imgData, setImgData] = useState(null)
  const [imgName, setImgName] = useState('')
  const {mode} = useThemeMode()
  const [data, setData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    gender: '',
    mobile: '',
    alternate_mobile: '',
    pod_code: '',
    candidate_image: '',
  })

  const intl = useIntl()
  const {id} = useParams()
  const podId = FindPodIdByCode()
  const {execute, postLoading} = usePostApi()
  const [currentStep, setCurrentStep] = useState(0)

  const set2ndIndex = () => {
    setCurrentStep(1)
  }

  const handleSubmit = async (formdata) => {
    try {
      const url = `/pod/candidate/create`

      const formData = new FormData()
      formData.append('candidate_image', imgData)

      formData.append('pod_id', podId)
      formData.append('first_name', data.first_name)
      formData.append('last_name', data.last_name)
      formData.append('gender', data.gender)
      formData.append('email', data.email)
      formData.append('mobile', data.mobile)
      formData.append('alternate_mobile', data.alternate_mobile)
      formData.append('pod_code', id)

      // console.log(formData)
      const response = await execute(url, 'POST', formData)
      setData({
        first_name: '',
        last_name: '',
        email: '',
        gender: '',
        mobile: '',
        alternate_mobile: '',
        candidate_image: '',
      })

      closeModal()
      fetchData()
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
    } catch (error) {
      console.error('Error:', error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }

  const steps = [
    <Step1 set2ndIndex={set2ndIndex} data={data} setData={setData} />,
    <Step2
      data={data}
      setData={setData}
      handleSubmit={handleSubmit}
      setImgData={setImgData}
      imgName={imgName}
      setImgName={setImgName}
    />,
  ]

  const nextStep = () => {
    if (currentStep === 1) {
      const step2Btn = document.getElementById('step2_submit_btn')
      if (step2Btn) {
        step2Btn.click()
      }
    }
    if (currentStep === 0) {
      const step1Btn = document.getElementById('step1_submit_btn')
      if (step1Btn) {
        step1Btn.click()
      }
    }
  }

  // }
  const prevStep = () => {
    if (currentStep !== 0) {
      setCurrentStep(currentStep - 1)
    }
  }

  return (
    <>
      <div className='card py-0 w-100'>
        <div className='w-100 m-auto'>
          <div className='d-flex justify-content-between mb-10'>
            <h4 className='fw-semibold'>Create New Candidate</h4>
            <button
              type='button'
              className='d-block btn btn-sm btn-icon btn-light'
              onClick={closeModal}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </button>
          </div>
          {/* <div className='w-100 d-flex flex-column flex-lg-row justify-content-between'> */}
          <div className='w-100 row m-0'>
            <div className='col-lg-5 p-0'>
              <div className=''>
                <div className='d-flex'>
                  <div
                    style={{borderRadius: '10px'}}
                    className={clsx(
                      'w-40px h-40px btn d-flex justify-content-center align-items-center',
                      {
                        'btn-primary': currentStep === 0,
                        'btn-light-primary': currentStep !== 0,
                      }
                    )}
                  >
                    {currentStep === 0 ? 1 : <i className='stepper-check fas fa-check'></i>}
                  </div>
                  <div className='ms-5'>
                    <h6
                      className={clsx('mb-0 fw-semibold', {
                        'text-muted': currentStep >= 0,
                      })}
                    >
                      Step 1
                    </h6>
                    <p
                      className={clsx('fs-6 fw-semibold mb-0', {
                        'text-muted': currentStep >= 0,
                      })}
                    >
                      Candidate Details
                    </p>
                  </div>
                </div>
                <div className='ms-6 h-30px border-left border-dashed border-muted border-top-0 border-bottom-0 border-right-0 border-left-1'></div>
                <div className='d-flex'>
                  <div
                    style={{borderRadius: '10px'}}
                    className={clsx(
                      'w-40px h-40px btn d-flex justify-content-center align-items-center',
                      {
                        'btn-primary': currentStep === 1,
                        'btn-light-primary': currentStep !== 1,
                      }
                    )}
                  >
                    {currentStep > 1 ? <i className='stepper-check fas fa-check'></i> : 2}
                  </div>
                  <div className='ms-5 '>
                    <h6
                      className={clsx('mb-0 fw-semibold', {
                        'text-muted': currentStep >= 1,
                      })}
                    >
                      Step 2
                    </h6>
                    <p
                      className={clsx('fs-6 fw-semibold', {
                        'text-muted': currentStep >= 1,
                      })}
                    >
                      Contact Details
                    </p>
                  </div>
                </div>
                {/* Add more steps here */}
              </div>
            </div>
            <div className='col-lg-7 p-0 m-0'>{steps[currentStep]}</div>
          </div>

          <div className='w-100 d-flex flex-stack mt-5'>
            <div className='mr-2'>
              {currentStep > 0 && (
                <button onClick={prevStep} type='button' className='btn btn-sm btn-light me-3'>
                  <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                  Back
                </button>
              )}
            </div>

            <div>
              <button type='submit' className='btn btn-sm btn-light-primary' onClick={nextStep}>
                {currentStep === steps.length - 1 ? (
                  <>
                    {postLoading && (
                      <div
                        className='spinner-border spinner-primary mr-15'
                        style={{height: '12px', width: '12px'}}
                      ></div>
                    )}
                    Create Candidate
                  </>
                ) : (
                  'Continue'
                )}
                {/* {currentStep < steps.length - 1 ? 'Continue' : 'Create POD'} */}
                <KTIcon iconName='arrow-right' className='fs-3 ms-1' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Step1 = (props) => {
  const onSubmit = (values) => {
    props.setData({...values})
    props.set2ndIndex()
  }

  return (
    <Formik validationSchema={registrationSchema} initialValues={props.data} onSubmit={onSubmit}>
      {({setFieldValue, values, handleChange}) => {
        return (
          <Form className='w-100'>
            <div className='w-100 mb-5'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>First Name</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title="Enter the candidate's first name."
                ></i>
              </label>
              <Field
                type='text'
                name='first_name'
                className='form-control form-control-solid'
                placeholder='Enter First Name'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='first_name' />
              </div>
            </div>
            <div className='w-100 mb-5'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Last Name</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title="Enter the candidate's last name."
                ></i>
              </label>
              <Field
                type='text'
                name='last_name'
                className='form-control form-control-solid'
                placeholder='Enter Last Name'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='last_name' />
              </div>
            </div>
            {/* </div> */}
            <div className='w-100 mb-5'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Gender</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Specify your Gender here,if you choose not to you can select "Others" Option'
                ></i>
              </label>

              {/* <div class='form-check form-check-inline'>
                <input
                  class='form-check-input'
                  type='radio'
                  name='inlineRadioOptions'
                  id='inlineRadio1'
                  value='option1'
                />
                <label className='form-check-label fs-6' for='inlineRadio1'>
                  Male
                </label>
              </div>
              <div class='form-check form-check-inline'>
                <input
                  class='form-check-input'
                  type='radio'
                  name='inlineRadioOptions'
                  id='inlineRadio2'
                  value='option2'
                />
                <label className='form-check-label fs-6' for='inlineRadio2'>
                  Female
                </label>
              </div>
              <div class='form-check form-check-inline'>
                <input
                  class='form-check-input'
                  type='radio'
                  name='inlineRadioOptions'
                  id='inlineRadio3'
                  value='option3'
                />
                <label className='form-check-label fs-5' for='inlineRadio3'>
                  Others
                </label>
              </div> */}
              {/* <div class='form-check form-check-inline'>
                <input class='form-check-input' type='radio' name='gender' value='M' />
                <label className='form-check-label fs-6' for='inlineRadio1'>
                  Male
                </label>
              </div> */}

              {/* <div class='form-check form-check-inline'>
                <input class='form-check-input' type='radio' name='gender' value='F' />
                <label className='form-check-label fs-6' for='inlineRadio2'>
                  Female
                </label>
              </div> */}
              {/* <div class='form-check form-check-inline'>
                <input class='form-check-input' type='radio' name='gender' value='O' />
                <label className='form-check-label fs-6' for='inlineRadio3'>
                  Others
                </label>
              </div> */}

              <div className='form-check form-check-inline'>
                <input
                  className='form-check-input'
                  type='radio'
                  id='genderMale'
                  name='gender'
                  value='M'
                  checked={values.gender === 'M'} // Check if the value is 'M' (Male)
                  onChange={handleChange} // Handle change and update Formik state
                />
                <label className='form-check-label fs-6' htmlFor='genderMale'>
                  Male
                </label>
              </div>
              <div className='form-check form-check-inline'>
                <input
                  className='form-check-input'
                  type='radio'
                  id='genderFemale'
                  name='gender'
                  value='F'
                  checked={values.gender === 'F'} // Check if the value is 'F' (Female)
                  onChange={handleChange} // Handle change and update Formik state
                />
                <label className='form-check-label fs-6' htmlFor='genderFemale'>
                  Female
                </label>
              </div>
              <div className='form-check form-check-inline'>
                <input
                  className='form-check-input'
                  type='radio'
                  id='genderOthers'
                  name='gender'
                  value='O'
                  checked={values.gender === 'O'} // Check if the value is 'O' (Others)
                  onChange={handleChange} // Handle change and update Formik state
                />
                <label className='form-check-label fs-6' htmlFor='genderOthers'>
                  Others
                </label>
              </div>
              <div className='text-danger mt-2'>
                <ErrorMessage name='gender' />
              </div>
            </div>

            <div className='w-100 mb-5'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>E-mail</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Enter your present Working E-mail address'
                ></i>
              </label>
              <Field
                type='email'
                name='email'
                className='form-control form-control-solid'
                placeholder='E-mail address'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='email' />
              </div>
            </div>

            <div className='d-none d-flex justify-content-end'>
              <div>
                <button
                  type='submit'
                  className='btn btn-sm btn-light me-2'
                  //   onClick={() => setAddNewMemberPopover(null)}
                >
                  Close
                </button>
                <button
                  type='submit'
                  className='btn btn-sm btn-light-primary ms-2'
                  id='step1_submit_btn'
                >
                  ADD
                </button>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

const step2Schema = Yup.object().shape({
  mobile: Yup.string()
    .test('is-valid-phone-number', 'Invalid phone number', (value) => {
      const phoneUtil = PhoneNumberUtil.getInstance()
      try {
        const phoneNumber = phoneUtil.parse(value)
        return phoneUtil.isValidNumber(phoneNumber)
      } catch (error) {
        return false
      }
    })
    .required('Contact Number is Required'),

  alternate_mobile: Yup.string().test('is-valid-phone-number', 'Invalid phone number', (value) => {
    if (!value) return true // Allow empty value
    const phoneUtil = PhoneNumberUtil.getInstance()
    try {
      const phoneNumber = phoneUtil.parse(value)
      return phoneUtil.isValidNumber(phoneNumber)
    } catch (error) {
      return false
    }
  }),
})

const Step2 = ({data, setData, handleSubmit, setImgData, imgName, setImgName}) => {
  const intl = useIntl()

  const onSubmit = (values) => {
    setData({...data, ...values})
    handleSubmit()
  }

  const dataURLtoFile = (dataURI, fileName) => {
    if (!dataURI) {
      return null
    }
    const byteString = atob(dataURI.split(',')[1])
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    return new File([ab], fileName, {type: mimeString})
  }

  const updateData = (key, val) => {
    setData({...data, [key]: val})
  }

  return (
    <Formik validationSchema={step2Schema} initialValues={data} onSubmit={onSubmit}>
      {({setFieldValue, values, handleChange}) => {
        return (
          <Form className='w-100'>
            <div className='w-100 mb-5'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Phone Number</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Enter Contact Number'
                ></i>
              </label>
              <Field
                type='text'
                name='mobile'
                className='form-control form-control-solid'
                placeholder='Enter Contact Number'
                onChange={(e) => {
                  updateData(e.target.name, e.target.value)
                  setFieldValue('mobile', e.target.value)
                }}
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='mobile' />
              </div>
            </div>
            <div className='w-100 mb-5'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className=''>Phone Number (Alternate)</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Alternate Contact Number.'
                ></i>
              </label>
              <Field
                type='text'
                name='alternate_mobile'
                className='form-control form-control-solid'
                placeholder='Enter Alternate Mobile Number (Optional)'
                onChange={(e) => {
                  updateData(e.target.name, e.target.value)
                  setFieldValue('alternate_mobile', e.target.value)
                }}
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='alternate_mobile' />
              </div>
            </div>

            <div className='w-100 mb-5'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className=''>Upload Photo</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Upload Photo'
                ></i>
              </label>
              <Field name='candidate_image'>
                {({form, field}) => {
                  const {setFieldValue} = form
                  const {value} = field

                  const handleImageChange = (file) => {
                    const reader = new FileReader()
                    reader.onloadend = () => {
                      const previewImage = reader.result
                      const documentName = `${Date.now()}`
                      const file = dataURLtoFile(previewImage, `candidate_image${documentName}`)
                      setImgData(file)
                    }
                    reader.readAsDataURL(file)
                  }
                  const handleHeaderChange = (e) => {
                    const file = e.target.files?.[0]
                    setFieldValue('candidate_image', file ? file.name : '')
                    setImgName(file ? file.name : '')
                    if (file) {
                      handleImageChange(file)
                    }
                  }
                  const handleInputClick = () => {
                    document.getElementById('get-document-from-user').click()
                  }
                  return (
                    <div>
                      <input
                        type='file'
                        onChange={handleHeaderChange}
                        id='get-document-from-user'
                        className='form-control form-control-solid d-none'
                      />
                      <p
                        className='form-control form-control-solid d-flex justify-content-between pe-10 cursor-pointer mb-0'
                        onClick={handleInputClick}
                      >
                        <span className='text-grey fw-normal'>
                          {imgName
                            ? imgName
                            : intl.formatMessage({id: 'LABEL.CHOOSE_FILE_TO_UPLOAD'})}{' '}
                          (Optional)
                        </span>
                        <span>
                          <KTIcon iconName='paper-clip' className='fs-3 position-absolute ms-3' />
                        </span>
                      </p>
                    </div>
                  )
                }}
              </Field>
              <ErrorMessage name='candidate_image' component='div' className='text-danger' />
            </div>

            <button type='submit' className='d-none btn btn-light-primary' id='step2_submit_btn'>
              Add
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}

export default CreateNewCandidates
