import {createContext, Dispatch, SetStateAction} from 'react'

type AppContextType = {
  corrections: any
  setCorrections: Dispatch<SetStateAction<any>>
  deleteCorrections: any
  updateDataOnDB: any
  formValues: any
  setFormData: any
}

const CandidateInfoContext = createContext<AppContextType>({
  corrections: {},
  setCorrections: () => {},
  deleteCorrections: () => {},
  updateDataOnDB: () => {},
  formValues: {},
  setFormData: () => {},
})

export default CandidateInfoContext
