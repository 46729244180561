import React from 'react'
import clsx from 'clsx'
import {useLocation} from 'react-router'
import {checkIsActive, KTIcon, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  hasChildren?: boolean
}

const SidebarHeadings: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
  hasChildren,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config

  return (
    <div>
      <span className='w-100'>
        {icon === null ? (
          <span
            className='text-uppercase'
            style={{color: '#fff', opacity: 2, fontWeight: 500, textAlign: 'center'}}
          >
            {title}
          </span>
        ) : (
          <span className='menu-title'>{title}</span>
        )}
      </span>
    </div>
  )
}

export {SidebarHeadings}
