import {useMemo, useState} from 'react'
import {Link} from 'react-router-dom'
import {useTable, usePagination} from 'react-table'
import {useIntl, FormattedMessage} from 'react-intl'
import {Pagination} from 'react-bootstrap'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {useParams} from 'react-router-dom'

const DataTable = ({columns, data}) => {
  const {id} = useParams()
  const intl = useIntl()

  const [inputVal, setInputVal] = useState('')

  const filteredData = useMemo(() => {
    return data
      ? data.filter((each) => {
          const docNameMatches =
            each.document_type_name &&
            each.document_type_name.toLowerCase().includes(inputVal.toLowerCase())
          const docIdMatches =
            each.document_identity_value &&
            each.document_identity_value.toLowerCase().includes(inputVal.toLowerCase())
          const docIssuedByMatches =
            each.issued_by && each.issued_by.toLowerCase().includes(inputVal.toLowerCase())
          const issuedCountryMatches =
            each.issued_country &&
            each.issued_country.toLowerCase().includes(inputVal.toLowerCase())
          const startDateMatches =
            each.issued_date && each.issued_date.toLowerCase().includes(inputVal.toLowerCase())
          const endDateMatches =
            each.expiry_date && each.expiry_date.toLowerCase().includes(inputVal.toLowerCase())

          return (
            docNameMatches ||
            docIdMatches ||
            startDateMatches ||
            endDateMatches ||
            docIssuedByMatches ||
            issuedCountryMatches
          )
        })
      : []
  }, [inputVal, data])

  const tableInstance = useTable({columns, data: filteredData}, usePagination)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: {pageIndex, pageSize},
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    gotoPage,
    setPageSize,
  } = tableInstance

  const pageOptions = [10, 20, 30, 40, 50]
  const count = data.length

  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-2'>
        <h3 className='fw-semibold default-cursor'>
          Attachments
          <span className='fs-6 text-gray-400 fw-normal ms-2'>{`Resources ${count}`}</span>
        </h3>

        <div className='d-flex my-2'>
          <div className='d-flex align-items-center position-relative'>
            <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
            <input
              type='search'
              id='kt_filter_search'
              className='form-control form-control-white form-control-sm w-150px ps-9'
              placeholder={intl.formatMessage({id: 'ECOMMERCE.COMMON.SEARCH'})}
              value={inputVal}
              onChange={(e) => setInputVal(e.target.value)}
            />
          </div>
          {/* <Link className='btn btn-sm btn-light-primary ms-4' to={`/${id}/onboarding`}>
            <KTIcon iconName='arrow-left' className='fs-3' />
            Back
          </Link> */}

          {/* {(is_admin === 1 || is_hr === 1) && id && ( */}
          {/* <button className='btn btn-sm btn-light-primary ms-4' id='employeeInfoToggle'>
            <KTIcon iconName='plus' className='fs-3' />
            {intl.formatMessage({id: 'BTN.UPLOAD'})}
          </button> */}
          {/* )} */}
        </div>
      </div>

      <div className='card mb-0 mb-xl-8 overflow-auto'>
        <table
          className='table table-row-dashed table-row-gray-300 align-middle'
          {...getTableProps()}
        >
          <thead>
            <tr className='fw-semibold text-muted default-cursor bg-light rounded'>
              <th className='min-w-150px text-start ps-3'>Document</th>
              <th className='min-w-150px text-start'>File ID</th>
              <th className='min-w-150px text-center'>
                {intl.formatMessage({id: 'MENU.ACTIONS'})}
              </th>
            </tr>
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.length > 0 ? (
              page.map((row) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td className='text-center' {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                )
              })
            ) : (
              <tr>
                <td className='' colSpan={3}>
                  <div className='d-flex justify-content-center'>
                    <p className='text-dark default-cursor fs-6 mt-5'>
                      {inputVal === '' ? (
                        intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
                      ) : (
                        <FormattedMessage
                          id='MSG.NO_DATA_MATCH'
                          values={{
                            inputVal: <span className='text-primary'>{inputVal}</span>,
                          }}
                        />
                      )}
                    </p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {data.length > 10 && (
        <Pagination>
          <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
          <Pagination.Prev onClick={previousPage} disabled={!canPreviousPage} />

          {[...Array(pageCount)].map((_, i) => (
            <Pagination.Item key={i} active={i === pageIndex} onClick={() => gotoPage(i)}>
              {i + 1}
            </Pagination.Item>
          ))}

          <Pagination.Next onClick={nextPage} disabled={!canNextPage} />
          <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />

          <div className='d-flex align-items-center mx-5'>
            <span className='me-2'>{intl.formatMessage({id: 'BTN.SHOW'})}</span>
            <select
              className='form-select form-select-solid fw-semibold w-75px me-2'
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              {pageOptions.map((option) => (
                <option key={option} value={option} className='select-bg'>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </Pagination>
      )}
    </>
  )
}

const DocumentsTable = ({data}) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'img',
        Cell: ({row}) => {
          return (
            <div className='min-w-170px text-start ps-3'>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-25px me-2'>
                  <img
                    src={toAbsoluteUrl('/media/svg/files/folder-document.svg')}
                    alt=''
                    className='text-primary'
                  />
                </div>
                <div className='d-flex justify-content-start flex-column'>
                  <p className='text-dark fw-semibold text-hover-primary default-cursor fs-6 m-0'>
                    {row.original.document_type_name}
                  </p>
                </div>
              </div>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'IssuedBy',
        accessor: 'issuedBy',
        Cell: ({row}) => {
          return (
            <p className={`text-dark text-hover-primary default-cursor d-block fs-6 text-start`}>
              {row.original.document_url ? row.original.file_id : 'N.A'}
            </p>
          )
        },
      },

      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({row}) => {
          const handleDownload = () => {
            if (row.original.document_url) {
              const downloadLink = document.createElement('a')
              downloadLink.href = row.original.document_url
              downloadLink.download = `${row.original.document_type_name}.pdf` // Specify your desired file name
              document.body.appendChild(downloadLink)
              downloadLink.click()
              document.body.removeChild(downloadLink)
            } else {
              alert('Document not found')
            }
          }
          return (
            <>
              {row.original.document_url ? (
                <div className='text-center'>
                  <button
                    className='btn btn-icon btn-light-primary btn-sm ms-3'
                    onClick={() => {
                      if (row.original.document_url) {
                        window.open(row.original.document_url, '_blank')
                      } else {
                        alert('Not Found')
                      }
                    }}
                    data-toggle='tooltip'
                    title='View document'
                  >
                    <KTIcon iconName='document' className='fs-3' />
                  </button>

                  <button
                    className='btn btn-icon btn-light-primary btn-sm ms-3'
                    onClick={handleDownload}
                    data-toggle='tooltip'
                    title='Download document'
                  >
                    <KTIcon iconName='arrow-down' className='fs-3' />
                  </button>
                </div>
              ) : (
                <p
                  className={`text-center text-dark text-hover-primary default-cursor d-block fs-6 text-start`}
                >
                  -
                </p>
              )}
            </>
          )
        },
      },
    ],
    []
  )

  return (
    <>
      <DataTable columns={columns} data={data} />
    </>
  )
}

export default DocumentsTable
