import React from 'react'

const Invoices = () => {
  return (
    <div className='card card-flush pt-3 mb-5 mb-xl-10'>
      {/* Card header */}
      <div className='card-header'>
        {/* Card title */}
        <div className='card-title'>
          <h2 className='fw-semibold'>Invoices</h2>
        </div>
        {/* Toolbar */}
        <div className='card-toolbar'>
          {/* Tab navigation */}
          <ul
            className='nav nav-stretch fs-5 fw-semibold nav-line-tabs nav-line-tabs-2x border-transparent'
            role='tablist'
          >
            <li className='nav-item' role='presentation'>
              <a
                id='kt_referrals_year_tab'
                className='nav-link text-active-primary active'
                data-bs-toggle='tab'
                role='tab'
                href='#kt_customer_details_invoices_1'
                aria-selected='true'
              >
                This Year
              </a>
            </li>
            <li className='nav-item' role='presentation'>
              <a
                id='kt_referrals_2019_tab'
                className='nav-link text-active-primary ms-3'
                data-bs-toggle='tab'
                role='tab'
                href='#kt_customer_details_invoices_2'
                aria-selected='false'
                // tabIndex='-1'
              >
                2020
              </a>
            </li>
            <li className='nav-item' role='presentation'>
              <a
                id='kt_referrals_2018_tab'
                className='nav-link text-active-primary ms-3'
                data-bs-toggle='tab'
                role='tab'
                href='#kt_customer_details_invoices_3'
                aria-selected='false'
                // tabIndex='-1'
              >
                2019
              </a>
            </li>
            <li className='nav-item' role='presentation'>
              <a
                id='kt_referrals_2017_tab'
                className='nav-link text-active-primary ms-3'
                data-bs-toggle='tab'
                role='tab'
                href='#kt_customer_details_invoices_4'
                aria-selected='false'
                // tabIndex='-1'
              >
                2018
              </a>
            </li>
          </ul>
          {/* End Tab navigation */}
        </div>
        {/* End Toolbar */}
      </div>
      {/* End Card header */}

      {/* Card body */}
      <div className='card-body pt-2'>
        {/* Tab Content */}
        <div id='kt_referred_users_tab_content' className='tab-content'>
          {/* Tab panel for This Year */}
          <div
            id='kt_customer_details_invoices_1'
            className='tab-pane fade show active'
            role='tabpanel'
            aria-labelledby='kt_referrals_year_tab'
          >
            {/* Table wrapper */}
            <div className='table-responsive'>
              {/* Table */}
              <table
                id='kt_customer_details_invoices_table_1'
                className='table align-middle table-row-dashed fs-6 fw-semibold gs-0 gy-4 p-0 m-0'
              >
                {/* Table header */}
                <thead className='border-bottom border-gray-200 fs-7 text-uppercase fw-semibold'>
                  <tr className='text-start text-gray-500'>
                    <th className='min-w-100px'>Order ID</th>
                    <th className='min-w-100px'>Amount</th>
                    <th className='min-w-100px'>Status</th>
                    <th className='min-w-125px'>Date</th>
                    <th className='w-100px'>Invoice</th>
                  </tr>
                </thead>
                {/* Table body */}
                <tbody className='fs-6 fw-semibold text-gray-600'>
                  {/* Sample rows (replace with your dynamic data) */}
                  <tr>
                    <td>
                      <a href='#' className='text-gray-600 text-hover-primary'>
                        102445788
                      </a>
                    </td>
                    <td className='text-success'>$38.00</td>
                    <td>
                      <span className='badge badge-light-danger'>Rejected</span>
                    </td>
                    <td>Nov 01, 2020</td>
                    <td>
                      <button className='btn btn-sm btn-light btn-active-light-primary'>
                        Download
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href='#' className='text-gray-600 text-hover-primary'>
                        423445721
                      </a>
                    </td>
                    <td className='text-danger'>$-2.60</td>
                    <td>
                      <span className='badge badge-light-warning'>Pending</span>
                    </td>
                    <td>Oct 24, 2020</td>
                    <td>
                      <button className='btn btn-sm btn-light btn-active-light-primary'>
                        Download
                      </button>
                    </td>
                  </tr>
                  {/* Add more rows as needed */}
                </tbody>
              </table>
              {/* End Table */}
            </div>
            {/* End Table wrapper */}
          </div>
          {/* End Tab panel for This Year */}
          {/* Repeat similar structures for other years */}
        </div>
        {/* End Tab Content */}
      </div>
      {/* End Card body */}
    </div>
  )
}

export default Invoices
