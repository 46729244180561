import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {FormikHelpers} from 'formik'
import usePostApi from '../../../custom_hooks/usePostApi'
import {KTIcon} from '../../../../../_metronic/helpers'
import {FindPodIdByCode} from '../../../FindPodIdByPodCode'
import {useEffect, useState} from 'react'
import {AddDepartment} from '../departments/AddDepartment'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import {useThemeMode} from '../../../../../_metronic/partials'
import {UseToast} from '../../../useToast'
import Select from 'react-select'
const AddJobRole = (props: any) => {
  const {fetchData, JobroleClosePopover} = props.props
  const podId = FindPodIdByCode()
  const intl = useIntl()
  const {execute, postLoading} = usePostApi()
  const {mode} = useThemeMode()
  const [depName, setTableData]: any | null = useState([])
  const [depOptions, setDepOptions] = useState([])
  const [singleOption, setSingleOption] = useState('')
  const depfetchData = async () => {
    try {
      const url = `/pod/department/active`
      const formData = {pod_id: podId}
      const response = await execute(url, 'POST', formData)
      setTableData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }
  useEffect(() => {
    depfetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [popover, setPopover]: any = useState(null)
  const [id, updateId]: any = useState('')
  const ClosePopover = () => {
    setPopover(null)
  }
  const openPopover = (id: any) => (
    <Popover placement='auto' className='min-w-400px'>
      <AddDepartment props={id} />
    </Popover>
  )
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .required('Job Role Name is required'),
    code: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .required('Job Role Code is required'),
    depName: Yup.string().required(intl.formatMessage({id: 'LABEL.DEP_NAME_REQUIRED'})),
    status: Yup.string().required(intl.formatMessage({id: 'LABEL.STATUS_REQUIRED'})),
  })
  const handleDepChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('depName', selectedOption.value)
    } else {
      setFieldValue('depName', '')
    }
  }
  const handleSubmit = async (values: any, {resetForm}: FormikHelpers<any>) => {
    const url = `/pod/job_roles/create`
    try {
      const requestData = {
        pod_id: podId,
        job_role_name: values.name,
        job_role_code: values.code,
        department_id: parseInt(values.depName),
        status: values.status ? 1 : 0,
      }
      const response = await execute(url, 'POST', requestData, 'post')
      resetForm()
      fetchData()
      JobroleClosePopover()
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
    } catch (error: any) {
      console.error(error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }
  useEffect(() => {
    const options: any = (depName || []).map((each: any) => ({
      value: each.dep_id,
      label: each.dep_name,
    }))
    if (options) {
      setDepOptions(options)
      if (options.length === 1) {
        setSingleOption(options[0].value)
      }
    }
  }, [depName])
  const handleCancel = (resetForm: any) => {
    resetForm()
  }
  return (
    <div>
      <Formik
        initialValues={{
          name: '',
          code: '',
          depName: '' || singleOption,
          status: true,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({values, resetForm, setFieldValue}) => {
          return (
            <Form>
              <div className='card'>
                <div className='card-header'>
                  <h3 className='card-title fw-semibold fs-3 text-darks'>Add Job Role</h3>
                  <div className='card-toolbar me-2'>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-active-light me-n5'
                      onClick={() => JobroleClosePopover()}
                    >
                      <KTIcon iconName='cross' className='fs-1' />
                    </button>
                  </div>
                </div>
                <div className='px-7 py-5'>
                  <div className='modal-body position-relative'>
                    <div>
                      <div className='d-flex mb-4'>
                        <div className='flex-fill'>
                          <label
                            htmlFor='floatingInput'
                            className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 required'
                          >
                            Job Role Name
                          </label>
                          <Field
                            type='text'
                            className='form-control form-control-solid mb-1'
                            id='floatingInput'
                            placeholder='Enter Job Role Name'
                            name='name'
                          />
                          <ErrorMessage component='div' className='text-danger' name='name' />
                        </div>
                        <div className='ms-2 flex-fill'>
                          <label
                            htmlFor='floatingInput'
                            className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 required'
                          >
                            Job Role Code
                          </label>
                          <Field
                            type='text'
                            className='form-control form-control-solid mb-1'
                            id='floatingInput'
                            placeholder='Enter Job Role Code'
                            name='code'
                          />
                          <ErrorMessage component='div' className='text-danger' name='code' />
                        </div>
                      </div>
                      <div className='d-flex flex-column mb-4'>
                        <label
                          htmlFor='depName'
                          className='fs-6 fw-semibold form-label mb-2 required'
                        >
                          {intl.formatMessage({id: 'LABEL.DEPARTMENT_NAME'})}
                        </label>
                        <div className='d-flex flex-row flex-fill'>
                          <Select
                            value={depOptions.find(
                              (option: any) => option.value === values.depName
                            )}
                            styles={{
                              control: (baseStyles: any, state: any) => ({
                                ...baseStyles,
                                backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                                color: '#ffffff',
                                borderWidth: '0px',
                              }),
                              menu: (baseStyles: any) => ({
                                ...baseStyles,
                                backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                              }),
                              singleValue: (baseStyles: any, state: any) => ({
                                ...baseStyles,
                                color: mode === 'dark' ? '#92929f' : '#000000',
                              }),
                              input: (baseStyles: any) => ({
                                ...baseStyles,
                                color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                              }),
                              option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                                ...baseStyles,
                                backgroundColor: isSelected
                                  ? '#C2C2C2'
                                  : isFocused
                                  ? '#e0e0e0'
                                  : baseStyles.backgroundColor,
                                color: isSelected
                                  ? '#000000'
                                  : isFocused
                                  ? '#000000'
                                  : baseStyles.color,
                              }),
                            }}
                            theme={(theme: any) => ({
                              ...theme,
                              borderRadius: 5,
                              backgroundColor: '#000000',
                              colors: {
                                ...theme.colors,
                                primary25: 'rgba(80, 107, 236, 0.5)',
                                primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                              },
                            })}
                            className='react-select-container flex-fill'
                            classNamePrefix='react-select'
                            options={depOptions}
                            isSearchable
                            placeholder='Select Department Name'
                            onChange={(val: any) => handleDepChange(setFieldValue, val)}
                          />
                          <OverlayTrigger
                            trigger='click'
                            placement='auto'
                            overlay={openPopover(id)}
                            show={popover === 'add_department'}
                          >
                            <button
                              type='button'
                              className='btn btn-icon btn-light btn-sm ms-2'
                              data-bs-toggle='tooltip'
                              title='Create New Department'
                              onClick={(e) => {
                                e.stopPropagation()
                                setPopover('add_department')
                                updateId({fetchData: depfetchData, ClosePopover})
                              }}
                            >
                              <KTIcon iconName='plus' className='fs-2' />
                            </button>
                          </OverlayTrigger>
                        </div>
                        <ErrorMessage component='div' className='text-danger' name='depName' />
                      </div>
                      <div className='form-floating flex-fill mb-4'>
                        <div className='form-check form-check-solid form-switch mb-0'>
                          <Field
                            className='form-check-input w-40px h-20px'
                            type='checkbox'
                            name='status'
                            id='toggle'
                            style={{
                              backgroundColor: values.status
                                ? '#009688'
                                : mode === 'dark'
                                ? '#1b1b29'
                                : '#f4f4f4',
                            }}
                          />
                          <span
                            className={`me-4 fw-semibold ${
                              values.status ? 'text-success' : 'text-danger'
                            }`}
                          >
                            {values.status ? 'Active' : 'Inactive'}
                          </span>
                          <ErrorMessage component='div' className='text-danger' name='status' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex justify-content-end'>
                    <button
                      type='reset'
                      onClick={() => {
                        handleCancel(resetForm)
                        JobroleClosePopover()
                      }}
                      className='btn btn-sm btn-light me-2'
                    >
                      {intl.formatMessage({id: 'LABEL.CANCEL'})}
                    </button>
                    <button
                      type='submit'
                      className='btn btn-sm btn-light-primary'
                      data-kt-menu-dismiss='true'
                    >
                      {postLoading ? (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      ) : (
                        <>
                          <KTIcon iconName='check' className='fs-7' />
                          Save
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export {AddJobRole}
