import Map, {Marker} from 'react-map-gl'
import maplibregl from 'maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import {useEffect, useRef, useState} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'

export default function EditMap({updateItemId, locationData}) {
  const mapRef = useRef(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [showSuggestions, setShowSuggestions] = useState(true)
  const [suggestions, setSuggestions] = useState([])
  const [searchResult, setSearchResult] = useState({
    latitude: locationData.latitude || 51.3233379650232,
    longitude: locationData.longitude || -0.481747846041145,
  })

  useEffect(() => {
    if (locationData) {
      setSearchResult({
        latitude: locationData.latitude || 51.3233379650232,
        longitude: locationData.longitude || -0.481747846041145,
      })
      if (mapRef.current) {
        mapRef.current.getMap().flyTo({
          center: [
            locationData.longitude || -0.481747846041145,
            locationData.latitude || 51.3233379650232,
          ],
          zoom: 12,
        })
      }
    }
  }, [locationData])

  const handleSearch = async () => {
    setShowSuggestions(false)
    const accessToken =
      'pk.eyJ1IjoiZ2FuZXN3YXJhcmFvIiwiYSI6ImNsdHBpeXJtZjA2eWkya28xcW8xbjg4dXcifQ.2NgizzxlIv7hziFTq6JmgQ'
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
      searchQuery
    )}.json?access_token=${accessToken}`
    try {
      const response = await fetch(url)
      const data = await response.json()
      if (data.features && data.features.length > 0) {
        const [longitude, latitude] = data.features[0].center
        handleMapClick({longitude: longitude, latitude: latitude})
        setSearchResult({longitude, latitude})
        updateItemId(data.features[0])
        mapRef.current.getMap().flyTo({center: [longitude, latitude], zoom: 12})
      } else {
        setSearchResult(null)
        console.error('No results found')
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error)
    }
  }

  const handleMapClick = async (eventOrCoordinates) => {
    const lngLat = eventOrCoordinates.lngLat || {
      lng: eventOrCoordinates.longitude,
      lat: eventOrCoordinates.latitude,
    }
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${lngLat.lng},${lngLat.lat}.json?access_token=pk.eyJ1IjoiZ2FuZXN3YXJhcmFvIiwiYSI6ImNsdHBpeXJtZjA2eWkya28xcW8xbjg4dXcifQ.2NgizzxlIv7hziFTq6JmgQ`
      )
      const data = await response.json()
      if (data.features && data.features.length > 0) {
        const [longitude, latitude] = data.features[0].center
        setSearchResult({longitude, latitude})
        updateItemId(data.features[0])
      } else {
        setSearchResult(null)
        console.error('No results found')
      }
    } catch (error) {
      console.error('Error fetching location details:', error)
    }
  }

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        const response = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
            searchQuery
          )}.json?access_token=pk.eyJ1IjoiZ2FuZXN3YXJhcmFvIiwiYSI6ImNsdHBpeXJtZjA2eWkya28xcW8xbjg4dXcifQ.2NgizzxlIv7hziFTq6JmgQ`
        )
        const data = await response.json()
        if (data.features && data.features.length > 0) {
          const names = data.features.map((feature) => feature.place_name)
          setSuggestions(names)
        } else {
          setSuggestions([])
        }
      } catch (error) {
        console.error('Error fetching suggestions:', error)
        setSuggestions([])
      }
    }

    if (searchQuery.trim() !== '') {
      fetchSuggestions()
    } else {
      setSuggestions([])
    }
  }, [searchQuery])

  return (
    <div>
      <div style={{position: 'relative'}}>
        <div style={{position: 'absolute', zIndex: 999, top: '10px', left: '10px'}}>
          <div className='d-flex align-items-center position-relative me-4'>
            <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
            <input
              type='search'
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value)
                setShowSuggestions(true)
              }}
              className='form-control form-control-white form-control-sm w-150px ps-9 mb-2'
            />
          </div>
          {showSuggestions && suggestions.length > 0 && (
            <div
              className='card'
              style={{
                overflowY: 'auto',
                borderRadius: '5px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                height: '100px',
                width: '200px',
              }}
            >
              {suggestions.map((name, index) => (
                <ul className='pt-2 pb-0 mb-0' key={index}>
                  <li
                    style={{
                      listStyleType: 'none',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setSearchQuery(name)
                      handleSearch()
                      setShowSuggestions(false)
                    }}
                  >
                    {name}
                  </li>
                </ul>
              ))}
            </div>
          )}
        </div>
        <div
          id='map-container'
          className='card'
          style={{
            height: '155px',
          }}
        >
          <Map
            ref={mapRef}
            mapLib={maplibregl}
            mapStyle='https://api.maptiler.com/maps/streets-v2/style.json?key=LiH20XNxcFiTXyT4fgjM'
            initialViewState={{
              longitude: searchResult.longitude,
              latitude: searchResult.latitude,
              zoom: 12,
            }}
            onClick={handleMapClick}
            style={{height: '100%', borderRadius: '10px'}}
          >
            <Marker
              latitude={searchResult.latitude}
              longitude={searchResult.longitude}
              offsetLeft={-20}
              offsetTop={-10}
            >
              <KTIcon iconName='geolocation' className='fs-2 text-danger fw-semibold' />
            </Marker>
          </Map>
        </div>
      </div>
    </div>
  )
}
