/* eslint-disable jsx-a11y/anchor-is-valid */
import {useContext, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import usePostApi from '../../custom_hooks/usePostApi'
import {KTIcon} from '../../../../_metronic/helpers'
import usePageTitle from '../../custom_hooks/usePageTitle'
import AppContext from '../../../AppContext'
import EditPodModal from './EditPodModal'

function Information({data, isLoading, fetchData}: any) {
  const information = data && data[0]
  const {getPodsData}: any = useContext(AppContext)
  const intl = useIntl()
  usePageTitle('Information')
  const {execute} = usePostApi()
  interface DateFormatMapping {
    [format: string]: string
  }
  const DateFormats: DateFormatMapping = {
    'dd/MM/yyyy': 'DD/MM/YYYY',
    'MM/dd/yyyy': 'MM/DD/YYYY',
    'yyyy/MM/dd': 'YYYY/MM/DD',
    'dd-MM-yyyy': 'DD-MM-YYYY',
    'MM dd, yyyy': 'MM DD, YYYY',
    'dd MMMM yyyy': 'DD Month YYYY',
    'MM dd yyyy': 'MM DD YYYY',
    'dd-MM-yy': 'DD-MM-YY',
    'yy/MM/dd': 'YY/MM/DD',
  }
  const addCurrentDateToLabel = (format: string) => {
    const currentDate = new Date()
    const formattedDate = format
      .replace('dd', currentDate.getDate().toString().padStart(2, '0'))
      .replace('DD', currentDate.getDate().toString().padStart(2, '0'))
      .replace('MM', (currentDate.getMonth() + 1).toString().padStart(2, '0'))
      .replace('yyyy', currentDate.getFullYear().toString())
      .replace('YYYY', currentDate.getFullYear().toString())
      .replace('yy', currentDate.getFullYear().toString().slice(-2))
      .replace('YY', currentDate.getFullYear().toString().slice(-2))
      .replace('Month', new Intl.DateTimeFormat('en-US', {month: 'long'}).format(currentDate))
    return `${format} (${formattedDate})`
  }
  const [timezones, setTimezones]: any = useState([])
  const getTimezones = async () => {
    try {
      const url = '/master/timezone'
      const formData = {}
      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.tz_name,
        label: each.tz_label,
      }))
      setTimezones(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }
  const getTimezoneLabel = (value: any) => {
    const timezone = timezones.find((tz: any) => tz.value === information?.pod_timezone)
    return timezone ? timezone?.label : 'No timezone found.'
  }
  useEffect(() => {
    getTimezones()
  }, [])
  const [showModal, setShowModal] = useState(false)
  const [selectedData, setSelectedData] = useState(null)
  const handleOpenModal = (data: any) => {
    setSelectedData(data)
    setShowModal(true)
  }
  const handleCloseModal = () => {
    setShowModal(false)
  }
  return (
    <>
      {isLoading ? (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : (
        <div className='card card-xl-stretch'>
          <div className='card-header'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-semibold fs-3 mb-1'>Information</span>
            </h3>
            <div className='card-toolbar'>
              <button
                className='btn btn-sm btn-light-primary btn-ripple'
                type='button'
                onClick={() => handleOpenModal(information)}
              >
                <KTIcon iconName='pencil' className='fs-7' />
                {intl.formatMessage({id: 'BTN.EDIT'})}
              </button>
            </div>
          </div>
          <div className='card-body'>
            <div className='row mb-4'>
              <label className='col-lg-4 fw-semibold text-muted'>Organization Name</label>
              <div className='col-lg-8'>
                <span className='fs-6 text-dark text-hover-primary default-cursor'>
                  {information?.pod_business_name}
                </span>
              </div>
            </div>
            <div className='row mb-4'>
              <label className='col-lg-4 fw-semibold text-muted'>Brand Name</label>
              <div className='col-lg-8'>
                <span className='fs-6 text-dark text-hover-primary default-cursor'>
                  {information?.pod_brand_name}
                </span>
              </div>
            </div>
            <div className='row mb-4'>
              <label className='col-lg-4 fw-semibold text-muted'>Industry</label>
              <div className='col-lg-8'>
                <span className='fs-6 text-dark text-hover-primary default-cursor'>
                  {information?.pod_industry_name}
                </span>
              </div>
            </div>
            <div className='row mb-4'>
              <label className='col-lg-4 fw-semibold text-muted'>Team Size</label>
              <div className='col-lg-8'>
                <span className='fs-6 text-dark text-hover-primary default-cursor'>
                  {information?.pod_team_size ? information?.pod_team_size : '-'}
                </span>
              </div>
            </div>
            <div className='row mb-4'>
              <label className='col-lg-4 fw-semibold text-muted'>Website</label>
              <div className='col-lg-8'>
                <span className='fs-6 text-dark text-hover-primary default-cursor'>
                  {information?.url ? information?.url : 'No url found.'}
                </span>
              </div>
            </div>
            <div className='row mb-4'>
              <label className='col-lg-4 fw-semibold text-muted'>Description</label>
              <div className='col-lg-8'>
                <span className='fs-6 text-dark text-hover-primary default-cursor'>
                  {information?.pod_description
                    ? information?.pod_description
                    : 'No description found.'}
                </span>
              </div>
            </div>
            <div className='row mb-4'>
              <label className='col-lg-4 fw-semibold text-muted'>Currency</label>
              <div className='col-lg-8'>
                <span className='fs-6 text-dark text-hover-primary default-cursor'>
                  {information?.pod_currency_name
                    ? information?.pod_currency_name
                    : 'No currency found.'}
                </span>
              </div>
            </div>
            <div className='row mb-4'>
              <label className='col-lg-4 fw-semibold text-muted'>Brand Color</label>
              <div className='col-lg-8'>
                <div className='d-flex'>
                  <div
                    className='color-container me-2'
                    style={{
                      backgroundColor: information?.pod_pref_color,
                      width: '20px',
                      height: '20px',
                      borderRadius: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      verticalAlign: 'middle',
                      outline: '0px',
                    }}
                  ></div>
                  <span className='fs-6 text-dark text-hover-primary default-cursor'>
                    {information?.pod_pref_color ? information?.pod_pref_color : 'No data found.'}
                  </span>
                </div>
              </div>
            </div>
            <div className='row mb-4'>
              <label className='col-lg-4 fw-semibold text-muted'>
                {intl.formatMessage({id: 'LABEL.ORGANIZATION_DATE_FORMATS'})}
              </label>
              <div className='col-lg-8'>
                <span className='fs-6 text-dark text-hover-primary default-cursor'>
                  {DateFormats[information?.pod_date_format]
                    ? addCurrentDateToLabel(DateFormats[information?.pod_date_format])
                    : 'No dateformat found.'}
                </span>
              </div>
            </div>
            <div className='row mb-4 pb-4'>
              <label className='col-lg-4 fw-semibold text-muted'>
                {intl.formatMessage({id: 'LABEL.ORGANIZATION_TIMEZONE'})}
              </label>
              <div className='col-lg-8'>
                <span className='fs-6 text-dark text-hover-primary default-cursor'>
                  {getTimezoneLabel(information?.timeZone)}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      <EditPodModal
        showModal={showModal}
        getPodsData={getPodsData}
        data={selectedData}
        fetchData={fetchData}
        closeModal={handleCloseModal}
      />
    </>
  )
}

export default Information
