import {SidebarMenuMain} from './SidebarMenuMain'
import {Link, useLocation, useParams} from 'react-router-dom'

const SidebarMenu = () => {
  const {pathname} = useLocation()
  const {id} = useParams()

  const showManageBillingsButton =
    pathname !== '/my-pods' && pathname !== '/my-invites' && pathname !== '/my-profile'

  return (
    <div className='app-sidebar-menu overflow-hidden flex-column-fluid'>
      <div
        id='kt_app_sidebar_menu_wrapper'
        className='app-sidebar-wrapper hover-scroll-overlay-y my-5 position-relative'
        style={{minHeight: '100vh'}}
        data-kt-scroll='true'
        data-kt-scroll-activate='true'
        data-kt-scroll-height='auto'
        data-kt-scroll-dependencies='#kt_app_sidebar_logo, #kt_app_sidebar_footer'
        data-kt-scroll-wrappers='#kt_app_sidebar_menu'
        data-kt-scroll-offset='5px'
        data-kt-scroll-save-state='true'
      >
        <div
          className='menu menu-column menu-rounded menu-sub-indention px-3'
          id='#kt_app_sidebar_menu'
          data-kt-menu='true'
          data-kt-menu-expand='false'
        >
          <SidebarMenuMain />
        </div>
        {showManageBillingsButton && (
          <div className='position-absolute w-100' style={{bottom: '100px', padding: '0 15px'}}>
            <Link to={`/${id}/subscriptions`} className='w-100 btn btn-custom btn-primary'>
              Manage Billings
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export {SidebarMenu}
