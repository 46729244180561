import clsx from 'clsx'
import {KTIcon} from '../../../helpers'
import {ThemeModeComponent} from '../../../assets/ts/layout'
import {ThemeModeType, useThemeMode} from './ThemeModeProvider'

/* eslint-disable jsx-a11y/anchor-is-valid */
type Props = {
  toggleBtnClass?: string
  toggleBtnIconClass?: string
  menuPlacement?: string
  menuTrigger?: string
}

const systemMode = ThemeModeComponent.getSystemMode() as 'light' | 'dark'

const ThemeModeSwitcher = ({
  toggleBtnClass = '',
  toggleBtnIconClass = 'fs-1',
  menuPlacement = 'top-start',
  menuTrigger = "{default: 'click', lg: 'hover'}",
}: Props) => {
  const {mode, updateMode} = useThemeMode()
  const calculatedMode = mode === 'system' ? systemMode : mode

  const toggleMode = () => {
    const newMode = calculatedMode === 'light' ? 'dark' : 'light'
    updateMode(newMode)
  }

  return (
    <a
      href='#'
      className={clsx('btn btn-icon', toggleBtnClass)}
      onClick={toggleMode}
      data-kt-menu-trigger={menuTrigger}
      data-kt-menu-attach='parent'
      data-kt-menu-placement={menuPlacement}
    >
      {calculatedMode === 'dark' ? (
        <KTIcon iconName='night-day' className={clsx('theme-light-hide', toggleBtnIconClass)} />
      ) : (
        <KTIcon iconName='moon' className={clsx('theme-dark-hide', toggleBtnIconClass)} />
      )}
    </a>
  )
}

export {ThemeModeSwitcher}
