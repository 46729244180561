import React, {useState, useEffect} from 'react'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import {KTIcon} from '../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {OverlayTrigger, Popover, Button} from 'react-bootstrap'
import {Modal} from 'react-bootstrap'

const PolicyDeclarations = ({policiesData, updatePoliciesData}) => {
  const intl = useIntl()
  const [policiesBoard, setPoliciesBoard] = useState(policiesData)
  const [deletePopoverId, setDeletePopoverId] = useState(null)

  useEffect(() => {
    setPoliciesBoard(policiesData)
  }, [policiesData])

  const [showEditModal, setShowEditModal] = useState(false)
  const handleCloseChange = () => {
    setShowEditModal(false)
  }

  const [showAddModal, setShowAddModal] = useState(false)
  const handleAddItemChange = () => {
    setShowAddModal(false)
  }

  const [editTaskId, setEditTaskId] = useState('')
  const [editTaskLabel, setEditTaskLabel] = useState('')
  const [editTaskType, setEditTaskType] = useState('')
  const [editPromptVal, setEditPromptVal] = useState('')

  const handleEditTask = (task) => {
    setShowEditModal(true)
    setEditTaskId(task.id)
    setEditTaskLabel(task.name)
    setEditTaskType(task.type)
    setEditPromptVal(task.prompt)
  }

  const editTask = () => {
    if (editTaskId && editTaskLabel && editPromptVal) {
      const updatedItems = policiesBoard.items.map((item) => {
        if (item.id === editTaskId) {
          return {...item, name: editTaskLabel, type: editTaskType, prompt: editPromptVal}
        }
        return item
      })
      updatePoliciesData({...policiesBoard, items: updatedItems})
      setPoliciesBoard({...policiesBoard, items: updatedItems})
      setEditTaskId('')
      setEditTaskLabel('')
      setEditTaskType('')
      setShowEditModal(false)
    }
  }

  const onDragEnd = (result) => {
    const {destination, source} = result
    if (!destination || destination.index === source.index) {
      return
    }

    const newItems = Array.from(policiesBoard.items)
    const [reorderedItem] = newItems.splice(source.index, 1)
    newItems.splice(destination.index, 0, reorderedItem)

    setPoliciesBoard({
      ...policiesBoard,
      items: newItems,
    })
    updatePoliciesData({
      ...policiesBoard,
      items: newItems,
    })
  }

  const handleDeleteConfirm = (taskId) => {
    const updatedItems = policiesBoard.items.filter((item) => item.id !== taskId)
    updatePoliciesData({...policiesBoard, items: updatedItems})
    setPoliciesBoard({...policiesBoard, items: updatedItems})
    setDeletePopoverId(null) // Close the popover after confirming delete
  }

  const deletePopover = (taskId) => (
    <Popover id={`delete-popover-${taskId}`} flip placement='auto'>
      <Popover.Header as='h3' className='fw-bold'>
        Confirm Delete
      </Popover.Header>
      <Popover.Body>
        <div className='w-100 d-flex justify-content-between'>
          <button
            className='btn btn-sm btn-danger me-2'
            onClick={() => {
              handleDeleteConfirm(taskId)
            }}
          >
            Yes
          </button>
          <button className='btn btn-sm btn-light ms-2' onClick={() => setDeletePopoverId(null)}>
            No
          </button>
        </div>
      </Popover.Body>
    </Popover>
  )

  const handleBodyClick = (e) => {
    const isInsidePopover = e.target.closest('.popover')

    if (!isInsidePopover) {
      // Click is outside the popover, so close it
      setDeletePopoverId(null)
    }
  }

  useEffect(() => {
    document.body.addEventListener('click', handleBodyClick)

    return () => {
      document.body.removeEventListener('click', handleBodyClick)
    }
  }, [])

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={policiesBoard.id}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className='col-lg-3 p-3 pb-0 mb-5'
            >
              <div>
                <header className='kanban-board-header bg-secondary mb-3'>
                  <div className='kanban-title-board'>
                    <h5 className='fw-semibold'>{policiesBoard.title}</h5>
                  </div>
                </header>
                <div className='bg-light-secondary overflow-auto' style={{height: '340px'}}>
                  {policiesBoard.items.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div
                            className='kanban-item mb-3 mx-3 card'
                            style={{position: 'relative'}}
                          >
                            <div
                              className='kanban-item-content fw-semibold d-flex align-items-center'
                              // style={{position: 'relative'}}
                            >
                              <span>
                                <KTIcon
                                  iconName='star'
                                  className='fw-semibold d-block me-1 text-danger'
                                />
                              </span>
                              <span className='d-block fw-normal'>Unique Code: {item.id}</span>
                            </div>

                            <div className='kanban-item-content fw-bold d-flex align-items-center'>
                              <span>
                                <KTIcon
                                  iconName='burger-menu-5'
                                  className='fw-semibold d-block me-1'
                                />
                              </span>
                              <span className='d-block fw-semibold'>{item.name}</span>
                            </div>

                            <div className='row fw-semibold ms-2'>
                              <label className='col-lg-6 text-muted'>Code</label>

                              <div className='col-lg-6'>
                                <span className='fs-6 text-dark fw-normal text-hover-primary default-cursor'>
                                  {item.id}
                                </span>
                              </div>
                            </div>
                            <div className='row fw-semibold ms-2'>
                              <label className='col-lg-6 text-muted'>Field Name</label>

                              <div className='col-lg-6'>
                                <span className='fs-6 text-dark fw-normal text-hover-primary default-cursor'>
                                  {item.name}
                                </span>
                              </div>
                            </div>

                            <div className='row fw-semibold ms-2'>
                              <label className='col-lg-6 text-muted'>Prompt</label>

                              <div className='col-lg-6'>
                                <span className='fs-6 text-dark fw-normal text-hover-primary default-cursor'>
                                  {item.prompt}
                                </span>
                              </div>
                            </div>

                            <div className='row fw-semibold ms-2'>
                              <label className='col-lg-6 text-muted'>Field Type</label>

                              <div className='col-lg-6'>
                                <span className='fs-6 text-dark fw-normal text-hover-primary default-cursor'>
                                  {item.type}
                                </span>
                              </div>
                            </div>

                            {item.required && item.required === true ? null : (
                              <OverlayTrigger
                                trigger='click'
                                placement='auto'
                                overlay={deletePopover(item.id)}
                                show={deletePopoverId === item.id}
                              >
                                <button
                                  style={{
                                    position: 'absolute',
                                    top: '0',
                                    right: '25px',
                                  }}
                                  className='btn btn-icon btn-sm btn-icon-danger'
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    setDeletePopoverId(item.id)
                                  }}
                                >
                                  <KTIcon iconName='trash' className='fs-5' />
                                </button>
                              </OverlayTrigger>
                            )}
                            <button
                              style={{
                                position: 'absolute',
                                top: '0',
                                right: '0',
                              }}
                              className='btn btn-icon btn-sm btn-icon-primary'
                              onClick={() => handleEditTask(item)}
                            >
                              <KTIcon iconName='pencil' className='fs-5' />
                            </button>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
                {provided.placeholder}
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Modal show={showEditModal} onHide={handleCloseChange}>
        <Modal.Header className='d-flex justify-content-between'>
          <h3>Edit Field</h3>

          <div className='card-toolbar me-3'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-active-light-primary me-n5'
              id='kt_drawer_chat_close'
              onClick={handleCloseChange}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className=''>
          <label className='fw-bold'>Edit Field Name</label>
          {/* <div className='form-group'> */}
          <input
            // id='kanban-edit-task'
            className='form-control mb-5'
            type='text'
            placeholder='Edit Field Name'
            value={editTaskLabel}
            onChange={(e) => setEditTaskLabel(e.target.value)}
          />

          <label className='fw-bold'>Edit Prompt</label>
          <input
            className='form-control mb-5'
            type='text'
            placeholder='Edit Prompt'
            value={editPromptVal}
            onChange={(e) => setEditPromptVal(e.target.value)}
          />

          <label className='fw-bold'>Field Type</label>
          <select
            // id='kanban-select-task'
            className='form-select'
            value={editTaskType}
            onChange={(e) => {
              setEditTaskType(e.target.value)
            }}
          >
            <option value=''>Select Field Type</option>
            <option value='Single Line'>Single Line</option>
            <option value='Multi Line'>Multi Line</option>
            <option value='Dropdown'>Dropdown</option>
            <option value='Date'>Date</option>
            <option value='Number'>Number</option>
            <option value='Attachment'>Attachment</option>
            <option value='Yes/No Question'>Yes/No Question</option>
          </select>
          <button
            className='d-none btn btn-warning btn-sm'
            onClick={editTask}
            id='update_field_btn'
          >
            UPDATE
          </button>
          {/* </div> */}
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-light btn-sm' onClick={handleCloseChange}>
            {intl.formatMessage({id: 'BTN.CLOSE'})}
          </button>

          <button
            className='btn btn-light-primary btn-sm'
            onClick={() => {
              const element = document.getElementById('update_field_btn')
              if (element) {
                element.click()
              }
            }}
          >
            UPDATE
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAddModal} onHide={handleAddItemChange}>
        <Modal.Header className='d-flex justify-content-between'>
          <h3>Edit Field</h3>

          <div className='card-toolbar me-3'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-active-light-primary me-n5'
              id='kt_drawer_chat_close'
              onClick={handleAddItemChange}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className=''>
          <label className='fw-bold'>Add Policy Field</label>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-light btn-sm' onClick={handleAddItemChange}>
            {intl.formatMessage({id: 'BTN.CLOSE'})}
          </button>

          <button
            className='btn btn-light-primary btn-sm'
            onClick={() => {
              const element = document.getElementById('update_field_btn')
              if (element) {
                element.click()
              }
            }}
          >
            UPDATE
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default PolicyDeclarations

// import React, {useState, useEffect} from 'react'
// import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
// import {KTIcon} from '../../../_metronic/helpers'
// import './KanbanBoard.css'
// import {Modal} from 'react-bootstrap'
// import {useIntl} from 'react-intl'
// import {OverlayTrigger, Popover, Button} from 'react-bootstrap'

// const PolicyDeclarations = ({policiesData, updatePoliciesData}) => {
//   const intl = useIntl()
//   const [policiesBoard, setPoliciesBoard] = useState(policiesData)

//   useEffect(() => {
//     setPoliciesBoard(policiesData)
//   }, [policiesData])

//   const [showEditModal, setShowEditModal] = useState(false)
//   const handleCloseChange = () => {
//     setShowEditModal(false)
//   }

//   const [showAddModal, setShowAddModal] = useState(false)
//   const handleAddItemChange = () => {
//     setShowAddModal(false)
//   }

//   const [editTaskId, setEditTaskId] = useState('')
//   const [editTaskLabel, setEditTaskLabel] = useState('')
//   const [editTaskType, setEditTaskType] = useState('')

//   const handleEditTask = (task) => {
//     setShowEditModal(true)
//     setEditTaskId(task.id)
//     setEditTaskLabel(task.name)
//     setEditTaskType(task.type)
//   }

//   const editTask = () => {
//     if (editTaskId && editTaskLabel) {
//       const updatedItems = policiesBoard.items.map((item) => {
//         if (item.id === editTaskId) {
//           return {...item, name: editTaskLabel, type: editTaskType}
//         }
//         return item
//       })
//       updatePoliciesData({...policiesBoard, items: updatedItems})
//       setPoliciesBoard({...policiesBoard, items: updatedItems})
//       setEditTaskId('')
//       setEditTaskLabel('')
//       setEditTaskType('')
//       setShowEditModal(false)
//     }
//   }

//   const onDragEnd = (result) => {
//     // Destructure the properties we need from result
//     const {destination, source} = result

//     // If no destination or dropped in the same place, do nothing
//     if (!destination || destination.index === source.index) {
//       return
//     }

//     const newItems = Array.from(policiesBoard.items)
//     const [reorderedItem] = newItems.splice(source.index, 1)
//     newItems.splice(destination.index, 0, reorderedItem)

//     setPoliciesBoard({
//       ...policiesBoard,
//       items: newItems,
//     })
//     updatePoliciesData({
//       ...policiesBoard,
//       items: newItems,
//     })
//   }

//   const handleDeleteConfirm = (taskId) => {
//     const updatedItems = policiesBoard.items.filter((item) => item.id !== taskId)
//     updatePoliciesData({...policiesBoard, items: updatedItems})
//     setPoliciesBoard({...policiesBoard, items: updatedItems})
//   }

//   const deletePopover = (taskId) => (
//     <Popover id={`delete-popover-${taskId}`} flip placement='auto'>
//       <Popover.Header as='h3'>Confirm Delete</Popover.Header>
//       <Popover.Body>
//         <Button
//           variant='danger'
//           onClick={() => {
//             handleDeleteConfirm(taskId)
//           }}
//         >
//           Yes
//         </Button>
//         <Button variant='secondary' onClick={() => setDeleteConfirmationId(null)}>
//           No
//         </Button>
//       </Popover.Body>
//     </Popover>
//   )

//   return (
//     <>
//       <DragDropContext onDragEnd={onDragEnd}>
//         <Droppable droppableId={policiesBoard.id}>
//           {(provided) => (
//             <div
//               ref={provided.innerRef}
//               {...provided.droppableProps}
//               className='col-lg-3 p-3 mb-5 min-h-300px'
//             >
//               <div className='bg-light-secondary min-h-300px'>
//                 <header className='kanban-board-header bg-secondary mb-3'>
//                   <div className='kanban-title-board'>
//                     <h5>{policiesBoard.title}</h5>
//                   </div>

//                   {/* <div className=''>
//                     <KTIcon iconName='pencil' className='fs-3' />
//                   </div> */}
//                 </header>
//                 {policiesBoard.items.map((item, index) => (
//                   <Draggable key={item.id} draggableId={item.id} index={index}>
//                     {(provided) => (
//                       <div
//                         ref={provided.innerRef}
//                         {...provided.draggableProps}
//                         {...provided.dragHandleProps}
//                         // className='kanban-item'
//                       >
//                         <div className='kanban-item mb-3 mx-3' style={{position: 'relative'}}>
//                           <div className='kanban-item-content'>{item.name}</div>

//                           {item.required && item.required === true ? null : (
//                             <OverlayTrigger
//                               trigger='click'
//                               placement='auto'
//                               overlay={deletePopover(item.id)}
//                             >
//                               <button
//                                 style={{
//                                   position: 'absolute',
//                                   bottom: '0',
//                                   right: '25px',
//                                 }}
//                                 className='btn btn-icon btn-sm btn-icon-danger'
//                               >
//                                 <KTIcon iconName='trash' className='fs-5' />
//                               </button>
//                             </OverlayTrigger>
//                           )}

//                           {
//                             <div
//                               className='menu menu-sub menu-sub-dropdown w-350px w-md-350px card'
//                               data-kt-menu='true'
//                               data-kt-menu-dismiss='none'
//                             >
//                               <h1>Test</h1>
//                             </div>
//                           }
//                           <button
//                             style={{
//                               position: 'absolute',
//                               bottom: '0',
//                               right: '0',
//                             }}
//                             className='btn btn-icon btn-sm btn-icon-primary'
//                             onClick={() => handleEditTask(item)}
//                           >
//                             <KTIcon iconName='pencil' className='fs-5' />
//                           </button>
//                         </div>
//                       </div>
//                     )}
//                   </Draggable>
//                 ))}
//                 {provided.placeholder}
//               </div>
//             </div>
//           )}
//         </Droppable>
//       </DragDropContext>

//       <Modal show={showEditModal} onHide={handleCloseChange}>
//         <Modal.Header className='d-flex justify-content-between'>
//           <h3>Edit Field</h3>

//           <div className='card-toolbar me-3'>
//             <button
//               type='button'
//               className='btn btn-sm btn-icon btn-active-light-primary me-n5'
//               id='kt_drawer_chat_close'
//               onClick={handleCloseChange}
//             >
//               <KTIcon iconName='cross' className='fs-1' />
//             </button>
//           </div>
//         </Modal.Header>
//         <Modal.Body className=''>
//           <label className='fw-bold'>Edit Field Name</label>
//           <div className='form-group'>
//             <input
//               // id='kanban-edit-task'
//               className='form-control'
//               type='text'
//               placeholder='Edit Field Name'
//               value={editTaskLabel}
//               onChange={(e) => setEditTaskLabel(e.target.value)}
//             />
//             <br />

//             <label className='fw-bold'>Field Type</label>
//             <select
//               // id='kanban-select-task'
//               className='form-select'
//               value={editTaskType}
//               onChange={(e) => {
//                 setEditTaskType(e.target.value)
//               }}
//             >
//               <option value=''>Select Field Type</option>
//               <option value='Single Line'>Single Line</option>
//               <option value='Multi Line'>Multi Line</option>
//               <option value='Dropdown'>Dropdown</option>
//               <option value='Date'>Date</option>
//               <option value='Number'>Number</option>
//               <option value='Attachment'>Attachment</option>
//               <option value='Yes/No Question'>Yes/No Question</option>
//             </select>
//             <br />
//             <button
//               className='d-none btn btn-warning btn-sm'
//               onClick={editTask}
//               id='update_field_btn'
//             >
//               UPDATE
//             </button>
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <button type='button' className='btn btn-light btn-sm' onClick={handleCloseChange}>
//             {intl.formatMessage({id: 'BTN.CLOSE'})}
//           </button>

//           <button
//             className='btn btn-light-primary btn-sm'
//             onClick={() => {
//               const element = document.getElementById('update_field_btn')
//               if (element) {
//                 element.click()
//               }
//             }}
//           >
//             UPDATE
//           </button>
//         </Modal.Footer>
//       </Modal>

//       <Modal show={showAddModal} onHide={handleAddItemChange}>
//         <Modal.Header className='d-flex justify-content-between'>
//           <h3>Edit Field</h3>

//           <div className='card-toolbar me-3'>
//             <button
//               type='button'
//               className='btn btn-sm btn-icon btn-active-light-primary me-n5'
//               id='kt_drawer_chat_close'
//               onClick={handleAddItemChange}
//             >
//               <KTIcon iconName='cross' className='fs-1' />
//             </button>
//           </div>
//         </Modal.Header>
//         <Modal.Body className=''>
//           <label className='fw-bold'>Add Policy Field</label>
//         </Modal.Body>
//         <Modal.Footer>
//           <button type='button' className='btn btn-light btn-sm' onClick={handleAddItemChange}>
//             {intl.formatMessage({id: 'BTN.CLOSE'})}
//           </button>

//           <button
//             className='btn btn-light-primary btn-sm'
//             onClick={() => {
//               const element = document.getElementById('update_field_btn')
//               if (element) {
//                 element.click()
//               }
//             }}
//           >
//             UPDATE
//           </button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   )
// }

// export default PolicyDeclarations
