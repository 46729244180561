/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth/core/Auth'
import {Languages} from './Languages'
import {defaultProfileImage} from '../../../../app/modules/core'
import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../helpers'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import secureLocalStorage from 'react-secure-storage'
import usePostApi from '../../../../app/modules/custom_hooks/usePostApi'
import {UseToast} from '../../../../app/modules/useToast'

const HeaderUserMenu = () => {
  const {currentUser, logout} = useAuth()

  const [showModalChange, setShowChange] = useState(false)

  const handleCloseChange = () => {
    setShowChange(false)
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <ChangePasswordModal
        showModalChange={showModalChange}
        handleCloseChange={handleCloseChange}
      />
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img
              src={currentUser?.profile_pic ? currentUser?.profile_pic : defaultProfileImage}
              alt='img'
            />
          </div>

          <div className='d-flex flex-column default-cursor'>
            <div className='fw-semibold d-flex align-items-center fs-5'>
              {currentUser && currentUser?.first_name} {currentUser?.last_name}
            </div>
            <span className='fw-normal text-muted text-hover-primary fs-7'>
              {currentUser && currentUser?.email}
            </span>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5 fw-normal'>
        {/* <a onClick={() => setShowChange(true)} className='menu-link px-5'>
          Change Password
        </a> */}

        <Link to='/my-profile' className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      <div className='separator my-2'></div>
      <div className='menu-item px-5 fw-normal'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}

export function ChangePasswordModal({showModalChange, handleCloseChange}) {
  const {execute} = usePostApi()
  const {logout} = useAuth()
  const intl = useIntl()
  let user_id
  let auth_token
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({user_id, auth_token} = userInfo)
  }
  const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required(
      intl.formatMessage({id: 'WARNING.CURRENT_PASSWORD_IS_REQUIRED'})
    ),

    newPassword: Yup.string()
      .required(intl.formatMessage({id: 'WARNING.NEW_PASSWORD_IS_REQUIRED'}))
      .min(8, intl.formatMessage({id: 'ERR.PASSWORD_LEN'})),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref('newPassword'), null],
        intl.formatMessage({id: 'WARNING.PASSWORD_MUST_MATCH'})
      )
      .required(intl.formatMessage({id: 'WARNING.CONFIRM_NEW_PASSWORD'})),
  })

  let initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  }

  const handleClose = () => {
    handleCloseChange()
  }

  const updatePassword = async (formData) => {
    try {
      const url = '/auth/change'
      const response = await execute(url, 'POST', formData)
      if (response.status === true) {
        handleClose()
      }

      Swal.fire({
        title: `Congratulations!`,
        text: `${response?.status_message}`,
        icon: 'success',
        confirmButtonText: intl.formatMessage({id: 'BTN.OK'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
        allowOutsideClick: false, // Prevents closing the modal by clicking outside
      }).then((result) => {
        if (result.isConfirmed || result.dismiss === Swal.DismissReason.overlay) {
          logout()
        }
      })
    } catch (error) {
      console.error('Error:', error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          autoClose: false,
          theme: 'colored',
        },
        'error'
      )
    }
  }

  const scrambleX = (str, numTimes = 2) => {
    let encoded = str
    for (let i = 0; i < numTimes; i++) {
      encoded = btoa(encoded)
    }
    return encoded
  }

  const handleSubmit = (values, {ChangeForm}) => {
    if (values.newPassword !== values.confirmPassword) {
      return
    }
    const formData = {
      user_id,
      token: auth_token,
      old_password: scrambleX(values.currentPassword),
      new_password: scrambleX(values.newPassword),
    }
    updatePassword(formData)
  }

  const ChangePassword = () => {
    const ChangePasswordBtn = document.getElementById('Change-password-btn')
    ChangePasswordBtn.click()
  }

  const [currentPWVisible, setCurrentPWVisible] = useState(false)
  const [newPWVisible, setNewPWVisible] = useState(false)
  const [confirmPWVisible, setConfirmPWVisible] = useState(false)

  const togglecurrentPWVisibility = () => {
    setCurrentPWVisible((prevVisible) => !prevVisible)
  }
  const toggleNewPWVisibility = () => {
    setNewPWVisible((prevVisible) => !prevVisible)
  }
  const toggleConfirmPWVisibility = () => {
    setConfirmPWVisible((prevVisible) => !prevVisible)
  }

  return (
    <Modal show={showModalChange} onHide={handleCloseChange}>
      <Modal.Header>
        <div className='w-100 d-flex justify-content-between align-items-centers'>
          <h3 className='fs-3 fw-semibold mt-3'>{intl.formatMessage({id: 'LABEL.ALERT'})}</h3>

          <div className='card-toolbar me-3'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-active-light-primary me-n5'
              id='kt_drawer_chat_close'
              onClick={handleCloseChange}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className='pt-0'>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ChangeForm, errors, touched}) => (
            <Form>
              <div className='modal-body p-0'>
                <div className='w-100'>
                  <label className='mt-5' htmlFor='doc-name'>
                    {intl.formatMessage({id: 'LABEL.CURRENT_PASSWORD'})}
                  </label>

                  <div style={{position: 'relative'}}>
                    <Field
                      type={currentPWVisible ? 'text' : 'password'}
                      name='currentPassword'
                      placeholder={intl.formatMessage({id: 'LABEL.CURRENT_PASSWORD'})}
                      autoComplete='off'
                      className='form-control bg-transparent'
                    />
                    <span
                      onClick={togglecurrentPWVisibility}
                      style={{
                        position: 'absolute',
                        right: '0px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                      }}
                    >
                      <KTIcon
                        iconName={currentPWVisible ? 'eye-slash' : 'eye'}
                        className='fs-2 icon'
                      />
                    </span>
                  </div>
                  <ErrorMessage name='currentPassword' component='div' className='text-danger' />
                </div>
                <div className='w-100'>
                  <label className='mt-5' htmlFor='doc-name'>
                    {intl.formatMessage({id: 'BTN.NEW_PASSWORD'})}
                  </label>

                  <div style={{position: 'relative'}}>
                    <Field
                      type={newPWVisible ? 'text' : 'password'}
                      name='newPassword'
                      placeholder={intl.formatMessage({id: 'BTN.NEW_PASSWORD'})}
                      autoComplete='off'
                      className='form-control bg-transparent'
                    />
                    <span
                      onClick={toggleNewPWVisibility}
                      style={{
                        position: 'absolute',
                        right: '0px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                      }}
                    >
                      <KTIcon iconName={newPWVisible ? 'eye-slash' : 'eye'} className='fs-2 icon' />
                    </span>
                  </div>

                  <ErrorMessage name='newPassword' component='div' className='text-danger' />
                </div>
                <div className='w-100'>
                  <label className='mt-5' htmlFor='doc-name'>
                    {intl.formatMessage({id: 'BTN.CONFIRM_NEW_PASSWORD'})}
                  </label>
                  <div style={{position: 'relative'}}>
                    <Field
                      type={confirmPWVisible ? 'text' : 'password'}
                      name='confirmPassword'
                      placeholder={intl.formatMessage({id: 'BTN.CONFIRM_NEW_PASSWORD'})}
                      autoComplete='off'
                      className='form-control bg-transparent'
                    />
                    <span
                      onClick={toggleConfirmPWVisibility}
                      style={{
                        position: 'absolute',
                        right: '0px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                      }}
                    >
                      <KTIcon
                        iconName={confirmPWVisible ? 'eye-slash' : 'eye'}
                        className='fs-2 icon'
                      />
                    </span>
                  </div>
                  <ErrorMessage name='confirmPassword' component='div' className='text-danger' />
                </div>
              </div>

              <div className='card-footer p-0 py-5 text-end d-none'>
                <button type='button' className='btn btn-light btn-sm me-2'>
                  {intl.formatMessage({id: 'BTN.CLOSE'})}
                </button>
                <button
                  type='submit'
                  className='btn btn-light-primary btn-sm ms-2'
                  id='Change-password-btn'
                >
                  {intl.formatMessage({id: 'BTN.ADD'})}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <button type='button' className='btn btn-light btn-sm' onClick={handleClose}>
          {intl.formatMessage({id: 'BTN.CLOSE'})}
        </button>

        <button className='btn btn-light-primary btn-sm' onClick={ChangePassword} type='submit'>
          {intl.formatMessage({id: 'BTN.CHANGE'})}
        </button>
      </Modal.Footer>
    </Modal>
  )
}
