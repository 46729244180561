import {useState, Dispatch, SetStateAction, useContext, useRef, useEffect} from 'react'
import {KTIcon} from '../../../../../../_metronic/helpers'
import AddFamilyMember from './family-emp-ref/AddFamilyMember'
import AddEmpReference from './family-emp-ref/AddReferenceEmp'
import {Modal} from 'react-bootstrap'
import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import {UseToast} from '../../../../useToast'
import {RenderFields} from '../RenderFields'
import CandidateInfoContext from '../../CandidateInfoContext'
import {useAuth} from '../../../../auth'

interface Props {
  formValues: any
  setFormValues: Dispatch<SetStateAction<any>>
  addFamilyMember(values: any, type: string): void
  deleteFamilyMember(index: number, type: string): void
  editFamilyMember(updatedValues: any, type: string, index: number): void
  candidateData: any
  updateData: (updatedData: any) => Promise<void>
  postLoading: boolean
  structure: any
  setEditMode: Dispatch<SetStateAction<string>>
}

const transformNestedObject = (nestedObject: any) => {
  const result: any = {}
  try {
    Object.keys(nestedObject).forEach((key) => {
      result[key] = nestedObject[key].value
    })
    return result
  } catch {
    return {}
  }
}

function FamilyEmployerRef(props: Props) {
  const {
    corrections: contextCorrections,
    setCorrections: setContextCorrections,
    updateDataOnDB,
  } = useContext(CandidateInfoContext)
  // console.log(contextCorrections)
  const {currentUser} = useAuth()
  const {_family_employer_reference} = contextCorrections
  const {
    family_members: correctionFamMems,
    employer_references: correctionReferences,
    customFields: correctionCustomFields,
  } = _family_employer_reference
  const {setFormValues, candidateData, structure, formValues, updateData} = props
  const {family_members, employer_references, customFields} = formValues
  const status: any = localStorage.getItem('status')
  const [showAddFamModal, setShowAddFamModal] = useState(false)
  const [showAddRefEmp, setShowAddRefEmp] = useState(false)

  const [famModalType, setFamModalType] = useState('add')
  const [famIndex, setFamIndex] = useState<null | number>(null)
  const [famInitialVals, setFamInitialVals]: any = useState({})
  const editFamilyMember = () => {
    setFamModalType('edit')
    setShowAddFamModal(true)
  }

  const [empRefModalType, setEmpRefModalType] = useState<string>('add')
  const [empRefIndex, setEmpRefIndex] = useState<null | number>(null)
  const [refEmpInitialVals, setRefEmpInitialVals]: any = useState({})
  const editRefEmploye = () => {
    setEmpRefModalType('edit')
    setShowAddRefEmp(true)
  }

  const customFieldsInitialVals = transformNestedObject(customFields)
  const initialValuesRef = useRef(customFields)
  useEffect(() => {
    // Update the ref when initialValues change
    initialValuesRef.current = customFields
  }, [customFields])

  const initialValues1 = customFieldsInitialVals || {}
  structure?.items?.forEach((item: any) => {
    if (!initialValues1[item.id]) {
      initialValues1[item.id] =
        item.field_type === null ? false : item.field_type === 'Separator' ? '_sep_a_ra_tor' : ''
      // initialValues[item.id] = item.field_type === 'Toggle' ? false : ''
    }
    if (item.require_confirmation && !initialValues1[`${item.id}_confirmation`]) {
      initialValues1[`${item.id}_confirmation`] = ''
    }

    // Update initialValues with corrections if available
    if (correctionCustomFields[item.id]) {
      initialValues1[item.id] = correctionCustomFields[item.id].value
    }
  })

  const validationSchema1 = Yup.object().shape(
    (structure?.items || [])?.reduce((schema: any, item: any) => {
      if (item.is_required) {
        switch (item.field_type) {
          case 'Single Line': {
            let validator = Yup.string()
              .min(
                item?.validations?.min || 0,
                `Minimum ${item?.validations?.min} characters required`
              )
              .max(
                item?.validations?.max || 250,
                `Maximum ${item?.validations?.max} characters allowed`
              )
              .required(`${item.field_name} is required`)

            if (item.validations?.regex) {
              validator = validator.matches(new RegExp(item.validations.regex), 'Invalid format')
            }

            schema[item.id] = validator

            if (item.require_confirmation) {
              schema[`${item.id}_confirmation`] = Yup.string()
                .oneOf([Yup.ref(item.id)], `${item.confirmation_prompt} does not match`)
                .required(`${item.confirmation_prompt} is required`)
            }
            break
          }
          case 'Multi Line': {
            let validator = Yup.string()
              .min(
                item?.validations?.min || 0,
                `Minimum ${item?.validations?.min} characters required`
              )
              .max(
                item?.validations?.max || 1000, // Adjust the max length as needed
                `Maximum ${item?.validations?.max} characters allowed`
              )
              .required(`${item.field_name} is required`)

            if (item.validations?.regex) {
              validator = validator.matches(new RegExp(item.validations.regex), 'Invalid format')
            }

            schema[item.id] = validator
            break
          }
          case 'Numeric': {
            let validator = Yup.number()
              .typeError(`${item.field_name} must be a number`)
              .required(`${item.field_name} is required`)

            if (item.validations?.min !== undefined && item.validations?.min !== null) {
              validator = validator.min(
                item.validations.min,
                `${item.field_name} must be at least ${item.validations.min}`
              )
            }

            if (item.validations?.max !== undefined && item.validations?.max !== null) {
              validator = validator.max(
                item.validations.max,
                `${item.field_name} must be at most ${item.validations.max}`
              )
            }

            schema[item.id] = validator
            break
          }
          case 'Date Time': {
            let validator = Yup.date().required(`${item.field_name} is required`)

            const {allow_past_date, allow_future_date, allow_current_date, min_date, max_date} =
              item.validations || {}

            const today = new Date()
            today.setHours(0, 0, 0, 0) // Reset hours to ensure proper comparison

            if (min_date) {
              validator = validator.min(new Date(min_date), `Date must be after ${min_date}`)
            }

            if (max_date) {
              validator = validator.max(new Date(max_date), `Date must be before ${max_date}`)
            }

            if (allow_past_date === false) {
              const minValidDate = allow_current_date ? today : new Date(today.getTime() + 86400000) // Next day if current date is not allowed
              validator = validator.min(
                minValidDate,
                allow_current_date
                  ? 'Past dates are not allowed except today'
                  : 'Past dates are not allowed'
              )
            }

            if (allow_future_date === false) {
              const maxValidDate = allow_current_date ? today : new Date(today.getTime() - 86400000) // Previous day if current date is not allowed
              validator = validator.max(
                maxValidDate,
                allow_current_date
                  ? 'Future dates are not allowed except today'
                  : 'Future dates are not allowed'
              )
            }

            schema[item.id] = validator
            break
          }
          case 'Dropdown':
            schema[item.id] = Yup.string().required(`${item.field_name} is required`)
            break
          case 'Toggle':
            schema[item.id] = Yup.string()
              .oneOf(['Yes', 'No'])
              .required(`${item.field_name} is required`)
            break
          case 'Media':
            schema[item.id] = Yup.mixed().required(`${item.field_name} is required`)
            break
          default:
            break
        }
      }
      return schema
    }, {})
  )

  const getEmpRefErrMsg = () => {
    switch (employer_references.length) {
      case 0:
        return (
          <>
            <div className='alert alert-danger d-flex align-items-center p-2 mt-5'>
              <KTIcon iconName='information-2' className='fs-2 text-danger me-2' />
              <div className='d-flex flex-column'>
                <span className='fs-6 text-dark'>
                  It is mandatory that you add at least <span className='fw-semibold'>TWO</span>{' '}
                  employee/academic references.
                </span>
              </div>
            </div>
            <div className='alert alert-primary d-flex align-items-center p-2 mt-5 mb-10'>
              <KTIcon iconName='information-2' className='fs-2 text-primary me-2' />
              <div className='d-flex flex-column'>
                <span className='fs-6 text-dark'>
                  To add employee/academic reference, use the{' '}
                  <span className='fw-semibold'>Add</span> button above.
                </span>
              </div>
            </div>
          </>
        )
      case 1:
        return (
          <div className='alert alert-danger d-flex align-items-center p-2 mt-5'>
            <KTIcon iconName='information-2' className='fs-2 text-danger me-2' />
            <div className='d-flex flex-column'>
              <span className='fs-6 text-dark'>
                It is mandatory that you add at least <span className='fw-semibold'>TWO</span>{' '}
                employee/academic references.
              </span>
            </div>
          </div>
        )
      default:
        return null
    }
  }

  const getFamilyErrMsg = () => {
    switch (family_members.length) {
      case 0:
        return (
          <>
            <div className='alert alert-danger d-flex align-items-center p-2 mt-5'>
              <KTIcon iconName='information-2' className='fs-2 text-danger me-2' />
              <div className='d-flex flex-column'>
                <span className='fs-6 text-dark'>
                  It is mandatory that you add at least <span className='fw-semibold'>ONE</span>{' '}
                  family members.
                </span>
              </div>
            </div>
            <div className='alert alert-primary d-flex align-items-center p-2 mt-5 mb-10'>
              <KTIcon iconName='information-2' className='fs-2 text-primary me-2' />
              <div className='d-flex flex-column'>
                <span className='fs-6 text-dark'>
                  To add family member, use the <span className='fw-semibold'>Add</span> button
                  above.
                </span>
              </div>
            </div>
          </>
        )
      default:
        return null
    }
  }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const handleClose = () => setIsModalOpen(false)

  const getModalInfoMsg = () => {
    if (family_members.length < 1) {
      return 'Please enter details for at least 1 family members'
    } else if (employer_references.length < 2) {
      return 'Please enter details for at least 2 employee/academic references'
    } else {
      return ''
    }
  }

  const transformData = (values: any, items: any) => {
    const result: any = {}
    items.forEach((item: any) => {
      const {id, field_name} = item
      if (values.hasOwnProperty(id)) {
        result[id] = {
          label: field_name,
          value: values[id],
        }
      } else {
        result[id] = {
          label: field_name,
          value: '',
        }
      }
    })

    return result
  }

  const getEditedValues = (currentValues: any) => {
    const initialValues = customFieldsInitialVals
    const editedValues: any = contextCorrections._family_employer_reference.customFields || {}
    const now = new Date()
    const year = now.getFullYear()
    const month = String(now.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
    const day = String(now.getDate()).padStart(2, '0')
    const hours = String(now.getHours()).padStart(2, '0')
    const minutes = String(now.getMinutes()).padStart(2, '0')
    const seconds = String(now.getSeconds()).padStart(2, '0')

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`

    Object.keys(currentValues).forEach((key) => {
      if (currentValues[key] !== initialValues[key]) {
        editedValues[key] = {
          value: currentValues[key],
          by: `${currentUser?.first_name} ${currentUser?.last_name} (${currentUser?.email})`,
          at: formattedDateTime,
        }
      } else if (currentValues[key] === initialValues[key] && editedValues[key]) {
        // If the value is the same, and it exists in editedValues, remove it
        delete editedValues[key]
      }
    })

    return editedValues
  }

  const customFieldSubmit = async (values: any) => {
    // console.log(values)
    const editedValues = getEditedValues(values)
    const updatedCorrections = {
      ...contextCorrections,
      _family_employer_reference: {
        ...contextCorrections._family_employer_reference,
        customFields: {
          ...contextCorrections._family_employer_reference.customFields,
          ...editedValues,
        },
      },
    }
    // console.log(updatedCorrections)
    // console.log(initialValuesRef.current)
    // return
    // const formattedVals = transformData(values, structure.items)
    try {
      setFormValues({
        ...candidateData,
        _family_employer_reference: {
          ...candidateData._family_employer_reference,
          customFields: initialValuesRef.current,
        },
      })
      await updateData({
        ...candidateData,
        _family_employer_reference: {
          ...candidateData._family_employer_reference,
          customFields: initialValuesRef.current,
        },
        corrections: updatedCorrections,
      })
    } catch (error) {
      console.error('Error updating data:', error)
      UseToast(
        'Failed to update data. Please try again.',
        {position: 'bottom-right', theme: 'colored', autoClose: false},
        'error'
      )
    }
  }

  const getCurrentUI = () => {
    return (
      <>
        <Formik
          initialValues={initialValues1}
          validationSchema={validationSchema1}
          onSubmit={customFieldSubmit}
          enableReinitialize
        >
          {({setFieldValue, values, errors, touched, isValid}) => {
            return (
              <Form
                className={`w-100 d-flex flex-wrap ${structure?.items?.length > 0 ? 'mb-5' : ''}`}
              >
                {RenderFields(structure?.items, setFieldValue, values, errors, touched)}
                <div className='d-none w-100 d-flex justify-content-center mt-5'>
                  <button
                    id='custom_fields_submit_fam_emp_ref'
                    type='submit'
                    className='btn btn-primary btn-md'
                    // disabled={!isValid}
                    onClick={() => {
                      if (!isValid) {
                        // setAccuracyErr(false)
                        // setIsModalOpen(true)
                        // return
                        UseToast(
                          'Please fill all the mandatory fields.',
                          {
                            position: 'bottom-right',
                            theme: 'colored',
                            autoClose: true,
                          },
                          'error'
                        )
                      }
                    }}
                  >
                    Save Information
                  </button>
                </div>
              </Form>
            )
          }}
        </Formik>

        <div className='d-flex justify-content-between'>
          <h3 className='fw-semibold'>Family Members</h3>

          <button
            className='btn btn-light-primary btn-sm'
            onClick={(e) => {
              setFamModalType('add')
              setShowAddFamModal(true)
            }}
          >
            <KTIcon iconName='plus' className='fs-2' />
            Add
          </button>
        </div>
        {family_members.length > 0
          ? family_members?.map((each: any, index: number) => {
              if (each.status && each.status === 'deleted') {
                return null
              }
              const updatedVal = correctionFamMems[each.id] || {}
              return (
                <div
                  key={index}
                  className='card bg-light border p-5 d-flex flex-row justify-content-between align-items-center mt-5'
                >
                  <div className='w-75 d-flex flex-row align-items-center'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      height='54px'
                      viewBox='0 -960 960 960'
                      width='54px'
                      fill='#5f6368'
                    >
                      <path d='M720-720q-33 0-56.5-23.5T640-800q0-33 23.5-56.5T720-880q33 0 56.5 23.5T800-800q0 33-23.5 56.5T720-720ZM680-80v-320q0-40-20.5-72T607-522l35-103q8-25 29.5-40t48.5-15q27 0 48.5 15t29.5 40l102 305H800v240H680ZM500-500q-25 0-42.5-17.5T440-560q0-25 17.5-42.5T500-620q25 0 42.5 17.5T560-560q0 25-17.5 42.5T500-500ZM220-720q-33 0-56.5-23.5T140-800q0-33 23.5-56.5T220-880q33 0 56.5 23.5T300-800q0 33-23.5 56.5T220-720ZM140-80v-280H80v-240q0-33 23.5-56.5T160-680h120q33 0 56.5 23.5T360-600v240h-60v280H140Zm300 0v-160h-40v-160q0-25 17.5-42.5T460-460h80q25 0 42.5 17.5T600-400v160h-40v160H440Z' />
                    </svg>
                    <div className='text-muted ms-5'>
                      <span className='m-0'>
                        {updatedVal.name || each.name}, {updatedVal.age || each.age}
                      </span>
                      <h4 className='fw-semibold m-0'>
                        {updatedVal.relationship || each.relationship}
                      </h4>
                      <span className='m-0'>{updatedVal.occupation || each.occupation}</span>
                    </div>
                  </div>
                  <div className=''>
                    <button
                      className='btn btn-light-primary btn-sm me-2'
                      onClick={() => {
                        setFamIndex(index)
                        setFamInitialVals(Object.keys(updatedVal).length > 0 ? updatedVal : each)
                        editFamilyMember()
                      }}
                    >
                      <KTIcon iconName='pencil' className='fs-2' />
                      Edit
                    </button>
                    <button
                      className='btn btn-light-danger btn-sm ms-2'
                      onClick={() => props.deleteFamilyMember(index, 'family_members')}
                    >
                      <KTIcon iconName='trash' className='fs-2' />
                    </button>
                  </div>
                </div>
              )
            })
          : null}
        {getFamilyErrMsg()}
        <div className='d-flex justify-content-between mt-10'>
          <h3 className='fw-semibold'>Employee/Academic Reference</h3>

          <button
            className='btn btn-light-primary btn-sm'
            onClick={(e) => {
              setEmpRefModalType('add')
              setShowAddRefEmp(true)
            }}
          >
            <KTIcon iconName='plus' className='fs-2' />
            Add
          </button>
        </div>

        <div className='bg-light-primary p-5 d-flex align-items-center border border-muted mt-5 rounded'>
          <KTIcon iconName='information-2' className='text-primary fs-1 me-2 mb-3' />
          <p className='fs-7 m-0'>
            This info will be used for Background Verification purposes. This may be a colleague or
            reporting manager in any of your previous places of work. Your candidacy is contingent
            upon us able to successfully complete background verification. Please provide
            active/working contact details.
          </p>
        </div>

        {employer_references.length > 0
          ? employer_references?.map((each: any, index: number) => {
              if (each.status && each.status === 'deleted') {
                return null
              }
              const updatedVal = correctionReferences[each.id] || {}
              return (
                <div
                  key={index}
                  className='card bg-light border p-5 d-flex flex-row justify-content-between align-items-center mt-5'
                >
                  <div className='w-75 d-flex flex-row align-items-center'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      height='54px'
                      viewBox='0 -960 960 960'
                      width='54px'
                      fill='#5f6368'
                    >
                      <path d='M720-720q-33 0-56.5-23.5T640-800q0-33 23.5-56.5T720-880q33 0 56.5 23.5T800-800q0 33-23.5 56.5T720-720ZM680-80v-320q0-40-20.5-72T607-522l35-103q8-25 29.5-40t48.5-15q27 0 48.5 15t29.5 40l102 305H800v240H680ZM500-500q-25 0-42.5-17.5T440-560q0-25 17.5-42.5T500-620q25 0 42.5 17.5T560-560q0 25-17.5 42.5T500-500ZM220-720q-33 0-56.5-23.5T140-800q0-33 23.5-56.5T220-880q33 0 56.5 23.5T300-800q0 33-23.5 56.5T220-720ZM140-80v-280H80v-240q0-33 23.5-56.5T160-680h120q33 0 56.5 23.5T360-600v240h-60v280H140Zm300 0v-160h-40v-160q0-25 17.5-42.5T460-460h80q25 0 42.5 17.5T600-400v160h-40v160H440Z' />
                    </svg>
                    <div className='text-muted ms-5'>
                      <h4 className='fw-semibold m-0'>{updatedVal.name || each.name}</h4>
                      <span className='m-0'>
                        {' '}
                        {updatedVal.designation || each.designation} -{' '}
                        {updatedVal.company || each.company}
                      </span>
                      <br />
                      <span className='m-0'>{updatedVal.email || each.email}</span>
                      <br />
                      <span className='m-0'>{updatedVal.mobile || each.mobile}</span>
                    </div>
                  </div>
                  <div className=''>
                    <button
                      className='btn btn-light-primary btn-sm me-2'
                      onClick={() => {
                        setEmpRefIndex(index)
                        setRefEmpInitialVals(Object.keys(updatedVal).length > 0 ? updatedVal : each)
                        editRefEmploye()
                      }}
                    >
                      <KTIcon iconName='pencil' className='fs-2' />
                      Edit
                    </button>
                    <button
                      className='btn btn-light-danger btn-sm ms-2'
                      // onClick={() => props.deleteFamilyMember(index, 'employer_references')}
                      onClick={() => props.deleteFamilyMember(index, 'employer_references')}
                    >
                      <KTIcon iconName='trash' className='fs-2' />
                    </button>
                  </div>
                </div>
              )
            })
          : null}
        {getEmpRefErrMsg()}

        <div className='d-flex justify-content-center mt-5'>
          <button
            className='btn btn-light btn-sm me-2'
            onClick={() => props.setEditMode('familyEmpRef')}
          >
            Cancel
          </button>
          <button
            className='btn btn-primary btn-md'
            onClick={async () => {
              if (family_members.length >= 1 && employer_references.length >= 2) {
                const submitBtn = document.getElementById('custom_fields_submit_fam_emp_ref')
                if (submitBtn) {
                  submitBtn.click()
                }
                // try {
                //   await props.updateData({
                //     ...props.candidateData,
                //     locked_sections: {...filledSections, family_employer: true},
                //   })
                //   setFilledSections((prev) => ({
                //     ...prev,
                //     family_employer: true,
                //   }))
                // } catch (error) {
                //   console.error('Error updating data:', error)
                //   UseToast(
                //     'Failed to update data. Please try again.',
                //     {position: 'bottom-right', theme: 'colored', autoClose: false},
                //     'error'
                //   )
                // }
              } else {
                setIsModalOpen(true)
              }
            }}
          >
            {props.postLoading ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : (
              'Save Information'
            )}
          </button>
          {/* <p className='text-danger fs-4'>*Please enter details for at least 2 family members</p> */}
        </div>
      </>
    )
  }

  return (
    <>
      <AddFamilyMember
        showModal={showAddFamModal}
        setShowModal={setShowAddFamModal}
        addFamilyMember={props.addFamilyMember}
        editFamilyMember={props.editFamilyMember}
        type={famModalType}
        index={famIndex}
        initialVals={famInitialVals}
      />
      <AddEmpReference
        showModal={showAddRefEmp}
        setShowModal={setShowAddRefEmp}
        addFamilyMember={props.addFamilyMember}
        editFamilyMember={props.editFamilyMember}
        type={empRefModalType}
        index={empRefIndex}
        initialVals={refEmpInitialVals}
      />
      {getCurrentUI()}
      <Modal show={isModalOpen} onHide={handleClose} centered>
        <Modal.Header className='py-5'>
          <div className='w-100 d-flex justify-content-between align-items-center'>
            <h4 className='fw-semibold'>Warning</h4>
            <button
              type='button'
              className='d-block btn btn-sm btn-icon btn-light'
              onClick={handleClose}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className='py-3'>
          <h4 className='fw-normal my-1'>{getModalInfoMsg()}</h4>
        </Modal.Body>
        <Modal.Footer className='py-3'>
          <button
            className='btn btn-light-primary btn-sm'
            onClick={() => {
              handleClose()
            }}
          >
            Ok
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {FamilyEmployerRef}
