import {useEffect, useMemo, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {useTable, usePagination} from 'react-table'
import usePageTitle from '../../custom_hooks/usePageTitle'
import {Pagination} from 'react-bootstrap'
import DateFormatter from '../../DateFormatter'
import {KTIcon} from '../../../../_metronic/helpers'
import {UseToast} from '../../useToast'
import {useIntl, FormattedMessage} from 'react-intl'
import {FindPodIdByCode} from '../../FindPodIdByPodCode'
import CreateNewOfferModal from './add_offer/CreateNewOfferModal'
import usePostApi from '../../custom_hooks/usePostApi'
import OffersknowmoreModal from './offersknowmore/OffersknowmoreModal'
import Offersknowmore from './offersknowmore/Offersknowmore'

const DataTable = ({columns, data, loading, fetchData, refresh}) => {
  const [inputVal, setInputVal] = useState('')
  const intl = useIntl()

  const [showKM, setShowKM] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [currentStatus, setCurrentStatus] = useState('I')
  const handleOpenModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const filteredData = useMemo(() => {
    return data
      ? data.filter((each) => {
          const fullName = each.candidate_first_name + ' ' + each.candidate_last_name
          const nameMatches = fullName && fullName.toLowerCase().includes(inputVal.toLowerCase())

          const jobTitleMatches =
            each.job_title_name &&
            each.job_title_name.toLowerCase().includes(inputVal.toLowerCase())

          const locationMatches =
            each.location_name && each.location_name.toLowerCase().includes(inputVal.toLowerCase())

          const currentStatusMatches =
            each.offer_status && each.offer_status.toLowerCase() === currentStatus.toLowerCase()
          const dateMatches =
            each.date_of_joining &&
            DateFormatter(each.date_of_joining).toLowerCase() === currentStatus.toLowerCase()

          return (
            (nameMatches || jobTitleMatches || locationMatches || dateMatches) &&
            currentStatusMatches
          )
        })
      : []
  }, [inputVal, currentStatus, data])
  usePageTitle(intl.formatMessage({id: 'LABEL.ALL_OFFERS'}))
  const TableInstance = useTable({columns, data: filteredData}, usePagination)

  const activePage = TableInstance.page
  const activePageCount = TableInstance.pageCount
  const activePageIndex = TableInstance.state.pageIndex
  const activePageSize = TableInstance.state.pageSize

  // Handle pagination for active dataset
  const gotoActivePage = (pageIndex) => {
    TableInstance.gotoPage(pageIndex)
  }

  const setActivePageSize = (pageSize) => {
    TableInstance.setPageSize(pageSize)
  }

  // Handle pagination for inactive dataset

  const pageOptions = [5, 10, 20, 30, 40, 50]

  return (
    <>
      <OffersknowmoreModal showModal={showKM} setShowModal={setShowKM} />
      <>
        {loading && refresh === false ? (
          <div className='card h-250px'>
            <div className='m-auto d-flex flex-column align-items-center'>
              <div className='spinner-border spinner-primary mr-15'></div>
            </div>
          </div>
        ) : data.length > 0 ? (
          <>
            <div className='d-flex flex-wrap flex-stack '>
              <div className='d-flex'>
                <h1 className='fw-semibold fs-3'>Manage Offers</h1>
              </div>
              <div className='d-flex'>
                <div className='d-flex'>
                  <div className='m-auto d-flex flex-column align-items-center'>
                    <button className='btn btn-sm btn-light' onClick={() => setShowKM(true)}>
                      <i className='ki-duotone ki-information-3 fs-3'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                      Know More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <p className='fw-normal fs-6 text-gray-600'>
              Seamlessly create, update, and track offers, ensuring no details are overlooked during
              the hiring process.
            </p>
            {refresh ? (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <div className='spinner-border spinner-primary mr-15'></div>
                </div>
              </div>
            ) : (
              <div className='card py-2 px-10 mb-5'>
                <div className='card-header border-0 px-0'>
                  <h3 className='card-title align-items-start flex-column'>
                    <ul className='nav'>
                      <li className='nav-item'>
                        <a
                          className='nav-link btn btn-sm btn-color-muted btn-active  btn-active-light-primary active fw-normal px-4 me-1'
                          data-bs-toggle='tab'
                          onClick={() => setCurrentStatus('I')}
                        >
                          Issued
                        </a>
                      </li>
                      <li className='nav-item'>
                        <a
                          className='nav-link btn btn-sm btn-color-muted btn-active  btn-active-light-success fw-normal px-4 me-1'
                          data-bs-toggle='tab'
                          onClick={() => setCurrentStatus('A')}
                        >
                          Accepted
                        </a>
                      </li>
                      <li className='nav-item'>
                        <a
                          className='nav-link btn btn-sm btn-color-muted btn-active  btn-active-light-danger fw-normal px-4 me-1'
                          data-bs-toggle='tab'
                          onClick={() => setCurrentStatus('R')}
                        >
                          Rejected
                        </a>
                      </li>
                    </ul>
                  </h3>

                  <div className='d-flex'>
                    <div className='d-flex align-items-center position-relative me-4'>
                      <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                      <input
                        type='search'
                        id='kt_filter_search'
                        className='form-control form-control-white form-control-sm w-150px ps-9'
                        placeholder='Search'
                        value={inputVal}
                        onChange={(e) => setInputVal(e.target.value)}
                      />
                    </div>

                    <div
                      className='d-flex justify-content-center align-items-center'
                      data-toggle='tooltip'
                      title='Create Offer'
                    >
                      <button
                        className='btn btn-sm btn-light-primary me-3'
                        onClick={handleOpenModal}
                      >
                        <KTIcon iconName='plus' className='fs-3' />
                        Create Offer
                      </button>
                    </div>

                    <div
                      className='d-flex justify-content-center align-items-center'
                      data-toggle='tooltip'
                      title='Create Offer'
                    >
                      <button
                        className='btn btn-sm btn-light-primary me-3'
                        onClick={() => fetchData(true)}
                      >
                        <i className='ki-solid ki-arrows-circle fs-3'></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div className='mb-0 mb-xl-8 '>
                  <div className='overflow-auto'>
                    <table className='table table-row-dashed table-row-gray-300 align-middle'>
                      <thead>
                        <tr className='fw-semibold text-muted bg-light rounded'>
                          <th className='min-w-150px default-cursor text-start ps-3'>Candidates</th>
                          <th className='min-w-140px'>Selected Role</th>
                          <th className='min-w-120px text-start'>Location</th>

                          <th className='min-w-100px text-start'>Date of Joining</th>
                          {/* <th className='min-w-100px text-start'>
                            {intl.formatMessage({id: 'LABEL.STATUS'})}
                          </th> */}
                          <th className='min-w-100px text-center'>
                            {intl.formatMessage({id: 'MENU.ACTIONS'})}
                          </th>
                        </tr>
                      </thead>

                      <tbody {...TableInstance.getTableBodyProps()}>
                        {activePage.length > 0 ? (
                          activePage.map((row) => {
                            TableInstance.prepareRow(row)
                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                  <td className=' ' {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                  </td>
                                ))}
                              </tr>
                            )
                          })
                        ) : (
                          <tr>
                            <td className='' colSpan={5}>
                              <div className='d-flex justify-content-center'>
                                <p className='text-dark default-cursor fs-6 mt-5'>
                                  {inputVal === '' ? (
                                    intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
                                  ) : (
                                    <FormattedMessage
                                      id='MSG.NO_DATA_MATCH'
                                      values={{
                                        inputVal: <span className='text-primary'>{inputVal}</span>,
                                      }}
                                    />
                                  )}
                                </p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {data.length > 10 && (
              <Pagination>
                <Pagination.First
                  onClick={() => gotoActivePage(0)}
                  disabled={!TableInstance.canPreviousPage}
                />
                <Pagination.Prev
                  onClick={TableInstance.previousPage}
                  disabled={!TableInstance.canPreviousPage}
                />
                {[...Array(activePageCount)].map((_, i) => (
                  <Pagination.Item
                    key={i}
                    active={i === activePageIndex}
                    onClick={() => gotoActivePage(i)}
                  >
                    {i + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={TableInstance.nextPage}
                  disabled={!TableInstance.canNextPage}
                />
                <Pagination.Last
                  onClick={() => gotoActivePage(activePageCount - 1)}
                  disabled={!TableInstance.canNextPage}
                />
                <div className='d-flex align-items-center mx-5'>
                  <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                  <select
                    className='form-select form-select-solid fw-semibold w-75px me-2'
                    value={activePageSize}
                    onChange={(e) => setActivePageSize(Number(e.target.value))}
                  >
                    {pageOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </Pagination>
            )}
          </>
        ) : (
          <div className='d-flex flex-column'>
            <h3 className='fs-3 fw-semibold'>Manage Offers</h3>

            <p className=''>
              Welcome to the Offers Page! Here, you have the tools to seamlessly create and track
              offers, ensuring a smooth transition into your organization.
            </p>
            <Offersknowmore />
            <div
              className='d-flex justify-content-center align-items-center'
              data-toggle='tooltip'
              title='Create New Offer'
            >
              <button
                className='btn btn-sm btn-light-primary'
                id='side_activities_toggle'
                onClick={handleOpenModal}
              >
                <KTIcon iconName='plus' className='fs-3' />
                Create Offer
              </button>
            </div>
          </div>
        )}
        <CreateNewOfferModal show={showModal} onClose={handleCloseModal} fetchData={fetchData} />
      </>
    </>
  )
}
const OffersTable = () => {
  const {id} = useParams()
  const podId = FindPodIdByCode()
  // const {offersData, isoffersLoading: isLoading, fetchData} = GetAllOffers()
  const {execute, loading: isLoading} = usePostApi()
  const intl = useIntl()
  const [offersData, setoffersData] = useState([])
  const [refresh, setRefresh] = useState(false)

  const getOffers = async (show) => {
    if (show) {
      setRefresh(true)
    }
    try {
      const url = `/pod/job_offers/list`
      const formData = {
        pod_id: podId,
      }

      const response = await execute(url, 'POST', formData)
      setoffersData(response.data)
      setRefresh(false)
      if (show) {
        return UseToast(
          response.status_message,
          {
            position: 'bottom-right',
            theme: 'colored',
            autoClose: true,
          },
          'success'
        )
      }
    } catch (error) {
      console.error('Error:', error)
      setRefresh(false)
      if (show) {
        return UseToast(
          error.response?.data?.status_message,
          {
            position: 'bottom-right',
            theme: 'colored',
            autoClose: true,
          },
          'error'
        )
      }
    }
  }

  useEffect(() => {
    getOffers()
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: 'Candidate',

        accessor: 'candidate',

        Cell: ({row}) => {
          return (
            <div className='d-flex align-items-center ms-3'>
              <div className='d-flex justify-content-start flex-row fw-semibold'>
                <p className='text-dark fw-semibold text-hover-primary fs-6'>
                  {`${row.original.candidate_first_name} ${row.original.candidate_last_name}`}
                </p>
              </div>
            </div>
          )
        },

        disableSortBy: true,
      },

      {
        Header: 'Role Selected',

        accessor: 'role',

        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-normal text-hover-primary d-block fs-6'>
                {row.original.job_title_name}
              </p>
            </div>
          )
        },
      },

      {
        Header: 'Interviewed By',

        accessor: 'Interviewed by',

        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark  text-hover-primary d-block fs-6'>
                {row.original.location_name}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'Issue Date',

        accessor: 'Date',

        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark  text-hover-primary d-block fs-6'>
                {DateFormatter(row.original.date_of_joining)}
              </p>
            </div>
          )
        },
      },

      {
        Header: 'Actions',

        accessor: 'Actions',

        Cell: ({row}) => {
          return (
            <div className='d-flex justify-content-center flex-shrink-0'>
              <button
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2 '
                title='Offer letter'
                onClick={() => {
                  if (row.original.offer_letter_attachment) {
                    window.open(row.original.offer_letter_attachment, '_blank')
                  } else {
                    alert('Not Found')
                  }
                }}
              >
                <KTIcon iconName='eye' className='fs-3 ' />
              </button>

              <Link
                to={`/${id}/offers/info/${row.original.job_offer_id}`}
                className='btn btn-icon btn-bg-light btn-active-light-primary btn-sm me-1'
                title='Offer Info'
              >
                <KTIcon iconName='information' className='fs-1' />
              </Link>
            </div>
          )
        },
      },
    ],

    []
  )

  return (
    <DataTable
      columns={columns}
      data={offersData ? offersData : []}
      loading={isLoading}
      fetchData={getOffers}
      refresh={refresh}
    />
  )
}

export default OffersTable
