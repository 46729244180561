import React, {useEffect, useState} from 'react'
import 'react-quill/dist/quill.snow.css'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import secureLocalStorage from 'react-secure-storage'
import {Link, useParams} from 'react-router-dom'
import {
  FindBrandnameByCode,
  FindOrgNameByCode,
  FindOrgPodLogoByCode,
  FindPodIdByCode,
} from '../../FindPodIdByPodCode'
import {KTIcon} from '../../../../_metronic/helpers'
import Select from 'react-select'
import {useThemeMode} from '../../../../_metronic/partials'
import usePostApi from '../../custom_hooks/usePostApi'
import nodatafound from './no-data.png'
import nodatafound2 from './cloud-folder.png'
function Vacancies() {
  const [domainName, setTableData]: any = useState([])
  const userInfo: any = secureLocalStorage.getItem('user_info')
  let user_id = ''
  let auth_token = ''
  if (userInfo) {
    ;({user_id, auth_token} = userInfo)
  }
  const [domainOptions, setDomainOptions] = useState([])
  const brandName = FindBrandnameByCode()
  const orgName = FindOrgNameByCode()
  const podLogo = FindOrgPodLogoByCode()
  const {id} = useParams()
  const podId = FindPodIdByCode()
  const {execute} = usePostApi()
  const {mode} = useThemeMode()
  const [emailAddon, setEmailAddon]: any = useState('example.com')
  const [selectedDomain, setSelectedDomain]: any = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const [portalName, setPortalName] = useState('')
  const validationSchema = Yup.object().shape({
    user_name: Yup.string().required('For portal username is required'),
    portal_name: Yup.string().required('Verification is required'),
  })

  const handleSubmit = async (values: any, {resetForm}: any) => {
    const data: any = {
      pod_code: id,
      pod_id: podId,
      user_id: user_id,
      auth_token: auth_token,
      brandName: brandName,
      orgName: orgName,
      podLogo: podLogo,
    }
    const query = new URLSearchParams(data).toString()
    window.location.href = `https://kwx-hrms.vercel.app/?${query}`
  }
  const domainfetchData = async () => {
    try {
      const url = `/pod/domains/active`
      const formData = {pod_id: podId}
      const response = await execute(url, 'POST', formData)
      setTableData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }
  useEffect(() => {
    domainfetchData()
  }, [])
  const handleDomainChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('domainParentId', selectedOption.value)
      setEmailAddon(selectedOption.label)
      setSelectedDomain(selectedOption) // Save the selected domain
    } else {
      setFieldValue('domainParentId', '')
      setSelectedDomain(null)
    }
  }
  useEffect(() => {
    const options: any = (domainName || []).map((each: any) => ({
      value: each.domain_id,
      label: each.domain_name,
    }))
    setDomainOptions(options)
    if (options.length === 1) {
      setSelectedDomain(options[0])
      setEmailAddon(options[0].label)
    }
  }, [domainName])
  const toggleEditing = () => {
    setIsEditing(!isEditing)
  }
  const handleVerify = async () => {
    // Simulate verification logic
    setPortalName('updated')
    setIsVerified(true)
    setIsEditing(false)
  }
  return (
    <>
      {domainName?.length > 0 ? (
        <div className='m-2 p-4'>
          <div className='card-body mb-8'>
            <div className='row'>
              <div className='col-xl-6'>
                <h2 className='fw-semibold'>Whitelabel Careers Requests</h2>
                <p className='fs-6 fw-semibold text-gray-600 py-2'>
                  JetBoard offers a powerful whitelabeling feature that allows you to seamlessly
                  integrate our employee platform into your company's domain and branding. By adding
                  your domains and subdomains to JetBoard, you can create a customized Career
                  experience for your employees, partners, and clients.
                  <br />
                  This means, instead of using a generic JetBoard onboarding URL, such as
                  <span className='text-dark'> {`https://${brandName}.jetboard.site`}</span>, you
                  can create custom Career links like demo
                  <button
                    className='text-primary border-0'
                    onClick={() => {
                      const data: any = {
                        pod_code: id,
                        pod_id: podId,
                        user_id: user_id,
                        auth_token: auth_token,
                        brandName: brandName,
                        orgName: orgName,
                        podLogo: podLogo,
                      }
                      const query = new URLSearchParams(data).toString()
                      window.location.href = `https://kwx-hrms.vercel.app/?${query}`
                    }}
                  >
                    {`https://${brandName}.jetboard.site/careers`}
                  </button>
                  .
                </p>
              </div>
              <div className='col-xl-6'>
                <h2 className='fw-semibold'>How it works?</h2>
                <div className='fs-6 fw-semibold text-gray-600 py-2'>
                  Follow these simple steps to integrate your Career for whitelabeling:
                  <br />
                  <ol className='py-2'>
                    <li className='pb-2'>
                      Go to the "Whitelabeling" tab and add your domain or subdomain.
                    </li>
                    <li className='pb-2'>
                      Verify your domain by adding the provided TXT record to your DNS settings.
                    </li>
                    <li className='pb-2'>
                      Once verified, customize your Career page's appearance with your brand's logo
                    </li>
                    <li className='pb-2'>
                      Save the changes and share the customized URL with your employees, partners,
                      and clients.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div className='alert alert-primary d-flex align-items-center p-1 mt-5 border-primary border border-dashed'>
              <KTIcon iconName='information' className='fs-2x text-primary me-2' />
              <div className='d-flex flex-column'>
                <span className='fs-6 text-dark'>
                  JetBoard periodically verifies the TXT records to ensure whitelabeled careers are
                  active. Please ensure that the verification code is not deleted, as it is crucial
                  for maintaining the functionality and integrity of your whitelabeled careers.
                </span>
              </div>
            </div>
          </div>
          <div>
            <Formik
              initialValues={{
                domainParentId: selectedDomain ? selectedDomain.value : '' || '',
                user_name: 'careers' || '',
                portal_name: portalName || '',
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({values, setFieldValue}) => (
                <Form>
                  <div>
                    <div className='card mb-4'>
                      <div className='card-header'>
                        <h3 className='card-title fw-semibold fs-3 text-dark'>
                          Portal Global Settings
                        </h3>
                      </div>
                      <div className='card-body border-top'>
                        <div className='mb-4 flex-fill'>
                          <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                            Portal username
                          </label>
                          <div className='d-flex flex-row'>
                            <Field
                              className={`h-40px form-control ${false ? 'is-invalid' : ''} ${
                                isVerified ? 'is-valid' : ''
                              }`}
                              id='floatingInput'
                              placeholder='Enter Portal Username'
                              value={`https://${brandName}.${emailAddon}/${values.user_name}`}
                              type='text'
                              autoComplete='off'
                              disabled
                            />
                            <button
                              className='btn btn-sm ms-2 h-40px w-100px btn-light-primary'
                              onClick={toggleEditing}
                              type='button'
                            >
                              {!isEditing ? (
                                <>
                                  <KTIcon iconName='pencil' className='fs-7' />
                                  Edit
                                </>
                              ) : (
                                <>Cancel</>
                              )}
                            </button>
                            <button
                              className='ms-2 btn btn-sm h-40px w-150px btn-light-success'
                              type='button'
                              onClick={handleVerify}
                            >
                              <KTIcon iconName='check' className='fs-2' />
                              Verify
                            </button>
                          </div>
                          <ErrorMessage
                            component='div'
                            className='text-danger mt-1'
                            name='portal_name'
                          />
                        </div>
                        {isEditing && (
                          <>
                            <div className='mb-4 flex-fill'>
                              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 required'>
                                Branded URL
                              </label>
                              <div className='d-flex'>
                                <Select
                                  value={domainOptions.find(
                                    (option: any) => option.value === values.domainParentId
                                  )}
                                  styles={{
                                    control: (baseStyles: any, state: any) => ({
                                      ...baseStyles,
                                      backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                                      color: '#ffffff',
                                      borderWidth: '0px',
                                    }),
                                    menu: (baseStyles: any) => ({
                                      ...baseStyles,
                                      backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                                    }),
                                    singleValue: (baseStyles: any, state: any) => ({
                                      ...baseStyles,
                                      color: mode === 'dark' ? '#92929f' : '#000000',
                                    }),
                                    input: (baseStyles: any) => ({
                                      ...baseStyles,
                                      color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                                    }),
                                    option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                                      ...baseStyles,
                                      backgroundColor: isSelected
                                        ? '#C2C2C2'
                                        : isFocused
                                        ? '#e0e0e0'
                                        : baseStyles.backgroundColor,
                                      color: isSelected
                                        ? '#000000'
                                        : isFocused
                                        ? '#000000'
                                        : baseStyles.color,
                                    }),
                                  }}
                                  theme={(theme: any) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    backgroundColor: '#000000',
                                    colors: {
                                      ...theme.colors,
                                      primary25: 'rgba(80, 107, 236, 0.5)',
                                      primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                                    },
                                  })}
                                  className='react-select-container w-100'
                                  classNamePrefix='react-select'
                                  options={domainOptions}
                                  isSearchable
                                  placeholder='Select Domain Name'
                                  onChange={(val: any) => handleDomainChange(setFieldValue, val)}
                                />
                              </div>
                              <ErrorMessage
                                component='div'
                                className='text-danger'
                                name='domainParentId'
                              />
                            </div>
                            <div className='mb-4 flex-fill'>
                              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 required'>
                                For portal username
                              </label>
                              <div className='input-group'>
                                <span className='input-group-text' style={{border: '0'}}>
                                  {emailAddon}/
                                </span>
                                <Field
                                  className='form-control form-control-solid'
                                  id='floatingInput'
                                  placeholder='Enter Portal Username'
                                  name='user_name'
                                  type='text'
                                  autoComplete='off'
                                />
                              </div>
                              <ErrorMessage
                                component='div'
                                className='text-danger'
                                name='user_name'
                              />
                            </div>
                          </>
                        )}
                        <div className='d-flex justify-content-center mb-4'>
                          <button
                            className='btn btn-sm btn-light-primary fw-normal h-40px'
                            type='submit'
                          >
                            <KTIcon iconName='setting-2' className='fs-3' />
                            Configure Career Portal
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <div className='m-2 p-4'>
          <h3 className='fw-semibold fs-3'>Managing Careers Portal</h3>
          <p className='fw-normal'>
            Welcome to the Careers Portal page! It appears you don't have any verified domains yet.
            To manage career portal page, please verify your domains first.
          </p>
          <div className='row mt-6'>
            <div className='col-lg-4 mb-6'>
              <div className='shadow-sm p-3 rounded h-100 d-flex flex-column justify-content-center align-items-center'>
                <img src={nodatafound} className='track-req-icon mb-5' alt='No Data Found' />
                <p className='text-center'>
                  To start configure the portal page, please visit the domains page and verify your
                  domains.
                </p>
              </div>
            </div>
            <div className='col-lg-4 mb-6'>
              <div className='shadow-sm p-3 rounded h-100 d-flex flex-column justify-content-center align-items-center'>
                <img src={nodatafound2} className='track-req-icon mb-5' alt='Add Sender' />
                <p className='text-center'>
                  After verifying your domains, you can return here to manage yours career portal
                  page.
                </p>
              </div>
            </div>
          </div>
          <div className='m-auto d-flex flex-column align-items-center'>
            <Link
              to={`/${id}/configuration/white-labeling/domains`}
              className='btn btn-sm btn-light-primary'
            >
              Go to Domains Page
            </Link>
          </div>
        </div>
      )}
    </>
  )
}

export default Vacancies
