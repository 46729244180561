import React, {useContext} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import clsx from 'clsx'
import Step1 from './steps/Step1'
import EmpData from './EmpData.json'
import './index.css'
import DocumentsTable from './documentsTable'
import AddressContact from './steps/AddressContact'
import {useParams} from 'react-router-dom'
import usePostApi from '../../custom_hooks/usePostApi'
import {UseToast} from '../../useToast'
import EmpAndEdu from './steps/EmpAndEdu'
import FamilyAndEmpRef from './steps/FamilyAndEmpRef'
import CandidateInfoContext from './CandidateInfoContext'

const transformData1 = async (values: any, items: any) => {
  const result: any = {}
  items.forEach((item: any) => {
    const {id, field_name, require_confirmation} = item
    if (values.hasOwnProperty(id)) {
      result[id] = {
        label: field_name,
        value: values[id],
      }
      if (require_confirmation) {
        result[`${id}_confirmation`] = {
          label: `${field_name} Confirmation`,
          value: values[id],
        }
      }
    } else {
      result[id] = {
        label: field_name,
        value: '',
      }
    }
  })

  return result
}

function EmpDetailsWizard({
  setLocalStep,
  currentStep,
  candidateData,
  isLoading,
  template,
  formValues = {},
  setFormValues,
  ids,
  getCandidateData,
  editSections,
  closeEditSec,
  corrections,
  setCorrections,
}: any) {
  const {
    corrections: contextCorrections,
    setCorrections: contextSetCorrections,
    updateDataOnDB: updateData,
  } = useContext(CandidateInfoContext)
  const {execute, postLoading} = usePostApi()
  const {jobJoinCode, pin} = useParams()
  const {personalInfo, addressContact, educationEmployment, familyEmpRef} = editSections
  const handleStepChange = (newStep: number) => {
    setLocalStep(newStep)
  }

  const encodeToBase64 = (data: any): string | null => {
    try {
      const jsonString = JSON.stringify(data)
      return btoa(jsonString)
    } catch (error) {
      console.error('Failed to encode and stringify data to base64 string:', error)
      return null
    }
  }

  const updateSameAsCurrentAdd = async (type: string, values: any) => {
    setFormValues({...formValues, [type]: values})
  }

  const updateFormValues = async (type: string, values: any, corrections: any) => {
    let items
    if (type === '_address_contacts') {
      const temp = [
        ...template['_address_contacts'].items,
        ...template['_address_contacts'].address_fields,
      ]
      items = temp
    } else {
      items = template[type].items
    }

    const formattedData = await transformData1(values, items)
    // setFormValues({...formValues, [type]: formattedData})
    setFormValues((prev: any) => ({
      ...prev,
      [type]: formattedData,
    }))

    await updateData({
      ...formValues,
      [type]: formattedData,
      corrections: corrections,
    })
  }

  const addQualification = async (values: any, type: string) => {
    setFormValues((prevFormValues: any) => {
      const updatedEmploymentEducation = {
        ...prevFormValues._employment_education,
        [type]: [...(prevFormValues._employment_education[type] || []), values],
      }

      return {
        ...prevFormValues,
        _employment_education: updatedEmploymentEducation,
      }
    })

    const updatedData = {
      ...formValues,
      _employment_education: {
        ...formValues._employment_education,
        [type]: [...(formValues._employment_education[type] || []), values],
      },
    }
    // await updateData({
    //   ...updatedData,
    // })
  }

  const editQualification = async (updatedValues: any, type: string, index: number) => {
    setFormValues((prevFormValues: any) => ({
      ...prevFormValues,
      _employment_education: {
        ...prevFormValues._employment_education,
        [type]: prevFormValues._employment_education[type].map((item: any, i: number) =>
          i === index ? updatedValues : item
        ),
      },
    }))

    const updatedData = {
      ...formValues,
      _employment_education: {
        ...formValues._employment_education,
        [type]: formValues._employment_education[type].map((item: any, i: number) =>
          i === index ? updatedValues : item
        ),
      },
    }

    // await updateData({
    //   ...updatedData,
    // })
  }

  const deleteQualification = async (index: number, type: string) => {
    // const updatedData = {
    //   ...formValues,
    //   _employment_education: {
    //     ...formValues._employment_education,
    //     [type]: formValues._employment_education[type].filter((_: any, i: number) => i !== index),
    //   },
    // }
    const deletedItem = formValues._employment_education[type].at(index)

    const updatedData = {
      ...formValues,
      _employment_education: {
        ...formValues._employment_education,
        [type]: formValues._employment_education[type].map((item: any, i: number) =>
          i === index ? {...item, status: 'deleted'} : item
        ),
      },
    }

    const updatedCorrections = {
      ...contextCorrections,
      _employment_education: {
        ...contextCorrections._employment_education,
        [type]: {
          ...contextCorrections._employment_education[type],
          [deletedItem.id]: {...deletedItem, status: 'deleted'},
        },
      },
    }

    // if (deletedItem) {
    //   contextSetCorrections(updatedCorrections)
    // }

    // console.log({...updatedData, corrections: updatedCorrections})
    setFormValues(updatedData)
    await updateData({
      ...updatedData,
      corrections: updatedCorrections,
    })

    // setFormValues((prevFormValues: any) => ({
    //   ...prevFormValues,
    //   _employment_education: {
    //     ...prevFormValues['_employment_education'],
    //     [type]: prevFormValues._employment_education[type]?.filter(
    //       (_: any, i: number) => i !== index
    //     ),
    //   },
    // }))
  }

  const updateIsFresher = (isFresher: boolean) => {
    setFormValues((prevState: any) => ({
      ...prevState,
      _employment_education: {
        ...prevState['_employment_education'],
        isFresher,
        experiences: [...prevState['_employment_education'].experiences],
      },
    }))
  }

  const addFamilyMember = async (values: any, type: string) => {
    setFormValues((prevFormValues: any) => ({
      ...prevFormValues,
      _family_employer_reference: {
        ...prevFormValues._family_employer_reference,
        [type]: [...prevFormValues._family_employer_reference[type], values],
      },
    }))

    const updatedData = {
      ...formValues,
      _family_employer_reference: {
        ...formValues._family_employer_reference,
        [type]: [...formValues._family_employer_reference[type], values],
      },
    }

    // await updateData({
    //   ...updatedData,
    // })
  }

  const deleteFamilyMember = async (index: number, type: string) => {
    const deletedItem = formValues._family_employer_reference[type].at(index)

    const updatedData = {
      ...formValues,
      _family_employer_reference: {
        ...formValues._family_employer_reference,
        [type]: formValues._family_employer_reference[type].map((item: any, i: number) =>
          i === index ? {...item, status: 'deleted'} : item
        ),
      },
    }

    const updatedCorrections = {
      ...contextCorrections,
      _family_employer_reference: {
        ...contextCorrections._family_employer_reference,
        [type]: {
          ...contextCorrections._family_employer_reference[type],
          [deletedItem.id]: {...deletedItem, status: 'deleted'},
        },
      },
    }

    // console.log({...updatedData, corrections: updatedCorrections})
    setFormValues(updatedData)
    await updateData({
      ...updatedData,
      corrections: updatedCorrections,
    })
  }

  const editFamilyMember = async (updatedValues: any, type: string, index: number) => {
    setFormValues((prevFormValues: any) => ({
      ...prevFormValues,
      _family_employer_reference: {
        ...prevFormValues._family_employer_reference,
        [type]: prevFormValues._family_employer_reference[type].map((item: any, i: number) =>
          i === index ? updatedValues : item
        ),
      },
    }))

    const updatedData = {
      ...formValues,
      _family_employer_reference: {
        ...formValues._family_employer_reference,
        [type]: formValues._family_employer_reference[type].map((item: any, i: number) =>
          i === index ? updatedValues : item
        ),
      },
    }
    // await updateData({
    //   ...updatedData,
    // })
  }

  const steps = [
    <Step1
      data={candidateData?._basic_info}
      dummyData={template['_basic_info']}
      updateFormValues={updateFormValues}
      postLoading={postLoading}
      edit={personalInfo}
      closeEditSec={closeEditSec}
      candidateData={candidateData}
    />,
    <AddressContact
      dummyData={template['_address_contacts']}
      updateFormValues={updateFormValues}
      postLoading={postLoading}
      same_as_current_add={formValues['same_as_current_add'] ?? false}
      formValues={formValues}
      updateSameAsCurrentAdd={updateSameAsCurrentAdd}
      edit={addressContact}
      closeEditSec={closeEditSec}
      candidateData={candidateData}
    />,
    <EmpAndEdu
      data={candidateData?._employment_education}
      formValues={formValues}
      addQualification={addQualification}
      editQualification={editQualification}
      deleteQualification={deleteQualification}
      setFormValues={setFormValues}
      updateIsFresher={updateIsFresher}
      updateData={updateData}
      candidateData={candidateData}
      postLoading={postLoading}
      structure={template ? template['_employment_education'] : {}}
      edit={educationEmployment}
      closeEditSec={closeEditSec}
      corrections={corrections['_employment_education']}
      setCorrections={setCorrections}
    />,
    <FamilyAndEmpRef
      data={candidateData?._family_employer_reference}
      formValues={formValues._family_employer_reference}
      setFormValues={setFormValues}
      addFamilyMember={addFamilyMember}
      deleteFamilyMember={deleteFamilyMember}
      editFamilyMember={editFamilyMember}
      candidateData={formValues}
      updateData={updateData}
      postLoading={postLoading}
      structure={template ? template['_family_employer_reference'] : {}}
      edit={familyEmpRef}
      closeEditSec={closeEditSec}
    />,
    <Step5 data={candidateData?._policy_declarations} signature={candidateData?.signature} />,
    <DocumentsTable
      data={formValues?._documents_attachments}
      formValues={formValues}
      setFormValues={setFormValues}
    />,
  ]

  const nextStep = (): void => {
    if (currentStep === 0) {
      handleStepChange(1)
    }
    if (currentStep === 0) {
      const element = document.getElementById('verify-sign-up-btn')
      if (element) {
        element.click()
      }
    } else {
      const totalSteps = steps.length - 1
      if (currentStep < totalSteps) {
        handleStepChange(currentStep + 1)
      }
    }
  }

  const prevStep = (): void => {
    if (currentStep !== 0) {
      handleStepChange(currentStep - 1)
    }
  }

  return (
    <>
      {isLoading ? (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : candidateData ? (
        <div className={`card p-10 w-100`}>
          {steps[currentStep]}

          <div className='d-none d-flex flex-stack py-5'>
            <div className='mr-2'>
              {currentStep > 0 && (
                <button onClick={prevStep} type='button' className='btn btn-sm btn-light me-3'>
                  <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                  Back
                </button>
              )}
            </div>

            <div>
              <button type='submit' className='btn btn-sm btn-primary' onClick={nextStep}>
                {currentStep < steps.length - 1 ? 'Next' : 'Close'}
                <KTIcon iconName='arrow-right' className='fs-3 ms-1' />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div
          className='card d-flex flex-column justify-content-center align-items-center'
          style={{height: '293px'}}
        >
          <h3 className='fw-semibold fs-3'>Candidate data not filled yet.</h3>
        </div>
      )}
    </>
  )
}

const Step5 = ({data, signature}: any) => {
  const {declarationVals, notPolicyOrDeclarations, policies} = data
  const isDataAvailable =
    declarationVals.length === 0 &&
    policies.length === 0 &&
    Object.keys(notPolicyOrDeclarations).length === 0

  return (
    <>
      {Object.keys(notPolicyOrDeclarations).length > 0 && (
        <div className='min-w-100 d-flex flex-wrap m-auto'>
          {notPolicyOrDeclarations &&
            Object.entries(notPolicyOrDeclarations).map(([key, value]: any, index) => {
              const {label, value: val} = value
              if (val === 'Separator') {
                return (
                  <div key={index} className='w-100'>
                    <h3 className='mb-3 text-primary'>
                      <p
                        className='alert bg-light text-center text-muted'
                        style={{
                          width: '100%',
                          verticalAlign: 'middle',
                          fontSize: '12px',
                          textTransform: 'uppercase',
                        }}
                      >
                        <span className='fw-semibold'>{label}</span>
                      </p>
                    </h3>
                  </div>
                )
              } else {
                return (
                  <div key={index} className='w-50 row mb-5'>
                    <label className='col-lg-4 fw-semibold text-muted'>
                      {label.replace(/_/g, ' ')}
                    </label>
                    <div className='col-lg-8'>
                      <span className='fs-6 text-dark text-hover-primary default-cursor'>
                        {val || '-'}
                      </span>
                    </div>
                  </div>
                )
              }
            })}
        </div>
      )}
      {!isDataAvailable ? (
        <>
          <h3 className='mb-3 text-primary'>
            <p
              className='alert bg-light text-center text-muted'
              style={{
                width: '100%',
                verticalAlign: 'middle',
                fontSize: '12px',
                textTransform: 'uppercase',
              }}
            >
              <span className='fw-semibold'>Declarations</span>
            </p>
          </h3>

          {declarationVals.length ? (
            declarationVals.map((each: any, index: number) => {
              return (
                <div key={index} className='mb-5'>
                  <div className='d-flex justify-content-between'>
                    <div>
                      <h3 className='fw-semibold m-0'>{each.prompt}</h3>
                      <p className='text-muted m-0'>{each.sub_text}</p>
                    </div>
                    <h5 className='fw-semibold text-uppercase text-primary'>{each.radioValue}</h5>
                  </div>
                  {each.trigger_for === each.radioValue && (
                    <p className='m-0'>
                      <span className='text-primary'>Explanation: </span>
                      {each.textValue}
                    </p>
                  )}
                </div>
              )
            })
          ) : (
            <div className='m-auto mb-5'>N.A</div>
          )}

          <h3 className='mb-3 text-primary'>
            <p
              className='alert bg-light text-center text-muted'
              style={{
                width: '100%',
                verticalAlign: 'middle',
                fontSize: '12px',
                textTransform: 'uppercase',
              }}
            >
              <span className='fw-semibold'>Policies</span>
            </p>
          </h3>

          {policies.length ? (
            policies.map((each: any, index: number) => {
              return (
                <div key={index} className='mb-5'>
                  <div className='d-flex justify-content-between'>
                    <div>
                      <h3 className='fw-semibold m-0'>{each.field_name}</h3>
                      <p className='text-muted m-0'>{each.prompt}</p>
                    </div>
                    <h5 className='fw-semibold text-uppercase text-primary'>{each.concent}</h5>
                  </div>
                  {each.validations?.policy_url && (
                    <p className='m-0'>
                      <span className='text-primary'>Policy: </span>
                      {each.validations?.policy_url}
                    </p>
                  )}
                </div>
              )
            })
          ) : (
            <div className='m-auto mb-5'>N.A</div>
          )}

          <h3 className='mb-3 text-primary'>
            <p
              className='alert bg-light text-center text-muted'
              style={{
                width: '100%',
                verticalAlign: 'middle',
                fontSize: '12px',
                textTransform: 'uppercase',
              }}
            >
              <span className='fw-semibold'>Accuracy Acceptance</span>
            </p>
          </h3>

          <div className='d-flex justify-content-between'>
            <h3 className='fw-semibold'>Accuracy Acceptance</h3>
            <h4 className='fw-semibold text-uppercase text-primary'>Yes</h4>
          </div>

          <h3 className='mb-3 text-primary my-5'>
            <p
              className='alert bg-light text-center text-muted'
              style={{
                width: '100%',
                verticalAlign: 'middle',
                fontSize: '12px',
                textTransform: 'uppercase',
              }}
            >
              <span className='fw-semibold'>Signature</span>
            </p>
          </h3>
          <div className='d-flex justify-content-center'>
            {signature ? (
              <h3 className='fw-semibold text-success'>
                The user has succefully signed the document.
              </h3>
            ) : (
              <h3 className='fw-semibold text-danger'>User not signed yet</h3>
            )}
          </div>
        </>
      ) : (
        <div
          className='card d-flex flex-column justify-content-center align-items-center'
          style={{height: '256px'}}
        >
          <h3 className='fw-semibold fs-3'>Candidate data not filled yet.</h3>
        </div>
      )}
    </>
  )
}

export default EmpDetailsWizard
