import {Route, Routes} from 'react-router-dom'

import CandidateNavBar from './CandidateNavBar'
import CandidateInterviewsTable from './candidate_interviews/CandidateInterviewsTable'
import CandidateOffersTable from './candidate_offers/create_candidateoffers/CandidateOffersTable'
import {CandidateCard} from './CandidateCard'
import CandidateOffers from './candidate_offers/create_candidateoffers/Candidateoffers'
import CandidateInterviews from './candidate_interviews/CandidateInterviews'
import CandidateInterviewBlock from './candidate_interviews/CandidateInterviewBlock'
function CandidateHistoryRouting() {
  return (
    <div>
      <Routes>
        <Route element={<CandidateCard />}>
          <Route path={`/interviews/:candidate_id/*`} element={<CandidateInterviewBlock />} />
          <Route path={`/offers/:candidate_id/*`} element={<CandidateOffers />} />

          <Route index element={<CandidateInterviewBlock />} />
        </Route>
      </Routes>
    </div>
  )
}

export default CandidateHistoryRouting
