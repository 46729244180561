import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import clsx from 'clsx'
import usePostApi from '../custom_hooks/usePostApi'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {FindPodIdByCode} from '../FindPodIdByPodCode'
import {useThemeMode} from '../../../_metronic/partials'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {format} from 'date-fns'
import {useLang} from '../../../_metronic/i18n/Metronici18n'
import {UseToast} from '../useToast'
import CreateNewCandidate from '../recruitment/candidates/add_candidate/CreateNewCandidatesModal'
// import CreateLocation from '../master-data-management/master-data/locations/CreateLocation'
import CreateLocation from '../master-data-management/master-data/locations/AddLocation'
import {Link} from 'react-router-dom'
import {useParams} from 'react-router-dom'
import {FindBrandnameByCode} from '../FindPodIdByPodCode'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import '../DatepickerStyles.css'

interface Props {
  showModal: boolean
  setShowModal(val: boolean): void
  fetchData(): void
}

type Data = {
  candidate_id: number | string
  location_id: number | string
  template_id: number | string
  date_of_joining: string
  onbr_expiry_date: string
}

type SuccessData = {
  onbr_code: string
  onbr_pin: string
  candidateName: string
  mobile: string
}

const AddNewOnboardingRequest = (props: Props) => {
  const {execute, postLoading} = usePostApi()
  const [currentStep, setCurrentStep] = useState(0)
  const [data, setData] = useState<Data>({
    candidate_id: '',
    location_id: '',
    template_id: '',
    date_of_joining: '',
    onbr_expiry_date: '',
  })

  const [successData, setSuccessData] = useState<SuccessData>({
    onbr_code: '',
    onbr_pin: '',
    candidateName: '',
    mobile: '',
  })

  const setSecondStep = () => {
    setCurrentStep(1)
  }

  const steps: any = [
    <Step1
      data={data}
      key={'step_1'}
      setSecondStep={setSecondStep}
      setSuccessData={setSuccessData}
      fetchOnboardings={props.fetchData}
      execute={execute}
    />,
    <Step2 successData={successData} />,
  ]

  const prevStep = () => {}
  const nextStep = () => {
    if (currentStep === 0) {
      const element = document.getElementById('step1_submit')
      if (element) {
        element.click()
      }
    }
  }

  const handleCloseModal = () => {
    if (currentStep === 1) {
      setCurrentStep(0)
      setSuccessData({onbr_code: '', onbr_pin: '', candidateName: '', mobile: ''})
    }
    props.setShowModal(false)
  }

  useEffect(() => {
    if (props.showModal === false && currentStep === 1) {
      setData({
        candidate_id: '',
        location_id: '',
        template_id: '',
        date_of_joining: '',
        onbr_expiry_date: '',
      })
      setCurrentStep(0)
    }
  }, [props.showModal, currentStep])

  return (
    <Modal
      show={props.showModal}
      onHide={() => props.setShowModal(false)}
      centered
      dialogClassName='custom-modal modal-dialog-centered'
      contentClassName='custom-modal-content'
    >
      <Modal.Body className='overflow-auto w-100'>
        <div className='card py-0 overflow-auto'>
          <div className='w-100 m-auto'>
            <div className='d-flex justify-content-between mb-10'>
              <h3 className='fs-3 fw-semibold'>Create Onboarding Request</h3>
              <button
                type='button'
                className='d-block btn btn-sm btn-icon btn-light'
                onClick={handleCloseModal}
              >
                <KTIcon iconName='cross' className='fs-1' />
              </button>
            </div>

            <div className='w-100 row m-0'>
              <div className='col-lg-5 p-0 mb-5 mb-lg-0'>
                <div className=''>
                  <div className='d-flex'>
                    <div
                      style={{borderRadius: '10px'}}
                      className={clsx(
                        'w-40px h-40px btn d-flex justify-content-center align-items-center',
                        {
                          'btn-primary': currentStep === 0,
                          'btn-light-primary': currentStep !== 0,
                        }
                      )}
                    >
                      {currentStep === 0 ? 1 : <i className='stepper-check fas fa-check'></i>}
                    </div>
                    <div className='ms-5'>
                      <h6
                        className={clsx('mb-0 fw-semibold', {
                          'text-muted': currentStep >= 0,
                        })}
                      >
                        Step 1
                      </h6>
                      <p
                        className={clsx('fs-6 mb-0', {
                          'text-muted': currentStep >= 0,
                        })}
                      >
                        Basic Info
                      </p>
                    </div>
                  </div>
                  <div className='ms-6 h-30px border-left border-dashed border-muted border-top-0 border-bottom-0 border-right-0 border-left-1'></div>
                  <div className='d-flex'>
                    <div
                      style={{borderRadius: '10px'}}
                      className={clsx(
                        'w-40px h-40px btn d-flex justify-content-center align-items-center',
                        {
                          'btn-primary': currentStep === 1,
                          'btn-light-primary': currentStep !== 1,
                        }
                      )}
                    >
                      {currentStep > 1 ? <i className='stepper-check fas fa-check'></i> : 2}
                    </div>
                    <div className='ms-5'>
                      <h6
                        className={clsx('mb-0 fw-semibold', {
                          'text-muted': currentStep >= 1,
                        })}
                      >
                        Step 2
                      </h6>
                      <p
                        className={clsx('fs-6', {
                          'text-muted': currentStep >= 1,
                        })}
                      >
                        Onboading Details
                      </p>
                    </div>
                  </div>
                  {/* Add more steps here */}
                </div>
              </div>
              <div className='col-lg-7 p-0 m-0'>{steps[currentStep]}</div>
              {/* <div className='col-lg-7 p-0 m-0'>{steps[currentStep]}</div> */}
            </div>

            <div className='w-100 d-flex flex-stack mt-5'>
              <div className='mr-2'>
                {currentStep > 0 && currentStep !== 1 && (
                  <button onClick={prevStep} type='button' className='btn btn-sm btn-light me-3'>
                    <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                    Back
                  </button>
                )}
              </div>

              <div>
                {currentStep !== steps.length - 1 && (
                  <button
                    type='submit'
                    className='btn btn-sm btn-light-primary'
                    onClick={nextStep}
                    disabled={postLoading}
                  >
                    {postLoading ? (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    ) : (
                      <>
                        Create
                        <KTIcon iconName='arrow-right' className='fs-2 ms-1' />
                      </>
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

interface Step1Props {
  data: Data
  setSecondStep(): void
  setSuccessData(data: {
    onbr_code: string
    onbr_pin: string
    candidateName: string
    mobile: string
  }): void
  fetchOnboardings(): void
  execute: any
}

const step1Schema = Yup.object().shape({
  candidate_id: Yup.string().required('Candidate is required'),
  location_id: Yup.string().required('Location is required'),
  template_id: Yup.string().required('Template is required'),
  // date_of_joining: Yup.string()
  //   .min(2, 'Minimum 2 characters')
  //   .max(250, 'Maximum 250 characters')
  //   .required('Date of Joining is required'),
  // onbr_expiry_date: Yup.string()
  //   .min(2, 'Minimum 2 characters')
  //   .max(250, 'Maximum 250 characters')
  //   .required('Request Expiry Date is required'),

  date_of_joining: Yup.string()
    .required('Date of Joining is required')
    .test('is-valid-date', 'Invalid date format', (value: any) => {
      const date = new Date(value)
      return !isNaN(date.getTime())
    }),
  onbr_expiry_date: Yup.string()
    .required('Request Expiry Date is required')
    .test('is-valid-date', 'Invalid date format', (value: any) => {
      const date = new Date(value)
      return !isNaN(date.getTime())
    }),
  // .test(
  //   'is-valid-expiry-date',
  //   'Expiry Date should be less than Joining date and not today',
  //   function (value: any, {parent}: any) {
  //     const dateOfJoining = new Date(parent.date_of_joining)
  //     const expiryDate = new Date(value)
  //     const today = new Date()

  //     if (expiryDate.getTime() === today.getTime()) {
  //       return false
  //     }

  //     if (expiryDate >= dateOfJoining) {
  //       return false
  //     }

  //     const twoDaysBeforeJoining = new Date(dateOfJoining)
  //     twoDaysBeforeJoining.setDate(twoDaysBeforeJoining.getDate() - 2)

  //     return expiryDate > today && expiryDate < dateOfJoining
  //   }
  // ),
})

const Step1 = (props: Step1Props) => {
  const [showKM, setShowKM] = useState(false)
  const [showCreateLocation, setShowCreateLocation] = useState(false)
  const [candidateName, setCandidateName] = useState('')

  const {mode} = useThemeMode()
  const lang = useLang()
  const {execute} = usePostApi()
  const podId = FindPodIdByCode()
  const [candidates, setCandidates] = useState([])
  const [locations, setLocations] = useState([])
  const [templates, settemplates] = useState([])

  const [singleCandidate, setSingleCandidate] = useState('')
  const [singleLocation, setSingleLocation] = useState('')
  const [singleTemp, setSingleTemp] = useState('')

  const getActiveCandidates = async () => {
    try {
      const url = '/pod/job_offers/active/list'
      // const url = '/pod/active/candidates'
      const formData = {pod_id: podId}
      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((candidate: any) => ({
        value: candidate.candidate_id,
        label: `${candidate.candidate_first_name} ${candidate.candidate_last_name}`,
      }))
      setCandidates(options)
      if (options.length === 1) {
        setSingleCandidate(options[0].value)
        setCandidateName(options[0].label)
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const getActiveLocations = async () => {
    try {
      const url = '/pod/locations/active'
      const formData = {pod_id: podId}
      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((location: any) => ({
        value: location.location_id,
        label: location.location_name,
      }))
      setLocations(options)
      if (options.length === 1) {
        setSingleLocation(options[0].value)
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const getTemplates = async () => {
    try {
      const url = `/pod/templates/active`
      const formData = {
        pod_id: podId,
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.template_id,
        label: each.template_name,
      }))
      settemplates(options)
      if (options.length === 1) {
        setSingleTemp(options[0].value)
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getActiveCandidates()
    getActiveLocations()
    getTemplates()
  }, [])

  const handleCandidateChange = (setFieldValue: any, selectedOption: any) => {
    setCandidateName(selectedOption.label)
    setFieldValue('candidate_id', selectedOption?.value || '')
  }
  const handleLocationChange = (setFieldValue: any, selectedOption: any) => {
    setFieldValue('location_id', selectedOption?.value || '')
  }

  const handletemplateChange = (setFieldValue: any, selectedOption: any) => {
    setFieldValue('template_id', selectedOption?.value || '')
  }

  const validateExpiryDate = (joiningDate: any, expiryDate: string): string => {
    const today = new Date()
    const jd = new Date(joiningDate)
    const ed = new Date(expiryDate)

    today.setHours(0, 0, 0, 0)
    jd.setHours(0, 0, 0, 0)
    ed.setHours(0, 0, 0, 0)

    const tomorrow = new Date()
    tomorrow.setHours(0, 0, 0, 0)
    tomorrow.setDate(tomorrow.getDate() + 1)

    if (jd.getTime() === tomorrow.getTime() && ed.getTime() === tomorrow.getTime()) {
      return ''
    } else if (jd.getTime() === tomorrow.getTime() && ed.getTime() > tomorrow.getTime()) {
      return 'Request expiry date should be less than joining date.'
    } else if (jd.getTime() > tomorrow.getTime()) {
      if (ed.getTime() >= jd.getTime()) {
        return 'Request expiry date should be less than joining date.'
      } else {
        return ''
      }
    } else {
      return ''
    }
  }

  const onSubmit = async (values: any) => {
    const validate = validateExpiryDate(values.date_of_joining, values.onbr_expiry_date)
    if (validate !== '') {
      return
    }
    const formData = {
      ...values,
      pod_id: podId,
    }
    try {
      const url = '/pod/onboarding/request/create'

      const response = await props.execute(url, 'POST', formData, 'post')
      props.setSecondStep()
      props.setSuccessData({...response.data, candidateName: candidateName})
      props.fetchOnboardings()
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
    } catch (error: any) {
      console.error('Error:', error)

      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }

  const getMaxDate = (joiningDate: string) => {
    const today = new Date()
    const jd = new Date(joiningDate)

    today.setHours(0, 0, 0, 0)
    jd.setHours(0, 0, 0, 0)

    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    tomorrow.setHours(0, 0, 0, 0)
    if (jd.getTime() == tomorrow.getTime()) {
      return tomorrow
    } else {
      const maxDate = new Date(joiningDate)
      maxDate.setDate(maxDate.getDate() - 1)
      return maxDate
    }
  }

  const initialValues = {
    ...props.data,
    candidate_id: singleCandidate,
    location_id: singleLocation,
    template_id: singleTemp,
  }

  return (
    <>
      <CreateNewCandidate
        show={showKM}
        onClose={() => setShowKM(false)}
        fetchData={getActiveCandidates}
      />
      <CreateLocation
        className='modal-backdrop'
        showModal={showCreateLocation}
        setShowModal={setShowCreateLocation}
        fetchData={getActiveLocations}
        closeModal={() => setShowCreateLocation(false)}
      />
      <Formik
        validationSchema={step1Schema}
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({setFieldValue, values}: any) => {
          return (
            <Form className='w-100'>
              {/* <div className='d-flex justify-content-between'> */}
              <div className='alert alert-danger d-flex align-items-center p-1'>
                <KTIcon iconName='information' className='fs-2 text-danger me-2' />
                <div className='d-flex flex-column'>
                  <h6 className='mb-0 fw-semibold fs-7 text-dark'>Note:</h6>
                  <span className='fs-8 text-dark'>
                    You can create an onboarding request only for candidates who have accepted their
                    offer.
                  </span>
                </div>
              </div>
              <div className='w-100 mb-5'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Candidate</span>
                  <i
                    className='fas fa-info-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Please enter the full name of the candidate.'
                  ></i>
                </label>
                <div className='d-flex align-items-center'>
                  <div style={{width: '95%'}}>
                    <Select
                      value={candidates.find((option: any) => option.value === values.candidate_id)}
                      styles={{
                        control: (baseStyles: any, state: any) => ({
                          ...baseStyles,
                          backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                          color: '#ffffff',
                          borderWidth: '0px',
                        }),
                        menu: (baseStyles: any) => ({
                          ...baseStyles,
                          backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                          // maxHeight: '240px',
                          // overflowY: 'scroll',
                        }),
                        singleValue: (baseStyles: any, state: any) => ({
                          ...baseStyles,
                          color: mode === 'dark' ? '#92929f' : '#000000',
                        }),
                        input: (baseStyles: any) => ({
                          ...baseStyles,
                          color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                        }),
                        option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                          ...baseStyles,
                          backgroundColor: isSelected
                            ? '#C2C2C2'
                            : isFocused
                            ? '#e0e0e0'
                            : baseStyles.backgroundColor,
                          color: isSelected ? '#000000' : isFocused ? '#000000' : baseStyles.color,
                        }),
                      }}
                      theme={(theme: any) => ({
                        ...theme,
                        borderRadius: 5,
                        backgroundColor: '#000000',
                        colors: {
                          ...theme.colors,
                          primary25: 'rgba(80, 107, 236, 0.5)',
                          primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                        },
                      })}
                      className='react-select-container'
                      classNamePrefix='react-select'
                      options={candidates}
                      isSearchable
                      placeholder='Select Candidate'
                      onChange={(val: any) => handleCandidateChange(setFieldValue, val)}
                    />
                  </div>
                  <button
                    className='btn btn-icon btn-light btn-sm ms-2'
                    type='button'
                    onClick={() => setShowKM(true)}
                    data-bs-toggle='tooltip'
                    title='Create New Candidate'
                  >
                    <KTIcon iconName='plus' className='fs-2' />
                  </button>
                </div>
                <div className='text-danger mt-2'>
                  <ErrorMessage name='candidate_id' />
                </div>
              </div>

              <div className='w-100 mb-5'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Location</span>
                  <i
                    className='fas fa-info-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Please enter the location where the onboarding will take place.'
                  ></i>
                </label>
                <div className='d-flex align-items-center'>
                  <div style={{width: '95%'}}>
                    <Select
                      value={locations.find((option: any) => option.value === values.location_id)}
                      styles={{
                        control: (baseStyles: any, state: any) => ({
                          ...baseStyles,
                          backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                          color: '#ffffff',
                          borderWidth: '0px',
                        }),
                        menu: (baseStyles: any) => ({
                          ...baseStyles,
                          backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                        }),
                        singleValue: (baseStyles: any, state: any) => ({
                          ...baseStyles,
                          color: mode === 'dark' ? '#92929f' : '#000000',
                        }),
                        input: (baseStyles: any) => ({
                          ...baseStyles,
                          color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                        }),
                        option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                          ...baseStyles,
                          backgroundColor: isSelected
                            ? '#C2C2C2'
                            : isFocused
                            ? '#e0e0e0'
                            : baseStyles.backgroundColor,
                          color: isSelected ? '#000000' : isFocused ? '#000000' : baseStyles.color,
                        }),
                      }}
                      theme={(theme: any) => ({
                        ...theme,
                        borderRadius: 5,
                        backgroundColor: '#000000',
                        colors: {
                          ...theme.colors,
                          primary25: 'rgba(80, 107, 236, 0.5)',
                          primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                        },
                      })}
                      className='react-select-container'
                      classNamePrefix='react-select'
                      options={locations}
                      isSearchable
                      placeholder='Select Location'
                      onChange={(val: any) => handleLocationChange(setFieldValue, val)}
                    />
                  </div>
                  <button
                    className='btn btn-icon btn-light btn-sm ms-2'
                    type='button'
                    onClick={() => setShowCreateLocation(true)}
                    data-bs-toggle='tooltip'
                    title='Create New Location'
                  >
                    <KTIcon iconName='plus' className='fs-2' />
                  </button>
                </div>
                <div className='text-danger mt-2'>
                  <ErrorMessage name='location_id' />
                </div>
              </div>
              {/* </div> */}

              <div className='w-100 mb-5'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  Onboarding Template
                  <i
                    className='fas fa-info-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Please select the onboarding template to be used for this employee. This template will include all necessary steps and information required for a smooth onboarding process.'
                  ></i>
                </label>

                <div>
                  <Select
                    value={templates.find((option: any) => option.value === values.template_id)}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                        color: '#ffffff',
                        borderWidth: '0px',
                      }),
                      menu: (baseStyles) => ({
                        ...baseStyles,
                        backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                      }),
                      singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        color: mode === 'dark' ? '#92929f' : '#000000',
                      }),
                      input: (baseStyles) => ({
                        ...baseStyles,
                        color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                      }),
                      option: (baseStyles, {isFocused, isSelected}) => ({
                        ...baseStyles,
                        backgroundColor: isSelected
                          ? '#C2C2C2'
                          : isFocused
                          ? '#e0e0e0'
                          : baseStyles.backgroundColor,
                        color: isSelected ? '#000000' : isFocused ? '#000000' : baseStyles.color,
                      }),
                    }}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 5,
                      backgroundColor: '#000000',
                      colors: {
                        ...theme.colors,
                        primary25: 'rgba(80, 107, 236, 0.5)',
                        primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                      },
                    })}
                    className='react-select-container'
                    classNamePrefix='react-select'
                    options={templates}
                    isSearchable
                    placeholder='Select template'
                    onChange={(val) => handletemplateChange(setFieldValue, val)}
                  />

                  <ErrorMessage name='template_id' component='div' className='text-danger mt-2' />
                </div>
              </div>

              <div className='d-flex justify-content-between'>
                <div className='w-50 mb-5 me-2'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Date of Joining</span>
                    <i
                      className='fas fa-info-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Please select the date when the candidate will join.'
                    ></i>
                  </label>
                  <div className='text-start mb-4'>
                    <Field name='date_of_joining'>
                      {({form, field}: any) => {
                        const {setFieldValue} = form
                        const {value} = field

                        const handleChange = (date: any) => {
                          if (date instanceof Date && !isNaN(date.getTime())) {
                            const dateTime = format(date, 'yyyy-MM-dd HH:mm:ss')
                            setFieldValue('date_of_joining', dateTime)
                          } else {
                            setFieldValue('date_of_joining', null)
                          }
                        }

                        const tomorrow = new Date()
                        tomorrow.setDate(tomorrow.getDate() + 1)

                        return (
                          <div
                            className={`react-datepicker-fullwidth-wrapper test ${
                              mode === 'dark' ? 'dark-mode' : 'light-mode'
                            }`}
                          >
                            <DatePicker
                              className='form-control form-control-solid'
                              selected={value}
                              showYearDropdown
                              scrollableYearDropdown
                              dateFormat='dd-MM-yyyy'
                              minDate={tomorrow}
                              onChange={(date: any) => {
                                handleChange(date)
                              }}
                              placeholderText='Pick a Date'
                              showMonthDropdown
                              useShortMonthInDropdown
                              locale={lang || 'en'}
                            />
                          </div>
                        )
                      }}
                    </Field>
                    <ErrorMessage
                      name='date_of_joining'
                      component='div'
                      className='text-danger mt-2'
                    />
                  </div>
                </div>
                <div className='w-50 mb-5 ms-2'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Request Expiry Date</span>
                    <i
                      className='fas fa-info-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Please select the date when the onboarding request will expire.'
                    ></i>
                  </label>
                  <Field name='onbr_expiry_date'>
                    {({form, field}: any) => {
                      const {setFieldValue} = form
                      const {value} = field

                      const handleChange = (date: any) => {
                        if (date instanceof Date && !isNaN(date.getTime())) {
                          const dateTime = format(date, 'yyyy-MM-dd HH:mm:ss')
                          setFieldValue('onbr_expiry_date', dateTime)
                        } else {
                          setFieldValue('onbr_expiry_date', null)
                        }
                      }

                      const tomorrow = new Date()
                      tomorrow.setDate(tomorrow.getDate() + 1)

                      const maxDate = new Date(values.date_of_joining)
                      maxDate.setDate(maxDate.getDate() - 1)

                      const timeFormatFunc = (val: any) => {
                        if (val) {
                          const dateValue = new Date(val)
                          if (
                            dateValue.getHours() === 0 &&
                            dateValue.getMinutes() === 0 &&
                            dateValue.getSeconds() === 0
                          ) {
                            const newValue = new Date(val)
                            newValue.setHours(23)
                            newValue.setMinutes(59)
                            return newValue
                          } else {
                            return val
                          }
                        } else {
                          return ''
                        }
                      }

                      return (
                        <div
                          className={`react-datepicker-fullwidth-wrapper test ${
                            mode === 'dark' ? 'dark-mode' : 'light-mode'
                          }`}
                        >
                          <DatePicker
                            className='form-control form-control-solid'
                            selected={timeFormatFunc(value)}
                            showYearDropdown
                            scrollableYearDropdown
                            dateFormat='dd-MM-yyyy HH:mm:ss'
                            showTimeInput
                            // minDate={getMinDate(values.date_of_joining)}
                            minDate={tomorrow}
                            // maxDate={tomorrow}
                            maxDate={getMaxDate(values.date_of_joining)}
                            onChange={(date: any) => {
                              handleChange(date)
                            }}
                            placeholderText='Pick a Date'
                            showMonthDropdown
                            useShortMonthInDropdown
                            locale={lang || 'en'}
                          />
                        </div>
                      )
                    }}
                  </Field>

                  <div className='text-danger mt-2'>
                    <ErrorMessage name='onbr_expiry_date' />
                  </div>
                  <div className='text-danger mt-2'>
                    {validateExpiryDate(values.date_of_joining, values.onbr_expiry_date)}
                  </div>
                </div>
              </div>

              <button
                type='submit'
                className='d-none btn btn-light-primary btn-sm'
                id='step1_submit'
              >
                Submit
              </button>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

type Step2Props = {
  successData: SuccessData
}

const Step2 = (props: Step2Props) => {
  const {id} = useParams()
  const {execute, postLoading} = usePostApi()
  const brandName = FindBrandnameByCode()
  const [showPopover, setShowPopover] = useState(false)

  const popover = (
    <Popover id='popover-basic'>
      <Popover.Body className='p-2'>Copied</Popover.Body>
    </Popover>
  )

  const handleCopyToClipboard = (values: any) => {
    const {onbr_pin, onbr_code, candidateName} = values
    const details = `Onboarding information for ${brandName}\n\nCandidate Name: ${candidateName}\nOnboarding Request ID: ${onbr_code}\nRequest PIN: ${onbr_pin}`

    const tempInput = document.createElement('textarea')
    tempInput.value = details
    tempInput.setAttribute('readonly', '')
    tempInput.style.position = 'absolute'
    tempInput.style.left = '-9999px'
    document.body.appendChild(tempInput)
    tempInput.select()
    document.execCommand('copy')
    document.body.removeChild(tempInput)
    setShowPopover(true)

    setTimeout(() => {
      setShowPopover(false)
    }, 2000)
  }

  const handleCopyWhatsAppMsg = (values: any) => {
    const {onbr_pin, onbr_code, candidateName} = values
    const details = `Onboarding information for ${brandName}\n\nCandidate Name: ${candidateName}\nOnboarding Request ID: ${onbr_code}\nRequest PIN: ${onbr_pin}`
    return details
  }

  const sendOnboardingRequest = async (code: string, pin: string) => {
    try {
      const url = '/pod/onboarding/request/send'
      const formData = {
        onbr_code: code,
        onbr_pin: pin,
      }

      const response = await execute(url, 'POST', formData, 'post')
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
    } catch (error: any) {
      console.error('Error:', error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }

  return (
    <>
      <div className='alert alert-success d-flex align-items-center p-1 mb-7'>
        <KTIcon iconName='check-circle' className='fs-1 text-success me-2' />
        <div className='d-flex flex-column'>
          <h6 className='mb-0 fw-semibold fs-6 text-dark'>Congratulations!</h6>
          <span className='fs-7 text-dark'>
            You have successfully created an onboarding request. You can now share the request with
            the employee for them to complete the onboarding process.
          </span>
        </div>
      </div>

      <div className='d-flex'>
        <div className='w-100 d-flex me-6 mb-3'>
          <div className='border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 me-2'>
            <span className='fs-6 fw-bold text-primary'>
              <span className='ms-n1'>{props.successData.onbr_code}</span>
            </span>
            <div className='fw-semibold text-gray-500'>Onboarding Request ID</div>
          </div>
        </div>

        <div className='w-100 d-flex mb-3'>
          <div className='border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 me-2'>
            <span className='fs-6 fw-bold text-primary'>
              <span className='ms-n1'>{props.successData.onbr_pin}</span>
            </span>
            <div className='fw-semibold text-gray-500'>Request PIN</div>
          </div>
        </div>

        <div className='m-auto pb-3'>
          <OverlayTrigger show={showPopover} placement='top' overlay={popover}>
            <button
              className='btn btn-icon btn-light btn-sm m-auto'
              onClick={() => handleCopyToClipboard(props.successData)}
              data-toggle='tooltip'
              title='Copy'
            >
              <KTIcon iconName='copy' className='fs-1' />
            </button>
          </OverlayTrigger>
        </div>
      </div>

      <div className='d-flex justify-content-between my-4'>
        <button
          className='btn btn-sm btn-light-primary me-2'
          style={{minWidth: '220px'}}
          onClick={() =>
            sendOnboardingRequest(props.successData.onbr_code, props.successData.onbr_pin)
          }
        >
          {postLoading ? (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            <>
              <KTIcon iconName='sms' className='fs-2' />
              Send Onboarding E-mail
            </>
          )}
        </button>
        <button
          className='btn btn-icon btn-light-success btn-sm me-2'
          onClick={() => {
            handleCopyToClipboard(props.successData)
            window.open(`https://wa.me/${props.successData.mobile}`, '_blank')
          }}
        >
          <KTIcon iconName='whatsapp' className='fs-1' />
        </button>

        {/* <button
          className='btn btn-icon btn-light-success btn-sm me-2'
          onClick={() => {
            const details = handleCopyWhatsAppMsg(props.successData)
            const whatsappUrl = `https://wa.me/${
              props.successData.mobile
            }?text=${encodeURIComponent(details)}`
            window.open(whatsappUrl, '_blank')
          }}
        >
          <KTIcon iconName='whatsapp' className='fs-1' />
        </button> */}
        <button
          className='btn btn-icon btn-light-info btn-sm me-2'
          onClick={() => window.open('https://messenger.com', '_blank')}
        >
          <KTIcon iconName='message-text-2' className='fs-1' />
        </button>
        <Link
          to={`/${id}/onboarding/onboarding-info/${props.successData.onbr_code}/${props.successData.onbr_pin}`}
          className='btn btn-light-primary btn-sm ms-2'
        >
          Finish
          <KTIcon iconName='arrow-right' className='fs-2 ms-2' />
        </Link>
      </div>
    </>
  )
}

export default AddNewOnboardingRequest
