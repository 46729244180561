import React from 'react'
import {Modal} from 'react-bootstrap'
import TemplatesKM from './templates_KM/TemplatesKM'
import {KTIcon} from '../../../_metronic/helpers'

interface Props {
  showModal: boolean
  setShowModal(val: boolean): void
}

function TemplatesKMmain(props: Props) {
  return (
    <Modal
      show={props.showModal}
      onHide={() => props.setShowModal(false)}
      centered
      dialogClassName='custom-modal modal-dialog-centered'
      contentClassName='custom-modal-content'
    >
      <Modal.Body className='overflow-auto w-100'>
        <div className='d-flex justify-content-between align-items-center'>
          <h3 className='mb-4 fw-semibold'>Creating Templates with Drag-and-Drop Ease</h3>
          <button
            type='button'
            className='d-block btn btn-sm btn-icon btn-light'
            onClick={() => props.setShowModal(false)}
          >
            <KTIcon iconName='cross' className='fs-1' />
          </button>
        </div>

        <p className=''>
          Welcome to the Template Page! This section empowers you to seamlessly create, manage, and
          customize templates to suit your needs. Explore the intuitive drag-and-drop functionality
          to design templates effortlessly.
        </p>
        <TemplatesKM />
      </Modal.Body>
    </Modal>
  )
}

export default TemplatesKMmain
