import React from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {Link, useParams} from 'react-router-dom'
import usePageTitle from '../custom_hooks/usePageTitle'
import FoldersTable from './FoldersTable'

const documentsData = [
  {
    id: 1,
    document_type_name: 'Organization',
    file_id: 'inv-12345',
    document_url: 'https://example.com/documents/invoice-12345.pdf',
  },
  {
    id: 2,
    document_type_name: 'Onboarding',
    file_id: 'rec-67890',
    document_url: 'https://example.com/documents/receipt-67890.pdf',
  },
  {
    id: 3,
    document_type_name: 'Offers',
    file_id: 'con-11223',
    document_url: 'https://example.com/documents/contract-11223.pdf',
  },
]

const Documents = () => {
  const {id} = useParams()
  usePageTitle('Documents')
  const documents = [
    {
      title: `(${id})`,
      path: `/${id}/documents`,
      isSeparator: false,
      isActive: false,
    },
  ]
  return (
    <>
      <PageTitle breadcrumbs={documents}>Documents</PageTitle>
      {/* 
      <div className='d-flex flex-row'>
        <Link
          to={`/${id}/documents/company-docs`}
          className='card d-flex justify-content-center text-center flex-column p-8 me-5'
        >
          <div className='text-gray-800 text-hover-primary d-flex flex-column'>
            <div className='symbol symbol-75px mb-6'>
              <img
                src={toAbsoluteUrl('/media/svg/files/folder-document.svg')}
                alt='Folder Document'
              />
            </div>
            <div className='fs-5 fw-semibold mb-2'>Company Documents</div>
          </div>
          <div className='fs-7 fw-normal text-gray-500 mt-auto'>7 files</div>
        </Link>
        <Link
          to={`/${id}/documents/onboarding-docs`}
          className='card d-flex justify-content-center text-center flex-column p-8'
        >
          <div className='text-gray-800 text-hover-primary d-flex flex-column'>
            <div className='symbol symbol-75px mb-6'>
              <img
                src={toAbsoluteUrl('/media/svg/files/folder-document.svg')}
                alt='Folder Document'
              />
            </div>
            <div className='fs-5 fw-semibold mb-2'>Onboarding Documents</div>
          </div>
          <div className='fs-7 fw-normal text-gray-500 mt-auto'>7 files</div>
        </Link>
      </div> */}

      <FoldersTable data={documentsData} />
    </>
  )
}

export default Documents
