import {useMemo, useState, useEffect} from 'react'
import {Pagination} from 'react-bootstrap'
import {useIntl, FormattedMessage} from 'react-intl'
import {KTIcon} from '../../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import {useTable, usePagination} from 'react-table'
import {EditCandidatePopover} from './edit_candidate/EditCandidatePopover'
import {UseToast} from '../../useToast'
import {Link} from 'react-router-dom'
import usePageTitle from '../../custom_hooks/usePageTitle'
import CandidatesKnowMoreModal from './candidatesknowmore/CandidatesKnowmoremodal'

import {OverlayTrigger, Popover} from 'react-bootstrap'
import ScheduleInterviews from './createinterviews_offers/ScheduleInterviews'
import usePostApi from '../../custom_hooks/usePostApi'

import CreateOffersModal from './createinterviews_offers/CreateOffersModal'
import {FindPodIdByCode} from '../../FindPodIdByPodCode'
import CandidatesKnowMoreCards from './candidatesknowmore/CandidatesKnowmorecards'
import CreateNewCandidatesModal from './add_candidate/CreateNewCandidatesModal'

const DataTable = ({columns, data, fetchData, loading, value, refresh}) => {
  const intl = useIntl()
  const {id} = useParams()
  const [addModal, setAddModal] = useState(false)

  const [showKM, setShowKM] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showoffer, setShowoffer] = useState(false)
  const handleOpenModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }
  const [inputVal, setInputVal] = useState('')
  usePageTitle('Candidates')
  const [functionCall, updateId] = useState('')
  const filteredData = useMemo(() => {
    return data
      ? data.filter((each) => {
          const nameMatches =
            each.candidate_first_name &&
            each.candidate_first_name.toLowerCase().includes(inputVal.toLowerCase())

          const numberMatches = each.mobile && each.mobile.includes(inputVal.toLowerCase())

          const phoneMatches = each.alt_phone && each.alt_phone.includes(inputVal.toLowerCase())

          const emailaddress =
            each.email_address && each.email_address.toLowerCase().includes(inputVal.toLowerCase())

          return nameMatches || numberMatches || phoneMatches || emailaddress
        })
      : []
  }, [inputVal, data])

  const tableInstance = useTable({columns, data: filteredData}, usePagination)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: {pageIndex, pageSize},
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    gotoPage,
    setPageSize,
  } = tableInstance

  const pageOptions = [10, 20, 30, 40, 50]

  return (
    <>
      <CandidatesKnowMoreModal showModal={showKM} setShowModal={setShowKM} />
      <>
        {loading && refresh === false ? (
          <div className='card h-250px'>
            <div className='m-auto d-flex flex-column align-items-center'>
              <div className='spinner-border spinner-primary mr-15'></div>
            </div>
          </div>
        ) : data.length > 0 ? (
          <>
            <div className='d-flex flex-wrap flex-stack'>
              <h1 className='fw-semibold fs-3 mb-3'>Manage Candidates</h1>
              <div className='d-flex'>
                <div className='d-flex'>
                  <div className='m-auto d-flex flex-column align-items-center'>
                    <button className='btn btn-sm btn-light' onClick={() => setShowKM(true)}>
                      <i className='ki-duotone ki-information-3 fs-3'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                      Know More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <p className='fw-normal fs-6 text-gray-600 mb-4'>
              Seamlessly create, update, and track candidates, ensuring no details are overlooked
              during the hiring process.
            </p>
            {refresh ? (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <div className='spinner-border spinner-primary mr-15'></div>
                </div>
              </div>
            ) : (
              <div className='card py-2 px-10 mb-5'>
                <div className='card-header border-0 px-0'>
                  <div className='d-flex flex-column'>
                    {/* <h1 className='fw-semibold fs-3 mt-2 mb-0'>Candidate Details</h1>
                  <p className='fw-normal fs-6 text-gray-600 '>
                    You can view all the Candidate Details here
                  </p> */}
                  </div>
                  <div className='card-toolbar'>
                    <div className='d-flex align-items-center position-relative me-3 mb-1 mb-md-0'>
                      <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                      <input
                        type='search'
                        id='kt_filter_search'
                        className='form-control form-control-white form-control-sm w-150px ps-9 fw-normal'
                        placeholder='Search'
                        value={inputVal}
                        onChange={(e) => setInputVal(e.target.value)}
                      />
                    </div>
                    <div
                      className='d-flex justify-content-center align-items-center'
                      data-toggle='tooltip'
                      title='Add Candidate'
                    >
                      <button
                        className='btn btn-sm btn-light-primary me-1'
                        id='side_activities_toggle'
                        onClick={handleOpenModal}
                      >
                        <KTIcon iconName='plus' className='fs-3' />
                        Add Candidate
                      </button>
                    </div>
                    <button
                      className='btn btn-sm btn-light-primary fw-normal mb-1 mb-md-0 ms-4'
                      onClick={() => fetchData(true)}
                      data-bs-toggle='tooltip'
                      title='Click to load the latest data.'
                    >
                      <i className='ki-solid ki-arrows-circle fs-2'></i>
                    </button>
                  </div>
                </div>
                <div className='mb-0 mb-xl-8   overflow-auto'>
                  <table
                    className='table table-row-dashed table-row-gray-300 align-middle'
                    {...getTableProps()}
                  >
                    <thead>
                      <tr className='fw-semibold text-muted bg-light rounded'>
                        <th className='min-w-150px default-cursor text-start ps-3'>Candidate</th>
                        <th className='min-w-120px text-start default-cursor'>Gender</th>
                        <th className='min-w-120px text-start default-cursor'>Email</th>
                        <th className='min-w-100px text-start default-cursor'>Phone No.</th>
                        <th className='min-w-100px text-start default-cursor'>Phone No. (Alt)</th>
                        {/* <th className='min-w-100px text-start default-cursor '>
                              Contacted Date (Last)
                            </th> */}

                        <th className='min-w-100px text-center default-cursor'>Actions</th>
                      </tr>
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.length > 0 ? (
                        page.map((row) => {
                          prepareRow(row)
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => (
                                <td className='text-center' {...cell.getCellProps()}>
                                  {cell.render('Cell')}
                                </td>
                              ))}
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td className='' colSpan={8}>
                            <div className='d-flex justify-content-center'>
                              <p className='text-dark default-cursor fs-6 mt-5'>
                                {inputVal === '' ? (
                                  intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
                                ) : (
                                  <FormattedMessage
                                    id='MSG.NO_DATA_MATCH'
                                    values={{
                                      inputVal: <span className='text-primary'>{inputVal}</span>,
                                    }}
                                  />
                                )}
                              </p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {data.length > 10 && (
              <Pagination>
                <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                <Pagination.Prev onClick={previousPage} disabled={!canPreviousPage} />

                {[...Array(pageCount)].map((_, i) => (
                  <Pagination.Item key={i} active={i === pageIndex} onClick={() => gotoPage(i)}>
                    {i + 1}
                  </Pagination.Item>
                ))}

                <Pagination.Next onClick={nextPage} disabled={!canNextPage} />
                <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />

                <div className='d-flex align-items-center mx-5'>
                  <span className='me-2'>Show</span>
                  <select
                    className='form-select form-select-solid fw-bold w-75px me-2'
                    value={pageSize}
                    onChange={(e) => setPageSize(Number(e.target.value))}
                  >
                    {pageOptions.map((option) => (
                      <option key={option} value={option} className='select-bg'>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </Pagination>
            )}

            <div className='my-5 ps-6 cursor-default d-flex flex-row align-items-center'>
              <h4 className='fs-7 fw-normal text-muted mt-1'>LEGEND:</h4>
              <div className='d-flex align-items-center mx-3'>
                <span className='me-2'>
                  <i className='ki-duotone ki-calendar-add fs-2'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                    <span className='path4'></span>
                    <span className='path5'></span>
                    <span className='path6'></span>
                  </i>
                </span>
                <span className='text-muted fs-7 mb-1'>Schedule Interview</span>
              </div>
              <span>|</span>
              <div className='d-flex align-items-center mx-3'>
                <span className='me-2'>
                  <i className='ki-duotone ki-add-files fs-2'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                </span>
                <span className='text-muted fs-7 mb-1'>Create Offer</span>
              </div>
              <span>|</span>
              <div className='d-flex align-items-center mx-3'>
                <span className='me-2'>
                  <i className='ki-duotone ki-user-edit fs-2'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                </span>
                <span className='text-muted fs-7 mb-1'>Create Onboarding</span>
              </div>
              <span>|</span>
              <div className='d-flex align-items-center mx-3'>
                <span className='me-2'>
                  <KTIcon iconName='arrow-right' className='fs-2' />
                </span>
                <span className='text-muted fs-7 mb-1'>
                  Move to Different Section (Interviews and Offers)
                </span>
              </div>
            </div>
          </>
        ) : (
          <div className='d-flex flex-column'>
            <h3 className='fs-3 fw-semibold'>Manage Candidates</h3>

            <p className=''>
              Welcome to the Candidates Page! Here, you have the tools to seamlessly create and
              track candidates , ensuring a smooth transition into your organization.
            </p>
            <CandidatesKnowMoreCards />
            {/* <div
              className='d-flex justify-content-center align-items-center'
              data-toggle='tooltip'
              title='Add New Candidate'
            >
              <button
                className='btn btn-sm btn-light-primary'
                // id='side_activities_toggle'
                // onClick={handleOpenModal}
                onClick={() => setAddModal(true)}
              >
                <KTIcon iconName='plus' className='fs-3' />
                Add Candidate
              </button>
            </div> */}
            <div
              className='d-flex justify-content-center align-items-center'
              data-toggle='tooltip'
              title='Add Candidate'
            >
              <button
                className='btn btn-sm btn-light-primary me-3'
                id='side_activities_toggle'
                onClick={handleOpenModal}
              >
                <KTIcon iconName='plus' className='fs-3' />
                Add Candidate
              </button>
            </div>
          </div>
        )}

        {/* <div className='modal fade' tabIndex={-1} id='kt_modal_edit_candidate_data'>
          <CreateNewCandidates id={value} />
        </div> */}

        <CreateNewCandidatesModal
          show={showModal}
          onClose={handleCloseModal}
          fetchData={fetchData}
        />
      </>
    </>
  )
}

const CandidatesTable = () => {
  const {execute, loading: isLoading} = usePostApi()
  const {id: podId} = useParams()
  const podID = FindPodIdByCode()
  const [refresh, setRefresh] = useState(false)
  const [candidatesData, setcandidatesData] = useState([])

  const fetchData = async (show) => {
    if (show) {
      setRefresh(true)
    }
    try {
      const url = `/pod/candidate/list`
      const formData = {
        pod_id: podID,
      }

      const response = await execute(url, 'POST', formData)
      setcandidatesData(response.data)

      setRefresh(false)
      if (show) {
        return UseToast(
          response.status_message,
          {
            position: 'bottom-right',
            theme: 'colored',
            autoClose: true,
          },
          'success'
        )
      }
    } catch (error) {
      console.error('Error:', error)
      setRefresh(false)
      if (show) {
        return UseToast(
          error.response?.data?.status_message,
          {
            position: 'bottom-right',
            theme: 'colored',
            autoClose: true,
          },
          'error'
        )
      }
    }
  }
  useEffect(() => {
    fetchData()
  }, [])
  const [addModal, setAddModal] = useState(false)

  const [showModal, setShowModal] = useState(false)

  const [id, updateId] = useState('1')

  const [candidateId, updateCandidateId] = useState('')
  const [candidatefirstName, setcandidateFirstName] = useState([])

  const handleOpenModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Position',
        accessor: 'Request Type',
        Cell: ({row}) => {
          return (
            <div className='text-start ms-3'>
              <p className='text-dark fw-semibold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.candidate_first_name} {row.original.candidate_last_name}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'HiringManager',
        accessor: 'HiringManager',
        Cell: ({row}) => {
          let genderLabel
          switch (row.original.gender) {
            case 'M':
              genderLabel = 'Male'
              break
            case 'F':
              genderLabel = 'Female'
              break
            case 'T':
              genderLabel = 'Transgender'
              break
            case 'O':
              genderLabel = 'Others'
              break
            default:
              genderLabel = 'Unknown'
          }
          return (
            <div className='text-start'>
              <p className='text-dark fw-normal text-hover-primary d-block fs-6 default-cursor'>
                {genderLabel}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Department Name',
        accessor: 'Department Name',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark  text-hover-primary d-block fs-6 default-cursor'>
                {row.original.email_address}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'HiringManager Name',
        accessor: 'HiringManager Name',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark  text-hover-primary d-block fs-6 default-cursor'>
                {row.original.mobile}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Alt Number',
        accessor: 'Alt Number',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark text-hover-primary d-block fs-6 default-cursor'>
                {row.original.alt_phone ? row.original.alt_phone : '-'}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Actions',
        accessor: 'Actions',
        Cell: ({row}) => {
          const [id, updateId] = useState('')
          const [popover, setPopover] = useState(null)
          const ClosePopover = () => {
            setPopover(null)
          }
          const openPopover = (id) => (
            <Popover flip='true' placement='auto' className='min-w-400px'>
              <EditCandidatePopover props={id} />
            </Popover>
          )
          return (
            <div className='d-flex justify-content-center'>
              <div className='d-flex text-center'>
                {/* <OverlayTrigger
                trigger='click'
                placement='auto'
                overlay={openPopover(id)}
                show={popover === 'edit_candidate_data'}
              >
                <div
                  className='btn btn-icon btn-bg-light btn-active-light-primary btn-sm me-1'
                  onClick={(e) => {
                    e.stopPropagation()
                    setPopover('edit_candidate_data')
                    updateId({
                      refreshData: fetchData,
                      ClosePopover,
                      id: row.original.candidate_id,
                      podId: row.original.pod_id,
                      firstName: row.original.candidate_first_name,
                      lastName: row.original.candidate_last_name,
                      gender: row.original.gender,
                      email_address: row.original.email_address,
                      mobile: row.original.mobile,
                      alt_phone: row.original.alt_phone,
                    })
                  }}
                >
                  <KTIcon iconName='pencil' className='fs-3' />
                </div>
              </OverlayTrigger> */}
                <div
                  className='d-flex justify-content-center align-items-center'
                  data-toggle='tooltip'
                  title='Schedule Interview'
                >
                  <button
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm  me-1'
                    // onClick={handleOpenModal}
                    onClick={() => {
                      setAddModal(true)
                      updateCandidateId(row.original.candidate_id)
                      setcandidateFirstName(row.original.candidate_first_name)
                    }}
                  >
                    <i className='ki-duotone ki-calendar-add fs-1'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                      <span className='path4'></span>
                      <span className='path5'></span>
                      <span className='path6'></span>
                    </i>
                  </button>
                </div>
                <div
                  className='d-flex justify-content-center align-items-center'
                  data-toggle='tooltip'
                  title='Issue Offer'
                >
                  <button
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm  me-1'
                    onClick={() => {
                      handleOpenModal()
                      updateCandidateId(row.original.candidate_id)
                    }}
                  >
                    <i className='ki-duotone ki-add-files fs-1'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                    </i>
                  </button>
                </div>
                <div
                  className='d-flex justify-content-center align-items-center'
                  data-toggle='tooltip'
                  title='Create Onboarding'
                >
                  <button className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm  me-1'>
                    <i className='ki-duotone ki-user-edit fs-1'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                    </i>
                  </button>
                </div>
                <Link
                  to={`/${podId}/candidates/interviews/${row.original.candidate_id}`}
                  className='btn btn-icon btn-bg-light btn-active-light-primary btn-sm me-1'
                  title='Interviews and Offers Info'
                >
                  <KTIcon iconName='arrow-right' className='fs-1' />
                </Link>
              </div>
            </div>
          )
        },
        disableSortBy: true,
      },
    ],
    [fetchData]
  )

  return (
    <>
      <DataTable
        columns={columns}
        data={candidatesData ? candidatesData : []}
        loading={isLoading}
        fetchData={fetchData}
        value={id}
        refresh={refresh}
      />
      <ScheduleInterviews
        showModal={addModal}
        setShowModal={setAddModal}
        candidateId={candidateId}
        firstName={candidatefirstName}
      />

      <CreateOffersModal show={showModal} onClose={handleCloseModal} candidateId={candidateId} />
    </>
  )
}

export default CandidatesTable
