import React, {useState} from 'react'
import {useParams} from 'react-router-dom'
import {useLocation} from 'react-router-dom'

import {PageTitle} from '../../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import OffersTable from './OffersTable'
import Context from '../../side-drawer/context'
function OfferRouting() {
  const {id} = useParams()
  const intl = useIntl()
  const {pathname} = useLocation()
  const [SideDrawerId, updateId] = useState('0')
  const UpdatingId = (id) => {
    updateId(id)
  }
  const candidateAttachments = [
    {
      title: `(${id})`,
      path: `/${id}/offers`,
      isSeparator: false,
      isActive: false,
    },
  ]
  const switchMethod = () => {
    switch (pathname) {
      case `/pod-${id}/offers`:
        return (
          <>
            <PageTitle breadcrumbs={candidateAttachments}>Offers</PageTitle>
            <OffersTable />
          </>
        )
      // case '/travel/team-travel':
      //   return (
      //     <>
      //       <PageTitle breadcrumbs={TravelBreadCrumbs}>
      //         {intl.formatMessage({id: 'MENU.TRAVEL.TEAM_TRAVEL'})}
      //       </PageTitle>
      //       <TeamTrips />
      //     </>
      //   )

      default:
        return (
          <>
            <PageTitle breadcrumbs={candidateAttachments}>Offers</PageTitle>
            <OffersTable />
          </>
        )
    }
  }

  return (
    <>
      <Context.Provider value={{id: SideDrawerId, updateId: UpdatingId}}>
        {switchMethod()}
      </Context.Provider>
    </>
  )
}

export default OfferRouting
