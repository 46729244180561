import {useMemo, useState, useEffect} from 'react'
import {Pagination} from 'react-bootstrap'
import {useIntl, FormattedMessage} from 'react-intl'
import {KTIcon} from '../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import {useTable, usePagination} from 'react-table'
import {Link} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import usePageTitle from '../custom_hooks/usePageTitle'
import TemplatesKMmain from '../know_more_modals/TemplatesKMModal'
import {CheckPodAccess, FindFirstPodCode, FindPodIdByCode} from '../FindPodIdByPodCode'
import secureLocalStorage from 'react-secure-storage'
import usePostApi from '../custom_hooks/usePostApi'
import {UseToast} from '../useToast'
import TemplatesKM from '../know_more_modals/templates_KM/TemplatesKM'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'

const CreateTemplateSchema = Yup.object().shape({
  temp_name: Yup.string().min(2, 'Minimum 3 characters').required('Template Name is required'),
  temp_des: Yup.string()
    .min(3, 'Minimum 3 characters')
    .required('Template Description is required'),
})

const DataTable = ({columns, data, isLoading, fetchData, refresh}) => {
  const havePodAccess = CheckPodAccess()
  const firstPodCode = FindFirstPodCode()
  const {execute, postLoading} = usePostApi()
  const templateCanvasAppUrl = process.env.REACT_APP_TEMPLATE_CANVAS_APP_URL
  const [showModal, setShowModal] = useState(false)
  const userInfo = secureLocalStorage.getItem('user_info')
  let user_id = ''
  let auth_token = ''
  if (userInfo) {
    ;({user_id, auth_token} = userInfo)
  }
  usePageTitle('Template')
  const podId = FindPodIdByCode()
  const intl = useIntl()
  const {id} = useParams()
  const Onboardings = [
    {
      title: `(${id})`,
      path: `/${id}/templates`,
      isSeparator: false,
      isActive: false,
    },
  ]

  const [inputVal, setInputVal] = useState('')
  const [currentStatus, setCurrentStatus] = useState('Active')

  const filteredData = useMemo(() => {
    return data
      ? data.filter((each) => {
          const status = each.is_active === 1 ? 'Active' : 'Inactive'
          const nameMatches =
            each.template_name && each.template_name.toLowerCase().includes(inputVal.toLowerCase())
          const currentStatusMatches =
            status && status.toLowerCase() === currentStatus.toLowerCase()

          return nameMatches && currentStatusMatches
        })
      : []
  }, [inputVal, data, currentStatus])

  const tableInstance = useTable({columns, data: filteredData}, usePagination)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: {pageIndex, pageSize},
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    gotoPage,
    setPageSize,
  } = tableInstance

  const pageOptions = [10, 20, 30, 40, 50]

  const scrambleX = (str, numTimes = 5) => {
    let encoded = str
    for (let i = 0; i < numTimes; i++) {
      encoded = btoa(encoded)
    }
    return encoded
  }

  const [popover, setPopover] = useState(null)
  const closePopover = () => {
    setPopover(null)
  }

  const onSubmit = async (values) => {
    const formData = {
      pod_id: podId,
      template_name: values.temp_name,
      description: values.temp_des,
      structure: null,
      settings: {},
      is_active: 1,
      template_id: null,
    }

    try {
      const url = '/pod/template/create-update'

      const response = await execute(url, 'POST', formData, 'post')
      fetchData()
      const data = {
        pod_code: id,
        pod_id: podId,
        user_id: user_id,
        auth_token: auth_token,
        temp_id: response.data.template_id,
      }
      const query = new URLSearchParams(data).toString()
      // window.location.href = `http://localhost:3011/?${query}`
      window.location.href = `https://template-create-canvas.vercel.app/?${query}`
      if (response?.status === true) {
        setPopover(null)
      }

      UseToast(
        response?.status_message,
        {
          position: 'bottom-right',
          autoClose: false,
          theme: 'colored',
        },
        'success'
      )
    } catch (error) {
      console.error('Error:', error)

      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          autoClose: false,
          theme: 'colored',
        },
        'error'
      )
    }
  }

  const openPopover = (id) => (
    <Popover id={`add-template-popover-${id}`} flip='true' placement='auto' className='min-w-400px'>
      <Popover.Header as='div'>
        <div className='card-toolbar me-3 d-flex justify-content-between align-items-center'>
          <h3 className='fs-4 fw-semibold'>Create New Template</h3>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-active-light me-n5'
            onClick={closePopover}
          >
            <KTIcon iconName='cross' className='fs-1' />
          </button>
        </div>
      </Popover.Header>
      <Popover.Body>
        <Formik
          validationSchema={CreateTemplateSchema}
          initialValues={{
            temp_name: '',
            temp_des: '',
          }}
          onSubmit={onSubmit}
        >
          <Form className=''>
            <div className='w-100'>
              <div className=''>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Template Name</span>
                  <i
                    className='fas fa-info-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Provide a clear and concise name for the template. This will help users identify the template easily.'
                  ></i>
                </label>
                <div className='mb-4'>
                  <Field
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='Enter a descriptive name for your template'
                    name='temp_name'
                  />

                  <ErrorMessage component='div' className='text-danger' name='temp_name' />
                </div>
              </div>
              <div className=''>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Template Description</span>
                  <i
                    className='fas fa-info-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title="Describe the main features and objectives of the template. This information will help users understand the template's use case and relevance."
                  ></i>
                </label>
                <div className='mb-4'>
                  <Field
                    as='textarea'
                    className='form-control form-control-solid'
                    placeholder="Enter a brief description of the template's purpose and content"
                    name='temp_des'
                  />

                  <ErrorMessage component='div' className='text-danger' name='temp_des' />
                </div>
              </div>
            </div>

            <div className='d-flex justify-content-end'>
              <div>
                <button type='button' className='btn btn-sm btn-light me-2' onClick={closePopover}>
                  Close
                </button>
                <button type='submit' className='btn btn-sm btn-light-primary ms-2'>
                  {postLoading ? (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  ) : (
                    <>
                      <KTIcon iconName='plus' className='fs-2' />
                      Create
                    </>
                  )}
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </Popover.Body>
    </Popover>
  )

  return havePodAccess ? (
    <>
      <TemplatesKMmain showModal={showModal} setShowModal={setShowModal} />
      <PageTitle breadcrumbs={Onboardings}>Templates</PageTitle>
      {isLoading && refresh === false ? (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : data && data.length > 0 ? (
        <>
          <div className='d-flex flex-wrap flex-stack '>
            <div className='d-flex'>
              <h1 className='fs-3 fw-semibold'>Manage Templates</h1>
            </div>
            <div className='d-flex'>
              <div className='m-auto d-flex flex-column align-items-center'>
                <button
                  className='btn btn-sm btn-light fw-normal'
                  onClick={() => setShowModal(true)}
                >
                  <i className='ki-duotone ki-information-3 fs-3'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                  Know More
                </button>
              </div>
            </div>
          </div>
          <p className='fs-6 text-gray-600 mb-4'>
            Seamlessly create, update templates, ensuring no details are overlooked during the
            hiring process.
          </p>
          {refresh ? (
            <div className='card h-250px'>
              <div className='m-auto d-flex flex-column align-items-center'>
                <div className='spinner-border spinner-primary mr-15'></div>
              </div>
            </div>
          ) : (
            <>
              <div className='card py-2 px-10 mb-5'>
                <div className='card-header border-0 px-0 d-flex flex-wrap justify-content-between align-items-center'>
                  <h3 className='card-title align-items-start flex-column'>
                    <ul className='nav flex-wrap'>
                      <li className='nav-item'>
                        <p
                          className={`nav-link btn btn-sm btn-color-muted btn-active ${
                            currentStatus === 'Active' && 'btn-active-light-success'
                          } active px-4 me-1 fw-normal`}
                          onClick={() => setCurrentStatus('Active')}
                        >
                          Active
                        </p>
                      </li>
                      <li className='nav-item'>
                        <p
                          className={`nav-link btn btn-sm btn-color-muted btn-active ${
                            currentStatus === 'Inactive' && 'btn-active-light-danger'
                          } active px-4 me-1 fw-normal`}
                          onClick={() => setCurrentStatus('Inactive')}
                        >
                          Inactive
                        </p>
                      </li>
                    </ul>
                  </h3>
                  <div className='card-toolbar d-flex flex-wrap mt-0 mt-md-0'>
                    <div className='d-flex align-items-center position-relative me-4 mb-1 mb-md-0'>
                      <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                      <input
                        type='search'
                        id='kt_filter_search'
                        className='form-control form-control-white form-control-sm w-150px ps-9 fw-normal'
                        placeholder='Search'
                        value={inputVal}
                        onChange={(e) => setInputVal(e.target.value)}
                      />
                    </div>
                    <div className='d-flex mt-3 mt-md-0'>
                      {/* <Link
                        className='btn btn-sm btn-light-primary fw-normal mb-1 mb-md-0'
                        to={`/${id}/templates/create`}
                      >
                        <KTIcon iconName='plus' className='fs-3' />
                        Create
                      </Link> */}
                      {/* Create Button */}
                      <OverlayTrigger
                        trigger='click'
                        placement='auto'
                        overlay={openPopover(id)}
                        show={popover === 'add_new_template'}
                      >
                        <button
                          className='btn btn-sm btn-light-primary fw-normal mb-1 mb-md-0'
                          onClick={(e) => {
                            e.stopPropagation()
                            setPopover('add_new_template')
                          }}
                        >
                          <KTIcon iconName='plus' className='fs-3' /> Create
                        </button>
                      </OverlayTrigger>
                      {/* Refresh Button */}
                      <button
                        className='btn btn-sm btn-light-primary fw-normal mb-1 mb-md-0 ms-4'
                        data-bs-toggle='tooltip'
                        title='Click to load the latest data.'
                        onClick={() => fetchData(true)}
                      >
                        <i className='ki-solid ki-arrows-circle fs-2'></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div className='mb-0 mb-xl-8'>
                  <div className='overflow-auto'>
                    <table
                      className='table table-row-dashed table-row-gray-300 align-middle'
                      {...getTableProps()}
                    >
                      <thead>
                        <tr className='fw-semibold text-muted default-cursor bg-light rounded'>
                          <th className='min-w-200px ps-3'>Name & ID</th>
                          <th className='min-w-140px'>Description</th>
                          <th className='min-w-140px w-150px'>Status</th>
                          <th className={`min-w-200px text-center pe-3`}>Action</th>
                        </tr>
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page?.length > 0 ? (
                          page.map((row) => {
                            prepareRow(row)
                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                  <td className='text-center' {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                  </td>
                                ))}
                              </tr>
                            )
                          })
                        ) : (
                          <tr>
                            <td className='' colSpan={4}>
                              <div className='d-flex justify-content-center'>
                                <p className='text-dark default-cursor fs-6 mt-5'>
                                  {inputVal === '' ? (
                                    intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
                                  ) : (
                                    <FormattedMessage
                                      id='MSG.NO_DATA_MATCH'
                                      values={{
                                        inputVal: <span className='text-primary'>{inputVal}</span>,
                                      }}
                                    />
                                  )}
                                </p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {data?.length > 10 && (
                <Pagination>
                  <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                  <Pagination.Prev onClick={previousPage} disabled={!canPreviousPage} />

                  {[...Array(pageCount)].map((_, i) => (
                    <Pagination.Item key={i} active={i === pageIndex} onClick={() => gotoPage(i)}>
                      {i + 1}
                    </Pagination.Item>
                  ))}

                  <Pagination.Next onClick={nextPage} disabled={!canNextPage} />
                  <Pagination.Last
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  />

                  <div className='d-flex align-items-center mx-5'>
                    <span className='me-2'>Show</span>
                    <select
                      className='form-select form-select-solid fw-bold w-75px me-2'
                      value={pageSize}
                      onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                      {pageOptions.map((option) => (
                        <option key={option} value={option} className='select-bg'>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </Pagination>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <h3 className='fs-3 fw-semibold'>Creating Templates with Drag-and-Drop Ease</h3>

          <p className=''>
            Welcome to the Template Page! This section empowers you to seamlessly create, manage,
            and customize templates to suit your needs. Explore the intuitive drag-and-drop
            functionality to design templates effortlessly.
          </p>

          <div className='text-center mb-4'>
            <TemplatesKM />

            <OverlayTrigger
              trigger='click'
              placement='auto'
              overlay={openPopover(id)}
              show={popover === 'add_new_template'}
            >
              <button
                className='btn btn-sm btn-light-primary'
                onClick={(e) => {
                  e.stopPropagation()
                  setPopover('add_new_template')
                }}
              >
                <KTIcon iconName='plus' className='fs-3' />
                Create
              </button>
            </OverlayTrigger>
          </div>
        </>
      )}
    </>
  ) : (
    <div className='card min-h-250px d-flex flex-column justify-content-center align-items-center bg-image'>
      <h1 className='fw-semibold fs-2hx text-gray-900 mb-4'>Oops!</h1>
      <div className='fs-6 text-gray-500 mb-7'>You don't have access to this POD.</div>
      <div className='mb-0'>
        <Link to={`/${firstPodCode}/dashboard`} className='btn btn-sm btn-light-primary'>
          Return Home
        </Link>
      </div>
    </div>
  )
}

const TemplatesMain = () => {
  const {execute, loading: isLoading} = usePostApi()
  const podId = FindPodIdByCode()
  const templateCanvasAppUrl = process.env.REACT_APP_TEMPLATE_CANVAS_APP_URL
  const userInfo = secureLocalStorage.getItem('user_info')
  let user_id = ''
  let auth_token = ''
  if (userInfo) {
    ;({user_id, auth_token} = userInfo)
  }

  const [refresh, setRefresh] = useState(false)

  const [templates, setTemplates] = useState([])
  const getTemplates = async (show) => {
    if (show) {
      setRefresh(true)
    }
    try {
      const url = '/pod/template/list'
      const formData = {
        pod_id: podId,
      }

      const response = await execute(url, 'POST', formData)
      setTemplates(response.data)
      setRefresh(false)
      if (show) {
        return UseToast(
          response.status_message,
          {
            position: 'bottom-right',
            theme: 'colored',
            autoClose: true,
          },
          'success'
        )
      }
    } catch (error) {
      console.error('Error:', error)
      setRefresh(false)
      if (show) {
        return UseToast(
          error.response?.data?.status_message,
          {
            position: 'bottom-right',
            theme: 'colored',
            autoClose: true,
          },
          'error'
        )
      }
    }
  }

  useEffect(() => {
    getTemplates()
  }, [])

  const {id} = useParams()

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({row}) => {
          return (
            <div className='d-flex justify-content-start flex-column text-start ps-3'>
              <p className='text-dark fw-semibold text-hover-primary mb-1 fs-6 default-cursor'>
                {row.original.template_name}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'DateOfBirth',
        accessor: 'dateOfBirth',
        Cell: ({row}) => {
          return (
            <p className='text-dark text-hover-primary default-cursor d-block fs-6 text-start'>
              {row.original?.description || '-'}
            </p>
          )
        },
      },
      {
        Header: 'Age',
        accessor: 'age',
        Cell: ({row}) => {
          return (
            <p className='text-dark text-hover-primary default-cursor d-block fs-6 text-start w-150px'>
              {row.original.is_active === 1 ? 'Active' : 'Inactive'}
            </p>
          )
        },
      },
      {
        Header: 'RequireVisa',
        accessor: 'requireVisa',
        Cell: ({row}) => {
          return (
            <div className='text-center'>
              <button
                className='btn btn-light-primary btn-sm'
                onClick={() => {
                  const data = {
                    pod_code: id,
                    pod_id: podId,
                    user_id: user_id,
                    auth_token: auth_token,
                    temp_id: row.original.template_id,
                  }
                  const query = new URLSearchParams(data).toString()
                  window.location.href = `${'https://template-create-canvas.vercel.app/'}?${query}`
                  // window.location.href = `${'http://localhost:3011/'}?${query}`
                }}
              >
                <KTIcon iconName='pencil' className='fs-5' />
                Edit
              </button>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <DataTable
      columns={columns}
      data={templates ?? []}
      isLoading={isLoading}
      fetchData={getTemplates}
      refresh={refresh}
    />
  )
}

export default TemplatesMain
