import {Route, Routes} from 'react-router-dom'
import VacancyCard from './VacancyCard'
import VacancyInfo from './VacancyInfo'
import VacancyInterviewsTable from '../interviews/VacancyInterviewsTable'

function EditVacancyMain() {
  return (
    <div>
      <Routes>
        <Route element={<VacancyCard />}>
          <Route path={`/basic-info/:vacancy_id/*`} element={<VacancyInterviewsTable />} />
          <Route path={`/interviews/:vacancy_id/*`} element={<VacancyInterviewsTable />} />
          {/* <Route path={`/policy-wordings/:policy_id/*`} element={<PolicyWordings />} /> */}
          <Route index element={<VacancyInfo />} />
        </Route>
      </Routes>
    </div>
  )
}

export default EditVacancyMain
