/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import {CheckPodAccess, FindFirstPodCode} from '../../modules/FindPodIdByPodCode'
import {Link} from 'react-router-dom'
import {useAuth} from '../../modules/auth'

// import {
//   ListsWidget2,
//   ListsWidget3,
//   ListsWidget4,
//   ListsWidget6,
//   TablesWidget5,
//   TablesWidget10,
//   MixedWidget8,
//   CardsWidget7,
//   CardsWidget17,
//   CardsWidget20,
//   ListsWidget26,
//   EngageWidget10,
// } from '../../../_metronic/partials/widgets'

const DashboardPage: FC = () => {
  const havePodAccess = CheckPodAccess()
  const firstPodCode = FindFirstPodCode()

  return havePodAccess ? (
    <div></div>
  ) : (
    <div className='card min-h-250px d-flex flex-column justify-content-center align-items-center bg-image'>
      <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Oops!</h1>
      <div className='fw-semibold fs-6 text-gray-500 mb-7'>You don't have access to this POD.</div>
      <div className='mb-0'>
        <Link to={`/${firstPodCode}/dashboard`} className='btn btn-sm btn-light-primary'>
          Return Home
        </Link>
      </div>
    </div>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const {id} = useParams()
  const dashboard = [
    {
      title: `(${id})`,
      path: `/${id}/dashboard`,
      isSeparator: false,
      isActive: false,
    },
  ]
  return (
    <>
      <PageTitle breadcrumbs={dashboard}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
