import React from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

function Summary() {
  return (
    <div className='ps-5'>
      <div className='card pt-0 fs-6 p-5'>
        {/* Section */}
        <div className='mb-5'>
          {/* Details */}
          <div className='d-flex align-items-center pt-5'>
            {/* Avatar */}
            <div className='symbol symbol-60px symbol-circle me-3'>
              <img alt='Pic' src={toAbsoluteUrl('/media/avatars/300-5.jpg')} />
            </div>
            {/* Info */}
            <div className='d-flex flex-column'>
              {/* Name */}
              <a href='#' className='fs-4 fw-semibold text-gray-900 text-hover-primary me-2'>
                Sean Bean
              </a>
              {/* Email */}
              <a href='#' className='fw-semibold text-gray-600 text-hover-primary'>
                sean@dellito.com
              </a>
            </div>
          </div>
        </div>
        {/* Seperator */}
        <div className='separator separator-dashed mb-5'></div>
        {/* Section */}
        <div className='mb-5'>
          {/* Title */}
          <h5 className='mb-4 fw-semibold'>Product details</h5>
          {/* Details */}
          <div className='mb-0'>
            {/* Plan */}
            <span className='badge badge-light-info me-2'>Basic Bundle</span>
            {/* Price */}
            <span className='fw-semibold text-gray-600'>$149.99 / Year</span>
          </div>
        </div>
        {/* Seperator */}
        <div className='separator separator-dashed mb-5'></div>
        {/* Section */}
        <div className='mb-5'>
          {/* Title */}
          <h5 className='fw-semibold'>Payment Details</h5>
          {/* Details */}
          <div className='mb-0'>
            {/* Card info */}
            <div className='fw-semibold text-gray-600 d-flex align-items-center'>
              Mastercard
              <img
                className='w-35px ms-2'
                alt='Mastercard'
                src={toAbsoluteUrl('/media/svg/card-logos/mastercard.svg')}
              />
            </div>
            {/* Card expiry */}
            <div className='fw-semibold text-gray-600'>Expires Dec 2024</div>
          </div>
        </div>
        {/* Seperator */}
        <div className='separator separator-dashed mb-5'></div>
        {/* Section */}
        <div className=''>
          {/* Title */}
          <h5 className='fw-semibold'>Subscription Details</h5>
          {/* Details */}
          <table className='table fs-6 fw-semibold gs-0 gy-2 gx-2'>
            <tbody>
              <tr>
                <td className='text-gray-500'>Subscription ID:</td>
                <td className='text-gray-800'>sub_4567_8765</td>
              </tr>
              <tr>
                <td className='text-gray-500'>Started:</td>
                <td className='text-gray-800'>15 Apr 2021</td>
              </tr>
              <tr>
                <td className='text-gray-500'>Status:</td>
                <td>
                  <span className='badge badge-light-success'>Active</span>
                </td>
              </tr>
              <tr>
                <td className='text-gray-500'>Next Invoice:</td>
                <td className='text-gray-800'>15 Apr 2022</td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* Actions */}
        <div className='mb-0'>
          <button className='btn btn-primary'>Edit Subscription</button>
        </div>
      </div>
    </div>
  )
}

export default Summary
