import {useMemo, useState, useEffect} from 'react'
import {Pagination} from 'react-bootstrap'
import {useIntl, FormattedMessage} from 'react-intl'
import {KTIcon} from '../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import {useTable, usePagination} from 'react-table'
import {Link} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import usePageTitle from '../custom_hooks/usePageTitle'
import OnboardingsKMModal from '../know_more_modals/OnboardingsKMModal'
import {CheckPodAccess, FindFirstPodCode} from '../FindPodIdByPodCode'
import AddNewOnboardingRequest from './AddNewRequest'
import {FindPodIdByCode} from '../FindPodIdByPodCode'
import usePostApi from '../custom_hooks/usePostApi'
import OnboardingsKM from '../know_more_modals/onboardings_KM/OnboardingsKM'
import {UseToast} from '../useToast'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import UpdateStatus from './emp_details/ChangeStatus'

const DataTable = ({columns, data, isLoading, fetchData, refresh}) => {
  const havePodAccess = CheckPodAccess()
  const firstPodCode = FindFirstPodCode()
  const [showModal, setShowModal] = useState(false)
  const [showAddReqModal, setShowAddReqModal] = useState(false)

  usePageTitle('Onboarding')
  const intl = useIntl()
  const {id} = useParams()
  const Onboardings = [
    {
      title: `(${id})`,
      path: `/${id}/onboarding`,
      isSeparator: false,
      isActive: false,
    },
  ]

  const [inputVal, setInputVal] = useState('')

  const [searchByStatus, setSearchByStatus] = useState(null)

  const filteredData = useMemo(() => {
    return data
      ? data.filter((each) => {
          const fullName = each.first_name + ' ' + each.last_name
          const nameMatches = fullName && fullName.toLowerCase().includes(inputVal.toLowerCase())
          const emailMatches =
            each.email_address && each.email_address.toLowerCase().includes(inputVal.toLowerCase())
          const jobCodeMatches =
            each.onbr_code && each.onbr_code.toLowerCase().includes(inputVal.toLowerCase())
          const jobPinMatches =
            each.onbr_pin && each.onbr_pin.toLowerCase().includes(inputVal.toLowerCase())
          const locationMatches =
            each.location_name && each.location_name.toLowerCase().includes(inputVal.toLowerCase())
          const statusMatches = searchByStatus ? each.status === searchByStatus : true
          return (
            (nameMatches || emailMatches || jobCodeMatches || jobPinMatches || locationMatches) &&
            statusMatches
          )
        })
      : []
  }, [inputVal, data, searchByStatus])

  const tableInstance = useTable({columns, data: filteredData}, usePagination)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: {pageIndex, pageSize},
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    gotoPage,
    setPageSize,
  } = tableInstance

  const pageOptions = [10, 20, 30, 40, 50]

  const onboardingStatuses = [
    'Draft/Open',
    'Sent/Pending',
    'In Progress',
    'Submitted',
    'Complete',
    'Rejected',
    'Closed',
    'Canceled',
    'Re-submission Requested',
  ]

  const statuses = [
    {value: 'N', label: 'New', icon: 'star'},
    {value: 'O', label: 'Open', icon: 'book-open'},
    {value: 'I', label: 'In Progress', icon: 'loading'},
    {value: 'S', label: 'Submitted', icon: 'check-square'},
    {value: 'P', label: 'Processing', icon: 'setting-2'},
    {value: 'C', label: 'Completed', icon: 'verify'},
    {value: 'X', label: 'Cancelled', icon: 'cross-square'},
    {value: 'E', label: 'Expired', icon: 'calendar-remove'},
    {value: 'R', label: 'Revoked', icon: 'arrow-circle-left'},
    // {value: 'A', label: 'Active', icon: 'check-circle'},
  ]

  const getStatus = (status) => {
    const foundStatus = statuses.find((item) => item.value === status)
    return foundStatus ? foundStatus.label : 'InProgress'
  }

  return havePodAccess ? (
    <>
      <PageTitle breadcrumbs={Onboardings}>Onboarding</PageTitle>
      <OnboardingsKMModal showModal={showModal} setShowModal={setShowModal} />
      <AddNewOnboardingRequest
        showModal={showAddReqModal}
        setShowModal={setShowAddReqModal}
        fetchData={fetchData}
      />

      {isLoading && refresh === false ? (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : data && data.length ? (
        <>
          <div className='d-flex flex-wrap flex-stack'>
            <div className='d-flex'>
              <h1 className='fs-3 fw-semibold'>Manage Onboarding</h1>
            </div>
            <div className='d-flex'>
              <div className='m-auto d-flex flex-column align-items-center'>
                <button className='btn btn-sm btn-light' onClick={() => setShowModal(true)}>
                  <i className='ki-duotone ki-information-3 fs-3'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                  Know More
                </button>
              </div>
            </div>
          </div>
          <p className='fs-6 text-gray-600 mb-4'>
            Seamlessly create, update, and track onboarding requests, ensuring no details are
            overlooked during the hiring process.
          </p>
          <ul className='w-100 nav nav-pills nav-pills-custom list-unstyled d-flex mb-3'>
            {' '}
            {(statuses || []).map((each) => (
              <li
                className='nav-ite mb-3 me-3'
                key={each.value}
                data-bs-toggle='tooltip'
                title={`${each.label}`}
              >
                <button
                  className={`card nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-60px overflow-auto p-2 ${
                    searchByStatus === each.value ? 'active-border bg-light-primary' : ''
                  }`}
                  onClick={() => {
                    if (searchByStatus === each.value) {
                      setSearchByStatus(null)
                    } else {
                      setSearchByStatus(each.value)
                    }
                  }}
                >
                  <div className='m-auto'>
                    <div className=''>
                      <KTIcon iconName={each?.icon || 'information-2'} className={`fs-1`} />
                    </div>
                    <span className='fw-semibold text-dark fs-8'>{each.label}</span>
                  </div>
                </button>
              </li>
            ))}
          </ul>

          {refresh ? (
            <div className='card h-250px'>
              <div className='m-auto d-flex flex-column align-items-center'>
                <div className='spinner-border spinner-primary mr-15'></div>
              </div>
            </div>
          ) : (
            <>
              <div className='card py-2 px-10 mb-5'>
                <div className='card-header border-0 px-0'>
                  <div className='card-title flex-column align-items-start'>
                    {/* <h3 className='fw-semibold fs-3'>Onboardings</h3>
                    <p className='fs-6 text-gray-600 mb-4 fw-normal'>
                      Filter onboardings by status.
                    </p> */}
                  </div>
                  {/* <h3 className='card-title align-items-start flex-column'>
                   
                  </h3> */}
                  <div className='card-toolbar'>
                    <div className='d-flex align-items-center position-relative me-4 mb-1 mb-md-0'>
                      <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                      <input
                        type='search'
                        id='kt_filter_search'
                        className='form-control form-control-white form-control-sm w-150px ps-9 fw-normal'
                        placeholder='Search'
                        value={inputVal}
                        onChange={(e) => setInputVal(e.target.value)}
                      />
                    </div>
                    <button
                      className='btn btn-sm btn-light-primary fw-normal mb-1 mb-md-0'
                      onClick={() => setShowAddReqModal(true)}
                    >
                      <KTIcon iconName='plus' className='fs-3' />
                      Create New Request
                    </button>

                    <button
                      className='btn btn-sm btn-light-primary fw-normal mb-1 mb-md-0 ms-4'
                      onClick={() => fetchData(true)}
                      data-bs-toggle='tooltip'
                      title='Click to load the latest data.'
                    >
                      <i className='ki-solid ki-arrows-circle fs-2'></i>
                    </button>
                  </div>
                </div>

                <div className='mb-0 mb-xl-8'>
                  <div className='overflow-auto'>
                    <table
                      className='table table-row-dashed table-row-gray-300 align-middle'
                      {...getTableProps()}
                    >
                      <thead>
                        <tr className='fw-semibold text-muted default-cursor bg-light rounded'>
                          <th className='min-w-140px ps-3'>Name</th>
                          <th className='min-w-140px'>Job-Join Code & Pin</th>
                          <th className='min-w-140px'>Location</th>
                          <th className='min-w-140px w-150px'>Status</th>
                          <th className={`min-w-140px text-center pe-3`}>Actions</th>
                        </tr>
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.length > 0 ? (
                          page.map((row) => {
                            prepareRow(row)
                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                  <td className='text-center' {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                  </td>
                                ))}
                              </tr>
                            )
                          })
                        ) : (
                          <tr>
                            <td className='' colSpan={5}>
                              <div className='d-flex justify-content-center'>
                                <p className='text-dark default-cursor fs-6 mt-5'>
                                  {inputVal === '' ? (
                                    intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
                                  ) : (
                                    <FormattedMessage
                                      id='MSG.NO_DATA_MATCH'
                                      values={{
                                        inputVal: <span className='text-primary'>{inputVal}</span>,
                                      }}
                                    />
                                  )}
                                </p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {data.length > 10 && (
                <Pagination>
                  <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                  <Pagination.Prev onClick={previousPage} disabled={!canPreviousPage} />

                  {[...Array(pageCount)].map((_, i) => (
                    <Pagination.Item key={i} active={i === pageIndex} onClick={() => gotoPage(i)}>
                      {i + 1}
                    </Pagination.Item>
                  ))}

                  <Pagination.Next onClick={nextPage} disabled={!canNextPage} />
                  <Pagination.Last
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  />

                  <div className='d-flex align-items-center mx-5'>
                    <span className='me-2'>Show</span>
                    <select
                      className='form-select form-select-solid fw-bold w-75px me-2'
                      value={pageSize}
                      onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                      {pageOptions.map((option) => (
                        <option key={option} value={option} className='select-bg'>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </Pagination>
              )}
            </>
          )}
        </>
      ) : (
        <div className='d-flex flex-column'>
          <h3 className='fs-3 fw-semibold'>Manage Onboarding Requests</h3>

          <p className=''>
            Welcome to the Onboardings Page! Here, you have the tools to seamlessly create and track
            onboarding requests for new candidates, ensuring a smooth transition into your
            organization.
          </p>
          <OnboardingsKM />
          <button
            className='btn btn-sm btn-light-primary fw-normal mb-1 mb-md-0 align-self-center'
            onClick={() => setShowAddReqModal(true)}
          >
            <KTIcon iconName='plus' className='fs-3' />
            Add Onboarding
          </button>
        </div>
      )}
    </>
  ) : (
    <div className='card min-h-250px d-flex flex-column justify-content-center align-items-center bg-image'>
      <h1 className='fw-semibold fs-2hx text-gray-900 mb-4'>Oops!</h1>
      <div className='fs-6 text-gray-500 mb-7'>You don't have access to this POD.</div>
      <div className='mb-0'>
        <Link to={`/${firstPodCode}/dashboard`} className='btn btn-sm btn-light-primary'>
          Return Home
        </Link>
      </div>
    </div>
  )
}

const OnboardingMain = () => {
  const {execute, loading, postLoading} = usePostApi()
  const podId = FindPodIdByCode()

  const {id} = useParams()
  const [refresh, setRefresh] = useState(false)

  const [onboardingsData, setOnboardingsData] = useState([])
  const getOnbardingData = async (show) => {
    if (show) {
      setRefresh(true)
    }
    try {
      const url = '/pod/onboarding/request/list'
      const formData = {
        pod_id: podId,
      }

      const response = await execute(url, 'POST', formData)
      setOnboardingsData(response.data)
      setRefresh(false)
      if (show) {
        return UseToast(
          response.status_message,
          {
            position: 'bottom-right',
            theme: 'colored',
            autoClose: true,
          },
          'success'
        )
      }
    } catch (error) {
      console.error('Error:', error)
      setRefresh(false)
      if (show) {
        return UseToast(
          error.response?.data?.status_message,
          {
            position: 'bottom-right',
            theme: 'colored',
            autoClose: true,
          },
          'error'
        )
      }
    }
  }

  useEffect(() => {
    getOnbardingData()
  }, [])

  const getStatus = (status) => {
    switch (status) {
      case 'I':
        return 'In Progress'
      case 'O':
        return 'Open'
      case 'N':
        return 'New'
      case 'S':
        return 'Submitted'
      case 'P':
        return 'Processing'
      case 'X':
        return 'Cancelled'
      case 'C':
        return 'Completed'
      case 'E':
        return 'Expired'
      case 'R':
        return 'Revoked'
      default:
        return 'In Progress'
    }
  }

  const sendOnboardingRequest = async (code, pin) => {
    try {
      const url = '/pod/onboarding/request/send'
      const formData = {
        onbr_code: code,
        onbr_pin: pin,
      }

      const response = await execute(url, 'POST', formData)
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: true,
        },
        'success'
      )
    } catch (error) {
      console.error('Error:', error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: true,
        },
        'error'
      )
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({row}) => {
          return (
            <div className='d-flex justify-content-start flex-column default-cursor ps-3'>
              <p className='text-dark fw-semibold text-hover-primary fs-6 text-start mb-0'>
                {row.original.first_name} {row.original.last_name}
              </p>
              <span className='text-muted text-muted d-block fs-7 text-start mt-0'>
                {row.original.email_address}
              </span>
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'DateOfBirth',
        accessor: 'dateOfBirth',
        Cell: ({row}) => {
          return (
            <div className='d-flex justify-content-start flex-column default-cursor'>
              <p className='text-dark text-hover-primary fs-6 text-start mb-0'>
                {row.original.onbr_code}
              </p>
              <span className='text-muted d-block fs-7 text-start mt-0'>
                {row.original.onbr_pin}
              </span>
            </div>
          )
        },
      },
      {
        Header: 'Location',
        accessor: 'location',
        Cell: ({row}) => {
          return (
            <p className='text-dark text-hover-primary default-cursor d-block fs-6 text-start w-150px'>
              {row.original.location_name}
            </p>
          )
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({row}) => {
          return (
            <p className='text-dark text-hover-primary default-cursor d-block fs-6 text-start w-150px'>
              {getStatus(row.original.status) || 'N.A.'}
            </p>
          )
        },
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({row}) => {
          const [data, updateData] = useState({
            refreshData: () => {},
            ClosePopover: () => {},
            memberId: '',
            role_id: '',
          })
          const [popover, setPopover] = useState('')
          const ClosePopover = () => {
            setPopover('')
          }
          const openPopover = (taskId) => {
            // const {id: teamMemberId, refreshData, ClosePopover} = id

            return (
              <Popover
                id={`delete-popover-${taskId}`}
                flip
                placement='auto'
                className='min-w-300px'
              >
                <Popover.Header as='div'>
                  <div className='card-toolbar me-3 d-flex justify-content-between align-items-center'>
                    <h3 className='fs-4 fw-semibold'>Update Status</h3>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-active-light me-n5'
                      onClick={ClosePopover}
                    >
                      <KTIcon iconName='cross' className='fs-1' />
                    </button>
                  </div>
                </Popover.Header>
                <Popover.Body>
                  <UpdateStatus data={data} />
                </Popover.Body>
              </Popover>
            )
          }

          return (
            <div className='text-center'>
              <OverlayTrigger
                trigger='click'
                placement='auto'
                overlay={openPopover(row.original.member_id)}
                show={popover === 'edit_status'}
              >
                <button
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-2'
                  onClick={(e) => {
                    e.preventDefault()
                    updateData({
                      refreshData: getOnbardingData,
                      ClosePopover,
                      memberId: row.original.onbr_id,
                      status: row.original.status,
                    })
                    setPopover('edit_status')
                  }}
                >
                  <KTIcon iconName='pencil' className='fs-3' />
                </button>
              </OverlayTrigger>
              <Link
                to={`/${id}/onboarding/onboarding-info/${row.original.onbr_code}/${row.original.onbr_pin}`}
                className='btn btn-light-primary btn-sm ms-2'
              >
                View
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <DataTable
      columns={columns}
      data={onboardingsData ?? []}
      isLoading={loading}
      fetchData={getOnbardingData}
      refresh={refresh}
    />
  )
}

export default OnboardingMain
