import React from 'react'

const RecentEvents = () => {
  const events = [
    {
      user: 'Sean Bean',
      action: 'has made payment to',
      target: '4720-7671',
      date: '05 May 2024, 9:23 pm',
    },
    {
      user: 'Max Smith',
      action: 'has made payment to',
      target: '8883-9332',
      date: '24 Jun 2024, 11:30 am',
    },
    {
      invoice: '8127-9115',
      action: 'status has changed from',
      fromStatus: 'Pending',
      toStatus: 'In Progress',
      date: '25 Oct 2024, 10:10 pm',
    },
    {
      invoice: '2337-2951',
      action: 'status has changed from',
      fromStatus: 'In Transit',
      toStatus: 'Approved',
      date: '22 Sep 2024, 8:43 pm',
    },
    {
      invoice: '5037-3543',
      action: 'has been',
      status: 'Declined',
      date: '22 Sep 2024, 6:05 pm',
    },
  ]

  return (
    <div className='card card-flush pt-3 mb-5 mb-xl-10'>
      {/* Card header */}
      <div className='card-header'>
        {/* Card title */}
        <div className='card-title'>
          <h2 className='fw-semibold'>Recent Events</h2>
        </div>
        {/* Card toolbar */}
        <div className='card-toolbar'>
          <a href='#' className='btn btn-light-primary'>
            View All Events
          </a>
        </div>
      </div>
      {/* Card body */}
      <div className='card-body pt-0'>
        {/* Table wrapper */}
        <div className='table-responsive'>
          {/* Table */}
          <table
            className='table align-middle table-row-dashed fs-6 text-gray-600 fw-semibold gy-5'
            id='kt_table_customers_events'
          >
            <tbody>
              {events.map((event, index) => (
                <tr key={index}>
                  <td className='min-w-400px'>
                    {event.user ? (
                      <>
                        <a href='#' className='fw-semibold text-gray-800 text-hover-primary me-1'>
                          {event.user}
                        </a>{' '}
                        {event.action}{' '}
                        <a href='#' className='fw-semibold text-gray-800 text-hover-primary'>
                          {event.target}
                        </a>
                      </>
                    ) : (
                      <>
                        Invoice{' '}
                        <a href='#' className='fw-semibold text-gray-800 text-hover-primary me-1'>
                          {event.invoice}
                        </a>{' '}
                        {event.action}{' '}
                        <span
                          className={`badge badge-light-${
                            event.fromStatus ? 'warning' : 'danger'
                          } me-1`}
                        >
                          {event.fromStatus || event.status}
                        </span>{' '}
                        {event.toStatus && 'to'}{' '}
                        {event.toStatus && (
                          <span className='badge badge-light-info'>{event.toStatus}</span>
                        )}
                      </>
                    )}
                  </td>
                  <td className='pe-0 text-gray-600 text-end min-w-200px'>{event.date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default RecentEvents
