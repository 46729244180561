import usePostApi from '../custom_hooks/usePostApi'
import Swal from 'sweetalert2'
import CustomWizardNew from './CreateNewPodNew'

function CreateNewPodCustomMain() {
  const {execute} = usePostApi()
  const addNewPod = async (formData: any) => {
    const formDataObject: {[key: string]: string | null} = {}
    for (const [key, value] of formData.entries()) {
      formDataObject[key] = value instanceof File ? value : value.toString()
    }

    try {
      const url = `/pod/create`

      const response = await execute(url, 'POST', formData)
      Swal.fire({
        title: `Pod code: ${response?.data?.pod_code}`,
        text: response?.status_message,
        icon: 'success',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-primary btn-sm',
        },
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed || result.dismiss === (Swal.DismissReason as any).overlay) {
          window.location.reload()
        }
      })
    } catch (error: any) {
      console.error('Error:', error)

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${error.response.data.status_message}`,
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-primary btn-sm',
        },
      })
    }
  }
  return (
    <div className='card p-10'>
      {/* <div className='d-flex flex-wrap flex-stack mb-6'>
        <div className='fw-bolder my-2'>
          <h3 className='fw-bolder m-2'>Create New Pod</h3>
        </div>
      </div> */}
      <CustomWizardNew addNewPod={addNewPod} />
    </div>
  )
}

export default CreateNewPodCustomMain
