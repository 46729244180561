import {Modal} from 'react-bootstrap'

import Swal from 'sweetalert2'
import usePostApi from '../../../custom_hooks/usePostApi'
import CreateNewVacancy from './CreateNewVacancy'
import {baseUrl} from '../../../core'
import {useIntl} from 'react-intl'
import {UseToast} from '../../../useToast'
import {useThemeMode} from '../../../../../_metronic/partials'
function CreateNewVacancyModal(props: any) {
  const handleClose = () => {
    props.onClose()
  }
  const {mode} = useThemeMode()
  const {execute} = usePostApi()
  const intl = useIntl()
  const addNewPod = async (formData: any) => {
    try {
      const url = `/pod/vacancy/create`

      const response = await execute(url, 'POST', formData)
      props.fetchData()
      props.onClose()
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
    } catch (error: any) {
      console.error('Error:', error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }
  return (
    <Modal
      show={props.show}
      onHide={handleClose}
      centered
      dialogClassName='custom-modal modal-dialog-centered'
      contentClassName='custom-modal-content'
      backdrop='static' // Prevents closing when clicking outside
      keyboard={false} // Prevents closing with the Escape key
    >
      <Modal.Body className='overflow-auto'>
        <CreateNewVacancy addNewPod={addNewPod} closeModal={props.onClose} />
      </Modal.Body>
    </Modal>
  )
}

export default CreateNewVacancyModal
