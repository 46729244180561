import {Modal} from 'react-bootstrap'

import {useIntl} from 'react-intl'
import CreateNewCandidates from './CreateNewCandidates'

function CreateNewCandidatesModal(props) {
  const handleClose = () => {
    props.onClose()
  }

  const intl = useIntl()

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
      centered
      dialogClassName='custom-modal modal-dialog-centered'
      contentClassName='custom-modal-content'
      backdrop='static' // Prevents closing when clicking outside
      keyboard={false} // Prevents closing with the Escape key
    >
      <Modal.Body className='overflow-auto w-100'>
        <CreateNewCandidates closeModal={props.onClose} fetchData={props.fetchData} />
      </Modal.Body>
    </Modal>
  )
}

export default CreateNewCandidatesModal
