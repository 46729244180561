// eslint-disable-next-line react-hooks/exhaustive-deps
import React, {useContext} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import NavigationBar from './NavigationBar'
import AppContext from '../../AppContext'
import {useParams} from 'react-router-dom'
const Organization: React.FC = () => {
  const {id} = useParams()
  const {podsData}: any = useContext(AppContext)
  const information: any = podsData?.find((pod: any) => String(pod.pod_code) === id)
  let color = information?.pod_pref_color ? information?.pod_pref_color.substring(1) : ''
  const name = encodeURIComponent(information?.pod_brand_name)
  return (
    <div className='card mb-4 mb-xl-6'>
      <div className='card-body pt-8 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap'>
          <div className='me-4'>
            <div className='symbol symbol-100px symbol-lg-150px symbol-fixed position-relative'>
              <img
                style={{height: '120px', width: '120px'}}
                src={
                  information?.pod_logo
                    ? information?.pod_logo
                    : `${process.env.REACT_APP_API_BASE_URL}/get/profile?name=${name}&color=${color}&size=128}`
                }
                alt='profile'
              />
            </div>
          </div>
          <div className='d-flex flex-column justify-content-between'>
            <div className='d-flex justify-content-between align-items-start flex-wrap'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <p className='text-gray-800 text-hover-primary fs-3 fw-semibold me-1 default-cursor mb-0'>
                    {information?.pod_business_name}
                  </p>
                </div>
                <div className='d-flex flex-wrap fw-normal fs-6 pe-2'>
                  <p className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2 default-cursor'>
                    <KTIcon iconName='bank' className='fs-6 me-1' />
                    {information?.pod_industry_name
                      ? information?.pod_industry_name
                      : 'No industry found.'}
                  </p>
                  <p className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2 default-cursor'>
                    <KTIcon iconName='disconnect' className='fs-6 me-1' />
                    {information?.url ? information?.url : 'No url found.'}
                  </p>
                  <p className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2 default-cursor'>
                    <KTIcon iconName='flag' className='fs-6 me-1' />
                    {information?.pod_country_name}
                  </p>
                </div>
                <p className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2 default-cursor'>
                  <KTIcon iconName='external-drive' className='fs-6 me-1' />
                  {information?.pod_code}
                </p>
              </div>
            </div>
          </div>
        </div>
        <NavigationBar />
      </div>
    </div>
  )
}

export default Organization
