import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import clsx from 'clsx'
import usePostApi from '../custom_hooks/usePostApi'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {useThemeMode} from '../../../_metronic/partials'
import {useLang} from '../../../_metronic/i18n/Metronici18n'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Swal from 'sweetalert2'
import {FindPodIdByCode} from '../FindPodIdByPodCode'
import {format} from 'date-fns'
import {UseToast} from '../useToast'
import './index.css'

interface Props {
  showModal: boolean
  setShowModal(val: boolean): void
  fetchAllTeamMembers(): void
}

interface Data {
  membership_expiry: string
  first_name: string
  last_name: string
  email: string
  role_id: string
}

const AddTeamMemberModal = (props: Props) => {
  const podId = FindPodIdByCode()
  const {execute, postLoading} = usePostApi()
  const [data, setData] = useState<Data>({
    membership_expiry: '',
    first_name: '',
    last_name: '',
    email: '',
    role_id: '',
  })
  const [currentStep, setCurrentStep] = useState(0)
  const set2ndIndex = () => {
    setCurrentStep(1)
  }
  const prevStep = (): void => {
    if (currentStep !== 0) {
      setCurrentStep(currentStep - 1)
    }
  }

  const addTeamMember = async (formData: any) => {
    const updatedData = {
      ...formData,
      pod_id: podId,
      membership_expiry: formData.membership_expiry,
    }
    try {
      const url = '/pod/members/create'

      const response = await execute(url, 'POST', updatedData, 'post')
      if (response?.status === true) {
        props.fetchAllTeamMembers()
        props.setShowModal(false)
        setData({
          membership_expiry: '',
          first_name: '',
          last_name: '',
          email: '',
          role_id: '',
        })
        setCurrentStep(0)
      }

      UseToast(
        response?.status_message,
        {
          position: 'bottom-right',
          autoClose: false,
          theme: 'colored',
        },
        'success'
      )
    } catch (error: any) {
      console.error('Error:', error)

      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          autoClose: false,
          theme: 'colored',
        },
        'error'
      )
    }
  }

  const nextStep = () => {
    if (currentStep === 1) {
      const step2Btn = document.getElementById('step2_submit_btn')
      if (step2Btn) {
        step2Btn.click()
      }
    }
    if (currentStep === 0) {
      const step1Btn = document.getElementById('step1_submit_btn')
      if (step1Btn) {
        step1Btn.click()
      }
    }
  }

  const steps = [
    <Step1 data={data} setData={setData} set2ndIndex={set2ndIndex} />,
    <Step2 data={data} setData={setData} addTeamMember={addTeamMember} />,
  ]

  return (
    <Modal
      show={props.showModal}
      onHide={() => props.setShowModal(false)}
      centered
      dialogClassName='custom-modal modal-dialog-centered'
      contentClassName='custom-modal-content'
    >
      <Modal.Body className='overflow-auto w-100'>
        <div className='card py-0 overflow-auto'>
          <div className='w-100 m-auto'>
            <div className='d-flex justify-content-between mb-10'>
              <h3 className='fs-3 fw-semibold'>Add Team Member</h3>
              <button
                type='button'
                className='d-block btn btn-sm btn-icon btn-light'
                onClick={() => props.setShowModal(false)}
              >
                <KTIcon iconName='cross' className='fs-1' />
              </button>
            </div>
            <div className='w-100 row m-0'>
              <div className='col-lg-5 p-0 mb-5 mb-lg-0'>
                <div className=''>
                  <div className='d-flex'>
                    <div
                      style={{borderRadius: '10px'}}
                      className={clsx(
                        'w-40px h-40px btn d-flex justify-content-center align-items-center',
                        {
                          'btn-primary': currentStep === 0,
                          'btn-light-primary': currentStep !== 0,
                        }
                      )}
                    >
                      {currentStep === 0 ? 1 : <i className='stepper-check fas fa-check'></i>}
                    </div>
                    <div className='ms-5'>
                      <h6
                        className={clsx('mb-0 fw-semibold', {
                          'text-muted': currentStep >= 0,
                        })}
                      >
                        Step 1
                      </h6>
                      <p
                        className={clsx('fs-6 mb-0', {
                          'text-muted': currentStep >= 0,
                        })}
                      >
                        Team Member
                      </p>
                    </div>
                  </div>
                  <div className='ms-6 h-30px border-left border-dashed border-muted border-top-0 border-bottom-0 border-right-0 border-left-1'></div>
                  <div className='d-flex'>
                    <div
                      style={{borderRadius: '10px'}}
                      className={clsx(
                        'w-40px h-40px btn d-flex justify-content-center align-items-center',
                        {
                          'btn-primary': currentStep === 1,
                          'btn-light-primary': currentStep !== 1,
                        }
                      )}
                    >
                      {currentStep > 1 ? <i className='stepper-check fas fa-check'></i> : 2}
                    </div>
                    <div className='ms-5'>
                      <h6
                        className={clsx('mb-0 fw-semibold', {
                          'text-muted': currentStep >= 1,
                        })}
                      >
                        Step 2
                      </h6>
                      <p
                        className={clsx('fs-6', {
                          'text-muted': currentStep >= 1,
                        })}
                      >
                        Roles
                      </p>
                    </div>
                  </div>
                  {/* Add more steps here */}
                </div>
              </div>
              <div className='col-lg-7 p-0 m-0'>{steps[currentStep]}</div>
            </div>

            <div className='w-100 d-flex flex-stack mt-5'>
              <div className='mr-2'>
                {currentStep > 0 && (
                  <button onClick={prevStep} type='button' className='btn btn-sm btn-light me-3'>
                    <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                    Back
                  </button>
                )}
              </div>

              <div>
                <button type='submit' className='btn btn-sm btn-light-primary' onClick={nextStep}>
                  {currentStep === steps.length - 1 ? (
                    postLoading ? (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    ) : (
                      'Send Invite'
                    )
                  ) : (
                    'Continue'
                  )}
                  {!postLoading && <KTIcon iconName='arrow-right' className='fs-3 ms-1' />}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const addTeamMemberSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(250, 'Maximum 250 characters')
    .required('First Name is required'),
  last_name: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(250, 'Maximum 250 characters')
    .required('Last Name is required'),
  //   role_id: Yup.string().required('Role is required'),
  email: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(250, 'Maximum 250 characters')
    .required('Email is required'),
})

const Step1 = (props: any) => {
  const onSubmit = (values: any) => {
    props.setData({...props.data, ...values})
    props.set2ndIndex()
  }
  return (
    <Formik validationSchema={addTeamMemberSchema} initialValues={props.data} onSubmit={onSubmit}>
      <Form className=''>
        <div className='w-100 d-flex flex-column flex-lg-row'>
          <div className='w-100 w-lg-50 pe-lg-2'>
            <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
              <span className='required'>First Name</span>
              <i
                className='fas fa-info-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Enter the first name of the team member.'
              ></i>
            </label>
            <div className='mb-4'>
              <Field
                type='text'
                className='form-control form-control-solid'
                placeholder='Enter the first name'
                name='first_name'
              />

              <ErrorMessage component='div' className='text-danger' name='first_name' />
            </div>
          </div>
          <div className='w-100 w-lg-50 ps-lg-2'>
            <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
              <span className='required'>Last Name</span>
              <i
                className='fas fa-info-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Enter the last name of the team member.'
              ></i>
            </label>
            <div className='mb-4'>
              <Field
                type='text'
                className='form-control form-control-solid'
                placeholder='Enter the last name'
                name='last_name'
              />

              <ErrorMessage component='div' className='text-danger' name='last_name' />
            </div>
          </div>
        </div>

        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
          <span className='required'>Email</span>
          <i
            className='fas fa-info-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Enter the email address of the team member.'
          ></i>
        </label>
        <div className='mb-4'>
          <Field
            type='text'
            className='form-control form-control-solid'
            placeholder='Enter the email'
            name='email'
          />

          <ErrorMessage component='div' className='text-danger' name='email' />
        </div>

        <div className='d-none d-flex justify-content-end'>
          <div>
            <button
              type='submit'
              className='btn btn-sm btn-light me-2'
              //   onClick={() => setAddNewMemberPopover(null)}
            >
              Close
            </button>
            <button
              type='submit'
              className='btn btn-sm btn-light-primary ms-2'
              id='step1_submit_btn'
            >
              ADD
            </button>
          </div>
        </div>
      </Form>
    </Formik>
  )
}

const step2Schema = Yup.object().shape({
  role_id: Yup.string().required('Role is required'),
})

const Step2 = (props: any) => {
  const {execute, loading: rolesLoading} = usePostApi()
  const [rolesData, setRolesData] = useState([])
  const getRolesData = async () => {
    try {
      const url = '/master/roles'
      const formData = {}

      const response = await execute(url, 'POST', formData)
      setRolesData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getRolesData()
  }, [])

  const lang: any = useLang()
  const {mode} = useThemeMode()

  const onSubmit = (values: any) => {
    props.setData({...props.data, ...values})
    props.addTeamMember({...props.data, ...values})
  }
  return (
    <Formik validationSchema={step2Schema} initialValues={props.data} onSubmit={onSubmit}>
      {({setFieldValue, values}: any) => {
        return (
          <Form className='w-100'>
            <div className='d-flex flex-column mb-5'>
              <label
                className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'
                htmlFor='roles'
              >
                <span className='required'>Role</span>
                <i
                  className='fas fa-info-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Select the role of the team member.'
                ></i>
              </label>

              {rolesLoading ? (
                <div className='w-100'>
                  <div className='m-auto d-flex flex-column align-items-center'>
                    <div className='spinner-border spinner-primary mr-15'></div>
                  </div>
                </div>
              ) : (
                <>
                  <ul
                    className='w-100 nav nav-pills nav-pills-custom list-unstyled d-flex'
                    id='roles'
                  >
                    {(rolesData ?? []).map((each: any) => (
                      <li
                        className='nav-ite mb-3 me-3'
                        key={each.role_id}
                        data-bs-toggle='tooltip'
                        title={`${each.role_name}`}
                      >
                        <div
                          className={`nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-70px overflow-auto p-2 ${
                            values.role_id === each.role_id ? 'active-border bg-light-primary' : ''
                          }`}
                          onClick={() => setFieldValue('role_id', each.role_id)}
                        >
                          <div className=''>
                            <KTIcon
                              iconName={each?.role_icon || 'information-2'}
                              className={`fs-1 ${
                                values.role_id === each.role_id ? 'text-primary' : ''
                              }`}
                            />
                          </div>
                          <span className='fw-semibold text-dark fs-8'>{each.role_name}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <ErrorMessage component='div' className='text-danger' name='role_id' />
                </>
              )}
            </div>

            <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
              <span className='required'>Access Expiry</span>
              <i
                className='fas fa-info-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title="Select the date when the team member's access will expire."
              ></i>
            </label>

            <div className='text-start mb-4' style={{position: 'relative'}}>
              <Field name='membership_expiry'>
                {({form, field}: any) => {
                  const {setFieldValue} = form
                  const {value} = field

                  const handleChange = (date: any) => {
                    if (date instanceof Date && !isNaN(date.getTime())) {
                      // If the time part is not set (00:00:00), default it to 23:59:59
                      if (
                        date.getHours() === 0 &&
                        date.getMinutes() === 0 &&
                        date.getSeconds() === 0
                      ) {
                        date.setHours(23)
                        date.setMinutes(59)
                        date.setSeconds(59)
                      }
                      const dateTime = format(date, 'yyyy-MM-dd HH:mm:ss')
                      setFieldValue('membership_expiry', dateTime)
                    } else {
                      setFieldValue('membership_expiry', null)
                    }
                  }

                  const tomorrow = new Date()
                  tomorrow.setDate(tomorrow.getDate() + 1)

                  const timeFormatFunc = (val: any) => {
                    if (val) {
                      const dateValue = new Date(val)
                      if (
                        dateValue.getHours() === 0 &&
                        dateValue.getMinutes() === 0 &&
                        dateValue.getSeconds() === 0
                      ) {
                        const newValue = new Date(val)
                        newValue.setHours(23)
                        newValue.setMinutes(59)
                        return newValue
                      } else {
                        return val
                      }
                    } else {
                      return ''
                    }
                  }

                  return (
                    <div
                      className={`react-datepicker-fullwidth-wrapper test ${
                        mode === 'dark' ? 'dark-mode' : 'light-mode'
                      }`}
                    >
                      <DatePicker
                        className='form-control form-control-solid'
                        selected={timeFormatFunc(value)}
                        showYearDropdown
                        scrollableYearDropdown
                        dateFormat='dd-MM-yyyy HH:mm:ss'
                        minDate={tomorrow}
                        showTimeInput
                        onChange={(date: any) => {
                          handleChange(date)
                        }}
                        placeholderText='Select date'
                        showMonthDropdown
                        useShortMonthInDropdown
                        locale={lang || 'en'}
                      />
                    </div>
                  )
                }}
              </Field>
              <ErrorMessage name='membership_expiry' component='div' className='text-danger' />
              <span
                className={`cursor-pointer ${values.membership_expiry ? '' : 'd-none'}`}
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '0',
                  transform: 'translateY(-50%)',
                }}
                onClick={() => setFieldValue('membership_expiry', null)}
              >
                <KTIcon iconName='cross' className='fs-1 me-2 mt-1' />
              </span>
            </div>
            <button type='submit' className='d-none btn btn-light-primary' id='step2_submit_btn'>
              Add
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}

export default AddTeamMemberModal
