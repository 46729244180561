import React from 'react'
import {Link, useParams} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import Organization from './Organization'
import OrganizationInfo from './organizationInformation/OrganizationInfo'
import Domains from './white_labeling/Domains'
import MasterDataRouting from '../master-data-management/MasterDataRouting'
import {CheckPodAccess, FindFirstPodCode} from '../FindPodIdByPodCode'
import Lookups from './lookups/Lookups'

function ConfigurationRoutes() {
  const {id} = useParams()
  const {pathname} = useLocation()
  const havePodAccess = CheckPodAccess()
  const firstPodCode = FindFirstPodCode()
  const ConfigurationTitle = [
    {
      title: `(${id})`,
      path: `/${id}/configuration/information`,
      isSeparator: false,
      isActive: false,
    },
  ]
  const switchMethod = () => {
    switch (pathname) {
      case `/${id}/configuration/information`:
        return (
          <>
            <PageTitle breadcrumbs={ConfigurationTitle}>Information</PageTitle>
            <OrganizationInfo />
          </>
        )
      case `/${id}/configuration/lookups`:
        return (
          <>
            <PageTitle breadcrumbs={ConfigurationTitle}>Lookups</PageTitle>
            <Lookups />
          </>
        )
      case `/${id}/configuration/white-labeling`:
      case `/${id}/configuration/white-labeling/domains`:
      case `/${id}/configuration/white-labeling/senders`:
      case `/${id}/configuration/white-labeling/careers`:
      case `/${id}/configuration/white-labeling/careers-portal`:
        return (
          <>
            <PageTitle breadcrumbs={ConfigurationTitle}>Domain Branding</PageTitle>
            <Domains />
          </>
        )
      case `/${id}/configuration/master-data`:
      case `/${id}/configuration/master-data/designations`:
      case `/${id}/configuration/master-data/locations`:
      case `/${id}/configuration/master-data/business-unit`:
      case `/${id}/configuration/master-data/departments`:
      case `/${id}/configuration/master-data/job-roles`:
      case `/${id}/configuration/master-data/job-titles`:
      case `/${id}/configuration/master-data/interview-types`:
      case `/${id}/configuration/master-data/qualifications`:
      case `/${id}/configuration/master-data/employment-type`:
        return (
          <>
            <PageTitle breadcrumbs={ConfigurationTitle}>Master Data</PageTitle>
            <MasterDataRouting />
          </>
        )
      default:
        return (
          <>
            <PageTitle breadcrumbs={ConfigurationTitle}>Domain Branding</PageTitle>
            <Domains />
          </>
        )
    }
  }
  return havePodAccess ? (
    <div>
      <Organization />
      {switchMethod()}
    </div>
  ) : (
    <div className='card min-h-250px d-flex flex-column justify-content-center align-items-center bg-image'>
      <h1 className='fw-semibold fs-2hx text-gray-900 mb-4'>Oops!</h1>
      <div className='fs-6 text-gray-500 mb-7'>You don't have access to this POD.</div>
      <div className='mb-0'>
        <Link to={`/${firstPodCode}/dashboard`} className='btn btn-sm btn-light-primary'>
          Return Home
        </Link>
      </div>
    </div>
  )
}

export default ConfigurationRoutes
