import {useEffect, useState, Dispatch, SetStateAction} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {KTIcon} from '../../../_metronic/helpers'
import './index.css'
import clsx from 'clsx'
import secureLocalStorage from 'react-secure-storage'
import ThemeColors from './ThemePref'
import Select, {components} from 'react-select'
import {useThemeMode} from '../../../_metronic/partials'
import {useLocation} from 'react-router-dom'
import usePostApi from '../custom_hooks/usePostApi'
import {UseToast} from '../useToast'

const registrationSchema = Yup.object().shape({
  pod_business_name: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(250, 'Maximum 250 characters')
    .required('Organization Name is required'),
  pod_brand_name: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(250, 'Maximum 250 characters')
    .required('Brand Name is required'),
})

type Data = {
  pod_business_name: string
  pod_brand_name: string
  pod_industry_id: number | string
  pod_team_size: number
  pod_description: string
  pod_country_code: string
  pod_website_url: string
  dateFormat: string
  timeZone: string
  pod_pref_color: string
  pod_currency_code: string
}

interface UserInfo {
  user_id: string | undefined
}

function CustomWizardNew({
  addNewPod = () => {},
  closeModal = () => {},
  fetchPODsList = () => {},
  postLoading = false,
}: any) {
  const userInfo = secureLocalStorage.getItem('user_info') as Partial<UserInfo> | null
  const user_id: any = userInfo?.user_id
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [promptData, setPromptData] = useState({})
  const [podData, setPodData] = useState({pod_code: '', pod_id: ''})
  const [data, setData] = useState<Data>({
    pod_business_name: '',
    pod_brand_name: '',
    pod_industry_id: '',
    pod_team_size: -1,
    pod_description: '',
    pod_country_code: '',
    pod_website_url: '',
    dateFormat: '',
    timeZone: '',
    pod_pref_color: '#506bec',
    pod_currency_code: '',
  })
  const set2ndIndex = () => {
    setCurrentStep(1)
  }

  const set3rdIndex = () => {
    setCurrentStep(2)
  }

  const handleCloseModalAndReloadPods = () => {
    if (currentStep === 3) {
      closeModal()
      fetchPODsList()
    } else {
      closeModal()
    }
  }

  const addPod = async () => {
    const formData: any = new FormData()
    formData.append('pod_brand_name', data.pod_brand_name)
    formData.append('pod_business_name', data.pod_business_name)
    formData.append('pod_country_code', data.pod_country_code)
    formData.append('pod_description', data.pod_description)
    formData.append('pod_industry_id', data.pod_industry_id)
    formData.append('pod_team_size', data.pod_team_size)
    formData.append('created_by', user_id)
    // formData.append('attachment', null)
    formData.append('pod_pref_color', data.pod_pref_color)
    formData.append('pod_date_format', data.dateFormat)
    formData.append('pod_timezone', data.timeZone)
    formData.append('url', data.pod_website_url)
    formData.append('pod_currency_code', data.pod_currency_code)
    // addNewPod(formData)
    const response = await addNewPod(formData)

    if (response?.status === true) {
      setPromptData(response?.data?.industry_prompt)
      setPodData({pod_code: response?.data?.pod_code, pod_id: response?.data?.pod_id})
      setCurrentStep(3)
      // fetchPODsList()
    } else if (response.status_code === 'SC0135') {
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
      console.log(response.status_message)
    }
  }

  const steps = [
    <Step1 set2ndIndex={set2ndIndex} data={data} setData={setData} key='step_1' />,
    <Step2 set3rdIndex={set3rdIndex} data={data} setData={setData} key='step_2' />,
    <Step3 data={data} setData={setData} addPod={addPod} key='step_3' />,
    <Step4
      promptData={promptData}
      podData={podData}
      key='step_4'
      closeModal={handleCloseModalAndReloadPods}
    />,
  ]

  const nextStep = async () => {
    if (currentStep === steps.length - 1) {
      closeModal()
      window.location.reload()
    } else if (currentStep === steps.length - 2) {
      const element = document.getElementById('step_3_submit')
      if (element) {
        element.click()
      }
    } else if (currentStep === 0) {
      const element = document.getElementById('verify-sign-up-btn')
      if (element) {
        element.click()
      }
    } else if (currentStep === 1) {
      const element = document.getElementById('step_2_submit')
      if (element) {
        element.click()
      }
    } else {
      const totalSteps = steps.length - 1
      if (currentStep < totalSteps) {
        setCurrentStep(currentStep + 1)
      }
    }
  }

  const prevStep = (): void => {
    if (currentStep !== 0) {
      setCurrentStep(currentStep - 1)
    }
  }

  const {pathname} = useLocation()

  let buttonText: string
  if (currentStep === 2) {
    buttonText = 'Create POD'
  } else if (currentStep === 3) {
    buttonText = 'Create'
  } else {
    buttonText = 'Continue'
  }

  return (
    <div className='card py-0 overflow-auto'>
      <div className='w-100 m-auto'>
        <div className='d-flex justify-content-between mb-10'>
          <h3 className='fs-3 fw-semibold'>Create New POD</h3>
          {pathname !== '/first-pod-setup' ? (
            <button
              type='button'
              className='d-block btn btn-sm btn-icon btn-light'
              onClick={handleCloseModalAndReloadPods}
              // onClick={() => closeModal()}
              // onClick={() => (currentStep === 3 ? window.location.reload() : closeModal())}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </button>
          ) : null}
        </div>
        <div className='w-100 row m-0'>
          <div className='col-lg-5 p-0 mb-5 mb-lg-0'>
            <div className=''>
              <div className='d-flex'>
                <div
                  style={{borderRadius: '10px'}}
                  className={clsx(
                    'w-40px h-40px btn d-flex justify-content-center align-items-center',
                    {
                      'btn-primary': currentStep === 0,
                      'btn-light-primary': currentStep !== 0,
                    }
                  )}
                >
                  {currentStep === 0 ? 1 : <i className='stepper-check fas fa-check'></i>}
                </div>
                <div className='ms-5'>
                  <h6
                    className={clsx('mb-0 fw-semibold', {
                      'text-muted': currentStep >= 0,
                    })}
                  >
                    Step 1
                  </h6>
                  <p
                    className={clsx('fs-6 mb-0', {
                      'text-muted': currentStep >= 0,
                    })}
                  >
                    Company & Brand
                  </p>
                </div>
              </div>
              <div className='ms-6 h-30px border-left border-dashed border-muted border-top-0 border-bottom-0 border-right-0 border-left-1'></div>
              <div className='d-flex'>
                <div
                  style={{borderRadius: '10px'}}
                  className={clsx(
                    'w-40px h-40px btn d-flex justify-content-center align-items-center',
                    {
                      'btn-primary': currentStep === 1,
                      'btn-light-primary': currentStep !== 1,
                    }
                  )}
                >
                  {currentStep > 1 ? <i className='stepper-check fas fa-check'></i> : 2}
                </div>
                <div className='ms-5'>
                  <h6
                    className={clsx('mb-0 fw-semibold', {
                      'text-muted': currentStep >= 1,
                    })}
                  >
                    Step 2
                  </h6>
                  <p
                    className={clsx('fs-6 mb-0', {
                      'text-muted': currentStep >= 1,
                    })}
                  >
                    Industry & Team
                  </p>
                </div>
              </div>
              {/* Add more steps here */}
              <div className='ms-6 h-30px border-left border-dashed border-muted border-top-0 border-bottom-0 border-right-0 border-left-1'></div>

              <div className='d-flex'>
                <div
                  style={{borderRadius: '10px'}}
                  className={clsx(
                    'w-40px h-40px btn d-flex justify-content-center align-items-center',
                    {
                      'btn-primary': currentStep === 2,
                      'btn-light-primary': currentStep !== 2,
                    }
                  )}
                >
                  {currentStep > 2 ? <i className='stepper-check fas fa-check'></i> : 3}
                </div>
                <div className='ms-5'>
                  <h6
                    className={clsx('mb-0 fw-semibold', {
                      'text-muted': currentStep >= 2,
                    })}
                  >
                    Step 3
                  </h6>
                  <p
                    className={clsx('fs-6 mb-0', {
                      'text-muted': currentStep > 1,
                    })}
                  >
                    Regional Preferences
                  </p>
                </div>
              </div>
              <div className='ms-6 h-30px border-left border-dashed border-muted border-top-0 border-bottom-0 border-right-0 border-left-1'></div>

              <div className='d-flex'>
                <div
                  style={{borderRadius: '10px'}}
                  className={clsx(
                    'w-40px h-40px btn d-flex justify-content-center align-items-center',
                    {
                      'btn-primary': currentStep === 3,
                      'btn-light-primary': currentStep !== 3,
                    }
                  )}
                >
                  {currentStep > 3 ? <i className='stepper-check fas fa-check'></i> : 4}
                </div>
                <div className='ms-5'>
                  <h6
                    className={clsx('mb-0 fw-semibold', {
                      'text-muted': currentStep >= 3,
                    })}
                  >
                    Step 4
                  </h6>
                  <p
                    className={clsx('fs-6 mb-0', {
                      'text-muted': currentStep >= 3,
                    })}
                  >
                    Organization Structure
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-7 p-0 m-0'>{steps[currentStep]}</div>
        </div>

        <div className='w-100 d-flex flex-stack mt-5'>
          <div className='mr-2'>
            {currentStep > 0 && currentStep !== steps.length - 1 && (
              <button onClick={prevStep} type='button' className='btn btn-sm btn-light me-3'>
                <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                Back
              </button>
            )}
          </div>

          <div>
            {currentStep !== 3 && (
              <button
                type='submit'
                className='btn btn-sm btn-light-primary'
                onClick={nextStep}
                disabled={postLoading}
              >
                {postLoading ? (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                ) : (
                  <>
                    {buttonText}
                    <KTIcon iconName='arrow-right' className='fs-3 ms-1' />
                  </>
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

interface Step1Props {
  set2ndIndex(): void
  data: Data
  setData: (values: Data) => void
}

interface Industry {
  industry_id: number
  industry_en: string
  status: number
}

interface IndustryData {
  data: Industry[] | null
  isLoading: boolean
  fetchData(): Promise<void>
}

interface Country {
  id: number
  iso: string
  name: number
}

interface Teamsize {
  id: number
  is_active: number
  meaning_en: string
  lookup_code: string
}

interface CountryData {
  data: Country[] | null
  isLoading: boolean
  fetchData(): Promise<void>
}
interface TeamsizesData {
  data: Teamsize[] | null
  isLoading: boolean
  fetchData(): Promise<void>
}

interface Industry {
  industry_id: number
  industry_en: string
}

interface IndustryData {
  data: Industry[] | null
  isLoading: boolean
  fetchData(): Promise<void>
}

const Step1 = (props: Step1Props) => {
  const onSubmit = async (Values: Data) => {
    props.setData({...Values})
    props.set2ndIndex()
  }

  return (
    <Formik validationSchema={registrationSchema} initialValues={props.data} onSubmit={onSubmit}>
      {({setFieldValue, values}: any) => {
        return (
          <Form className='w-100'>
            {/* <div className='w-100 d-flex flex-column justify-content-between mb-7 fv-row'> */}
            <div className='w-100 mb-5'>
              <label
                className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'
                htmlFor='org_name'
              >
                <span className='required'>Organization Name</span>
                <i
                  className='fas fa-info-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='This is the name of your company or organization.'
                ></i>
              </label>
              <Field
                id='org_name'
                type='text'
                name='pod_business_name'
                className='form-control form-control-solid'
                placeholder='Enter your organization name'
                autoFocus
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='pod_business_name' />
              </div>
            </div>
            <div className='w-100 mb-5'>
              <label
                className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'
                htmlFor='brand_name'
              >
                <span className='required'>Brand Name</span>
                <i
                  className='fas fa-info-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='The name of your brand or product.'
                ></i>
              </label>
              <Field
                id='brand_name'
                type='text'
                name='pod_brand_name'
                className='form-control form-control-solid'
                placeholder='Enter your brand name'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='pod_brand_name' />
              </div>
            </div>
            {/* </div> */}

            <div className='w-100 d-flex flex-column mb-4 fv-row'>
              <label
                className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'
                htmlFor='brand_des'
              >
                <span className=''>About {values?.pod_brand_name}</span>
                <i
                  className='fas fa-info-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Provide a short description of your organization, its mission, or what it does.'
                ></i>
              </label>
              <Field
                id='brand_des'
                as='textarea'
                className={`form-control form-control form-control-solid`}
                name='pod_description'
                placeholder='Briefly describe your organization'
                rows={3}
              />
            </div>

            <button
              type='submit'
              className='d-none btn btn-lg btn-primary w-100'
              id='verify-sign-up-btn'
            >
              Submit
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}

const step2Schema = Yup.object().shape({
  pod_industry_id: Yup.string().required('Industry is required'),
  pod_country_code: Yup.string().required('Country is required'),
})

const Step2 = ({set3rdIndex, data, setData}: any) => {
  const {execute} = usePostApi()
  const {mode} = useThemeMode()

  const [industries, setIndustries] = useState([])

  const getIndustries = async () => {
    try {
      const url = '/master/industries'
      const formData = {}

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.industry_id,
        label: each.industry_en,
      }))
      setIndustries(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }
  const [countryOptions, setCountryOptions] = useState([])

  const getCountries = async () => {
    try {
      const url = '/master/country'
      const formData = {}

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        pref_date_format: each.pref_date_format,
        pref_timezone: each.pref_timezone,
        value: each.iso,
        label: each.name,
      }))
      setCountryOptions(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }
  const [teamsizeOptions, setTeamSizeOptions] = useState([])

  const getDifferentTeamSizes = async () => {
    try {
      const url = '/lookup/TEAM_SIZE'
      const formData = {}

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.lookup_code,
        label: each.meaning_en,
      }))
      setTeamSizeOptions(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getIndustries()
    getCountries()
    getDifferentTeamSizes()
  }, [])

  const [urlValid, setUrlValid] = useState(false)
  const [urlInput, setUrlInput] = useState(data.pod_website_url)

  const regex =
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/

  useEffect(() => {
    if (urlInput) {
      const isValid = regex.test(urlInput)
      if (isValid === false) {
        setUrlValid(true)
        return
      }
      setUrlValid(false)
    } else {
      setUrlValid(false)
    }
  }, [urlInput])

  const onSubmit = (values: any) => {
    if (urlValid === true) {
      return
    }
    setData({...values})
    set3rdIndex()
  }

  const handleIndustryChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('pod_industry_id', selectedOption.value)
    } else {
      setFieldValue('pod_industry_id', '') // Handle case where no option is selected
    }
  }

  const handleCountryChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('pod_country_code', selectedOption.value)
      setFieldValue('timeZone', selectedOption.pref_timezone)
      setFieldValue('dateFormat', selectedOption.pref_date_format)
    } else {
      setFieldValue('pod_country_code', '') // Handle case where no option is selected
    }
  }

  const handleTeamsizeChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('pod_team_size', selectedOption.value)
    } else {
      setFieldValue('pod_team_size', '') // Handle case where no option is selected
    }
  }

  return (
    <Formik validationSchema={step2Schema} initialValues={data} onSubmit={onSubmit}>
      {({setFieldValue, values}: any) => {
        return (
          <Form className='w-100'>
            <div className='mb-5'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Industry</span>
                <i
                  className='fas fa-info-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Choose the industry your organization belongs to.'
                ></i>
              </label>
              <Select
                value={industries.find((option: any) => option.value === values.pod_industry_id)}
                styles={{
                  control: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                    color: '#ffffff',
                    borderWidth: '0px',
                  }),
                  menu: (baseStyles: any) => ({
                    ...baseStyles,
                    backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                  }),
                  singleValue: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    color: mode === 'dark' ? '#92929f' : '#000000',
                  }),
                  input: (baseStyles: any) => ({
                    ...baseStyles,
                    color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                  }),
                  option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                    ...baseStyles,
                    backgroundColor: isSelected
                      ? '#C2C2C2'
                      : isFocused
                      ? '#e0e0e0'
                      : baseStyles.backgroundColor,
                    color: isSelected ? '#000000' : isFocused ? '#000000' : baseStyles.color,
                  }),
                }}
                theme={(theme: any) => ({
                  ...theme,
                  borderRadius: 5,
                  backgroundColor: '#000000',
                  colors: {
                    ...theme.colors,
                    primary25: 'rgba(80, 107, 236, 0.5)',
                    primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                  },
                })}
                className='react-select-container'
                classNamePrefix='react-select'
                options={industries}
                isSearchable
                placeholder='Select your industry'
                onChange={(val: any) => handleIndustryChange(setFieldValue, val)}
              />

              <div className='text-danger mt-2'>
                <ErrorMessage name='pod_industry_id' />
              </div>
            </div>
            <div className='mb-5'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Country</span>
                <i
                  className='fas fa-info-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Choose the country where your organization is located.'
                ></i>
              </label>

              <Select
                value={countryOptions.find(
                  (option: any) => option.value === values.pod_country_code
                )}
                styles={{
                  control: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                    color: '#ffffff',
                    borderWidth: '0px',
                  }),
                  menu: (baseStyles: any) => ({
                    ...baseStyles,
                    backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                  }),
                  singleValue: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    color: mode === 'dark' ? '#92929f' : '#000000',
                  }),
                  input: (baseStyles: any) => ({
                    ...baseStyles,
                    color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                  }),
                  option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                    ...baseStyles,
                    backgroundColor: isSelected
                      ? '#C2C2C2'
                      : isFocused
                      ? '#e0e0e0'
                      : baseStyles.backgroundColor,
                    color: isSelected ? '#000000' : isFocused ? '#000000' : baseStyles.color,
                  }),
                }}
                theme={(theme: any) => ({
                  ...theme,
                  borderRadius: 5,
                  backgroundColor: '#000000',
                  colors: {
                    ...theme.colors,
                    primary25: 'rgba(80, 107, 236, 0.3)',
                    primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                  },
                })}
                className='react-select-container'
                classNamePrefix='react-select'
                options={countryOptions}
                isSearchable
                placeholder='Select your country'
                onChange={(val: any) => handleCountryChange(setFieldValue, val)}
              />
              <div className='text-danger'>
                <ErrorMessage name='pod_country_code' />
              </div>
            </div>

            <div className='alert alert-danger d-flex align-items-center p-1'>
              <KTIcon iconName='information' className='fs-2 text-danger me-2' />
              <div className='d-flex flex-column'>
                <h6 className='mb-0 fw-semibold fs-7 text-dark'>Note:</h6>
                <span className='fs-8 text-dark'>
                  Please be advised that your country selection is final and cannot be modified
                  later. Please ensure careful consideration before making your country selection.
                </span>
              </div>
            </div>

            <div className='mb-5'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className=''>Team Size</span>
                <i
                  className='fas fa-info-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='The number of people in your organization.'
                ></i>
              </label>
              <Select
                value={teamsizeOptions.find((option: any) => option.value === values.pod_team_size)}
                styles={{
                  control: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                    color: '#ffffff',
                    borderWidth: '0px',
                  }),
                  menu: (baseStyles: any) => ({
                    ...baseStyles,
                    backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                  }),
                  singleValue: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    color: mode === 'dark' ? '#92929f' : '#000000',
                  }),
                  input: (baseStyles: any) => ({
                    ...baseStyles,
                    color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                  }),
                  option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                    ...baseStyles,
                    backgroundColor: isSelected
                      ? '#C2C2C2'
                      : isFocused
                      ? '#e0e0e0'
                      : baseStyles.backgroundColor,
                    color: isSelected ? '#000000' : isFocused ? '#000000' : baseStyles.color,
                  }),
                }}
                theme={(theme: any) => ({
                  ...theme,
                  borderRadius: 5,
                  backgroundColor: '#000000',
                  colors: {
                    ...theme.colors,
                    primary25: 'rgba(80, 107, 236, 0.5)',
                    primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                  },
                })}
                className='react-select-container'
                classNamePrefix='react-select'
                options={teamsizeOptions}
                isSearchable
                placeholder='Select your team size'
                onChange={(val: any) => handleTeamsizeChange(setFieldValue, val)}
              />
            </div>
            <div className='mb-5'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className=''>Website</span>
                <i
                  className='fas fa-info-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title="The web address of your organization's website."
                ></i>
              </label>
              <input
                className='form-control form-control-solid'
                placeholder='Enter your website URL'
                value={urlInput}
                onChange={(e: any) => {
                  setUrlInput(e.target.value)
                  setFieldValue('pod_website_url', e.target.value)
                }}
              />

              <div className='text-danger'>{urlValid && 'Please enter a valid URL'}</div>
            </div>

            <button
              type='submit'
              className='d-none btn btn-lg btn-primary w-100'
              id='step_2_submit'
            >
              Submit
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}

interface Step3Props {
  data: Data
  setData(data: any): void
  addPod(): void
}

const step3Schema = Yup.object().shape({
  pod_currency_code: Yup.string().required('Currency is required'),
})

const Step3 = ({data, setData, addPod}: Step3Props) => {
  const {mode} = useThemeMode()
  const {execute} = usePostApi()

  const [timezones, setTimezones] = useState([])
  const [currencies, setCurrencies] = useState([])

  const getTimezones = async () => {
    try {
      const url = '/master/timezone'
      const formData = {}

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.tz_name,
        label: each.tz_label,
      }))
      setTimezones(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const getCurrencies = async () => {
    try {
      const url = `/master/currency`
      const formData = {}
      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.currency_code,
        label: each.currency_name,
      }))
      setCurrencies(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getTimezones()
    getCurrencies()
  }, [])

  const dateFormatOptions = [
    {value: 'dd/MM/yyyy', label: 'DD/MM/YYYY'},
    {value: 'MM/dd/yyyy', label: 'MM/DD/YYYY'},
    {value: 'yyyy/MM/dd', label: 'YYYY/MM/DD'},
    {value: 'dd-MM-yyyy', label: 'DD-MM-YYYY'},
    {value: 'MM dd, yyyy', label: 'MM DD, YYYY'},
    {value: 'dd MMMM yyyy', label: 'DD Month YYYY'},
    {value: 'MM dd yyyy', label: 'MM DD YYYY'},
    {value: 'dd-MM-yy', label: 'DD-MM-YY'},
    {value: 'yy/MM/dd', label: 'YY/MM/DD'},
  ]

  const addCurrentDateToLabel = (format: any) => {
    const currentDate = new Date()

    const formattedDate = format
      .replace('dd', currentDate.getDate().toString().padStart(2, '0'))
      .replace('DD', currentDate.getDate().toString().padStart(2, '0'))
      .replace('MM', (currentDate.getMonth() + 1).toString().padStart(2, '0'))
      .replace('yyyy', currentDate.getFullYear())
      .replace('YYYY', currentDate.getFullYear())
      .replace('yy', currentDate.getFullYear().toString().slice(-2))
      .replace('YY', currentDate.getFullYear().toString().slice(-2))
      .replace('Month', new Intl.DateTimeFormat('en-US', {month: 'long'}).format(currentDate))

    return `${format} (${formattedDate})`
  }

  // Update dateFormatOptions with current date added to label
  const dateFormatOptionsWithDate = dateFormatOptions.map((option) => ({
    ...option,
    label: addCurrentDateToLabel(option.label),
  }))

  const updateData = (value: string, key: string) => {
    setData({...data, [key]: value})
  }

  const MultiLineOption = (props: any) => (
    <components.Option {...props}>
      <div>{props.data.label}</div>
      <div style={{fontSize: '12px', color: '#92929f'}}>{props.data.value}</div>
    </components.Option>
  )

  const submitStep3 = (values: any) => {
    addPod()
  }

  return (
    <>
      <Formik
        validationSchema={step3Schema}
        initialValues={data}
        onSubmit={submitStep3}
        enableReinitialize={true}
      >
        {({setFieldValue, values}: any) => {
          return (
            <Form className='w-100'>
              <div className='mb-5 w-100'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className=''>Date Format</span>
                  <i
                    className='fas fa-info-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Choose how you want dates to be displayed (e.g., DD/MM/YYYY, MM/DD/YYYY).'
                  ></i>
                </label>
                <Select
                  value={dateFormatOptionsWithDate.find(
                    (option: any) => option.value === data.dateFormat
                  )}
                  styles={{
                    control: (baseStyles: any, state: any) => ({
                      ...baseStyles,
                      backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                      color: '#ffffff',
                      borderWidth: '0px',
                    }),
                    menu: (baseStyles: any) => ({
                      ...baseStyles,
                      backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                    }),
                    singleValue: (baseStyles: any, state: any) => ({
                      ...baseStyles,
                      color: mode === 'dark' ? '#92929f' : '#000000',
                    }),
                    input: (baseStyles: any) => ({
                      ...baseStyles,
                      color: mode === 'dark' ? '#92929f' : '#000000',
                    }),
                    option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                      ...baseStyles,
                      backgroundColor: isSelected
                        ? '#C2C2C2'
                        : isFocused
                        ? '#e0e0e0'
                        : baseStyles.backgroundColor,
                      color: isSelected ? '#000000' : isFocused ? '#000000' : baseStyles.color,
                    }),
                  }}
                  theme={(theme: any) => ({
                    ...theme,
                    borderRadius: 5,
                    backgroundColor: '#000000',
                    colors: {
                      ...theme.colors,
                      primary25: 'rgba(80, 107, 236, 0.5)',
                      primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                    },
                  })}
                  className='react-select-container'
                  classNamePrefix='react-select'
                  options={dateFormatOptionsWithDate}
                  isSearchable
                  placeholder='Select your preferred date format'
                  onChange={(val: any) => {
                    updateData(val?.value, 'dateFormat')
                  }}
                  // components={{Option: MultiLineOption}}
                />
              </div>
              <div className='mb-5'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className=''>Timezone</span>
                  <i
                    className='fas fa-info-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Choose the timezone your organization operates in.'
                  ></i>
                </label>
                <Select
                  value={timezones.find((option: any) => option.value === data.timeZone)}
                  styles={{
                    control: (baseStyles: any, state: any) => ({
                      ...baseStyles,
                      backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                      color: '#ffffff',
                      borderWidth: '0px',
                    }),
                    menu: (baseStyles: any) => ({
                      ...baseStyles,
                      backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                      // maxHeight: '160px',
                      // overflowY: 'scroll',
                    }),
                    singleValue: (baseStyles: any, state: any) => ({
                      ...baseStyles,
                      color: mode === 'dark' ? '#92929f' : '#000000',
                    }),
                    input: (baseStyles: any) => ({
                      ...baseStyles,
                      color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                    }),
                    option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                      ...baseStyles,
                      backgroundColor: isSelected
                        ? '#C2C2C2'
                        : isFocused
                        ? '#e0e0e0'
                        : baseStyles.backgroundColor,
                      color: isSelected ? '#000000' : isFocused ? '#000000' : baseStyles.color,
                    }),
                  }}
                  theme={(theme: any) => ({
                    ...theme,
                    borderRadius: 5,
                    backgroundColor: '#000000',
                    colors: {
                      ...theme.colors,
                      primary25: 'rgba(80, 107, 236, 0.5)',
                      primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                    },
                  })}
                  className='react-select-container'
                  classNamePrefix='react-select'
                  options={timezones}
                  isSearchable
                  placeholder='Select your timezone'
                  onChange={(val: any) => {
                    // Check if the selected option value is already selected
                    const isAlreadySelected = data.timeZone === val?.value

                    // Update data only if the selected value is not already selected
                    if (!isAlreadySelected) {
                      updateData(val?.value, 'timeZone')
                    }
                  }}
                  components={{Option: MultiLineOption}} // Add this line to use the MultiLineOption component
                />
              </div>

              <div className='mb-5'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className=''>Currency</span>
                  <i
                    className='fas fa-info-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Select the currency your organization uses for transactions.'
                  ></i>
                </label>
                <Select
                  value={currencies.find((option: any) => option.value === data.pod_currency_code)}
                  styles={{
                    control: (baseStyles: any, state: any) => ({
                      ...baseStyles,
                      backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                      color: '#ffffff',
                      borderWidth: '0px',
                    }),
                    menu: (baseStyles: any) => ({
                      ...baseStyles,
                      backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                      // maxHeight: '160px',
                      // overflowY: 'scroll',
                    }),
                    singleValue: (baseStyles: any, state: any) => ({
                      ...baseStyles,
                      color: mode === 'dark' ? '#92929f' : '#000000',
                    }),
                    input: (baseStyles: any) => ({
                      ...baseStyles,
                      color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                    }),
                    option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                      ...baseStyles,
                      backgroundColor: isSelected
                        ? '#C2C2C2'
                        : isFocused
                        ? '#e0e0e0'
                        : baseStyles.backgroundColor,
                      color: isSelected ? '#000000' : isFocused ? '#000000' : baseStyles.color,
                    }),
                  }}
                  theme={(theme: any) => ({
                    ...theme,
                    borderRadius: 5,
                    backgroundColor: '#000000',
                    colors: {
                      ...theme.colors,
                      primary25: 'rgba(80, 107, 236, 0.5)',
                      primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                    },
                  })}
                  className='react-select-container'
                  classNamePrefix='react-select'
                  options={currencies}
                  isSearchable
                  placeholder='Select your currency'
                  onChange={(val: any) => {
                    setFieldValue('pod_currency_code', val?.value || '')

                    const isAlreadySelected = data.pod_currency_code === val?.value

                    if (!isAlreadySelected) {
                      updateData(val?.value, 'pod_currency_code')
                    }
                  }}
                  // components={{Option: MultiLineOption}} // Add this line to use the MultiLineOption component
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='pod_currency_code' />
                </div>
              </div>
              <ThemeColors updateData={updateData} data={data} />

              <button
                type='submit'
                className='d-none btn btn-lg btn-primary w-100'
                id='step_3_submit'
              >
                Submit
              </button>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

const Accordion = ({title, children, isActive, onClick, checkedColor, skippedColor}: any) => {
  return (
    <div className='border rounded border-dashed mb-2'>
      <div
        className={`bg-${
          checkedColor ? 'light-success' : skippedColor ? 'light-primary' : 'light'
        } rounded p-3 d-flex flex-column justify-content-between`}
        onClick={onClick}
      >
        <div className='d-flex justify-content-between'>
          <label>
            <h5 className='fw-semibold d-flex align-items-center'>
              {!checkedColor && !skippedColor ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  height='18px'
                  viewBox='0 -960 960 960'
                  width='18px'
                  fill='#5f6368'
                >
                  <path d='M610-760q-21 0-35.5-14.5T560-810q0-21 14.5-35.5T610-860q21 0 35.5 14.5T660-810q0 21-14.5 35.5T610-760Zm0 660q-21 0-35.5-14.5T560-150q0-21 14.5-35.5T610-200q21 0 35.5 14.5T660-150q0 21-14.5 35.5T610-100Zm160-520q-21 0-35.5-14.5T720-670q0-21 14.5-35.5T770-720q21 0 35.5 14.5T820-670q0 21-14.5 35.5T770-620Zm0 380q-21 0-35.5-14.5T720-290q0-21 14.5-35.5T770-340q21 0 35.5 14.5T820-290q0 21-14.5 35.5T770-240Zm60-190q-21 0-35.5-14.5T780-480q0-21 14.5-35.5T830-530q21 0 35.5 14.5T880-480q0 21-14.5 35.5T830-430ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880v80q-134 0-227 93t-93 227q0 134 93 227t227 93v80Zm0-320q-33 0-56.5-23.5T400-480q0-5 .5-10.5T403-501l-83-83 56-56 83 83q4-1 21-3 33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Z' />
                </svg>
              ) : (
                <KTIcon
                  iconName={`${
                    checkedColor ? 'check-square' : skippedColor ? 'double-right-arrow' : 'loading'
                  }`}
                  className={`fs-2 ${
                    checkedColor ? 'text-success' : skippedColor ? 'text-primary' : 'text-black-50'
                  }`}
                />
              )}
              <span className='ms-2'>
                {title} {' -\u00A0'}
              </span>
              <span
                className={`${
                  checkedColor ? 'text-success' : skippedColor ? 'text-primary' : 'text-black-50'
                }`}
              >
                {checkedColor ? 'Completed' : skippedColor ? 'Skipped' : 'Pending'}
              </span>
            </h5>
            {/* <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='The "Quick Create Job Titles" feature allows you to easily add new job titles to the system. You can choose from suggested job titles or create a custom one.'
          ></i> */}
          </label>
          <span>
            <KTIcon iconName={checkedColor ? 'check' : isActive ? 'up' : 'down'} className='fs-1' />
          </span>
        </div>
      </div>
      <div className={`accordion ${isActive ? 'show' : ''}`}>
        {isActive && <div className='accordion-content'>{children}</div>}
      </div>
    </div>
  )
}

const Step4 = ({promptData, podData, closeModal}: any) => {
  const {execute, postLoading} = usePostApi()
  const {pod_code, pod_id} = podData
  let job_titles, interview_types, designations
  try {
    ;({job_titles, interview_types, designations} = JSON.parse(promptData || {}))
  } catch (error) {
    // console.error('Error parsing JSON:', error)
  }

  const [tags, setTags] = useState<string[]>([])

  const removeTags = (indexToRemove: any) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)])
  }

  const addTags = (event: any) => {
    const newTag = event.target.value.trim()
    if (newTag !== '') {
      setTags([...tags, newTag])
      event.target.value = ''
    }
  }

  const [designationTags, setDesignationTags] = useState<string[]>([])

  const removeDesignationTags = (indexToRemove: any) => {
    setDesignationTags([...designationTags.filter((_, index) => index !== indexToRemove)])
  }

  const addDesignationTags = (event: any) => {
    const newTag = event.target.value.trim()
    if (newTag !== '') {
      setDesignationTags([...designationTags, event.target.value])
      event.target.value = ''
    }
  }

  const [interviewTags, setInterviewTags] = useState<string[]>([])

  const removeInterviewTags = (indexToRemove: any) => {
    setInterviewTags([...interviewTags.filter((_, index) => index !== indexToRemove)])
  }

  const addInterviewTags = (event: any) => {
    const newTag = event.target.value.trim()
    if (newTag !== '') {
      setInterviewTags([...interviewTags, event.target.value])
      event.target.value = ''
    }
  }

  const [bUTags, setBUTags] = useState<string[]>([])
  const removeBUTags = (indexToRemove: any) => {
    setBUTags([...bUTags.filter((_, index) => index !== indexToRemove)])
  }

  const addBUTags = (event: any) => {
    const newTag = event.target.value.trim()
    if (newTag !== '') {
      setBUTags([...bUTags, event.target.value])
      event.target.value = ''
    }
  }

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)
  const [designationHoverIndex, setDesignationHoverIndex] = useState<number | null>(null)
  const [interviewHoverIndex, setInterviewHoverIndex] = useState<number | null>(null)
  const [bUhoveredIndex, setBUHoveredIndex] = useState<number | null>(null)

  const [activeAccordion, setActiveAccordion] = useState<number | null>(0)

  const handleAccordionClick = (index: any) => {
    setActiveAccordion(index === activeAccordion ? null : index)
  }

  const [addedData, setAddedData]: any = useState({
    designations: [],
    job_titles: [],
    interview_types: [],
  })

  interface CheckedTypes {
    business_units: boolean
    designations: boolean
    job_titles: boolean
    interview_types: boolean
  }

  const [checkedSections, setCheckedSections] = useState<CheckedTypes>({
    business_units: false,
    designations: false,
    job_titles: false,
    interview_types: false,
  })

  const [skippedSections, setSkippedSections] = useState<CheckedTypes>({
    business_units: false,
    designations: false,
    job_titles: false,
    interview_types: false,
  })

  const handleAccordianIndex = (type: string | null) => {
    switch (type) {
      case 'business_units':
        setActiveAccordion(1)
        return
      case 'job_titles':
        setActiveAccordion(2)
        return
      case 'designations':
        setActiveAccordion(3)
        return
      default:
        setActiveAccordion(null)
        return
    }
  }

  const getProperMsg = (type: string): string => {
    switch (type) {
      case 'business_units':
        return 'Business Units'
      case 'job_titles':
        return 'Job Titles'
      case 'designations':
        return 'Designations'
      case 'interview_types':
        return 'Interview Types'
      default:
        return type
    }
  }

  const handleBulkDataAdd = async (
    record_type: string,
    records: string[],
    updateFunc: Dispatch<SetStateAction<string[]>>
  ) => {
    if (!records.length) {
      return UseToast(
        `Please enter the ${getProperMsg(record_type)} information to add`,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: true,
        },
        'warn'
      )
    }
    const formData = {
      pod_id: pod_id,
      pod_code: pod_code,
      record_type: record_type,
      records: records?.join(','),
    }
    try {
      const url = '/pod/bulk-add-data'
      const response = await execute(url, 'POST', formData)

      if (record_type !== 'business_units') {
        setAddedData((prevData: any) => ({
          ...prevData,
          [record_type]: [...prevData[record_type], ...records],
        }))
      }

      updateFunc([])
      setCheckedSections((prev: any) => ({
        ...prev,
        [record_type]: true,
      }))
      handleAccordianIndex(record_type)
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
    } catch (error: any) {
      console.error('Error:', error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }

  const [editIndex, setEditIndex] = useState<number | null>(null) // State to track which item is being edited
  const [editedTag, setEditedTag] = useState('')

  const handleEdit = (index: number) => {
    setEditIndex(index)
    setEditedTag(interviewTags[index])
  }

  return (
    <>
      <div className='alert alert-success d-flex align-items-center p-2'>
        <KTIcon iconName='check-circle' className='fs-2 text-success me-1' />
        <div className='d-flex flex-column'>
          <p className='mb-0 fw-semibold text-dark'>
            Congratulations. Your POD has been created successfully. Your POD ID {pod_code}
          </p>
        </div>
      </div>

      <div className='d-none d-flex flex-row justify-content-between align-items-center fs-6 fw-semibold form-label mb-2'>
        <label>
          <span className=''>Quick Create Job Titles</span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='The "Quick Create Job Titles" feature allows you to easily add new job titles to the system. You can choose from suggested job titles or create a custom one.'
          ></i>
        </label>
        <button
          className='btn btn-light-primary btn-sm'
          onClick={() => handleBulkDataAdd('job_titles', tags, setTags)}
        >
          <KTIcon iconName='check' className='fs-3' />
          Save
        </button>
      </div>
      <Accordion
        title='Quick Create Business Units'
        isActive={activeAccordion === 0}
        onClick={() => handleAccordionClick(0)}
        checkedColor={checkedSections.business_units === true}
        skippedColor={skippedSections.business_units === true}
      >
        <div className='p-2'>
          <div
            className={`form-control form-control-solid d-flex p-${bUTags.length > 0 ? '1' : '3'}`}
          >
            <ul id='tags' className='list-unstyled d-flex flex-wrap flex-grow-1 m-0'>
              {bUTags.map((tag, index) => (
                <li
                  key={index}
                  className='card zo-om-effect tag badge me-2 my-1 p-2 d-flex flex-row'
                  style={{
                    backgroundColor: index === bUhoveredIndex ? 'rgba(255, 0, 0, 0.1)' : '', // Change background color if hovered
                  }}
                >
                  <span className='fw-semibold me-2'>{tag}</span>
                  <button
                    className='btn btn-unstyled p-0 cursor-pointer'
                    onMouseEnter={() => setBUHoveredIndex(index)} // Set hoveredIndex on mouse enter
                    onMouseLeave={() => setBUHoveredIndex(null)} // Reset hoveredIndex on mouse leave
                    onClick={() => {
                      removeBUTags(index)
                      setBUHoveredIndex(null)
                    }}
                  >
                    <KTIcon iconName='cross' className='fs-3' />
                  </button>
                </li>
              ))}
              <input
                type='text'
                className='flex-grow-1 my-1'
                style={{background: 'transparent', border: '0px', outline: 'none'}}
                onKeyUp={(event) => (event.key === 'Enter' ? addBUTags(event) : null)}
                onBlur={addBUTags}
                placeholder='Enter the name(s) of the business unit(s) to be created, separated by a comma.'
              />
            </ul>
          </div>

          <div className='d-flex justify-content-end mt-2'>
            <button
              className='btn btn-light-primary btn-sm'
              onClick={() => {
                handleAccordionClick(1)
                setSkippedSections((prev: any) => ({
                  ...prev,
                  business_units: true,
                }))
              }}
            >
              Skip
            </button>
            <button
              className='btn btn-light-success btn-sm ms-2'
              onClick={() => handleBulkDataAdd('business_units', bUTags, setBUTags)}
              disabled={postLoading}
            >
              {postLoading ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : (
                <>
                  <KTIcon iconName='check' className='fs-3' />
                  Save
                </>
              )}
            </button>
          </div>
        </div>
      </Accordion>
      <Accordion
        title='Quick Create Job Titles'
        isActive={activeAccordion === 1}
        onClick={() => handleAccordionClick(1)}
        checkedColor={checkedSections.job_titles === true}
        skippedColor={skippedSections.job_titles === true}
      >
        <div className='p-2'>
          <div
            className={`form-control form-control-solid d-flex p-${tags.length > 0 ? '1' : '3'}`}
          >
            <ul id='tags' className='list-unstyled d-flex flex-wrap flex-grow-1 m-0'>
              {tags.map((tag, index) => (
                <li
                  key={index}
                  className='card zo-om-effect tag badge me-2 my-1 p-2 d-flex flex-row'
                  style={{
                    backgroundColor: index === hoveredIndex ? 'rgba(255, 0, 0, 0.1)' : '', // Change background color if hovered
                  }}
                >
                  <span className='fw-semibold me-2'>{tag}</span>
                  <button
                    className='btn btn-unstyled p-0 cursor-pointer'
                    onMouseEnter={() => setHoveredIndex(index)} // Set hoveredIndex on mouse enter
                    onMouseLeave={() => setHoveredIndex(null)} // Reset hoveredIndex on mouse leave
                    onClick={() => {
                      removeTags(index)
                      setHoveredIndex(null)
                    }}
                  >
                    <KTIcon iconName='cross' className='fs-3' />
                  </button>
                </li>
              ))}
              <input
                type='text'
                className='flex-grow-1 my-1'
                style={{background: 'transparent', border: '0px', outline: 'none'}}
                onKeyUp={(event) => (event.key === 'Enter' ? addTags(event) : null)}
                onBlur={addTags}
                placeholder='Enter the name(s) of the job title(s) to be created, separated by a comma.'
              />
            </ul>
          </div>
          <div className='mt-2'>
            {(job_titles?.split(',') || []).map((role: string) => (
              <label
                key={role}
                className={`form-check-label me-3 mb-2 ${
                  addedData.job_titles?.includes(role) === true
                    ? 'text-decoration-line-through'
                    : ''
                }`}
              >
                <input
                  type='checkbox'
                  className='form-check-input me-1'
                  style={{height: '18px', width: '18px'}}
                  disabled={addedData.job_titles?.includes(role) === true}
                  value={role}
                  checked={tags.includes(role) || addedData.job_titles?.includes(role) === true}
                  onChange={(e: any) => {
                    if (tags.includes(role)) {
                      const data = tags.filter((each: string) => each !== role)
                      setTags(data)
                    } else {
                      setTags([...tags, e.target.value])
                    }
                  }}
                />
                {role}
              </label>
            ))}
          </div>
          <div className='d-flex justify-content-end'>
            <button
              className='btn btn-light-primary btn-sm'
              onClick={() => {
                handleAccordionClick(2)
                setSkippedSections((prev: any) => ({
                  ...prev,
                  job_titles: true,
                }))
              }}
            >
              Skip
            </button>
            <button
              className='btn btn-light-success btn-sm ms-2'
              onClick={() => handleBulkDataAdd('job_titles', tags, setTags)}
              disabled={postLoading}
            >
              {postLoading ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : (
                <>
                  <KTIcon iconName='check' className='fs-3' />
                  Save
                </>
              )}
            </button>
          </div>
        </div>
      </Accordion>
      <Accordion
        title='Quick Create Designations'
        isActive={activeAccordion === 2}
        onClick={() => handleAccordionClick(2)}
        checkedColor={checkedSections.designations === true}
        skippedColor={skippedSections.designations === true}
      >
        <div className='p-2'>
          <div
            className={`form-control form-control-solid d-flex p-${
              designationTags.length > 0 ? '1' : '3'
            }`}
          >
            <ul id='tags' className='list-unstyled d-flex flex-wrap flex-grow-1 m-0'>
              {designationTags.map((tag, index) => (
                <li
                  key={index}
                  className='card zo-om-effect tag badge me-2 my-1 p-2 d-flex flex-row'
                  style={{
                    backgroundColor: index === designationHoverIndex ? 'rgba(255, 0, 0, 0.1)' : '', // Change background color if hovered
                  }}
                >
                  <span className='fw-semibold me-3'>{tag}</span>
                  <button
                    className='btn btn-unstyled p-0 cursor-pointer'
                    onMouseEnter={() => setDesignationHoverIndex(index)} // Set hoveredIndex on mouse enter
                    onMouseLeave={() => setDesignationHoverIndex(null)} // Reset hoveredIndex on mouse leave
                    onClick={() => {
                      removeDesignationTags(index)
                      setDesignationHoverIndex(null)
                    }}
                  >
                    <KTIcon iconName='cross' className='fs-3' />
                  </button>
                </li>
              ))}
              <input
                type='text'
                className='flex-grow-1'
                style={{background: 'transparent', border: '0px', outline: 'none'}}
                onKeyUp={(event) => (event.key === 'Enter' ? addDesignationTags(event) : null)}
                onBlur={addDesignationTags}
                placeholder='Enter the name(s) of the designation(s) to be created, separated by a comma.'
              />
            </ul>
          </div>
          <div className='mt-2'>
            {(designations?.split(',') || []).map((role: string) => (
              <label
                key={role}
                className={`form-check-label me-3 mb-2 ${
                  addedData.designations?.includes(role) === true
                    ? 'text-decoration-line-through'
                    : ''
                }`}
              >
                <input
                  type='checkbox'
                  className={`form-check-input me-1`}
                  style={{height: '18px', width: '18px'}}
                  disabled={addedData.designations?.includes(role) === true}
                  value={role}
                  checked={
                    designationTags.includes(role) ||
                    addedData.designations?.includes(role) === true
                  }
                  onChange={(e: any) => {
                    if (designationTags.includes(role)) {
                      const data = designationTags.filter((each: string) => each !== role)
                      setDesignationTags(data)
                    } else {
                      setDesignationTags([...designationTags, e.target.value])
                    }
                  }}
                />
                {role}
              </label>
            ))}
          </div>
          <div className='d-flex justify-content-end'>
            <button
              className='btn btn-light-primary btn-sm'
              onClick={() => {
                handleAccordionClick(3)
                setSkippedSections((prev: any) => ({
                  ...prev,
                  designations: true,
                }))
              }}
            >
              Skip
            </button>
            <button
              className='btn btn-light-success btn-sm ms-2'
              onClick={() => handleBulkDataAdd('designations', designationTags, setDesignationTags)}
              disabled={postLoading}
            >
              {postLoading ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : (
                <>
                  <KTIcon iconName='check' className='fs-3' />
                  Save
                </>
              )}
            </button>
          </div>
        </div>
      </Accordion>
      <Accordion
        title='Quick Create Interview Types'
        isActive={activeAccordion === 3}
        onClick={() => handleAccordionClick(3)}
        checkedColor={checkedSections.interview_types === true}
        skippedColor={skippedSections.interview_types === true}
      >
        <div className='p-2'>
          <div
            className={`form-control form-control-solid d-flex p-${
              interviewTags.length > 0 ? '1' : '3'
            }`}
          >
            <ul id='tags' className='list-unstyled d-flex flex-wrap flex-grow-1 m-0'>
              {interviewTags.map((tag, index) => (
                <li
                  key={index}
                  className={`${
                    index !== interviewHoverIndex && 'card'
                  } zoom-effect tag badge me-2 my-1 p-2 d-flex flex-row`}
                  style={{
                    backgroundColor: index === interviewHoverIndex ? 'rgba(255, 0, 0, 0.1)' : '',
                    maxWidth: '250px',
                  }}
                  onDoubleClick={() => handleEdit(index)}
                >
                  <span
                    className='fs-7 fw-semibold me-3 overflow-hidden'
                    data-bs-toggle='tooltip'
                    title={tag}
                  >
                    {tag}
                  </span>
                  <button
                    className='btn btn-unstyled p-0 cursor-pointer'
                    onMouseEnter={() => setInterviewHoverIndex(index)}
                    onMouseLeave={() => setInterviewHoverIndex(null)}
                    onClick={() => {
                      removeInterviewTags(index)
                      setInterviewHoverIndex(null)
                    }}
                  >
                    <KTIcon iconName='cross' className='fs-3' />
                  </button>
                </li>
              ))}
              <input
                type='text'
                className='flex-grow-1'
                style={{background: 'transparent', border: '0px', outline: 'none'}}
                onKeyUp={(event) => (event.key === 'Enter' ? addInterviewTags(event) : null)}
                onBlur={addInterviewTags}
                placeholder='Enter the name(s) of the interview type(s) to be created, separated by a comma.'
              />
            </ul>
          </div>
          <div className='mt-2'>
            {(interview_types?.split(',') || []).map((role: string) => (
              <label
                key={role}
                className={`form-check-label me-3 mb-2 ${
                  addedData.interview_types?.includes(role) === true
                    ? 'text-decoration-line-through'
                    : ''
                }`}
              >
                <input
                  type='checkbox'
                  className='form-check-input me-1'
                  style={{height: '18px', width: '18px'}}
                  value={role}
                  disabled={addedData.interview_types?.includes(role)}
                  checked={
                    interviewTags.includes(role) || addedData.interview_types?.includes(role)
                  }
                  onChange={(e: any) => {
                    if (interviewTags.includes(role)) {
                      const data = interviewTags.filter((each: string) => each !== role)
                      setInterviewTags(data)
                    } else {
                      setInterviewTags([...interviewTags, e.target.value])
                    }
                  }}
                />
                {role}
              </label>
            ))}
          </div>
          <div className='d-flex justify-content-end'>
            <button
              className='btn btn-light-primary btn-sm'
              onClick={() => {
                handleAccordionClick(null)
                setSkippedSections((prev: any) => ({
                  ...prev,
                  interview_types: true,
                }))
              }}
            >
              Skip
            </button>
            <button
              className='btn btn-light-success btn-sm ms-2'
              onClick={() => handleBulkDataAdd('interview_types', interviewTags, setInterviewTags)}
              disabled={postLoading}
            >
              {postLoading ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : (
                <>
                  <KTIcon iconName='check' className='fs-3' />
                  Save
                </>
              )}
            </button>
          </div>
        </div>
      </Accordion>

      <div className='alert alert-primary d-flex align-items-center p-1 mt-5'>
        <KTIcon iconName='information' className='fs-2 text-primary me-2' />
        <div className='d-flex flex-column'>
          <h6 className='mb-0 fw-semibold fs-7 text-dark'>Note:</h6>
          <span className='fs-8 text-dark'>
            You can add departments, locations, and more from the administration panel.{' '}
            <a href={`/${pod_code}/configuration/master-data`}>Click here</a>
          </span>
        </div>
      </div>

      <div className='d-flex justify-content-end'>
        <button className='btn btn-light-primary btn-sm' onClick={() => closeModal()}>
          Finish
        </button>
      </div>
    </>
  )
}

export default CustomWizardNew
