import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import clsx from 'clsx'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import './index.css'
import usePostApi from '../../../custom_hooks/usePostApi'
import {FindPodIdByCode} from '../../../FindPodIdByPodCode'
import {KTIcon} from '../../../../../_metronic/helpers'
import {UseToast} from '../../../useToast'
import {useThemeMode} from '../../../../../_metronic/partials'
import EditMap from './EditMap'
function EditLocation(props: any) {
  const [currentStep, setCurrentStep] = useState(0)
  const steps = [<Step1 props={props} />]
  return (
    <Modal
      show={props.showModal}
      onHide={() => props.setShowModal(false)}
      centered
      dialogClassName='custom-modal modal-dialog-centered'
      contentClassName='custom-modal-content'
      style={{backgroundColor: 'rgba(0, 0, 0, 0.3)'}}
    >
      <Modal.Body className='overflow-auto w-100'>
        <div className='card py-0 overflow-auto'>
          <div className='w-100 m-auto'>
            <div className='d-flex justify-content-between mb-10'>
              <h4 className='fw-semibold'>Edit Location</h4>
              <button
                type='button'
                className='d-block btn btn-sm btn-icon btn-light'
                onClick={() => {
                  props.setShowModal(false)
                  setCurrentStep(0)
                }}
              >
                <KTIcon iconName='cross' className='fs-1' />
              </button>
            </div>
            <div className='w-100 row m-0'>
              <div className='col-lg-4 p-0 mb-5 mb-lg-0'>
                <div className=''>
                  <div className='d-flex'>
                    <div
                      style={{borderRadius: '10px'}}
                      className={clsx(
                        'w-40px h-40px btn d-flex justify-content-center align-items-center',
                        {
                          'btn-primary': currentStep === 0,
                          'btn-light-primary': currentStep !== 0,
                        }
                      )}
                    >
                      {currentStep === 0 ? 1 : <i className='stepper-check fas fa-check'></i>}
                    </div>
                    <div className='ms-5'>
                      <h6
                        className={clsx('mb-0 fw-semibold', {
                          'text-muted': currentStep >= 0,
                        })}
                      >
                        Step 1
                      </h6>
                      <p
                        className={clsx('fs-6 mb-0', {
                          'text-muted': currentStep >= 0,
                        })}
                      >
                        About Location
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-8 p-0 m-0'>{steps[currentStep]}</div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const registrationSchema = Yup.object().shape({
  location_name: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Location Name is required'),
  location_code: Yup.string()
    .max(50, 'Maximum 50 characters')
    .required('Location Code is required'),
})
const Step1 = ({props}: any) => {
  const {locationData, fetchData, closeModal}: any = props
  const {execute, postLoading} = usePostApi()
  const [error, setError] = useState(true)
  const podId = FindPodIdByCode()
  const {mode} = useThemeMode()
  const [status, setStatus] = useState(locationData.status === 1)
  const [pincode, setPincode] = useState(locationData.postal_code || '')
  const [latitude, setLatitude] = useState(locationData.latitude || '')
  const [longitude, setLongitude] = useState(locationData.longitude || '')
  const [city, setCity] = useState(locationData.city || '')
  const [countryCode, setCountryCode] = useState(locationData?.country || '')
  const [country, setCountry] = useState('')
  const [state, setState] = useState(locationData.state || '')
  const [text, setText] = useState(locationData.place || '')
  const onSubmit = async (values: Partial<any>, {setFieldError}: any) => {
    if (!countryCode) {
      setFieldError('map', 'Location address is required')
      return
    }
    const formattedAddress = [text, pincode, city, state, country].filter(Boolean).join(', ')
    const url = `/pod/locations/update`
    const requestData = {
      pod_id: podId,
      location_id: locationData?.location_id,
      location_name: values.location_name,
      location_code: values.location_code,
      place: text,
      postal_code: pincode,
      country: countryCode,
      state: state ? state : null,
      address: formattedAddress,
      city: city ? city : null,
      latitude: `${latitude}`,
      longitude: `${longitude}`,
      status: status ? 1 : 0,
    }
    try {
      const response = await execute(url, 'POST', requestData, 'post')
      fetchData()
      closeModal()
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
    } catch (error: any) {
      console.error(error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }

  const updateItemId = (address: any) => {
    const countryCode =
      address.context?.find((contextItem: any) => contextItem.id.startsWith('country.'))
        ?.short_code || ''
    const country =
      address.context?.find((contextItem: any) => contextItem.id.startsWith('country.'))?.text || ''
    const state =
      address.context?.find((contextItem: any) => contextItem.id.startsWith('region.'))?.text || ''
    const city =
      address.context?.find((contextItem: any) => contextItem.id.startsWith('place.'))?.text || ''
    const district =
      address.context?.find((contextItem: any) => contextItem.id.startsWith('district.'))?.text ||
      ''
    const locality =
      address.context?.find((contextItem: any) => contextItem.id.startsWith('locality.'))?.text ||
      ''
    const postcode =
      address.context?.find((contextItem: any) => contextItem.id.startsWith('postcode.'))?.text ||
      ''
    const formattedPostcode = [locality, district, city].filter(Boolean).join(', ')
    setCountryCode(countryCode)
    setCountry(country)
    setError(false)
    setState(state)
    setCity(formattedPostcode)
    setPincode(postcode)
    setLatitude(address.center[1])
    setLongitude(address.center[0])
    setText(address.text)
  }
  useEffect(() => {
    if (locationData?.address) {
      const addressParts = locationData.address.split(',')
      const lastPart = addressParts[addressParts.length - 1].trim()
      setCountry(lastPart)
    }
  }, [locationData])
  return (
    <Formik
      validationSchema={registrationSchema}
      initialValues={{
        location_name: '' || locationData.location_name,
        location_code: '' || locationData.location_code,
        map: '' || countryCode,
      }}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({values, handleChange}) => {
        return (
          <Form className='w-100'>
            <div className='col-lg-12 d-flex flex-row col-12'>
              <div className='col-lg-6 col-6'>
                <div className='w-100 mb-5 pe-4'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Location Name</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Specify your Location Name'
                    ></i>
                  </label>
                  <Field
                    type='text'
                    name='location_name'
                    className='form-control form-control-solid'
                    placeholder='Location Name'
                  />
                  <ErrorMessage component='div' className='text-danger' name='location_name' />
                </div>
                <div className='w-100 mb-5 pe-4'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Location Code</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Specify your Location Code'
                    ></i>
                  </label>
                  <Field
                    type='text'
                    name='location_code'
                    className='form-control form-control-solid'
                    placeholder='Location Code'
                  />
                  <ErrorMessage component='div' className='text-danger' name='location_code' />
                </div>
              </div>
              <div className='col-lg-6 col-6'>
                <EditMap updateItemId={updateItemId} locationData={locationData} />
                {error && <ErrorMessage component='div' className='text-danger' name='map' />}
              </div>
            </div>
            <div className='col-lg-12 d-flex flex-row'>
              <div className='col-lg-6 col-6'>
                <div className='w-100 mb-5 pe-4'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    Address Line 1
                  </label>
                  <Field
                    type='text'
                    name='text'
                    value={text}
                    onChange={(e: any) => setText(e.target.value)}
                    className='form-control form-control-solid'
                    placeholder='Address Line 1'
                  />
                </div>
                <div className='w-100 mb-5 pe-4'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    State/Region
                  </label>
                  <Field
                    type='text'
                    value={state}
                    className='form-control form-control-solid'
                    placeholder='Select from map'
                    disabled
                  />
                </div>
                <div className='w-100 pe-4'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    Zip/Pincode
                  </label>
                  <Field
                    type='text'
                    value={pincode}
                    className='form-control form-control-solid'
                    placeholder='Select from map'
                    disabled
                  />
                </div>
              </div>
              <div className='col-lg-6 col-6'>
                <div className='w-100 mb-5'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    Address
                  </label>
                  <Field
                    type='text'
                    value={city}
                    className='form-control form-control-solid'
                    placeholder='Select from map'
                    disabled
                  />
                </div>
                <div className='w-100 mb-5'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 required'>
                    Country
                  </label>
                  <Field
                    type='text'
                    value={country}
                    className='form-control form-control-solid'
                    placeholder='Select from map'
                    disabled
                  />
                </div>
                <div className='w-100'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    Status
                  </label>
                  <div className='form-check form-check-solid form-switch'>
                    <input
                      className='form-check-input w-40px h-20px'
                      type='checkbox'
                      name='status'
                      id='toggle'
                      checked={status}
                      style={{
                        backgroundColor: status
                          ? '#009688'
                          : mode === 'dark'
                          ? '#1b1b29'
                          : '#f4f4f4',
                      }}
                      onChange={(e) => {
                        const isChecked = e.target.checked
                        setStatus(isChecked)
                      }}
                    />
                    <span className={`me-4 fw-semibold ${status ? 'text-success' : 'text-danger'}`}>
                      {status ? 'Active' : 'Inactive'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-end'>
              <button type='submit' className='btn btn-sm btn-light-primary'>
                {postLoading ? (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                ) : (
                  <>
                    <KTIcon iconName='check' className='fs-7' />
                    Save
                  </>
                )}
              </button>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default EditLocation
