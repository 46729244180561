import {useMemo, useState, useEffect} from 'react'
import {Pagination} from 'react-bootstrap'
import {useIntl, FormattedMessage} from 'react-intl'
import {KTIcon} from '../../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import {useTable, usePagination} from 'react-table'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import {GetAllInterviews} from './InterviewsAPI'
import InterviewsknowmoreModal from './Interviewsknowmore/InterviewknowmoreModal'
import ScheduleInterviewModal from './schedule_interview/ScheduleInterviewModal'
import {EditInterviewPopover} from './EditInterviewPopover'
import usePostApi from '../../custom_hooks/usePostApi'
import {FindPodIdByCode} from '../../FindPodIdByPodCode'
import DateFormatter from '../../DateFormatter'
import Interviewsknowmore from './Interviewsknowmore/Interviewknowmore'
import {UseToast} from '../../useToast'
import usePageTitle from '../../custom_hooks/usePageTitle'
const DataTable = ({columns, data, fetchData, loading, refresh}) => {
  const intl = useIntl()
  const {id} = useParams()
  const [showModal, setShowModal] = useState(false)
  const [addModal, setAddModal] = useState(false)
  const [showKM, setShowKM] = useState(false)
  const todayDate = useMemo(() => new Date().toISOString().slice(0, 10), [])
  usePageTitle('Interviews')
  const [inputVal, setInputVal] = useState('')
  const [currentStatus, setCurrentStatus] = useState('Scheduled')

  const filteredData = useMemo(() => {
    return data
      ? data.filter((each) => {
          const nameMatches =
            each.candidate_first_name &&
            each.candidate_first_name.toLowerCase().includes(inputVal.toLowerCase())
          const currentStatusMatches =
            currentStatus === 'Completed'
              ? each.interview_date <= todayDate
              : each.interview_date > todayDate

          return nameMatches && currentStatusMatches
        })
      : []
  }, [inputVal, data, currentStatus, todayDate])

  const tableInstance = useTable({columns, data: filteredData}, usePagination)
  const {
    getTableProps,
    getTableBodyProps,

    prepareRow,
    page,
    state: {pageIndex, pageSize},
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    gotoPage,
    setPageSize,
  } = tableInstance

  const pageOptions = [10, 20, 30, 40, 50]

  return (
    <>
      <InterviewsknowmoreModal showModal={showKM} setShowModal={setShowKM} />
      <>
        {loading && refresh === false ? (
          <div className='card h-250px'>
            <div className='m-auto d-flex flex-column align-items-center'>
              <div className='spinner-border spinner-primary mr-15'></div>
            </div>
          </div>
        ) : data.length > 0 ? (
          <>
            <div className='d-flex flex-wrap flex-stack '>
              <div className='d-flex'>
                <h1 className='fw-semibold fs-3'>Manage Interviews</h1>
              </div>

              <div className='d-flex'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <button className='btn btn-sm btn-light' onClick={() => setShowKM(true)}>
                    <i className='ki-duotone ki-information-3 fs-3'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                    </i>
                    Know More
                  </button>
                </div>
              </div>
            </div>
            <p className='fw-normal fs-6 text-gray-600 mb-4'>
              Seamlessly create and track interviews, ensuring no details are overlooked during the
              hiring process.
            </p>
            {refresh ? (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <div className='spinner-border spinner-primary mr-15'></div>
                </div>
              </div>
            ) : (
              <>
                <div className='card py-2 px-10 mb-5'>
                  <div className='card-header border-0 px-0'>
                    <h3 className='card-title align-items-start flex-column'>
                      <ul className='nav'>
                        <li className='nav-item'>
                          <a
                            className='nav-link btn btn-sm btn-color-muted btn-active  btn-active-light-success active fw-normal px-4 me-1'
                            data-bs-toggle='tab'
                            onClick={() => setCurrentStatus('Scheduled')}
                          >
                            Scheduled
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a
                            className='nav-link btn btn-sm btn-color-muted btn-active  btn-active-light-danger fw-normal px-4 me-1'
                            data-bs-toggle='tab'
                            onClick={() => setCurrentStatus('Completed')}
                          >
                            Completed
                          </a>
                        </li>
                      </ul>
                    </h3>

                    <div className='d-flex'>
                      <div className='d-flex align-items-center position-relative me-4'>
                        <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                        <input
                          type='search'
                          id='kt_filter_search'
                          className='form-control form-control-white form-control-sm w-150px ps-9'
                          placeholder='Search'
                          value={inputVal}
                          onChange={(e) => setInputVal(e.target.value)}
                        />
                      </div>
                      <div
                        className='d-flex justify-content-center align-items-center'
                        data-toggle='tooltip'
                        title='Add New Interview'
                      >
                        <button
                          className='btn btn-sm btn-light-primary me-1'
                          id='side_activities_toggle'
                          onClick={() => setAddModal(true)}
                        >
                          <KTIcon iconName='plus' className='fs-3' />
                          Schedule Interview
                        </button>
                      </div>

                      <div
                        className='d-flex justify-content-center align-items-center'
                        data-toggle='tooltip'
                      >
                        <button
                          className='btn btn-sm btn-light-primary'
                          onClick={() => fetchData(true)}
                          data-bs-toggle='tooltip'
                          title='Click to load the latest data.'
                        >
                          <i className='ki-solid ki-arrows-circle fs-2'></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className='mb-0 mb-xl-8 '>
                    <div className='overflow-auto'>
                      <table
                        className='table table-row-dashed table-row-gray-300 align-middle'
                        {...getTableProps()}
                      >
                        <thead>
                          <tr className='fw-semibold text-muted bg-light rounded'>
                            <th className='min-w-150px default-cursor text-start ps-3'>
                              Candidate Name
                            </th>

                            <th className='min-w-120px text-start default-cursor'>
                              Interview Round
                            </th>

                            <th className='min-w-140px text-start default-cursor'>
                              Interview Type
                            </th>
                            <th className='min-w-140px text-start default-cursor'>
                              Interviewer Name
                            </th>
                            {/* 
                            <th className='min-w-100px text-start default-cursor'>
                              Interview Mode
                            </th> */}
                            <th className='min-w-100px text-start default-cursor'>
                              Interview Date
                            </th>
                            <th className='min-w-100px text-start default-cursor'>
                              Interview Time
                            </th>

                            <th className='min-w-100px text-start default-cursor'>Status</th>
                            {/* <th className='min-w-100px text-start default-cursor'>Actions</th> */}
                          </tr>
                        </thead>
                        <tbody {...getTableBodyProps()}>
                          {page.length > 0 ? (
                            page.map((row) => {
                              prepareRow(row)
                              return (
                                <tr {...row.getRowProps()}>
                                  {row.cells.map((cell) => (
                                    <td className='text-center' {...cell.getCellProps()}>
                                      {cell.render('Cell')}
                                    </td>
                                  ))}
                                </tr>
                              )
                            })
                          ) : (
                            <tr>
                              <td className='' colSpan={8}>
                                <div className='d-flex justify-content-center'>
                                  <p className='text-dark default-cursor fs-6 mt-5'>
                                    {inputVal === '' ? (
                                      intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
                                    ) : (
                                      <FormattedMessage
                                        id='MSG.NO_DATA_MATCH'
                                        values={{
                                          inputVal: (
                                            <span className='text-primary'>{inputVal}</span>
                                          ),
                                        }}
                                      />
                                    )}
                                  </p>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {data.length > 10 && (
                  <Pagination>
                    <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                    <Pagination.Prev onClick={previousPage} disabled={!canPreviousPage} />

                    {[...Array(pageCount)].map((_, i) => (
                      <Pagination.Item key={i} active={i === pageIndex} onClick={() => gotoPage(i)}>
                        {i + 1}
                      </Pagination.Item>
                    ))}

                    <Pagination.Next onClick={nextPage} disabled={!canNextPage} />
                    <Pagination.Last
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                    />

                    <div className='d-flex align-items-center mx-5'>
                      <span className='me-2'>Show</span>
                      <select
                        className='form-select form-select-solid fw-semibold w-75px me-2'
                        value={pageSize}
                        onChange={(e) => setPageSize(Number(e.target.value))}
                      >
                        {pageOptions.map((option) => (
                          <option key={option} value={option} className='select-bg'>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Pagination>
                )}
              </>
            )}
            {/* <div className='my-5 ps-6 cursor-default d-flex flex-row align-items-center'>
              <h4 className='fs-7 fw-normal text-muted mt-1'>LEGEND:</h4>
              <div className='d-flex align-items-center mx-3'>
                <span className='me-2'>
                  <i className='ki-duotone ki-calendar-add fs-2'>
                    <span class='path1'></span>
                    <span class='path2'></span>
                    <span class='path3'></span>
                    <span class='path4'></span>
                    <span class='path5'></span>
                    <span class='path6'></span>
                  </i>
                </span>
                <span className='text-muted fs-7 mb-1'>Schedule Interview</span>
              </div>
              <span>|</span>

              <div className='d-flex align-items-center mx-3'>
                <span className='me-2'>
                  <KTIcon iconName='arrow-right' className='fs-2' />
                </span>
                <span className='text-muted fs-7 mb-1'>
                  Move to Different Section(Interviews and Offers)
                </span>
              </div>
            </div> */}
          </>
        ) : (
          <>
            <>
              <div className='d-flex flex-column'>
                <h3 className='fs-3 fw-semibold'>Manage Interviews</h3>

                <p className=''>
                  Welcome to the Interviews Page! Here, you have the tools to seamlessly schedule
                  and track interviews, ensuring a smooth transition into your organization.
                </p>
                <Interviewsknowmore />
                <div
                  className='d-flex justify-content-center align-items-center'
                  data-toggle='tooltip'
                  title='Add New Interview'
                >
                  <button
                    className='btn btn-sm btn-light-primary'
                    onClick={() => setAddModal(true)}
                  >
                    <KTIcon iconName='plus' className='fs-3' />
                    Schedule Interview
                  </button>
                </div>
              </div>
            </>
          </>
        )}
        <ScheduleInterviewModal
          showModal={addModal}
          setShowModal={setAddModal}
          fetchInterviewsData={fetchData}
        />
      </>
    </>
  )
}

const InterviewsTable = () => {
  // const {interviewsData, isinterviewsLoading: isLoading, fetchData} = GetAllInterviews()
  const [interviewsData, setcandidatesData] = useState([])
  const {execute, loading: isLoading} = usePostApi()
  const podId = FindPodIdByCode()
  const [refresh, setRefresh] = useState(false)
  const fetchData = async (show) => {
    if (show) {
      setRefresh(true)
    }
    try {
      const url = `/pod/interview/list`
      const formData = {
        pod_id: podId,
      }

      const response = await execute(url, 'POST', formData)
      setcandidatesData(response.data)
      console.log(response.data)
      setRefresh(false)
      if (show) {
        return UseToast(
          response.status_message,
          {
            position: 'bottom-right',
            theme: 'colored',
            autoClose: true,
          },
          'success'
        )
      }
    } catch (error) {
      console.error('Error:', error)
      setRefresh(false)
      if (show) {
        return UseToast(
          error.response?.data?.status_message,
          {
            position: 'bottom-right',
            theme: 'colored',
            autoClose: true,
          },
          'error'
        )
      }
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const columns = useMemo(() => [
    {
      Header: 'Position',
      accessor: 'Request Type',
      Cell: ({row}) => {
        return (
          <div className='text-start ms-3'>
            <p className='text-dark fw-semibold text-hover-primary d-block fs-6 default-cursor'>
              {row.original.candidate_first_name} {row.original.candidate_last_name}
            </p>
          </div>
        )
      },
    },
    {
      Header: 'Country Name',
      accessor: 'Country Name',
      Cell: ({row}) => {
        return (
          <div className='text-start'>
            <p className='text-dark fw-normal text-hover-primary d-block fs-6 default-cursor'>
              {row.original.interview_round}
            </p>
          </div>
        )
      },
      disableSortBy: true,
    },

    {
      Header: 'Department Name',
      accessor: 'Department Name',
      Cell: ({row}) => {
        return (
          <div className='text-start'>
            <p className='text-dark fw-normal text-hover-primary d-block fs-6 default-cursor'>
              {row.original.interview_type_name}
            </p>
          </div>
        )
      },
      disableSortBy: true,
    },

    {
      Header: 'HiringManager Name',
      accessor: 'HiringManager Name',
      Cell: ({row}) => {
        return (
          <div className='text-start'>
            <p className='text-dark fw-normal text-hover-primary d-block fs-6 default-cursor'>
              {row.original.interviewer_name}
            </p>
          </div>
        )
      },
      disableSortBy: true,
    },

    // {
    //   Header: 'State Name',
    //   accessor: 'State Name',
    //   Cell: ({row}) => {
    //     return (
    //       <div className='text-start'>
    //         <p className='text-dark fw-normal text-hover-primary d-block fs-6 default-cursor'>
    //           {row.original.interview_coordinates.mode}
    //         </p>
    //       </div>
    //     )
    //   },
    //   disableSortBy: true,
    // },

    {
      Header: 'State Na',
      accessor: 'State Na',
      Cell: ({row}) => {
        return (
          <div className='text-start'>
            <p className='text-dark fw-normal text-hover-primary d-block fs-6 default-cursor'>
              {DateFormatter(row.original.interview_date)}
            </p>
          </div>
        )
      },
      disableSortBy: true,
    },

    {
      Header: 'Time',
      accessor: 'Time',
      Cell: ({row}) => {
        return (
          <div className='text-start'>
            <p className='text-dark fw-normal text-hover-primary d-block fs-6 default-cursor'>
              {row.original.interview_start_time?.slice(0, 5)} -{' '}
              {row.original.interview_end_time?.slice(0, 5)}
            </p>
          </div>
        )
      },
      disableSortBy: true,
    },

    {
      Header: 'State Nam',
      accessor: 'State Nam',
      Cell: ({row}) => {
        return (
          <div className='text-start'>
            <p className='text-dark fw-normal text-hover-primary d-block fs-6 default-cursor'>
              {row.original.status}
            </p>
          </div>
        )
      },
      disableSortBy: true,
    },

    // {
    //   Header: 'Actions',
    //   accessor: 'Actions',
    //   Cell: ({row}) => {
    //     const [id, updateId] = useState('')
    //     const [popover, setPopover] = useState(null)
    //     const ClosePopover = () => {
    //       setPopover(null)
    //     }
    //     const openPopover = (id) => (
    //       <Popover flip='true' placement='auto' className='min-w-400px'>
    //         <EditInterviewPopover props={id} />
    //       </Popover>
    //     )
    //     let interviewStatus = row.original.status
    //     return (
    //       <>
    //         <>
    //           <>
    //             {interviewStatus === 'Accepted' || interviewStatus === 'Rejected' ? (
    //               <div className='d-flex btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2'>
    //                 <KTIcon iconName='cross-circle' className='fs-1 text-danger' />
    //               </div>
    //             ) : (
    //               <div className='d-flex'>
    //                 <OverlayTrigger
    //                   trigger='click'
    //                   placement='auto'
    //                   overlay={openPopover(id)}
    //                   show={popover === 'update_interview_data'}
    //                 >
    //                   <div
    //                     className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2'
    //                     onClick={(e) => {
    //                       e.stopPropagation()
    //                       setPopover('update_interview_data')
    //                       updateId({
    //                         refreshData: fetchData,
    //                         ClosePopover,
    //                         id: row.original.interview_id,
    //                         status: row.original.status,
    //                         feedBack: row.original.interview_feedback,
    //                       })
    //                     }}
    //                   >
    //                     <KTIcon iconName='pencil' className='fs-3' />
    //                   </div>
    //                 </OverlayTrigger>
    //               </div>
    //             )}
    //           </>
    //         </>
    //       </>
    //     )
    //   },
    // },
  ])

  return (
    <DataTable
      columns={columns}
      data={interviewsData ? interviewsData : []}
      loading={isLoading}
      fetchData={fetchData}
      refresh={refresh}
    />
  )
}

export default InterviewsTable
