import React, {useState} from 'react'
import {useParams} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import MasterData from './master-data/MasterData'
import {PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import DesignationTypes from './master-data/designation_types/DesignationTypes'
import BusinessBlock from './master-data/business_Units/BusinessBlock'
import Context from '../side-drawer/context'
import Locations from './master-data/locations/Locations'
import DepartmentBlock from './master-data/departments/DepartmentBlock'
import JobRoles from './master-data/job_roles/JobRoles'
import JobTitles from './master-data/job_titles/JobTitles'
import InterviewTypes from './master-data/interview_types/InterviewTypes'
import Qualifications from './master-data/qualifications/Qualifications'
import EmploymentType from './master-data/employment_type/EmploymentType'
function MasterDataRouting() {
  const {id} = useParams()
  const intl = useIntl()
  const {pathname} = useLocation()
  const [SideDrawerId, updateId] = useState('0')
  const UpdatingId = (id) => {
    updateId(id)
  }
  const MasterDataTitle = [
    {
      title: `(${id})`,
      path: `/${id}/configuration/master-data`,
      isSeparator: false,
      isActive: true,
    },
    {
      title: '',
      path: `/${id}/configuration/master-data`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `Master Data`,
      path: `/${id}/configuration/master-data`,
      isSeparator: false,
      isActive: true,
    },
  ]
  const getCurrentComponent = () => {
    if (pathname.endsWith(`/master-data/designations`) === true) {
      return (
        <>
          <PageTitle breadcrumbs={MasterDataTitle}>
            {intl.formatMessage({id: 'MENU.HR_CONFIGURATION.DESIGNATIONS'})}
          </PageTitle>
          <DesignationTypes />
        </>
      )
    } else if (pathname.endsWith('/master-data/business-unit') === true) {
      return (
        <>
          <PageTitle breadcrumbs={MasterDataTitle}>Business Units</PageTitle>
          <BusinessBlock />
        </>
      )
    } else if (pathname.endsWith('/master-data/departments') === true) {
      return (
        <>
          <PageTitle breadcrumbs={MasterDataTitle}>Departments</PageTitle>
          <DepartmentBlock />
        </>
      )
    } else if (pathname.endsWith('/master-data/locations') === true) {
      return (
        <>
          <PageTitle breadcrumbs={MasterDataTitle}>Locations</PageTitle>
          <Locations />
        </>
      )
    } else if (pathname.endsWith('/master-data/job-roles') === true) {
      return (
        <>
          <PageTitle breadcrumbs={MasterDataTitle}>Job Roles</PageTitle>
          <JobRoles />
        </>
      )
    } else if (pathname.endsWith('/master-data/job-titles') === true) {
      return (
        <>
          <PageTitle breadcrumbs={MasterDataTitle}>Job Titles</PageTitle>
          <JobTitles />
        </>
      )
    } else if (pathname.endsWith('/master-data/interview-types') === true) {
      return (
        <>
          <PageTitle breadcrumbs={MasterDataTitle}>Interview Types</PageTitle>
          <InterviewTypes />
        </>
      )
    } else if (pathname.endsWith('/master-data/qualifications') === true) {
      return (
        <>
          <PageTitle breadcrumbs={MasterDataTitle}>Qualifications</PageTitle>
          <Qualifications />
        </>
      )
    } else if (pathname.endsWith('/master-data/employment-type') === true) {
      return (
        <>
          <PageTitle breadcrumbs={MasterDataTitle}>Employment Type</PageTitle>
          <EmploymentType />
        </>
      )
    } else
      return (
        <>
          <PageTitle breadcrumbs={MasterDataTitle}>Business Units</PageTitle>
          <BusinessBlock />
        </>
      )
  }
  return (
    <>
      <Context.Provider value={{id: SideDrawerId, updateId: UpdatingId}}>
        <div className='row g-5 g-xxl-8'>
          <div className='col-xl-3'>
            <div className='app-navbar-item'>
              <MasterData />
            </div>
          </div>
          <div className={'col-xl-9'}>{getCurrentComponent()}</div>
        </div>
      </Context.Provider>
    </>
  )
}

export default MasterDataRouting
