// BoardDragDropContext.js

import React from 'react'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import {KTIcon} from '../../../_metronic/helpers'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import PolicyBoard from './policy'

const BoardDragDropContext = ({
  boards,
  onDragEnd,
  children,
  editedIndex,
  deletePopoverId,
  setDeletePopoverId,
  handleDeleteTask,
  updatePoliciesData,
  handleEditTask,
  handleEditTitle,
  inputRef1,
  editedTitle,
  handleSaveTitle,
  handleTitleChange,
}) => {
  const policiesData = boards.filter((each) => each.id === '_policy&declarations')

  const deletePopover = (taskId) => (
    <Popover id={`delete-popover-${taskId}`} flip placement='auto'>
      <Popover.Header as='h3' className='fw-bold'>
        Confirm Delete
      </Popover.Header>
      <Popover.Body>
        <div className='w-100 d-flex justify-content-between'>
          <button
            className='btn btn-sm btn-danger me-2'
            onClick={() => {
              handleDeleteTask(taskId)
            }}
          >
            Yes
          </button>
          <button className='btn btn-sm btn-light ms-2' onClick={() => setDeletePopoverId(null)}>
            No
          </button>
        </div>
      </Popover.Body>
    </Popover>
  )

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='boards' direction='horizontal' type='BOARD'>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className='row flex-nowrap overflow-auto'
          >
            {boards.map((board, index) => {
              if (board.id === '_policy&declarations') {
                return
              }
              return (
                <Draggable key={board.id} draggableId={board.id} index={index} type='BOARD'>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className='col-lg-3 p-3 mb-5 p-0'
                    >
                      <div className='bg-light-secondary'>
                        <div className='kanban-board'>
                          <header className='kanban-board-header bg-secondary'>
                            <div className='kanban-title-board'>
                              {editedIndex === index ? (
                                <input
                                  ref={inputRef1}
                                  type='text'
                                  value={editedTitle}
                                  onBlur={() => handleSaveTitle(index)}
                                  onChange={handleTitleChange}
                                  className='form-control border-0'
                                  style={{
                                    background: 'transparent',
                                    textAlign: 'center',
                                    caretColor: 'black',
                                    cursor: 'default',
                                  }}
                                />
                              ) : (
                                <h5 className='fw-semibold'>{board.title}</h5>
                              )}
                            </div>

                            {editedIndex === index ? (
                              <span
                                onClick={() => handleSaveTitle(index)}
                                className='cursor-pointer'
                              >
                                <KTIcon iconName='check' className='fs-3' />
                              </span>
                            ) : (
                              <span
                                onClick={() => handleEditTitle(index)}
                                className='cursor-pointer'
                              >
                                <KTIcon iconName='pencil' className='fs-3' />
                              </span>
                            )}
                          </header>
                          <Droppable droppableId={board.id} type='TASK'>
                            {(provided) => (
                              <main
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className='kanban-drag bg-light-secondary h-350px overflow-auto'
                              >
                                {board.items.map((task, index) => (
                                  <Draggable
                                    key={task.id}
                                    draggableId={task.id}
                                    index={index}
                                    type='TASK'
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className='kanban-item card'
                                      >
                                        <div
                                          className='kanban-item-content fw-semibold d-flex align-items-center'
                                          style={{position: 'relative'}}
                                        >
                                          <span>
                                            <KTIcon
                                              iconName='star'
                                              className='fw-semibold d-block me-1 text-danger'
                                            />
                                          </span>
                                          <span className='d-block fw-normal'>
                                            Unique Code: {task.id}
                                          </span>
                                        </div>

                                        <div className='kanban-item-content fw-bold d-flex align-items-center'>
                                          <span>
                                            <KTIcon
                                              iconName='burger-menu-5'
                                              className='fw-semibold d-block me-1'
                                            />
                                          </span>
                                          <span className='d-block fw-semibold'>{task.name}</span>
                                        </div>
                                        <div className='row fw-semibold ms-2'>
                                          <label className='col-lg-6 text-muted'>Code</label>

                                          <div className='col-lg-6'>
                                            <span className='text-dark fw-normal text-hover-primary default-cursor'>
                                              {task.id}
                                            </span>
                                          </div>
                                        </div>
                                        <div className='row fw-semibold ms-2'>
                                          <label className='col-lg-6 text-muted'>Field Name</label>

                                          <div className='col-lg-6'>
                                            <span className='text-dark fw-normal text-hover-primary default-cursor'>
                                              {task.name}
                                            </span>
                                          </div>
                                        </div>
                                        <div className='row fw-semibold ms-2'>
                                          <label className='col-lg-6 text-muted'>Prompt</label>

                                          <div className='col-lg-6'>
                                            <span className='text-dark fw-normal text-hover-primary default-cursor'>
                                              {task.prompt}
                                            </span>
                                          </div>
                                        </div>
                                        <div className='row fw-semibold ms-2'>
                                          <label className='col-lg-6 text-muted'>Field Type</label>

                                          <div className='col-lg-6'>
                                            <span className='text-dark fw-normal text-hover-primary default-cursor'>
                                              {task.type}
                                            </span>
                                          </div>
                                        </div>
                                        {task.required && task.required === true ? null : (
                                          <OverlayTrigger
                                            trigger='click'
                                            placement='auto'
                                            overlay={deletePopover(task.id)}
                                            show={deletePopoverId === task.id}
                                          >
                                            <button
                                              style={{
                                                position: 'absolute',
                                                top: '0',
                                                right: '25px',
                                              }}
                                              className='btn btn-icon btn-sm btn-icon-danger'
                                              onClick={(e) => {
                                                e.stopPropagation()
                                                setDeletePopoverId(task.id)
                                              }}
                                            >
                                              <KTIcon iconName='trash' className='fs-5' />
                                            </button>
                                          </OverlayTrigger>
                                        )}
                                        <button
                                          style={{
                                            position: 'absolute',
                                            top: '0',
                                            right: '0',
                                          }}
                                          className='btn btn-icon btn-sm btn-icon-primary'
                                          onClick={() => handleEditTask(task)}
                                        >
                                          <KTIcon iconName='pencil' className='fs-5' />
                                        </button>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </main>
                            )}
                          </Droppable>
                          <footer></footer>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              )
            })}
            <PolicyBoard
              policiesData={policiesData[0] || {}}
              updatePoliciesData={updatePoliciesData}
            />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {children}
    </DragDropContext>
  )
}

export default BoardDragDropContext
