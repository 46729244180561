export const FormatDateToExperience = (dateString) => {
  const date = new Date(dateString)
  if (isNaN(date)) {
    return '-'
  }

  const today = new Date()
  const diffTime = Math.abs(today - date)
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))
  const years = Math.floor(diffDays / 365)
  const months = Math.floor((diffDays % 365) / 30)
  const days = diffDays % 30

  const formattedDate = []
  if (years > 0) {
    formattedDate.push(`${years}Y`)
  }
  if (months > 0) {
    formattedDate.push(`${months}M`)
  }
  if (days > 0) {
    formattedDate.push(`${days}D`)
  }

  return formattedDate.length > 0 ? formattedDate.join(' ') : '0D'
}

export const CheckExpiry = (expiryDate) => {
  const expiry = new Date(expiryDate)
  if (isNaN(expiry)) {
    return '-'
  }
  const today = new Date()
  if (expiry > today) {
    return 'Valid'
  } else {
    return 'Invalid'
  }
}

export const DateBasicFormat = (dateString) => {
  const date = new Date(dateString)
  if (isNaN(date)) {
    return '-'
  }
  const year = date.getFullYear()
  const month = `${date.getMonth() + 1}`.padStart(2, '0')
  const day = `${date.getDate()}`.padStart(2, '0')

  return `${year}-${month}-${day}`
}
