import {useState, useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'
import {KTIcon} from '../../../../../../_metronic/helpers'
import {PageTitle} from '../../../../../../_metronic/layout/core'
import usePostApi from '../../../../custom_hooks/usePostApi'
import DateFormatter from '../../../../DateFormatter'

function CandidateInterviews() {
  const {execute, loading} = usePostApi()
  const {id, candidate_id} = useParams()

  const [offersData, setoffersData] = useState({
    interviewer_id: ' ',
    interview_type_id: '',
    url: '',
    meeting_id: '',
    passcode: '',
    mode: '',

    status: '',

    address: '',
    contact_person: '',
    contact_number: '',
    interview_timestamp: '',
    interview_round: '',
    vacancy_id: '',
    interview_description: '',
  })
  const [offerData, setofferData] = useState<any>(null)
  const getoffers = async () => {
    try {
      const url = `/pod/interview/candidate`
      const formData = {
        candidate_id: candidate_id,
      }

      const response = await execute(url, 'POST', formData)

      setoffersData(response.data)
      setofferData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getoffers()
  }, [])
  const policyBC = [
    {
      title: `(${id})`,
      path: `/${id}/candidates`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: ``,
      path: `/${id}/candidates`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `Policies`,
      path: `/${id}/candidates`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: ``,
      path: `/${id}/candidates`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `${candidate_id}`,
      path: `/${id}/candidates/interviews/${candidate_id}`,
      isSeparator: false,
      isActive: false,
    },
  ]

  useEffect(() => {
    getoffers()
  }, [candidate_id])

  return (
    <>
      <PageTitle breadcrumbs={policyBC}>Interviews</PageTitle>
      {loading ? (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : offerData && offerData.length > 0 ? (
        <>
          <div className='d-none d-flex flex-wrap flex-stack mb-6'>
            <div>
              <h3 className='fw-semibold my-2'>Interviews</h3>
            </div>
            <div className='d-flex'>
              <Link
                className='btn btn-sm btn-light-primary ms-4 fw-normal'
                to={`/${id}/candidates`}
              >
                <KTIcon iconName='arrow-left' className='fs-3' />
                Back
              </Link>
            </div>
          </div>

          <div className='card timeline'>
            {Array.isArray(offersData) &&
              offersData
                .slice()
                .reverse()
                .map((each: any, index: number) => (
                  <div key={index} className='timeline-item'>
                    <div className='timeline-line w-20px dashed-line'></div>

                    <div className='timeline-icon symbol symbol-circle symbol-20px me-4'>
                      <div className={`symbol-label bg-secondary`}></div>
                    </div>

                    <div className='timeline-content mb-10 mt-n1'>
                      <div className='d-flex justify-content-between mt-1'>
                        <p className={`mb-0 fw-semibold text-dark default-cursor`}>
                          The {each.interview_round} {each.interview_type_name} interview has been
                          scheduled with interviewer {each.interviewer_name} on{' '}
                          {DateFormatter(each.interview_timestamp.slice(0, 10))} at{' '}
                          {DateFormatter(each.interview_timestamp.slice(11, 19))}. The interview
                          mode is {each.interview_coordinates.mode}.
                        </p>

                        {/* <p className={`mb-0 fw-semibold text-dark default-cursor`}>
                          {DateFormatter(offersData.date_of_joining)}

                       
                        </p> */}
                        <div className=''>
                          <p className='designation-btn bg-secondary fs-7 m-auto  default-cursor'>
                            {/* {new Date(each.action_ts).toLocaleString()} */}
                            {each.status}
                          </p>
                        </div>
                      </div>

                      {/* <p className='m-0 text-muted default-cursor'>
                        {DateFormatter(each.interview_timestamp.slice(0, 10))}
                      </p>

                      <p className={`mb-0 fw-semibold text-dark default-cursor`}>
                        {each.interview_round}
                      </p>
                      <p className={`mb-0 fw-semibold text-dark default-cursor`}>
                        {each.interviewer_name}
                      </p> */}
                    </div>
                  </div>
                ))}
          </div>
        </>
      ) : (
        <div className='card min-h-250px d-flex justify-content-center align-items-center'>
          <h3 className='fs-3 fw-normal'>Interviews history not found.</h3>
        </div>
      )}
    </>
  )
}

export default CandidateInterviews
