import React, {useContext, useState} from 'react'
import {EmploymentAndEducation} from './education_employment/EmploymentEducation'
import {KTIcon} from '../../../../../_metronic/helpers'
import _ from 'lodash'
import CandidateInfoContext from '../CandidateInfoContext'
import AddNewQualification from './education_employment/employment-education/AddQualification'
import AddCertification from './education_employment/employment-education/AddCertification'
import AddExperience from './education_employment/employment-education/AddExperience'

function EmpAndEdu({
  data,
  formValues,
  addQualification,
  editQualification,
  deleteQualification,
  setFormValues,
  updateIsFresher,
  updateData,
  candidateData,
  postLoading,
  structure,
  edit: editMode,
  closeEditSec,
  corrections,
  setCorrections,
}: any) {
  const {qualifications, certifications, isFresher, experiences, customFields} = data
  const {
    corrections: contextCorrections,
    setCorrections: setContextCorrections,
    updateDataOnDB,
  } = useContext(CandidateInfoContext)
  // console.log(contextCorrections)
  const {_employment_education} = contextCorrections
  const {
    qualifications: correctionQuals,
    certifications: correctionCertifications,
    experiences: correctionExps,
    customFields: correctionCustomFields,
  } = _employment_education

  // console.log('correctionCertifications', correctionCertifications)

  const isDataAvailable =
    qualifications.length === 0 &&
    certifications.length === 0 &&
    experiences.length === 0 &&
    Object.keys(customFields).length === 0

  const [showModal, setShowModal] = useState(false)
  const [qualIndex, setQualIndex] = useState<null | number>(null)
  const [qualInitialVals, setQualInitialVals]: any = useState({})
  const editQualification1 = () => {
    setShowModal(true)
  }

  const [showCertificationModal, setShowCertificationModal] = useState(false)
  const [certificateIndex, setCertificateIndex] = useState<null | number>(null)
  const [certificateInitialVals, setCertificateInitialVals]: any = useState({})

  const [showExpModal, setShowExpModal] = useState(false)
  const [experienceIndex, setExperienceIndex] = useState<null | number>(null)
  const [experienceInitialVals, setExperienceInitialVals]: any = useState({})

  const editAddedQualification = async (values: any, index: number) => {
    const updatedCorrections = {
      ...contextCorrections,
      _employment_education: {
        ...contextCorrections._employment_education,
        qualifications: {
          ...contextCorrections._employment_education.qualifications,
          [values.id]: {
            ...contextCorrections._employment_education.qualifications[values.id],
            ...values,
          },
        },
      },
    }

    await updateDataOnDB({...formValues, corrections: updatedCorrections})
  }
  const editAddedCertification = async (values: any, index: number) => {
    // console.log(values, 'trigger')
    const updatedCorrections = {
      ...contextCorrections,
      _employment_education: {
        ...contextCorrections._employment_education,
        certifications: {
          ...contextCorrections._employment_education.certifications,
          [values.id]: {
            ...contextCorrections._employment_education.certifications[values.id],
            ...values,
          },
        },
      },
    }

    await updateDataOnDB({...formValues, corrections: updatedCorrections})
  }

  const editAddedExperience = async (values: any, index: number) => {
    // console.log(values, 'trigger')
    const updatedCorrections = {
      ...contextCorrections,
      _employment_education: {
        ...contextCorrections._employment_education,
        experiences: {
          ...contextCorrections._employment_education.experiences,
          [values.id]: {
            ...contextCorrections._employment_education.experiences[values.id],
            ...values,
          },
        },
      },
    }

    await updateDataOnDB({...formValues, corrections: updatedCorrections})
  }

  // console.log(certifications, correctionCertifications)

  const updateQualificationValue = async (qualificationId: string, key: string, newValue: any) => {
    setContextCorrections((prevState: any) => {
      const newCorrections = {...prevState}
      const qualifications = newCorrections._employment_education.qualifications

      // Check if qualification with the given ID exists
      if (!qualifications[qualificationId]) {
        console.warn(`Qualification with ID "${qualificationId}" not found.`)
        return prevState
      }

      // Update the value for the specified key
      qualifications[qualificationId][key] = newValue

      newCorrections._employment_education.qualifications = qualifications
      return newCorrections
    })

    // After updating the state

    // Get original value
    const originalVal = formValues._employment_education.qualifications?.find(
      (each: any) => each.id === qualificationId
    )

    // Get current value (consider deep copy if needed)
    const currentVal = {...contextCorrections._employment_education.qualifications[qualificationId]} // Create a copy

    // Delete status from both objects
    delete originalVal?.status
    delete currentVal.status

    // Check if original and current values are the same (deep comparison)
    if (_.isEqual(originalVal, currentVal)) {
      // If same, delete the qualification by ID
      delete contextCorrections._employment_education.qualifications[qualificationId]
    }

    await updateDataOnDB({...formValues, corrections: contextCorrections})
  }

  const updateQualificationOrCertification = async (
    sectionName: string, // 'qualifications' or 'certifications'
    itemId: string, // qualificationId or certificationId
    key: string,
    newValue: any
  ) => {
    // Create a copy of the current contextCorrections
    const updatedCorrections = {...contextCorrections}
    const section = updatedCorrections._employment_education[sectionName]

    // Check if the item with the given ID exists
    if (!section[itemId]) {
      console.warn(`${sectionName.slice(0, -1)} with ID "${itemId}" not found.`)
      return
    }

    // Update the value for the specified key
    section[itemId][key] = newValue

    // Update the contextCorrections state immediately
    setContextCorrections(updatedCorrections)

    // After updating the state

    // Get original value
    const originalVal = formValues._employment_education[sectionName]?.find(
      (each: any) => each.id === itemId
    )

    // Get current value (consider deep copy if needed)
    const currentVal = {...updatedCorrections._employment_education[sectionName][itemId]}

    // Delete status from both objects
    delete originalVal?.status
    delete currentVal.status

    // Check if original and current values are the same (deep comparison)
    if (_.isEqual(originalVal, currentVal)) {
      // If the same, delete the item by ID
      delete updatedCorrections._employment_education[sectionName][itemId]
      // Update the state again after deletion
      setContextCorrections(updatedCorrections)
    }

    // Proceed to update the database with the new values
    await updateDataOnDB({...formValues, corrections: updatedCorrections})
  }

  const deleteCustomFieldsInEmpEdu = async (section: string, id: string) => {
    const updatedCorrections = {
      ...contextCorrections,
      _employment_education: {
        ...contextCorrections._employment_education,
        customFields: Object.fromEntries(
          Object.entries(contextCorrections._employment_education.customFields).filter(
            ([key]) => key !== id
          )
        ),
      },
    }

    setCorrections(updatedCorrections)
    await updateData({...formValues, corrections: updatedCorrections})
  }

  return (
    <>
      <AddNewQualification
        showModal={showModal}
        setShowModal={setShowModal}
        addQualification={() => {}}
        editQualification={editAddedQualification}
        type={'editNewQual'}
        index={qualIndex}
        initialVals={qualInitialVals}
      />
      <AddCertification
        showModal={showCertificationModal}
        setShowModal={setShowCertificationModal}
        addQualification={() => {}}
        editCertification={editAddedCertification}
        type={'editNewCert'}
        index={certificateIndex}
        initialVals={certificateInitialVals}
        timeStamp={Date.now()}
      />
      <AddExperience
        showModal={showExpModal}
        setShowModal={setShowExpModal}
        addQualification={() => {}}
        editQualification={editAddedExperience}
        type={'editNewExp'}
        index={experienceIndex}
        initialVals={experienceInitialVals}
        isFresher={isFresher}
        timeStamp={Date.now()}
      />
      {!isDataAvailable ? (
        <>
          {editMode ? (
            <EmploymentAndEducation
              formValues={formValues['_employment_education']}
              fullFormValues={formValues}
              addQualification={addQualification}
              editQualification={editQualification}
              deleteQualification={deleteQualification}
              setFormValues={setFormValues}
              updateIsFresher={updateIsFresher}
              updateData={updateData}
              candidateData={candidateData}
              postLoading={postLoading}
              structure={structure}
              setEditMode={closeEditSec}
              setCorrections={setCorrections}
              corrections={corrections}
            />
          ) : (
            <>
              {/* {!editMode && ( */}
              {/* <div className='d-flex justify-content-end mb-5'>
                <button
                  className='btn btn-light-primary btn-sm ms-2'
                  onClick={() => setEditMode(true)}
                >
                  <KTIcon iconName='pencil' className='fs-5' />
                  Edit
                </button>
              </div> */}
              {/* // )} */}
              <div className='min-w-100 d-flex flex-wrap m-auto'>
                {customFields &&
                  Object.entries(customFields).map(([key, value]: any, index) => {
                    const {label, value: val} = value
                    const correction = correctionCustomFields[key]
                    const isDefault = correction ? correction['value'] !== val : false
                    if (val === '_sep_a_ra_tor') {
                      return (
                        <div key={index} className='w-100'>
                          <h3 className='mb-3 text-primary'>
                            <p
                              className='alert bg-light text-center text-muted'
                              style={{
                                width: '100%',
                                verticalAlign: 'middle',
                                fontSize: '12px',
                                textTransform: 'uppercase',
                              }}
                            >
                              <span className='fw-semibold'>{label}</span>
                            </p>
                          </h3>
                        </div>
                      )
                    } else {
                      return (
                        <div key={index} className='w-50 row mb-5 align-items-center'>
                          <label className='col-lg-4 fw-semibold text-muted'>
                            {label.replace(/_/g, ' ')}
                          </label>
                          <div className='col-lg-8'>
                            {correction && isDefault ? (
                              <>
                                <span className='fs-6 default-cursor text-decoration-line-through text-danger'>
                                  {val || '-'}
                                </span>
                                <br />
                                <div className='d-flex align-items-center'>
                                  <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                    {correction?.value || '-'}
                                  </span>
                                  <button
                                    className='btn btn-unstyled m-0 p-0'
                                    data-bs-toggle='tooltip'
                                    title='Revert changes'
                                    onClick={() => deleteCustomFieldsInEmpEdu('_basic_info', key)}
                                  >
                                    <KTIcon iconName='cross-circle' className='text-danger' />
                                  </button>
                                </div>
                              </>
                            ) : (
                              <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                {val || '-'}
                              </span>
                            )}
                          </div>
                        </div>
                      )
                    }
                  })}
              </div>
              <h3 className='mb-0 text-primary'>
                <p
                  className='alert bg-light text-center text-muted'
                  style={{
                    width: '100%',
                    verticalAlign: 'middle',
                    fontSize: '12px',
                    textTransform: 'uppercase',
                  }}
                >
                  <span className='fw-semibold'>Educational Qualifications</span>
                </p>
              </h3>

              <table className='table table-row-dashed table-row-gray-300 align-middle'>
                <thead>
                  <tr className='fw-semibold text-muted default-cursor bg-light-primary rounded'>
                    <th className='fw-semibold ps-3'>Qualification</th>
                    <th className='fw-semibold'>College/University</th>
                    <th className='fw-semibold'>Score Type</th>
                    <th className='fw-semibold'>Score</th>
                    <th className='fw-semibold'>Mode</th>
                    <th className='fw-semibold'>Passed Out Year</th>
                    {/* <th className='fw-semibold text-muted'>Actions</th> */}
                  </tr>
                </thead>
                <tbody>
                  {qualifications.length ? (
                    qualifications.map((qualification: any, index: number) => {
                      // Find the corresponding correction qualification by id
                      const correction = correctionQuals[qualification.id]
                      // const correction =
                      //   correctionQuals.find((cor: any) => cor.id === qualification.id) || {}

                      const condition =
                        correction &&
                        Object.keys(correction).length > 0 &&
                        !_.isEqual(qualification, correction)
                      // console.log(qualification, correction, condition)

                      const isNew = qualification['status'] === 'New'
                      if (qualification.status && qualification.status === 'deleted') {
                        return null
                      }

                      return (
                        <tr
                          key={index}
                          style={{marginTop: '10px', color: isNew ? 'var(--bs-success)' : ''}}
                        >
                          <td>
                            <div className='ps-3'>
                              {condition &&
                              correction['educationLevel'] !== qualification['educationLevel'] ? (
                                <p className='m-0'>
                                  <span className='text-decoration-line-through text-danger'>
                                    {qualification.educationLevel}
                                  </span>
                                  <br />
                                  <div className='d-flex align-items-center'>
                                    <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                      {correction?.educationLevel || '-'}
                                    </span>
                                    <button
                                      className='btn btn-unstyled m-0 p-0'
                                      data-bs-toggle='tooltip'
                                      title='Revert changes'
                                      onClick={() =>
                                        updateQualificationOrCertification(
                                          'qualifications',
                                          qualification.id,
                                          'educationLevel',
                                          qualification.educationLevel
                                        )
                                      }
                                    >
                                      <KTIcon iconName='cross-circle' className='text-danger' />
                                    </button>
                                  </div>
                                </p>
                              ) : (
                                <p className='m-0'>
                                  <span>{qualification.educationLevel}</span>
                                </p>
                              )}
                              {condition &&
                              correction['discipline'] !== qualification['discipline'] ? (
                                <p className='m-0 fs-8'>
                                  <span className='text-decoration-line-through text-danger'>
                                    {qualification.discipline}
                                  </span>
                                  <br />
                                  {/* <span>{correction.discipline}</span> */}
                                  <div className='d-flex align-items-center'>
                                    <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                      {correction?.discipline || '-'}
                                    </span>
                                    <button
                                      className='btn btn-unstyled m-0 p-0'
                                      data-bs-toggle='tooltip'
                                      title='Revert changes'
                                      onClick={() =>
                                        updateQualificationOrCertification(
                                          'qualifications',
                                          qualification.id,
                                          'discipline',
                                          qualification.discipline
                                        )
                                      }
                                    >
                                      <KTIcon iconName='cross-circle' className='text-danger' />
                                    </button>
                                  </div>
                                </p>
                              ) : (
                                <p className='m-0 text-muted fs-8'>
                                  <span>{qualification.discipline}</span>
                                </p>
                              )}
                            </div>
                          </td>
                          <td>
                            <div>
                              {condition && correction['school'] !== qualification['school'] ? (
                                <>
                                  <span className='text-decoration-line-through text-danger'>
                                    {qualification.school}
                                  </span>
                                  <br />

                                  <div className='d-flex align-items-center'>
                                    <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                      {correction?.school || '-'}
                                    </span>
                                    <button
                                      className='btn btn-unstyled m-0 p-0'
                                      data-bs-toggle='tooltip'
                                      title='Revert changes'
                                      onClick={() =>
                                        updateQualificationValue(
                                          qualification.id,
                                          'school',
                                          qualification.school
                                        )
                                      }
                                    >
                                      <KTIcon iconName='cross-circle' className='text-danger' />
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <span>{qualification.school}</span>
                              )}
                            </div>
                          </td>
                          <td>
                            <div>
                              {condition &&
                              correction['scoreType'] !== qualification['scoreType'] ? (
                                <>
                                  <span className='text-decoration-line-through text-danger'>
                                    {qualification.scoreType}
                                  </span>
                                  <br />
                                  <div className='d-flex align-items-center'>
                                    <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                      {correction?.scoreType || '-'}
                                    </span>
                                    <button
                                      className='btn btn-unstyled m-0 p-0'
                                      data-bs-toggle='tooltip'
                                      title='Revert changes'
                                      onClick={() =>
                                        updateQualificationValue(
                                          qualification.id,
                                          'scoreType',
                                          qualification.scoreType
                                        )
                                      }
                                    >
                                      <KTIcon iconName='cross-circle' className='text-danger' />
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <span>{qualification.scoreType}</span>
                              )}
                            </div>
                          </td>
                          <td>
                            <div>
                              {condition && correction['score'] !== qualification['score'] ? (
                                <>
                                  <span className='text-decoration-line-through text-danger'>
                                    {qualification.score}
                                  </span>
                                  <br />
                                  <div className='d-flex align-items-center'>
                                    <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                      {correction?.score || '-'}
                                    </span>
                                    <button
                                      className='btn btn-unstyled m-0 p-0'
                                      data-bs-toggle='tooltip'
                                      title='Revert changes'
                                      onClick={() =>
                                        updateQualificationValue(
                                          qualification.id,
                                          'score',
                                          qualification.score
                                        )
                                      }
                                    >
                                      <KTIcon iconName='cross-circle' className='text-danger' />
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <span>{qualification.score}</span>
                              )}
                            </div>
                          </td>
                          <td>
                            <div>
                              {condition && correction['mode'] !== qualification['mode'] ? (
                                <>
                                  <span className='text-decoration-line-through text-danger'>
                                    {qualification.mode}
                                  </span>
                                  <br />
                                  <div className='d-flex align-items-center'>
                                    <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                      {correction?.mode || '-'}
                                    </span>
                                    <button
                                      className='btn btn-unstyled m-0 p-0'
                                      data-bs-toggle='tooltip'
                                      title='Revert changes'
                                      onClick={() =>
                                        updateQualificationValue(
                                          qualification.id,
                                          'mode',
                                          qualification.mode
                                        )
                                      }
                                    >
                                      <KTIcon iconName='cross-circle' className='text-danger' />
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <span>{qualification.mode}</span>
                              )}
                            </div>
                          </td>
                          <td>
                            <div>
                              {condition &&
                              correction['yearOfPassing'] !== qualification['yearOfPassing'] ? (
                                <>
                                  <span className='text-decoration-line-through text-danger'>
                                    {qualification.yearOfPassing}
                                  </span>
                                  <br />
                                  <div className='d-flex align-items-center'>
                                    <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                      {correction?.yearOfPassing || '-'}
                                    </span>
                                    <button
                                      className='btn btn-unstyled m-0 p-0'
                                      data-bs-toggle='tooltip'
                                      title='Revert changes'
                                      onClick={() =>
                                        updateQualificationValue(
                                          qualification.id,
                                          'yearOfPassing',
                                          qualification.yearOfPassing
                                        )
                                      }
                                    >
                                      <KTIcon iconName='cross-circle' className='text-danger' />
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <span>{qualification.yearOfPassing}</span>
                              )}
                            </div>
                          </td>
                          {/* <td>
                            <span className='badge badge-light-primary fw-normal'>Edited</span>
                            <br />
                            <span className='badge badge-light-primary mt-1 fw-normal'>
                              View Changes
                            </span>
                          </td> */}
                        </tr>
                      )
                    })
                  ) : (
                    <tr style={{marginTop: '10px'}}>
                      <td colSpan={6}>
                        <div className='d-flex justify-content-center'>
                          <p className='text-dark default-cursor d-block fs-6 m-0 mt-5 ps-3'>N.A</p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              {Object.values(correctionQuals).map((each: any, index: number) => {
                if (each.status && each.status === 'New') {
                  return (
                    <div key={each.id} className='bg-light-success p-3 mb-3 rounded'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <div className='w-75 d-flex align-items-center'>
                          <div className='d-flex flex-row align-items-center'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              height='44px'
                              viewBox='0 -960 960 960'
                              width='44px'
                              fill='#5f6368'
                            >
                              <path d='M480-120 200-272v-240L40-600l440-240 440 240v320h-80v-276l-80 44v240L480-120Zm0-332 274-148-274-148-274 148 274 148Zm0 241 200-108v-151L480-360 280-470v151l200 108Zm0-241Zm0 90Zm0 0Z' />
                            </svg>
                            <div className='text-muted ms-5'>
                              <h6 className='fw-semibold m-0'>
                                {each.educationLevel} - {each.discipline}
                              </h6>
                              <span className='m-0 fs-7'>{each.school}</span>
                              <br />
                              <span className='m-0 fs-7'>
                                {each.score} ({each.scoreType}), {each.mode}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className=''>
                          <button
                            className='btn btn-light-primary btn-sm me-2'
                            onClick={() => {
                              setQualIndex(index)
                              setQualInitialVals(each)
                              editQualification1()
                            }}
                          >
                            <KTIcon iconName='pencil' className='fs-2' />
                            Edit
                          </button>
                          <button
                            className='btn btn-light-danger btn-sm ms-2'
                            onClick={async () => {
                              delete correctionQuals[each.id]

                              const updatedCorrections = {
                                ...contextCorrections,
                                _employment_education: {
                                  ...contextCorrections._employment_education,
                                  qualifications: correctionQuals,
                                },
                              }
                              await updateDataOnDB({
                                ...formValues,
                                corrections: updatedCorrections,
                              })
                            }}
                          >
                            <KTIcon iconName='trash' className='fs-2' />
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                } else if (each.status && each.status === 'deleted') {
                  return (
                    <div key={each.id} className='bg-light-danger p-3 mb-3 rounded'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <div className='w-75 d-flex align-items-center'>
                          <div className='d-flex flex-row align-items-center'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              height='44px'
                              viewBox='0 -960 960 960'
                              width='44px'
                              fill='#5f6368'
                            >
                              <path d='M480-120 200-272v-240L40-600l440-240 440 240v320h-80v-276l-80 44v240L480-120Zm0-332 274-148-274-148-274 148 274 148Zm0 241 200-108v-151L480-360 280-470v151l200 108Zm0-241Zm0 90Zm0 0Z' />
                            </svg>
                            <div className='text-muted ms-5'>
                              <h6 className='fw-semibold m-0'>
                                {each.educationLevel} - {each.discipline}
                              </h6>
                              <span className='m-0 fs-7'>{each.school}</span>
                              <br />
                              <span className='m-0 fs-7'>
                                {each.score} ({each.scoreType}), {each.mode}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className=''>
                          <button
                            className='btn btn-light-primary btn-sm me-2'
                            onClick={async () => {
                              delete correctionQuals[each.id]

                              const updatedData = {
                                ...formValues,
                                _employment_education: {
                                  ...formValues._employment_education,
                                  qualifications: formValues._employment_education[
                                    'qualifications'
                                  ].map((item: any, i: number) =>
                                    item.id === each.id ? {...item, status: 'original'} : item
                                  ),
                                },
                              }

                              const updatedCorrections = {
                                ...contextCorrections,
                                _employment_education: {
                                  ...contextCorrections._employment_education,
                                  qualifications: correctionQuals,
                                },
                              }
                              await updateDataOnDB({
                                ...updatedData,
                                corrections: updatedCorrections,
                              })
                            }}
                          >
                            <KTIcon iconName='arrow-circle-left' className='fs-2' />
                            Restore
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                }
              })}

              <h3 className='mt-3 mb-0 text-primary'>
                <p
                  className='alert bg-light text-center text-muted'
                  style={{
                    width: '100%',
                    verticalAlign: 'middle',
                    fontSize: '12px',
                    textTransform: 'uppercase',
                  }}
                >
                  <span className='fw-semibold'>Certifications</span>
                </p>
              </h3>

              {certifications?.length > 0 && (
                <table className='table table-row-dashed table-row-gray-300 align-middle'>
                  <thead>
                    <tr className='fw-semibold text-muted default-cursor bg-light-primary rounded'>
                      <th className='fw-semibold text-muted ps-3'>Course Name</th>
                      <th className='fw-semibold text-muted'>Issuer</th>
                      <th className='fw-semibold text-muted'>Completion Year</th>
                      <th className='fw-semibold text-muted'>Course Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {certifications.length ? (
                      certifications.map((certification: any, index: number) => {
                        const correction = correctionCertifications[certification.id]
                        // const correction =
                        //   correctionQuals.find((cor: any) => cor.id === qualification.id) || {}

                        const condition =
                          correction &&
                          Object.keys(correction).length > 0 &&
                          !_.isEqual(certification, correction)

                        if (certification.status && certification.status === 'deleted') {
                          return null
                        }

                        return (
                          <tr key={index} style={{marginTop: '10px'}}>
                            <td className='ps-3'>
                              <div>
                                {condition &&
                                correction['courseName'] !== certification['courseName'] ? (
                                  <>
                                    <span className='text-decoration-line-through text-danger'>
                                      {certification.courseName}
                                    </span>
                                    <br />
                                    <div className='d-flex align-items-center'>
                                      <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                        {correction?.courseName || '-'}
                                      </span>
                                      <button
                                        className='btn btn-unstyled m-0 p-0'
                                        data-bs-toggle='tooltip'
                                        title='Revert changes'
                                        onClick={() =>
                                          updateQualificationOrCertification(
                                            'certifications',
                                            certification.id,
                                            'courseName',
                                            certification.courseName
                                          )
                                        }
                                      >
                                        <KTIcon iconName='cross-circle' className='text-danger' />
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  <span>{certification.courseName}</span>
                                )}
                              </div>
                            </td>
                            <td>
                              {condition && correction['issuer'] !== certification['issuer'] ? (
                                <>
                                  <span className='text-decoration-line-through text-danger'>
                                    {certification.issuer}
                                  </span>
                                  <br />
                                  <div className='d-flex align-items-center'>
                                    <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                      {correction?.issuer || '-'}
                                    </span>
                                    <button
                                      className='btn btn-unstyled m-0 p-0'
                                      data-bs-toggle='tooltip'
                                      title='Revert changes'
                                      onClick={() =>
                                        updateQualificationOrCertification(
                                          'certifications',
                                          certification.id,
                                          'issuer',
                                          certification.issuer
                                        )
                                      }
                                    >
                                      <KTIcon iconName='cross-circle' className='text-danger' />
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <span>{certification.issuer}</span>
                              )}
                            </td>
                            <td>
                              {condition &&
                              correction['yearOfCompletion'] !==
                                certification['yearOfCompletion'] ? (
                                <>
                                  <span className='text-decoration-line-through text-danger'>
                                    {certification.yearOfCompletion}
                                  </span>
                                  <br />
                                  <div className='d-flex align-items-center'>
                                    <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                      {correction?.yearOfCompletion || '-'}
                                    </span>
                                    <button
                                      className='btn btn-unstyled m-0 p-0'
                                      data-bs-toggle='tooltip'
                                      title='Revert changes'
                                      onClick={() =>
                                        updateQualificationOrCertification(
                                          'certifications',
                                          certification.id,
                                          'yearOfCompletion',
                                          certification.yearOfCompletion
                                        )
                                      }
                                    >
                                      <KTIcon iconName='cross-circle' className='text-danger' />
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <span>{certification.yearOfCompletion}</span>
                              )}
                            </td>
                            <td>
                              {' '}
                              <div>
                                {condition && correction['mode'] !== certification['mode'] ? (
                                  <>
                                    <span className='text-decoration-line-through text-danger'>
                                      {certification.mode}
                                    </span>
                                    <br />
                                    <div className='d-flex align-items-center'>
                                      <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                        {correction?.mode || '-'}
                                      </span>
                                      <button
                                        className='btn btn-unstyled m-0 p-0'
                                        data-bs-toggle='tooltip'
                                        title='Revert changes'
                                        onClick={() =>
                                          updateQualificationOrCertification(
                                            'certifications',
                                            certification.id,
                                            'mode',
                                            certification.mode
                                          )
                                        }
                                      >
                                        <KTIcon iconName='cross-circle' className='text-danger' />
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  <span>{certification.mode}</span>
                                )}
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr style={{marginTop: '10px'}}>
                        <td colSpan={4}>
                          <div className='d-flex justify-content-center'>
                            <p className='text-dark default-cursor d-block fs-6 m-0 mt-5 ps-3'>
                              N.A
                            </p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}

              {correctionCertifications &&
                (Object.values(correctionCertifications) || []).map((each: any, index: number) => {
                  if (each.status && each.status === 'New') {
                    return (
                      <div key={each.id} className='bg-light-success p-3 mb-3 rounded'>
                        <div className='d-flex justify-content-between align-items-center'>
                          <div className='w-75 d-flex align-items-center'>
                            <div className='d-flex flex-row align-items-center'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                height='54px'
                                viewBox='0 -960 960 960'
                                width='44px'
                                fill='#5f6368'
                              >
                                <path d='m387-412 35-114-92-74h114l36-112 36 112h114l-93 74 35 114-92-71-93 71ZM240-40v-309q-38-42-59-96t-21-115q0-134 93-227t227-93q134 0 227 93t93 227q0 61-21 115t-59 96v309l-240-80-240 80Zm240-280q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70ZM320-159l160-41 160 41v-124q-35 20-75.5 31.5T480-240q-44 0-84.5-11.5T320-283v124Zm160-62Z' />
                              </svg>
                              <div className='text-muted ms-5'>
                                <h6 className='fw-semibold m-0'>{each.courseName}</h6>
                                <span className='m-0 fs-7'>{each.issuer}</span>
                                <br />
                                <span className='m-0 fs-7'>
                                  {each.yearOfCompletion}, {each.mode}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className=''>
                            <button
                              className='btn btn-light-primary btn-sm me-2'
                              onClick={() => {
                                setCertificateIndex(index)
                                setCertificateInitialVals(each)
                                setShowCertificationModal(true)
                              }}
                            >
                              <KTIcon iconName='pencil' className='fs-2' />
                              Edit
                            </button>
                            <button
                              className='btn btn-light-danger btn-sm ms-2'
                              onClick={async () => {
                                delete correctionCertifications[each.id]

                                const updatedCorrections = {
                                  ...contextCorrections,
                                  _employment_education: {
                                    ...contextCorrections._employment_education,
                                    certifications: correctionCertifications,
                                  },
                                }
                                await updateDataOnDB({
                                  ...formValues,
                                  corrections: updatedCorrections,
                                })
                              }}
                            >
                              <KTIcon iconName='trash' className='fs-2' />
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                  } else if (each.status && each.status === 'deleted') {
                    return (
                      <div key={each.id} className='bg-light-danger p-3 mb-3 rounded'>
                        <div className='d-flex justify-content-between align-items-center'>
                          <div className='w-75 d-flex align-items-center'>
                            <div className='d-flex flex-row align-items-center'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                height='54px'
                                viewBox='0 -960 960 960'
                                width='44px'
                                fill='#5f6368'
                              >
                                <path d='m387-412 35-114-92-74h114l36-112 36 112h114l-93 74 35 114-92-71-93 71ZM240-40v-309q-38-42-59-96t-21-115q0-134 93-227t227-93q134 0 227 93t93 227q0 61-21 115t-59 96v309l-240-80-240 80Zm240-280q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70ZM320-159l160-41 160 41v-124q-35 20-75.5 31.5T480-240q-44 0-84.5-11.5T320-283v124Zm160-62Z' />
                              </svg>
                              <div className='text-muted ms-5'>
                                <h6 className='fw-semibold m-0'>{each.courseName}</h6>
                                <span className='m-0 fs-7'>{each.issuer}</span>
                                <br />
                                <span className='m-0 fs-7'>
                                  {each.yearOfCompletion}, {each.mode}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className=''>
                            <button
                              className='btn btn-light-primary btn-sm me-2'
                              onClick={async () => {
                                delete correctionCertifications[each.id]

                                const updatedData = {
                                  ...formValues,
                                  _employment_education: {
                                    ...formValues._employment_education,
                                    certifications: formValues._employment_education[
                                      'certifications'
                                    ].map((item: any, i: number) =>
                                      item.id === each.id ? {...item, status: 'original'} : item
                                    ),
                                  },
                                }

                                const updatedCorrections = {
                                  ...contextCorrections,
                                  _employment_education: {
                                    ...contextCorrections._employment_education,
                                    certifications: correctionCertifications,
                                  },
                                }
                                await updateDataOnDB({
                                  ...updatedData,
                                  corrections: updatedCorrections,
                                })
                              }}
                            >
                              <KTIcon iconName='arrow-circle-left' className='fs-2' />
                              Restore
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                  }
                  return null
                })}

              {certifications.length === 0 &&
                (!correctionCertifications ||
                  Object.keys(correctionCertifications).length === 0) && (
                  <div className='d-flex justify-content-center'>
                    <p className='text-dark default-cursor d-block fs-6 m-0 my-5 ps-3'>N.A</p>
                  </div>
                )}

              <h3 className='mt-3 mb-0 text-primary'>
                <p
                  className='alert bg-light text-center text-muted'
                  style={{
                    width: '100%',
                    verticalAlign: 'middle',
                    fontSize: '12px',
                    textTransform: 'uppercase',
                  }}
                >
                  <span className='fw-semibold'>Employment</span>
                </p>
              </h3>

              {experiences?.length > 0 && (
                <table className='table table-row-dashed table-row-gray-300 align-middle'>
                  <thead>
                    <tr className='fw-semibold text-muted default-cursor bg-light-primary rounded'>
                      <th className='fw-semibold text-muted ps-3'>Company</th>
                      <th className='fw-semibold text-muted'>Designation</th>
                      <th className='fw-semibold text-muted'>Work Type</th>
                      <th className='fw-semibold text-muted'>Start Date</th>
                      <th className='fw-semibold text-muted'>End Date</th>
                      <th className='fw-semibold text-muted'>Currency</th>
                      <th className='fw-semibold text-muted'>Annual CTC</th>
                    </tr>
                  </thead>

                  <tbody>
                    {experiences.length ? (
                      experiences.map((qualification: any, index: number) => {
                        const correction = correctionExps[qualification.id]

                        const condition =
                          correction &&
                          Object.keys(correction).length > 0 &&
                          !_.isEqual(qualification, correction)

                        if (qualification.status && qualification.status === 'deleted') {
                          return null
                        }
                        return (
                          <tr key={index} style={{marginTop: '10px'}}>
                            <td className='ps-3'>
                              <div>
                                {condition && correction['company'] !== qualification['company'] ? (
                                  <>
                                    <span className='text-decoration-line-through text-danger'>
                                      {qualification.company}
                                    </span>
                                    <br />
                                    <div className='d-flex align-items-center'>
                                      <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                        {correction?.company || '-'}
                                      </span>
                                      <button
                                        className='btn btn-unstyled m-0 p-0'
                                        data-bs-toggle='tooltip'
                                        title='Revert changes'
                                        onClick={() =>
                                          updateQualificationOrCertification(
                                            'experiences',
                                            qualification.id,
                                            'company',
                                            qualification.company
                                          )
                                        }
                                      >
                                        <KTIcon iconName='cross-circle' className='text-danger' />
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  <span>{qualification.company}</span>
                                )}
                              </div>
                            </td>
                            <td>
                              <div>
                                {condition &&
                                correction['designation'] !== qualification['designation'] ? (
                                  <>
                                    <span className='text-decoration-line-through text-danger'>
                                      {qualification.designation}
                                    </span>
                                    <br />
                                    <div className='d-flex align-items-center'>
                                      <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                        {correction?.designation || '-'}
                                      </span>
                                      <button
                                        className='btn btn-unstyled m-0 p-0'
                                        data-bs-toggle='tooltip'
                                        title='Revert changes'
                                        onClick={() =>
                                          updateQualificationOrCertification(
                                            'experiences',
                                            qualification.id,
                                            'designation',
                                            qualification.designation
                                          )
                                        }
                                      >
                                        <KTIcon iconName='cross-circle' className='text-danger' />
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  <span>{qualification.designation}</span>
                                )}
                              </div>
                            </td>
                            <td>
                              <div>
                                {condition &&
                                correction['workType'] !== qualification['workType'] ? (
                                  <>
                                    <span className='text-decoration-line-through text-danger'>
                                      {qualification.workType}
                                    </span>
                                    <br />
                                    <div className='d-flex align-items-center'>
                                      <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                        {correction?.workType || '-'}
                                      </span>
                                      <button
                                        className='btn btn-unstyled m-0 p-0'
                                        data-bs-toggle='tooltip'
                                        title='Revert changes'
                                        onClick={() =>
                                          updateQualificationOrCertification(
                                            'experiences',
                                            qualification.id,
                                            'workType',
                                            qualification.workType
                                          )
                                        }
                                      >
                                        <KTIcon iconName='cross-circle' className='text-danger' />
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  <span>{qualification.workType}</span>
                                )}
                              </div>
                            </td>
                            <td>
                              <div>
                                {condition &&
                                correction['startDate'] !== qualification['startDate'] ? (
                                  <>
                                    <span className='text-decoration-line-through text-danger'>
                                      {qualification.startDate}
                                    </span>
                                    <br />
                                    <div className='d-flex align-items-center'>
                                      <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                        {correction?.startDate || '-'}
                                      </span>
                                      <button
                                        className='btn btn-unstyled m-0 p-0'
                                        data-bs-toggle='tooltip'
                                        title='Revert changes'
                                        onClick={() =>
                                          updateQualificationOrCertification(
                                            'experiences',
                                            qualification.id,
                                            'startDate',
                                            qualification.startDate
                                          )
                                        }
                                      >
                                        <KTIcon iconName='cross-circle' className='text-danger' />
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  <span>{qualification.startDate}</span>
                                )}
                              </div>
                            </td>
                            <td>
                              <div>
                                {condition && correction['endDate'] !== qualification['endDate'] ? (
                                  <>
                                    <span className='text-decoration-line-through text-danger'>
                                      {qualification.endDate}
                                    </span>
                                    <br />
                                    <div className='d-flex align-items-center'>
                                      <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                        {correction?.endDate || '-'}
                                      </span>
                                      <button
                                        className='btn btn-unstyled m-0 p-0'
                                        data-bs-toggle='tooltip'
                                        title='Revert changes'
                                        onClick={() =>
                                          updateQualificationOrCertification(
                                            'experiences',
                                            qualification.id,
                                            'endDate',
                                            qualification.endDate
                                          )
                                        }
                                      >
                                        <KTIcon iconName='cross-circle' className='text-danger' />
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  <span>{qualification.endDate || '-'}</span>
                                )}
                              </div>
                            </td>
                            <td>
                              <div>
                                {condition &&
                                correction['currency'] !== qualification['currency'] ? (
                                  <>
                                    <span className='text-decoration-line-through text-danger'>
                                      {qualification.currency}
                                    </span>
                                    <br />
                                    <div className='d-flex align-items-center'>
                                      <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                        {correction?.currency || '-'}
                                      </span>
                                      <button
                                        className='btn btn-unstyled m-0 p-0'
                                        data-bs-toggle='tooltip'
                                        title='Revert changes'
                                        onClick={() =>
                                          updateQualificationOrCertification(
                                            'experiences',
                                            qualification.id,
                                            'currency',
                                            qualification.currency
                                          )
                                        }
                                      >
                                        <KTIcon iconName='cross-circle' className='text-danger' />
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  <span>{qualification.currency}</span>
                                )}
                              </div>
                            </td>
                            <td>
                              <div>
                                {condition &&
                                correction['annualCTC'] !== qualification['annualCTC'] ? (
                                  <>
                                    <span className='text-decoration-line-through text-danger'>
                                      {qualification.annualCTC}
                                    </span>
                                    <br />
                                    <div className='d-flex align-items-center'>
                                      <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                        {correction?.annualCTC || '-'}
                                      </span>
                                      <button
                                        className='btn btn-unstyled m-0 p-0'
                                        data-bs-toggle='tooltip'
                                        title='Revert changes'
                                        onClick={() =>
                                          updateQualificationOrCertification(
                                            'experiences',
                                            qualification.id,
                                            'annualCTC',
                                            qualification.annualCTC
                                          )
                                        }
                                      >
                                        <KTIcon iconName='cross-circle' className='text-danger' />
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  <span>{qualification.annualCTC}</span>
                                )}
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr style={{marginTop: '10px'}}>
                        <td colSpan={7}>
                          <div className='d-flex justify-content-center'>
                            <p className='text-dark default-cursor d-block fs-6 m-0 mt-5 ps-3'>
                              N.A
                            </p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}

              {Object.values(correctionExps).map((each: any, index: number) => {
                if (each.status && each.status === 'New') {
                  return (
                    <div key={each.id} className='bg-light-success p-3 mb-3 rounded'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <div className='w-75 d-flex align-items-center'>
                          <div className='d-flex flex-row align-items-center'>
                            <div className='d-flex flex-row align-items-center'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                height='54px'
                                viewBox='0 -960 960 960'
                                width='44px'
                                fill='#5f6368'
                              >
                                <path d='M160-200v-440 440-15 15Zm0 80q-33 0-56.5-23.5T80-200v-440q0-33 23.5-56.5T160-720h160v-80q0-33 23.5-56.5T400-880h160q33 0 56.5 23.5T640-800v80h160q33 0 56.5 23.5T880-640v171q-18-13-38-22.5T800-508v-132H160v440h283q3 21 9 41t15 39H160Zm240-600h160v-80H400v80ZM720-40q-83 0-141.5-58.5T520-240q0-83 58.5-141.5T720-440q83 0 141.5 58.5T920-240q0 83-58.5 141.5T720-40Zm20-208v-112h-40v128l86 86 28-28-74-74Z' />
                              </svg>
                              <div className='text-muted ms-5'>
                                {/* <br />
                            <span className='m-0'>{each.workType}</span> */}
                                <h6 className='fw-semibold m-0'>
                                  {each.designation}, {each.workType}
                                </h6>
                                <span className='m-0'>{each.company}</span>
                                <br />
                                <span className='m-0'>
                                  {each.startDate}{' '}
                                  {each.isCurrentJob ? '(Active)' : `to ${each.endDate}`}
                                </span>
                                <br />
                                <span className='m-0'>
                                  CTC: {each?.currency} ({decodeURI(each?.currencyIcon)}){' '}
                                  {each.annualCTC} per annum
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=''>
                          <button
                            className='btn btn-light-primary btn-sm me-2'
                            onClick={() => {
                              setExperienceIndex(index)
                              setExperienceInitialVals(each)
                              setShowExpModal(true)
                            }}
                          >
                            <KTIcon iconName='pencil' className='fs-2' />
                            Edit
                          </button>
                          <button
                            className='btn btn-light-danger btn-sm ms-2'
                            onClick={async () => {
                              delete correctionExps[each.id]

                              const updatedCorrections = {
                                ...contextCorrections,
                                _employment_education: {
                                  ...contextCorrections._employment_education,
                                  experiences: correctionExps,
                                },
                              }
                              await updateDataOnDB({
                                ...formValues,
                                corrections: updatedCorrections,
                              })
                            }}
                          >
                            <KTIcon iconName='trash' className='fs-2' />
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                } else if (each.status && each.status === 'deleted') {
                  return (
                    <div key={each.id} className='bg-light-danger p-3 mb-3 rounded'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <div className='w-75 d-flex align-items-center'>
                          <div className='d-flex flex-row align-items-center'>
                            <div className='d-flex flex-row align-items-center'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                height='54px'
                                viewBox='0 -960 960 960'
                                width='44px'
                                fill='#5f6368'
                              >
                                <path d='M160-200v-440 440-15 15Zm0 80q-33 0-56.5-23.5T80-200v-440q0-33 23.5-56.5T160-720h160v-80q0-33 23.5-56.5T400-880h160q33 0 56.5 23.5T640-800v80h160q33 0 56.5 23.5T880-640v171q-18-13-38-22.5T800-508v-132H160v440h283q3 21 9 41t15 39H160Zm240-600h160v-80H400v80ZM720-40q-83 0-141.5-58.5T520-240q0-83 58.5-141.5T720-440q83 0 141.5 58.5T920-240q0 83-58.5 141.5T720-40Zm20-208v-112h-40v128l86 86 28-28-74-74Z' />
                              </svg>
                              <div className='text-muted ms-5'>
                                <h6 className='fw-semibold m-0'>
                                  {each.designation}, {each.workType}
                                </h6>
                                <span className='m-0'>{each.company}</span>
                                <br />
                                <span className='m-0'>
                                  {each.startDate}{' '}
                                  {each.isCurrentJob ? '(Active)' : `to ${each.endDate}`}
                                </span>
                                <br />
                                <span className='m-0'>
                                  CTC: {each?.currency} ({decodeURI(each?.currencyIcon)}){' '}
                                  {each.annualCTC} per annum
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=''>
                          <button
                            className='btn btn-light-primary btn-sm me-2'
                            onClick={async () => {
                              delete correctionExps[each.id]

                              const updatedData = {
                                ...formValues,
                                _employment_education: {
                                  ...formValues._employment_education,
                                  experiences: formValues._employment_education['experiences'].map(
                                    (item: any, i: number) =>
                                      item.id === each.id ? {...item, status: 'original'} : item
                                  ),
                                },
                              }

                              const updatedCorrections = {
                                ...contextCorrections,
                                _employment_education: {
                                  ...contextCorrections._employment_education,
                                  experiences: correctionExps,
                                },
                              }
                              await updateDataOnDB({
                                ...updatedData,
                                corrections: updatedCorrections,
                              })
                            }}
                          >
                            <KTIcon iconName='arrow-circle-left' className='fs-2' />
                            Restore
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                }
              })}

              {experiences.length === 0 &&
                (!correctionExps || Object.keys(correctionExps).length === 0) && (
                  <div className='d-flex justify-content-center'>
                    <p className='text-dark default-cursor d-block fs-6 m-0 my-5 ps-3'>N.A</p>
                  </div>
                )}
            </>
          )}
        </>
      ) : (
        <div
          className='card d-flex flex-column justify-content-center align-items-center'
          style={{height: '228px'}}
        >
          <h3 className='fw-semibold fs-3'>Candidate data not filled yet.</h3>
        </div>
      )}
    </>
  )
}

export default EmpAndEdu
