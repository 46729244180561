import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const UseToast = (message: string, options: any, type: 'success' | 'error' | 'warn') => {
  toast.dismiss()

  if (type === 'success') {
    toast.success(message, {...options})
  } else if (type === 'error') {
    toast.error(message, {...options})
  } else if (type === 'warn') {
    toast.warn(message, {...options})
  }
}

export {UseToast}
