import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../../_metronic/helpers'
import clsx from 'clsx'
import usePostApi from '../../../custom_hooks/usePostApi'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import useApiCall from '../../../custom_hooks/useApiCall'
import Select, {components} from 'react-select'
import 'react-datepicker/dist/react-datepicker.css'
import '../../../../../'
import {FindPodIdByCode} from '../../../FindPodIdByPodCode'
import {useIntl} from 'react-intl'
import {useLang} from '../../../../../_metronic/i18n/Metronici18n'
import {format} from 'date-fns'
import {useThemeMode} from '../../../../../_metronic/partials'
import {UseToast} from '../../../useToast'
import {useParams} from 'react-router-dom'
import CreateNewCandidatesModal from '../../candidates/add_candidate/CreateNewCandidatesModal'
import CreateNewVacancyModal from '../../vacancies/add_vacancy/CreateNewVacancyModal'
import AddTeamMemberModal from '../../../team_members/AddTeamMemberModal'
interface Props {
  showModal: boolean
  setShowModal(val: boolean): void
  fetchInterviewsData(): void
}

interface Data {
  candidate_id: string
  interviewer_id: string
  interview_type_id: string
  url: string
  mode: string
  meeting_id: string
  passcode: string
  pod_id: string
  status: string
  address: string
  contact_person: string
  contact_number: string
  interview_date: string
  interview_start_time: string
  interview_end_time: string
  interview_round: string
  vacancy_id: number | string
  interview_description: string
}

const ScheduleInterviewModal = (props: Props) => {
  const podId = FindPodIdByCode()
  const {mode} = useThemeMode()
  const {execute, postLoading} = usePostApi()
  const [locations, setlocations] = useState([])
  const [candidatesData, setcandidatesData] = useState([])
  const [departments, setdepartmentData] = useState([])
  const [seededinterviewtypes, setseededinterviewtypes] = useState('')
  const [interviewTypes, setinterviewTypes] = useState('')
  const getseededinterviewTypes = async () => {
    try {
      const url = `/pod/interview_types/seeded`
      const formData = {}

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.interview_type_id,
        label: each.interview_type,
      }))

      setseededinterviewtypes(options)
      if (options.length === 1) {
        setinterviewTypes(options[0].value)
      } else {
        setinterviewTypes('')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }
  const getCandidates = async () => {
    try {
      const url = `/pod/candidate/list`
      const formData = {
        pod_id: podId,
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.candidate_id,
        label: each.candidate_first_name,
      }))
      setcandidatesData(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getCandidates()
  }, [])

  const [internalInterviewers, setinternalInterviewers] = useState([])

  const getinternalInterviewers = async () => {
    try {
      const url = `/pod/interviewers`
      const formData = {
        pod_id: podId,
        type: 'ANY',
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.user_id,
        label: `${each.first_name} ${each.last_name}`,
      }))
      setinternalInterviewers(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getinternalInterviewers()
  }, [])

  const [jobRoles, setjobroleData] = useState([])

  const getjobRoles = async () => {
    try {
      const url = `/pod/vacancies/active`
      const formData = {
        pod_id: podId,
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.vacancy_id,
        label: each.job_role_name,
      }))
      setjobroleData(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getjobRoles()
  }, [])

  const getinterviewTypes = async () => {
    try {
      const url = `/pod/interview_type/active`
      const formData = {
        pod_id: podId,
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.interview_type_id,
        label: each.interview_type,
      }))
      setinterviewTypes(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getinterviewTypes()
  }, [])

  const getlocations = async () => {
    try {
      const url = `/pod/locations/active`
      const formData = {
        pod_id: podId,
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.location_name,
        label: `${each.location_name} ${','} ${each.address}`,
      }))
      setlocations(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getlocations()
  }, [])

  const fetchingMissedData = async () => {
    await Promise.all([
      getinterviewTypes(),
      getjobRoles(),
      getCandidates(),

      getinternalInterviewers(),
    ])
  }
  const [data, setData] = useState<Data>({
    candidate_id: ' ',
    interviewer_id: ' ',
    interview_type_id: '',
    url: '',
    meeting_id: '',
    passcode: '',
    mode: '',
    pod_id: podId,
    status: 'Scheduled',
    address: '',
    contact_person: '',
    contact_number: '',
    interview_date: '',
    interview_start_time: '',
    interview_end_time: '',
    interview_round: '',
    vacancy_id: '',
    interview_description: '',
  })

  const [currentStep, setCurrentStep] = useState(0)
  const set2ndIndex = () => {
    setCurrentStep(1)
  }
  const prevStep = (): void => {
    if (currentStep !== 0) {
      setCurrentStep(currentStep - 1)
    }
  }

  const addTeamMember = async (formData: any) => {
    const formattedStartDate = formData.interview_date
      ? format(new Date(formData.interview_date), 'yyyy-MM-dd')
      : ''

    const updatedData = {
      pod_id: podId,
      candidate_id: data.candidate_id,
      interviewer_id: data.interviewer_id,
      interview_type_id: data.interview_type_id,
      interview_description: formData.interview_description,
      interview_coordinates: {
        mode: formData.mode,
        location: {
          url: formData.url,
          meeting_id: formData.meeting_id,
          passcode: formData.passcode,
        },
      },
      vacancy_id: data.vacancy_id,
      interview_round: formData.interview_round,
      interview_date: formattedStartDate,
      interview_start_time: formData.interview_start_time,
      interview_end_time: formData.interview_end_time,
      status: 'Scheduled',
    }

    const offlinedata = {
      pod_id: podId,
      candidate_id: data.candidate_id,
      interviewer_id: data.interviewer_id,
      interview_type_id: data.interview_type_id,
      interview_description: formData.interview_description,
      interview_coordinates: {
        mode: formData.mode,
        location: {
          address: formData.address,
          contact_number: formData.contact_number,
          contact_person: formData.contact_person,
        },
      },
      vacancy_id: data.vacancy_id,
      interview_round: formData.interview_round,
      interview_date: formattedStartDate,
      interview_start_time: formData.interview_start_time,
      interview_end_time: formData.interview_end_time,
      status: 'Scheduled',
    }

    try {
      const url = `/pod/interview/create`
      let postData

      if (formData.mode === 'online') {
        postData = updatedData
      } else {
        postData = offlinedata
      }
      const response = await execute(url, 'POST', postData)
      if (response?.status === true) {
        props.fetchInterviewsData()
        props.setShowModal(false)
        setData({
          candidate_id: ' ',
          interviewer_id: ' ',
          interview_type_id: '',
          url: '',
          mode: '',
          meeting_id: '',
          passcode: '',
          status: 'Scheduled',
          address: '',
          contact_person: '',
          contact_number: '',
          interview_date: '',
          interview_start_time: '',
          interview_end_time: '',
          interview_round: '',
          vacancy_id: '',
          interview_description: '',

          pod_id: '',
        })
        setCurrentStep(0)
      }
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
    } catch (error: any) {
      console.error('Error:', error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }
  const nextStep = () => {
    if (currentStep === 1) {
      const step2Btn = document.getElementById('step2_submit_btn')
      if (step2Btn) {
        step2Btn.click()
      }
    }
    if (currentStep === 0) {
      const step1Btn = document.getElementById('step1_submit_btn')
      if (step1Btn) {
        step1Btn.click()
      }
    }
  }

  const steps = [
    <Step1
      data={data}
      setData={setData}
      set2ndIndex={set2ndIndex}
      jobrolesData={jobRoles}
      candidatesData={candidatesData}
      internalData={internalInterviewers}
      interviewtypesData={interviewTypes}
      fetchCandidates={getCandidates}
      fetchjobroles={getjobRoles}
      fetchinternalinterviewers={getinternalInterviewers}
      fetchinterviewtypes={getinterviewTypes}
      departmentData={departments}
      seededinterviewtypes={seededinterviewtypes}
      getseededinterviewTypes={getseededinterviewTypes}
    />,
    <Step2 data={data} setData={setData} addTeamMember={addTeamMember} locationsData={locations} />,
  ]
  const isDataMissing =
    candidatesData.length === 0 ||
    internalInterviewers.length === 0 ||
    jobRoles.length === 0 ||
    (interviewTypes.length === 0 && seededinterviewtypes.length === 0)
  return (
    <Modal
      show={props.showModal}
      onHide={() => props.setShowModal(false)}
      centered
      dialogClassName='custom-modal modal-dialog-centered'
      contentClassName='custom-modal-content'
    >
      <Modal.Body>
        <div className='card py-0 min-w-600px'>
          <div className='w-100 m-auto min-h-400px'>
            <div className='d-flex justify-content-between mb-10'>
              <h4 className='fw-semibold'>Schedule Interview</h4>
              <button
                type='button'
                className='d-block btn btn-sm btn-icon btn-light'
                onClick={() => props.setShowModal(false)}
              >
                <KTIcon iconName='cross' className='fs-1' />
              </button>
            </div>
            {/* <div className='w-100 d-flex flex-column flex-lg-row justify-content-between'> */}
            {isDataMissing ? (
              <>
                <div className='w-100 row m-0'>
                  <div className='col-lg-5 p-0 mb-5 mb-lg-0'>
                    <div className=''>
                      <div className='d-flex'>
                        <div
                          style={{borderRadius: '10px'}}
                          className={clsx(
                            'w-40px h-40px btn d-flex justify-content-center align-items-center',
                            {
                              'btn-primary': currentStep === 0,
                              'btn-light-primary': currentStep !== 0,
                            }
                          )}
                        >
                          {currentStep === 0 ? 1 : <i className='stepper-check fas fa-check'></i>}
                        </div>
                        <div className='ms-5 text-muted'>
                          <h6
                            className={clsx('mb-0 text-muted', {
                              'text-muted': currentStep >= 0,
                            })}
                          >
                            Step 1
                          </h6>
                          <p
                            className={clsx('fs-6 mb-0 text-muted', {
                              'text-muted': currentStep >= 0,
                            })}
                          >
                            Interview Details
                          </p>
                        </div>
                      </div>
                      <div className='ms-6 h-30px border-left border-dashed border-muted border-top-0 border-bottom-0 border-right-0 border-left-1'></div>
                      <div className='d-flex'>
                        <div
                          style={{borderRadius: '10px'}}
                          className={clsx(
                            'w-40px h-40px btn d-flex justify-content-center align-items-center',
                            {
                              'btn-primary': currentStep === 1,
                              'btn-light-primary': currentStep !== 1,
                            }
                          )}
                        >
                          {currentStep > 1 ? <i className='stepper-check fas fa-check'></i> : 2}
                        </div>
                        <div className='ms-5 text-muted'>
                          <h6
                            className={clsx('mb-0 text-muted', {
                              'text-muted': currentStep >= 1,
                            })}
                          >
                            Step 2
                          </h6>
                          <p
                            className={clsx('fs-6 text-muted', {
                              'text-muted': currentStep >= 1,
                            })}
                          >
                            Location and Date
                          </p>
                        </div>
                      </div>
                      {/* Add more steps here */}
                    </div>
                  </div>
                  <div className='col-lg-7 p-0 m-0'>{steps[currentStep]}</div>
                </div>
              </>
            ) : (
              <div className='w-100 row m-0'>
                <div className='col-lg-5 p-0 mb-5 mb-lg-0'>
                  <div className=''>
                    <div className='d-flex'>
                      <div
                        style={{borderRadius: '10px'}}
                        className={clsx(
                          'w-40px h-40px btn d-flex justify-content-center align-items-center',
                          {
                            'btn-primary': currentStep === 0,
                            'btn-light-primary': currentStep !== 0,
                          }
                        )}
                      >
                        {currentStep === 0 ? 1 : <i className='stepper-check fas fa-check'></i>}
                      </div>
                      <div className='ms-5 fw-semibold'>
                        <h6
                          className={clsx('mb-0 fw-semibold', {
                            'text-muted': currentStep >= 0,
                          })}
                        >
                          Step 1
                        </h6>
                        <p
                          className={clsx('fs-6 mb-0 fw-semibold', {
                            'text-muted': currentStep >= 0,
                          })}
                        >
                          Interview Details
                        </p>
                      </div>
                    </div>
                    <div className='ms-6 h-30px border-left border-dashed border-muted border-top-0 border-bottom-0 border-right-0 border-left-1'></div>
                    <div className='d-flex'>
                      <div
                        style={{borderRadius: '10px'}}
                        className={clsx(
                          'w-40px h-40px btn d-flex justify-content-center align-items-center',
                          {
                            'btn-primary': currentStep === 1,
                            'btn-light-primary': currentStep !== 1,
                          }
                        )}
                      >
                        {currentStep > 1 ? <i className='stepper-check fas fa-check'></i> : 2}
                      </div>
                      <div className='ms-5 fw-semibold'>
                        <h6
                          className={clsx('mb-0 fw-semibold', {
                            'text-muted': currentStep >= 1,
                          })}
                        >
                          Step 2
                        </h6>
                        <p
                          className={clsx('fs-6 fw-semibold', {
                            'text-muted': currentStep >= 1,
                          })}
                        >
                          Location and Date
                        </p>
                      </div>
                    </div>
                    {/* Add more steps here */}
                  </div>
                </div>
                <div className='col-lg-7 p-0 m-0'>{steps[currentStep]}</div>
              </div>
            )}

            <div className='w-100 d-flex flex-stack mt-5'>
              <div className='mr-2'>
                {currentStep > 0 && (
                  <button onClick={prevStep} type='button' className='btn btn-sm btn-light me-3'>
                    <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                    Back
                  </button>
                )}
              </div>

              {isDataMissing ? (
                <button
                  type='button'
                  className='btn btn-sm btn-light-primary ms-3'
                  onClick={() => {
                    fetchingMissedData()
                  }}
                >
                  <i className='fas fa-refresh text-primary me-2'></i>
                  Refresh
                </button>
              ) : (
                <div>
                  <button type='submit' className='btn btn-sm btn-light-primary' onClick={nextStep}>
                    {currentStep === steps.length - 1 ? (
                      <>
                        {postLoading && (
                          <div
                            className='spinner-border spinner-primary mr-15'
                            style={{height: '12px', width: '12px'}}
                          ></div>
                        )}
                        Schedule Interview
                      </>
                    ) : (
                      'Continue'
                    )}

                    <KTIcon iconName='arrow-right' className='fs-3 ms-1' />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const addTeamMemberSchema = Yup.object().shape({
  candidate_id: Yup.string().required('Candidate is required'),
  interviewer_id: Yup.string().required('Interviewer is required'),
  interview_round: Yup.string().required('Interview Round is required'),

  interview_type_id: Yup.string().required('Interview type is required'),

  // vacancy_id: Yup.string().required('Job role is required'),
})

const Step1 = (props: any) => {
  const {id} = useParams()
  const [showKM, setShowKM] = useState(false)
  const [addModal, setAddModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const candidatesData = props.candidatesData
  const internalInterviewers = props.internalData
  const departments = props.departmentData
  const jobRoles = props.jobrolesData
  const interviewTypes =
    props.interviewtypesData.length > 0 ? props.interviewtypesData : props.seededinterviewtypes
  const [interviewerType, setInterviewerType] = useState('')
  const seededinterviewtypes = props.seededinterviewtypes
  const [loading, setLoading] = useState(true)
  const {mode} = useThemeMode()
  const onSubmit = (values: any) => {
    props.setData({...props.data, ...values})
    props.set2ndIndex()
  }

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        props.fetchCandidates(),
        props.getseededinterviewTypes(),
        props.fetchjobroles(),
        props.fetchinternalinterviewers(),

        props.fetchinterviewtypes(),
      ])
      setLoading(false)
    }

    fetchData()
  }, [])

  const handleinterviewTypesChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('interview_type_id', selectedOption.value)
    } else {
      setFieldValue('interview_type_id', '') // Handle case where no option is selected
    }
  }
  const handleInternalChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('interviewer_id', selectedOption.value)
    } else {
      setFieldValue('interviewer_id', '') // Handle case where no option is selected
    }
  }

  const handleCandidateChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('candidate_id', selectedOption.value)
    } else {
      setFieldValue('candidate_id', '') // Handle case where no option is selected
    }
  }

  const handlejobRoleChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('vacancy_id', selectedOption.value)
    } else {
      setFieldValue('vacancy_id', '') // Handle case where no option is selected
    }
  }

  const handledepartmentChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('department_id', selectedOption.value)
    } else {
      setFieldValue('department_id', '') // Handle case where no option is selected
    }
  }

  return (
    <>
      <CreateNewCandidatesModal show={showKM} onClose={() => setShowKM(false)} />
      <CreateNewVacancyModal show={showModal} onClose={() => setShowModal(false)} />
      <AddTeamMemberModal
        showModal={addModal}
        setShowModal={setAddModal}
        fetchAllTeamMembers={function (): void {
          throw new Error('Function not implemented.')
        }}
      />
      <Formik validationSchema={addTeamMemberSchema} initialValues={props.data} onSubmit={onSubmit}>
        {({setFieldValue, values, handleChange}: any) => {
          if (loading) {
            return (
              <div className='m-auto d-flex flex-column align-items-center'>
                <div className='spinner-border spinner-primary mr-15'></div>
                <p className='mt-6 fw-semibold'>Verifying if all prerequisites are in place…</p>
              </div>
            )
          }

          // Check if any required data is null
          const isDataMissing =
            candidatesData.length === 0 ||
            internalInterviewers.length === 0 ||
            jobRoles.length === 0 ||
            (interviewTypes.length === 0 && seededinterviewtypes.length === 0)

          return (
            <Form className=''>
              <>
                {isDataMissing ? (
                  <>
                    <div className=''>
                      {/* <p className='fw-semibold fs-4'>
                        Before you schedule a interview, please ensure all necessary prerequisite
                        data are available
                      </p> */}
                      <div className='alert alert-primary d-flex align-items-center p-1'>
                        <KTIcon iconName='information' className='fs-2 text-primary me-2' />
                        <div className='d-flex flex-column'>
                          <h6 className='mb-0 fw-semibold fs-7 text-dark'>Note:</h6>
                          <span className='fs-8 text-dark'>
                            Before you schedule a interview, please ensure all necessary
                            prerequisite data are available
                          </span>
                        </div>
                      </div>
                      <div className='d-flex '>
                        <div className='flex-column w-50 me-2'>
                          <div className='text-success bg-secondary fs-4'>
                            <p className='ms-2 fw-semibold'>Available</p>
                          </div>
                          {candidatesData.length !== 0 && (
                            <div className='d-flex align-items-center mb-1'>
                              <KTIcon iconName='check-circle' className='text-success fs-2 me-1' />
                              <div className='fs-6'>Candidates</div>
                            </div>
                          )}
                          {internalInterviewers.length !== 0 && (
                            <div className='d-flex align-items-center mb-1'>
                              <KTIcon iconName='check-circle' className='text-success fs-2 me-1' />
                              <div className='fs-6'>Interviewers</div>
                            </div>
                          )}

                          {jobRoles.length !== 0 && (
                            <div className='d-flex align-items-center mb-1'>
                              <KTIcon iconName='check-circle' className='text-success fs-2 me-1' />
                              <div className='fs-6'>Vacancies</div>
                            </div>
                          )}
                          {(interviewTypes.length !== 0 || seededinterviewtypes.length !== 0) && (
                            <div className='d-flex align-items-center mb-1'>
                              <KTIcon iconName='check-circle' className='text-success fs-2 me-1' />
                              <div className='fs-6'>Interview types</div>
                            </div>
                          )}
                        </div>
                        <div className='flex-column w-50'>
                          <div className='text-danger bg-secondary fs-4'>
                            <p className='ms-2 fw-semibold'>Missing</p>
                          </div>
                          {candidatesData.length === 0 && (
                            <div className='d-flex align-items-center mb-1'>
                              <KTIcon iconName='cross-circle' className='text-danger fs-2 me-1' />

                              <div className='me-2 fs-6 w-100'>Candidates</div>

                              <i
                                className='ki-solid ki-plus-square fs-2 hover-primary'
                                onClick={() => setShowKM(true)}
                              ></i>
                            </div>
                          )}

                          {jobRoles.length === 0 && (
                            <div className='d-flex align-items-center mb-1'>
                              <KTIcon iconName='cross-circle' className='text-danger fs-2 me-1' />
                              <div className='me-2 fs-6 w-100'>Vacancies</div>

                              <i
                                className='ki-solid ki-plus-square fs-2 hover-primary'
                                onClick={() => setShowModal(true)}
                              ></i>
                            </div>
                          )}
                          {internalInterviewers.length === 0 && (
                            <div className='d-flex align-items-center mb-1'>
                              <KTIcon iconName='cross-circle' className='text-danger fs-2 me-1' />
                              <div className='me-3 fs-6 w-100'>Interviewers </div>

                              <i
                                className='ki-solid ki-plus-square fs-2 hover-primary'
                                onClick={() => setAddModal(true)}
                              ></i>
                              {/* </button> */}
                            </div>
                          )}
                          {/* {hiringManager === null && (
                          <div className='d-flex align-items-center mb-3'>
                            <KTIcon iconName='cross-circle' className='text-danger fs-2 me-1' />
                            <div className='fs-6'>Hiring Manager </div>
                          </div>
                        )} */}

                          {interviewTypes.length === 0 && seededinterviewtypes.length === 0 && (
                            <div className='d-flex align-items-center'>
                              <KTIcon iconName='cross-circle' className='text-danger fs-2 me-1' />
                              <div className='fs-6 me-3 w-100'>Interview types</div>
                              {/* <button
                                className='btn btn-sm btn-light-primary'
                                onClick={() =>
                                  window.open(
                                    `/${id}/configuration/master-data/interview-types`,
                                    '_blank'
                                  )
                                }
                              >
                                <KTIcon iconName='plus' className='fs-3' />
                              </button> */}
                              <i
                                className='ki-solid ki-plus-square fs-2 hover-primary'
                                onClick={() =>
                                  window.open(
                                    `/${id}/configuration/master-data/interview-types`,
                                    '_blank'
                                  )
                                }
                              ></i>
                            </div>
                          )}
                          {/* Repeat similar structure for other missing data */}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='d-flex'>
                      <div className='w-100 mb-5 me-2'>
                        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                          <span className='required'>Candidate</span>
                          <i
                            className='fas fa-exclamation-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title='Select Candidate'
                          ></i>
                        </label>
                        <Select
                          value={candidatesData.find(
                            (option: any) => option.value === values.candidate_id
                          )}
                          styles={{
                            control: (baseStyles: any, state: any) => ({
                              ...baseStyles,
                              backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                              color: '#ffffff',
                              borderWidth: '0px',
                            }),
                            menu: (baseStyles: any) => ({
                              ...baseStyles,
                              backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                            }),
                            singleValue: (baseStyles: any, state: any) => ({
                              ...baseStyles,
                              color: mode === 'dark' ? '#92929f' : '#000000',
                            }),
                            input: (baseStyles: any) => ({
                              ...baseStyles,
                              color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                            }),
                            option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                              ...baseStyles,
                              backgroundColor: isSelected
                                ? '#C2C2C2'
                                : isFocused
                                ? '#e0e0e0'
                                : baseStyles.backgroundColor,
                              color: isSelected
                                ? '#000000'
                                : isFocused
                                ? '#000000'
                                : baseStyles.color,
                            }),
                          }}
                          theme={(theme: any) => ({
                            ...theme,
                            borderRadius: 5,
                            backgroundColor: '#000000',
                            colors: {
                              ...theme.colors,
                              primary25: 'rgba(80, 107, 236, 0.5)',
                              primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                            },
                          })}
                          className='react-select-container'
                          classNamePrefix='react-select'
                          options={candidatesData}
                          isSearchable
                          placeholder='Select Candidate'
                          onChange={(val: any) => handleCandidateChange(setFieldValue, val)}
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='candidate_id' />
                        </div>
                      </div>

                      <div className='w-100 mb-5 me-2'>
                        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                          <span className='required'>Select Vacancy</span>
                          <i
                            className='fas fa-exclamation-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title='Select Vacancy (Optional)'
                          ></i>
                        </label>
                        <Select
                          value={jobRoles.find((option: any) => option.value === values.vacancy_id)}
                          styles={{
                            control: (baseStyles: any, state: any) => ({
                              ...baseStyles,
                              backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                              color: '#ffffff',
                              borderWidth: '0px',
                            }),
                            menu: (baseStyles: any) => ({
                              ...baseStyles,
                              backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                            }),
                            singleValue: (baseStyles: any, state: any) => ({
                              ...baseStyles,
                              color: mode === 'dark' ? '#92929f' : '#000000',
                            }),
                            input: (baseStyles: any) => ({
                              ...baseStyles,
                              color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                            }),
                            option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                              ...baseStyles,
                              backgroundColor: isSelected
                                ? '#C2C2C2'
                                : isFocused
                                ? '#e0e0e0'
                                : baseStyles.backgroundColor,
                              color: isSelected
                                ? '#000000'
                                : isFocused
                                ? '#000000'
                                : baseStyles.color,
                            }),
                          }}
                          theme={(theme: any) => ({
                            ...theme,
                            borderRadius: 5,
                            backgroundColor: '#000000',
                            colors: {
                              ...theme.colors,
                              primary25: 'rgba(80, 107, 236, 0.5)',
                              primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                            },
                          })}
                          className='react-select-container w-500'
                          classNamePrefix='react-select'
                          options={jobRoles}
                          isSearchable
                          placeholder='Select Vacancy'
                          onChange={(val: any) => handlejobRoleChange(setFieldValue, val)}
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='vacancy_id' />
                        </div>
                      </div>
                    </div>

                    <div className='d-flex'>
                      <div className='w-100 mb-5 me-2'>
                        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                          <span className='required'>Select Interviewer</span>
                          <i
                            className='fas fa-exclamation-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title='Select Interviewer'
                          ></i>
                        </label>
                        <Select
                          value={internalInterviewers.find(
                            (option: any) => option.value === values.user_id
                          )}
                          styles={{
                            control: (baseStyles: any, state: any) => ({
                              ...baseStyles,
                              backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                              color: '#ffffff',
                              borderWidth: '0px',
                            }),
                            menu: (baseStyles: any) => ({
                              ...baseStyles,
                              backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                            }),
                            singleValue: (baseStyles: any, state: any) => ({
                              ...baseStyles,
                              color: mode === 'dark' ? '#92929f' : '#000000',
                            }),
                            input: (baseStyles: any) => ({
                              ...baseStyles,
                              color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                            }),
                            option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                              ...baseStyles,
                              backgroundColor: isSelected
                                ? '#C2C2C2'
                                : isFocused
                                ? '#e0e0e0'
                                : baseStyles.backgroundColor,
                              color: isSelected
                                ? '#000000'
                                : isFocused
                                ? '#000000'
                                : baseStyles.color,
                            }),
                          }}
                          theme={(theme: any) => ({
                            ...theme,
                            borderRadius: 5,
                            backgroundColor: '#000000',
                            colors: {
                              ...theme.colors,
                              primary25: 'rgba(80, 107, 236, 0.5)',
                              primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                            },
                          })}
                          className='react-select-container w-500'
                          classNamePrefix='react-select'
                          options={internalInterviewers}
                          isSearchable
                          placeholder='Select Interviewer'
                          onChange={(val: any) => handleInternalChange(setFieldValue, val)}
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='interviewer_id' />
                        </div>
                      </div>

                      <div className='w-100 mb-5 me-2'>
                        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                          <span className='required'>Interview type</span>
                          <i
                            className='fas fa-exclamation-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title='Select Interview type'
                          ></i>
                        </label>
                        <Select
                          value={interviewTypes.find(
                            (option: any) =>
                              option.value === (interviewTypes || values.interview_type_id)
                          )}
                          styles={{
                            control: (baseStyles: any, state: any) => ({
                              ...baseStyles,
                              backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                              color: '#ffffff',
                              borderWidth: '0px',
                            }),
                            menu: (baseStyles: any) => ({
                              ...baseStyles,
                              backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                            }),
                            singleValue: (baseStyles: any, state: any) => ({
                              ...baseStyles,
                              color: mode === 'dark' ? '#92929f' : '#000000',
                            }),
                            input: (baseStyles: any) => ({
                              ...baseStyles,
                              color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                            }),
                            option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                              ...baseStyles,
                              backgroundColor: isSelected
                                ? '#C2C2C2'
                                : isFocused
                                ? '#e0e0e0'
                                : baseStyles.backgroundColor,
                              color: isSelected
                                ? '#000000'
                                : isFocused
                                ? '#000000'
                                : baseStyles.color,
                            }),
                          }}
                          theme={(theme: any) => ({
                            ...theme,
                            borderRadius: 5,
                            backgroundColor: '#000000',
                            colors: {
                              ...theme.colors,
                              primary25: 'rgba(80, 107, 236, 0.5)',
                              primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                            },
                          })}
                          className='react-select-container w-500'
                          classNamePrefix='react-select'
                          options={interviewTypes}
                          isSearchable
                          placeholder='Select Interview type'
                          onChange={(val: any) => handleinterviewTypesChange(setFieldValue, val)}
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='interview_type_id' />
                        </div>
                      </div>
                    </div>

                    <div className='w-100 mb-5 ms-2'>
                      <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                        <span className='required'>Interview Round</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Enter Round No.'
                        ></i>
                      </label>
                      <Field
                        type='text'
                        name='interview_round'
                        className='form-control form-control-solid'
                        placeholder='Enter Round No.'
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='interview_round' />
                      </div>
                    </div>

                    <div className='d-none d-flex justify-content-end'>
                      <div>
                        <button type='submit' className='btn btn-sm btn-light me-2'>
                          Close
                        </button>
                        <button
                          type='submit'
                          className='btn btn-sm btn-light-primary ms-2'
                          id='step1_submit_btn'
                        >
                          ADD
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

const step2Schema = Yup.object().shape({
  mode: Yup.string().required('Interview Mode is required'),
  interview_description: Yup.string().required('Description is required'),
  interview_date: Yup.string().required('Interview Date is required'),
  interview_start_time: Yup.string().required('Interview Start time is required'),
  interview_end_time: Yup.string().required('Interview End time is required'),
  url: Yup.string().when('mode', {
    is: (mode: any) => mode === 'Online',
    then: () => Yup.string().required('URL is required'),
  }),
  passcode: Yup.string().when('mode', {
    is: (mode: any) => mode === 'Online',
    then: () => Yup.string().required('Passcode is required'),
  }),
  meeting_id: Yup.string().when('mode', {
    is: (mode: any) => mode === 'Online',
    then: () => Yup.string().required('Meeting ID is required'),
  }),

  address: Yup.string().when('mode', {
    is: (mode: any) => mode === 'inperson',
    then: () => Yup.string().required('Address is required'),
  }),
  contact_person: Yup.string().when('mode', {
    is: (mode: any) => mode === 'inperson',
    then: () => Yup.string().required('Contact Person is required'),
  }),
  contact_number: Yup.string().when('mode', {
    is: (mode: any) => mode === 'inperson',
    then: () => Yup.string().required('Contact Person Number is required'),
  }),
})
const Step2 = (props: any) => {
  const podId = FindPodIdByCode()
  const {mode} = useThemeMode()
  const intl = useIntl()
  const [locale, setLocale] = React.useState('en')
  const [selectedDate, setSelectedDate] = useState('')

  const locations = props.locationsData
  const onSubmit = (values: any) => {
    props.setData({...props.data, ...values})
    props.addTeamMember({...props.data, ...values})
  }
  const handleIndustryChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('address', selectedOption.value)
    } else {
      setFieldValue('address', '') // Handle case where no option is selected
    }
  }

  return (
    <>
      <Formik validationSchema={step2Schema} initialValues={props.data} onSubmit={onSubmit}>
        {({setFieldValue, values}: any) => {
          return (
            <Form className='min-w-350px'>
              <div className='d-flex'>
                <div className='w-100 mb-5'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Interview Mode</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Select Interview Mode'
                    ></i>
                  </label>
                  <div>
                    <button
                      style={{
                        borderRadius: '0px',
                        borderTopLeftRadius: '24px',
                        borderBottomLeftRadius: '24px',
                      }}
                      name='status'
                      className={clsx('btn btn-sm', {
                        'btn-primary': values.mode === 'Online',
                        'btn-light-primary': values.mode !== 'Online',
                      })}
                      type='button'
                      onClick={() => setFieldValue('mode', 'Online')}
                    >
                      Online
                    </button>
                    <button
                      style={{
                        borderRadius: '0px',
                        borderTopRightRadius: '24px',
                        borderBottomRightRadius: '24px',
                      }}
                      name='status'
                      className={clsx('btn btn-sm', {
                        'btn-primary': values.mode === 'inperson',
                        'btn-light-primary': values.mode !== 'inperson',
                      })}
                      type='button'
                      onClick={() => setFieldValue('mode', 'inperson')}
                    >
                      Offline
                    </button>
                  </div>
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='mode' />
                  </div>
                </div>
                {values.mode === 'inperson' && (
                  <div className='w-100 mb-5'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>Interview Location</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Select Interview Location'
                      ></i>
                    </label>
                    <div>
                      <button
                        style={{
                          borderRadius: '0px',
                          borderTopLeftRadius: '24px',
                          borderBottomLeftRadius: '24px',
                        }}
                        name='status'
                        className={clsx('btn btn-sm', {
                          'btn-primary': values.interview_location === 'Office',
                          'btn-light-primary': values.interview_location !== 'Office',
                        })}
                        type='button'
                        onClick={() => setFieldValue('interview_location', 'Office')}
                      >
                        Office
                      </button>
                      <button
                        style={{
                          borderRadius: '0px',
                          borderTopRightRadius: '24px',
                          borderBottomRightRadius: '24px',
                        }}
                        name='status'
                        className={clsx('btn btn-sm', {
                          'btn-primary': values.interview_location === 'Outside',
                          'btn-light-primary': values.interview_location !== 'Outside',
                        })}
                        type='button'
                        onClick={() => setFieldValue('interview_location', 'Outside')}
                      >
                        Outside
                      </button>
                    </div>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='interview_location' />
                    </div>
                  </div>
                )}
              </div>
              {values.mode === 'Online' && (
                <>
                  {/* URL field */}
                  <div className='d-flex'>
                    <div className='w-100 mb-5 me-2'>
                      <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                        <span className='required'>URL</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Enter URL'
                        ></i>
                      </label>
                      <Field
                        type='text'
                        name='url'
                        className='form-control form-control-solid'
                        placeholder='Enter URL'
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='url' />
                      </div>
                    </div>

                    {/* Passcode field */}
                    <div className='w-100 mb-5'>
                      <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 ms-2'>
                        <span className='required'>Passcode</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Enter Passcode'
                        ></i>
                      </label>
                      <Field
                        type='text'
                        name='passcode'
                        className='form-control form-control-solid'
                        placeholder='Enter Passcode'
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='passcode' />
                      </div>
                    </div>
                  </div>

                  {/* Meeting field */}
                  <div className='w-100 mb-5'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>Meeting</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Enter Meeting Id here'
                      ></i>
                    </label>
                    <Field
                      type='text'
                      name='meeting_id'
                      className='form-control form-control-solid'
                      placeholder='Enter Meeting Id here'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='meeting_id' />
                    </div>
                  </div>
                </>
              )}

              {values.mode === 'inperson' && values.interview_location === 'Office' && (
                <>
                  {/* Address field */}
                  <div className='d-flex'>
                    <div className='w-100 mb-5 me-2'>
                      <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                        <span className='required'>Address</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Enter Address'
                        ></i>
                      </label>
                      <Select
                        value={locations.find(
                          (option: any) => option.value === values.location_name
                        )}
                        styles={{
                          control: (baseStyles: any, state: any) => ({
                            ...baseStyles,
                            backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                            color: '#ffffff',
                            borderWidth: '0px',
                          }),
                          menu: (baseStyles: any) => ({
                            ...baseStyles,
                            backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                          }),
                          singleValue: (baseStyles: any, state: any) => ({
                            ...baseStyles,
                            color: mode === 'dark' ? '#92929f' : '#000000',
                          }),
                          input: (baseStyles: any) => ({
                            ...baseStyles,
                            color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                          }),
                          option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                            ...baseStyles,
                            backgroundColor: isSelected
                              ? '#C2C2C2'
                              : isFocused
                              ? '#e0e0e0'
                              : baseStyles.backgroundColor,
                            color: isSelected
                              ? '#000000'
                              : isFocused
                              ? '#000000'
                              : baseStyles.color,
                          }),
                        }}
                        theme={(theme: any) => ({
                          ...theme,
                          borderRadius: 5,
                          backgroundColor: '#000000',
                          colors: {
                            ...theme.colors,
                            primary25: 'rgba(80, 107, 236, 0.5)',
                            primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                          },
                        })}
                        className='react-select-container'
                        classNamePrefix='react-select'
                        options={locations}
                        isSearchable
                        placeholder='Select Location'
                        onChange={(val: any) => handleIndustryChange(setFieldValue, val)}
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='address' />
                      </div>
                    </div>

                    {/* Contact person field */}
                    {/* <div className='w-100 mb-5 ms-2'>
                      <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                        <span className='required'>Contact Person</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Enter Contact Person Name'
                        ></i>
                      </label>
                      <Field
                        type='text'
                        name='contact_person'
                        className='form-control form-control-solid'
                        placeholder='Enter Contact Person Name'
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='contact_person' />
                      </div>
                    </div> */}
                  </div>
                  <div className='d-flex'>
                    <div className='w-100 mb-5 me-2'>
                      <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                        <span className='required'>Contact Person</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Enter Contact Person Name'
                        ></i>
                      </label>
                      <Field
                        type='text'
                        name='contact_person'
                        className='form-control form-control-solid'
                        placeholder='Enter Contact Person Name'
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='contact_person' />
                      </div>
                    </div>

                    {/* Contact person (number) field */}
                    <div className='w-100 mb-5'>
                      <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 ms-2'>
                        <span className='required'>Contact Person (Number)</span>
                        <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Enter Contact Person Number'
                        ></i>
                      </label>
                      <Field
                        type='number'
                        name='contact_number'
                        className='form-control form-control-solid'
                        placeholder='Enter Contact Person Number'
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='contact_number' />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {values.mode === 'inperson' && values.interview_location === 'Outside' && (
                <>
                  <>
                    {/* Address field */}
                    <div className='d-flex'>
                      <div className='w-100 mb-5 me-2'>
                        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                          <span className='required'>Address</span>
                          <i
                            className='fas fa-exclamation-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title='Enter Address'
                          ></i>
                        </label>
                        <Field
                          type='text'
                          name='address'
                          className='form-control form-control-solid'
                          placeholder='Enter Address'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='address' />
                        </div>
                      </div>

                      {/* Contact person field */}
                      {/* <div className='w-100 mb-5 ms-2'>
                        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                          <span className='required'>Contact Person</span>
                          <i
                            className='fas fa-exclamation-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title='Enter Contact Person Name'
                          ></i>
                        </label>
                        <Field
                          type='text'
                          name='contact_person'
                          className='form-control form-control-solid'
                          placeholder='Enter Contact Person Name'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='contact_person' />
                        </div>
                      </div> */}
                    </div>

                    {/* Contact person (number) field */}
                    <div className='d-flex'>
                      <div className='w-100 mb-5 me-2'>
                        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                          <span className='required'>Contact Person</span>
                          <i
                            className='fas fa-exclamation-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title='Enter Contact Person Name'
                          ></i>
                        </label>
                        <Field
                          type='text'
                          name='contact_person'
                          className='form-control form-control-solid'
                          placeholder='Enter Contact Person Name'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='contact_person' />
                        </div>
                      </div>

                      {/* Contact person (number) field */}
                      <div className='w-100 mb-5'>
                        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 ms-2'>
                          <span className='required'>Contact Person (Number)</span>
                          <i
                            className='fas fa-exclamation-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title='Enter Contact Person Number'
                          ></i>
                        </label>
                        <Field
                          type='number'
                          name='contact_number'
                          className='form-control form-control-solid'
                          placeholder='Enter Contact Person Number'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='contact_number' />
                        </div>
                      </div>
                    </div>
                  </>
                </>
              )}
              <div className='w-100 mb-5'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Interview Date</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Enter Interview Date'
                  ></i>
                </label>

                <Field name='interview_date'>
                  {({form, field}: any) => {
                    const {setFieldValue} = form
                    const {value} = field

                    const handleChange = (date: any) => {
                      setFieldValue('interview_date', date)
                      setSelectedDate(date)
                    }

                    return (
                      <div
                        className={`react-datepicker-fullwidth-wrapper test ${
                          mode === 'dark' ? 'dark-mode' : 'light-mode'
                        }`}
                      >
                        <DatePicker
                          selected={value}
                          minDate={new Date()}
                          isClearable
                          showYearDropdown
                          scrollableYearDropdown
                          onChange={(date: any) => {
                            handleChange(date)
                          }}
                          placeholderText={intl.formatMessage({
                            id: 'Select Date',
                          })}
                          showMonthDropdown
                          useShortMonthInDropdown
                          dateFormat='MM/dd/yyyy'
                          timeInputLabel='Time:'
                          className='form-control form-control-solid'
                          popperPlacement='bottom'
                          locale={locale}
                        />
                      </div>
                    )
                  }}
                </Field>
                <div className='text-danger mt-2'>
                  <ErrorMessage name='interview_date' />
                </div>
              </div>

              <div className='d-flex'>
                <div className='w-100 mb-5 ms-2'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Interview Start time</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Enter Start time.'
                    ></i>
                  </label>
                  <Field
                    type='time'
                    name='interview_start_time'
                    className='form-control form-control-solid'
                    placeholder='Enter Start time.'
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='interview_start_time' />
                  </div>
                </div>

                <div className='w-100 mb-5 ms-2'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Interview End time</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Enter End time.'
                    ></i>
                  </label>
                  <Field
                    type='time'
                    name='interview_end_time'
                    className='form-control form-control-solid'
                    placeholder='Enter End time.'
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='interview_end_time' />
                  </div>
                </div>
              </div>

              <div className='w-100 mb-5'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>Interview Description</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Enter Some Details about Interview Process'
                  ></i>
                </label>

                <Field
                  as='textarea'
                  className={`form-control form-control form-control-solid`}
                  name='interview_description'
                  placeholder='Enter Some Details about Interview Process.For Eg:Duration,Format,Follow-up,topics.'
                  rows={3}
                />

                <div className='text-danger mt-2'>
                  <ErrorMessage name='interview_description' />
                </div>
              </div>

              <button type='submit' className='d-none btn btn-light-primary' id='step2_submit_btn'>
                Add
              </button>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default ScheduleInterviewModal
