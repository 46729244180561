import {FormattedMessage, useIntl} from 'react-intl'
import {useEffect, useMemo, useState} from 'react'
import {useTable, usePagination} from 'react-table'
import {Pagination} from 'react-bootstrap'
import usePageTitle from '../../../custom_hooks/usePageTitle'
import {KTIcon} from '../../../../../_metronic/helpers'
import {FindPodIdByCode} from '../../../FindPodIdByPodCode'
import {OverlayTrigger, Popover, Tooltip} from 'react-bootstrap'
import NoDataFound from '../../no_data_found/NoDataFound'
import NoDataIcon from './qualification.png'
import usePostApi from '../../../custom_hooks/usePostApi'
import {AddQualification} from './AddQualification'
import {EditQualification} from './EditQualification'
const DataTable = ({columns, data, loading, refreshData}) => {
  const [inputVal, setInputVal] = useState('')
  const [dataType, setDataType] = useState('all') // Default to 'all' to show all data initially
  const filterByDataType = (data) => {
    if (dataType === 'seeded') {
      return data.filter((record) => record.qualification_pod_id === 0)
    }
    if (dataType === 'user') {
      return data.filter((record) => record.qualification_pod_id !== 0)
    }
    return data // Return all data if no specific type is selected
  }
  const intl = useIntl()
  const [popover, setPopover] = useState(null)
  const [id, updateId] = useState('')
  const ClosePopover = () => {
    setPopover(null)
  }
  const openPopover = (id) => (
    <Popover flip='true' placement='auto' className='min-w-400px'>
      <AddQualification props={id} />
    </Popover>
  )
  usePageTitle(intl.formatMessage({id: 'MENU.HR_CONFIGURATION.DESIGNATIONS'}))
  const filteredData = useMemo(() => {
    const dataTypeFiltered = filterByDataType(data)
    const sortedData = dataTypeFiltered.sort(
      (a, b) => b.qualification_pod_id - a.qualification_pod_id
    )
    return sortedData.filter((each) =>
      each.qualification_name.toLowerCase().includes(inputVal.toLowerCase())
    )
  }, [data, inputVal, dataType])
  const activeData = useMemo(() => {
    return filteredData.filter((record) => record.is_active === 1)
  }, [filteredData])
  const inactiveData = useMemo(() => {
    return filteredData.filter((record) => record.is_active === 0)
  }, [filteredData])

  // Table instances for active and inactive datasets
  const activeTableInstance = useTable({columns, data: activeData}, usePagination)
  const inactiveTableInstance = useTable({columns, data: inactiveData}, usePagination)

  // Extract required values from table instances
  const activePage = activeTableInstance.page
  const activePageCount = activeTableInstance.pageCount
  const activePageIndex = activeTableInstance.state.pageIndex
  const activePageSize = activeTableInstance.state.pageSize

  const inactivePage = inactiveTableInstance.page
  const inactivePageCount = inactiveTableInstance.pageCount
  const inactivePageIndex = inactiveTableInstance.state.pageIndex
  const inactivePageSize = inactiveTableInstance.state.pageSize

  // Handle pagination for active dataset
  const gotoActivePage = (pageIndex) => {
    activeTableInstance.gotoPage(pageIndex)
  }

  const setActivePageSize = (pageSize) => {
    activeTableInstance.setPageSize(pageSize)
  }

  // Handle pagination for inactive dataset
  const gotoInactivePage = (pageIndex) => {
    inactiveTableInstance.gotoPage(pageIndex)
  }

  const setInactivePageSize = (pageSize) => {
    inactiveTableInstance.setPageSize(pageSize)
  }
  const pageOptions = [5, 10, 20, 30, 40, 50]
  // Render the component
  return (
    <>
      {loading ? (
        <div className='mt-6 h-250px d-flex justify-content-center align-items-center'>
          <div className='spinner-border spinner-primary mr-15'></div>
        </div>
      ) : data.length > 0 ? (
        <div>
          <div className='card p-10 py-5 pt-0'>
            <div className='card-header border-0 px-0'>
              <div className='d-flex flex-wrap flex-stack'>
                <div
                  className='btn-group btn-group-sm me-2'
                  role='group'
                  aria-label='Large button group'
                >
                  <button
                    type='button'
                    className={`btn btn-active-primary fw-normal btn-color-muted border ${
                      dataType === 'all' ? 'active' : ''
                    }`}
                    onClick={() => setDataType(dataType === 'all' ? 'all' : 'all')}
                  >
                    All
                  </button>
                  <button
                    type='button'
                    className={`btn btn-active-primary fw-normal btn-color-muted border ${
                      dataType === 'seeded' ? 'active' : ''
                    }`}
                    onClick={() => setDataType(dataType === 'seeded' ? 'all' : 'seeded')}
                  >
                    Seeded
                  </button>
                  <button
                    type='button'
                    className={`btn btn-active-primary fw-normal btn-color-muted border ${
                      dataType === 'user' ? 'active' : ''
                    }`}
                    onClick={() => setDataType(dataType === 'user' ? 'all' : 'user')}
                  >
                    User
                  </button>
                </div>
                <div className='d-flex'>
                  <ul className='nav'>
                    <li className='nav-item'>
                      <a
                        className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-success active fw-normal px-4 me-1 ms-0'
                        data-bs-toggle='tab'
                        href='#kt_table_widget_4_tab_1'
                      >
                        {intl.formatMessage({id: 'LABEL.ACTIVE'})}
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-danger fw-normal px-4 me-1'
                        data-bs-toggle='tab'
                        href='#kt_table_widget_4_tab_2'
                      >
                        {intl.formatMessage({id: 'LABEL.INACTIVE'})}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='card-toolbar'>
                <div className='d-flex align-items-center position-relative me-2'>
                  <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                  <input
                    type='search'
                    id='kt_filter_search'
                    className='form-control form-control-white form-control-sm w-150px ps-9 fw-normal'
                    placeholder={intl.formatMessage({id: 'LABEL.SEARCH'})}
                    value={inputVal}
                    onChange={(e) => setInputVal(e.target.value)}
                    autoComplete='off'
                  />
                </div>
                <OverlayTrigger
                  trigger='click'
                  placement='auto'
                  overlay={openPopover(id)}
                  show={popover === 'add_qualification'}
                >
                  <button
                    className='btn btn-sm btn-light-primary fw-normal'
                    onClick={(e) => {
                      e.stopPropagation()
                      setPopover('add_qualification')
                      updateId({refreshData, ClosePopover})
                    }}
                  >
                    <KTIcon iconName='plus' className='fs-3' />
                    Add Qualification
                  </button>
                </OverlayTrigger>
              </div>
            </div>
            <div>
              <div className='tab-content'>
                <div
                  className='tab-pane fade show active table-responsive'
                  id='kt_table_widget_4_tab_1'
                >
                  <table
                    className='table table-row-dashed align-middle table-row-gray-300'
                    {...activeTableInstance.getTableProps()}
                  >
                    <thead>
                      <tr className='fw-semibold text-muted default-cursor bg-light rounded'>
                        <th className='min-w-140px ps-3'>Qualification Name</th>
                        <th className='min-w-140px'>Qualification Description</th>
                        <th className='min-w-140px'>{intl.formatMessage({id: 'LABEL.STATUS'})}</th>
                        <th className='min-w-120px'>{intl.formatMessage({id: 'LABEL.ACTION'})}</th>
                      </tr>
                    </thead>
                    <tbody {...activeTableInstance.getTableBodyProps()}>
                      {activePage.length > 0 ? (
                        activePage.map((row) => {
                          activeTableInstance.prepareRow(row)
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => (
                                <td className=' ' {...cell.getCellProps()}>
                                  {cell.render('Cell')}
                                </td>
                              ))}
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td className='' colSpan={3}>
                            <div className='d-flex justify-content-center'>
                              <p className='text-dark default-cursor fs-6 mt-5'>
                                {inputVal === '' ? (
                                  intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
                                ) : (
                                  <FormattedMessage
                                    id='MSG.NO_DATA_MATCH'
                                    values={{
                                      inputVal: <span className='text-primary'>{inputVal}</span>,
                                    }}
                                  />
                                )}
                              </p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {activeData.length > 10 && (
                    <Pagination>
                      <Pagination.First
                        onClick={() => gotoActivePage(0)}
                        disabled={!activeTableInstance.canPreviousPage}
                      />
                      <Pagination.Prev
                        onClick={activeTableInstance.previousPage}
                        disabled={!activeTableInstance.canPreviousPage}
                      />
                      {[...Array(activePageCount)].map((_, i) => {
                        const pageToDisplay = i + 1
                        const isFirstPage = i === 0
                        const isLastPage = i === activePageCount - 1

                        if (isFirstPage || isLastPage || pageToDisplay === activePageIndex + 1) {
                          return (
                            <Pagination.Item
                              key={i}
                              active={pageToDisplay === activePageIndex + 1}
                              onClick={() => gotoActivePage(i)}
                            >
                              {pageToDisplay}
                            </Pagination.Item>
                          )
                        }
                        if (isFirstPage && pageToDisplay === 2) {
                          return <Pagination.Ellipsis key={`ellipsis-${i}`} />
                        }
                        if (isLastPage && pageToDisplay === activePageCount) {
                          return <Pagination.Ellipsis key={`ellipsis-${i}`} />
                        }
                        return null
                      })}
                      <Pagination.Next
                        onClick={activeTableInstance.nextPage}
                        disabled={!activeTableInstance.canNextPage}
                      />
                      <Pagination.Last
                        onClick={() => gotoActivePage(activePageCount - 1)}
                        disabled={!activeTableInstance.canNextPage}
                      />
                      <div className='d-flex align-items-center mx-5'>
                        <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                        <select
                          className='form-select form-select-solid fw-bold w-75px me-2'
                          value={activePageSize}
                          onChange={(e) => setActivePageSize(Number(e.target.value))}
                        >
                          {pageOptions.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Pagination>
                  )}
                </div>

                {/* Inactive Tab */}
                <div className='tab-pane fade table-responsive' id='kt_table_widget_4_tab_2'>
                  <table
                    className='table table-row-dashed align-middle table-row-gray-300'
                    {...inactiveTableInstance.getTableProps()}
                  >
                    <thead>
                      <tr className='fw-semibold text-muted default-cursor bg-light rounded'>
                        <th className='min-w-140px ps-3'>Qualification Name</th>
                        <th className='min-w-140px'>Qualification Description</th>
                        <th className='min-w-140px'>{intl.formatMessage({id: 'LABEL.STATUS'})}</th>
                        <th className='min-w-120px'>{intl.formatMessage({id: 'LABEL.ACTION'})}</th>
                      </tr>
                    </thead>
                    <tbody {...inactiveTableInstance.getTableBodyProps()}>
                      {inactivePage.length > 0 ? (
                        inactivePage.map((row) => {
                          inactiveTableInstance.prepareRow(row)
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => (
                                <td className=' ' {...cell.getCellProps()}>
                                  {cell.render('Cell')}
                                </td>
                              ))}
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td className='' colSpan={3}>
                            <div className='d-flex justify-content-center'>
                              <p className='text-dark default-cursor fs-6 mt-5'>
                                {inputVal === '' ? (
                                  intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
                                ) : (
                                  <FormattedMessage
                                    id='MSG.NO_DATA_MATCH'
                                    values={{
                                      inputVal: <span className='text-primary'>{inputVal}</span>,
                                    }}
                                  />
                                )}
                              </p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {inactiveData.length > 10 && (
                    <Pagination>
                      <Pagination.First
                        onClick={() => gotoInactivePage(0)}
                        disabled={!inactiveTableInstance.canPreviousPage}
                      />
                      <Pagination.Prev
                        onClick={inactiveTableInstance.previousPage}
                        disabled={!inactiveTableInstance.canPreviousPage}
                      />
                      {[...Array(inactivePageCount)].map((_, i) => {
                        const pageToDisplay = i + 1
                        const isFirstPage = i === 0
                        const isLastPage = i === inactivePageCount - 1

                        if (isFirstPage || isLastPage || pageToDisplay === inactivePageIndex + 1) {
                          return (
                            <Pagination.Item
                              key={i}
                              active={pageToDisplay === inactivePageIndex + 1}
                              onClick={() => gotoInactivePage(i)}
                            >
                              {pageToDisplay}
                            </Pagination.Item>
                          )
                        }
                        if (isFirstPage && pageToDisplay === 2) {
                          return <Pagination.Ellipsis key={`ellipsis-${i}`} />
                        }
                        if (isLastPage && pageToDisplay === inactivePageCount) {
                          return <Pagination.Ellipsis key={`ellipsis-${i}`} />
                        }
                        return null
                      })}
                      <Pagination.Next
                        onClick={inactiveTableInstance.nextPage}
                        disabled={!inactiveTableInstance.canNextPage}
                      />
                      <Pagination.Last
                        onClick={() => gotoInactivePage(inactivePageCount - 1)}
                        disabled={!inactiveTableInstance.canNextPage}
                      />
                      <div className='d-flex align-items-center mx-5'>
                        <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                        <select
                          className='form-select form-select-solid fw-semibold w-75px me-2'
                          value={inactivePageSize}
                          onChange={(e) => setInactivePageSize(Number(e.target.value))}
                        >
                          {pageOptions.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Pagination>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='m-2 p-4'>
          <NoDataFound
            data={{
              name: 'Qualifications',
              smallname: 'qualifications',
              icon: {NoDataIcon},
              buttonLabel: '"+ Add Qualification"',
            }}
          />
          <div className='m-auto d-flex flex-column align-items-center'>
            <OverlayTrigger
              trigger='click'
              placement='top'
              overlay={openPopover(id)}
              show={popover === 'add_qualification'}
            >
              <button
                className='btn btn-sm btn-light-primary'
                onClick={(e) => {
                  e.stopPropagation()
                  setPopover('add_qualification')
                  updateId({refreshData, ClosePopover})
                }}
              >
                <KTIcon iconName='plus' className='fs-3' />
                Add Qualification
              </button>
            </OverlayTrigger>
          </div>
        </div>
      )}
    </>
  )
}

const Qualifications = () => {
  const podId = FindPodIdByCode()
  const {execute, loading: isLoading} = usePostApi()
  const [TableData, setTableData] = useState([])
  const fetchData = async () => {
    try {
      const url = `/pod/qualifications/list`
      const formData = {
        pod_id: podId,
      }
      const response = await execute(url, 'POST', formData)
      setTableData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'qualification_name',
        Cell: ({row}) => {
          const indicator = (
            <div
              className='bg-secondary default-cursor'
              title={row.original.qualification_pod_id === 0 ? 'Seeded Data' : 'User Data'}
              style={{
                width: '18px',
                height: '18px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '10px',
                fontWeight: 'bold',
                marginRight: '8px',
              }}
            >
              {row.original.qualification_pod_id === 0 ? 'S' : 'U'}
            </div>
          )
          return (
            <div className='d-flex align-items-center flex-row ps-3'>
              {indicator}
              <p className='fw-semibold text-dark text-hover-primary fs-6 mb-0'>
                {row.original.qualification_name}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'Description',
        accessor: 'qualification_description',
        Cell: ({row}) => {
          return (
            <div className='d-flex align-items-center'>
              <div className='d-flex justify-content-start flex-column'>
                <p className='fw-normal text-dark text-hover-primary fs-6 default-cursor'>
                  {row.original.qualification_description}
                </p>
              </div>
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({row}) => {
          const statusColor = row.original.is_active === 1 ? 'success' : 'danger'
          return (
            <p className={`text-${statusColor} fw-normal d-block fs-6 default-cursor`}>
              {row.original.is_active === 1 ? 'Active' : 'Inactive'}
            </p>
          )
        },
      },
      {
        Header: 'Actions',
        accessor: 'Actions',
        Cell: ({row}) => {
          const [id, updateId] = useState('')
          const [popover, setPopover] = useState(null)
          const ClosePopover = () => {
            setPopover(null)
          }
          const openPopover = (id) => (
            <Popover flip='true' placement='auto' className='min-w-400px'>
              <EditQualification props={id} />
            </Popover>
          )
          return (
            <>
              {row.original.qualification_pod_id !== 0 ? (
                <div>
                  <OverlayTrigger
                    trigger='click'
                    placement='auto'
                    overlay={openPopover(id)}
                    show={popover === 'edit_qualification'}
                  >
                    <div
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      onClick={(e) => {
                        e.stopPropagation()
                        setPopover('edit_qualification')
                        updateId({
                          refreshData: fetchData,
                          ClosePopover,
                          id: row.original.qualification_id,
                          name: row.original.qualification_name,
                          description: row.original.qualification_description,
                          status: row.original.is_active,
                          qualification_pod_id: row.original.qualification_pod_id,
                        })
                      }}
                    >
                      <KTIcon iconName='pencil' className='fs-3' />
                    </div>
                  </OverlayTrigger>
                </div>
              ) : (
                <OverlayTrigger
                  placement='top'
                  overlay={<Tooltip id={`tooltip-top`}>Seeded Data not editable</Tooltip>}
                >
                  <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                    <KTIcon iconName='information' className='fs-3' />
                  </div>
                </OverlayTrigger>
              )}
            </>
          )
        },
      },
    ],
    []
  )

  return (
    <DataTable
      columns={columns}
      data={TableData ? TableData : []}
      loading={isLoading}
      refreshData={fetchData}
    />
  )
}

export default Qualifications
