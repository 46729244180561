import {Route, Routes} from 'react-router-dom'
import {OfferCard} from './OfferCard'
import OfferStep1 from './OfferStep1'

function OfferInfoRouting() {
  return (
    <div>
      <Routes>
        <Route element={<OfferCard />}>
          <Route path={`/info/:job_offer_id/*`} element={<OfferStep1 />} />
          {/* <Route path={`/offers/:candidate_id/*`} element={<CandidateOffers />} /> */}

          {/* <Route index element={<CandidateInterviewsTable />} /> */}
        </Route>
      </Routes>
    </div>
  )
}

export default OfferInfoRouting
