import {useEffect, useContext} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../../../../_metronic/helpers'
// import '../.././index.css'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import CandidateInfoContext from '../../../CandidateInfoContext'
import _ from 'lodash'

interface Props {
  showModal: boolean
  setShowModal(val: boolean): void
  addQualification: any
  editCertification: any
  type: string
  index: null | number
  initialVals: any
  timeStamp: any
}

function AddCertification(props: Props) {
  const {setCorrections, corrections, formValues, updateDataOnDB} = useContext(CandidateInfoContext)
  const {type, initialVals, timeStamp} = props
  const currentYear = new Date().getFullYear()

  const formik = useFormik({
    initialValues: {
      id: '',
      courseName: '',
      issuer: '',
      yearOfCompletion: '',
      mode: '',
    },
    validationSchema: Yup.object({
      courseName: Yup.string().required('Required'),
      issuer: Yup.string().required('Required'),
      yearOfCompletion: Yup.string()
        .required('Required')
        .test('is-valid-year', 'Future years not allowed', (value: any) => {
          const year = parseInt(value, 10)
          return year <= currentYear
        }),
      mode: Yup.string().required('Required'),
    }),
    onSubmit: async (values, {resetForm}) => {
      if (props.type === 'edit') {
        if (!_.isEqual(props.initialVals, values)) {
          const updatedCorrections = {
            ...corrections,
            _employment_education: {
              ...corrections._employment_education,
              certifications: {
                ...corrections._employment_education.certifications,
                [values.id]: {...values, status: 'edited'},
              },
            },
          }

          setCorrections(updatedCorrections)
          await updateDataOnDB({...formValues, corrections: updatedCorrections})
        } else {
          setCorrections((prevCorrections: any) => {
            const newCorrections = {...prevCorrections}
            const certifications = newCorrections._employment_education.certifications
            const newCertifications = {...certifications}

            delete newCertifications[values.id]

            newCorrections._employment_education.certifications = newCertifications
            return newCorrections
          })
        }
        // }
        // props.editCertification(values, 'certifications', props.index)
      } else if (props.type === 'editNewCert') {
        await props.editCertification(values)
      } else {
        const iniqueId = '_' + Math.random().toString(36).substr(2, 9)
        const updatedCorrections = {
          ...corrections,
          _employment_education: {
            ...corrections._employment_education,
            certifications: {
              ...corrections._employment_education.certifications,
              [iniqueId]: {
                ...values,
                id: iniqueId,
                status: 'New',
              },
            },
          },
        }

        updateDataOnDB({...formValues, corrections: updatedCorrections})

        // props.addQualification(
        //   {...values, id: '_' + Math.random().toString(36).substr(2, 9)},
        //   'certifications'
        // )
      }
      resetForm()
      props.setShowModal(false)
    },
  })

  useEffect(() => {
    if ((props.type === 'edit' || props.type === 'editNewCert') && props.initialVals) {
      formik.setValues(props.initialVals)
    } else {
      const initialValues = {
        id: '',
        courseName: '',
        issuer: '',
        yearOfCompletion: '',
        mode: '',
      }
      formik.setValues(initialValues)
    }
  }, [type, initialVals, timeStamp])

  const handleInputChange = (event: any) => {
    const value = event.target.value
    // Remove non-numeric characters
    const numericValue = value.replace(/\D/g, '')
    formik.setFieldValue('yearOfCompletion', numericValue)
  }

  return (
    <Modal
      show={props.showModal}
      onHide={() => props.setShowModal(false)}
      centered
      dialogClassName='custom-modal modal-dialog-centered'
      contentClassName='custom-modal-content'
      backdrop='static'
    >
      <Modal.Body className='overflow-auto w-100'>
        <div className='d-flex justify-content-between mb-10'>
          <h3 className='fs-3 fw-semibold'>
            {props.type === 'edit' ? 'Edit Certification' : 'Add New Certification'}
          </h3>
          <button
            type='button'
            className='d-block btn btn-sm btn-icon btn-light'
            onClick={() => props.setShowModal(false)}
          >
            <KTIcon iconName='cross' className='fs-1' />
          </button>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div className=''>
            <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
              <span className='required'>Course Name</span>
            </label>
            <div className='mb-4'>
              <input
                id='courseName'
                type='text'
                name='courseName'
                className='form-control form-control-solid'
                placeholder='e.g. Frontend, Backend, Fullstack etc.'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.courseName}
              />
              {formik.touched.courseName && formik.errors.courseName ? (
                <div className='text-danger'>{formik.errors.courseName}</div>
              ) : null}
            </div>
          </div>
          <div className=''>
            <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
              <span className='required'>Issuer</span>
            </label>
            <div className='mb-4'>
              <input
                id='issuer'
                type='text'
                name='issuer'
                className='form-control form-control-solid'
                placeholder='Enter issuer name'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.issuer}
              />
              {formik.touched.issuer && formik.errors.issuer ? (
                <div className='text-danger'>{formik.errors.issuer}</div>
              ) : null}
            </div>
          </div>

          <div className='w-100 d-flex flex-column flex-md-row justify-content-between'>
            <div className='w-100 w-md-50 me-md-2'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Year of Completion</span>
              </label>
              <div className='mb-4'>
                <input
                  id='yearOfCompletion'
                  type='number'
                  name='yearOfCompletion'
                  className='form-control form-control-solid'
                  placeholder='Year of Completion'
                  // onChange={formik.handleChange}
                  onChange={handleInputChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.yearOfCompletion}
                />
                {formik.touched.yearOfCompletion && formik.errors.yearOfCompletion ? (
                  <div className='text-danger'>{formik.errors.yearOfCompletion}</div>
                ) : null}
              </div>
            </div>
            <div className='w-100 w-md-50 ms-md-2'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Mode</span>
              </label>
              <div className='mb-4'>
                {/* <select
                  className='form-select form-select-solid'
                  name='type'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.type}
                >
                  <option value=''>Select option</option>
                  <option value='Online'>Online</option>
                  <option value='In-class'>In-class</option>
                  <option value='Correspondence'>Correspondence</option>
                </select> */}
                <button
                  type='button'
                  className={`btn btn-${
                    formik.values.mode === 'Online' ? 'primary' : 'light-primary'
                  } btn-sm rounded-pill me-1`}
                  onClick={() => formik.setFieldValue('mode', 'Online')}
                >
                  Online
                </button>
                <button
                  type='button'
                  className={`btn btn-${
                    formik.values.mode === 'In-class' ? 'primary' : 'light-primary'
                  } btn-sm rounded-pill me-1`}
                  onClick={() => formik.setFieldValue('mode', 'In-class')}
                >
                  In-class
                </button>
                <button
                  type='button'
                  className={`btn btn-${
                    formik.values.mode === 'Correspondence' ? 'primary' : 'light-primary'
                  } btn-sm rounded-pill`}
                  onClick={() => formik.setFieldValue('mode', 'Correspondence')}
                >
                  Correspondence
                </button>
                {formik.touched.mode && formik.errors.mode ? (
                  <div className='text-danger'>{formik.errors.mode}</div>
                ) : null}
              </div>
            </div>
          </div>

          <div className='w-100 d-flex justify-content-end mt-5'>
            <button
              type='button'
              className='btn btn-light btn-sm me-2'
              onClick={() => props.setShowModal(false)}
            >
              Close
            </button>

            <button type='submit' className='btn btn-sm btn-light-primary ms-2'>
              <KTIcon iconName='check' className='fs-3 ms-1' />
              {props.type === 'edit' ? 'Update' : 'Add'}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default AddCertification
