import React, {useState, useEffect} from 'react'
import {Link, Outlet, useLocation, useParams} from 'react-router-dom'
import {KTIcon} from '../../../../../_metronic/helpers'
import {CheckPodAccess, FindFirstPodCode, FindPodIdByCode} from '../../../FindPodIdByPodCode'
import usePostApi from '../../../custom_hooks/usePostApi'
import clsx from 'clsx'

import {defaultProfileImage} from '../../../core'

function OfferCard() {
  const [showModalChange, setShowChange] = useState<boolean>(false)

  const handleCloseChange = () => {
    setShowChange(false)
  }
  const podHaveAccess = CheckPodAccess()
  const {execute, loading} = usePostApi()
  const {id, job_offer_id} = useParams()
  console.log(job_offer_id)
  const {pathname} = useLocation()
  const firstPodCode = FindFirstPodCode()

  const [candidatesData, setcandidatesData] = useState<any>(null)

  const fetchData = async () => {
    try {
      const url = `/pod/job_offers/get`
      const formData = {
        job_offer_id: job_offer_id,
      }

      const response = await execute(url, 'POST', formData)
      setcandidatesData(response.data)
      console.log(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const userData = candidatesData ? candidatesData[0] : null

  function getGenderDescription(genderCode: any) {
    switch (genderCode) {
      case 'M':
        return 'Male'
      case 'F':
        return 'Female'
      case 'T':
        return 'Transgender'
      case 'O':
        return 'Others'
      default:
        return '-'
    }
  }
  return (
    <>
      {podHaveAccess ? (
        loading ? (
          <div className='card h-250px mb-5'>
            <div className='m-auto d-flex flex-column align-items-center'>
              <div className='spinner-border spinner-primary mr-15'></div>
            </div>
          </div>
        ) : (
          <>
            <div className='card mb-4 mb-xl-6 h-200px'>
              {userData ? (
                <div className='card-body min-h-150px row  pt-8 pb-0'>
                  <div className='row col-12 col-md-6 col-lg-7'>
                    <div className='d-flex flex-column flex-md-row mb-0 pb-0'>
                      <div className='me-md-4 mb-4 mb-md-0'>
                        <div className='symbol symbol-100px symbol-lg-150px symbol-fixed position-relative'>
                          <img
                            style={{height: '140px', width: '140px'}}
                            src={userData?.candidate_image ?? defaultProfileImage}
                            alt='profile'
                          />
                        </div>
                      </div>
                      <div className='w-100'>
                        <div className='d-flex align-items-start mb-3'>
                          <span className='text-gray-800 text-hover-primary default-cursor fs-2 fw-semibold me-1'>
                            {userData?.candidate_first_name} {''} {userData?.candidate_last_name}
                          </span>
                        </div>
                        <div className='row mb-3 d-flex align-items-start'>
                          <label className='col-lg-4 col-6 fw-semibold text-muted'>Gender</label>
                          <div className='col-lg-8 col-6'>
                            <span className='fs-6 text-dark text-hover-primary default-cursor'>
                              {getGenderDescription(userData?.gender)}
                            </span>
                          </div>
                        </div>

                        <div className='row mb-3 d-flex align-items-start'>
                          <label className='col-lg-4 col-6 fw-semibold text-muted'>Email</label>
                          <div className='col-lg-8 col-6'>
                            <span className='fs-6 text-dark text-hover-primary default-cursor'>
                              {userData?.email_address || ''}
                            </span>
                          </div>
                        </div>

                        {/* <div className='row mb-3 d-flex align-items-start'>
                          <label className='col-lg-4 col-6 fw-semibold text-muted'>
                            Offer Letter
                          </label>
                          <div className='col-lg-8 col-6'>
                            <span
                              className='btn btn-light-primary btn-sm'
                              onClick={() => {
                                if (userData?.offer_letter_attachment) {
                                  window.open(userData?.offer_letter_attachment, '_blank')
                                } else {
                                  alert('Not Found')
                                }
                              }}
                            >
                              <KTIcon iconName='eye' className='fs-3 ' />
                              View
                            </span>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>

                  <div className='row col-12 col-md-6 col-lg-5 pt-md-12'>
                    <div>
                      <div className='row mb-3 d-flex align-items-start'>
                        <label className='col-lg-3 col-6 fw-semibold text-muted'>Phone No.</label>
                        <div className='col-lg-9 col-6'>
                          <span className='fs-6 text-dark text-hover-primary default-cursor'>
                            {userData?.mobile || '-'}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-3 d-flex align-items-start'>
                        <label className='col-lg-3 col-6 fw-semibold text-muted'>
                          Phone No. (Alt)
                        </label>
                        <div className='col-lg-9 col-6'>
                          <span className='fs-6 text-dark text-hover-primary default-cursor'>
                            {userData?.alt_phone || '-'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='d-flex flex-column justify-content-center align-items-center h-150px'>
                  <div>
                    <h3 className='fs-3 fw-normal'>Not found</h3>
                    <button className='btn btn-sm btn-light-primary' onClick={fetchData}>
                      Retry
                    </button>
                  </div>
                </div>
              )}
            </div>
          </>
        )
      ) : (
        <div className='card min-h-250px d-flex flex-column justify-content-center align-items-center bg-image'>
          <h1 className='fw-semibold fs-2hx text-gray-900 mb-4'>Oops!</h1>
          <div className='fs-6 text-gray-500 mb-7'>You don't have access to this POD.</div>
          <div className='mb-0'>
            <Link to={`/${firstPodCode}/dashboard`} className='btn btn-sm btn-light-primary'>
              Return Home
            </Link>
          </div>
        </div>
      )}
      {podHaveAccess && (
        <>
          <div className='d-flex flex-wrap flex-stack mb-6'>
            <div>
              <h3 className='fs-3 fw-semibold my-2'>Offer Info</h3>
            </div>
            <div className='d-flex'>
              <Link
                className='btn btn-sm btn-light-primary ms-4 fw-normal'
                to={`/${id}/candidates`}
              >
                <KTIcon iconName='arrow-left' className='fs-3' />
                Back
              </Link>
            </div>
          </div>
          <div className='w-100 d-flex flex-column flex-md-row justify-content-between'>
            <div className='w-100 card me-5 w-md-25 mb-5 h-100'>
              <div className='w-100 d-flex flex-column justify-content-between text-dark p-5'>
                <Link className='menu-item' to={`/${id}/offers/info/${job_offer_id}`}>
                  <p
                    className={clsx(
                      'menu-link btn btn-sm btn-color-muted btn-active btn-active-light-primary text-hover-primary mb-0 text-start',
                      {
                        active: pathname === `/${id}/offers/info/${job_offer_id}`,
                      }
                    )}
                    style={{padding: '10px', marginBottom: '0px !important'}}
                  >
                    <span className='menu-icon'>
                      <KTIcon iconName='badge' className='fs-2' />
                    </span>
                    <span className='menu-title fw-semibold'>Offer Info</span>
                  </p>
                </Link>

                {/* <Link className='menu-item' to={`/${id}/candidates/offers/${candidate_id}`}>
                  <p
                    className={clsx(
                      'menu-link btn btn-sm btn-color-muted btn-active btn-active-light-primary text-hover-primary mb-0 text-start',
                      {
                        active: pathname === `/${id}/candidates/offers/${candidate_id}`,
                      }
                    )}
                    style={{padding: '10px', marginBottom: '0px !important'}}
                  >
                    <span className='menu-icon'>
                      <KTIcon iconName='address-book' className='fs-2' />
                    </span>
                    <span className='menu-title fw-semibold'>Offers</span>
                  </p>
                </Link> */}

                {/* <Link className='menu-item' to={`/${id}/policies/archive/${policy_id}`}>
                  <p
                    className={clsx(
                      'menu-link btn btn-sm btn-color-muted btn-active btn-active-light-primary text-hover-primary mb-0 text-start',
                      {
                        active: pathname === `/${id}/policies/archive/${policy_id}`,
                      }
                    )}
                    style={{padding: '10px', marginBottom: '0px !important'}}
                  >
                    <span className='menu-icon'>
                      <KTIcon iconName='book-open' className='fs-2' />
                    </span>
                    <span className='menu-title fw-semibold'>Archive</span>
                  </p>
                </Link> */}
              </div>
            </div>

            <div className='w-100 w-md-75 h-100 overflow-auto'>
              <Outlet />
            </div>
          </div>
        </>
      )}
    </>
  )
}

// export {PolicyCard, PolicyDataContext}

export {OfferCard}
