import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import usePostApi from '../custom_hooks/usePostApi'
import {UseToast} from '../useToast'
import {FindPodIdByCode} from '../FindPodIdByPodCode'

const ChangeRole = ({data}: any) => {
  const podId = FindPodIdByCode()
  const {memberId, refreshData, ClosePopover, role_id} = data
  const {execute, loading: rolesLoading, postLoading} = usePostApi()
  const [selectRole, setSeletRole] = useState(role_id)
  const [showErr, setShowErr] = useState(false)

  const [rolesData, setRolesData] = useState([])
  const getRolesData = async () => {
    try {
      const url = '/master/roles'
      const formData = {}

      const response = await execute(url, 'POST', formData)
      setRolesData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getRolesData()
  }, [])

  useEffect(() => {
    setShowErr(false)
  }, [selectRole])

  const updateMember = async () => {
    if (selectRole === '') {
      setShowErr(true)
      return
    } else {
      setShowErr(false)
    }

    try {
      const url = '/pod/members/update'

      const formData = {
        role_id: selectRole,
        pod_id: podId,
        member_id: memberId,
      }

      const response = await execute(url, 'POST', formData, 'post')
      ClosePopover()
      refreshData()

      UseToast(
        response?.status_message,
        {
          position: 'bottom-right',
          autoClose: false,
          theme: 'colored',
        },
        'success'
      )
    } catch (error: any) {
      console.error('Error:', error)

      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          autoClose: false,
          theme: 'colored',
        },
        'error'
      )
    }
  }

  return (
    <div className=''>
      {rolesLoading ? (
        <div className='w-100'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : (
        <>
          <ul
            className='w-100 nav nav-pills nav-pills-custom list-unstyled d-flex flex-row justify-content-between'
            id='roles'
          >
            {(rolesData ?? []).map((each: any) => (
              <li
                style={{width: '29%'}}
                className='nav-ite mb-3'
                key={each.role_id}
                data-bs-toggle='tooltip'
                title={`${each.role_name}`}
              >
                <div
                  className={`nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-70px overflow-auto p-2 ${
                    selectRole === each.role_id ? 'active-border bg-light-primary' : ''
                  }`}
                  onClick={() => setSeletRole(each.role_id)}
                >
                  <div className=''>
                    <KTIcon
                      iconName={each?.role_icon || 'information-2'}
                      className={`fs-1 ${selectRole === each.role_id ? 'text-primary' : ''}`}
                    />
                  </div>
                  <span className='fw-semibold text-dark fs-8'>{each.role_name}</span>
                </div>
              </li>
            ))}
            {showErr && <div className='text-danger'>Role is required</div>}
          </ul>
        </>
      )}

      <div className='d-flex justify-content-end'>
        <button className='btn btn-light-primary btn-sm' type='button' onClick={updateMember}>
          {postLoading ? (
            <span className='indicator-progress' style={{display: 'block'}}>
              wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            'Update'
          )}
        </button>
      </div>
    </div>
  )
}

export default ChangeRole
