import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'
import clsx from 'clsx'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'

function AddNewPolicy(props) {
  const [data, setData] = useState({
    field_name: '',
    prompt: '',
    field_section: '',
    field_type: '',
    isChecked: false,
  })
  const [currentStep, setCurrentStep] = useState(0)

  const prevStep = () => {
    if (currentStep !== 0) {
      setCurrentStep(currentStep - 1)
    }
  }

  const nextStep = () => {
    if (currentStep === 0) {
      const step1SubmitBtn = document.getElementById('submit_step1_btn')
      if (step1SubmitBtn) {
        step1SubmitBtn.click()
      }
    } else if (currentStep === 1) {
      const step2SubmitBtn = document.getElementById('submit_step2_btn')
      if (step2SubmitBtn) {
        step2SubmitBtn.click()
      }
    }
  }

  const setSecondStep = () => {
    setCurrentStep(1)
  }

  const steps = [
    <Step1 data={data} setData={setData} setSecondStep={setSecondStep} />,
    <Step2
      data={data}
      setData={setData}
      addNewField={props.addNewField}
      closeModal={props.setShowModal}
      setCurrentStep={setCurrentStep}
    />,
  ]

  return (
    <Modal
      show={props.modalShow}
      onHide={() => props.setShowModal(false)}
      centered
      dialogClassName='custom-modal modal-dialog-centered'
      contentClassName='custom-modal-content'
    >
      <Modal.Body className='overflow-auto'>
        <div className='d-flex justify-content-between mb-10'>
          <h4 className=''>Add New Policy</h4>
          <button
            type='button'
            className='d-block btn btn-sm btn-icon btn-light'
            onClick={() => props.setShowModal(false)}
          >
            <KTIcon iconName='cross' className='fs-1' />
          </button>
        </div>
        <div className='w-100 row m-0'>
          <div className='col-lg-5 p-0'>
            <div className=''>
              <div className='d-flex'>
                <div
                  style={{borderRadius: '10px'}}
                  className={clsx(
                    'w-40px h-40px btn d-flex justify-content-center align-items-center',
                    {
                      'btn-primary': currentStep === 0,
                      'btn-light-primary': currentStep !== 0,
                    }
                  )}
                >
                  {currentStep === 0 ? 1 : <i className='stepper-check fas fa-check'></i>}
                </div>
                <div className='ms-5'>
                  <h6
                    className={clsx('mb-0', {
                      'text-muted': currentStep >= 0,
                    })}
                  >
                    Step 1
                  </h6>
                  <p
                    className={clsx('fs-6', {
                      'text-muted': currentStep >= 0,
                    })}
                  >
                    Name & Type
                  </p>
                </div>
              </div>
              <div className='ms-6 h-30px border-left border-dashed border-muted border-top-0 border-bottom-0 border-right-0 border-left-1'></div>
              <div className='d-flex'>
                <div
                  style={{borderRadius: '10px'}}
                  className={clsx(
                    'w-40px h-40px btn d-flex justify-content-center align-items-center',
                    {
                      'btn-primary': currentStep === 1,
                      'btn-light-primary': currentStep !== 1,
                    }
                  )}
                >
                  {currentStep > 1 ? <i className='stepper-check fas fa-check'></i> : 2}
                </div>
                <div className='ms-5'>
                  <h6
                    className={clsx('mb-0', {
                      'text-muted': currentStep >= 1,
                    })}
                  >
                    Step 2
                  </h6>
                  <p
                    className={clsx('fs-6', {
                      'text-muted': currentStep >= 1,
                    })}
                  >
                    Prompt
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-7 p-0 m-0'>{steps[currentStep]}</div>
        </div>
        <div className='w-100 d-flex flex-stack mt-5'>
          <div className='mr-2'>
            {currentStep > 0 && (
              <button onClick={prevStep} type='button' className='btn btn-sm btn-light me-3'>
                <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                Back
              </button>
            )}
          </div>

          <div>
            <button type='submit' className='btn btn-sm btn-light-primary' onClick={nextStep}>
              {currentStep < steps.length - 1 ? 'Continue' : 'Add Policy'}
              <KTIcon iconName='arrow-right' className='fs-3 ms-1' />
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const step1Schema = Yup.object().shape({
  field_name: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(250, 'Maximum 250 characters')
    .required('Field Name is required'),
  field_type: Yup.string().required('Field Type is required'),
})

const Step1 = ({data, setData, setSecondStep}) => {
  const onSubmit = (values) => {
    setData({...values})
    setSecondStep()
  }
  return (
    <>
      <Formik validationSchema={step1Schema} initialValues={data} onSubmit={onSubmit}>
        <Form className='min-w-350px'>
          <div className='fv-row mb-8'>
            <div className='d-flex justify-content-between align-items-center'>
              <label className='fw-bold'>Field Name</label>
              <span className='d-block bg-secondary px-2 rounded'>Unique Code: Auto-generated</span>
            </div>
            <Field name='field_name' className='form-control' placeholder='Field Name' />
            <ErrorMessage name='field_name' component='div' className='text-danger' />
          </div>

          <div className='fv-row mb-3'>
            <p className='form-label fs-6 fw-bolder text-dark mb-0'>Select Field Type</p>
            <Field as='select' name='field_type' className='form-select'>
              <option value=''>Select Field Type</option>

              <option value='Single Line'>Single Line</option>
              <option value='Multi Line'>Multi Line</option>
              <option value='Dropdown'>Dropdown</option>
              <option value='Date'>Date</option>
              <option value='Number'>Number</option>
              <option value='Attachment'>Attachment</option>
              <option value='Yes/No Question'>Yes/No Question</option>
            </Field>
            <ErrorMessage name='field_type' component='div' className='text-danger' />
          </div>

          <button
            type='submit'
            className='d-none btn btn-lg btn-primary w-100'
            id='submit_step1_btn'
          >
            ADD
          </button>
        </Form>
      </Formik>
    </>
  )
}

const step2Schema = Yup.object().shape({
  prompt: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(250, 'Maximum 250 characters')
    .required('Prompt is required'),
})

const Step2 = ({data, setData, addNewField, closeModal, setCurrentStep}) => {
  const onSubmit = (values, {resetForm}) => {
    setData({...values})
    addNewField(values, {resetForm})
    setData({
      field_name: '',
      prompt: '',
      field_section: '',
      field_type: '',
      isChecked: false,
    })
    setCurrentStep(0)
    closeModal()

    // console.log(values)
  }
  return (
    <Formik validationSchema={step2Schema} initialValues={data} onSubmit={onSubmit}>
      {({values, setFieldValue}) => {
        return (
          <Form className='min-w-350px'>
            <div className='fv-row mb-3'>
              <div className='d-flex justify-content-between align-items-center'>
                <label className='fw-bold'>Prompt</label>
              </div>
              <Field name='prompt' className='form-control' placeholder='Enter Prompt' />
              <ErrorMessage name='prompt' component='div' className='text-danger' />
            </div>

            <div className='d-flex align-items-center mt-2 mb-5'>
              <label className='switch'>
                <input
                  type='checkbox'
                  checked={values.isChecked}
                  onChange={() => setFieldValue('isChecked', !values.isChecked)}
                  id='mandatorySwitch'
                  className='d-none'
                />
                <span className='slider round'></span>
              </label>
              <label className='d-block ms-2 fw-semibold' htmlFor='mandatorySwitch'>
                Mandatory Field
              </label>
            </div>
            <button
              type='submit'
              className='d-none btn btn-lg btn-primary w-100'
              id='submit_step2_btn'
            >
              ADD
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}

export default AddNewPolicy
