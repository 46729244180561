import {format} from 'date-fns'

function DateFormatter(date) {
  const dateFormat = localStorage.getItem('dateFormat')
  const dateString = new Date(date)
  const isValid = !isNaN(dateString)

  const formattedDate = (date, dateFormat) => {
    const formatDate = dateFormat ? dateFormat : 'dd-MM-yyyy'
    return format(new Date(date), formatDate)
  }
  // return formattedDate(date, dateFormat)
  return isValid ? formattedDate(date, dateFormat) : date
}

export default DateFormatter
