import {Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {AuthLayout} from './AuthLayout'
import VerifyUser from './components/verifyUser'
import ResetPwViaLink from './components/ResetPwViaLink'
import InviteAccept from './components/InviteAccept'

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='verify' element={<VerifyUser />} />
      <Route path='forgot' element={<ForgotPassword />} />
      <Route path='reset' element={<ResetPwViaLink />} />
      <Route path='invite' element={<InviteAccept />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
