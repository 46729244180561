import {useIntl} from 'react-intl'
import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {FormikHelpers} from 'formik'
import usePostApi from '../../../custom_hooks/usePostApi'
import {KTIcon} from '../../../../../_metronic/helpers'
import {FindPodIdByCode} from '../../../FindPodIdByPodCode'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import {AddBusinessUnit} from '../business_Units/AddBusinessUnit'
import {useThemeMode} from '../../../../../_metronic/partials'
import {UseToast} from '../../../useToast'
import Select from 'react-select'
const EditDepartment = (props: any) => {
  const {value, refreshData, ClosePopover, name, getstatus, erp_id, buID, code} = props.props
  const podId = FindPodIdByCode()
  const intl = useIntl()
  const {mode} = useThemeMode()
  const [popover, setPopover]: any = useState(null)
  const [buOptions, setBuOptions] = useState([])
  const [id, updateId]: any = useState('')
  const BusinessUnitClosePopover = () => {
    setPopover(null)
  }
  const openPopover = (id: any) => (
    <Popover placement='auto' className='min-w-400px'>
      <AddBusinessUnit props={id} />
    </Popover>
  )
  const {execute, postLoading} = usePostApi()
  const [buName, setTableData]: any = useState([])
  const bufetchData = async () => {
    try {
      const url = `/pod/business_unit/active`
      const formData = {pod_id: podId}
      const response = await execute(url, 'POST', formData)
      setTableData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }
  useEffect(() => {
    bufetchData()
  }, [])
  const handleBuChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('buID', selectedOption.value)
    } else {
      setFieldValue('buID', '')
    }
  }
  useEffect(() => {
    const options: any = (buName || []).map((each: any) => ({
      value: each.bu_id,
      label: each.bu_name,
    }))
    if (options) {
      setBuOptions(options)
    }
  }, [buName])
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .required('Department Name is required'),
    depCode: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .required('Department Code is required'),
    buID: Yup.string().required('Business Unit Name is required'),
    erpId: Yup.string().min(3, 'Minimum 3 characters').max(50, 'Maximum 50 characters'),
    status: Yup.boolean().required(intl.formatMessage({id: 'LABEL.STATUS_REQUIRED'})),
  })
  const handleSubmit = async (values: any, {resetForm}: FormikHelpers<any>) => {
    const url = `/pod/department/update`
    try {
      const requestData = {
        department_id: value,
        pod_id: podId,
        department_name: values.name,
        business_unit_id: values.buID,
        dep_code: values.depCode,
        erp_id: values.erpId,
        status: values.status ? 1 : 0,
      }
      const response = await execute(url, 'POST', requestData, 'post')
      resetForm()
      refreshData()
      ClosePopover()
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
    } catch (error: any) {
      console.error(error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }
  return (
    <div>
      <Formik
        initialValues={{
          name: name || '',
          buID: buID || '',
          depCode: code || '',
          erpId: erp_id || '',
          status: getstatus === 1 || '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({values, setFieldValue, handleChange}) => {
          return (
            <Form>
              <div className='card'>
                <div className='card-header'>
                  <h3 className='card-title fw-semibold fs-3 text-dark'>Edit Department</h3>
                  <div className='card-toolbar me-2'>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-active-light me-n5'
                      onClick={() => ClosePopover()}
                    >
                      <KTIcon iconName='cross' className='fs-1' />
                    </button>
                  </div>
                </div>
                <div className='px-7 py-5'>
                  <div className='modal-body'>
                    <div>
                      <div className='mb-4 flex-fill'>
                        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 required'>
                          {intl.formatMessage({id: 'LABEL.DEPARTMENT_NAME'})}
                        </label>
                        <Field
                          type='text'
                          className='form-control form-control-solid mb-1'
                          id='floatingInput'
                          placeholder='Enter Department Name'
                          name='name'
                          value={values.name}
                          onChange={handleChange}
                        />
                        <ErrorMessage component='div' className='text-danger' name='name' />
                      </div>
                      <div className='d-flex flex-column mb-4'>
                        <label className='fs-6 fw-semibold form-label mb-2 required'>
                          Business Unit Name
                        </label>
                        <div className='d-flex flex-row flex-fill'>
                          <Select
                            value={buOptions.find((option: any) => option.value === values.buID)}
                            styles={{
                              control: (baseStyles: any, state: any) => ({
                                ...baseStyles,
                                backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                                color: '#ffffff',
                                borderWidth: '0px',
                              }),
                              menu: (baseStyles: any) => ({
                                ...baseStyles,
                                backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                              }),
                              singleValue: (baseStyles: any, state: any) => ({
                                ...baseStyles,
                                color: mode === 'dark' ? '#92929f' : '#000000',
                              }),
                              input: (baseStyles: any) => ({
                                ...baseStyles,
                                color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                              }),
                              option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                                ...baseStyles,
                                backgroundColor: isSelected
                                  ? '#C2C2C2'
                                  : isFocused
                                  ? '#e0e0e0'
                                  : baseStyles.backgroundColor,
                                color: isSelected
                                  ? '#000000'
                                  : isFocused
                                  ? '#000000'
                                  : baseStyles.color,
                              }),
                            }}
                            theme={(theme: any) => ({
                              ...theme,
                              borderRadius: 5,
                              backgroundColor: '#000000',
                              colors: {
                                ...theme.colors,
                                primary25: 'rgba(80, 107, 236, 0.5)',
                                primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                              },
                            })}
                            className='react-select-container flex-fill'
                            classNamePrefix='react-select'
                            options={buOptions}
                            isSearchable
                            placeholder='Select Business Unit Name'
                            onChange={(val: any) => handleBuChange(setFieldValue, val)}
                          />
                          <OverlayTrigger
                            trigger='click'
                            placement='auto'
                            overlay={openPopover(id)}
                            show={popover === 'add_business_unit'}
                          >
                            <button
                              type='button'
                              className='btn btn-icon btn-light btn-sm ms-2'
                              data-bs-toggle='tooltip'
                              title='Create New Business Unit'
                              onClick={(e) => {
                                e.stopPropagation()
                                setPopover('add_business_unit')
                                updateId({fetchData: bufetchData, BusinessUnitClosePopover})
                              }}
                            >
                              <KTIcon iconName='plus' className='fs-2' />
                            </button>
                          </OverlayTrigger>
                        </div>
                        <ErrorMessage component='div' className='text-danger' name='buID' />
                      </div>
                      <div className='d-flex mb-4'>
                        <div className='flex-fill'>
                          <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 required'>
                            {intl.formatMessage({id: 'LABEL.DEPARTMENT_CODE'})}
                          </label>
                          <Field
                            type='text'
                            className='form-control form-control-solid mb-1'
                            id='depCode'
                            placeholder='Enter Department Code'
                            name='depCode'
                            value={values.depCode}
                            onChange={handleChange}
                          />
                          <ErrorMessage component='div' className='text-danger' name='depCode' />
                        </div>
                        <div className='ms-2 flex-fill'>
                          <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                            {intl.formatMessage({
                              id: 'LABEL.ERP_ID',
                            })}
                          </label>
                          <Field
                            type='text'
                            className='form-control form-control-solid mb-1'
                            id='erpId'
                            placeholder='Enter ERP ID'
                            name='erpId'
                            value={values.erpId}
                            onChange={handleChange}
                          />
                          <ErrorMessage component='div' className='text-danger' name='erpId' />
                        </div>
                      </div>
                      <div className='form-floating flex-fill mb-4'>
                        <div className='form-check form-check-solid form-switch mb-0'>
                          <Field
                            className='form-check-input w-40px h-20px'
                            type='checkbox'
                            name='status'
                            id='toggle'
                            style={{
                              backgroundColor: values.status
                                ? '#009688'
                                : mode === 'dark'
                                ? '#1b1b29'
                                : '#f4f4f4',
                            }}
                          />
                          <span
                            className={`me-4 fw-semibold ${
                              values.status ? 'text-success' : 'text-danger'
                            }`}
                          >
                            {values.status ? 'Active' : 'Inactive'}
                          </span>
                          <ErrorMessage component='div' className='text-danger' name='status' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex justify-content-end'>
                    <button
                      type='reset'
                      className='btn btn-sm btn-light me-2'
                      onClick={() => {
                        ClosePopover()
                      }}
                    >
                      {intl.formatMessage({id: 'LABEL.CANCEL'})}
                    </button>

                    <button type='submit' className='btn btn-sm btn-light-primary'>
                      {postLoading ? (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      ) : (
                        <>
                          <KTIcon iconName='check' className='fs-7' />
                          {intl.formatMessage({id: 'BTN.SAVE'})}
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export {EditDepartment}
