import {Link, useLocation, useParams} from 'react-router-dom'
const NavigationBar = () => {
  const location = useLocation()
  const {id} = useParams()
  const masterDataRoutes = [
    `/${id}/configuration/master-data`,
    `/${id}/configuration/master-data/designations`,
    `/${id}/configuration/master-data/locations`,
    `/${id}/configuration/master-data/business-unit`,
    `/${id}/configuration/master-data/departments`,
    `/${id}/configuration/master-data/job-roles`,
    `/${id}/configuration/master-data/job-titles`,
    `/${id}/configuration/master-data/interview-types`,
  ]
  const isMasterDataActive = masterDataRoutes.some((route) => location.pathname.includes(route))
  return (
    <>
      <div className='d-flex overflow-auto h-55px'>
        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-semibold flex-nowrap'>
          <li className='nav-item'>
            <Link
              className={
                `fs-6 nav-link text-active-primary me-6 ` +
                (location.pathname === `/${id}/configuration/information` && 'active')
              }
              to={`/${id}/configuration/information`}
            >
              Information
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              className={
                `fs-6 nav-link text-active-primary me-6 ` +
                ((location.pathname === `/${id}/configuration/white-labeling` ||
                  location.pathname === `/${id}/configuration/white-labeling/domains` ||
                  location.pathname === `/${id}/configuration/white-labeling/careers-portal` ||
                  location.pathname === `/${id}/configuration/white-labeling/careers` ||
                  location.pathname === `/${id}/configuration/white-labeling/senders`) &&
                  'active')
              }
              to={`/${id}/configuration/white-labeling`}
            >
              Domain Branding
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              className={`fs-6 nav-link text-active-primary me-6 ${isMasterDataActive && 'active'}`}
              to={`/${id}/configuration/master-data`}
            >
              Master Data
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              className={
                `fs-6 nav-link text-active-primary me-6 ` +
                (location.pathname === `/${id}/configuration/lookups` && 'active')
              }
              to={`/${id}/configuration/lookups`}
            >
              Lookups
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}

export default NavigationBar
