import React from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import ProductDetails from './ProductDetails'
import Summary from './Summary'
import RecentEvents from './RecentEvents'
import Invoices from './Invoices'

function BillingsPage() {
  const {id} = useParams()
  const Onboardings = [
    {
      title: `(${id})`,
      path: `/${id}/subscriptions`,
      isSeparator: false,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={Onboardings}>Subscriptions</PageTitle>
      <div className='row' style={{height: '100vh'}}>
        <div
          className='col-8 overflow-auto'
          style={{
            maxHeight: '100%',
          }}
        >
          <ProductDetails />
          <RecentEvents />
          <Invoices />
        </div>
        <div className='col-4' style={{position: 'sticky', top: 0}}>
          <Summary />
        </div>
      </div>
    </>
  )
}

export default BillingsPage
