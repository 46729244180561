import React, {useState, useEffect} from 'react'
import {Link, Outlet, useLocation, useParams} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'
import {CheckPodAccess, FindFirstPodCode, FindPodIdByCode} from '../../FindPodIdByPodCode'
import usePostApi from '../../custom_hooks/usePostApi'
import clsx from 'clsx'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import DateFormatter from '../../DateFormatter'
import {UseToast} from '../../useToast'
import {useThemeMode} from '../../../../_metronic/partials'

function PolicyCard() {
  const [showModalChange, setShowChange] = useState<boolean>(false)

  const handleCloseChange = () => {
    setShowChange(false)
  }
  const podHaveAccess = CheckPodAccess()
  const {execute, loading} = usePostApi()
  const {id, policy_id} = useParams()
  const {pathname} = useLocation()
  const firstPodCode = FindFirstPodCode()

  const [policyData, setPolicyData] = useState<any>(null)

  const getPolicyData = async () => {
    try {
      const url = '/pod/policy/get'
      const formData = {
        policy_id,
      }

      const response = await execute(url, 'POST', formData)
      // console.log(response.data[0])
      setPolicyData(response.data[0])
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getPolicyData()
  }, [policy_id])

  return (
    <>
      {podHaveAccess ? (
        loading ? (
          <div className='card h-250px mb-5'>
            <div className='m-auto d-flex flex-column align-items-center'>
              <div className='spinner-border spinner-primary mr-15'></div>
            </div>
          </div>
        ) : (
          <>
            <UpdatePolicy
              showModalChange={showModalChange}
              handleCloseChange={handleCloseChange}
              policyData={policyData}
              getPolicyData={getPolicyData}
            />
            <div className='card mb-4 mb-xl-6'>
              {policyData ? (
                <div className='card-body min-h-150px row'>
                  <div className='d-flex align-items-start mb-5'>
                    <span className='text-gray-800 text-hover-primary default-cursor fs-2 fw-semibold me-1'>
                      {policyData?.policy_name}
                    </span>
                    <button
                      className='ms-10 btn btn-sm btn-light-primary'
                      onClick={() => setShowChange(true)}
                    >
                      <KTIcon iconName='pencil' className='fs-3' />
                      Edit
                    </button>
                  </div>
                  <div className='row col-12 col-md-6 col-lg-5'>
                    <div>
                      {' '}
                      <div className='row mb-3 d-flex align-items-start'>
                        <label className='col-lg-4 col-6 fw-semibold text-muted'>Policy Code</label>
                        <div className='col-lg-8 col-6'>
                          <span className='fs-6 text-dark text-hover-primary default-cursor'>
                            {policyData?.policy_code || '-'}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-3 d-flex align-items-start'>
                        <label className='col-lg-4 col-6 fw-semibold text-muted'>
                          Current Version
                        </label>
                        <div className='col-lg-8 col-6'>
                          <span className='fs-6 text-dark text-hover-primary default-cursor'>
                            {policyData?.policy_version || '-'}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-3 d-flex align-items-start'>
                        <label className='col-lg-4 col-6 fw-semibold text-muted'>
                          Last Updated
                        </label>
                        <div className='col-lg-8 col-6'>
                          <span className='fs-6 text-dark text-hover-primary default-cursor'>
                            {policyData?.updated_timestamp
                              ? DateFormatter(policyData?.updated_timestamp)
                              : 'N.A.'}
                            {policyData?.updated_timestamp && ', '}

                            {policyData?.updated_timestamp &&
                              policyData?.updated_timestamp.length >= 16 &&
                              policyData?.updated_timestamp.slice(11, 16)}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-3 d-flex align-items-start'>
                        <label className='col-lg-4 col-6 fw-semibold text-muted'>Status</label>
                        <div className='col-lg-8 col-6'>
                          <span className='fs-6 text-dark text-hover-primary default-cursor'>
                            {policyData?.is_active === 1 ? 'Active' : 'Inactive'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row col-12 col-md-6 col-lg-7'>
                    <div>
                      {' '}
                      <div className='row mb-3 d-flex align-items-start'>
                        <label className='col-lg-4 col-6 fw-semibold text-muted'>
                          Policy Link / Legal Email ID
                        </label>
                        <div className='col-lg-8 col-6'>
                          <span className='fs-6 text-dark text-hover-primary default-cursor'>
                            {policyData?.policy_url || '-'}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-3 d-flex align-items-start'>
                        <label className='col-lg-4 col-6 fw-semibold text-muted'>Description</label>
                        <div className='col-lg-8 col-6'>
                          <span className='fs-6 text-dark text-hover-primary default-cursor'>
                            {policyData?.policy_description || '-'}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-3 d-flex align-items-start'>
                        <label className='col-lg-4 col-6 fw-semibold text-muted'>
                          Declaration Text
                        </label>
                        <div className='col-lg-8 col-6'>
                          <span className='fs-6 text-dark text-hover-primary default-cursor'>
                            {policyData?.declaration || '-'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className='row col-12 col-md-6 col-lg-7 d-flex flex-column justify-content-start'> */}
                </div>
              ) : (
                <div className='d-flex flex-column justify-content-center align-items-center h-150px'>
                  <div>
                    <h3 className='fs-3 fw-normal'>Not found</h3>
                    <button className='btn btn-sm btn-light-primary' onClick={getPolicyData}>
                      Retry
                    </button>
                  </div>
                </div>
              )}
            </div>
          </>
        )
      ) : (
        <div className='card min-h-250px d-flex flex-column justify-content-center align-items-center bg-image'>
          <h1 className='fw-semibold fs-2hx text-gray-900 mb-4'>Oops!</h1>
          <div className='fs-6 text-gray-500 mb-7'>You don't have access to this POD.</div>
          <div className='mb-0'>
            <Link to={`/${firstPodCode}/dashboard`} className='btn btn-sm btn-light-primary'>
              Return Home
            </Link>
          </div>
        </div>
      )}
      {podHaveAccess && (
        <>
          <div className='d-flex flex-wrap flex-stack mb-6'>
            <div>
              <h3 className='fs-3 fw-semibold my-2'>Policy Info</h3>
            </div>
            <div className='d-flex'>
              <Link className='btn btn-sm btn-light-primary ms-4 fw-normal' to={`/${id}/policies`}>
                <KTIcon iconName='arrow-left' className='fs-3' />
                Back
              </Link>
            </div>
          </div>
          <div className='w-100 d-flex flex-column flex-md-row justify-content-between'>
            <div className='w-100 card me-5 w-md-25 mb-5 h-100'>
              <div className='w-100 d-flex flex-column justify-content-between text-dark p-5'>
                <Link className='menu-item' to={`/${id}/policies/wordings/${policy_id}`}>
                  <p
                    className={clsx(
                      'menu-link btn btn-sm btn-color-muted btn-active btn-active-light-primary text-hover-primary mb-0 text-start',
                      {
                        active: pathname === `/${id}/policies/wordings/${policy_id}`,
                      }
                    )}
                    style={{padding: '10px', marginBottom: '0px !important'}}
                  >
                    <span className='menu-icon'>
                      <KTIcon iconName='badge' className='fs-2' />
                    </span>
                    <span className='menu-title fw-semibold'>Wordings</span>
                  </p>
                </Link>

                <Link className='menu-item' to={`/${id}/policies/history/${policy_id}`}>
                  <p
                    className={clsx(
                      'menu-link btn btn-sm btn-color-muted btn-active btn-active-light-primary text-hover-primary mb-0 text-start',
                      {
                        active: pathname === `/${id}/policies/history/${policy_id}`,
                      }
                    )}
                    style={{padding: '10px', marginBottom: '0px !important'}}
                  >
                    <span className='menu-icon'>
                      <KTIcon iconName='address-book' className='fs-2' />
                    </span>
                    <span className='menu-title fw-semibold'>History</span>
                  </p>
                </Link>

                <Link className='menu-item' to={`/${id}/policies/archive/${policy_id}`}>
                  <p
                    className={clsx(
                      'menu-link btn btn-sm btn-color-muted btn-active btn-active-light-primary text-hover-primary mb-0 text-start',
                      {
                        active: pathname === `/${id}/policies/archive/${policy_id}`,
                      }
                    )}
                    style={{padding: '10px', marginBottom: '0px !important'}}
                  >
                    <span className='menu-icon'>
                      <KTIcon iconName='book-open' className='fs-2' />
                    </span>
                    <span className='menu-title fw-semibold'>Archive</span>
                  </p>
                </Link>
              </div>
            </div>

            <div className='w-100 w-md-75 h-100 overflow-auto'>
              <Outlet />
            </div>
          </div>
        </>
      )}
    </>
  )
}

// export {PolicyCard, PolicyDataContext}

export {PolicyCard}

const validationSchema = Yup.object().shape({
  policy_name: Yup.string().required('Policy Name is required'),
  policy_description: Yup.string().required('Policy Description is required'),
})

export const UpdatePolicy = ({
  showModalChange,
  handleCloseChange,
  policyData,
  getPolicyData,
}: any) => {
  const {execute} = usePostApi()
  const {mode} = useThemeMode()
  const podId = FindPodIdByCode()
  const {policy_id} = useParams()
  const intl = useIntl()
  let initialValues = {
    policy_name: policyData?.policy_name || '',
    policy_url: policyData?.policy_url || '',
    policy_description: policyData?.policy_description || '',
    is_active: policyData?.is_active === 1 ? true : false,
    declaration: policyData?.declaration || '',
  }

  const handleSubmit = (values: any) => {
    const updatePolicy = () => {
      const formData = {
        pod_id: podId,
        policy_id: policy_id,
        policy_name: values.policy_name,
        policy_url: values.policy_url,
        policy_description: values.policy_description,
        declaration: values.declaration,
        is_active: values.is_active === true ? 1 : 0,
      }
      const updatePolicy = async () => {
        try {
          const url = '/pod/policy/update'

          const response = await execute(url, 'POST', formData)
          if (response?.status === true) {
            getPolicyData()
            handleCloseChange()
          }
          UseToast(
            response?.status_message,
            {
              position: 'bottom-right',
              autoClose: false,
              theme: 'colored',
            },
            'success'
          )
        } catch (error: any) {
          console.error('Error:', error)

          UseToast(
            error.response?.data?.status_message,
            {
              position: 'bottom-right',
              autoClose: false,
              theme: 'colored',
            },
            'error'
          )
        }
      }
      updatePolicy()
      // setEditMode(false)
    }
    updatePolicy()
  }
  return (
    <Modal show={showModalChange} onHide={handleCloseChange}>
      <Modal.Header>
        <div className='w-100 d-flex justify-content-between align-items-centers'>
          <h3 className='fs-3 fw-semibold mt-3'>Update Policy</h3>

          <div className='card-toolbar me-3'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-active-light-primary me-n5'
              id='kt_drawer_chat_close'
              onClick={handleCloseChange}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className='pt-0'>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({values}) => {
            return (
              <Form>
                <div className='modal-body p-0'>
                  <div className='w-100'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 mt-5'>
                      <span className='required'>Policy Name</span>
                      <i
                        className='fas fa-info-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='The name/title of the policy.'
                      ></i>
                    </label>

                    <Field
                      type='text'
                      name='policy_name'
                      className='form-control form-control-solid'
                      placeholder='Policy Name'
                    />

                    <ErrorMessage name='policy_name' component='div' className='text-danger' />
                  </div>

                  <div className='w-100'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 mt-5'>
                      <span className='required'>Policy Link / Legal Email ID</span>
                      <i
                        className='fas fa-info-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title="If the policy is available online, enter the URL here. Otherwise, enter the legal manager's Email ID."
                      ></i>
                    </label>

                    <Field
                      type='text'
                      name='policy_url'
                      className='form-control form-control-solid'
                      placeholder='Policy Link'
                    />

                    <ErrorMessage name='policy_url' component='div' className='text-danger' />
                  </div>

                  <div className='w-100'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 mt-5'>
                      <span className='required'>About {values?.policy_name}</span>
                      <i
                        className='fas fa-info-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Provide a short description of the policy and its purpose.'
                      ></i>
                    </label>

                    <Field
                      as='textarea'
                      className={`form-control form-control form-control-solid`}
                      name='policy_description'
                      placeholder='Briefly describe the policy'
                      rows={3}
                    />

                    <ErrorMessage
                      name='policy_description'
                      component='div'
                      className='text-danger'
                    />
                  </div>

                  <div className='w-100'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 mt-5'>
                      <span className='required'>Policy Declaration Text</span>
                      <i
                        className='fas fa-info-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Please provide the policy declaration text for this policy, as it will be used in the confirmation label for employee onboarding.'
                      ></i>
                    </label>

                    <Field
                      as='textarea'
                      className={`form-control form-control form-control-solid`}
                      name='declaration'
                      placeholder='Briefly describe about the policy declaration'
                      rows={3}
                    />

                    <ErrorMessage name='declaration' component='div' className='text-danger' />
                  </div>

                  <div className='w-100'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 mt-5'>
                      <span className='required'>Policy Status</span>
                      <i
                        className='fas fa-info-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='This indicates the current state of the policy.'
                      ></i>
                    </label>

                    <div className='form-floating flex-fill'>
                      <div className='form-check form-check-solid form-switch mb-0'>
                        <Field
                          className='form-check-input w-40px h-20px'
                          type='checkbox'
                          name='is_active'
                          id='toggle'
                          style={{
                            backgroundColor: values.is_active
                              ? '#009688'
                              : mode === 'dark'
                              ? '#1b1b29'
                              : '#f4f4f4',
                          }}
                        />
                        <span
                          className={`me-4 fw-semibold ${
                            values.is_active ? 'text-success' : 'text-danger'
                          }`}
                        >
                          {values.is_active ? 'Active' : 'Inactive'}
                        </span>
                        <ErrorMessage component='div' className='text-danger' name='is_active' />
                      </div>
                    </div>
                    {/* <div>
                      <button
                        style={{
                          borderRadius: '0px',
                          borderTopLeftRadius: '24px',
                          borderBottomLeftRadius: '24px',
                        }}
                        name='status'
                        className={clsx('btn btn-sm', {
                          'btn-primary': values.is_active === 1,
                          'btn-light-primary': values.is_active !== 1,
                        })}
                        type='button'
                        onClick={() => setFieldValue('is_active', 1)}
                      >
                        Active
                      </button>
                      <button
                        style={{
                          borderRadius: '0px',
                          borderTopRightRadius: '24px',
                          borderBottomRightRadius: '24px',
                        }}
                        name='status'
                        className={clsx('btn btn-sm', {
                          'btn-primary': values.is_active === 0,
                          'btn-light-primary': values.is_active !== 0,
                        })}
                        type='button'
                        onClick={() => setFieldValue('is_active', 0)}
                      >
                        Inactive
                      </button>
                    </div> */}
                  </div>
                  <button
                    type='submit'
                    className='d-none btn btn-light-primary btn-sm'
                    id='save_policy_updates'
                  >
                    Save
                  </button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <button type='button' className='btn btn-light btn-sm' onClick={handleCloseChange}>
          {intl.formatMessage({id: 'BTN.CLOSE'})}
        </button>

        <button
          className='btn btn-light-primary btn-sm'
          onClick={() => {
            const submitBtn = document.getElementById('save_policy_updates')
            if (submitBtn) {
              submitBtn.click()
            }
          }}
          type='submit'
        >
          {intl.formatMessage({id: 'BTN.SAVE'})}
        </button>
      </Modal.Footer>
    </Modal>
  )
}
