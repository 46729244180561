//businessBlock
import React, {useEffect, useMemo, useState} from 'react'
import {useTable, usePagination} from 'react-table'
import {Pagination} from 'react-bootstrap'
import {FormattedMessage, useIntl} from 'react-intl'
import {KTIcon} from '../../../../../_metronic/helpers'
import usePageTitle from '../../../custom_hooks/usePageTitle'
import {FindPodIdByCode} from '../../../FindPodIdByPodCode'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import {AddBusinessUnit} from './AddBusinessUnit'
import {EditBusinessUnit} from './EditBusinessUnit'
import NoDataFound from '../../no_data_found/NoDataFound'
import NoDataIcon from './bank.png'
import usePostApi from '../../../custom_hooks/usePostApi'
const DataTable = ({columns, data, loading, fetchData}) => {
  const [inputVal, setInputVal] = useState('')
  const [popover, setPopover] = useState(null)
  const [id, updateId] = useState('')
  const BusinessUnitClosePopover = () => {
    setPopover(null)
  }
  const openPopover = (id) => (
    <Popover flip='true' placement='top' className='min-w-400px'>
      <AddBusinessUnit props={id} />
    </Popover>
  )
  const intl = useIntl()
  usePageTitle(intl.formatMessage({id: 'MENU.ORGANIZATION_CONFIGURATION.BUSINESS_UNITS'}))
  const filteredData = useMemo(() => {
    return data?.filter((each) => each.bu_name?.toLowerCase().includes(inputVal.toLowerCase()))
  }, [data, inputVal])

  // Create separate datasets for active and inactive records
  const activeData = useMemo(() => {
    return filteredData?.filter((record) => record.status === 1)
  }, [filteredData])

  const inactiveData = useMemo(() => {
    return filteredData?.filter((record) => record.status === 0)
  }, [filteredData])

  // Table instances for active and inactive dataset
  const activeTableInstance = useTable({columns, data: activeData}, usePagination)
  const inactiveTableInstance = useTable({columns, data: inactiveData}, usePagination)
  // Extract required values from table instances
  const activePage = activeTableInstance.page
  const activePageCount = activeTableInstance.pageCount
  const activePageIndex = activeTableInstance.state.pageIndex
  const activePageSize = activeTableInstance.state.pageSize

  const inactivePage = inactiveTableInstance.page
  const inactivePageCount = inactiveTableInstance.pageCount
  const inactivePageIndex = inactiveTableInstance.state.pageIndex
  const inactivePageSize = inactiveTableInstance.state.pageSize

  // Handle pagination for active dataset
  const gotoActivePage = (pageIndex) => {
    activeTableInstance.gotoPage(pageIndex)
  }

  const setActivePageSize = (pageSize) => {
    activeTableInstance.setPageSize(pageSize)
  }

  // Handle pagination for inactive dataset
  const gotoInactivePage = (pageIndex) => {
    inactiveTableInstance.gotoPage(pageIndex)
  }

  const setInactivePageSize = (pageSize) => {
    inactiveTableInstance.setPageSize(pageSize)
  }
  const pageOptions = [5, 10, 20, 30, 40, 50]
  // Render the component
  return (
    <div>
      {loading ? (
        <div className='mt-6 h-250px d-flex justify-content-center align-items-center'>
          <div className='spinner-border spinner-primary mr-15'></div>
        </div>
      ) : data.length > 0 ? (
        <div className='card p-10 py-5 pt-0'>
          <div className='card-header border-0 px-0'>
            <div className='d-flex flex-wrap flex-stack'>
              <div className='fw-bolder'>
                <ul className='nav'>
                  <li className='nav-item'>
                    <a
                      className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-success active fw-normal px-4 me-1'
                      data-bs-toggle='tab'
                      href='#kt_table_widget_4_tab_1'
                    >
                      {intl.formatMessage({id: 'LABEL.ACTIVE'})}
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-danger fw-normal px-4 me-1'
                      data-bs-toggle='tab'
                      href='#kt_table_widget_4_tab_2'
                    >
                      {intl.formatMessage({id: 'LABEL.INACTIVE'})}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='card-toolbar'>
              <div className='d-flex align-items-center position-relative me-2'>
                <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                <input
                  type='search'
                  id='kt_filter_search'
                  className='form-control form-control-white form-control-sm w-150px ps-9 fw-normal'
                  placeholder={intl.formatMessage({id: 'LABEL.SEARCH'})}
                  value={inputVal}
                  onChange={(e) => setInputVal(e.target.value)}
                  autoComplete='off'
                />
              </div>
              <OverlayTrigger
                trigger='click'
                placement='auto'
                overlay={openPopover(id)}
                show={popover === 'add_business_unit'}
              >
                <button
                  className='btn btn-sm btn-light-primary fw-normal'
                  onClick={(e) => {
                    e.stopPropagation()
                    setPopover('add_business_unit')
                    updateId({fetchData, BusinessUnitClosePopover})
                  }}
                >
                  <KTIcon iconName='plus' className='fs-3' />
                  Add Business Unit
                </button>
              </OverlayTrigger>
            </div>
          </div>
          <div className='tab-content'>
            {/* Active Tab */}
            <div
              className='tab-pane fade show active table-responsive'
              id='kt_table_widget_4_tab_1'
            >
              <table
                className='table table-row-dashed align-middle table-row-gray-300'
                {...activeTableInstance.getTableProps()}
              >
                <thead>
                  <tr className='fw-semibold text-muted default-cursor bg-light rounded'>
                    <th className='min-w-120px ps-3'>
                      Business Unit {intl.formatMessage({id: 'LABEL.NAME'})}
                    </th>
                    <th className='min-w-120px'>Business Unit Code</th>
                    <th className='min-w-120px'>{intl.formatMessage({id: 'LABEL.ERP_ID'})}</th>
                    <th className='min-w-120px'>{intl.formatMessage({id: 'LABEL.STATUS'})}</th>
                    <th className='min-w-120px'>{intl.formatMessage({id: 'LABEL.ACTION'})}</th>
                  </tr>
                </thead>
                <tbody {...activeTableInstance.getTableBodyProps()}>
                  {activePage.length > 0 ? (
                    activePage.map((row) => {
                      activeTableInstance.prepareRow(row)
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => (
                            <td className=' ' {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          ))}
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td className='' colSpan={5}>
                        <div className='d-flex justify-content-center'>
                          <p className='text-dark default-cursor fs-6 mt-5'>
                            {inputVal === '' ? (
                              intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
                            ) : (
                              <FormattedMessage
                                id='MSG.NO_DATA_MATCH'
                                values={{
                                  inputVal: <span className='text-primary'>{inputVal}</span>,
                                }}
                              />
                            )}
                          </p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {activePage.length > 10 && (
                <Pagination>
                  <Pagination.First
                    onClick={() => gotoActivePage(0)}
                    disabled={!activeTableInstance.canPreviousPage}
                  />
                  <Pagination.Prev
                    onClick={activeTableInstance.previousPage}
                    disabled={!activeTableInstance.canPreviousPage}
                  />
                  {[...Array(activePageCount)].map((_, i) => (
                    <Pagination.Item
                      key={i}
                      active={i === activePageIndex}
                      onClick={() => gotoActivePage(i)}
                    >
                      {i + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={activeTableInstance.nextPage}
                    disabled={!activeTableInstance.canNextPage}
                  />
                  <Pagination.Last
                    onClick={() => gotoActivePage(activePageCount - 1)}
                    disabled={!activeTableInstance.canNextPage}
                  />
                  <div className='d-flex align-items-center mx-5'>
                    <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                    <select
                      className='form-select form-select-solid fw-semibold w-75px me-2'
                      value={activePageSize}
                      onChange={(e) => setActivePageSize(Number(e.target.value))}
                    >
                      {pageOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </Pagination>
              )}
            </div>

            {/* Inactive Tab */}
            <div className='tab-pane fade table-responsive' id='kt_table_widget_4_tab_2'>
              <table
                className='table table-row-dashed align-middle table-row-gray-300'
                {...inactiveTableInstance.getTableProps()}
              >
                <thead>
                  <tr className='fw-semibold text-muted default-cursor bg-light rounded'>
                    <th className='min-w-120px ps-3'>
                      Business Unit {intl.formatMessage({id: 'LABEL.NAME'})}
                    </th>
                    <th className='min-w-120px'>Business Unit Code</th>
                    <th className='min-w-120px'>{intl.formatMessage({id: 'LABEL.ERP_ID'})}</th>
                    <th className='min-w-120px'>{intl.formatMessage({id: 'LABEL.STATUS'})}</th>
                    <th className='min-w-120px'>{intl.formatMessage({id: 'LABEL.ACTION'})}</th>
                  </tr>
                </thead>
                <tbody {...inactiveTableInstance.getTableBodyProps()}>
                  {inactivePage.length > 0 ? (
                    inactivePage.map((row) => {
                      inactiveTableInstance.prepareRow(row)
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => (
                            <td className=' ' {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          ))}
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td className='' colSpan={5}>
                        <div className='d-flex justify-content-center'>
                          <p className='text-dark default-cursor fs-6 mt-5'>
                            {inputVal === '' ? (
                              intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
                            ) : (
                              <FormattedMessage
                                id='MSG.NO_DATA_MATCH'
                                values={{
                                  inputVal: <span className='text-primary'>{inputVal}</span>,
                                }}
                              />
                            )}
                          </p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {inactivePage.length > 10 && (
                <Pagination>
                  <Pagination.First
                    onClick={() => gotoInactivePage(0)}
                    disabled={!inactiveTableInstance.canPreviousPage}
                  />
                  <Pagination.Prev
                    onClick={inactiveTableInstance.previousPage}
                    disabled={!inactiveTableInstance.canPreviousPage}
                  />
                  {[...Array(inactivePageCount)].map((_, i) => (
                    <Pagination.Item
                      key={i}
                      active={i === inactivePageIndex}
                      onClick={() => gotoInactivePage(i)}
                    >
                      {i + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={inactiveTableInstance.nextPage}
                    disabled={!inactiveTableInstance.canNextPage}
                  />
                  <Pagination.Last
                    onClick={() => gotoInactivePage(inactivePageCount - 1)}
                    disabled={!inactiveTableInstance.canNextPage}
                  />
                  <div className='d-flex align-items-center mx-5'>
                    <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                    <select
                      className='form-select form-select-solid fw-semibold w-75px me-2'
                      value={inactivePageSize}
                      onChange={(e) => setInactivePageSize(Number(e.target.value))}
                    >
                      {pageOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </Pagination>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className='m-2 p-4'>
          <NoDataFound
            data={{
              name: 'Business Units',
              smallname: 'business units',
              icon: {NoDataIcon},
              buttonLabel: '"+ Add Business Unit"',
            }}
          />
          <div className='m-auto d-flex flex-column align-items-center'>
            <OverlayTrigger
              trigger='click'
              placement='top'
              overlay={openPopover(id)}
              show={popover === 'add_business_unit'}
            >
              <button
                className='btn btn-sm btn-light-primary fw-normal'
                onClick={(e) => {
                  e.stopPropagation()
                  setPopover('add_business_unit')
                  updateId({fetchData, BusinessUnitClosePopover})
                }}
              >
                <KTIcon iconName='plus' className='fs-3' />
                Add Business Unit
              </button>
            </OverlayTrigger>
          </div>
        </div>
      )}
    </div>
  )
}

const BusinessBlock = () => {
  const podId = FindPodIdByCode()
  const [businessUnitData, setTableData] = useState([])
  const {execute, loading: isLoading} = usePostApi()
  const fetchData = async () => {
    try {
      const url = `/pod/business_unit/list`
      const formData = {pod_id: podId}
      const response = await execute(url, 'POST', formData)
      setTableData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'business_unit_name',
        Cell: ({row}) => {
          return (
            <div className='d-flex align-items-center'>
              <div className='d-flex justify-content-start flex-column'>
                <p className='fw-semibold text-dark text-hover-primary fs-6 default-cursor ps-3'>
                  {row.original.bu_name}
                </p>
              </div>
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'BU CODE',
        accessor: 'bu_code',
        Cell: ({row}) => {
          return (
            <p className='fw-normal text-dark text-hover-primary d-block fs-6 default-cursor'>
              {row.original.bu_code}
            </p>
          )
        },
      },
      {
        Header: 'ERP ID',
        accessor: 'erp_id',
        Cell: ({row}) => {
          return (
            <p className='fw-normal text-dark text-hover-primary d-block fs-6 default-cursor'>
              {row.original.erp_id ? row.original.erp_id : '-'}
            </p>
          )
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({row}) => {
          const statusColor = row.original.status === 1 ? 'success' : 'danger'
          return (
            <p className={`text-${statusColor} fw-normal d-block fs-6 default-cursor`}>
              {row.original.status === 1 ? 'Active' : 'Inactive'}
            </p>
          )
        },
      },
      {
        Header: 'Actions',
        accessor: 'Actions',
        Cell: ({row}) => {
          const [id, updateId] = useState('')
          const [popover, setPopover] = useState(null)
          const ClosePopover = () => {
            setPopover(null)
          }
          const openPopover = (id) => (
            <Popover
              flip='true'
              placement='top'
              className='min-w-400px'
              style={{marginTop: '-20px'}}
            >
              <EditBusinessUnit props={id} />
            </Popover>
          )
          return (
            <>
              <OverlayTrigger
                trigger='click'
                placement='auto'
                overlay={openPopover(id)}
                show={popover === 'edit_business_unit'}
              >
                <div
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                  onClick={(e) => {
                    e.stopPropagation()
                    setPopover('edit_business_unit')
                    updateId({
                      refreshData: fetchData,
                      ClosePopover,
                      id: row.original.bu_id,
                      name: row.original.bu_name,
                      code: row.original.bu_code,
                      erp_id: row.original.erp_id,
                      getstatus: row.original.status,
                    })
                  }}
                >
                  <KTIcon iconName='pencil' className='fs-3' />
                </div>
              </OverlayTrigger>
            </>
          )
        },
      },
    ],
    [fetchData]
  )

  return (
    <DataTable
      columns={columns}
      data={businessUnitData ? businessUnitData : []}
      loading={isLoading}
      fetchData={fetchData}
    />
  )
}

export default BusinessBlock
