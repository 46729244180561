import {useState, useEffect} from 'react'
import {Link, Outlet} from 'react-router-dom'
import usePostApi from '../../custom_hooks/usePostApi'
import {useLocation, useParams} from 'react-router-dom'
import {defaultProfileImage} from '../../core'
import DateFormatter from '../../DateFormatter'
import {PageTitle} from '../../../../_metronic/layout/core'
import {KTIcon} from '../../../../_metronic/helpers'
import {UseToast} from '../../useToast'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import UpdateStatus from './ChangeStatus'

function EmpCard() {
  const {id, jobJoinCode, pin} = useParams()
  const {pathname} = useLocation()

  const {execute, loading, postLoading} = usePostApi()
  const [basicInfo, setBasicInfo] = useState([])
  const getCandidateBasicInfo = async () => {
    try {
      const url = '/pod/onbr/basicInfo/get'
      // const url = '/pod/onbr/basic-info/get'
      const formData = {
        onbr_code: jobJoinCode,
        onbr_pin: pin,
      }

      const response = await execute(url, 'POST', formData)
      setBasicInfo(response.data[0])
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getCandidateBasicInfo()
  }, [])

  const getStatus = (status) => {
    switch (status) {
      case 'A':
        return 'Active'
      case 'I':
        return 'InProgress'
      case 'O':
        return 'Open'
      case 'N':
        return 'New'
      case 'S':
        return 'Submitted'
      case 'P':
        return 'Processing'
      case 'X':
        return 'Cancelled'
      case 'C':
        return 'Completed'
      case 'E':
        return 'Expired'
      case 'R':
        return 'Revoked'
      default:
        return 'InProgress'
    }
  }

  const candidateBasicInfo = [
    {
      title: `(${id})`,
      path: `/${id}/onboarding`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: ``,
      path: `/${id}/onboarding`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `Onboarding`,
      path: `/${id}/onboarding`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: `/${id}/onboarding`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: basicInfo ? `${basicInfo?.candidate_name} (${basicInfo?.onbr_code})` : '',
      path: `/${id}/onboarding/onboarding-info/${jobJoinCode}`,
      isSeparator: false,
      isActive: false,
    },
  ]

  const sendOnboardingRequest = async () => {
    try {
      const url = '/pod/onboarding/request/send'
      const formData = {
        onbr_code: jobJoinCode,
        onbr_pin: pin,
      }

      const response = await execute(url, 'POST', formData, 'post')
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
    } catch (error) {
      console.error('Error:', error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }

  const [popover, setPopover] = useState('')

  const openPopover = (id, status) => {
    const data = {
      refreshData: getCandidateBasicInfo,
      ClosePopover: () => setPopover(''),
      memberId: id,
      status: status,
    }
    return (
      <Popover id={`add-template-popover-${id}`} flip placement='auto' className='min-w-300px'>
        <Popover.Header as='div'>
          <div className='card-toolbar me-3 d-flex justify-content-between align-items-center'>
            <h3 className='fs-4 fw-semibold'>Update Status</h3>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-active-light me-n5'
              onClick={() => setPopover('')}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </button>
          </div>
        </Popover.Header>
        <Popover.Body>
          <UpdateStatus data={data} />
        </Popover.Body>
      </Popover>
    )
  }

  const handleBodyClick = (e) => {
    const isInsidePopover = e.target.closest('.popover')

    if (!isInsidePopover) {
      setPopover('')
    }
  }

  useEffect(() => {
    document.body.addEventListener('click', handleBodyClick)

    return () => {
      document.body.removeEventListener('click', handleBodyClick)
    }
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={candidateBasicInfo}>Onboarding Info</PageTitle>

      {loading ? (
        <div className='card mb-5' style={{height: '220px'}}>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : (
        <div className='card mb-4 mb-xl-6'>
          <div className='card-body min-h-150px row pb-0'>
            <div className='row col-12 col-md-6 col-lg-7'>
              <div className='d-flex flex-column flex-md-row mb-0 pb-0'>
                <div className='me-md-4 mb-4 mb-md-0'>
                  <div className='symbol symbol-100px symbol-lg-150px symbol-fixed position-relative'>
                    <img
                      style={{height: '140px', width: '140px'}}
                      src={basicInfo?.candidate_image ?? defaultProfileImage}
                      alt='profile'
                    />
                  </div>
                </div>
                <div className='w-100'>
                  <div className='d-flex align-items-start mb-3'>
                    <span className='text-gray-800 text-hover-primary default-cursor fs-2 fw-semibold me-1'>
                      {basicInfo?.candidate_name}
                    </span>
                  </div>
                  <div className='row mb-3 d-flex align-items-start'>
                    <label className='col-lg-4 col-6 fw-semibold text-muted'>Email</label>
                    <div className='col-lg-8 col-6'>
                      <span className='fs-6 text-dark text-hover-primary default-cursor'>
                        {basicInfo?.email_address}
                      </span>
                    </div>
                  </div>
                  {/* <div className='row mb-3 d-flex align-items-start'>
                    <label className='col-lg-4 col-6 fw-semibold text-muted'>Job Join Code</label>
                    <div className='col-lg-8 col-6'>
                      <span className='fs-6 text-dark text-hover-primary default-cursor'>
                        {basicInfo?.onbr_code}
                      </span>
                    </div>
                  </div> */}

                  <div className='row mb-3 d-flex align-items-start'>
                    <label className='col-lg-4 col-6 fw-semibold text-muted'>Status</label>
                    <div className='col-lg-8 col-6'>
                      <span className='fs-6 text-dark text-hover-primary default-cursor'>
                        {getStatus(basicInfo?.status)}
                      </span>
                    </div>
                  </div>
                  <div className='w-100 d-none d-md-flex d-lg-block btn-group' role='group'>
                    <button
                      type='button'
                      className='btn btn-light-primary btn-sm flex-fill'
                      style={{border: '1px solid #a1a5b7', height: '40px'}}
                      onClick={sendOnboardingRequest}
                      data-bs-toggle='tooltip'
                      title='Send onboarding details to the candidate via email.'
                    >
                      {postLoading ? (
                        <>
                          Sending...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </>
                      ) : (
                        <>
                          <KTIcon iconName='sms' className='fs-1' />
                          Send E-mail
                        </>
                      )}
                    </button>

                    <button
                      type='button'
                      className='btn btn-light-success btn-icon flex-fill'
                      style={{border: '1px solid #a1a5b7', height: '40px'}}
                      onClick={() => window.open(`https://wa.me/${basicInfo?.mobile}`, '_blank')}
                      data-bs-toggle='tooltip'
                      title='Send onboarding details to the candidate via whatsapp.'
                    >
                      <KTIcon iconName='whatsapp' className='fs-1' />
                    </button>
                    <button
                      type='button'
                      className='btn btn-light-info btn-icon flex-fill'
                      style={{border: '1px solid #a1a5b7', height: '40px'}}
                      onClick={() => window.open('https://messenger.com', '_blank')}
                      data-bs-toggle='tooltip'
                      title='Send onboarding details to the candidate via messenger.'
                    >
                      <KTIcon iconName='sms' className='fs-1' />
                    </button>
                    <OverlayTrigger
                      trigger='click'
                      placement='auto'
                      overlay={openPopover(basicInfo?.onbr_id, basicInfo?.status)}
                      show={popover === 'update_onbr_req_status-1'}
                    >
                      <button
                        type='button'
                        className='btn btn-light-primary btn-icon flex-fill'
                        style={{border: '1px solid #a1a5b7', height: '40px'}}
                        onClick={(e) => {
                          e.stopPropagation()
                          setPopover('update_onbr_req_status-1')
                        }}
                        data-bs-toggle='tooltip'
                        title='Update the status of the request.'
                      >
                        <KTIcon iconName='pencil' className='fs-1' />
                      </button>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </div>

            <div className='row col-12 col-md-6 col-lg-5 pt-md-12'>
              <div>
                <div className='row mb-3 d-flex align-items-start'>
                  <label className='col-lg-3 col-6 fw-semibold text-muted'>Job Join Code</label>
                  <div className='col-lg-9 col-6'>
                    <span className='fs-6 text-dark text-hover-primary default-cursor'>
                      {basicInfo?.onbr_code}
                    </span>
                  </div>
                </div>
                <div className='row mb-3 d-flex align-items-start'>
                  <label className='col-lg-3 col-6 fw-semibold text-muted'>Location</label>
                  <div className='col-lg-9 col-6'>
                    <span className='fs-6 text-dark text-hover-primary default-cursor'>
                      {basicInfo?.location_name}
                    </span>
                  </div>
                </div>
                <div className='row mb-3 d-flex align-items-start'>
                  <label className='col-lg-3 col-6 fw-semibold text-muted'>Last Updated</label>
                  <div className='col-lg-9 col-6'>
                    <span className='fs-6 text-dark text-hover-primary default-cursor'>
                      {basicInfo?.updated_ts ? DateFormatter(basicInfo?.updated_ts) : 'N.A.'}
                      {basicInfo?.updated_ts && ', '}

                      {basicInfo?.updated_ts &&
                        basicInfo?.updated_ts.length >= 16 &&
                        basicInfo?.updated_ts.slice(11, 16)}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className='d-md-none btn-group mr-2' role='group'>
              <button
                type='button'
                className='btn btn-light-primary btn-sm'
                style={{border: '1px solid #a1a5b7'}}
                onClick={sendOnboardingRequest}
                data-bs-toggle='tooltip'
                title='Send onboarding details to the candidate via email.'
              >
                {postLoading ? (
                  <>
                    Sending...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </>
                ) : (
                  <>
                    <KTIcon iconName='sms' className='fs-1' />
                    Send E-mail
                  </>
                )}
              </button>
              <button
                type='button'
                className='btn btn-light-success btn-icon'
                style={{border: '1px solid #a1a5b7'}}
                onClick={() => window.open(`https://wa.me/${basicInfo?.mobile}`, '_blank')}
                data-bs-toggle='tooltip'
                title='Send onboarding details to the candidate via whatsapp.'
              >
                <KTIcon iconName='whatsapp' className='fs-1' />
              </button>
              <button
                type='button'
                className='btn btn-light-info btn-icon'
                style={{border: '1px solid #a1a5b7'}}
                onClick={() => window.open('https://messenger.com', '_blank')}
                data-bs-toggle='tooltip'
                title='Send onboarding details to the candidate via sms.'
              >
                <KTIcon iconName='sms' className='fs-1' />
              </button>
              <OverlayTrigger
                trigger='click'
                placement='auto'
                overlay={openPopover(basicInfo?.onbr_id, basicInfo?.status)}
                show={popover === 'update_onbr_req_status'}
              >
                <button
                  type='button'
                  className='btn btn-light-primary btn-icon'
                  style={{border: '1px solid #a1a5b7'}}
                  onClick={(e) => {
                    e.stopPropagation()
                    setPopover('update_onbr_req_status')
                  }}
                  data-bs-toggle='tooltip'
                  title='Update the status of the request.'
                >
                  <KTIcon iconName='pencil' className='fs-1' />
                </button>
              </OverlayTrigger>
            </div>

            <div className='d-flex overflow-auto h-55px'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap p-0'>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (pathname.startsWith(`/${id}/onboarding/onboarding-info`) && 'active')
                    }
                    to={`/${id}/onboarding/onboarding-info/${jobJoinCode}/${pin}`}
                  >
                    Onboarding Info
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (pathname.startsWith(`/${id}/onboarding/history`) && 'active')
                    }
                    to={`/${id}/onboarding/history/${jobJoinCode}/${pin}`}
                  >
                    History
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      <Outlet />
    </>
  )
}

export default EmpCard
