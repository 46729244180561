/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useRef} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {Link} from 'react-router-dom'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import './index.css'
import {KTIcon} from '../../../../_metronic/helpers'
import {motion, AnimatePresence} from 'framer-motion'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import secureLocalStorage from 'react-secure-storage'
import usePostApi from '../../custom_hooks/usePostApi'
import ReCAPTCHA from 'react-google-recaptcha'

const registrationSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('First Name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
  last_name: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Last Name is required'),
  password_field: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .max(50, 'Password must be at most 50 characters')
    .required('Password is required'),
  not_robot: Yup.string().required('Please verify that you are not a robot.'),
})

export function Registration() {
  const [data, setData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password_field: '',
    not_robot: '',
  })
  const handleNextStep = (newData: any) => {
    setData((prev) => ({...prev, ...newData}))
    setCurrentStep((prev) => prev + 1)
  }

  const [currentStep, setCurrentStep] = useState(0)
  const steps = [
    <Step1 next={handleNextStep} data={data} key='step_1' />,
    <Step2 data={data} key='step_2' />,
  ]

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <div
      className='min-w-350px d-flex flex-column justify-content-center align-items-center reg-con con-max-width'
      style={{maxWidth: '350px'}}
    >
      {steps[currentStep]}
      <div className='text-gray-500 text-center fw-semibold fs-6 mt-10'>
        Already have an Account?{' '}
        <Link to='/auth/login' className='login-text active'>
          Sign In
        </Link>
      </div>
    </div>
  )
}

const Step1 = (props: any) => {
  const {execute} = usePostApi()
  const [statusMsg, setStatusMsg] = useState('')

  const scrambleX = (str: any, numTimes = 2) => {
    let encoded = str
    for (let i = 0; i < numTimes; i++) {
      encoded = btoa(encoded)
    }
    return encoded
  }
  const onSubmit = async (Values: any) => {
    try {
      const url = '/lead/create'
      const formData = {
        first_name: Values.first_name,
        last_name: Values.last_name,
        email: Values.email,
        password: scrambleX(Values.password_field),
        recaptcha_validation_token: Values.not_robot,
      }

      const response = await execute(url, 'POST', formData)
      if (response.http_code === 200) {
        props.next({...Values})
      }
    } catch (error: any) {
      console.error(error)
      if (error.response) {
        const statusCode = error.response.data.status_code
        const statusMsg = error.response.data.status_message
        setStatusMsg(statusMsg)
        if (statusCode === 'SC0014') {
          props.next({...Values})
        }
      } else {
        console.error('An unexpected error occurred:', error)
      }
    }
  }
  const [showPassword, setShowPassword] = useState(false)
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const recaptchaRef = useRef<ReCAPTCHA | null>(null)
  const [recaptchaSelected, setRecaptchaSelected] = useState(false)

  return (
    <AnimatePresence mode='wait'>
      <motion.div
        // key={selectedTab ? selectedTab.label : 'empty'}
        initial={{y: 10, opacity: 0}}
        animate={{y: 0, opacity: 1}}
        exit={{y: -10, opacity: 0}}
        transition={{duration: 0.5}}
      >
        <div className='login-light-mode'>
          <Formik
            validationSchema={registrationSchema}
            initialValues={props.data}
            onSubmit={onSubmit}
          >
            {({setFieldValue, values}: any) => {
              return (
                <Form className='min-w-350px'>
                  <div className='text-center mb-11'>
                    <h1 className='fw-semibold mb-3' style={{color: '#181c32'}}>
                      Create Account
                    </h1>
                  </div>
                  <div className='d-flex'>
                    <div className='fv-row mb-8 me-1'>
                      <p className='form-label fs-6 fw-semibold mb-0' style={{color: '#181c32'}}>
                        First Name
                      </p>
                      <Field
                        name='first_name'
                        className='form-control'
                        placeholder='First Name'
                        autoFocus
                        onChange={(e: any) => {
                          setFieldValue('first_name', e.target.value)
                          if (recaptchaSelected && recaptchaRef.current) {
                            recaptchaRef.current.reset()
                            setFieldValue('not_robot', '')
                            setRecaptchaSelected(false)
                          }
                        }}
                      />
                      <ErrorMessage name='first_name' component='div' className='text-danger' />
                    </div>
                    <div className='fv-row mb-8 ms-1'>
                      <p className='form-label fs-6 fw-semibold mb-0' style={{color: '#181c32'}}>
                        Last Name
                      </p>
                      <Field
                        name='last_name'
                        className='form-control'
                        placeholder='Last Name'
                        onChange={(e: any) => {
                          setFieldValue('last_name', e.target.value)
                          if (recaptchaSelected && recaptchaRef.current) {
                            recaptchaRef.current.reset()
                            setFieldValue('not_robot', '')
                            setRecaptchaSelected(false)
                          }
                        }}
                      />
                      <ErrorMessage name='last_name' component='div' className='text-danger' />
                    </div>
                  </div>
                  <div className='fv-row mb-8'>
                    <p className='mb-0 form-label fs-6 fw-semibold' style={{color: '#181c32'}}>
                      Email
                    </p>
                    <Field
                      name='email'
                      className='form-control'
                      placeholder='Email'
                      onChange={(e: any) => {
                        setFieldValue('email', e.target.value)
                        if (recaptchaSelected && recaptchaRef.current) {
                          recaptchaRef.current.reset()
                          setFieldValue('not_robot', '')
                          setRecaptchaSelected(false)
                        }
                      }}
                    />
                    <ErrorMessage name='email' component='div' className='text-danger' />
                  </div>

                  <div className='fv-row mb-8'>
                    <p className='mb-0 form-label fs-6 fw-semibold' style={{color: '#181c32'}}>
                      Password
                    </p>
                    <div className='position-relative'>
                      <Field
                        name='password_field'
                        type={showPassword ? 'text' : 'password'}
                        className='form-control pr-10'
                        placeholder='Password'
                        onChange={(e: any) => {
                          setFieldValue('password_field', e.target.value)
                          if (recaptchaSelected && recaptchaRef.current) {
                            recaptchaRef.current.reset()
                            setFieldValue('not_robot', '')
                            setRecaptchaSelected(false)
                          }
                        }}
                      />
                      <span onClick={togglePasswordVisibility}>
                        <KTIcon
                          iconName={showPassword ? 'eye-slash' : 'eye'}
                          className='fs-2 icon'
                        />
                      </span>
                    </div>
                    <ErrorMessage name='password_field' component='div' className='text-danger' />
                  </div>
                  <div className='mb-8 fw-normal'>
                    By signing up, you are creating a {process.env.REACT_APP_APP_NAME} account, and
                    you agree to {process.env.REACT_APP_APP_NAME}’s{' '}
                    <a className='login-text active' href='https://legal.jetboard.site/tos'>
                      Terms of Use
                    </a>{' '}
                    and{' '}
                    <a className='login-text active' href='https://legal.jetboard.site/privacy'>
                      Privacy Policy
                    </a>
                    .
                  </div>
                  <div className='mb-8 w-100 d-flex justify-content-center'>
                    <div>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
                        // sitekey='6LeDB74pAAAAACtM7mxTw8d0onUarTtpaZBPUfAu'
                        onChange={(val: any) => {
                          setFieldValue('not_robot', val)
                          setRecaptchaSelected(true)
                        }}
                        onExpired={() => {
                          setFieldValue('not_robot', '')
                          setRecaptchaSelected(false)
                        }}
                        onErrored={() => console.log('reCAPTCHA error')}
                      />
                      <ErrorMessage name='not_robot' component='div' className='text-danger' />
                    </div>
                  </div>
                  <button
                    type='submit'
                    className='btn btn-lg login-btn w-100'
                    style={{color: '#fff'}}
                  >
                    Verify & Signup
                  </button>
                  {statusMsg && (
                    // <div className='w-350px alert alert-danger d-flex align-items-center p-1 mt-2'>

                    <div className='alert border border-dashed border-gray-300 rounded px-5 py-3 badge-light-danger d-flex align-items-center p-2 mt-4 d-flex flex-row align-items-center'>
                      <KTIcon iconName='information' className='fs-3 text-danger me-1' />
                      <span className='fs-6' style={{color: '#181c32'}}>
                        {statusMsg}
                      </span>
                    </div>
                  )}
                </Form>
              )
            }}
          </Formik>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}

const Step2 = (props: any) => {
  // const navigate = useNavigate()
  const {execute} = usePostApi()
  const [otp, setOtp] = useState(['', '', '', '', '', ''])
  const [codeErr, setCodeErr] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [errorMsg, setErrorMessage] = useState('')

  const handleInputChange = (index: any, value: any) => {
    // Ensure that the input is a number and not empty
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newOtp = [...otp]
      newOtp[index] = value
      setOtp(newOtp)

      // If the current input is not empty and the next element exists, focus on it
      if (value && index < 5) {
        const nextInput = document.getElementById(`otp-input-${index + 1}`)
        if (nextInput) {
          nextInput.focus()
        }
      }
    }
  }

  const onSubmit = async () => {
    const code = otp.join('')
    if (code.length !== 6) {
      setCodeErr(true)
    }
    if (code.length === 6) {
      try {
        const url = '/lead/verify'
        const formData = {
          verify_user: props.data.email,
          verify_token: code,
          verify_type: 'OTP',
        }

        const auth = await execute(url, 'POST', formData)
        // const {data: auth} = await verifyLead(props.data.email, code, 'OTP')
        const userInfo = {
          auth_token: auth?.data?.token,
          user_id: auth?.data?.user_id,
        }

        secureLocalStorage.setItem('user_info', userInfo)
        saveAuth(auth)
        setCurrentUser(auth.data)
      } catch (error: any) {
        console.error(error)
        if (error.response) {
          const errorMessage = error.response.data.status_message
          setErrorMessage(errorMessage)
        } else {
          console.error('An unexpected error occurred:', error)
        }
        saveAuth(undefined)
      }
    }
  }

  const [showPopover, setShowPopover] = useState(false)
  const [resendOtpMsg, setResendOtpMsg] = useState('')

  const popover = (
    <Popover id='popover-basic'>
      <Popover.Body className='p-2'>{resendOtpMsg}</Popover.Body>
    </Popover>
  )

  const reqResendOTP = async () => {
    try {
      const url = '/lead/resend'
      const formData = {
        email: props.data.email,
      }

      const auth = await execute(url, 'POST', formData)
    } catch (error: any) {
      console.error(error)
      if (error.response) {
        const errorMessage = error.response.data.status_message
        setResendOtpMsg(errorMessage)
      } else {
        console.error('An unexpected error occurred:', error)
      }
    }

    setShowPopover(true)
    setTimeout(() => {
      setShowPopover(false)
    }, 3000)
  }

  return (
    <AnimatePresence mode='wait'>
      <motion.div
        initial={{y: 10, opacity: 0}}
        animate={{y: 0, opacity: 1}}
        exit={{y: -10, opacity: 0}}
        transition={{duration: 0.5}}
      >
        <Formik initialValues={props.data} onSubmit={onSubmit}>
          <Form className='min-w-350px d-flex flex-column justify-content-center align-items-center'>
            <KTIcon iconName='verify' className='verify-icon mb-5' />
            <h3 className='text-dark fw-semibold mb-3'>Account/E-mail Verification</h3>
            <div className='text-gray-500 text-center fw-normal fs-6 mb-3'>
              Enter the verification code we sent to
            </div>
            <h5 className='text-dark fw-semibold mb-10 text-center'>{props.data.email}</h5>
            <div className='fv-row mb-1'>
              <p className='text-dark fw-semibold mb-0 fs-6 mb-1'>
                Type your 6 digit security code
              </p>
              <div className='d-flex'>
                {otp.map((digit, index) => (
                  <input
                    className='otp-input'
                    key={index}
                    id={`otp-input-${index}`}
                    type='text'
                    maxLength={1}
                    value={digit}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                  />
                ))}
              </div>
              {codeErr && otp.join('').length !== 6 && (
                <p className='text-danger'>Enter Verification Code</p>
              )}
            </div>
            <button
              className='btn btn-unstyled d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-5 d-flex justify-content-end align-self-end pe-10 login-text active cursor-pointer p-1'
              onClick={reqResendOTP}
            >
              <OverlayTrigger show={showPopover} placement='bottom' overlay={popover}>
                <p className='' data-toggle='tooltip' title='Resend OTP'>
                  Resend OTP
                </p>
              </OverlayTrigger>
            </button>

            <button type='submit' className='btn btn-primary mb-5'>
              Submit
            </button>
            <p className='text-danger'>{errorMsg}</p>
          </Form>
        </Formik>
      </motion.div>
    </AnimatePresence>
  )
}
