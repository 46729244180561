import {Logos} from './Logos'
const Preferences = ({data, isLoading, fetchData}: any) => {
  return (
    <>
      {isLoading ? (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : (
        <div className='card'>
          <div className='card-header'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-semibold fs-3 mb-1'>Preferences</span>
            </h3>
          </div>
          <div className='card-body'>
            <div className='row'>
              <Logos data={data} fetchData={fetchData} />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export {Preferences}
