import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import clsx from 'clsx'
import {FindPodIdByCode} from '../../FindPodIdByPodCode'
import usePostApi from '../../custom_hooks/usePostApi'
import LookupValueMain from './LookupValueMain'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import {EditLookup} from './EditLookup'
import {AddLookup} from './AddLookup'
function Lookups() {
  const podId = FindPodIdByCode()
  const [data, setTableData] = useState([])
  const {execute, loading} = usePostApi()
  const [id, updateId]: any = useState('')
  const [activeLookup, setActiveLookup] = useState({
    pod_lookup_name: 'create_new',
    pod_lookup_id: 0,
  })

  const fetchData = async () => {
    try {
      const url = `/pod/lookups/list`
      const formData = {pod_id: podId}
      const response = await execute(url, 'POST', formData)
      setTableData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLookupClick = (lookupName: any) => {
    setActiveLookup(lookupName)
  }

  const handleCreateLookupClick = () => {
    setActiveLookup({
      pod_lookup_name: 'create_new',
      pod_lookup_id: 0,
    })
  }

  const [popover, setPopover]: any = useState(null)
  const [popoverId, setPopoverId]: any = useState(null)

  const ClosePopover = () => {
    setPopover(null)
    setPopoverId(null)
  }

  const openPopover = (lookup: any) => (
    <Popover placement='top' className='min-w-400px'>
      <EditLookup props={{lookup, fetchData, ClosePopover}} />
    </Popover>
  )

  const CloseLookupPopover = () => {
    setPopover(null)
  }

  const openLookupPopover = (id: any) => (
    <Popover placement='auto' className='min-w-400px'>
      <AddLookup props={id} />
    </Popover>
  )
  const handlePopoverClick = (e: any, lookup: any) => {
    e.stopPropagation()
    if (popoverId === lookup.pod_lookup_id) {
      ClosePopover()
    } else {
      setPopover('add_lookup_value')
      setPopoverId(lookup.pod_lookup_id)
    }
  }

  return (
    <div className='row g-5 g-xxl-8'>
      <div className='col-xl-3'>
        <div className='app-navbar-item'>
          <div className={`card mb-4 p-5`}>
            <div className='menu-item'>
              <div
                className={clsx(
                  'menu-link without-sub btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-semibold',
                  {
                    active: activeLookup.pod_lookup_name === 'create_new',
                  }
                )}
                style={{padding: '10px', marginBottom: '0px !important'}}
                onClick={handleCreateLookupClick}
              >
                <span className='menu-title fw-semibold'>Create New Lookup</span>
                <OverlayTrigger
                  trigger='click'
                  placement='top'
                  overlay={openLookupPopover(id)}
                  show={popover === 'add_lookup'}
                >
                  <span
                    className='menu-icon'
                    onClick={(e) => {
                      e.stopPropagation()
                      setPopover('add_lookup')
                      updateId({lookupfetchData: fetchData, CloseLookupPopover})
                    }}
                    style={{cursor: 'default'}}
                  >
                    <KTIcon iconName='plus' className='fs-2' />
                  </span>
                </OverlayTrigger>
              </div>
            </div>
            {loading ? (
              <div className='mt-6 d-flex justify-content-center align-items-center'>
                <div className='spinner-border spinner-primary mr-15'></div>
              </div>
            ) : (
              data?.map((look: any, index) => (
                <div className='menu-item' key={index}>
                  <div
                    className={clsx(
                      `menu-link without-sub btn btn-sm btn-color-muted btn-active ${
                        look.is_active === 1
                          ? 'btn-active-light-primary'
                          : 'btn-active-light-danger'
                      }  fw-semibold`,
                      {
                        active: activeLookup.pod_lookup_name === look.pod_lookup_name,
                      }
                    )}
                    style={{padding: '10px', marginBottom: '0px !important'}}
                    onClick={() => handleLookupClick(look)}
                  >
                    <div className='menu-icon'>
                      <div className='form-check form-check-solid form-switch ps-0 pt-1'>
                        <input
                          className='form-check-input w-30px h-15px m-0'
                          type='checkbox'
                          name='status'
                          id='toggle'
                          checked={look.is_active === 1}
                          disabled
                          style={{
                            backgroundColor: look.is_active ? '#009688' : '#dc3545',
                          }}
                        />
                      </div>
                    </div>
                    <span
                      className={`menu-title fw-semibold ${
                        look.is_active === 1 ? '' : 'text-danger'
                      }`}
                    >
                      {look.pod_lookup_name}
                    </span>
                    <OverlayTrigger
                      trigger='click'
                      placement='top'
                      overlay={openPopover(look)}
                      show={popover === 'add_lookup_value' && popoverId === look.pod_lookup_id}
                    >
                      <span
                        className='menu-icon'
                        onClick={(e) => handlePopoverClick(e, look)}
                        style={{cursor: 'default'}}
                      >
                        <KTIcon iconName='pencil' className='fs-4' />
                      </span>
                    </OverlayTrigger>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      <div className={'col-xl-9'}>
        <LookupValueMain props={{activeLookup, fetchData}} />
      </div>
    </div>
  )
}

export default Lookups
