import {useMemo, useState, useEffect} from 'react'
import {Pagination} from 'react-bootstrap'
import {useIntl, FormattedMessage} from 'react-intl'
import {KTIcon} from '../../../../_metronic/helpers'
import {useParams, Link} from 'react-router-dom'
import {useTable, usePagination} from 'react-table'
import {UseToast} from '../../useToast'
import {GetAllVacancies} from './VacanciesAPI'
import CreateNewVacancyModal from './add_vacancy/CreateNewVacancyModal'
import VacanciesKnowMoreModal from './vacanciesknowmore/VacanciesKnowMoreModal'
import ScheduleVacancyInterview from './schedule_interview/ScheduleVacancyInterview'
import {FindPodIdByCode} from '../../FindPodIdByPodCode'
import usePostApi from '../../custom_hooks/usePostApi'
import VacanciesKnowMoreCards from './vacanciesknowmore/VacanciiesKnowMoreCards'
import usePageTitle from '../../custom_hooks/usePageTitle'
import EditVacancyModal from './edit_vacancy/EditVacancyModal'
const DataTable = ({columns, data, fetchData, loading, refresh}) => {
  const [showModal, setShowModal] = useState(false)
  const [showKM, setShowKM] = useState(false)
  const intl = useIntl()
  const handleOpenModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }
  usePageTitle('Vacancies')
  const [inputVal, setInputVal] = useState('')
  const [currentStatus, setCurrentStatus] = useState('Open')
  const filteredData = useMemo(() => {
    return data
      ? data.filter((each) => {
          const nameMatches =
            each.job_title_name &&
            each.job_title_name.toLowerCase().includes(inputVal.toLowerCase())
          const currentStatusMatches =
            each.vacancy_status && each.vacancy_status.toLowerCase() === currentStatus.toLowerCase()

          return nameMatches && currentStatusMatches
        })
      : []
  }, [inputVal, data, currentStatus])

  const tableInstance = useTable({columns, data: filteredData}, usePagination)
  const {
    getTableProps,
    getTableBodyProps,

    prepareRow,
    page,
    state: {pageIndex, pageSize},
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    gotoPage,
    setPageSize,
  } = tableInstance

  const pageOptions = [10, 20, 30, 40, 50]

  return (
    <>
      <VacanciesKnowMoreModal showModal={showKM} setShowModal={setShowKM} />
      <>
        {loading && refresh === false ? (
          <div className='card h-250px'>
            <div className='m-auto d-flex flex-column align-items-center'>
              <div className='spinner-border spinner-primary mr-15'></div>
            </div>
          </div>
        ) : data.length > 0 ? (
          <>
            <div className='d-flex flex-wrap flex-stack '>
              <div className='d-flex'>
                <h1 className='fw-semibold fs-3 mb-3'>Manage Vacancies</h1>
              </div>

              <div className='d-flex'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <button className='btn btn-sm btn-light' onClick={() => setShowKM(true)}>
                    <i className='ki-duotone ki-information-3 fs-3'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                    </i>
                    Know More
                  </button>
                </div>
              </div>
            </div>
            <p className='fw-normal fs-6 text-gray-600 mb-4'>
              Seamlessly create, update, and track vacancies, ensuring no details are overlooked
              during the hiring process.
            </p>
            {refresh ? (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <div className='spinner-border spinner-primary mr-15'></div>
                </div>
              </div>
            ) : (
              <>
                <div className='card py-2 px-10 mb-5'>
                  <div className='card-header border-0 px-0'>
                    <h3 className='card-title align-items-start flex-column'>
                      <ul className='nav'>
                        <li className='nav-item'>
                          <a
                            className='nav-link btn btn-sm btn-color-muted btn-active  btn-active-light-success active fw-normal px-4 me-1'
                            data-bs-toggle='tab'
                            onClick={() => setCurrentStatus('Open')}
                          >
                            Open
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a
                            className='nav-link btn btn-sm btn-color-muted btn-active  btn-active-light-danger fw-normal px-4 me-1'
                            data-bs-toggle='tab'
                            onClick={() => setCurrentStatus('Closed')}
                          >
                            Closed
                          </a>
                        </li>
                      </ul>
                    </h3>

                    <div className='d-flex'>
                      <div className='d-flex align-items-center position-relative me-4'>
                        <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                        <input
                          type='search'
                          id='kt_filter_search'
                          className='form-control form-control-white form-control-sm w-150px ps-9'
                          placeholder='Search'
                          value={inputVal}
                          onChange={(e) => setInputVal(e.target.value)}
                        />
                      </div>
                      <div
                        className='d-flex justify-content-center align-items-center me-2'
                        data-toggle='tooltip'
                      >
                        <button className='btn btn-sm btn-light-primary' onClick={handleOpenModal}>
                          <KTIcon iconName='plus' className='fs-3' />
                          Create Vacancy
                        </button>
                      </div>

                      <div
                        className='d-flex justify-content-center align-items-center'
                        data-toggle='tooltip'
                        title='Add New Pod'
                      >
                        <button
                          className='btn btn-sm btn-light-primary'
                          onClick={() => fetchData(true)}
                          data-bs-toggle='tooltip'
                          title='Click to load the latest data.'
                        >
                          <i className='ki-solid ki-arrows-circle fs-2'></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className='mb-0 mb-xl-8 '>
                    <div className='overflow-auto'>
                      <table
                        className='table table-row-dashed table-row-gray-300 align-middle'
                        {...getTableProps()}
                      >
                        <thead>
                          <tr className='fw-semibold text-muted bg-light'>
                            <th className='min-w-150px default-cursor text-start ps-3'>Position</th>
                            <th className='min-w-140px default-cursor'>Department</th>
                            <th className='min-w-140px default-cursor'>Vacancies</th>
                            <th className='min-w-100px text-start default-cursor'>
                              Hiring Manager
                            </th>
                            <th className='min-w-100px text-start default-cursor'>Address</th>

                            <th className='min-w-100px text-start default-cursor'>
                              Employment Type
                            </th>
                            {/* <th className={`min-w-140px text-start`}>Status</th> */}
                            <th className='min-w-100px text-start'>Actions</th>
                          </tr>
                        </thead>
                        <tbody {...getTableBodyProps()}>
                          {page.length > 0 ? (
                            page.map((row) => {
                              prepareRow(row)
                              return (
                                <tr {...row.getRowProps()}>
                                  {row.cells.map((cell) => (
                                    <td className='text-center' {...cell.getCellProps()}>
                                      {cell.render('Cell')}
                                    </td>
                                  ))}
                                </tr>
                              )
                            })
                          ) : (
                            <tr>
                              <td className='' colSpan={8}>
                                <div className='d-flex justify-content-center'>
                                  <p className='text-dark default-cursor fs-6 mt-5'>
                                    {inputVal === '' ? (
                                      intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
                                    ) : (
                                      <FormattedMessage
                                        id='MSG.NO_DATA_MATCH'
                                        values={{
                                          inputVal: (
                                            <span className='text-primary'>{inputVal}</span>
                                          ),
                                        }}
                                      />
                                    )}
                                  </p>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {data.length > 10 && (
                  <Pagination>
                    <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                    <Pagination.Prev onClick={previousPage} disabled={!canPreviousPage} />

                    {[...Array(pageCount)].map((_, i) => (
                      <Pagination.Item key={i} active={i === pageIndex} onClick={() => gotoPage(i)}>
                        {i + 1}
                      </Pagination.Item>
                    ))}

                    <Pagination.Next onClick={nextPage} disabled={!canNextPage} />
                    <Pagination.Last
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                    />

                    <div className='d-flex align-items-center mx-5'>
                      <span className='me-2'>Show</span>
                      <select
                        className='form-select form-select-solid fw-semibold w-75px me-2'
                        value={pageSize}
                        onChange={(e) => setPageSize(Number(e.target.value))}
                      >
                        {pageOptions.map((option) => (
                          <option key={option} value={option} className='select-bg'>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Pagination>
                )}
              </>
            )}
            <div className='my-5 ps-6 cursor-default d-flex flex-row align-items-center'>
              <h4 className='fs-7 fw-normal text-muted mt-1'>LEGEND:</h4>
              <div className='d-flex align-items-center mx-3'>
                <span className='me-2'>
                  <i className='ki-duotone ki-calendar-add fs-2'>
                    <span class='path1'></span>
                    <span class='path2'></span>
                    <span class='path3'></span>
                    <span class='path4'></span>
                    <span class='path5'></span>
                    <span class='path6'></span>
                  </i>
                </span>
                <span className='text-muted fs-7 mb-1'>Schedule Interview</span>
              </div>
              <span>|</span>

              <div className='d-flex align-items-center mx-3'>
                <span className='me-2'>
                  <KTIcon iconName='arrow-right' className='fs-2' />
                </span>
                <span className='text-muted fs-7 mb-1'>
                  Move to Different Section(Interviews and Offers)
                </span>
              </div>
            </div>
          </>
        ) : (
          <>
            <>
              <div className='d-flex flex-column'>
                <h3 className='fs-3 fw-semibold'>Manage Vacancies</h3>

                <p className=''>
                  Welcome to the Vacancies Page! Here, you have the tools to seamlessly create and
                  track vacancies , ensuring a smooth transition into your organization.
                </p>
                <VacanciesKnowMoreCards />

                <div
                  className='d-flex justify-content-center align-items-center'
                  data-toggle='tooltip'
                  title='Create New Vacancy'
                >
                  <button
                    className='btn btn-sm btn-light-primary'
                    id='side_activities_toggle'
                    onClick={handleOpenModal}
                  >
                    <KTIcon iconName='plus' className='fs-3' />
                    Create Vacancy
                  </button>
                </div>
              </div>
            </>
          </>
        )}
        <CreateNewVacancyModal show={showModal} onClose={handleCloseModal} fetchData={fetchData} />
      </>
    </>
  )
}

const VacanciesTable = () => {
  // const {vacanciesData, isvacanciesLoading: isLoading, fetchData} = GetAllVacancies()
  const {execute, loading: isLoading} = usePostApi()
  const [showModal, setShowModal] = useState(false)
  const podId = FindPodIdByCode()
  const [vacanciesData, setvacanciesData] = useState([])
  const [addModal, setAddModal] = useState(false)
  const [vacancyId, updateVacancyId] = useState('1')
  const [refresh, setRefresh] = useState(false)
  const [selectedData, setSelectedData] = useState(null)

  const getVacancies = async (show) => {
    if (show) {
      setRefresh(true)
    }
    try {
      const url = `/pod/vacancy/list`
      const formData = {
        pod_id: podId,
      }

      const response = await execute(url, 'POST', formData)
      setvacanciesData(response.data)

      setRefresh(false)
      if (show) {
        return UseToast(
          response.status_message,
          {
            position: 'bottom-right',
            theme: 'colored',
            autoClose: true,
          },
          'success'
        )
      }
    } catch (error) {
      console.error('Error:', error)
      setRefresh(false)
      if (show) {
        return UseToast(
          error.response?.data?.status_message,
          {
            position: 'bottom-right',
            theme: 'colored',
            autoClose: true,
          },
          'error'
        )
      }
    }
  }

  useEffect(() => {
    getVacancies()
  }, [])

  const userData = vacanciesData ? vacanciesData[0] : null

  const handleOpenModal = () => {
    setSelectedData(userData)

    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Position',
        accessor: 'Request Type',
        Cell: ({row}) => {
          return (
            <div className='text-start ms-3'>
              <p className='text-dark fw-semibold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.job_title_name}
              </p>
            </div>
          )
        },
      },

      {
        Header: 'Department Name',
        accessor: 'Department Name',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark  text-hover-primary d-block fs-6 default-cursor'>
                {row.original.department_name}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'No_of_Vacancies',
        accessor: 'No_of_Vacancies',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark  text-hover-primary d-block fs-6 default-cursor'>
                {row.original.number_of_vacancies}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Country Name',
        accessor: 'Country Name',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark  text-hover-primary d-block fs-6 default-cursor'>
                {row.original.hiring_manager_name}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'City Name',
        accessor: 'City Name',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark  text-hover-primary d-block fs-6 default-cursor'>
                {row.original.location_name} {','} {row.original.city}
                {row.original.city ? ',' : null} {row.original.state}
                {''}
                {row.original.state ? ',' : null}
                {row.original.country}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'RequireVisa',
        accessor: 'requireVisa',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark  text-hover-primary d-block fs-6 default-cursor'>
                {row.original.employment_type}
              </p>
            </div>
          )
        },
      },

      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({row}) => {
          const podId = FindPodIdByCode()
          const {id} = useParams()

          return (
            <div className='d-flex '>
              <div
                className='d-flex justify-content-center align-items-center'
                data-toggle='tooltip'
                title='Schedule Interview'
              >
                <button
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2 '
                  onClick={() => {
                    setAddModal(true)
                    updateVacancyId(row.original.vacancy_id)
                  }}
                >
                  <i className='ki-duotone ki-calendar-add fs-1'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                    <span className='path4'></span>
                    <span className='path5'></span>
                    <span className='path6'></span>
                  </i>
                </button>
              </div>

              <Link
                to={`/${id}/vacancies/basic-info/${row.original.vacancy_id}`}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2 '
              >
                <KTIcon iconName='arrow-right' className='fs-1' />
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <>
      <DataTable
        columns={columns}
        data={vacanciesData ? vacanciesData : []}
        loading={isLoading}
        fetchData={getVacancies}
        refresh={refresh}
      />
      <ScheduleVacancyInterview
        showModal={addModal}
        setShowModal={setAddModal}
        vacancyId={vacancyId}
      />

      <EditVacancyModal
        show={showModal}
        onClose={handleCloseModal}
        vacancyId={vacancyId}
        getVacancies={getVacancies}
        data={selectedData}
      />
    </>
  )
}

export default VacanciesTable
