import {useEffect, useContext} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../../../../_metronic/helpers'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import CandidateInfoContext from '../../../CandidateInfoContext'
import _ from 'lodash'

interface Props {
  showModal: boolean
  setShowModal(val: boolean): void
  addFamilyMember: any
  editFamilyMember: any
  type: string
  index: null | number
  initialVals: any
}

function AddFamilyMember(props: Props) {
  const {setCorrections, corrections, formValues, updateDataOnDB, setFormData} =
    useContext(CandidateInfoContext)

  const formik = useFormik({
    initialValues: {
      id: '',
      name: '',
      relationship: '',
      age: '',
      occupation: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      relationship: Yup.string().required('Required'),
      age: Yup.number().required('Required'),
      occupation: Yup.string().required('Required'),
    }),
    onSubmit: async (values, {resetForm}) => {
      if (props.type === 'edit') {
        const originalVal = formValues._family_employer_reference.family_members?.filter(
          (each: any) => each.id === formik.values.id
        )
        delete originalVal[0].status

        const currentVal: any = values
        delete currentVal['status']

        if (!_.isEqual(originalVal[0], values)) {
          const updatedCorrections = {
            ...corrections,
            _family_employer_reference: {
              ...corrections._family_employer_reference,
              family_members: {
                ...corrections._family_employer_reference.family_members,
                [values.id]: {...values, status: 'edited'},
              },
            },
          }

          setCorrections(updatedCorrections)
          await updateDataOnDB({...formValues, corrections: updatedCorrections})
        } else {
          setCorrections((prevCorrections: any) => {
            const newCorrections = {...prevCorrections}
            const familyMem = newCorrections._family_employer_reference.family_members
            const newFamilyMems = {...familyMem}

            delete newFamilyMems[values.id]

            newCorrections._family_employer_reference.family_members = newFamilyMems
            return newCorrections
          })
          await updateDataOnDB({...formValues, corrections: corrections})
        }
      } else if (props.type === 'editNewFamMem') {
        props.editFamilyMember(values)
      } else {
        const iniqueId = '_' + Math.random().toString(36).substr(2, 9)
        const updatedCorrections = {
          ...corrections,
          _family_employer_reference: {
            ...corrections._family_employer_reference,
            family_members: {
              ...corrections._family_employer_reference.family_members,
              [iniqueId]: {
                ...values,
                id: iniqueId,
                status: 'New',
              },
            },
          },
        }
        await updateDataOnDB({...formValues, corrections: updatedCorrections})
      }
      resetForm()
      props.setShowModal(false)
      //   props.addFamilyMember(
      //     {...values, id: '_' + Math.random().toString(36).substr(2, 9)},
      //     'family_members'
      //   )
      //   resetForm()
      //   props.setShowModal(false)
    },
  })

  useEffect(() => {
    if ((props.type === 'edit' || props.type === 'editNewFamMem') && props.initialVals) {
      formik.setValues(props.initialVals)
    } else {
      const initialValues = {
        id: '',
        name: '',
        relationship: '',
        age: '',
        occupation: '',
      }
      formik.setValues(initialValues)
    }
  }, [props.type, props.initialVals])

  return (
    <Modal
      show={props.showModal}
      onHide={() => props.setShowModal(false)}
      centered
      dialogClassName='custom-modal modal-dialog-centered'
      contentClassName='custom-modal-content'
      backdrop='static'
    >
      <Modal.Body className='overflow-auto w-100'>
        <div className='d-flex justify-content-between mb-10'>
          <h3 className='fs-3 fw-semibold'>
            {props.type === 'edit' ? 'Edit Family Member' : 'Add Family Member'}
          </h3>
          <button
            type='button'
            className='d-block btn btn-sm btn-icon btn-light'
            onClick={() => props.setShowModal(false)}
          >
            <KTIcon iconName='cross' className='fs-1' />
          </button>
        </div>

        <form onSubmit={formik.handleSubmit}>
          {/* <div className='d-flex'> */}
          <div className=''>
            <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
              <span className='required'>Name</span>
            </label>
            <div className='mb-4'>
              <input
                id='name'
                type='text'
                name='name'
                className='form-control form-control-solid'
                placeholder='Enter name of the family member'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className='text-danger'>{formik.errors.name}</div>
              ) : null}
            </div>
          </div>

          <div className=''>
            <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
              <span className='required'>Select Relationship</span>
            </label>
            <div className='mb-4'>
              <select
                className='form-select form-select-solid'
                name='relationship'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.relationship}
              >
                <option value=''>Select option</option>
                {[
                  'Father',
                  'Mother',
                  'Guardian',
                  'Brother',
                  'Sister',
                  'Wife',
                  'Husband',
                  'Son',
                  'Daughter',
                  'Mother-in-Law',
                  'Father-in-Law',
                  'Grandmother',
                  'Grandfather',
                ].map((each, index) => (
                  <option key={index} value={each}>
                    {each}
                  </option>
                ))}
              </select>
              {formik.touched.relationship && formik.errors.relationship ? (
                <div className='text-danger'>{formik.errors.relationship}</div>
              ) : null}
            </div>
          </div>

          <div className='d-flex flex-column flex-md-row justify-content-between'>
            <div className='w-100 w-md-50 me-md-2'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Age</span>
              </label>
              <div className='mb-4'>
                <input
                  id='age'
                  type='number'
                  name='age'
                  className='form-control form-control-solid'
                  placeholder='Enter age of the family member'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.age}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault()
                    }
                  }}
                />

                {formik.touched.age && formik.errors.age ? (
                  <div className='text-danger'>{formik.errors.age}</div>
                ) : null}
              </div>
            </div>
            <div className='w-100 w-md-50 ms-md-2'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Occupation</span>
              </label>
              <div className='mb-4'>
                <select
                  className='form-select form-select-solid'
                  name='occupation'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.occupation}
                >
                  <option value=''>Select option</option>
                  {[
                    'Self-Employed',
                    'Student',
                    'Retired',
                    'Business',
                    'Home-maker',
                    'Employed',
                    'Unemployed',
                    'Expired/Deceased',
                  ].map((each, index) => (
                    <option key={index} value={each}>
                      {each}
                    </option>
                  ))}
                </select>
                {formik.touched.occupation && formik.errors.occupation ? (
                  <div className='text-danger'>{formik.errors.occupation}</div>
                ) : null}
              </div>
            </div>
          </div>

          <div className='w-100 d-flex justify-content-end mt-5'>
            <button
              type='button'
              className='btn btn-light btn-sm me-2'
              onClick={() => props.setShowModal(false)}
            >
              Close
            </button>

            <button type='submit' className='btn btn-sm btn-light-primary ms-2'>
              <KTIcon iconName='check' className='fs-3 ms-1' />
              {props.type === 'edit' ? 'Update' : 'Add'}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default AddFamilyMember
