import {useEffect, useContext, useRef} from 'react'
// import {RenderFields} from './RenderFields'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {UseToast} from '../../../useToast'
import {KTIcon} from '../../../../../_metronic/helpers'
import {useThemeMode} from '../../../../../_metronic/partials'
import MapComponent from './Map'
import GetLookupDD from './GetLookupDD'
import {useAuth} from '../../../auth'
import CandidateInfoContext from '../CandidateInfoContext'
import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2'

const transformNestedObject1 = (nestedObject: any) => {
  const result: any = {}
  try {
    Object.keys(nestedObject).forEach((key) => {
      result[key] = nestedObject[key].value
    })
    return result
  } catch {
    return {}
  }
}

const dropdownDataSets: any = {
  gender: ['Male', 'Female', 'Non-binary', 'Prefer not to say'],
  blood_group: ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-', 'Do Not Know'],
  marital_status: [
    'Single',
    'Married',
    'Divorced',
    'Widowed',
    'Living Together',
    'Civil Partnership',
    'Prefer not to say',
  ],
  relation: [
    'Father',
    'Mother',
    'Guardian',
    'Brother',
    'Sister',
    'Wife',
    'Husband',
    'Son',
    'Daughter',
    'Mother-in-Law',
    'Father-in-Law',
    'Grandmother',
    'Grandfather',
  ],
  default: [],
}

function AddressContact({
  dummyData,
  updateFormValues,
  postLoading,
  same_as_current_add = false,
  formValues: fmVals,
  updateSameAsCurrentAdd,
  edit: editMode,
  closeEditSec,
  candidateData,
}: any) {
  const {currentUser} = useAuth()
  const {mode} = useThemeMode()
  const {
    setCorrections: contextSetCorrections,
    corrections: contextCorrections,
    deleteCorrections,
    updateDataOnDB,
  } = useContext(CandidateInfoContext)
  const corrections = contextCorrections['_address_contacts']
  const formValues1 = transformNestedObject1(fmVals['_address_contacts'])
  const formValues = transformNestedObject1(fmVals['_address_contacts'])
  const initialValuesRef = useRef(fmVals['_address_contacts'])

  useEffect(() => {
    // Update the ref when initialValues change
    initialValuesRef.current = fmVals['_address_contacts']
  }, [fmVals['_address_contacts']])

  const fields = [...(dummyData?.items || []), ...(dummyData?.address_fields || [])]

  const initialValues = formValues || {}
  fields?.forEach((item: any) => {
    const itemId = item.id
    if (!initialValues[item.id]) {
      initialValues[item.id] =
        item.field_type === null ? false : item.field_type === 'Separator' ? '_sep_a_ra_tor' : ''
    }
    if (item.require_confirmation && !initialValues[`${item.id}_confirmation`]) {
      initialValues[`${item.id}_confirmation`] = ''
    }

    if (corrections[itemId]) {
      initialValues[itemId] = corrections[itemId].value
    }
  })

  const validationSchema = Yup.object().shape(
    fields.reduce((schema, item) => {
      if (item.is_required) {
        switch (item.field_type) {
          case 'Single Line': {
            let validator = Yup.string()
              .min(
                item?.validations?.min || 0,
                `Minimum ${item?.validations?.min} characters required`
              )
              .max(
                item?.validations?.max || 250,
                `Maximum ${item?.validations?.max} characters allowed`
              )
              .required(`${item?._field_name ?? item.field_name} is required`)

            // Type assertion to check for optional regex property
            if ((item.validations as {regex?: string}).regex) {
              validator = validator.matches(
                new RegExp((item.validations as {regex?: string}).regex!),
                'Invalid format'
              )
            }

            schema[item.id] = validator
            break
          }
          case 'Multi Line': {
            let validator = Yup.string()
              .min(
                item?.validations?.min || 0,
                `Minimum ${item?.validations?.min} characters required`
              )
              .max(
                item?.validations?.max || 1000, // Adjust the max length as needed
                `Maximum ${item?.validations?.max} characters allowed`
              )
              .required(`${item.field_name} is required`)

            if (item.validations?.regex) {
              validator = validator.matches(new RegExp(item.validations.regex), 'Invalid format')
            }

            schema[item.id] = validator
            break
          }
          case 'Numeric': {
            let validator = Yup.number()
              .typeError(`${item.field_name} must be a number`)
              .required(`${item.field_name} is required`)

            if (item.validations?.min !== undefined && item.validations?.min !== null) {
              validator = validator.min(
                item.validations.min,
                `${item.field_name} must be at least ${item.validations.min}`
              )
            }

            if (item.validations?.max !== undefined && item.validations?.max !== null) {
              validator = validator.max(
                item.validations.max,
                `${item.field_name} must be at most ${item.validations.max}`
              )
            }

            schema[item.id] = validator
            break
          }
          case 'Date Time':
            schema[item.id] = Yup.date().required(
              `${item?._field_name ?? item.field_name} is required`
            )
            break
          case 'Dropdown':
            schema[item.id] = Yup.string().required(
              `${item?._field_name ?? item.field_name} is required`
            )
            break
          case 'PhoneNo':
            schema[item.id] = Yup.string()
              // .matches(/^\[1-9]\d{1,14}$/, 'Phone number is not valid') // International format
              .required('Phone number is required')
            break
          case 'Toggle':
            schema[item.id] = Yup.string()
              .oneOf(['Yes', 'No'])
              .required(`${item.field_name} is required`)
            break
          case 'Media':
            schema[item.id] = Yup.mixed().required(
              `${item?._field_name ?? item.field_name} is required`
            )
            break
          case 'Map':
            schema[item.id] = Yup.string().required(
              `${item?._field_name ?? item.field_name} is required`
            )
            break
          default:
            break
        }
      }
      return schema
    }, {})
  )

  useEffect(() => {
    document.documentElement.classList.toggle('dark-mode', mode === 'dark')
    document.documentElement.classList.toggle('light-mode', mode === 'light')
    return () => {
      document.documentElement.classList.remove('dark-mode')
      document.documentElement.classList.remove('light-mode')
    }
  }, [mode])

  const phoneRegexes: any = {
    AM: /^(\+?374|0)((10|[9|7][0-9])\d{6}$|[2-4]\d{7}$)/,
    AE: /^((\+?971)|0)?5[024568]\d{7}$/,
    BH: /^(\+?973)?(3|6)\d{7}$/,
    DZ: /^(\+?213|0)(5|6|7)\d{8}$/,
    EG: /^((\+?20)|0)?1[0125]\d{8}$/,
    IQ: /^(\+?964|0)?7[0-9]\d{8}$/,
    JO: /^(\+?962|0)?7[789]\d{7}$/,
    KW: /^(\+?965)[569]\d{7}$/,
    SA: /^(!?(\+?966)|0)?5\d{8}$/,
    SY: /^(!?(\+?963)|0)?9\d{8}$/,
    TN: /^(\+?216)?[2459]\d{7}$/,
    BY: /^(\+?375)?(24|25|29|33|44)\d{7}$/,
    BG: /^(\+?359|0)?8[789]\d{7}$/,
    BD: /^(\+?880|0)1[13456789][0-9]{8}$/,
    CZ: /^(\+?420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
    DK: /^(\+?45)?\s?\d{2}\s?\d{2}\s?\d{2}\s?\d{2}$/,
    DE: /^(\+49)?0?1(5[0-25-9]\d|6([23]|0\d?)|7([0-57-9]|6\d))\d{7}$/,
    AT: /^(\+43|0)\d{1,4}\d{3,12}$/,
    GR: /^(\+?30|0)?(69\d{8})$/,
    AU: /^(\+?61|0)4\d{8}$/,
    GB: /^(\+?44|0)7\d{9}$/,
    GG: /^(\+?44|0)1481\d{6}$/,
    GH: /^(\+233|0)(20|50|24|54|27|57|26|56|23|28)\d{7}$/,
    HK: /^(\+?852[-\s]?)?[456789]\d{3}[-\s]?\d{4}$/,
    MO: /^(\+?853[-\s]?)?[6]\d{3}[-\s]?\d{4}$/,
    IE: /^(\+?353|0)8[356789]\d{7}$/,
    IN: /^(\+?91|0)?[6789]\d{9}$/,
    KE: /^(\+?254|0)(7|1)\d{8}$/,
    MT: /^(\+?356|0)?(99|79|77|21|27|22|25)[0-9]{6}$/,
    MU: /^(\+?230|0)?\d{8}$/,
    NG: /^(\+?234|0)?[789]\d{9}$/,
    NZ: /^(\+?64|0)[28]\d{7,9}$/,
    PK: /^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/,
    RW: /^(\+?250|0)?[7]\d{8}$/,
    SG: /^(\+65)?[89]\d{7}$/,
    SL: /^(?:0|94|\+94)?(7(0|1|2|5|6|7|8)( |-)?\d)\d{6}$/,
    TZ: /^(\+?255|0)?[67]\d{8}$/,
    UG: /^(\+?256|0)?[7]\d{8}$/,
    US: /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/,
    ZA: /^(\+?27|0)\d{9}$/,
    ZM: /^(\+?26)?09[567]\d{7}$/,
    CL: /^(\+?56|0)[2-9]\d{1}\d{7}$/,
    EC: /^(\+?593|0)([2-7]|9[2-9])\d{7}$/,
    ES: /^(\+?34)?(6\d{1}|7[1234])\d{7}$/,
    MX: /^(\+?52)?(1|01)?\d{10,11}$/,
    PA: /^(\+?507)\d{7,8}$/,
    PY: /^(\+?595|0)9[9876]\d{7}$/,
    UY: /^(\+598|0)9[1-9][\d]{6}$/,
    EE: /^(\+?372)?\s?(5|8[1-4])\s?([0-9]\s?){6,7}$/,
    IR: /^(\+?98[\-\s]?|0)9[0-39]\d[\-\s]?\d{3}[\-\s]?\d{4}$/,
    FI: /^(\+?358|0)\s?(4(0|1|2|4|5|6)?|50)\s?(\d\s?){4,8}\d$/,
    FJ: /^(\+?679)?\s?\d{3}\s?\d{4}$/,
    FO: /^(\+?298)?\s?\d{2}\s?\d{2}\s?\d{2}$/,
    FR: /^(\+?33|0)[67]\d{8}$/,
    GF: /^(\+?594|0|00594)[67]\d{8}$/,
    GP: /^(\+?590|0|00590)[67]\d{8}$/,
    MQ: /^(\+?596|0|00596)[67]\d{8}$/,
    RE: /^(\+?262|0|00262)[67]\d{8}$/,
    IL: /^(\+972|0)[ ]?([23489]|5[012345689]|77)[ ]?[1-9]\d{6}$/,
    HU: /^(\+?36)(20|30|70)\d{7}$/,
    ID: /^(\+?62|0)8(1[123456789]|2[1238]|3[1238]|5[12356789]|7[78]|9[56789]|8[123456789])([\s?|\d]{5,11})$/,
    IT: /^(\+?39)?\s?3\d{2} ?\d{6,7}$/,
    JP: /^(\+81[ \-]?(\(0\))?|0)[6789]0[ \-]?\d{4}[ \-]?\d{4}$/,
    KZ: /^(\+?7|8)?7\d{9}$/,
    GL: /^(\+?299)?\s?\d{2}\s?\d{2}\s?\d{2}$/,
    KR: /^((\+?82)[ \-]?)?0?1([0|1|6|7|8|9]{1})[ \-]?\d{3,4}[ \-]?\d{4}$/,
    LT: /^(\+370|8)\d{8}$/,
    MY: /^(\+?6?01){1}(([0145]{1}(\-|\s)?\d{7,8})|([236789]{1}(\s|\-)?\d{7}))$/,
    NO: /^(\+?47)?[49]\d{7}$/,
    NP: /^(\+?977)?9[78]\d{8}$/,
    BE: /^(\+?32|0)4?\d{8}$/,
    NL: /^(\+?31|0)6?\d{8}$/,
    RS: /^(\+3816|06)[- \d]{5,9}$/,
    SE: /^(\+?46|0)[\s\-]?7[\s\-]?[02369]([\s\-]?\d){7}$/,
    TH: /^(\+66|66|0)\d{9}$/,
    TR: /^(\+?90|0)?5\d{9}$/,
    VN: /^(\+?84|0)(3|5|7|8|9)(\d{8})$/,
    UA: /^(\+380|0)\d{9}$/,
    VE: /^(\+?58)?(2|4)\d{9}$/,
    XK: /^(\+383|0)?(4)\d{7,8}$/,
    PL: /^(\+?48)? ?[5-8]\d ?\d{3} ?\d{3}$/,
    RU: /^(\+?7|8)?9\d{9}$/,
    QA: /^(\+974|0)?[3-9]\d{7}$/,
  }

  function formatPhoneNumber(value: any, dialCode: any, countryCode: string) {
    if (!value) {
      return {formattedNumber: '', isValid: false}
    }

    const numericValue = value.replace(/\D/g, '')
    const formattedNumber = numericValue.substring(dialCode.length)
    // const numericValue = value.replace(/\D/g, '')
    // const formattedNumber = numericValue.substring(dialCode.length)

    const formattedCountryCode = countryCode.toUpperCase()
    const regex = phoneRegexes[formattedCountryCode]

    if (!regex) {
      console.error(`Regex for country code ${countryCode} not found`)
      return {formattedNumber, isValid: true}
    }

    const isValid = regex.test(value)
    console.log(isValid)

    return {formattedNumber, isValid}
  }

  const RenderFields = (
    setFieldValue: any,
    values: any,
    errors: any,
    touched: any,
    setFieldError: any
  ) => {
    return fields.map((item: any) => {
      // const isValid = touched[item.field_name] && !errors[item.field_name] && item.is_required
      const isValid = (fieldName: string) => {
        return touched[fieldName] && !errors[fieldName] && item.is_required
      }

      const isInValid = (fieldName: string) => {
        return touched[fieldName] && errors[fieldName] && item.is_required
      }

      const isValid1 = touched[item.id] && !errors[item.id] && item.is_required
      const isInvalid = touched[item.id] && errors[item.id] && item.is_required

      // if (same_as_current_add && ids.includes(item.field_name)) {
      //   return
      // }

      if ('is_show' in item && !item.is_show) {
        return null
      }

      switch (item.field_type) {
        case 'Separator':
          return (
            <div key={item.id} className='w-100'>
              {dummyData.id === '_address_contacts' && item.id === '_item3015' ? (
                <>
                  <h3 className='w-100 mb-3'>
                    <p
                      className='card p-5 alertbg-light-primary text-center text-muted'
                      style={{
                        width: '100%',
                        verticalAlign: 'middle',
                        fontSize: '12px',
                        textTransform: 'uppercase',
                      }}
                    >
                      <span className='fw-semibold'>{item.field_name}</span>
                    </p>
                  </h3>

                  <div className='w-100 bg-light-primary p-5 pb-2 d-flex justify-content-between align-items-center border border-muted mt-5 rounded mb-4'>
                    <div className='d-flex'>
                      <KTIcon iconName='information-2' className='text-primary fs-1 me-2 mb-3' />
                      <p className='fs-6'>Is your Current Address same as Permanent Address</p>
                    </div>
                    <div>
                      <div className='form-floating flex-fill'>
                        <div className='form-check form-check-solid form-switch mb-0'>
                          <input
                            className='form-check-input w-40px h-20px'
                            type='checkbox'
                            checked={same_as_current_add}
                            onChange={(event) => {
                              // event.preventDefault()
                              if (
                                !same_as_current_add &&
                                (!values.curr_pincode ||
                                  !values.curr_country ||
                                  !values.curr_state ||
                                  !values.curr_address_line_1 ||
                                  !values.curr_address_line_2 ||
                                  !values.curr_city ||
                                  !values['curr_address'])
                              ) {
                                return UseToast(
                                  'Please fill all the mandatory fields in current address section.',
                                  {
                                    position: 'bottom-right',
                                    theme: 'colored',
                                    autoClose: true,
                                  },
                                  'error'
                                )
                              } else {
                                updateSameAsCurrentAdd('same_as_current_add', !same_as_current_add)
                                setFieldValue('per_country', values.curr_country)
                                setFieldValue('per_state', values.curr_state)
                                setFieldValue('per_address_line_1', values.curr_address_line_1)
                                setFieldValue('per_address_line_2', values.curr_address_line_2)
                                setFieldValue('per_city', values.curr_city)
                                setFieldValue('per_pincode', values.curr_pincode)
                                setFieldValue('per_address', values['curr_address'])
                              }
                            }}
                            style={{
                              backgroundColor: same_as_current_add
                                ? '#506bec'
                                : mode === 'dark'
                                ? '#1b1b29'
                                : '#757575',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {same_as_current_add && (
                    <div className='card border border-2 bg-light-info min-w-100 d-flex flex-wrap m-auto p-5 pb-0'>
                      <div className='row w-100 g-1'>
                        <div className='col-lg-12 mb-5'>
                          <div className='row align-items-start'>
                            <label className='col-lg-2 fw-semibold text-muted'>Address</label>
                            <div className='col-lg-8'>
                              <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                {decodeFromBase64(values['per_address'] || '')?.place_name}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-6 mb-5'>
                          <div className='row align-items-start'>
                            <label className='col-lg-4 fw-semibold text-muted'>
                              Address Line 1
                            </label>
                            <div className='col-lg-8'>
                              <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                {values['per_address_line_1']}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-6 mb-5'>
                          <div className='row align-items-start'>
                            <label className='col-lg-4 fw-semibold text-muted'>
                              Address Line 2
                            </label>
                            <div className='col-lg-8'>
                              <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                {values['per_address_line_2']}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-6 mb-5'>
                          <div className='row align-items-start'>
                            <label className='col-lg-4 fw-semibold text-muted'>City</label>
                            <div className='col-lg-8'>
                              <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                {values['per_city']}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-6 mb-5'>
                          <div className='row align-items-start'>
                            <label className='col-lg-4 fw-semibold text-muted'>State/Region</label>
                            <div className='col-lg-8'>
                              <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                {values['per_state']}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-6 mb-5'>
                          <div className='row align-items-start'>
                            <label className='col-lg-4 fw-semibold text-muted'>Country</label>
                            <div className='col-lg-8'>
                              <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                {values['per_country']}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-6 mb-5'>
                          <div className='row align-items-start'>
                            <label className='col-lg-4 fw-semibold text-muted'>Zip/Pincode</label>
                            <div className='col-lg-8'>
                              <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                {values['per_pincode']}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <h3 key={item.id} className='w-100 mb-3'>
                  <p
                    className='card p-5 alertbg-light-primary text-center text-muted'
                    style={{
                      width: '100%',
                      verticalAlign: 'middle',
                      fontSize: '12px',
                      textTransform: 'uppercase',
                    }}
                  >
                    <span className='fw-semibold'>{item.field_name}</span>
                  </p>
                </h3>
              )}
            </div>
          )

        case 'Single Line':
          return (
            <div
              key={item.id}
              id={item.id}
              className='w-100 w-md-50 d-flex flex-column mb-4 fv-row px-2'
            >
              <label
                htmlFor={item.id}
                className={`fs-6 fw-semibold form-label ${item.is_required ? 'required' : ''}`}
              >
                <span className=''>{item?._field_name || item.field_name}</span>
              </label>
              <Field
                type='text'
                className={`form-control ${isValid1 ? 'is-valid' : isInvalid ? 'is-invalid' : ''}`}
                id={item.id}
                name={item.id}
                placeholder={item.prompt}
              />
              <div className='text-danger'>
                <ErrorMessage name={item.id} />
              </div>
            </div>
          )
        case 'Multi Line':
          return (
            <div
              key={item.id}
              id={item.id}
              className='w-100 w-md-50 d-flex flex-column mb-4 fv-row px-2'
            >
              <label
                htmlFor={item.id}
                className={`fs-6 fw-semibold form-label ${item.is_required ? 'required' : ''}`}
              >
                <span className=''>{item.field_name}</span>
              </label>
              <Field
                as='textarea'
                className={`form-control ${isValid1 ? 'is-valid' : isInvalid ? 'is-invalid' : ''}`}
                id={item.id}
                name={item.id}
                placeholder={item.prompt}
                row={3}
              />
              <div className='text-danger'>
                <ErrorMessage name={item.id} />
              </div>
            </div>
          )
        case 'Numeric':
          return (
            <div key={item.id} className='w-100 w-md-50 d-flex flex-column mb-4 fv-row px-2'>
              <label
                htmlFor={item.id}
                className={`fs-6 fw-semibold form-label ${item.is_required ? 'required' : ''}`}
              >
                <span className=''>{item.field_name}</span>
              </label>
              <Field
                type='number'
                className={`form-control ${isValid1 ? 'is-valid' : isInvalid ? 'is-invalid' : ''}`}
                id={item.id}
                name={item.id}
                placeholder={item.prompt}
                onKeyDown={(e: any) => {
                  if (e.key === 'e' || e.key === 'E') {
                    e.preventDefault()
                  }
                }}
              />
              <div className='text-danger'>
                <ErrorMessage name={item.id} />
              </div>
            </div>
          )
        case 'Date Time':
          return (
            <div key={item.id} id={item.id} className='w-100 w-md-50 mb-4 px-2'>
              <label
                htmlFor={item.id}
                className={`fs-6 fw-semibold form-label ${item.is_required ? 'required' : ''}`}
              >
                {item.prompt}
              </label>
              <Field
                type='date'
                id={item.id}
                name={item.id}
                className={`form-control ${isValid1 ? 'is-valid' : isInvalid ? 'is-invalid' : ''}`}
              />

              <div className='text-danger'>
                <ErrorMessage name={item.id} />
              </div>
            </div>
          )
        case 'PhoneNo':
          return (
            <div key={item.id} className='w-100 w-md-50 mb-4 px-2'>
              <label
                htmlFor={item.id}
                className={`fs-6 fw-semibold form-label ${item.is_required ? 'required' : ''}`}
              >
                {item.prompt}
              </label>
              <div
                className={`phone-input-container ${
                  isValid1 ? 'is-valid' : isInvalid ? 'is-invalid' : ''
                }`}
                style={{
                  border: isValid1 ? '1px solid #50cd89' : isInvalid ? '1px solid #ff4d4f' : '',
                  borderRadius: '8px',
                }}
              >
                <PhoneInput
                  country={'in'}
                  value={values[item.id]}
                  onChange={(value, country: any, event) => {
                    let sanitizedNumber = event.target.value
                    if (sanitizedNumber) {
                      sanitizedNumber = sanitizedNumber.replace(/[^+\d]/g, '')
                    }

                    const isValid = /^(\+972|0)([23489]|5[012345689]|77)[1-9]\d{6}$/.test(
                      sanitizedNumber
                    )
                    console.log('first', isValid, sanitizedNumber)

                    const {formattedNumber, isValid: test} = formatPhoneNumber(
                      sanitizedNumber,
                      country.dialCode,
                      country.countryCode
                    )
                    console.log(country)
                    // console.log(event.target.value, country, formattedNumber)
                    // if (!test) {
                    //   console.log(test)
                    //   setFieldError(item.id, 'Phone number is not valid')
                    // }
                    if (test) {
                      setFieldValue(item.id, value)
                    } else {
                      setFieldValue(item.id, '')
                      setFieldError(item.id, 'Phone number is not valid')
                    }
                  }}
                  enableSearch={true}
                  containerClass='phone-input'
                />
                {/* <PhoneInput
                  country={'in'}
                  value={values[item.id]}
                  onChange={(value, country: any, event) => {
                    const formattedNum = formatPhoneNumber(
                      event.target.value,
                      country.dialCode,
                      country.countryCode
                    )
                    console.log(event.target.value, country, formattedNum)
                    setFieldValue(item.id, value)
                  }}
                  // onChange={(value) => setFieldValue(item.id, value)}
                  enableSearch={true}
                  containerClass='phone-input'
                /> */}

                <div className={`icon ${isValid1 ? 'valid' : isInvalid ? 'invalid' : ''}`}>
                  {isValid1 ? (
                    <i className='bi bi-check' style={{color: '#50cd89', fontSize: '2.5rem'}}></i>
                  ) : isInvalid ? (
                    <i
                      className='bi bi-exclamation-circle'
                      style={{color: '#f1416c', fontSize: '1.4rem'}}
                    ></i>
                  ) : null}
                </div>
              </div>
              <div className='text-danger'>
                <ErrorMessage name={item.id} />
              </div>
            </div>
          )
        case 'Dropdown':
          return (
            <GetLookupDD
              id={item?.validations?.dropdown_type}
              item={item}
              isInvalid={isInvalid}
              isValid={isValid1}
              values={values}
              setFieldValue={setFieldValue}
              key={item.id}
            />
          )
        case 'Toggle':
          return (
            <div key={item.id} className='w-100 w-md-50 mb-4 px-2'>
              <label
                className={`fs-6 fw-semibold form-label ${item.is_required ? 'required' : ''}`}
              >
                <span className=''>{item.prompt}</span>
              </label>
              <div className='d-flex form-floating flex-fill'>
                <div className='form-check form-check-solid mb-0'>
                  <label className='form-check-label'>
                    <Field
                      className='form-check-input'
                      type='radio'
                      name={item.id}
                      value='Yes'
                      checked={values[item.id] === 'Yes'}
                    />
                    <span
                      className={`me-4 fw-semibold ${
                        values[item.id] === 'Yes' ? 'text-success' : ''
                      }`}
                    >
                      {item.validations.label_1}
                    </span>
                  </label>
                </div>
                <div className='form-check form-check-solid mb-0'>
                  <label className='form-check-label'>
                    <Field
                      className='form-check-input'
                      type='radio'
                      name={item.id}
                      value='No'
                      checked={values[item.id] === 'No'}
                    />
                    <span
                      className={`me-4 fw-semibold ${
                        values[item.id] === 'No' ? 'text-danger' : ''
                      }`}
                    >
                      {item.validations.label_2}
                    </span>
                  </label>
                </div>
              </div>
              <div className='text-danger'>
                <ErrorMessage name={item.id} />
              </div>
            </div>
          )
        case 'Media':
          return (
            <div key={item.id} id={item.id} className='w-100 w-md-50 mb-4 px-2'>
              <label
                htmlFor={item.id}
                className={`fs-6 fw-semibold form-label ${item.is_required ? 'required' : ''}`}
              >
                {item.prompt}
              </label>
              <input
                id={item.id}
                name={item.id}
                type='file'
                className={`form-control ${isValid1 ? 'is-valid' : isInvalid ? 'is-invalid' : ''}`}
                onChange={(event) => {
                  const files = event.currentTarget.files
                  if (files && files.length > 0) {
                    const file = files[0]
                    setFieldValue(item.id, file)
                  }
                }}
              />

              <div className='text-danger'>
                <ErrorMessage name={item.id} />
              </div>
            </div>
          )
        case 'Map':
          return same_as_current_add && item.id !== 'curr_address' ? null : (
            <div key={item.id} className='w-100'>
              <div id={item.id} className='w-100 mb-4 px-2'>
                <label
                  htmlFor={item.id}
                  className={`fs-6 fw-semibold form-label ${item.is_required ? 'required' : ''}`}
                >
                  {item.prompt}
                </label>
                <MapComponent
                  setFieldValue={setFieldValue}
                  id={item.id}
                  isValid={isValid}
                  isInValid={isInValid}
                  type={item.id}
                  value={values[item.id]}
                  sameAsCurrentAdd={same_as_current_add}
                />
                <div className='text-danger'>
                  <ErrorMessage name={item.id} />
                </div>
              </div>

              <div className='w-100 d-flex flex-column mb-4 fv-row px-2'>
                <label
                  htmlFor={
                    item.id === 'curr_address' ? `curr_address_line_1` : 'per_address_line_1'
                  }
                  className={`fs-6 fw-semibold form-label required`}
                >
                  <span className=''>Address Line 1</span>
                </label>
                <Field
                  type='text'
                  className={`form-control ${
                    isValid(
                      `${item.id === 'curr_address' ? `curr_address_line_1` : 'per_address_line_1'}`
                    )
                      ? 'is-valid'
                      : isInValid(
                          `${
                            item.id === 'curr_address'
                              ? `curr_address_line_1`
                              : 'per_address_line_1'
                          }`
                        )
                      ? 'is-invalid'
                      : ''
                  }`}
                  id={item.id === 'curr_address' ? `curr_address_line_1` : 'per_address_line_1'}
                  name={item.id === 'curr_address' ? `curr_address_line_1` : 'per_address_line_1'}
                  placeholder='Enter adress line 1'
                  onChange={(e: any) => {
                    const inputVal = e.target.value
                    if (item.id === 'curr_address') {
                      setFieldValue('curr_address_line_1', inputVal)
                    } else {
                      setFieldValue('per_address_line_1', inputVal)
                    }
                    if (same_as_current_add && item.id === 'curr_address') {
                      setFieldValue('per_address_line_1', inputVal)
                    }
                  }}
                />
                <div className='text-danger'>
                  <ErrorMessage
                    name={item.id === 'curr_address' ? `curr_address_line_1` : 'per_address_line_1'}
                  />
                </div>
              </div>

              <div className='w-100 d-flex flex-column mb-4 fv-row px-2'>
                <label
                  htmlFor={
                    item.id === 'curr_address' ? `curr_address_line_2` : 'per_address_line_2'
                  }
                  className={`fs-6 fw-semibold form-label required`}
                >
                  <span className=''>Address Line 2</span>
                </label>
                <Field
                  type='text'
                  className={`form-control ${
                    isValid(
                      `${item.id === 'curr_address' ? `curr_address_line_2` : 'per_address_line_2'}`
                    )
                      ? 'is-valid'
                      : isInValid(
                          `${
                            item.id === 'curr_address'
                              ? `curr_address_line_2`
                              : 'per_address_line_2'
                          }`
                        )
                      ? 'is-invalid'
                      : ''
                  }`}
                  id={item.id === 'curr_address' ? `curr_address_line_2` : 'per_address_line_2'}
                  name={item.id === 'curr_address' ? `curr_address_line_2` : 'per_address_line_2'}
                  placeholder='Enter adress line 2'
                  onChange={(e: any) => {
                    const inputVal = e.target.value
                    if (item.id === 'curr_address') {
                      setFieldValue('curr_address_line_2', inputVal)
                    } else {
                      setFieldValue('per_address_line_2', inputVal)
                    }
                    if (same_as_current_add && item.id === 'curr_address') {
                      setFieldValue('per_address_line_2', inputVal)
                    }
                  }}
                />
                <div className='text-danger'>
                  <ErrorMessage
                    name={item.id === 'curr_address' ? `curr_address_line_2` : 'per_address_line_2'}
                  />
                </div>
              </div>

              <div className='w-100 d-flex mb-4'>
                <div className='w-100 w-md-50 d-flex flex-column fv-row px-2'>
                  <label
                    htmlFor={item.id === 'curr_address' ? `curr_city` : 'per_city'}
                    className={`fs-6 fw-semibold form-label`}
                  >
                    <span className='required'>City</span>
                    <i
                      className='fas fa-info-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title="Enter 'NA' if the City does not apply to you."
                    ></i>
                  </label>
                  <Field
                    type='text'
                    className={`form-control ${
                      isValid(`${item.id === 'curr_address' ? `curr_city` : 'per_city'}`)
                        ? 'is-valid'
                        : isInValid(`${item.id === 'curr_address' ? `curr_city` : 'per_city'}`)
                        ? 'is-invalid'
                        : ''
                    }`}
                    id={item.id === 'curr_address' ? `curr_city` : 'per_city'}
                    name={item.id === 'curr_address' ? `curr_city` : 'per_city'}
                    placeholder='Enter your city'
                    onChange={(e: any) => {
                      const inputVal = e.target.value
                      if (item.id === 'curr_address') {
                        setFieldValue('curr_city', inputVal)
                      } else {
                        setFieldValue('per_city', inputVal)
                      }
                      if (same_as_current_add && item.id === 'curr_address') {
                        setFieldValue('per_city', inputVal)
                      }
                    }}
                  />
                  <div className='text-danger'>
                    <ErrorMessage name={item.id === 'curr_address' ? `curr_city` : 'per_city'} />
                  </div>
                </div>
                <div className='w-100 w-md-50 d-flex flex-column fv-row px-2'>
                  <label
                    htmlFor={item.id === 'curr_address' ? `curr_state` : 'per_state'}
                    className={`fs-6 fw-semibold form-label`}
                  >
                    <span className='required'>State/Region</span>
                    <i
                      className='fas fa-info-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title="Enter 'NA' if the State/Region does not apply to you."
                    ></i>
                  </label>
                  <Field
                    type='text'
                    className={`form-control ${
                      isValid(`${item.id === 'curr_address' ? `curr_state` : 'per_state'}`)
                        ? 'is-valid'
                        : isInValid(`${item.id === 'curr_address' ? `curr_state` : 'per_state'}`)
                        ? 'is-invalid'
                        : ''
                    }`}
                    id={item.id === 'curr_address' ? `curr_state` : 'per_state'}
                    name={item.id === 'curr_address' ? `curr_state` : 'per_state'}
                    placeholder='Enter your state/region'
                    onChange={(e: any) => {
                      const inputVal = e.target.value
                      if (item.id === 'curr_address') {
                        setFieldValue('curr_state', inputVal)
                      } else {
                        setFieldValue('per_state', inputVal)
                      }
                      if (same_as_current_add && item.id === 'curr_address') {
                        setFieldValue('per_state', inputVal)
                      }
                    }}
                  />
                  <div className='text-danger'>
                    <ErrorMessage name={item.id === 'curr_address' ? `curr_state` : 'per_state'} />
                  </div>
                </div>
              </div>

              <div className='w-100 d-flex mb-4'>
                <div className='w-100 w-md-50 px-2'>
                  <label
                    htmlFor={item.id === 'curr_address' ? `curr_country` : 'per_country'}
                    className={`fs-6 fw-semibold form-label required`}
                  >
                    Country
                  </label>
                  <Field
                    type='text'
                    id={item.id === 'curr_address' ? `curr_country` : 'per_country'}
                    name={item.id === 'curr_address' ? `curr_country` : 'per_country'}
                    className={`form-control ${
                      isValid(`${item.id === 'curr_address' ? `curr_country` : 'per_country'}`)
                        ? 'is-valid'
                        : isInValid(
                            `${item.id === 'curr_address' ? `curr_country` : 'per_country'}`
                          )
                        ? 'is-invalid'
                        : ''
                    }`}
                    placeholder='Enter your country'
                    onChange={(e: any) => {
                      const inputVal = e.target.value
                      if (item.id === 'curr_address') {
                        setFieldValue('curr_country', inputVal)
                      } else {
                        setFieldValue('per_country', inputVal)
                      }
                      if (same_as_current_add && item.id === 'curr_address') {
                        setFieldValue('per_country', inputVal)
                      }
                    }}
                  />
                  <div className='text-danger'>
                    <ErrorMessage
                      name={item.id === 'curr_address' ? `curr_country` : 'per_country'}
                    />
                  </div>
                </div>
                <div className='w-100 w-md-50 d-flex flex-column fv-row px-2'>
                  <label
                    htmlFor={item.id === 'curr_address' ? `curr_pincode` : 'per_pincode'}
                    className={`fs-6 fw-semibold form-label required`}
                  >
                    <span className=''>Zip/Pincode</span>
                  </label>
                  <Field
                    type='text'
                    className={`form-control ${
                      isValid(`${item.id === 'curr_address' ? `curr_pincode` : 'per_pincode'}`)
                        ? 'is-valid'
                        : isInValid(
                            `${item.id === 'curr_address' ? `curr_pincode` : 'per_pincode'}`
                          )
                        ? 'is-invalid'
                        : ''
                    }`}
                    id={item.id === 'curr_address' ? `curr_pincode` : 'per_pincode'}
                    name={item.id === 'curr_address' ? `curr_pincode` : 'per_pincode'}
                    placeholder='Enter your Zip/Pincode'
                    onChange={(e: any) => {
                      const inputVal = e.target.value
                      if (item.id === 'curr_address') {
                        setFieldValue('curr_pincode', inputVal)
                      } else {
                        setFieldValue('per_pincode', inputVal)
                      }
                      if (same_as_current_add && item.id === 'curr_address') {
                        setFieldValue('per_pincode', inputVal)
                      }
                    }}
                  />
                  <div className='text-danger'>
                    <ErrorMessage
                      name={item.id === 'curr_address' ? `curr_pincode` : 'per_pincode'}
                    />
                  </div>
                </div>
              </div>
            </div>
          )

        default:
          return null
      }
    })
  }

  const ids = [
    'per_country',
    'per_state',
    'per_address_line_1',
    'per_address_line_2',
    'per_city',
    'per_pincode',
  ]
  useEffect(() => {
    ids.forEach((id) => {
      const element = document.getElementById(id)
      if (element) {
        if (same_as_current_add) {
          element.classList.add('d-none')
        } else {
          element.classList.remove('d-none')
        }
      }
    })
  }, [same_as_current_add])

  // const initialValuesRef = useRef(initialValues)

  // useEffect(() => {
  //   initialValuesRef.current = initialValues
  // }, [initialValues])

  const getEditedValues = (currentValues: any) => {
    const initialValues = formValues1
    const editedValues: any = corrections || {}
    const now = new Date()
    const year = now.getFullYear()
    const month = String(now.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
    const day = String(now.getDate()).padStart(2, '0')
    const hours = String(now.getHours()).padStart(2, '0')
    const minutes = String(now.getMinutes()).padStart(2, '0')
    const seconds = String(now.getSeconds()).padStart(2, '0')

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`

    Object.keys(currentValues).forEach((key) => {
      if (currentValues[key] !== initialValues[key]) {
        editedValues[key] = {
          value: currentValues[key],
          by: `${currentUser?.first_name} ${currentUser?.last_name} (${currentUser?.email})`,
          at: formattedDateTime,
        }
      } else if (currentValues[key] === initialValues[key] && editedValues[key]) {
        // If the value is the same, and it exists in editedValues, remove it
        delete editedValues[key]
      }
    })

    // console.log(editedValues)

    return editedValues
  }

  const onSubmit = async (values: any) => {
    const editedValues = getEditedValues(values)
    contextSetCorrections({
      ...contextCorrections,
      _address_contacts: {...editedValues},
    })

    const corr = {
      ...contextCorrections,
      _address_contacts: {...editedValues},
    }
    await updateDataOnDB({
      ...candidateData,
      corrections: corr,
    })
    closeEditSec('addressContact')
  }

  const decodeFromBase64 = (data: any) => {
    if (data === '' || data === null) {
      return ''
    }
    try {
      const jsonString = atob(data) // Decode base64
      return JSON.parse(jsonString) // Parse JSON string
    } catch (error) {
      console.error('Failed to decode and parse base64 string:', error)
      return null
    }
  }

  const formatKeys = (key: string) => {
    const keys: any = {
      curr_address: 'Address',
      curr_country: 'Country',
      curr_state: 'State',
      curr_pincode: 'Pincode',
      curr_address_line_1: 'Address Line 1',
      curr_address_line_2: 'Address Line 2',
      curr_city: 'City',
      per_country: 'Country',
      per_state: 'State',
      per_pincode: 'Pincode',
      per_address_line_1: 'Address Line 1',
      per_address_line_2: 'Address Line 2',
      per_city: 'City',
      per_address: 'Address',
    }
    return keys[key] || key
  }

  return (
    <>
      {fmVals &&
      fmVals['_address_contacts'] &&
      Object.keys(fmVals['_address_contacts']).length > 0 ? (
        <>
          {editMode ? (
            <div className='d-flex justify-content-between flex-wrap'>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {({setFieldValue, values, errors, touched, isValid, setFieldError}) => {
                  return (
                    <Form className='w-100 d-flex flex-wrap'>
                      {/* {RenderFields(dummyData?.items, setFieldValue, values, errors, touched)} */}
                      {RenderFields(setFieldValue, values, errors, touched, setFieldError)}
                      <div className='w-100 d-flex justify-content-center mt-5'>
                        <button
                          className='btn btn-light btn-sm me-2'
                          onClick={() => closeEditSec('addressContact')}
                        >
                          Cancel
                        </button>
                        <button
                          type='submit'
                          className='btn btn-primary btn-md'
                          // disabled={!isValid}
                          onClick={() => {
                            if (!isValid) {
                              return UseToast(
                                'Please fill all the mandatory fields.',
                                {
                                  position: 'bottom-right',
                                  theme: 'colored',
                                  autoClose: true,
                                },
                                'error'
                              )
                            }
                          }}
                        >
                          {postLoading ? (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              Please wait...{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          ) : (
                            'Save Information'
                          )}
                        </button>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          ) : (
            <>
              {/* {!editMode && Object.keys(fmVals['_address_contacts']).length > 0 && (
                <div className='d-flex justify-content-end mb-5'>
                  <button
                    className='btn btn-light-primary btn-sm ms-2'
                    onClick={() => setEditMode(true)}
                  >
                    <KTIcon iconName='pencil' className='fs-5' />
                    Edit
                  </button>
                </div>
              )} */}
              <div className='w-100 d-flex flex-wrap m-auto'>
                {Object.entries(fmVals['_address_contacts']).map(([key, value]: any, index) => {
                  const {label, value: val} = value
                  const correction = corrections[key]

                  if (val === 'Separator') {
                    return (
                      <div key={index} className='w-100'>
                        <h3 className='mb-3 text-primary'>
                          <p
                            className='alert bg-light text-center text-muted'
                            style={{
                              width: '100%',
                              verticalAlign: 'middle',
                              fontSize: '12px',
                              textTransform: 'uppercase',
                            }}
                          >
                            <span className='fw-semibold'>{label}</span>
                          </p>
                        </h3>
                      </div>
                    )
                  } else {
                    return (
                      <div key={index} className='w-50 row mb-5 align-items-center'>
                        <label className='col-lg-4 fw-semibold text-muted'>
                          {/* {key.replace(/_/g, ' ')} */}
                          {formatKeys(label)}
                        </label>
                        <div className='col-lg-8'>
                          <span className='fs-6 text-dark text-hover-primary default-cursor'>
                            {correction ? (
                              <>
                                <span className='fs-6 default-cursor text-decoration-line-through text-danger'>
                                  {label === 'curr_address' || label === 'per_address'
                                    ? decodeFromBase64(val)?.place_name
                                    : val || '-'}
                                </span>
                                <br />
                                <div className='d-flex align-items-center'>
                                  <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                    {label === 'curr_address' || label === 'per_address'
                                      ? decodeFromBase64(correction?.value)?.place_name
                                      : correction.value || '-'}
                                  </span>
                                  <button
                                    className='btn btn-unstyled m-0 p-0 text-hover-danger'
                                    data-bs-toggle='tooltip'
                                    title='Revert changes'
                                    onClick={() => deleteCorrections('_address_contacts', key)}
                                  >
                                    <KTIcon iconName='cross-circle' className='text-danger' />
                                  </button>
                                </div>
                              </>
                            ) : (
                              <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                {label === 'curr_address' || label === 'per_address'
                                  ? decodeFromBase64(val)?.place_name
                                  : val || '-'}
                              </span>
                            )}
                          </span>
                        </div>
                      </div>
                    )
                  }
                })}
              </div>
            </>
          )}
        </>
      ) : (
        <div
          className='card d-flex flex-column justify-content-center align-items-center'
          style={{height: '228px'}}
        >
          <h3 className='fw-semibold fs-3'>Candidate data not filled yet.</h3>
        </div>
      )}
    </>
  )
}

export default AddressContact
