import {createContext} from 'react'

type AppContextType = {
  podsData: any
  getPodsData: () => void
  isLoading: boolean
}

const AppContext = createContext<AppContextType>({
  podsData: [],
  getPodsData: () => {},
  isLoading: true,
})

export default AppContext
