import {Modal} from 'react-bootstrap'
import './index.css'
import {KTIcon} from '../../../_metronic/helpers'
import PoliciesKM from './policies_KM/PoliciesKM'

interface Props {
  showModal: boolean
  setShowModal(val: boolean): void
}

function PoliciesKMModal(props: Props) {
  return (
    <Modal
      show={props.showModal}
      onHide={() => props.setShowModal(false)}
      centered
      dialogClassName='custom-modal modal-dialog-centered'
      contentClassName='custom-modal-content'
    >
      <Modal.Body className='overflow-auto w-100'>
        <div className='d-flex justify-content-between align-items-center'>
          <h3 className='mb-4 fw-semibold'>Manage Policies</h3>
          <button
            type='button'
            className='d-block btn btn-sm btn-icon btn-light'
            onClick={() => props.setShowModal(false)}
          >
            <KTIcon iconName='cross' className='fs-1' />
          </button>
        </div>

        <p className=''>
          Welcome to the Policies page! This section empowers you to oversee all policies within
          your current pod. Here, you can view existing policies, add new ones, and modify or remove
          policies as needed, ensuring that your team operates within clear guidelines and updated
          standards.
        </p>
        <PoliciesKM />
      </Modal.Body>
    </Modal>
  )
}

export default PoliciesKMModal
