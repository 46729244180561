import React, {lazy, FC, Suspense, useState, useEffect} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import CreateNewPodCustomMain from '../modules/create-new-pod-wizard/CreateNewPodMain'
import OnboardingMain from '../modules/onboardings/OnboardingMain'
import EmpDetailsMain from '../modules/onboardings/emp_details/EmpDetailsMain'
import ConfigurationRoutes from '../modules/configuration/ConfigurationRoutes'
import {useAuth} from '../modules/auth'
import CandidateDataRouting from '../modules/recruitment/candidates/CandidateDataRouting'
import InterviewDataRouting from '../modules/recruitment/interviews/InterviewDataRouting'
import VacanciesDataRouting from '../modules/recruitment/vacancies/VacanciesDataRouting'
import TemplatesMain from '../modules/templates/TemplatesMain'
import Template1Main from '../modules/templates/template1/Template1Main'
import KanbanBoard from '../modules/templates/KanbanBoard'
import KanbanBoardNew from '../modules/templates/KanbanBoardNew'
import OfferRouting from '../modules/recruitment/offers/OfferRouting'
import TeamMembersMain from '../modules/team_members/TeamMembersMain'
import {TeamMembersTable} from '../modules/team_members/TeamMembersTable'
import PoliciesMain from '../modules/policies/PoliciesMain'
import CandidateHistoryRouting from '../modules/recruitment/candidates/candidate_history/CandidateHistoryRouting'
import AboutPolicyMain from '../modules/policies/about_policy/AboutPolicyMain'
import MyPods from '../modules/my_account/MyPods'
import EditVacancyMain from '../modules/recruitment/vacancies/edit_vacancy/EditVacancyMain'
import MyInvitesTable from '../modules/my_account/MyInvites'
import MyProfile from '../modules/my_account/MyProfile'
import OfferInfoRouting from '../modules/recruitment/offers/offer_info/OfferInfoRouting'
import TempInfo from '../modules/templates/templateInfo/TempInfo'
import BillingsPage from '../modules/subscriptions/BillingsPage'
import Documents from '../modules/documents/Folders'
import CompanyDocs from '../modules/documents/CompanyDocs'
import OnboardingDocuments from '../modules/documents/OnboardingDocuments'

const PrivateRoutes = () => {
  const {currentUser} = useAuth()
  const isFirstPodAvailable =
    currentUser && currentUser.pods_list && currentUser.pods_list.length > 0
  const currentPod: any = localStorage.getItem('currentPodCode')

  let podId: string | undefined
  if (currentUser?.pods_list?.length) {
    if (typeof currentPod === 'string') {
      const podExists = currentUser.pods_list.some(
        (each) => JSON.stringify(each.pod_code) === currentPod
      )
      podId = podExists ? JSON.parse(currentPod) : currentUser.pods_list[0].pod_code
    } else {
      podId = currentUser.pods_list[0].pod_code
    }
  }

  let currPod: string | undefined | null
  if (currentUser?.pods_list?.length) {
    if (typeof currentPod === 'string') {
      const podExists = currentUser.pods_list.some(
        (each) => JSON.stringify(each.pod_code) === currentPod
      )
      currPod = podExists ? JSON.parse(currentPod) : null
    } else {
      currPod = null
    }
  }
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  // const isFirstPodSet = localStorage.getItem('IsSetFirstPod')

  const NavigateInitial: React.FC<{element: React.ReactNode}> = ({element}) => {
    return !isFirstPodAvailable ? (
      <Navigate to='/my-pods' replace />
    ) : (
      // <Navigate to='/first-pod-setup' replace />
      <React.Fragment>{element}</React.Fragment>
    )
  }

  const NavigateHome: React.FC<{element: React.ReactNode}> = ({element}) => {
    return isFirstPodAvailable ? (
      <Navigate to={`/${podId}/dashboard`} replace />
    ) : (
      <React.Fragment>{element}</React.Fragment>
    )
  }

  const searchParams = new URLSearchParams(window.location.search)
  const redirectParam: string = decodeURIComponent(searchParams?.get('redirect') ?? '')

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        {/* <Route path='auth/*' element={<Navigate to={`/${podId}/dashboard`} />} /> */}
        <Route path='auth/*' element={<Navigate to={redirectParam || '/my-pods'} />} />
        {/* <Route path='auth/*' element={<Navigate to={`/my-invites`} />} /> */}
        {/* <Route path='auth/*' element={<Navigate to={`/pod-:id/dashboard`} />} /> */}
        {/* Pages */}
        <Route
          path={`/:id/dashboard`}
          element={<NavigateInitial element={<DashboardWrapper />} />}
        />
        <Route path='builder' element={<BuilderPageWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='/first-pod-setup'
          element={
            <SuspensedView>
              <NavigateHome element={<CreateNewPodCustomMain />} />
            </SuspensedView>
          }
        />

        <Route
          path={`/:id/onboarding`}
          element={
            <SuspensedView>
              <OnboardingMain />
            </SuspensedView>
          }
        />
        <Route
          path={`/:id/subscriptions`}
          element={
            <SuspensedView>
              <BillingsPage />
            </SuspensedView>
          }
        />
        <Route
          path={`/my-pods`}
          element={
            <SuspensedView>
              <MyPods />
            </SuspensedView>
          }
        />
        <Route
          path={`/my-invites`}
          element={
            <SuspensedView>
              <MyInvitesTable />
            </SuspensedView>
          }
        />
        <Route
          path={`/my-profile`}
          element={
            <SuspensedView>
              <MyProfile />
            </SuspensedView>
          }
        />
        {/* <Route
          path={`/pod-:id/onboarding/:type/:job_join_id`}
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={myExpensesTitle}>Onboardings</PageTitle>
              <EmpDetailsMain />
            </SuspensedView>
          }
        /> */}
        <Route
          path={`/:id/onboarding/*`}
          element={
            <SuspensedView>
              {/* <PageTitle breadcrumbs={myExpensesTitle}>Onboardings</PageTitle> */}
              <EmpDetailsMain />
            </SuspensedView>
          }
        />
        <Route
          path={`/:id/templates`}
          element={
            <SuspensedView>
              <TemplatesMain />
            </SuspensedView>
          }
        />
        <Route
          path={`/:id/templates/create/old`}
          element={
            <SuspensedView>
              <KanbanBoard />
            </SuspensedView>
          }
        />
        <Route
          path={`/:id/templates/create`}
          element={
            <SuspensedView>
              <KanbanBoardNew />
              {/* <KanbanBoard /> */}
            </SuspensedView>
          }
        />
        <Route
          path={`/:id/templates/:temp_id`}
          element={
            <SuspensedView>
              {/* <Template1Main /> */}
              <TempInfo />
            </SuspensedView>
          }
        />
        <Route
          path={`/:id/team-members`}
          element={
            <SuspensedView>
              <TeamMembersMain />
              {/* <TeamMembersTable /> */}
            </SuspensedView>
          }
        />
        <Route
          path={`/:id/policies`}
          element={
            <SuspensedView>
              <PoliciesMain />
            </SuspensedView>
          }
        />
        <Route
          path={`/:id/policies/*`}
          element={
            <SuspensedView>
              <AboutPolicyMain />
            </SuspensedView>
          }
        />
        <Route
          path={`/:id/candidates`}
          element={
            <SuspensedView>
              <CandidateDataRouting />
            </SuspensedView>
          }
        />

        <Route
          path={`/:id/candidates/*`}
          element={
            <SuspensedView>
              <CandidateHistoryRouting />
            </SuspensedView>
          }
        />
        <Route
          path={`/:id/vacancies`}
          element={
            <SuspensedView>
              <VacanciesDataRouting />
            </SuspensedView>
          }
        />

        <Route
          path={`/:id/vacancies/*`}
          element={
            <SuspensedView>
              <EditVacancyMain />
            </SuspensedView>
          }
        />

        <Route
          path={`/:id/offers/*`}
          element={
            <SuspensedView>
              <OfferInfoRouting />
            </SuspensedView>
          }
        />

        <Route
          path={`/:id/interviews`}
          element={
            <SuspensedView>
              <InterviewDataRouting />
            </SuspensedView>
          }
        />

        <Route
          path={`/:id/offers`}
          element={
            <SuspensedView>
              <OfferRouting />
            </SuspensedView>
          }
        />
        <Route
          path={`/:id/documents`}
          element={
            <SuspensedView>
              <Documents />
            </SuspensedView>
          }
        />
        <Route
          path={`/:id/documents/company-docs`}
          element={
            <SuspensedView>
              <CompanyDocs />
            </SuspensedView>
          }
        />
        <Route
          path={`/:id/documents/onboarding-docs`}
          element={
            <SuspensedView>
              <OnboardingDocuments />
            </SuspensedView>
          }
        />
        {/* <Route
          path={`/:id/*`}
          element={
            <SuspensedView>
              <div className='card h-250px d-flex justify-content-center align-items-center'>
                <h1>{pathname}</h1>
              </div>
            </SuspensedView>
          }
        /> */}

        <Route
          path='crafted/pages/profile/*'
          element={<SuspensedView>{/* <ProfilePage /> */}</SuspensedView>}
        />
        <Route
          path='crafted/pages/wizards/*'
          element={<SuspensedView>{/* <WizardsPage /> */}</SuspensedView>}
        />
        <Route
          path='crafted/widgets/*'
          element={<SuspensedView>{/* <WidgetsPage /> */}</SuspensedView>}
        />
        <Route
          path='crafted/account/*'
          element={<SuspensedView>{/* <AccountPage /> */}</SuspensedView>}
        />
        <Route path='apps/chat/*' element={<SuspensedView>{/* <ChatPage /> */}</SuspensedView>} />
        <Route
          path='apps/user-management/*'
          element={<SuspensedView>{/* <UsersPage /> */}</SuspensedView>}
        />
        <Route
          path={`/:id/configuration/*`}
          element={
            <SuspensedView>
              <ConfigurationRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='/accounts'
          element={
            <SuspensedView>
              {
                <div>
                  <h1>Accounts</h1>
                </div>
              }
            </SuspensedView>
          }
        />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
