import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../_metronic/helpers'
export function Toggle(props: any) {
  const {pod_logo, pod_pref_color, brandName, name, ClosePopover} = props.props
  const intl = useIntl()
  const handleDownload = () => {
    window.open(
      pod_logo
        ? pod_logo
        : `${process.env.REACT_APP_API_BASE_URL}/get/profile?name=${logobrandname}&color=${color}&size=128}`,
      '_blank'
    )
  }
  let color = pod_pref_color ? pod_pref_color.substring(1) : ''
  const logobrandname = encodeURIComponent(brandName)
  return (
    <div className='card'>
      <div className='card-header'>
        <h3 className='card-title fw-semibold fs-3 text-dark'>{name}</h3>
        <div className='card-toolbar me-2'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-active-light me-n5'
            onClick={() => ClosePopover()}
          >
            <KTIcon iconName='cross' className='fs-1' />
          </button>
        </div>
      </div>
      <div className='m-4 px-4 symbol symbol-200px'>
        <img
          src={
            pod_logo
              ? pod_logo
              : `${process.env.REACT_APP_API_BASE_URL}/get/profile?name=${logobrandname}&color=${color}&size=128}`
          }
          alt='profile'
        />
      </div>
      <div className='me-7 mb-4 d-flex justify-content-end'>
        <button
          type='button'
          className='btn btn-sm btn-light-primary btn-ripple'
          data-kt-menu-dismiss='true'
          onClick={handleDownload}
        >
          {intl.formatMessage({id: 'BUTTON.DOWNLOAD'})}
        </button>
      </div>
    </div>
  )
}
