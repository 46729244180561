import React from 'react'
import AddPolicyIcon from './privacy-policy.png'
import TrackReqImg from '../team_members_KM/time-tracking.png'
import SearchIcon from '../team_members_KM/search.png'

function PoliciesKM() {
  return (
    <>
      <div className='row'>
        <div className='col-lg-4 p-0'>
          <div className='w-100 h-100 p-5'>
            <div className='shadow-sm p-3 rounded h-100 d-flex flex-column justify-content-center align-items-center'>
              <img src={AddPolicyIcon} className='track-req-icon mb-5' alt='Add Friend' />
              <p className='text-center'>
                Click on the "+ New Policy" button to create and add new policies to your pod.
                Whether it's updating existing guidelines or adding new ones, this feature helps you
                keep your policies up-to-date and relevant.
              </p>
            </div>
          </div>
        </div>
        <div className='col-lg-4 p-0'>
          <div className='w-100 h-100 p-5'>
            <div className='shadow-sm p-3 rounded h-100 d-flex flex-column justify-content-center align-items-center'>
              <img src={TrackReqImg} alt='Track Request' className='track-req-icon mb-5' />
              <p className='text-center'>
                Easily monitor the status of your policies. Track which ones are active, pending
                approval, or archived, ensuring that your team operates according to the latest
                guidelines.
              </p>
            </div>
          </div>
        </div>
        <div className='col-lg-4 p-0'>
          <div className='w-100 h-100 p-5'>
            <div className='shadow-sm p-3 rounded h-100 d-flex flex-column justify-content-center align-items-center'>
              <img src={SearchIcon} className='track-req-icon mb-5' alt='Add Friend' />
              <p className='text-center'>
                Utilize the search function to swiftly find specific policies. Search by title,
                category, or status to locate the policy you need, streamlining your policy
                management process.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PoliciesKM
