import {useState} from 'react'
import {motion, AnimatePresence} from 'framer-motion'
import {KTIcon} from '../../../../_metronic/helpers'
import {useLocation} from 'react-router-dom'
import {useAuth} from '../core/Auth'
import secureLocalStorage from 'react-secure-storage'
import usePostApi from '../../custom_hooks/usePostApi'

function VerifyUser() {
  const {execute, postLoading} = usePostApi()
  const {saveAuth, setCurrentUser} = useAuth()
  const [errorMsg, setErrorMsg] = useState('')

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const u: string = searchParams?.get('u') || ''
  const t: string = searchParams?.get('t') || ''
  const verifyUserWithLink = async () => {
    try {
      const url = `/lead/verify`
      const formData = {
        verify_user: u,
        verify_token: t,
        verify_type: 'LNK',
      }

      const response = await execute(url, 'POST', formData)
      const userInfo = {
        auth_token: response?.data?.token,
        user_id: response?.data?.user_id,
      }

      secureLocalStorage.setItem('user_info', userInfo)
      saveAuth(response)
      setCurrentUser(response.data)
    } catch (error: any) {
      console.error(error)
      if (error.response) {
        const errorMessage = error.response.data.status_message
        setErrorMsg(errorMessage)
      } else {
        console.error('An unexpected error occurred:', error)
      }
      saveAuth(undefined)
    }
  }
  return (
    <AnimatePresence mode='wait'>
      <motion.div
        initial={{y: 10, opacity: 0}}
        animate={{y: 0, opacity: 1}}
        exit={{y: -10, opacity: 0}}
        transition={{duration: 0.5}}
      >
        <div className='w-350px d-flex flex-column justify-content-center'>
          <KTIcon iconName='verify' className='verify-icon mb-5 m-auto' />
          <h3 className='text-dark fw-semibold mb-3 m-auto'>Account/E-mail Verification</h3>

          <button type='button' className='btn btn-primary' onClick={verifyUserWithLink}>
            {!postLoading && <span className='indicator-label'>Complete Verification</span>}

            {postLoading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          {errorMsg && (
            <div className='alert border border-dashed border-gray-300 rounded px-5 py-3 badge-light-danger d-flex align-items-center p-2 mt-4 d-flex flex-row align-items-center'>
              <KTIcon iconName='information' className='fs-3 text-danger me-1' />
              <span className='fs-6 text-dark'>{errorMsg}</span>
            </div>
          )}
        </div>
      </motion.div>
    </AnimatePresence>
  )
}

export default VerifyUser
