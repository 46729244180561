import {useState, useContext} from 'react'
import axios from 'axios'
import sha512 from 'crypto-js/sha512'
import secureLocalStorage from 'react-secure-storage'
import AppContext from '../../AppContext'

interface UserInfo {
  user_id: string
  auth_token: string
}

const usePostApi = () => {
  const {getPodsData} = useContext(AppContext)
  const [loading, setLoading] = useState(true)
  const [postLoading, setPostLoading] = useState(false)
  const [error, setError] = useState(null)
  const [response, setResponse] = useState(null)
  const podListUpdateSign = secureLocalStorage.getItem('token_mod_signature')

  const execute = async (url: string | null, method: string, data: any, type = 'get') => {
    if (!url) {
      setLoading(false)
      return
    }
    if (type === 'post') {
      setPostLoading(true)
    } else {
      setLoading(true)
    }

    setError(null)
    setResponse(null)

    const endpoint = process.env.REACT_APP_API_BASE_URL + url
    const endpoint_checksum = sha512(endpoint).toString()

    // Calculate timestamp
    const timestamp = Math.floor(Date.now() / 1000)

    // Calculate x-api-magic-key
    const userInfo = secureLocalStorage.getItem('user_info')
    let user_id = ''
    let auth_token = ''
    if (userInfo) {
      ;({user_id, auth_token} = userInfo as UserInfo)
    }
    const base64ApiKeyPass = btoa(
      `${process.env.REACT_APP_API_KEY}:${process.env.REACT_APP_API_PASS}`
    )
    const concatenatedString =
      JSON.stringify(data) +
      timestamp +
      auth_token +
      base64ApiKeyPass +
      '-' +
      `${process.env.REACT_APP_API_DATA_CHECKSUM_FILLER}`
    const calculatedMagicKey = sha512(concatenatedString).toString()

    const headers = {
      'x-api-token': auth_token,
      'x-api-user-id': user_id,
      'x-api-key': process.env.REACT_APP_API_KEY,
      'x-api-pass': process.env.REACT_APP_API_PASS,
      'x-api-magic-key': calculatedMagicKey,
      'x-api-timestamp': timestamp,
      'x-api-endpoint': url,
      'x-api-endpoint-checksum': endpoint_checksum,
    }

    const urls: any = ['/pod/bulk-add-data', '/onbr/document/upload']

    try {
      const result = await axios({
        method,
        url: endpoint,
        data,
        headers,
      })
      setLoading(false)
      setPostLoading(false)
      setResponse(result.data)
      if (podListUpdateSign !== result.data.token_mod_signature && !urls.includes(url)) {
        getPodsData()
      }
      return result.data
    } catch (err: any) {
      setLoading(false)
      setPostLoading(false)
      setError(err)
      setResponse(err.response ? err.response.data : err.message)
      throw err
    }
  }

  return {loading, error, execute, response, postLoading}
}

export default usePostApi
