import {useState, useEffect} from 'react'
import axios from 'axios'
import {baseUrl} from '../core'
import secureLocalStorage from 'react-secure-storage'

function useApiCall(url) {
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const fetchData = async () => {
    try {
      if (!url) {
        setIsLoading(false)
        return
      }
      setIsLoading(true)
      // setData([])
      let user_id
      let auth_token
      const userInfo = secureLocalStorage.getItem('user_info')
      if (userInfo) {
        ;({user_id, auth_token} = userInfo)
      }

      const headers = {
        'x-api-token': auth_token,
        'x-api-user-id': user_id,
        'x-api-key': 'x-api-key-123',
        'x-api-pass': 'x-api-pass-123',
        'x-api-magic-key':
          '6eccef85e6749c49b2fbc983c9e784ecb146214e20e6ccccc6e559e3306df28f8033718e8db904004b30bf440453fc74d47c147e5a6c0f9093bc723c16563f0',
        'x-api-timestamp': '1700999929',
        'x-api-endpoint': '/pod/create',
        'x-api-endpoint-checksum':
          '63F158A92308B139B5CDB1724BEF697E9D2D655C9CA5141E144814C35966B495',
      }
      const response = await axios.get(baseUrl + url, {
        headers,
      })
      setData(response.data.data)
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching user data:', error)
      setIsLoading(false)
    }
  }
  useEffect(() => {
    fetchData()
  }, [url])
  return {data, isLoading, fetchData}
}

export default useApiCall
