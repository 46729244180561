//businessBlock
import React, {useEffect, useMemo, useState} from 'react'
import {useTable, usePagination} from 'react-table'
import {Pagination} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../../../_metronic/helpers'
import usePageTitle from '../../../../custom_hooks/usePageTitle'
import {useParams} from 'react-router-dom'
import usePostApi from '../../../../custom_hooks/usePostApi'
import {FormattedMessage} from 'react-intl'
import Interviewsknowmore from '../../../interviews/Interviewsknowmore/Interviewknowmore'
import {PageTitle} from '../../../../../../_metronic/layout/core'
import DateFormatter from '../../../../DateFormatter'
import ScheduleCandidateInterview from './ScheduleCandidateInterview'
const DataTable = ({columns, data, loading, fetchData}) => {
  const [inputVal, setInputVal] = useState('')
  const [popover, setPopover] = useState(null)
  const todayDate = useMemo(() => new Date().toISOString().slice(0, 10), [])
  const [showModal, setShowModal] = useState(false)
  const [showKM, setShowKM] = useState(false)
  const [addModal, setAddModal] = useState(false)
  const {id, candidate_id} = useParams()
  const {execute} = usePostApi()
  const [candidateId, updateCandidateId] = useState('')
  const intl = useIntl()
  usePageTitle('Interviews')
  const [interviewData, setinterviewsData] = useState([])

  const getInterviews = async () => {
    try {
      const url = `/pod/candidate/get`
      const formData = {
        candidate_id: candidate_id,
      }

      const response = await execute(url, 'POST', formData)
      setinterviewsData(response.data)
      console.log(response)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getInterviews()
  }, [])
  const [currentStatus, setCurrentStatus] = useState('Scheduled')

  const filteredData = useMemo(() => {
    return data
      ? data.filter((each) => {
          const nameMatches =
            each.candidate_first_name &&
            each.candidate_first_name.toLowerCase().includes(inputVal.toLowerCase())
          const currentStatusMatches =
            currentStatus === 'Completed'
              ? each.interview_date <= todayDate
              : each.interview_date > todayDate

          return nameMatches && currentStatusMatches
        })
      : []
  }, [inputVal, data, currentStatus, todayDate])

  // Create separate datasets for active and inactive records

  const tableInstance = useTable({columns, data: filteredData}, usePagination)
  const {
    getTableProps,
    getTableBodyProps,

    prepareRow,
    page,
    state: {pageIndex, pageSize},
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    gotoPage,
    setPageSize,
  } = tableInstance
  const pageOptions = [10, 20, 30, 40, 50]
  const policyBC = [
    {
      title: `(${id})`,
      path: `/${id}/candidates`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: ``,
      path: `/${id}/candidates`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `Interviews`,
      path: `/${id}/candidates`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: ``,
      path: `/${id}/candidates`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: interviewData[0]
        ? interviewData[0].candidate_first_name + ' ' + interviewData[0].candidate_last_name
        : '',
      path: `/${id}/candidates/interviews/${candidate_id}`,
      isSeparator: false,
      isActive: false,
    },
  ]

  // Render the component
  return (
    <div>
      <PageTitle breadcrumbs={policyBC}>Interviews</PageTitle>
      {loading ? (
        <div className='mt-6 h-250px d-flex justify-content-center align-items-center'>
          <div className='spinner-border spinner-primary mr-15'></div>
        </div>
      ) : data.length > 0 ? (
        <div className='card py-2 px-10'>
          <div className='card-header border-0 px-0'>
            <h3 className='card-title align-items-start flex-column'>
              <ul className='nav'>
                <li className='nav-item'>
                  <a
                    className='nav-link btn btn-sm btn-color-muted btn-active  btn-active-light-success active fw-normal px-4 me-1'
                    data-bs-toggle='tab'
                    onClick={() => setCurrentStatus('Scheduled')}
                  >
                    Scheduled
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link btn btn-sm btn-color-muted btn-active  btn-active-light-danger fw-normal px-4 me-1'
                    data-bs-toggle='tab'
                    onClick={() => setCurrentStatus('Completed')}
                  >
                    Completed
                  </a>
                </li>
              </ul>
            </h3>

            <div className='d-flex'>
              <div className='d-flex align-items-center position-relative me-4'>
                <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                <input
                  type='search'
                  id='kt_filter_search'
                  className='form-control form-control-white form-control-sm w-150px ps-9'
                  placeholder='Search'
                  value={inputVal}
                  onChange={(e) => setInputVal(e.target.value)}
                />
              </div>
              <div
                className='d-flex justify-content-center align-items-center'
                data-toggle='tooltip'
                title='Schedule Interview'
              >
                <button
                  className='btn btn-sm btn-light-primary me-3'
                  // onClick={handleOpenModal}
                  onClick={() => {
                    setAddModal(true)
                    updateCandidateId(candidate_id)
                  }}
                >
                  <KTIcon iconName='plus' className='fs-3' />
                  SCHEDULE
                </button>
              </div>

              <div
                className='d-flex justify-content-center align-items-center'
                data-toggle='tooltip'
              >
                <button
                  className='btn btn-sm btn-light-primary'
                  onClick={() => fetchData(true)}
                  data-bs-toggle='tooltip'
                  title='Click to load the latest data.'
                >
                  <i className='ki-solid ki-arrows-circle fs-2'></i>
                </button>
              </div>
            </div>
          </div>

          <div className='mb-0 mb-xl-8 '>
            <div className='overflow-auto'>
              <table
                className='table table-row-dashed table-row-gray-300 align-middle'
                {...getTableProps()}
              >
                <thead>
                  <tr className='fw-semibold text-muted bg-light rounded'>
                    <th className='min-w-150px default-cursor text-start ps-3'>Interview Round</th>

                    <th className='min-w-140px text-start default-cursor'>Interview Type</th>
                    <th className='min-w-140px text-start default-cursor'>Interviewer Name</th>
                    {/* <th className='min-w-140px text-start default-cursor'>Interview Mode</th> */}
                    <th className='min-w-100px text-start default-cursor'>Interview Date</th>
                    <th className='min-w-100px text-start default-cursor'>Interview Time</th>

                    <th className='min-w-100px text-start default-cursor'>Status</th>

                    {/* <th className='min-w-100px text-start'>
                          {intl.formatMessage({id: 'MENU.ACTIONS'})}
                        </th> */}
                  </tr>
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.length > 0 ? (
                    page.map((row) => {
                      prepareRow(row)
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => (
                            <td className='text-center' {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          ))}
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td className='' colSpan={8}>
                        <div className='d-flex justify-content-center'>
                          <p className='text-dark default-cursor fs-6 mt-5'>
                            {inputVal === '' ? (
                              intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
                            ) : (
                              <FormattedMessage
                                id='MSG.NO_DATA_MATCH'
                                values={{
                                  inputVal: <span className='text-primary'>{inputVal}</span>,
                                }}
                              />
                            )}
                          </p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          {data.length > 10 && (
            <Pagination>
              <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
              <Pagination.Prev onClick={previousPage} disabled={!canPreviousPage} />

              {[...Array(pageCount)].map((_, i) => (
                <Pagination.Item key={i} active={i === pageIndex} onClick={() => gotoPage(i)}>
                  {i + 1}
                </Pagination.Item>
              ))}

              <Pagination.Next onClick={nextPage} disabled={!canNextPage} />
              <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />

              <div className='d-flex align-items-center mx-5'>
                <span className='me-2'>Show</span>
                <select
                  className='form-select form-select-solid fw-semibold w-75px me-2'
                  value={pageSize}
                  onChange={(e) => setPageSize(Number(e.target.value))}
                >
                  {pageOptions.map((option) => (
                    <option key={option} value={option} className='select-bg'>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </Pagination>
          )}
        </div>
      ) : (
        <div className='min-h-250px d-flex justify-content-center align-items-center'>
          <div className='m-2 p-4'>
            <h3 className='fs-3 fw-semibold'>Manage Interviews</h3>

            <p className=''>
              Welcome to the Candidate-Interviews Page! Here, you have the tools to seamlessly
              schedule and track interviews for a Candidate, ensuring a smooth transition into your
              organization.
            </p>
            <Interviewsknowmore />
            <div
              className='d-flex justify-content-center align-items-center'
              data-toggle='tooltip'
              title='Schedule Interview'
            >
              <button
                className='btn btn-sm btn-light-primary me-3'
                // onClick={handleOpenModal}
                onClick={() => {
                  setAddModal(true)
                  updateCandidateId(candidate_id)
                }}
              >
                <KTIcon iconName='plus' className='fs-3' />
                SCHEDULE
              </button>
            </div>
          </div>
        </div>
      )}
      <ScheduleCandidateInterview
        showModal={addModal}
        setShowModal={setAddModal}
        candidateId={candidateId}
        fetchData={fetchData}
      />
    </div>
  )
}

const CandidateInterviewBlock = () => {
  const {execute, loading} = usePostApi()
  const [interviewData, setinterviewsData] = useState([])
  const {candidate_id} = useParams()

  const getInterviews = async () => {
    try {
      const url = `/pod/interview/candidate`
      const formData = {
        candidate_id: candidate_id,
      }

      const response = await execute(url, 'POST', formData)
      setinterviewsData(response.data)
      console.log(response)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getInterviews()
  }, [])

  const columns = useMemo(() => [
    {
      Header: 'Country Name',
      accessor: 'Country Name',
      Cell: ({row}) => {
        return (
          <div className='text-start ms-3'>
            <p className='text-dark fw-semibold text-hover-primary d-block fs-6 default-cursor'>
              {row.original.interview_round}
            </p>
          </div>
        )
      },
      disableSortBy: true,
    },

    {
      Header: 'Department Name',
      accessor: 'Department Name',
      Cell: ({row}) => {
        return (
          <div className='text-start'>
            <p className='text-dark  text-hover-primary d-block fs-6 default-cursor'>
              {row.original.interview_type_name}
            </p>
          </div>
        )
      },
      disableSortBy: true,
    },

    {
      Header: 'HiringManager Name',
      accessor: 'HiringManager Name',
      Cell: ({row}) => {
        return (
          <div className='text-start'>
            <p className='text-dark  text-hover-primary d-block fs-6 default-cursor'>
              {row.original.interviewer_name}
            </p>
          </div>
        )
      },
      disableSortBy: true,
    },

    {
      Header: 'State Name',
      accessor: 'State Name',
      Cell: ({row}) => {
        return (
          <div className='text-start'>
            <p className='text-dark  text-hover-primary d-block fs-6 default-cursor'>
              {DateFormatter(row.original.interview_date)}
            </p>
          </div>
        )
      },
      disableSortBy: true,
    },

    {
      Header: 'State Na',
      accessor: 'State Na',
      Cell: ({row}) => {
        return (
          <div className='text-start'>
            <p className='text-dark  text-hover-primary d-block fs-6 default-cursor'>
              {row.original.interview_start_time?.slice(0, 5)} -{' '}
              {row.original.interview_end_time?.slice(0, 5)}
            </p>
          </div>
        )
      },
      disableSortBy: true,
    },

    {
      Header: 'State Nam',
      accessor: 'State Nam',
      Cell: ({row}) => {
        return (
          <div className='text-start'>
            <p className='text-dark  text-hover-primary d-block fs-6 default-cursor'>
              {row.original.status}
            </p>
          </div>
        )
      },
      disableSortBy: true,
    },
  ])

  return (
    <DataTable
      columns={columns}
      data={interviewData ? interviewData : []}
      loading={loading}
      fetchData={getInterviews}
    />
  )
}

export default CandidateInterviewBlock
