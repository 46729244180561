/* eslint-disable jsx-a11y/anchor-is-valid */

import {useLocation, useParams} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import Vacancies from './Vacancies'
import Careers from './Careers'
import DomainInfo from './DomainInfo'
function Domains() {
  const {id} = useParams()
  const {pathname} = useLocation()
  const MasterDataTitle = [
    {
      title: `(${id})`,
      path: `/${id}/configuration/white-labeling`,
      isSeparator: false,
      isActive: true,
    },
    {
      title: '',
      path: `/${id}/configuration/white-labeling`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `Domain Branding`,
      path: `/${id}/configuration/white-labeling`,
      isSeparator: false,
      isActive: true,
    },
  ]
  const getCurrentComponent = () => {
    if (pathname.endsWith(`/white-labeling/domains`) === true) {
      return (
        <>
          <PageTitle breadcrumbs={MasterDataTitle}>Domains</PageTitle>
          <DomainInfo />
        </>
      )
    } else if (pathname.endsWith('/white-labeling/careers-portal') === true) {
      return (
        <>
          <PageTitle breadcrumbs={MasterDataTitle}>Careers Portal</PageTitle>
          <Vacancies />
        </>
      )
    } else if (pathname.endsWith('/white-labeling/careers') === true) {
      return (
        <>
          <PageTitle breadcrumbs={MasterDataTitle}>Careers</PageTitle>
          <Careers />
        </>
      )
    } else {
      return (
        <>
          <PageTitle breadcrumbs={MasterDataTitle}>Domains</PageTitle>
          <DomainInfo />
        </>
      )
    }
  }
  return <div>{getCurrentComponent()}</div>
}

export default Domains
