import {KTIcon} from '../../../../../_metronic/helpers'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import {useState, useEffect} from 'react'
import usePostApi from '../../../custom_hooks/usePostApi'
import Select, {components} from 'react-select'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {FindPodIdByCode} from '../../../FindPodIdByPodCode'

import {useIntl} from 'react-intl'
import {useThemeMode} from '../../../../../_metronic/partials'
import {UseToast} from '../../../useToast'

function VacancyInfo(className) {
  const podId = FindPodIdByCode()

  const {execute, postLoading} = usePostApi()
  const [editMode, setEditMode] = useState(false)
  const {id, vacancy_id} = useParams()
  const intl = useIntl()
  const {mode} = useThemeMode()
  const handleCancelChanges = () => {
    setEditMode(false)
  }

  const [vacancydata, setvacancyData] = useState([])

  const getVacancies = async () => {
    try {
      const url = `/pod/vacancy/get`
      const formData = {
        vacancy_id: vacancy_id,
      }

      const response = await execute(url, 'POST', formData)
      setvacancyData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getVacancies()
  }, [])
  let vacancyData = vacancydata && vacancydata[0]

  const [jobTitles, setjobtitleData] = useState([])
  const [departments, setdepartmentData] = useState([])
  const [jobRoles, setjobroleData] = useState([])
  const [hiringManager, sethiringManager] = useState([])
  const [currencies, setcurrencies] = useState([])
  const [locations, setlocations] = useState([])
  const [employmentTypes, setemploymentTypes] = useState([])
  const [qualifications, setqualifications] = useState([])
  const getEmploymenttypes = async () => {
    try {
      const url = `/pod/employment_type/active`
      const formData = {
        pod_id: podId,
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each) => ({
        value: each.emp_type_id,
        label: each.emp_type_name,
      }))

      setemploymentTypes(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getEmploymenttypes()
  }, [])

  const getjobRoles = async () => {
    try {
      const url = `/pod/job_roles/active`
      const formData = {
        pod_id: podId,
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each) => ({
        value: each.job_role_id,
        label: each.job_role_name,
      }))

      setjobroleData(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getjobRoles()
  }, [])

  const getDepartments = async () => {
    try {
      const url = `/pod/department/active`
      const formData = {
        pod_id: podId,
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each) => ({
        value: each.dep_id,
        label: each.dep_name,
      }))
      setdepartmentData(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getDepartments()
  }, [])

  const getjobtitles = async () => {
    try {
      const url = `/pod/job_titles/active`
      const formData = {
        pod_id: podId,
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each) => ({
        value: each.job_title_id,
        label: each.job_title_name,
      }))
      setjobtitleData(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getjobtitles()
  }, [])

  const getManager = async () => {
    try {
      const url = `/pod/interviewers`
      const formData = {
        pod_id: podId,
        type: 'ANY',
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each) => ({
        value: each.user_id,
        label: `${each.first_name} ${each.last_name}`,
      }))
      sethiringManager(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getManager()
  }, [])

  const getlocations = async () => {
    try {
      const url = `/pod/locations/active`
      const formData = {
        pod_id: podId,
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each) => ({
        value: each.location_id,
        label: each.location_name,
      }))
      setlocations(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getlocations()
  }, [])

  const getCurrencies = async () => {
    try {
      const url = `/master/currency`
      const formData = {
        pod_id: podId,
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each) => ({
        value: each.currency_id,
        label: each.currency_name,
      }))
      setcurrencies(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getCurrencies()
  }, [])

  const getqualifications = async () => {
    try {
      const url = `/pod/qualifications/list`
      const formData = {
        pod_id: podId,
      }

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each) => ({
        value: each.qualification_name,
        label: each.qualification_name,
      }))
      setqualifications(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getqualifications()
  }, [])

  const handleIndustryChange = (setFieldValue, selectedOptions) => {
    const values = selectedOptions ? selectedOptions.map((option) => option.value) : []

    setFieldValue('qualifications', values)
  }
  const handlejobRoleChange = (setFieldValue, selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('job_role_id', selectedOption.value)
    } else {
      setFieldValue('job_role_id', '')
    }
  }

  const handleEmploymenttypeChange = (setFieldValue, selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('employment_type_id', selectedOption.value)
    } else {
      setFieldValue('employment_type_id', '')
    }
  }

  const handlejobTitleChange = (setFieldValue, selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('job_title_id', selectedOption.value)
    } else {
      setFieldValue('job_title_id', '')
    }
  }

  const handledepartmentChange = (setFieldValue, selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('department_id', selectedOption.value)
    } else {
      setFieldValue('department_id', '')
    }
  }

  const handledManagerChange = (setFieldValue, selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('hiring_manager_id', selectedOption.value)
    } else {
      setFieldValue('hiring_manager_id', '') // Handle case where no option is selected
    }
  }

  const handlelocationChange = (setFieldValue, selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('location_id', selectedOption.value)
    } else {
      setFieldValue('location_id', '') // Handle case where no option is selected
    }
  }

  const handleCurrencyChange = (setFieldValue, selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('currency_id', selectedOption.value)
    } else {
      setFieldValue('currency_id', '') // Handle case where no option is selected
    }
  }

  const handleSaveChanges = () => {
    const element = document.getElementById('update_policy_btn')
    if (element) {
      element.click()
    }
  }

  const validationSchema = Yup.object().shape({
    job_role_id: Yup.string().required('Job Role is required'),
    job_title_id: Yup.string().required('Job title is required'),
    job_description: Yup.string().required('Description is required'),
    department_id: Yup.string().required('Department is required'),
    hiring_manager_id: Yup.string().required('Hiring Manager is required'),
    number_of_vacancies: Yup.string().required('No. of Vacancies is required'),
    qualifications: Yup.array().required('Qualification is required'),
    skills: Yup.string().required('skills is required'),
    experience_in_yrs: Yup.number()
      .required('Experience is required')
      .min(0, 'Experience must be between 0 and 60')
      .max(60, 'Experience must be between 0 and 60'),

    location_id: Yup.string().required('Location is required'),
    currency_id: Yup.string().required('Currency is required'),
    salary_start: Yup.number().required('Min. Salary is required'),
    salary_end: Yup.number()
      .required('Max. Salary is required')
      .moreThan(Yup.ref('salary_start'), 'Max. Salary should be greater than Min. Salary'),
    employment_type_id: Yup.string().required('Employement type is required'),
  })

  const initialValues = {
    job_role_id: vacancyData?.job_role_id || '',
    job_title_id: vacancyData?.job_title_id || '',

    job_description: vacancyData?.job_description || '',
    department_id: vacancyData?.department_id ? vacancyData?.department_id : 0,
    department_name: vacancyData?.department_name ? vacancyData?.department_name : 0,
    number_of_vacancies: vacancyData?.number_of_vacancies || '',

    location_id: vacancyData?.location_id,
    salary_start: vacancyData?.salary_start || '',
    salary_end: vacancyData?.salary_end || '',

    qualifications: vacancyData?.qualifications || [],

    experience_in_yrs: vacancyData?.experience_in_yrs || '',

    skills: vacancyData?.skills || '',
    salary_currency_name: vacancyData?.salary_currency_name || '',
    employment_type_id: vacancyData?.employment_type_id || '',
    currency_id: vacancyData?.salary_currency_id || '',
    hiring_manager_id: vacancyData?.hiring_manager_id ? vacancyData?.hiring_manager_id : 0,
    vacancy_status: vacancyData?.vacancy_status || '',
    hiring_manager_name: vacancyData?.hiring_manager_name,
  }

  const handleSubmit = (values) => {
    const formData = {
      job_role_id: parseInt(values.job_role_id),
      job_title_id: parseInt(values.job_title_id),
      pod_id: podId,
      location_id: values.location_id,
      department_id: values.department_id,
      hiring_manager_id: parseInt(values.hiring_manager_id) || 0,
      number_of_vacancies: values.number_of_vacancies,
      job_description: values.job_description,
      vacancy_id: vacancy_id,
      salary_start: values.salary_start,
      salary_end: values.salary_end,
      currency_id: parseInt(values.currency_id),
      qualifications: values.qualifications.join(','),
      skills: values.skills,
      experience_in_yrs: values.experience_in_yrs,
      vacancy_status: values.vacancy_status,
      vacancy_id: vacancy_id,
      employment_type_id: values.employment_type_id,
    }
    const updateVacancy = async () => {
      try {
        const url = `/pod/vacancy/update`

        const response = await execute(url, 'POST', formData)
        if (response?.status === true) {
          setEditMode(false)
        }
        getVacancies()
        UseToast(
          response.status_message,
          {
            position: 'bottom-right',
            theme: 'colored',
            autoClose: false,
          },
          'success'
        )
      } catch (error) {
        console.error('Error:', error)
        UseToast(
          error.response?.data?.status_message,
          {
            position: 'bottom-right',
            theme: 'colored',
            autoClose: false,
          },
          'error'
        )
      }
    }
    updateVacancy()
  }

  const policyBC = [
    {
      title: `(${id})`,
      path: `/${id}/vacancies`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: ``,
      path: `/${id}/vacancies`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `Vacancies`,
      path: `/${id}/vacancies`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: ``,
      path: `/${id}/vacancies`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: vacancyData ? vacancyData?.job_role_name : '',
      path: `/${id}/vacancies/basic-info/${vacancy_id}`,
      isSeparator: false,
      isActive: false,
    },
  ]
  const selectedQualifications = qualifications.filter((option) =>
    vacancyData.qualifications.includes(option.value)
  )

  return (
    <>
      <PageTitle breadcrumbs={policyBC}>Vacancy Info</PageTitle>

      <>
        <div className='row g-5 g-xl-8 d-flex justify-content-between pt-2'>
          <div className='col-lg-6 col-xl-6'>
            <div className='card mb-5 mb-xl-8 card-lg-stretch'>
              <>
                <div className='card-header'>
                  <div className='card-title m-0'>
                    <h3 className='fw-semibold m-0 default-cursor'>Vacancy Details</h3>
                  </div>

                  {editMode ? (
                    <div className='text-end'>
                      <button
                        type='submit'
                        className='btn btn-light-primary btn-sm mt-5'
                        onClick={handleSaveChanges}
                      >
                        <KTIcon iconName='check' className='fs-7' />
                        Save
                      </button>
                      <button
                        className='btn btn-icon btn-sm btn-active-light-danger mt-5 ms-3'
                        onClick={handleCancelChanges}
                      >
                        <KTIcon iconName='cross' className='fs-2' />
                      </button>
                    </div>
                  ) : (
                    <button
                      className='btn btn-light-primary btn-sm align-self-center btn-ripple'
                      onClick={() => {
                        setEditMode(true)
                      }}
                    >
                      <KTIcon iconName='pencil' className='fs-7' />
                      Edit
                    </button>
                  )}
                </div>

                {postLoading ? (
                  <div className='card h-250px'>
                    <div className='m-auto d-flex flex-column align-items-center'>
                      <div className='spinner-border spinner-primary mr-15'></div>
                    </div>
                  </div>
                ) : (
                  <div className='card-body p-9 pb-0'>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      {({handleChange, errors, touched, values, setFieldValue}) => {
                        return (
                          <Form>
                            <div className='row mb-7 d-flex align-items-center'>
                              <label className='col-lg-4 fw-semibold text-muted'>
                                {intl.formatMessage({id: 'LABEL.POSITION'})}
                              </label>
                              <div className='col-lg-8'>
                                {editMode ? (
                                  <>
                                    <Select
                                      value={jobRoles.find(
                                        (option) => option.value === values.job_role_id
                                      )}
                                      styles={{
                                        control: (baseStyles, state) => ({
                                          ...baseStyles,
                                          backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                                          color: '#ffffff',
                                          borderWidth: '0px',
                                        }),
                                        menu: (baseStyles) => ({
                                          ...baseStyles,
                                          backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                          ...baseStyles,
                                          color: mode === 'dark' ? '#92929f' : '#000000',
                                        }),
                                        input: (baseStyles) => ({
                                          ...baseStyles,
                                          color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                                        }),
                                        option: (baseStyles, {isFocused, isSelected}) => ({
                                          ...baseStyles,
                                          backgroundColor: isSelected
                                            ? '#C2C2C2'
                                            : isFocused
                                            ? '#e0e0e0'
                                            : baseStyles.backgroundColor,
                                          color: isSelected
                                            ? '#000000'
                                            : isFocused
                                            ? '#000000'
                                            : baseStyles.color,
                                        }),
                                      }}
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        backgroundColor: '#000000',
                                        colors: {
                                          ...theme.colors,
                                          primary25: 'rgba(80, 107, 236, 0.5)',
                                          primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                                        },
                                      })}
                                      className='react-select-container w-500'
                                      classNamePrefix='react-select'
                                      options={jobRoles}
                                      isSearchable
                                      placeholder='Select Job Role'
                                      onChange={(val) => handlejobRoleChange(setFieldValue, val)}
                                    />

                                    <ErrorMessage
                                      name='job_role_id'
                                      component='div'
                                      className='text-danger'
                                    />
                                  </>
                                ) : (
                                  <span className=' fs-6 text-dark text-hover-primary default-cursor'>
                                    {vacancyData?.job_role_name || '-'}
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className='row mb-7 d-flex align-items-center'>
                              <label className='col-lg-4 fw-semibold text-muted'>
                                {intl.formatMessage({id: 'LABEL.EMP.JOB_TITLE'})}
                              </label>
                              <div className='col-lg-8'>
                                {editMode ? (
                                  <>
                                    <Select
                                      value={jobTitles.find(
                                        (option) => option.value === values.job_title_id
                                      )}
                                      styles={{
                                        control: (baseStyles, state) => ({
                                          ...baseStyles,
                                          backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                                          color: '#ffffff',
                                          borderWidth: '0px',
                                        }),
                                        menu: (baseStyles) => ({
                                          ...baseStyles,
                                          backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                          ...baseStyles,
                                          color: mode === 'dark' ? '#92929f' : '#000000',
                                        }),
                                        input: (baseStyles) => ({
                                          ...baseStyles,
                                          color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                                        }),
                                        option: (baseStyles, {isFocused, isSelected}) => ({
                                          ...baseStyles,
                                          backgroundColor: isSelected
                                            ? '#C2C2C2'
                                            : isFocused
                                            ? '#e0e0e0'
                                            : baseStyles.backgroundColor,
                                          color: isSelected
                                            ? '#000000'
                                            : isFocused
                                            ? '#000000'
                                            : baseStyles.color,
                                        }),
                                      }}
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        backgroundColor: '#000000',
                                        colors: {
                                          ...theme.colors,
                                          primary25: 'rgba(80, 107, 236, 0.5)',
                                          primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                                        },
                                      })}
                                      className='react-select-container'
                                      classNamePrefix='react-select'
                                      options={jobTitles}
                                      isSearchable
                                      placeholder='Select Job Title'
                                      onChange={(val) => handlejobTitleChange(setFieldValue, val)}
                                    />
                                    <ErrorMessage
                                      name='job_title_id'
                                      component='div'
                                      className='text-danger'
                                    />
                                  </>
                                ) : (
                                  <span className=' fs-6 text-dark text-hover-primary default-cursor'>
                                    {vacancyData?.job_title_name}
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className='row mb-7 d-flex align-items-center'>
                              <label className='col-lg-4 fw-semibold text-muted'>
                                {intl.formatMessage({id: 'LABEL.DESCRIPTION'})}
                              </label>
                              <div className='col-lg-8'>
                                {editMode ? (
                                  <>
                                    <Field
                                      type='text'
                                      name='job_description'
                                      className='form-control'
                                      placeholder={intl.formatMessage({id: 'LABEL.DESCRIPTION'})}
                                    />
                                    <ErrorMessage
                                      name='job_description'
                                      component='div'
                                      className='text-danger'
                                    />
                                  </>
                                ) : (
                                  <span className=' fs-6 text-dark text-hover-primary default-cursor'>
                                    {vacancyData?.job_description}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className='row mb-7 d-flex align-items-center'>
                              <label className='col-lg-4 fw-semibold text-muted'>
                                {intl.formatMessage({id: 'LABEL.DEPARTMENTS'})}
                              </label>
                              <div className='col-lg-8'>
                                {editMode ? (
                                  <>
                                    <Select
                                      value={departments.find(
                                        (option) => option.value === values.department_id
                                      )}
                                      styles={{
                                        control: (baseStyles, state) => ({
                                          ...baseStyles,
                                          backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                                          color: '#ffffff',
                                          borderWidth: '0px',
                                        }),
                                        menu: (baseStyles) => ({
                                          ...baseStyles,
                                          backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                          ...baseStyles,
                                          color: mode === 'dark' ? '#92929f' : '#000000',
                                        }),
                                        input: (baseStyles) => ({
                                          ...baseStyles,
                                          color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                                        }),
                                        option: (baseStyles, {isFocused, isSelected}) => ({
                                          ...baseStyles,
                                          backgroundColor: isSelected
                                            ? '#C2C2C2'
                                            : isFocused
                                            ? '#e0e0e0'
                                            : baseStyles.backgroundColor,
                                          color: isSelected
                                            ? '#000000'
                                            : isFocused
                                            ? '#000000'
                                            : baseStyles.color,
                                        }),
                                      }}
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        backgroundColor: '#000000',
                                        colors: {
                                          ...theme.colors,
                                          primary25: 'rgba(80, 107, 236, 0.5)',
                                          primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                                        },
                                      })}
                                      className='react-select-container'
                                      classNamePrefix='react-select'
                                      options={departments}
                                      isSearchable
                                      placeholder='Select Department'
                                      onChange={(val) => handledepartmentChange(setFieldValue, val)}
                                    />
                                    <ErrorMessage
                                      name='department_id'
                                      component='div'
                                      className='text-danger'
                                    />
                                  </>
                                ) : (
                                  <span className=' fs-6 text-dark text-hover-primary default-cursor'>
                                    {vacancyData?.department_name}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className='row mb-7 d-flex align-items-center'>
                              <label className='col-lg-4 fw-semibold text-muted'>
                                {intl.formatMessage({id: 'LABEL.QUALIFICATION'})}
                              </label>
                              <div className='col-lg-8'>
                                {editMode ? (
                                  <>
                                    <Select
                                      value={qualifications.filter((option) =>
                                        values.qualifications.includes(option.value)
                                      )}
                                      styles={{
                                        control: (baseStyles, state) => ({
                                          ...baseStyles,
                                          backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                                          color: '#ffffff',
                                          borderWidth: '0px',
                                        }),
                                        menu: (baseStyles) => ({
                                          ...baseStyles,
                                          backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                          ...baseStyles,
                                          color: mode === 'dark' ? '#92929f' : '#000000',
                                        }),
                                        input: (baseStyles) => ({
                                          ...baseStyles,
                                          color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                                        }),
                                        option: (baseStyles, {isFocused, isSelected}) => ({
                                          ...baseStyles,
                                          backgroundColor: isSelected
                                            ? '#C2C2C2'
                                            : isFocused
                                            ? '#e0e0e0'
                                            : baseStyles.backgroundColor,
                                          color: isSelected
                                            ? '#000000'
                                            : isFocused
                                            ? '#000000'
                                            : baseStyles.color,
                                        }),
                                      }}
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        backgroundColor: '#000000',
                                        colors: {
                                          ...theme.colors,
                                          primary25: 'rgba(80, 107, 236, 0.5)',
                                          primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                                        },
                                      })}
                                      className='react-select-container'
                                      classNamePrefix='react-select'
                                      options={qualifications}
                                      isSearchable
                                      isMulti
                                      placeholder='Select Qualification'
                                      onChange={(val) => handleIndustryChange(setFieldValue, val)}
                                    />
                                    <ErrorMessage
                                      name='qualifications'
                                      component='div'
                                      className='text-danger'
                                    />
                                  </>
                                ) : (
                                  <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                    {vacancyData?.qualifications}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className='row mb-7 d-flex align-items-center'>
                              <label className='col-lg-4 fw-semibold text-muted'>
                                {intl.formatMessage({id: 'LABEL.SKILLS'})}
                              </label>
                              <div className='col-lg-8'>
                                {editMode ? (
                                  <>
                                    <Field
                                      type='text'
                                      name='skills'
                                      className='form-control form-control-solid'
                                      value={values.skills}
                                      placeholder='skills'
                                      onChange={handleChange}
                                    />

                                    <ErrorMessage
                                      name='skills'
                                      component='div'
                                      className='text-danger'
                                    />
                                  </>
                                ) : (
                                  <span className=' fs-6 text-dark text-hover-primary default-cursor'>
                                    {vacancyData?.skills}
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className='row mb-7 d-flex align-items-center'>
                              <label className='col-lg-4 fw-semibold text-muted'>
                                {intl.formatMessage({id: 'LABEL.HIRING_MANAGER'})}
                              </label>
                              <div className='col-lg-8'>
                                {editMode ? (
                                  <>
                                    <Select
                                      value={hiringManager.find(
                                        (option) => option.value === values.hiring_manager_id
                                      )}
                                      styles={{
                                        control: (baseStyles, state) => ({
                                          ...baseStyles,
                                          backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                                          color: '#ffffff',
                                          borderWidth: '0px',
                                        }),
                                        menu: (baseStyles) => ({
                                          ...baseStyles,
                                          backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                          ...baseStyles,
                                          color: mode === 'dark' ? '#92929f' : '#000000',
                                        }),
                                        input: (baseStyles) => ({
                                          ...baseStyles,
                                          color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                                        }),
                                        option: (baseStyles, {isFocused, isSelected}) => ({
                                          ...baseStyles,
                                          backgroundColor: isSelected
                                            ? '#C2C2C2'
                                            : isFocused
                                            ? '#e0e0e0'
                                            : baseStyles.backgroundColor,
                                          color: isSelected
                                            ? '#000000'
                                            : isFocused
                                            ? '#000000'
                                            : baseStyles.color,
                                        }),
                                      }}
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        backgroundColor: '#000000',
                                        colors: {
                                          ...theme.colors,
                                          primary25: 'rgba(80, 107, 236, 0.5)',
                                          primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                                        },
                                      })}
                                      className='react-select-container'
                                      classNamePrefix='react-select'
                                      options={hiringManager}
                                      isSearchable
                                      placeholder='Select Manager'
                                      onChange={(val) => handledManagerChange(setFieldValue, val)}
                                    />
                                    <ErrorMessage
                                      name='hiring_manager_id'
                                      component='div'
                                      className='text-danger'
                                    />
                                  </>
                                ) : (
                                  <span className=' fs-6 text-dark text-hover-primary default-cursor'>
                                    {vacancyData?.hiring_manager_name}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className='row mb-7 d-flex align-items-center'>
                              <label className='col-lg-4 fw-semibold text-muted'>
                                {intl.formatMessage({id: 'LABEL.NO_OF_VACANCIES'})}
                              </label>
                              <div className='col-lg-8'>
                                {editMode ? (
                                  <>
                                    <Field
                                      type='number'
                                      name='number_of_vacancies'
                                      className='form-control'
                                      placeholder={intl.formatMessage({
                                        id: 'LABEL.NO_OF_VACANCIES',
                                      })}
                                    />
                                    <ErrorMessage
                                      name='number_of_vacancies'
                                      component='div'
                                      className='text-danger'
                                    />
                                  </>
                                ) : (
                                  <span className=' fs-6 text-dark text-hover-primary default-cursor'>
                                    {vacancyData?.number_of_vacancies}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className='row mb-7 d-flex align-items-center'>
                              <label className='col-lg-4 fw-semibold text-muted'>
                                {intl.formatMessage({id: 'LABEL.EXPERIENCE'})}
                              </label>
                              <div className='col-lg-8'>
                                {editMode ? (
                                  <>
                                    <Field
                                      type='number'
                                      name='experience_in_yrs'
                                      className='form-control'
                                      placeholder={intl.formatMessage({id: 'LABEL.EXPERIENCE'})}
                                      min='0'
                                      max='60'
                                    />
                                    <ErrorMessage
                                      name='experience_in_yrs'
                                      component='div'
                                      className='text-danger'
                                    />
                                  </>
                                ) : (
                                  <span className=' fs-6 text-dark text-hover-primary default-cursor'>
                                    {vacancyData?.experience_in_yrs}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className='row mb-7 d-flex align-items-center'>
                              <label className='col-lg-4 fw-semibold text-muted'>Location</label>
                              <div className='col-lg-8'>
                                {editMode ? (
                                  <>
                                    <Select
                                      value={locations.find(
                                        (option) => option.value === values.location_id
                                      )}
                                      styles={{
                                        control: (baseStyles, state) => ({
                                          ...baseStyles,
                                          backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                                          color: '#ffffff',
                                          borderWidth: '0px',
                                        }),
                                        menu: (baseStyles) => ({
                                          ...baseStyles,
                                          backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                          ...baseStyles,
                                          color: mode === 'dark' ? '#92929f' : '#000000',
                                        }),
                                        input: (baseStyles) => ({
                                          ...baseStyles,
                                          color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                                        }),
                                        option: (baseStyles, {isFocused, isSelected}) => ({
                                          ...baseStyles,
                                          backgroundColor: isSelected
                                            ? '#C2C2C2'
                                            : isFocused
                                            ? '#e0e0e0'
                                            : baseStyles.backgroundColor,
                                          color: isSelected
                                            ? '#000000'
                                            : isFocused
                                            ? '#000000'
                                            : baseStyles.color,
                                        }),
                                      }}
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        backgroundColor: '#000000',
                                        colors: {
                                          ...theme.colors,
                                          primary25: 'rgba(80, 107, 236, 0.5)',
                                          primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                                        },
                                      })}
                                      className='react-select-container'
                                      classNamePrefix='react-select'
                                      options={locations}
                                      isSearchable
                                      placeholder='Select Location'
                                      onChange={(val) => handlelocationChange(setFieldValue, val)}
                                    />
                                    <ErrorMessage
                                      name='location_id'
                                      component='div'
                                      className='text-danger'
                                    />
                                  </>
                                ) : (
                                  <span className=' fs-6 text-dark text-hover-primary default-cursor'>
                                    {vacancyData?.location_name}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className='row mb-7 d-flex align-items-center'>
                              <label className='col-lg-4 fw-semibold text-muted'>
                                {intl.formatMessage({id: 'LABEL.CURRENCY'})}
                              </label>
                              <div className='col-lg-8'>
                                {editMode ? (
                                  <>
                                    <Select
                                      value={currencies.find(
                                        (option) => option.value === values.currency_id
                                      )}
                                      styles={{
                                        control: (baseStyles, state) => ({
                                          ...baseStyles,
                                          backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                                          color: '#ffffff',
                                          borderWidth: '0px',
                                        }),
                                        menu: (baseStyles) => ({
                                          ...baseStyles,
                                          backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                          ...baseStyles,
                                          color: mode === 'dark' ? '#92929f' : '#000000',
                                        }),
                                        input: (baseStyles) => ({
                                          ...baseStyles,
                                          color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                                        }),
                                        option: (baseStyles, {isFocused, isSelected}) => ({
                                          ...baseStyles,
                                          backgroundColor: isSelected
                                            ? '#C2C2C2'
                                            : isFocused
                                            ? '#e0e0e0'
                                            : baseStyles.backgroundColor,
                                          color: isSelected
                                            ? '#000000'
                                            : isFocused
                                            ? '#000000'
                                            : baseStyles.color,
                                        }),
                                      }}
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        backgroundColor: '#000000',
                                        colors: {
                                          ...theme.colors,
                                          primary25: 'rgba(80, 107, 236, 0.5)',
                                          primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                                        },
                                      })}
                                      className='react-select-container'
                                      classNamePrefix='react-select'
                                      options={currencies}
                                      isSearchable
                                      placeholder='Select Currency'
                                      onChange={(val) => handleCurrencyChange(setFieldValue, val)}
                                    />
                                    <ErrorMessage
                                      name='currency_id'
                                      component='div'
                                      className='text-danger'
                                    />
                                  </>
                                ) : (
                                  <span className=' fs-6 text-dark text-hover-primary default-cursor'>
                                    {vacancyData?.salary_currency_name}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className='row mb-7 d-flex align-items-center'>
                              <label className='col-lg-4 fw-semibold text-muted'>
                                {' '}
                                {intl.formatMessage({id: 'LABEL.MIN_SALARY'})}
                              </label>
                              <div className='col-lg-8'>
                                {editMode ? (
                                  <>
                                    <Field
                                      type='number'
                                      name='salary_start'
                                      className='form-control'
                                      placeholder={intl.formatMessage({id: 'LABEL.MIN_SALARY'})}
                                    />
                                    <ErrorMessage
                                      name='salary_start'
                                      component='div'
                                      className='text-danger'
                                    />
                                  </>
                                ) : (
                                  <span className=' fs-6 text-dark text-hover-primary default-cursor'>
                                    {vacancyData?.salary_start} {'-'} {vacancyData?.salary_end}
                                  </span>
                                )}
                              </div>
                            </div>
                            {/* <div className='row mb-7 d-flex align-items-center'>
                              <label className='col-lg-4 fw-semibold text-muted'>
                                {' '}
                                {intl.formatMessage({id: 'LABEL.MAX_SALARY'})}
                              </label>
                              <div className='col-lg-8'>
                                {editMode ? (
                                  <>
                                    <Field
                                      type='number'
                                      name='salary_end'
                                      className='form-control'
                                      placeholder={intl.formatMessage({id: 'LABEL.MAX_SALARY'})}
                                    />
                                    <ErrorMessage
                                      name='salary_end'
                                      component='div'
                                      className='text-danger'
                                    />
                                  </>
                                ) : (
                                  <span className=' fs-6 text-dark text-hover-primary default-cursor'>
                                    {vacancyData?.salary_end}
                                  </span>
                                )}
                              </div>
                            </div> */}
                            <div className='row mb-7 d-flex align-items-center'>
                              <label className='col-lg-4 fw-semibold text-muted'>
                                {intl.formatMessage({id: 'LABEL.EMPLOYEMENT_TYPE'})}
                              </label>
                              <div className='col-lg-8'>
                                {editMode ? (
                                  <>
                                    {/* <Field
                                      type='text'
                                      id='floatingInput'
                                      className='form-control'
                                      name='employment_type_id'
                                      value={values.employment_type}
                                      onChange={handleChange}
                                    ></Field> */}
                                    <Select
                                      value={employmentTypes.find(
                                        (option) => option.value === values.employment_type_id
                                      )}
                                      styles={{
                                        control: (baseStyles, state) => ({
                                          ...baseStyles,
                                          backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                                          color: '#ffffff',
                                          borderWidth: '0px',
                                        }),
                                        menu: (baseStyles) => ({
                                          ...baseStyles,
                                          backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                          ...baseStyles,
                                          color: mode === 'dark' ? '#92929f' : '#000000',
                                        }),
                                        input: (baseStyles) => ({
                                          ...baseStyles,
                                          color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                                        }),
                                        option: (baseStyles, {isFocused, isSelected}) => ({
                                          ...baseStyles,
                                          backgroundColor: isSelected
                                            ? '#C2C2C2'
                                            : isFocused
                                            ? '#e0e0e0'
                                            : baseStyles.backgroundColor,
                                          color: isSelected
                                            ? '#000000'
                                            : isFocused
                                            ? '#000000'
                                            : baseStyles.color,
                                        }),
                                      }}
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        backgroundColor: '#000000',
                                        colors: {
                                          ...theme.colors,
                                          primary25: 'rgba(80, 107, 236, 0.5)',
                                          primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                                        },
                                      })}
                                      className='react-select-container'
                                      classNamePrefix='react-select'
                                      options={employmentTypes}
                                      isSearchable
                                      placeholder='Select Employment type'
                                      onChange={(val) =>
                                        handleEmploymenttypeChange(setFieldValue, val)
                                      }
                                    />
                                    <ErrorMessage
                                      name='employment_type_id'
                                      component='div'
                                      className='text-danger'
                                    />
                                  </>
                                ) : (
                                  <span className=' fs-6 text-dark text-hover-primary default-cursor'>
                                    {vacancyData?.employment_type}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className='row mb-7 d-flex align-items-center'>
                              <label className='col-lg-4 fw-semibold text-muted'>
                                {intl.formatMessage({id: 'LABEL.STATUS'})}
                              </label>
                              <div className='col-lg-8'>
                                {editMode ? (
                                  <>
                                    <Field
                                      type='text'
                                      id='floatingInput'
                                      className='form-control'
                                      name='vacancy_status'
                                      value={values.vacancy_status}
                                      onChange={handleChange}
                                    ></Field>
                                    <ErrorMessage
                                      name='vacancy_status'
                                      component='div'
                                      className='text-danger'
                                    />
                                  </>
                                ) : (
                                  <span className=' fs-6 text-dark text-hover-primary default-cursor'>
                                    {vacancyData?.vacancy_status}
                                  </span>
                                )}
                              </div>
                            </div>
                            <button
                              type='submit'
                              className='d-none btn btn-light-primary btn-sm'
                              id='update_policy_btn'
                            >
                              Save
                            </button>
                          </Form>
                        )
                      }}
                    </Formik>
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
      </>
    </>
  )
}

export default VacancyInfo
