import {Modal} from 'react-bootstrap'
import './index.css'
import TeamMembersKM from './team_members_KM/TeamMembers'
import {KTIcon} from '../../../_metronic/helpers'

interface Props {
  showModal: boolean
  setShowModal(val: boolean): void
}

function TeamMembersKnowMore(props: Props) {
  return (
    <Modal
      show={props.showModal}
      onHide={() => props.setShowModal(false)}
      centered
      dialogClassName='custom-modal modal-dialog-centered'
      contentClassName='custom-modal-content'
    >
      <Modal.Body className='overflow-auto w-100'>
        <div className='d-flex justify-content-between align-items-center'>
          <h3 className='mb-4 fw-semibold'>Manage Team Members</h3>
          <button
            type='button'
            className='d-block btn btn-sm btn-icon btn-light'
            onClick={() => props.setShowModal(false)}
          >
            <KTIcon iconName='cross' className='fs-1' />
          </button>
        </div>

        <p className=''>
          Welcome to the Team Members page! This section is designed to help you manage your team
          effortlessly, whether they're inside or outside the organization. Here, you can send
          invites to new members, track their status, and ensure seamless collaboration.
        </p>
        <TeamMembersKM />
      </Modal.Body>
    </Modal>
  )
}

export default TeamMembersKnowMore
