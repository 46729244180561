import {useMemo, useState, useEffect, useCallback} from 'react'
import {useTable, usePagination} from 'react-table'
import {useIntl, FormattedMessage} from 'react-intl'
import {Pagination} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import {FindPodIdByCode, CheckPodAccess, FindFirstPodCode} from '../FindPodIdByPodCode'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import {PageTitle} from '../../../_metronic/layout/core'
import AddNewPolicyModal from './AddPolicyModal'
import PoliciesKM from '../know_more_modals/policies_KM/PoliciesKM'
import PoliciesKMModal from '../know_more_modals/PolociesKMModal'
import {Link} from 'react-router-dom'
import usePostApi from '../custom_hooks/usePostApi'
import {UseToast} from '../useToast'
import DateFormatter from '../DateFormatter'

const DataTable = ({columns, data, isLoading, fetchData, refresh}) => {
  const {id} = useParams()
  const firstPodCode = FindFirstPodCode()

  const podHaveAccess = CheckPodAccess()

  const [showKM, setShowKM] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [addNewPolicyPopover, setAddNewPolicyPopover] = useState('')
  const [currentStatus, setCurrentStatus] = useState('Active')

  const intl = useIntl()
  const teammembers = [
    {
      title: `(${id})`,
      path: `/${id}/policies`,
      isSeparator: false,
      isActive: false,
    },
  ]

  const [inputVal, setInputVal] = useState('')

  const filteredData = useMemo(() => {
    return data
      ? data.filter((each) => {
          const status = each.is_active == 1 ? 'Active' : 'Inactive'
          const policyNameMatches =
            each.policy_name && each.policy_name.toLowerCase().includes(inputVal.toLowerCase())
          const policyUrlMatches =
            each.policy_url && each.policy_url.toLowerCase().includes(inputVal.toLowerCase())
          const currentStatusMatches =
            status && status.toLowerCase() === currentStatus.toLowerCase()

          return (policyNameMatches || policyUrlMatches) && currentStatusMatches
        })
      : []
  }, [inputVal, data, currentStatus])

  const tableInstance = useTable({columns, data: filteredData}, usePagination)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: {pageIndex, pageSize},
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    gotoPage,
    setPageSize,
  } = tableInstance

  const pageOptions = [10, 20, 30, 40, 50]

  const AddNewPolicyPopover = (popoverId) => {
    return (
      <Popover
        id={`delete-popover-${popoverId}`}
        flip='true'
        placement='auto'
        className='min-w-400px'
      >
        <Popover.Header as='div'>
          <div className='card-toolbar me-3 d-flex justify-content-between align-items-center'>
            <h3>Add New Policy</h3>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-active-light me-n5'
              onClick={() => setAddNewPolicyPopover(null)}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </button>
          </div>
        </Popover.Header>
        <Popover.Body></Popover.Body>
      </Popover>
    )
  }

  return podHaveAccess ? (
    <>
      <PoliciesKMModal showModal={showKM} setShowModal={setShowKM} />
      <AddNewPolicyModal showModal={showModal} setShowModal={setShowModal} fetchData={fetchData} />

      <PageTitle breadcrumbs={teammembers}>Policies</PageTitle>
      {isLoading && refresh === false ? (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : data && data.length > 0 ? (
        <>
          <div className='d-flex flex-wrap flex-stack'>
            <div className='d-flex'>
              <h1 className='fs-3 fw-semibold'>Manage Policies</h1>
            </div>
            <div className='d-flex'>
              <div className='m-auto d-flex flex-column align-items-center'>
                <button className='btn btn-sm btn-light' onClick={() => setShowKM(true)}>
                  <i className='ki-duotone ki-information-3 fs-3'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                  Know More
                </button>
              </div>
            </div>
          </div>
          <p className='fs-6 text-gray-600 mb-4'>
            Seamlessly view, add, modify, and remove policies within your current pod, ensuring
            clear guidelines and easy management for your team's operations.
          </p>

          {refresh ? (
            <div className='card h-250px'>
              <div className='m-auto d-flex flex-column align-items-center'>
                <div className='spinner-border spinner-primary mr-15'></div>
              </div>
            </div>
          ) : (
            <>
              <div className='card py-2 px-10 mb-5'>
                <div className='card-header border-0 px-0'>
                  <h3 className='card-title align-items-start flex-column'>
                    <ul className='nav'>
                      <li className='nav-item'>
                        <a
                          className='nav-link btn btn-sm btn-color-muted btn-active  btn-active-light-success active px-4 me-1 fw-normal'
                          data-bs-toggle='tab'
                          onClick={() => setCurrentStatus('Active')}
                        >
                          Active
                        </a>
                      </li>
                      <li className='nav-item'>
                        <a
                          className='nav-link btn btn-sm btn-color-muted btn-active  btn-active-light-danger px-4 me-1 fw-normal'
                          data-bs-toggle='tab'
                          onClick={() => setCurrentStatus('Inactive')}
                        >
                          Inactive
                        </a>
                      </li>
                    </ul>
                  </h3>
                  <div className='card-toolbar'>
                    <div className='d-flex align-items-center position-relative me-4 mb-1 mb-md-0'>
                      <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                      <input
                        type='search'
                        id='kt_filter_search'
                        className='form-control form-control-white form-control-sm w-150px ps-9 fw-normal'
                        placeholder={intl.formatMessage({id: 'ECOMMERCE.COMMON.SEARCH'})}
                        value={inputVal}
                        onChange={(e) => setInputVal(e.target.value)}
                      />
                    </div>

                    <button
                      className='btn btn-sm btn-light-primary mb-1 mb-md-0'
                      onClick={() => setShowModal(true)}
                    >
                      <KTIcon iconName='plus' className='fs-3' />
                      New Policy
                    </button>

                    <button
                      className='btn btn-sm btn-light-primary fw-normal mb-1 mb-md-0 ms-4'
                      onClick={() => fetchData(true)}
                      data-bs-toggle='tooltip'
                      title='Click to load the latest data.'
                    >
                      <i className='ki-solid ki-arrows-circle fs-2'></i>
                    </button>
                  </div>
                </div>

                <div className='mb-0 mb-xl-8'>
                  <div className='overflow-auto'>
                    <table
                      className='table table-row-dashed table-row-gray-300 align-middle'
                      {...getTableProps()}
                    >
                      <thead>
                        <tr className='fw-semibold text-muted bg-light rounded'>
                          <th className='min-w-150px ps-3'>Policy Name</th>
                          <th className='min-w-150px'>Policy Link / Legal Email ID</th>
                          <th className='min-w-150px'>Policy Version</th>
                          <th className='min-w-150px'>Last Updated Date</th>
                          <th className='min-w-140px text-start'>Status</th>
                          <th className='min-w-150px text-center pe-3'>Actions</th>
                        </tr>
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.length > 0 ? (
                          page.map((row) => {
                            prepareRow(row)
                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                  <td className='text-center' {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                  </td>
                                ))}
                              </tr>
                            )
                          })
                        ) : (
                          <tr>
                            <td className='' colSpan={6}>
                              <div className='d-flex justify-content-center'>
                                <p className='text-dark default-cursor fs-6 mt-5'>
                                  {inputVal === '' ? (
                                    intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
                                  ) : (
                                    <FormattedMessage
                                      id='MSG.NO_DATA_MATCH'
                                      values={{
                                        inputVal: <span className='text-primary'>{inputVal}</span>,
                                      }}
                                    />
                                  )}
                                </p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {data.length > 10 && (
                <Pagination>
                  <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                  <Pagination.Prev onClick={previousPage} disabled={!canPreviousPage} />

                  {[...Array(pageCount)].map((_, i) => (
                    <Pagination.Item key={i} active={i === pageIndex} onClick={() => gotoPage(i)}>
                      {i + 1}
                    </Pagination.Item>
                  ))}

                  <Pagination.Next onClick={nextPage} disabled={!canNextPage} />
                  <Pagination.Last
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  />

                  <div className='d-flex align-items-center mx-5'>
                    <span className='me-2'>{intl.formatMessage({id: 'BTN.SHOW'})}</span>
                    <select
                      className='form-select form-select-solid fw-bold w-75px me-2'
                      value={pageSize}
                      onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                      {pageOptions.map((option) => (
                        <option key={option} value={option} className='select-bg'>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </Pagination>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <h3 className='fs-3 fw-semibold'>Managing Policies</h3>

          <p className=''>
            Welcome to the Policies page! Here, you have the ability to create new policies, delete
            existing ones, and modify policy details as needed. We've designed this section to guide
            you through the process, ensuring that your policies are effectively managed and up to
            date.
          </p>

          <div className='text-center mb-4'>
            <PoliciesKM />
            <OverlayTrigger
              trigger='click'
              placement='auto'
              overlay={AddNewPolicyPopover('add_new_policy')}
              show={addNewPolicyPopover === 'add_new_policy'}
            >
              <button className='btn btn-sm btn-light-primary' onClick={() => setShowModal(true)}>
                <KTIcon iconName='plus' className='fs-3' />
                New Policy
              </button>
            </OverlayTrigger>
          </div>
        </>
      )}
    </>
  ) : (
    <div className='card min-h-250px d-flex flex-column justify-content-center align-items-center bg-image'>
      <h1 className='fw-semibold fs-2hx text-gray-900 mb-4'>Oops!</h1>
      <div className='fs-6 text-gray-500 mb-7'>You don't have access to this POD.</div>
      <div className='mb-0'>
        <Link to={`/${firstPodCode}/dashboard`} className='btn btn-sm btn-light-primary'>
          Return Home
        </Link>
      </div>
    </div>
  )
}

const PoliciesMain = () => {
  const {execute, loading: isLoading} = usePostApi()
  const podId = FindPodIdByCode()
  const {id} = useParams()
  const [refresh, setRefresh] = useState(false)

  const [policiesData, setPoliciesData] = useState([])
  const getPoliciesData = useCallback(
    async (show = false) => {
      if (show) {
        setRefresh(true)
      }
      try {
        const url = '/pod/policy/list'
        const formData = {pod_id: podId}

        const response = await execute(url, 'POST', formData)
        setPoliciesData(response.data)
        setRefresh(false)
        if (show) {
          UseToast(
            response.status_message,
            {
              position: 'bottom-right',
              theme: 'colored',
              autoClose: true,
            },
            'success'
          )
        }
      } catch (error) {
        console.error('Error:', error)
        setRefresh(false)
        if (show) {
          UseToast(
            error.response?.data?.status_message,
            {
              position: 'bottom-right',
              theme: 'colored',
              autoClose: true,
            },
            'error'
          )
        }
      } finally {
        setRefresh(false)
      }
    },
    [podId]
  )

  useEffect(() => {
    getPoliciesData()
  }, [getPoliciesData])

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({row}) => {
          return (
            <div className='d-flex align-items-center ps-3'>
              <p className='text-dark fw-semibold text-hover-primary mb-1 fs-6 default-cursor text-start'>
                {row.original.policy_name}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'DateOfBirth',
        accessor: 'dateOfBirth',
        Cell: ({row}) => {
          return (
            <p className='text-dark text-hover-primary default-cursor d-block fs-6 text-start'>
              {row.original.policy_url || '-'}
            </p>
          )
        },
      },
      {
        Header: 'policyVersion',
        accessor: 'policy_version',
        Cell: ({row}) => {
          return (
            <p className='text-dark text-hover-primary default-cursor d-block fs-6 text-start'>
              {row.original.policy_version || '-'}
            </p>
          )
        },
      },
      {
        Header: 'lastUpdatedDatw',
        accessor: 'last_updated_date',
        Cell: ({row}) => {
          return (
            <p className='text-dark text-hover-primary default-cursor d-block fs-6 text-start'>
              {row.original.policy_last_updated_date && (
                <span className='text-muted text-muted d-block fs-8 default-cursor'>
                  {row.original.policy_last_updated_date
                    ? DateFormatter(row.original.policy_last_updated_date)
                    : 'N.A.'}
                  {row.original.policy_last_updated_date && ', '}

                  {row.original.policy_last_updated_date &&
                    row.original.policy_last_updated_date.length >= 16 &&
                    row.original.policy_last_updated_date.slice(11, 16)}
                </span>
              )}
            </p>
          )
        },
      },
      {
        Header: 'Relation',
        accessor: 'relation',
        Cell: ({row}) => {
          return (
            <div className='d-flex justify-content-start'>
              <p
                className={`badge badge-light-${
                  row.original.is_active === 1 ? 'success' : 'danger'
                } fs-7 fw-normal default-cursor`}
              >
                {row.original.is_active === 1 ? 'Active' : 'Inactive'}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({row}) => {
          return (
            <div className='text-center'>
              <Link
                to={`/${id}/policies/wordings/${row.original.legal_policy_id}`}
                className='btn btn-light-primary btn-sm me-1'
              >
                View
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <DataTable
      columns={columns}
      data={policiesData || []}
      isLoading={isLoading}
      fetchData={getPoliciesData}
      refresh={refresh}
    />
  )
}

export default PoliciesMain
