import React, {useState, useEffect, useRef} from 'react'
import './KanbanBoard.css'
import {KTIcon} from '../../../_metronic/helpers'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import {PageTitle} from '../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import AddNewFieldModal from './modal_middle_screen/AddNewField'
import BoardDragDropContext from './BoardDrapDropContext'
import AddNewPolicyModal from './modal_middle_screen/AddNewPolicy'

const KanbanBoard = () => {
  const intl = useIntl()
  const {id} = useParams()
  const newTemplate = [
    {
      title: `(${id})`,
      path: `/${id}/template/create`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: ``,
      path: ``,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `Templates`,
      path: `/${id}/templates`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: ``,
      path: ``,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `Create New Template`,
      path: `/${id}/template/create`,
      isSeparator: false,
      isActive: false,
    },
  ]

  const [boards, setBoards] = useState([
    {
      id: '_basic_info',
      title: 'Basic Information',
      required: true,
      items: [
        {
          id: '_item1',
          name: 'First Name',
          required: true,
          type: 'Single Line',
          prompt: 'Enter your first name',
        },
        {
          id: '_item2',
          name: 'Last Name',
          required: true,
          type: 'Single Line',
          prompt: 'Enter your last name',
        },
      ],
    },
    {
      id: '_address&contacts',
      title: 'Address & Contacts',
      required: true,
      items: [
        {
          id: '_item3',
          name: 'Country',
          required: true,
          type: 'Single Line',
          prompt: 'Enter your country',
        },
        {
          id: '_item4',
          name: 'State',
          required: true,
          type: 'Single Line',
          prompt: 'Enter your state',
        },
        {id: '_item5', name: 'City', type: 'Single Line', prompt: 'Enter your city'},
      ],
    },
    {
      id: '_employment&education',
      title: 'Employment & Education',
      items: [
        {
          id: '_item6',
          name: 'Mobile No.',
          required: true,
          type: 'Number',
          prompt: 'Enter your mobile number',
        },
        {
          id: '_item7',
          name: 'Alternate No.',
          type: 'Number',
          prompt: 'Enter your Alt. mobile No.',
        },
        {
          id: '_item8',
          name: 'Email',
          required: true,
          type: 'Single Line',
          prompt: 'Enter your email',
        },
      ],
    },
    {
      id: '_policy&declarations',
      title: 'Policy & Declarations',
      items: [
        {
          id: '_item9',
          name: 'Self Declaration',
          required: true,
          type: 'Single Line',
          prompt: 'Declare your statement',
        },
        {
          id: '_item10',
          name: 'Digital Sign',
          required: true,
          type: 'Single Line',
          prompt: 'Digital sign for confirmation',
        },
      ],
    },
  ])

  const updatePoliciesData = (policiesData) => {
    const updatedBoards = boards.map((board) => {
      if (board.id === '_policy&declarations') {
        return {
          ...board,
          items: policiesData.items,
        }
      }
      return board
    })

    setBoards(updatedBoards)
  }

  const [editTaskId, setEditTaskId] = useState('')
  const [editTaskLabel, setEditTaskLabel] = useState('')
  const [editTaskType, setEditTaskType] = useState('')
  const [editPromptVal, setEditPromptVal] = useState('')
  const [isChecked, setIsChecked] = useState(false)

  const toggleMandatorySwitch = () => {
    const newState = !isChecked
    setIsChecked(newState)
  }

  const onSubmitSectionAdd = (values) => {
    const addBoard = () => {
      const newBoard = {
        id: '_' + Math.random().toString(36).substr(2, 9),
        title: values.section_name,
        items: [],
      }

      // Update the boards state with the new board
      const updatedBoards = [...boards, newBoard]
      // console.log('Updated Boards:', updatedBoards) // Check the updated boards
      setBoards(updatedBoards)
      setShowAddSectionModal(false)
      setAddNewSecPopover(null)
    }

    // console.log('Adding new board...')
    addBoard()
  }

  const onDragEnd = (result) => {
    const {source, destination, draggableId, type} = result

    // If dropped outside the list
    if (!destination) {
      return
    }

    // If dropped in the same list
    if (type === 'BOARD') {
      setEditedIndex(null)
      setEditedTitle('')
      const updatedBoards = [...boards]
      const [removedBoard] = updatedBoards.splice(source.index, 1)
      updatedBoards.splice(destination.index, 0, removedBoard)
      setBoards(updatedBoards)
    }

    if (type === 'TASK') {
      const sourceBoardIndex = boards.findIndex((board) => board.id === source.droppableId)
      const destinationBoardIndex = boards.findIndex(
        (board) => board.id === destination.droppableId
      )

      // If dropped in the same board
      if (sourceBoardIndex === destinationBoardIndex) {
        const updatedBoards = [...boards]
        const sourceBoard = updatedBoards[sourceBoardIndex]
        const updatedItems = Array.from(sourceBoard.items)
        const [removed] = updatedItems.splice(source.index, 1)
        updatedItems.splice(destination.index, 0, removed)

        const updatedBoard = {
          ...sourceBoard,
          items: updatedItems,
        }

        updatedBoards[sourceBoardIndex] = updatedBoard
        setBoards(updatedBoards)
      } else {
        // If dropped in a different board
        const updatedBoards = [...boards]
        const sourceBoard = updatedBoards[sourceBoardIndex]
        const destinationBoard = updatedBoards[destinationBoardIndex]
        const [removed] = sourceBoard.items.splice(source.index, 1)
        destinationBoard.items.splice(destination.index, 0, removed)

        setBoards(updatedBoards)
      }
    }
  }

  const handleEditTask = (task) => {
    setShowEditModal(true)
    setEditTaskId(task.id)
    setEditTaskLabel(task.name)
    setEditTaskType(task.type)
    setEditPromptVal(task.prompt)
  }

  const removeBoard = (id) => {
    if (id) {
      const updatedBoards = boards.filter((board) => board.id !== id)
      setBoards(updatedBoards)
    }
  }

  const handleDeleteTask = (taskId) => {
    const updatedBoards = boards.map((board) => {
      const updatedItems = board.items.filter((item) => item.id !== taskId)
      return {...board, items: updatedItems}
    })

    setBoards(updatedBoards)
  }

  const [showEditModal, setShowEditModal] = useState(false)
  const [showAddFieldModal, setShowAddFieldModal] = useState(false)
  const [showAddSectionModal, setShowAddSectionModal] = useState(false)
  const [showAddPolicyModal, setShowAddPolicyModal] = useState(false)

  const handleCloseChange = () => {
    setShowEditModal(false)
  }

  const handleClossAddFieldModal = () => {
    setShowAddFieldModal(false)
  }
  const handleClossAddSectionModal = () => {
    setShowAddSectionModal(false)
  }

  const handleAddPolicyItemChange = () => {
    setShowAddPolicyModal(false)
  }

  const addNewFieldSchema = Yup.object().shape({
    field_name: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(250, 'Maximum 250 characters')
      .required('Field Name is required'),
    prompt: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(250, 'Maximum 250 characters')
      .required('Prompt is required'),
    field_section: Yup.string().required('Field Section is required'),
    field_type: Yup.string().required('Field Type is required'),
  })

  const sectionAddSchema = Yup.object().shape({
    section_name: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(250, 'Maximum 250 characters')
      .required('Section Name is required'),
  })

  const initialValues = {
    field_name: '',
    field_section: '',
    prompt: '',
    field_type: '',
  }

  const sectionAddInitialValues = {
    section_name: '',
  }

  const onSubmit = (values, {resetForm}) => {
    // console.log(values, 'gfwhfgrjk')
    const addTask = () => {
      const updatedBoards = boards.map((board) => {
        if (board.id === values.field_section) {
          return {
            ...board,
            items: [
              ...board.items,
              {
                id: '_' + Math.random().toString(36).substr(2, 9),
                name: values.field_name,
                type: values.field_type,
                prompt: values.prompt,
                required: isChecked,
              },
            ],
          }
        }
        return board
      })
      setBoards(updatedBoards)
      // setNewTaskName('')
      // setSelectedFieldType('')
      setIsChecked(false)
      resetForm()
      setShowAddFieldModal(false)
    }
    addTask()
  }

  // Function to generate a unique ID
  const generateUniqueId = () => {
    return `_${Math.random().toString(36).substr(2, 9)}`
  }

  const [isTempNameEditing, setIsTempNameEditing] = useState(false)
  const [templateName, setTemplateName] = useState('Template Name')
  const inputRef = useRef(null)

  const handleEditClick = () => {
    setIsTempNameEditing(true)
  }

  const handleInputChange = (event) => {
    setTemplateName(event.target.value)
  }

  const handleInputBlur = () => {
    setIsTempNameEditing(false)
  }

  useEffect(() => {
    if (isTempNameEditing && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isTempNameEditing])

  const onSubmitPolicyAdd = (values, {resetForm}) => {
    const addNewPolicy = () => {
      const newObj = {
        id: generateUniqueId(), // Generate unique ID
        name: values.policy_name,
        required: false,
        prompt: values.prompt,
        type: values.policy_field_type,
      }
      const updatedBoards = boards.map((board) => {
        if (board.id === '_policy&declarations') {
          return {
            ...board,
            items: [...board.items, newObj],
          }
        }
        return board
      })

      setBoards(updatedBoards)
      setShowAddPolicyModal(false)
      resetForm()
    }

    addNewPolicy()
  }

  const policyAddSchema = Yup.object().shape({
    policy_name: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(250, 'Maximum 250 characters')
      .required('Policy Name is required'),
    prompt: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(250, 'Maximum 250 characters')
      .required('Prompt is required'),
    policy_field_type: Yup.string().required('Policy Field Type is required'),
  })

  const policyAddInitialValues = {
    policy_name: '',
    prompt: '',
    policy_field_type: '',
  }

  const [editedIndex, setEditedIndex] = useState(null)
  const [editedTitle, setEditedTitle] = useState('')
  const inputRef1 = useRef(null)

  const handleEditTitle = (index) => {
    setEditedIndex(index)
    setEditedTitle(boards[index].title)
  }

  const handleTitleChange = (event) => {
    setEditedTitle(event.target.value)
  }

  const handleSaveTitle = (index) => {
    const updatedBoards = [...boards]
    updatedBoards[index].title = editedTitle
    setBoards(updatedBoards)
    setEditedIndex(null)
  }

  useEffect(() => {
    if (editedIndex !== null && inputRef1.current) {
      inputRef1.current.focus()
    }
  }, [editedIndex])

  const [eSignReq, setESignReq] = useState(false)
  const [realTimeSigReq, setRealTimeSigReq] = useState(false)

  const editFieldOnsubmit = (values) => {
    // console.log(values)
    const editTask = () => {
      // if (editTaskId && editTaskLabel) {
      const updatedBoards = boards.map((board) => {
        const updatedItems = board.items.map((item) => {
          if (item.id === editTaskId) {
            return {
              ...item,
              name: values.field_name,
              type: values.field_type,
              prompt: values.prompt,
            }
          }
          return item
        })
        return {...board, items: updatedItems}
      })
      setBoards(updatedBoards)
      setEditTaskId('')
      setEditTaskLabel('')
      setEditTaskType('')
      setEditPromptVal('')
      setShowEditModal(false)
    }
    editTask()
  }

  const editFIeldSchema = Yup.object().shape({
    field_name: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(250, 'Maximum 250 characters')
      .required('Field Name is required'),
    prompt: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(250, 'Maximum 250 characters')
      .required('Prompt is required'),
    field_type: Yup.string().required('Field Type is required'),
  })

  const editFIeldInitialVals = {
    field_name: editTaskLabel,
    prompt: editPromptVal,
    field_type: editTaskType,
  }

  const [addNewSecPopover, setAddNewSecPopover] = useState(null)

  const addNewSectionPopover = (taskId) => (
    <Popover id={`delete-popover-${taskId}`} flip placement='auto' className='min-w-400px'>
      <Popover.Header as='div'>
        <div className='card-toolbar me-3 d-flex justify-content-between align-items-center'>
          <h3>Add New Section</h3>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-active-light me-n5'
            onClick={() => setAddNewSecPopover(null)}
          >
            <KTIcon iconName='cross' className='fs-1' />
          </button>
        </div>
      </Popover.Header>
      <Popover.Body>
        <Formik
          validationSchema={sectionAddSchema}
          initialValues={sectionAddInitialValues}
          onSubmit={onSubmitSectionAdd}
        >
          <Form className='min-w-350px'>
            <div className='fv-row mb-8 me-1'>
              <div className=''>
                <label className='fw-bold'>Section Name</label>
              </div>
              <Field name='section_name' className='form-control' placeholder='Section Name' />
              <ErrorMessage name='section_name' component='div' className='text-danger' />
            </div>
            <div className='d-flex justify-content-end'>
              <div>
                <button
                  type='submit'
                  className='btn btn-sm btn-light me-2'
                  onClick={() => setAddNewSecPopover(null)}
                >
                  Close
                </button>
                <button
                  type='submit'
                  className='btn btn-sm btn-primary ms-2'
                  id='add_new_section_btn'
                >
                  ADD
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </Popover.Body>
    </Popover>
  )

  const handleBodyClick = (e) => {
    // Check if the click target is inside the popover
    const isInsidePopover = e.target.closest('.popover')

    if (!isInsidePopover) {
      // Click is outside the popover, so close it
      setAddNewSecPopover(null)
    }
  }

  useEffect(() => {
    document.body.addEventListener('click', handleBodyClick)

    return () => {
      document.body.removeEventListener('click', handleBodyClick)
    }
  }, [])

  const [deletePopoverId, setDeletePopoverId] = useState(null)

  const handleBodyClick1 = (e) => {
    const isInsidePopover = e.target.closest('.popover')

    if (!isInsidePopover) {
      // Click is outside the popover, so close it
      setDeletePopoverId(null)
    }
  }

  useEffect(() => {
    document.body.addEventListener('click', handleBodyClick1)

    return () => {
      document.body.removeEventListener('click', handleBodyClick1)
    }
  }, [])

  const [addFieldPopup, setShowAddFieldPopup] = useState(false)
  const AddNewField = (values) => {
    const addTask = () => {
      const updatedBoards = boards.map((board) => {
        if (board.id === values.field_section) {
          return {
            ...board,
            items: [
              ...board.items,
              {
                id: '_' + Math.random().toString(36).substr(2, 9),
                name: values.field_name,
                type: values.field_type,
                prompt: values.prompt,
                required: values.isChecked,
              },
            ],
          }
        }
        return board
      })
      setBoards(updatedBoards)
    }
    addTask()
  }

  const [addPolicyPopup, setShowAddPolicyPopup] = useState(false)
  const AddNewPolicyItem = (values) => {
    const addNewPolicy = () => {
      const newObj = {
        id: generateUniqueId(), // Generate unique ID
        name: values.field_name,
        required: values.isChecked,
        prompt: values.prompt,
        type: values.field_type,
      }
      const updatedBoards = boards.map((board) => {
        if (board.id === '_policy&declarations') {
          return {
            ...board,
            items: [...board.items, newObj],
          }
        }
        return board
      })

      setBoards(updatedBoards)
    }

    addNewPolicy()
  }

  return (
    <>
      <PageTitle breadcrumbs={newTemplate}>New Template</PageTitle>

      <AddNewFieldModal
        modalShow={addFieldPopup}
        setShowModal={setShowAddFieldPopup}
        boards={boards}
        addNewField={AddNewField}
      />

      <AddNewPolicyModal
        modalShow={addPolicyPopup}
        setShowModal={setShowAddPolicyPopup}
        boards={boards}
        addNewField={AddNewPolicyItem}
      />

      <div className='w-100 d-flex flex-column flex-md-row'>
        <div
          className='card me-5 d-flex flex-column align-items-center justify-content-center template_name p-5'
          style={{position: 'relative'}}
        >
          {isTempNameEditing ? (
            <input
              type='text'
              value={templateName}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              className='form-control border-0 fw-bold'
              style={{background: 'transparent', textAlign: 'center', caretColor: 'black'}}
              ref={inputRef}
            />
          ) : (
            <h3 className='fs-3 fw-semibold'>{templateName}</h3>
          )}
          <span
            style={{position: 'absolute', top: '0', right: '0', cursor: 'pointer'}}
            onClick={!isTempNameEditing ? handleEditClick : handleInputBlur}
          >
            {isTempNameEditing ? null : <KTIcon iconName='pencil' className='fs-3 me-3 mt-3' />}
            {!isTempNameEditing ? null : <KTIcon iconName='check' className='fs-3 me-3 mt-3' />}
          </span>
        </div>

        <div className='card me-5 p-3 template_name'>
          <p className='fw-semibold mb-1' style={{width: '90%'}}>
            Template Preferences
          </p>
          <div className='w-100 d-flex justify-content-between'>
            <span
              className='text-muted fw-semibold cursor-default'
              onClick={() => setESignReq(!eSignReq)}
              style={{width: '90%'}}
            >
              All e-Sign Name (via Signature Fonts)
            </span>
            {eSignReq ? (
              <div className='cursor-pointer' onClick={() => setESignReq(false)}>
                <KTIcon iconName='toggle-on-circle' className='fs-1 text-primary' />
              </div>
            ) : (
              <div className='cursor-pointer' onClick={() => setESignReq(true)}>
                <KTIcon iconName='toggle-off-circle' className='fs-1' />
              </div>
            )}
          </div>
          <div className='w-100 d-flex justify-content-between'>
            <span
              className='text-muted fw-semibold cursor-default'
              onClick={() => setRealTimeSigReq(!realTimeSigReq)}
            >
              Require Real-time Signature Phote Capture
            </span>
            {realTimeSigReq ? (
              <div className='cursor-pointer' onClick={() => setRealTimeSigReq(false)}>
                <KTIcon iconName='toggle-on-circle' className='fs-1 text-primary' />
              </div>
            ) : (
              <div className='cursor-pointer' onClick={() => setRealTimeSigReq(true)}>
                <KTIcon iconName='toggle-off-circle' className='fs-1' />
              </div>
            )}
          </div>
        </div>

        <OverlayTrigger
          trigger='click'
          placement='auto'
          overlay={addNewSectionPopover('new_section')}
          show={addNewSecPopover === 'new_section'}
        >
          <div
            className='card me-5 btn btn-light-primary fw-bold d-flex flex-column justify-content-center align-items-center add_section_btn'
            // style={{width: '10%'}}
            // onClick={() => setShowAddSectionModal(true)}
            onClick={(e) => {
              e.stopPropagation()
              setAddNewSecPopover('new_section')
            }}
          >
            <div className=''>
              <KTIcon iconName='plus' className='fs-1' />
            </div>
            <p className='fw-semibold'>ADD NEW SECTION</p>
          </div>
        </OverlayTrigger>

        <div
          className='card me-5 btn btn-light-success fw-bold d-flex flex-column justify-content-center align-items-center add_section_btn'
          // style={{width: '10%'}}
          // onClick={() => setShowAddFieldModal(true)}
          onClick={() => setShowAddFieldPopup(true)}
        >
          <div className=''>
            <KTIcon iconName='plus' className='fs-1' />
          </div>
          <p className='fw-semibold'>ADD NEW FIELD</p>
        </div>
        <div
          className='card me-5 btn btn-light-danger fw-bold d-flex flex-column justify-content-center align-items-center add_section_btn'
          style={{padding: '0px'}}
          // onClick={() => setShowModal(true)}
        >
          <div className=''>
            <KTIcon iconName='plus' className='fs-1' />
          </div>
          <p className='mb-0 fw-semibold'>ADD NEW UNIQUE ID</p>
          <span className='fw-normal' style={{fontSize: '12px'}}>
            (Country Specific)
          </span>
        </div>
        <div
          className='card btn btn-light-info fw-bold d-flex flex-column justify-content-center align-items-center add_section_btn'
          // style={{width: '10%'}}
          // onClick={() => setShowAddPolicyModal(true)}
          onClick={() => setShowAddPolicyPopup(true)}
        >
          <div className=''>
            <KTIcon iconName='plus' className='fs-1' />
          </div>
          <p className='fw-semibold'>ADD POLICY</p>
        </div>
      </div>

      <div className='kt-portlet__body mt-lg-5 card p-5 pb-0'>
        <BoardDragDropContext
          boards={boards}
          onDragEnd={onDragEnd}
          editedIndex={editedIndex}
          deletePopoverId={deletePopoverId}
          setDeletePopoverId={setDeletePopoverId}
          handleDeleteTask={handleDeleteTask}
          updatePoliciesData={updatePoliciesData}
          handleEditTask={handleEditTask}
          handleEditTitle={handleEditTitle}
          inputRef1={inputRef1}
          editedTitle={editedTitle}
          handleSaveTitle={handleSaveTitle}
          handleTitleChange={handleTitleChange}
        >
          {/* This will render inside the DragDropContext */}
          {/* If you have other components to render alongside the boards, place them here */}
        </BoardDragDropContext>
      </div>

      {/* edit field  */}
      <Modal show={showEditModal} onHide={handleCloseChange}>
        <Modal.Header className='d-flex justify-content-between'>
          <h3>Edit Field</h3>

          <div className='card-toolbar me-3'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-active-light-primary me-n5'
              id='kt_drawer_chat_close'
              onClick={handleCloseChange}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className=''>
          <Formik
            validationSchema={editFIeldSchema}
            initialValues={editFIeldInitialVals}
            onSubmit={editFieldOnsubmit}
          >
            <Form className='min-w-350px'>
              <div className='fv-row mb-8 me-1'>
                <div className='d-flex justify-content-between align-items-center'>
                  <label className='fw-bold'>Field Name</label>
                </div>
                <Field name='field_name' className='form-control' placeholder='Field Name' />
                <ErrorMessage name='field_name' component='div' className='text-danger' />
              </div>
              <div className='fv-row mb-8 me-1'>
                <div className='d-flex justify-content-between align-items-center'>
                  <label className='fw-bold'>Prompt</label>
                </div>
                <Field name='prompt' className='form-control' placeholder='Field Name' />
                <ErrorMessage name='prompt' component='div' className='text-danger' />
              </div>
              <div className='fv-row mb-3 me-1'>
                <p className='form-label fs-6 fw-bolder text-dark mb-0'>Select Field Type</p>
                <Field as='select' name='field_type' className='form-select'>
                  <option value=''>Select Field Type</option>

                  <option value='Single Line'>Single Line</option>
                  <option value='Multi Line'>Multi Line</option>
                  <option value='Dropdown'>Dropdown</option>
                  <option value='Date'>Date</option>
                  <option value='Number'>Number</option>
                  <option value='Attachment'>Attachment</option>
                  <option value='Yes/No Question'>Yes/No Question</option>
                </Field>
                <ErrorMessage name='field_type' component='div' className='text-danger' />
              </div>
              <button
                type='submit'
                className='d-none btn btn-lg btn-primary w-100'
                id='edit_field_btn'
              >
                ADD
              </button>
            </Form>
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-light btn-sm' onClick={handleCloseChange}>
            {intl.formatMessage({id: 'BTN.CLOSE'})}
          </button>

          <button
            className='btn btn-light-primary btn-sm'
            onClick={() => {
              const element = document.getElementById('edit_field_btn')
              if (element) {
                element.click()
              }
            }}
          >
            UPDATE
          </button>
        </Modal.Footer>
      </Modal>

      {/* add field */}
      <Modal show={showAddFieldModal} onHide={handleClossAddFieldModal}>
        <Modal.Header className='d-flex justify-content-between'>
          <h3>Add Field</h3>

          <div className='card-toolbar me-3'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-active-light-primary me-n5'
              id='kt_drawer_chat_close'
              onClick={handleClossAddFieldModal}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className=''>
          <Formik
            validationSchema={addNewFieldSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            <Form className='min-w-350px'>
              <div className='fv-row mb-8 me-1'>
                <div className='d-flex justify-content-between align-items-center'>
                  <label className='fw-bold'>Field Name</label>
                  <span className='d-block bg-secondary px-2 rounded'>
                    Unique Code: Auto-generated
                  </span>
                </div>
                <Field name='field_name' className='form-control' placeholder='Field Name' />
                <ErrorMessage name='field_name' component='div' className='text-danger' />
              </div>

              <div className='fv-row mb-8 me-1'>
                <p className='form-label fs-6 fw-bolder text-dark mb-0'>Select Section</p>
                <Field as='select' name='field_section' className='form-select'>
                  <option value=''>Select a Section</option>
                  {boards.map((board) => (
                    <option key={board.id} value={board.id}>
                      {board.title}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name='field_section' component='div' className='text-danger' />
              </div>

              <div className='fv-row mb-8 me-1'>
                <div className='d-flex justify-content-between align-items-center'>
                  <label className='fw-bold'>Prompt</label>
                </div>
                <Field name='prompt' className='form-control' placeholder='Enter Prompt' />
                <ErrorMessage name='prompt' component='div' className='text-danger' />
              </div>

              <div className='fv-row mb-3 me-1'>
                <p className='form-label fs-6 fw-bolder text-dark mb-0'>Select Field Type</p>
                <Field as='select' name='field_type' className='form-select'>
                  <option value=''>Select Field Type</option>

                  <option value='Single Line'>Single Line</option>
                  <option value='Multi Line'>Multi Line</option>
                  <option value='Dropdown'>Dropdown</option>
                  <option value='Date'>Date</option>
                  <option value='Number'>Number</option>
                  <option value='Attachment'>Attachment</option>
                  <option value='Yes/No Question'>Yes/No Question</option>
                </Field>
                <ErrorMessage name='field_type' component='div' className='text-danger' />
              </div>
              <div className='d-flex align-items-center mt-2 mb-5'>
                <label className='switch'>
                  <input
                    type='checkbox'
                    checked={isChecked}
                    onChange={toggleMandatorySwitch}
                    id='mandatorySwitch'
                    className='d-none'
                  />
                  <span className='slider round'></span>
                </label>
                <label className='d-block ms-2 fw-semibold' htmlFor='mandatorySwitch'>
                  Mandatory Field
                </label>
              </div>
              <button
                type='submit'
                className='d-none btn btn-lg btn-primary w-100'
                id='add_new_field_btn'
              >
                ADD
              </button>
            </Form>
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-light btn-sm' onClick={handleClossAddFieldModal}>
            {intl.formatMessage({id: 'BTN.CLOSE'})}
          </button>

          <button
            type='submit'
            className='btn btn-light-primary btn-sm'
            onClick={() => {
              const element = document.getElementById('add_new_field_btn')
              if (element) {
                element.click()
              }
            }}
          >
            UPDATE
          </button>
        </Modal.Footer>
      </Modal>

      {/* add new section */}
      <Modal show={showAddSectionModal} onHide={handleClossAddSectionModal}>
        <Modal.Header className='d-flex justify-content-between'>
          <h3>Add New Section</h3>

          <div className='card-toolbar me-3'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-active-light-primary me-n5'
              id='kt_drawer_chat_close'
              onClick={handleClossAddSectionModal}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className=''>
          <Formik
            validationSchema={sectionAddSchema}
            initialValues={sectionAddInitialValues}
            onSubmit={onSubmitSectionAdd}
          >
            <Form className='min-w-350px'>
              <div className='fv-row mb-8 me-1'>
                <div className=''>
                  <label className='fw-bold'>Section Name</label>
                </div>
                <Field name='section_name' className='form-control' placeholder='Section Name' />
                <ErrorMessage name='section_name' component='div' className='text-danger' />
              </div>
              <button
                type='submit'
                className='d-none btn btn-lg btn-primary w-100'
                id='add_new_section_btn'
              >
                ADD
              </button>
            </Form>
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-light btn-sm'
            onClick={handleClossAddSectionModal}
          >
            {intl.formatMessage({id: 'BTN.CLOSE'})}
          </button>

          <button
            type='submit'
            className='btn btn-light-primary btn-sm'
            onClick={() => {
              const element = document.getElementById('add_new_section_btn')
              if (element) {
                element.click()
              }
            }}
          >
            UPDATE
          </button>
        </Modal.Footer>
      </Modal>

      {/* add policy item */}
      <Modal show={showAddPolicyModal} onHide={handleAddPolicyItemChange}>
        <Modal.Header className='d-flex justify-content-between'>
          <h3>Add Policy Field</h3>

          <div className='card-toolbar me-3'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-active-light-primary me-n5'
              id='kt_drawer_chat_close'
              onClick={handleAddPolicyItemChange}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className=''>
          <Formik
            validationSchema={policyAddSchema}
            initialValues={policyAddInitialValues}
            onSubmit={onSubmitPolicyAdd}
          >
            <Form className='min-w-350px'>
              <div className='fv-row mb-8 me-1'>
                <div className=''>
                  <label className='fw-bold'>Policy Name</label>
                </div>
                <Field
                  name='policy_name'
                  className='form-control'
                  placeholder='Enter Policy Name'
                />
                <ErrorMessage name='policy_name' component='div' className='text-danger' />
              </div>

              <div className='fv-row mb-8 me-1'>
                <div className=''>
                  <label className='fw-bold'>Prompt</label>
                </div>
                <Field name='prompt' className='form-control' placeholder='Enter Prompt' />
                <ErrorMessage name='prompt' component='div' className='text-danger' />
              </div>

              <div className='fv-row mb-3 me-1'>
                <p className='form-label fs-6 fw-bolder text-dark mb-0'>Select Field Type</p>
                <Field as='select' name='policy_field_type' className='form-select'>
                  <option value=''>Select Field Type</option>

                  <option value='Single Line'>Single Line</option>
                  <option value='Multi Line'>Multi Line</option>
                  <option value='Dropdown'>Dropdown</option>
                  <option value='Date'>Date</option>
                  <option value='Number'>Number</option>
                  <option value='Attachment'>Attachment</option>
                  <option value='Yes/No Question'>Yes/No Question</option>
                </Field>
                <ErrorMessage name='policy_field_type' component='div' className='text-danger' />
              </div>
              <button
                type='submit'
                className='d-none btn btn-lg btn-primary w-100'
                id='add_new_policy_btn'
              >
                ADD
              </button>
            </Form>
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-light btn-sm'
            onClick={handleAddPolicyItemChange}
          >
            {intl.formatMessage({id: 'BTN.CLOSE'})}
          </button>

          <button
            className='btn btn-light-primary btn-sm'
            type='submit'
            onClick={() => {
              const element = document.getElementById('add_new_policy_btn')
              if (element) {
                element.click()
              }
            }}
          >
            UPDATE
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default KanbanBoard
