/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import usePageTitle from '../../custom_hooks/usePageTitle'
import {
  FindBrandnameByCode,
  FindOrgNameByCode,
  FindOrgPodLogoByCode,
  FindPodIdByCode,
} from '../../FindPodIdByPodCode'
import usePostApi from '../../custom_hooks/usePostApi'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {UseToast} from '../../useToast'
import CreateSenders from './CreateSenders'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {useParams} from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
function DomainInfo() {
  const podId = FindPodIdByCode()
  const {id: pod_code}: any = useParams()
  const {execute, loading: isLoading} = usePostApi()
  const [domainsData, setTableData]: any = useState([])
  const domainsInfo = domainsData && domainsData[0]
  const [showModal, setShowModal] = useState(false)
  const [senderData, setSenderData]: any = useState([])
  const senderInfo = senderData && senderData[0]
  const brandName = FindBrandnameByCode()
  const orgName = FindOrgNameByCode()
  const podLogo = FindOrgPodLogoByCode()
  const userInfo: any = secureLocalStorage.getItem('user_info')
  let user_id = ''
  let auth_token = ''
  if (userInfo) {
    ;({user_id, auth_token} = userInfo)
  }
  const [isEditMode, setIsEditMode] = useState(false)
  const handleOpenModal = () => {
    setShowModal(true)
  }
  const handleCloseModal = () => {
    setShowModal(false)
  }
  const validationSchema = Yup.object().shape({
    domain_name: Yup.string()
      .required('Domain Name is required')
      .transform((value: string) => {
        const transformedValue = value
          ? value
              .replace(/^(https?:\/\/)?/, '')
              .replace(/\/+$/, '')
              .toLowerCase()
          : value
        return transformedValue
      })
      .matches(/^[a-z0-9-]+(\.[a-z]{2,})+$/, 'Invalid domain format')
      .test('no-special-characters', 'Domain name contains invalid characters', (value: string) =>
        /^[a-z0-9-.]+$/.test(value)
      ),
  })
  const fetchData = async () => {
    try {
      const url = `/pod/domain/list`
      const formData = {pod_id: podId}
      const response = await execute(url, 'POST', formData)
      setTableData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const senderFetchData = async (domainId: any) => {
    try {
      const url = `/pod/domain/senders/get`
      const formData = {pod_id: podId, domain_id: domainId}
      const response = await execute(url, 'POST', formData)
      setSenderData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    if (domainsInfo) {
      senderFetchData(domainsInfo?.domain_id)
    }
  }, [domainsInfo])
  usePageTitle('Domain Branding')
  const [copied, setCopied] = useState(false)
  const handleDomainVerify = async () => {
    const url = `/pod/domain/verify`
    const requestData = {
      pod_id: podId,
      domain_id: domainsInfo?.domain_id,
      domain_name: domainsInfo?.domain_name,
      last_verified_ts: domainsInfo?.last_verified_ts ? domainsInfo?.last_verified_ts : '',
      is_verified: 1,
    }
    try {
      const response = await execute(url, 'POST', requestData)
      fetchData()
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
    } catch (error: any) {
      console.error(error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }
  const handleSubmit = async (values: any) => {
    const url = `/pod/domain/update`
    const requestData = {
      pod_id: podId,
      parent_domain_id: domainsInfo?.domain_id,
      domain_name: values.domain_name
        ? values.domain_name
            .replace(/^(https?:\/\/)?/, '')
            .replace(/\/+$/, '')
            .toLowerCase()
        : values.domain_name,
      branding_type: 'cus',
      pod_code: pod_code,
    }
    try {
      const response = await execute(url, 'POST', requestData)
      fetchData()
      setIsEditMode(false)
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
    } catch (error: any) {
      console.error(error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }
  const handleEditClick = () => {
    setIsEditMode(true)
  }
  const handleCancelClick = () => {
    setIsEditMode(false)
  }
  return (
    <>
      {isLoading ? (
        <div className='pt-20 h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : (
        <div className='row g-5 g-xxl-8'>
          <div className='col-xl-6'>
            <div className='card card-xl-stretch'>
              <div className='card-header'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-semibold fs-3 mb-1'>Career Portal</span>
                </h3>
              </div>
              <div className='card-body'>
                <div className='row mb-4'>
                  <label className='col-lg-4 fw-semibold text-muted'>Career Portal URL</label>
                  <div className='col-lg-8'>
                    <span className='fs-6 text-dark text-hover-primary default-cursor'>
                      https://career.jetboard.site/{pod_code}/
                    </span>
                  </div>
                </div>
                <div className='row mb-4'>
                  <label className='col-lg-4 fw-semibold text-muted'>Status</label>
                  <div className='col-lg-8'>
                    <span className='fs-6 text-dark text-hover-primary default-cursor'>
                      Verified
                    </span>
                  </div>
                </div>
                <div className='row mb-4'>
                  <label className='col-lg-4 fw-semibold text-muted'>View Portal</label>
                  <div className='col-lg-8'>
                    <button
                      className='btn btn-sm btn-light-primary btn-ripple'
                      onClick={() => {
                        const data: any = {
                          pod_code: pod_code,
                          pod_id: podId,
                          user_id: user_id,
                          auth_token: auth_token,
                          brandName: brandName,
                          orgName: orgName,
                          podLogo: podLogo,
                        }
                        const query = new URLSearchParams(data).toString()
                        window.location.href = `https://careers-fjnmpqbo5-kloudworx.vercel.app/?${query}`
                      }}
                    >
                      View
                      <KTIcon iconName='arrow-right' className='fs-4 me-1 ms-2' />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xl-6'>
            <div className='card card-xl-stretch'>
              <div className='card-header'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-semibold fs-3 mb-1'>Onboarding Portal</span>
                </h3>
              </div>
              <div className='card-body'>
                <div className='row mb-4'>
                  <label className='col-lg-4 fw-semibold text-muted'>Onboarding URL</label>
                  <div className='col-lg-8'>
                    <span className='fs-6 text-dark text-hover-primary default-cursor'>
                      https://onboarding.jetboard.site/{pod_code}/
                    </span>
                  </div>
                </div>
                <div className='row mb-4'>
                  <label className='col-lg-4 fw-semibold text-muted'>Status</label>
                  <div className='col-lg-8'>
                    <span className='fs-6 text-dark text-hover-primary default-cursor'>
                      Verified
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xl-6 mt-0'>
            <div className='card card-xl-stretch'>
              <Formik
                initialValues={{
                  domain_name: '' || domainsInfo?.domain_name,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
              >
                {({values, resetForm}) => {
                  return (
                    <Form>
                      <div className='card-header'>
                        <h3 className='card-title align-items-start flex-column'>
                          <span className='card-label fw-semibold fs-3 mb-1'>Domain Activity</span>
                        </h3>
                        <div className='card-toolbar'>
                          {domainsInfo?.is_verified === 1 ? (
                            !isEditMode ? (
                              domainsInfo?.branding_type === 'sys' && (
                                <button
                                  className='btn btn-sm btn-light-primary btn-ripple'
                                  onClick={handleEditClick}
                                >
                                  <KTIcon iconName='pencil' className='fs-7' />
                                  Change Domain
                                </button>
                              )
                            ) : (
                              <div className='d-flex'>
                                <button className='btn btn-light-primary btn-sm' type='submit'>
                                  <KTIcon iconName='check' className='fs-7' />
                                  Save
                                </button>
                                <div
                                  className='btn btn-sm btn-light ms-2 w-30px d-flex justify-content-center align-items-center'
                                  onClick={() => handleCancelClick()}
                                >
                                  <KTIcon iconName='cross' className='fs-3 text-start' />
                                </div>
                              </div>
                            )
                          ) : (
                            <button
                              className='btn btn-light-primary btn-sm'
                              onClick={handleDomainVerify}
                            >
                              Domain Verify
                            </button>
                          )}
                        </div>
                      </div>
                      <div className='card-body'>
                        <div className='row mb-4'>
                          <label className='col-lg-4 fw-semibold text-muted'>Domain Name</label>
                          <div className='col-lg-8'>
                            {isEditMode ? (
                              <>
                                <Field
                                  type='text'
                                  className='form-control form-control-solid mb-1'
                                  id='floatingInput'
                                  placeholder='Enter Domain Name'
                                  name='domain_name'
                                />
                                <ErrorMessage
                                  component='div'
                                  className='text-danger'
                                  name='domain_name'
                                />
                              </>
                            ) : (
                              <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                {domainsInfo?.domain_name
                                  ? domainsInfo?.domain_name
                                  : 'devx.jetboard.site'}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className='row mb-4'>
                          <label className='col-lg-4 fw-semibold text-muted'>
                            Verification Code
                          </label>
                          <div className='col-lg-8'>
                            <div className='border border-dashed border-gray-300 rounded bg-light-primary p-3 d-flex'>
                              <span className='fs-6 text-dark default-cursor fw-normal'>
                                {domainsInfo
                                  ? domainsInfo?.domain_code
                                  : 'jetboard-verification-code=86bc5c-82cb9239-f7f0-11ee-a9af-560004cb7737'}
                              </span>
                              <button
                                className='btn btn-icon btn-sm'
                                onClick={() => {
                                  navigator.clipboard
                                    .writeText(domainsInfo?.domain_code)
                                    .then(() => {
                                      setCopied(true)
                                      UseToast(
                                        'Copied to clipboard!',
                                        {
                                          position: 'bottom-right',
                                          theme: 'colored',
                                          autoClose: true,
                                        },
                                        'success'
                                      )
                                      setTimeout(() => setCopied(false), 2000)
                                    })
                                    .catch((err) => {
                                      console.error('Failed to copy: ', err)
                                    })
                                }}
                                title={copied ? 'Copied' : 'Copy to clipboard'}
                              >
                                {copied ? (
                                  <KTIcon iconName='double-check' className='fs-4 text-success' />
                                ) : (
                                  <KTIcon iconName='copy' className='fs-4' />
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className='row mb-4'>
                          <label className='col-lg-4 fw-semibold text-muted'>Status</label>
                          <div className='col-lg-8'>
                            <span className='fs-6 text-dark text-hover-primary default-cursor'>
                              {domainsInfo?.is_verified === 1 ? 'Verified' : 'Unverified'}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
          <div className='col-xl-6 mt-0'>
            {senderInfo ? (
              <div className='card card-xl-stretch pb-9'>
                <div className='card-header'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-semibold fs-3 mb-1'>Sender</span>
                  </h3>
                </div>
                <div className='card-body'>
                  <div className='row mb-4'>
                    <label className='col-lg-4 fw-semibold text-muted'>Sender Name</label>
                    <div className='col-lg-8'>
                      <span className='fs-6 text-dark text-hover-primary default-cursor'>
                        jetboard HR
                      </span>
                    </div>
                  </div>
                  <div className='row mb-4'>
                    <label className='col-lg-4 fw-semibold text-muted'>Sender Email</label>
                    <div className='col-lg-8'>
                      <span className='fs-6 text-dark text-hover-primary default-cursorr'>
                        kloudworx@jetboard.cloud
                      </span>
                    </div>
                  </div>
                  <div className='row mb-4'>
                    <label className='col-lg-4 fw-semibold text-muted'>Status</label>
                    <div className='col-lg-8'>
                      <span className='fs-6 text-dark text-hover-primary default-cursor'>
                        Active
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='card card-xl-stretch pb-9'>
                <div
                  className='card-body d-flex flex-column justify-content-between bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0'
                  style={{
                    backgroundPosition: '100% 50%',
                    backgroundImage: `url('${toAbsoluteUrl('/media/stock/900x600/42.png')}')`,
                  }}
                >
                  {domainsInfo?.is_verified === 1 ? (
                    <div className='mb-4'>
                      <div className='fs-3 fw-semibold text-gray-800 text-center'>
                        <span className='me-2'>
                          Click on the below button to Creat the Senders.
                        </span>
                      </div>
                      <div className='m-auto d-flex flex-column align-items-center mt-2'>
                        <div
                          className='btn btn-sm btn-light-primary fw-normal'
                          onClick={handleOpenModal}
                        >
                          <KTIcon iconName='plus' className='fs-3' />
                          Add Senders
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='mb-6'>
                      <div className='fs-3 fw-semibold text-gray-800 text-center'>
                        <span className='me-2'>
                          Please verify your domain first,
                          <br />
                        </span>
                        to Create Senders.
                      </div>
                    </div>
                  )}
                  <img
                    className='mx-auto h-150px h-lg-165px  theme-light-show'
                    src={toAbsoluteUrl('/media/illustrations/misc/upgrade.svg')}
                    alt=''
                  />
                  <img
                    className='mx-auto h-150px h-lg-165px  theme-dark-show'
                    src={toAbsoluteUrl('/media/illustrations/misc/upgrade-dark.svg')}
                    alt=''
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <CreateSenders
        showModal={showModal}
        setShowModal={setShowModal}
        fetchData={fetchData}
        closeModal={handleCloseModal}
        className='modal-backdrop'
      />
    </>
  )
}

export default DomainInfo
