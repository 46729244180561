import {useMemo, useState, useEffect} from 'react'
import {Pagination} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
// import {Link} from 'react-router-dom'
import {useTable, usePagination} from 'react-table'
import {Link} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import usePageTitle from '../custom_hooks/usePageTitle'
import InvitesKMModal from '../know_more_modals/invites_KM/InvitesKMModal'
import usePostApi from '../custom_hooks/usePostApi'
import DateFormatter from '../DateFormatter'
import {UseToast} from '../useToast'
import {useIntl, FormattedMessage} from 'react-intl'

const DataTable = ({columns, data, isLoading, fetchData, refresh}) => {
  const intl = useIntl()
  const [showModal, setShowModal] = useState(false)
  usePageTitle('My Invites')
  const myInvites = [
    {
      title: 'My Account',
      path: `/my-invites`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: ``,
      path: `/my-invites`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `My Invites`,
      path: `/my-invites`,
      isSeparator: false,
      isActive: false,
    },
  ]

  const [inputVal, setInputVal] = useState('')
  const [currentStatus, setCurrentStatus] = useState('I')

  const filteredData = useMemo(() => {
    return data
      ? data.filter((each) => {
          const statusName =
            each.status === 'I'
              ? 'Pending'
              : each.status === 'A'
              ? 'Accepted'
              : each.status === 'R'
              ? 'Rejected'
              : each.status === 'Expired'

          const nameMatches =
            each.invited_by_name &&
            each.invited_by_name.toLowerCase().includes(inputVal.toLowerCase())
          const emailMatches =
            each.invited_by_email &&
            each.invited_by_email.toLowerCase().includes(inputVal.toLowerCase())
          const podNameMatches =
            each.pod_business_name &&
            each.pod_business_name.toLowerCase().includes(inputVal.toLowerCase())
          const statusMatches =
            each.status && each.status.toLowerCase().includes(inputVal.toLowerCase())
          const roleMatches =
            each.role_name && each.role_name.toLowerCase().includes(inputVal.toLowerCase())
          const statusNameMatches =
            statusName && statusName.toLowerCase().includes(inputVal.toLowerCase())
          const currentStatusMatches =
            each.status && each.status.toLowerCase() === currentStatus.toLowerCase()

          // return nameMatches || emailMatches || podNameMatches || statusMatches || roleMatches

          return (
            (nameMatches ||
              emailMatches ||
              podNameMatches ||
              statusMatches ||
              roleMatches ||
              statusNameMatches) &&
            currentStatusMatches
          )
        })
      : []
  }, [inputVal, data, currentStatus])

  const tableInstance = useTable({columns, data: filteredData}, usePagination)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: {pageIndex, pageSize},
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    gotoPage,
    setPageSize,
  } = tableInstance

  const pageOptions = [10, 20, 30, 40, 50]

  return (
    <>
      <InvitesKMModal showModal={showModal} setShowModal={setShowModal} />
      <PageTitle breadcrumbs={myInvites}>My Invites</PageTitle>

      {isLoading && refresh === false ? (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : data && data.length > 0 ? (
        <>
          <div className='d-flex flex-wrap flex-stack '>
            <div className='d-flex'>
              <h1 className='fs-3 fw-semibold'>Manage Invites</h1>
            </div>
            <div className='d-flex'>
              <div className='m-auto d-flex flex-column align-items-center'>
                <button
                  className='btn btn-sm btn-light fw-normal'
                  onClick={() => setShowModal(true)}
                >
                  <i className='ki-duotone ki-information-3 fs-3'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                  Know More
                </button>
              </div>
            </div>
          </div>
          <p className='fs-6 text-gray-600 mb-4'>
            Easily manage invite requests, allowing you to accept or reject them with no details
            overlooked in the process.
          </p>
          <div className='alert alert-success d-flex align-items-center p-1 mt-5'>
            <KTIcon iconName='information' className='fs-2 text-success me-2' />
            <div className='d-flex flex-column'>
              <h6 className='mb-0 fw-semibold fs-7 text-dark'>Note:</h6>
              <span className='fs-8 text-dark'>
                Invites that have already been accepted are accessible from the{' '}
                <Link to={`/my-pods`}>“My PODs”</Link> section.{' '}
              </span>
            </div>
          </div>

          {refresh ? (
            <div className='card h-250px'>
              <div className='m-auto d-flex flex-column align-items-center'>
                <div className='spinner-border spinner-primary mr-15'></div>
              </div>
            </div>
          ) : (
            <div className='card py-2 px-10'>
              <div className='card-header border-0 px-0'>
                <h3 className='card-title align-items-start flex-column'>
                  <ul className='nav'>
                    <li className='nav-item'>
                      <a
                        className={`nav-link btn btn-sm btn-color-muted ${
                          currentStatus === 'I' ? 'active btn-active-light-primary' : ''
                        } px-4 me-1`}
                        onClick={() => setCurrentStatus('I')}
                      >
                        Pending
                      </a>
                    </li>
                    {/* <li className='nav-item'>
                    <a
                      className={`nav-link btn btn-sm btn-color-muted ${
                        currentStatus === 'A' ? 'active btn-active-light-success' : ''
                      } px-4 me-1`}
                      onClick={() => setCurrentStatus('A')}
                    >
                      Accepted
                    </a>
                  </li> */}
                    <li className='nav-item'>
                      <a
                        className={`nav-link btn btn-sm btn-color-muted ${
                          currentStatus === 'R' ? 'active btn-active-light-danger' : ''
                        } px-4 me-1`}
                        onClick={() => setCurrentStatus('R')}
                      >
                        Rejected
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className={`nav-link btn btn-sm btn-color-muted ${
                          currentStatus === 'E' ? 'active btn-active-light-info' : ''
                        } px-4 me-1`}
                        onClick={() => setCurrentStatus('E')}
                      >
                        Expired
                      </a>
                    </li>
                  </ul>
                </h3>
                <div className='card-toolbar'>
                  <div className='d-flex align-items-center position-relative'>
                    <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                    <input
                      type='search'
                      id='kt_filter_search'
                      className='form-control form-control-white form-control-sm w-150px ps-9'
                      placeholder='Search'
                      value={inputVal}
                      onChange={(e) => setInputVal(e.target.value)}
                    />
                  </div>
                  <button
                    className='btn btn-sm btn-light-primary fw-normal mb-1 mb-md-0 ms-4'
                    onClick={() => fetchData(true)}
                    data-bs-toggle='tooltip'
                    title='Click to load the latest data.'
                  >
                    <i className='ki-solid ki-arrows-circle fs-2'></i>
                  </button>
                </div>
              </div>

              <div className='mb-0 mb-xl-8'>
                <div className='overflow-auto'>
                  <table
                    className='table table-row-dashed table-row-gray-300 align-middle'
                    {...getTableProps()}
                  >
                    <thead>
                      <tr className='fw-semibold text-muted default-cursor bg-light rounded'>
                        <th className='min-w-140px ps-3'>Name</th>
                        <th className='min-w-140px'>Pod Name</th>
                        <th className='min-w-140px w-150px'>Status</th>
                        <th className='min-w-140px'>Role & Expiry</th>
                        <th className={`min-w-140px text-center pe-3`}>Action</th>
                      </tr>
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.length > 0 ? (
                        page.map((row) => {
                          prepareRow(row)
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => (
                                <td className='text-center' {...cell.getCellProps()}>
                                  {cell.render('Cell')}
                                </td>
                              ))}
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td className='' colSpan={5}>
                            <div className='d-flex justify-content-center'>
                              <p className='text-dark default-cursor d-block fs-6 m-0 my-5 ps-3'>
                                {inputVal === '' ? (
                                  intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
                                ) : (
                                  <FormattedMessage
                                    id='MSG.NO_DATA_MATCH'
                                    values={{
                                      inputVal: <span className='text-primary'>{inputVal}</span>,
                                    }}
                                  />
                                )}
                              </p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              {data.length > 10 && (
                <Pagination>
                  <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                  <Pagination.Prev onClick={previousPage} disabled={!canPreviousPage} />

                  {[...Array(pageCount)].map((_, i) => (
                    <Pagination.Item key={i} active={i === pageIndex} onClick={() => gotoPage(i)}>
                      {i + 1}
                    </Pagination.Item>
                  ))}

                  <Pagination.Next onClick={nextPage} disabled={!canNextPage} />
                  <Pagination.Last
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  />

                  <div className='d-flex align-items-center mx-5'>
                    <span className='me-2'>Show</span>
                    <select
                      className='form-select form-select-solid fw-bold w-75px me-2'
                      value={pageSize}
                      onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                      {pageOptions.map((option) => (
                        <option key={option} value={option} className='select-bg'>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </Pagination>
              )}
            </div>
          )}
        </>
      ) : (
        <div className='card min-h-250px d-flex justify-content-center align-items-center'>
          {/* <div className='d-flex flex-column justify-content-center align-items-center'>
            <img className='mb-3' style={{width: '200px'}} src={NoInvitationsIcon} />
            <h3 className='fs-3 fw-semibold'>No invitation requests found.</h3>
          </div> */}
          {/* <InvitesContent /> */}
          <h3 className='fs-3 fw-semibold'>No invitation requests found.</h3>
          <button className='btn btn-light-primary btn-sm' onClick={() => fetchData()}>
            <KTIcon iconName='arrows-circle' className='fs-4' />
            Reload
          </button>
        </div>
      )}
    </>
  )
}

const MyInvitesTable = () => {
  const {execute, loading} = usePostApi()

  const [invitationReq, setInvitationReq] = useState([])
  const [refresh, setRefresh] = useState(false)

  const getInvitationReq = async (show) => {
    if (show) {
      setRefresh(true)
    }
    try {
      const url = '/pod/myinvites/list'

      const response = await execute(url, 'POST')
      setInvitationReq(response.data)
      setRefresh(false)
      if (show) {
        return UseToast(
          response.status_message,
          {
            position: 'bottom-right',
            theme: 'colored',
            autoClose: true,
          },
          'success'
        )
      }
    } catch (error) {
      console.error('Error:', error)
      setRefresh(false)
      if (show) {
        return UseToast(
          error.response?.data?.status_message,
          {
            position: 'bottom-right',
            theme: 'colored',
            autoClose: true,
          },
          'error'
        )
      }
    }
  }

  useEffect(() => {
    getInvitationReq()
  }, [])

  const getStatusName = (status) => {
    switch (status) {
      case 'I':
        return 'Pending'
      case 'A':
        return 'Accepted'
      case 'R':
        return 'Rejected'
      default:
        return 'Expired'
    }
  }

  const handleReq = async (data, action) => {
    try {
      const url = '/pod/members/invite/action'
      const formData = {
        pod_id: data.member_pod_id,
        invite_id: data.invite_id,
        invite_action: action,
      }
      const response = await execute(url, 'POST', formData)
      if (response.status === true) {
        getInvitationReq()
      }

      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
    } catch (error) {
      console.error('Error:', error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({row}) => {
          return (
            <div className='d-flex justify-content-start flex-column text-start ps-3'>
              <p className='text-dark fw-semibold text-hover-primary mb-1 fs-6 default-cursor'>
                {row.original?.invited_by_name}
              </p>
              <span className='text-muted text-muted d-block fs-7 default-cursor'>
                {row.original?.invited_by_email}
              </span>
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'DateOfBirth',
        accessor: 'dateOfBirth',
        Cell: ({row}) => {
          return (
            <p className='text-dark text-hover-primary default-cursor d-block fs-6 text-start'>
              {row.original?.pod_business_name}
            </p>
          )
        },
      },
      {
        Header: 'Age',
        accessor: 'age',
        Cell: ({row}) => {
          return (
            <p className='text-dark text-hover-primary default-cursor d-block fs-6 text-start w-150px'>
              {getStatusName(row.original?.status)}
            </p>
          )
        },
      },
      {
        Header: 'Role',
        accessor: 'role',
        Cell: ({row}) => {
          return (
            <div className='d-flex justify-content-start flex-column text-start'>
              <p className='text-dark text-hover-primary mb-1 fs-6 default-cursor'>
                {row.original?.role_name}
              </p>
              {row.original.expiry_ts && (
                <span className='text-muted text-muted d-block fs-8 default-cursor'>
                  {row.original.expiry_ts ? DateFormatter(row.original.expiry_ts) : 'N.A.'}
                  {row.original.expiry_ts && ', '}

                  {row.original.expiry_ts &&
                    row.original.expiry_ts.length >= 16 &&
                    row.original.expiry_ts.slice(11, 16)}
                </span>
              )}
            </div>
          )
        },
      },
      {
        Header: 'RequireVisa',
        accessor: 'requireVisa',
        Cell: ({row}) => {
          return (
            <div className='text-center'>
              {row.original.status === 'I' ? (
                <>
                  <button
                    className='btn btn-icon btn-light-success btn-sm me-1 mb-1 mb-md-0'
                    onClick={() => handleReq(row.original, 'A')}
                    data-bs-toggle='tooltip'
                    title='Accept pod join request.'
                  >
                    <KTIcon iconName='check' className='fs-2' />
                  </button>
                  <button
                    className='btn btn-icon btn-light-danger btn-sm me-1'
                    onClick={() => handleReq(row.original, 'R')}
                    data-bs-toggle='tooltip'
                    title='Reject pod join request.'
                  >
                    <KTIcon iconName='cross' className='fs-2' />
                  </button>
                </>
              ) : (
                'N.A.'
              )}
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <DataTable
      columns={columns}
      data={invitationReq}
      isLoading={loading}
      fetchData={getInvitationReq}
      refresh={refresh}
    />
  )
}

export default MyInvitesTable
