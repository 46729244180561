import {useState} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import {motion, AnimatePresence} from 'framer-motion'
import * as Yup from 'yup'
import {KTIcon} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {resetPassword} from '../core/_requests'
import usePostApi from '../../custom_hooks/usePostApi'

const step3Schema = Yup.object().shape({
  newPassword: Yup.string()
    .required('New Password is required')
    .min(8, 'Password must be 8 characters'),
  confirmPassword: Yup.string()
    .test('passwords-match', 'Passwords must match', function (this: any, value: any) {
      return this.parent.newPassword === value
    })
    .required('Confirm New Password is required'),
})

const initialValues = {
  newPassword: '',
  confirmPassword: '',
}

function ResetPwViaLink() {
  const {execute} = usePostApi()
  const [resetSuccess, setResetSuccess] = useState<boolean>(false)
  const [statusMsg, setStatusMsg] = useState('')

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const u: string = searchParams?.get('u') || ''
  const t: string = searchParams?.get('t') || ''
  const [showNewPW, setNewPW] = useState(false)
  const [showConfirmPW, setConfirNewPW] = useState(false)

  const toggleNewPWVisibility = () => {
    setNewPW(!showNewPW)
  }

  const toggleConfirmPWVisibility = () => {
    setConfirNewPW(!showConfirmPW)
  }

  const scrambleX = (str: string, numTimes = 2) => {
    let encoded = str
    for (let i = 0; i < numTimes; i++) {
      encoded = btoa(encoded)
    }
    return encoded
  }

  const onSubmit = async (values: any) => {
    try {
      const url = '/auth/reset'
      const formData = {
        verify_user: u,
        verify_token: t,
        verify_type: 'LNK',
        new_password: scrambleX(values.confirmPassword),
      }

      const response = await execute(url, 'POST', formData)
      // const {data: auth} = await resetPassword(u, t, 'LNK', values.confirmPassword)
      setResetSuccess(true)
    } catch (error: any) {
      console.error(error)
      if (error.response) {
        const errorMessage = error.response.data.status_message
        setStatusMsg(errorMessage)
      } else {
        console.error('An unexpected error occurred:', error)
      }
      // saveAuth(undefined)
    }
  }
  return (
    <AnimatePresence mode='wait'>
      <motion.div
        // key={selectedTab ? selectedTab.label : 'empty'}
        initial={{y: 10, opacity: 0}}
        animate={{y: 0, opacity: 1}}
        exit={{y: -10, opacity: 0}}
        transition={{duration: 0.5}}
      >
        {resetSuccess ? (
          <div className='d-flex flex-column align-items-center'>
            <KTIcon iconName='verify' className='verify-icon mb-5' />
            <h3 className='text-dark fw-semibold mb-3'>Password reset successful!</h3>
            <div className='text-gray-500 text-center fw-semibold fs-6 mb-3'>
              You can now login with your new password.
            </div>
            <Link to='/auth/login' className='btn btn-primary mt-3'>
              Back to Login
            </Link>
          </div>
        ) : (
          <Formik validationSchema={step3Schema} initialValues={initialValues} onSubmit={onSubmit}>
            <Form className='min-w-350px'>
              <div className='text-center mb-10'>
                <h1 className='text-dark fw-semibold'>Reset Password</h1>
              </div>
              <div className='fv-row mb-8'>
                <p className='mb-0 form-label fs-6 fw-semibold text-dark'>Password</p>
                <div className='position-relative'>
                  <Field
                    name='newPassword'
                    type={showNewPW ? 'text' : 'password'}
                    autoFocus
                    className='form-control pr-10'
                    placeholder='New Password'
                  />
                  <span onClick={toggleNewPWVisibility}>
                    <KTIcon iconName={showNewPW ? 'eye-slash' : 'eye'} className='fs-2 icon' />
                  </span>
                </div>
                <ErrorMessage name='newPassword' component='div' className='text-danger' />
              </div>

              <div className='fv-row mb-8'>
                <p className='mb-0 form-label fs-6 fw-semibold text-dark'>Confirm Password</p>
                <div className='position-relative'>
                  <Field
                    name='confirmPassword'
                    type={showConfirmPW ? 'text' : 'password'}
                    className='form-control pr-10'
                    placeholder='Confirm New Password'
                  />
                  <span onClick={toggleConfirmPWVisibility}>
                    <KTIcon iconName={showConfirmPW ? 'eye-slash' : 'eye'} className='fs-2 icon' />
                  </span>
                </div>
                <ErrorMessage name='confirmPassword' component='div' className='text-danger' />
              </div>

              <div className='d-flex flex-wrap justify-content-center pb-lg-0 mt-10'>
                <button
                  type='submit'
                  id='kt_password_reset_submit'
                  className='btn btn-primary me-4'
                >
                  <span className='indicator-label'>Submit</span>
                </button>
                <Link to='/auth/login'>
                  <button type='button' className='btn btn-light'>
                    Cancel
                  </button>
                </Link>{' '}
              </div>
              {statusMsg && (
                <div className='alert border border-dashed border-gray-300 rounded px-5 py-3 badge-light-danger d-flex align-items-center p-2 mt-4 d-flex flex-row align-items-center'>
                  <KTIcon iconName='information' className='fs-3 text-danger me-1' />
                  <span className='fs-6 text-dark'>{statusMsg}</span>
                </div>
              )}
            </Form>
          </Formik>
        )}
      </motion.div>
    </AnimatePresence>
  )
}

export default ResetPwViaLink
