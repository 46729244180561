import React, {useState} from 'react'
import {useParams} from 'react-router-dom'
import {useLocation} from 'react-router-dom'

import {PageTitle} from '../../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import CandidatesTable from './CandidatesTable'
import Context from '../../side-drawer/context'
import CandidateInterviewsTable from './candidate_history/candidate_interviews/CandidateInterviewsTable'
import CandidatateHistory from '../../onboardings/emp_details/CandidatateHistory'
import CandidateHistoryRouting from './candidate_history/CandidateHistoryRouting'
function CandidateDataRouting() {
  const {id} = useParams()
  const intl = useIntl()
  const {pathname} = useLocation()
  const [SideDrawerId, updateId] = useState('0')

  const UpdatingId = (sidedrawerid) => {
    updateId(sidedrawerid)
  }
  const candidateAttachments = [
    {
      title: `(${id})`,
      path: `/${id}/candidates`,
      isSeparator: false,
      isActive: false,
    },
  ]
  const switchMethod = () => {
    switch (pathname) {
      case `/${id}/candidates`:
        return (
          <>
            <PageTitle breadcrumbs={candidateAttachments}>Candidates</PageTitle>
            <CandidatesTable />
          </>
        )
      case `/${id}/candidates/history`:
        return (
          <>
            <PageTitle breadcrumbs={candidateAttachments}>Interviews</PageTitle>
            <CandidateHistoryRouting />
          </>
        )
      // case '/travel/team-travel':
      //   return (
      //     <>
      //       <PageTitle breadcrumbs={TravelBreadCrumbs}>
      //         {intl.formatMessage({id: 'MENU.TRAVEL.TEAM_TRAVEL'})}
      //       </PageTitle>
      //       <TeamTrips />
      //     </>
      //   )

      default:
        return (
          <>
            <PageTitle breadcrumbs={candidateAttachments}>Candidates</PageTitle>
            <CandidatesTable />
          </>
        )
    }
  }

  return (
    <>
      <Context.Provider value={{id: SideDrawerId, updateId: UpdatingId}}>
        {switchMethod()}
      </Context.Provider>
    </>
  )
}

export default CandidateDataRouting
