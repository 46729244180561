import {useEffect, useState} from 'react'
import {useThemeMode} from '../../../../../_metronic/partials'

export default function LocationSearchInput({
  setFieldValue,
  id,
  isValid,
  isInValid,
  type,
  value,
  sameAsCurrentAdd,
}) {
  const [searchQuery, setSearchQuery] = useState('')
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const {mode} = useThemeMode()

  const decodeFromBase64 = (data) => {
    if (!data) return {}
    try {
      const jsonString = atob(data)
      return JSON.parse(jsonString)
    } catch (error) {
      console.error('Failed to decode and parse base64 string:', error)
      return {}
    }
  }

  const encodeToBase64 = (data) => {
    try {
      const jsonString = JSON.stringify(data)
      return btoa(jsonString)
    } catch (error) {
      console.error('Failed to encode object to base64 string:', error)
      return null
    }
  }

  const fetchSuggestions = async (query) => {
    const accessToken =
      'pk.eyJ1IjoiZ2FuZXN3YXJhcmFvIiwiYSI6ImNsdHBpeXJtZjA2eWkya28xcW8xbjg4dXcifQ.2NgizzxlIv7hziFTq6JmgQ'
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
      query
    )}.json?access_token=${accessToken}&types=country,region,place,district,locality,postcode,neighborhood`
    try {
      const response = await fetch(url)
      const data = await response.json()
      return data.features || []
    } catch (error) {
      console.error('Error fetching suggestions:', error)
      return []
    }
  }

  const handleSuggestionClick = (feature) => {
    setSearchQuery(feature.place_name)
    const encodedVal = encodeToBase64(feature)
    setFieldValue(id, encodedVal)
    if (sameAsCurrentAdd && type === 'curr_address') {
      setFieldValue('per_address', encodedVal)
    }
    setShowSuggestions(false)

    const state =
      feature.context.find((contextItem) => contextItem.id.startsWith('region'))?.text || ''
    const postcode =
      feature.context.find((contextItem) => contextItem.id.startsWith('postcode'))?.text || ''
    const district =
      feature.context.find((contextItem) => contextItem.id.startsWith('district'))?.text || ''
    const country =
      feature.context.find((contextItem) => contextItem.id.startsWith('country'))?.text || ''

    const addressType = type === 'curr_address' ? 'curr' : 'per'
    setFieldValue(`${addressType}_city`, district)
    setFieldValue(`${addressType}_state`, state)
    setFieldValue(`${addressType}_country`, country)
    if (postcode) {
      setFieldValue(`${addressType}_pincode`, postcode)
    }
  }

  useEffect(() => {
    const val = decodeFromBase64(value)
    setSearchQuery(val.place_name || '')
  }, [value])

  useEffect(() => {
    const fetchAndSetSuggestions = async () => {
      if (searchQuery.trim()) {
        const suggestions = await fetchSuggestions(searchQuery)
        setSuggestions(suggestions)
      } else {
        setSuggestions([])
      }
    }

    fetchAndSetSuggestions()
  }, [searchQuery])

  return (
    <div style={{position: 'relative'}}>
      <div className='input-group'>
        <input
          type='search'
          value={searchQuery}
          placeholder='Search city, state or country'
          onChange={(e) => {
            setSearchQuery(e.target.value)
            setShowSuggestions(true)
          }}
          className={`form-control ${isValid(id) ? 'is-valid' : isInValid(id) ? 'is-invalid' : ''}`}
        />
      </div>
      {showSuggestions && suggestions.length > 0 && (
        <div
          style={{
            maxHeight: '400px',
            overflowY: 'auto',
            backgroundColor: mode === 'dark' ? '#1e1e2d' : 'white',
            borderRadius: '5px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            position: 'absolute',
            zIndex: 1,
            width: '100%',
            color: mode === 'dark' ? '#92929f' : '#4b5675',
          }}
        >
          {suggestions.map((feature, index) => (
            <div
              key={index}
              style={{
                padding: '10px',
                cursor: 'pointer',
                borderBottom: `1px solid ${mode === 'dark' ? '#92929f' : '#ddd'}`,
              }}
              className='text-hover-primary'
              onClick={() => handleSuggestionClick(feature)}
            >
              {feature.place_name}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
