import React, {useState, useEffect} from 'react'
import {Link, Outlet, useLocation, useParams} from 'react-router-dom'
import DateFormatter from '../../../DateFormatter'
import usePostApi from '../../../custom_hooks/usePostApi'
import {PageTitle} from '../../../../../_metronic/layout/core'
function OfferStep1() {
  const {execute, loading} = usePostApi()
  const {id, job_offer_id} = useParams()

  const [candidatesData, setcandidatesData] = useState([null])

  const fetchData = async () => {
    try {
      const url = `/pod/job_offers/get`
      const formData = {
        job_offer_id: job_offer_id,
      }

      const response = await execute(url, 'POST', formData)
      setcandidatesData(response.data)
      console.log(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const userData = candidatesData ? candidatesData[0] : null
  const policyBC = [
    {
      title: `(${id})`,
      path: `/${id}/offers`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: ``,
      path: `/${id}/offers`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `Offers`,
      path: `/${id}/offers`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: ``,
      path: `/${id}/offers`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: candidatesData[0]
        ? candidatesData[0].candidate_first_name + ' ' + candidatesData[0].candidate_last_name
        : '',
      path: `/${id}/offers/info/${job_offer_id}`,
      isSeparator: false,
      isActive: false,
    },
  ]
  //   console.log(props.data)
  return (
    <>
      <PageTitle breadcrumbs={policyBC}>Candidate Offer Info</PageTitle>
      <>
        <div className='card p-10 w-100'>
          <h3 className='mb-3 text-primary'>
            <p
              className='alert bg-light text-center text-muted'
              style={{
                width: '100%',
                verticalAlign: 'middle',
                fontSize: '12px',
                textTransform: 'uppercase',
              }}
            >
              <span className='fw-semibold'>Offer Info</span>
            </p>
          </h3>
          <div className='min-w-100 d-flex m-auto'>
            <div className='w-50 row mb-5'>
              <label className='col-lg-4 fw-semibold text-muted ms-2'>Selected Role</label>

              <div className='w-50 col-lg-8'>
                <span className='fs-6 text-dark text-hover-primary default-cursor'>
                  {userData?.job_title_name}
                </span>
              </div>
            </div>
            <div className='w-50 row mb-5'>
              <label className='col-lg-4 fw-semibold text-muted'>Location</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark text-hover-primary default-cursor'>
                  {userData?.location_name} {','}
                  {userData?.address}
                </span>
              </div>
            </div>
          </div>
          <div className='w-100 d-flex m-auto'>
            <div className='w-50 row mb-5'>
              <label className='col-lg-4 fw-semibold text-muted ms-2'>Date of Joining</label>

              <div className='w-50 col-lg-8'>
                <span className='fs-6 text-dark text-hover-primary default-cursor'>
                  {DateFormatter(userData?.date_of_joining)}
                </span>
              </div>
            </div>
            <div className='w-50 row mb-5'>
              <label className='col-lg-4 fw-semibold text-muted'>Status</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark text-hover-primary default-cursor'>
                  {userData?.offer_status === 'I'
                    ? 'Issued'
                    : userData?.offer_status === 'A'
                    ? 'Accepted'
                    : userData?.offer_status === 'R'
                    ? 'Rejected'
                    : 'Unknown Status'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  )
}

export default OfferStep1
