import {useState, useEffect, useContext} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../../../../_metronic/helpers'
// import '../.././index.css'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import usePostApi from '../../../../../custom_hooks/usePostApi'
import {useParams} from 'react-router-dom'
import _ from 'lodash'
import CandidateInfoContext from '../../../CandidateInfoContext'

interface Props {
  showModal: boolean
  setShowModal(val: boolean): void
  addQualification: any
  editQualification: any
  type: string
  index: null | number
  initialVals: any
}

function AddNewQualification(props: Props) {
  const {setCorrections, corrections, formValues, updateDataOnDB, setFormData} =
    useContext(CandidateInfoContext)
  const {execute} = usePostApi()
  const {jobJoinCode, pin} = useParams()

  const [qualifications, setQualifications]: any = useState([])
  const getQualifications = async () => {
    try {
      const url = `/qualifications/seeded`
      const formData = {
        onbr_code: jobJoinCode,
        onbr_pin: pin,
      }
      const response = await execute(url, 'POST', formData)
      setQualifications(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getQualifications()
  }, [])
  const currentYear = new Date().getFullYear()

  const formik = useFormik({
    initialValues: {
      id: '',
      educationLevel: '',
      discipline: '',
      school: '',
      yearOfPassing: '',
      mode: '',
      score: '',
      scoreType: '',
    },
    validationSchema: Yup.object({
      educationLevel: Yup.string().required('Required'),
      discipline: Yup.string().required('Required'),
      school: Yup.string().required('Required'),
      yearOfPassing: Yup.string()
        .required('Required')
        .test('is-valid-year', 'Future years not allowed', (value: any) => {
          const year = parseInt(value, 10)
          return year <= currentYear
        }),
      mode: Yup.string().required('Required'),
      score: Yup.number()
        .required('Score is required')
        .typeError('Score must be a number')
        .test('score-range', 'Invalid score', (value: any) => {
          const scoreType = formik.values.scoreType
          if (scoreType === 'Percentage') {
            return value >= 0 && value <= 100
          } else if (scoreType === 'CGPA (5.0)') {
            return value >= 0 && value <= 5
          } else if (scoreType === 'CGPA (10.0)') {
            return value >= 0 && value <= 10
          }
          return true // Default to true if scoreType is invalid
        }),
      scoreType: Yup.string().required('Required'),
    }),
    onSubmit: async (values, {resetForm}) => {
      // const id = values.id
      // console.log(values.id)

      if (props.type === 'edit') {
        const originalVal = formValues._employment_education.qualifications?.filter(
          (each: any) => each.id === formik.values.id
        )
        delete originalVal[0].status

        const currentVal: any = values
        delete currentVal['status']

        // console.log(_.isEqual(originalVal[0], currentVal))
        // console.log(originalVal, currentVal, values)
        // return

        // if (!_.isEqual(props.initialVals, values)) {
        if (!_.isEqual(originalVal[0], values)) {
          const updatedCorrections = {
            ...corrections,
            _employment_education: {
              ...corrections._employment_education,
              qualifications: {
                ...corrections._employment_education.qualifications,
                [values.id]: {...values, status: 'edited'},
              },
            },
          }

          setCorrections(updatedCorrections)
          await updateDataOnDB({...formValues, corrections: updatedCorrections})
        } else {
          setCorrections((prevCorrections: any) => {
            const newCorrections = {...prevCorrections}
            const qualifications = newCorrections._employment_education.qualifications
            const newQualifications = {...qualifications}

            delete newQualifications[values.id]

            newCorrections._employment_education.qualifications = newQualifications
            return newCorrections
          })
          await updateDataOnDB({...formValues, corrections: corrections})
        }
      } else if (props.type === 'editNewQual') {
        await props.editQualification(values)
      } else {
        const iniqueId = '_' + Math.random().toString(36).substr(2, 9)
        const updatedCorrections = {
          ...corrections,
          _employment_education: {
            ...corrections._employment_education,
            qualifications: {
              ...corrections._employment_education.qualifications,
              [iniqueId]: {
                ...values,
                id: iniqueId,
                status: 'New',
              },
            },
          },
        }
        // setCorrections({
        //   ...corrections,
        //   _employment_education: {
        //     ...corrections._employment_education,
        //     qualifications: {
        //       ...corrections._employment_education.qualifications,
        //       [iniqueId]: {
        //         ...values,
        //         id: iniqueId,
        //         status: 'New',
        //       },
        //     },
        //   },
        // })
        await updateDataOnDB({...formValues, corrections: updatedCorrections})
        // props.addQualification(
        //   {...values, id: '_' + Math.random().toString(36).substr(2, 9), status: 'New'},
        //   'qualifications'
        // )
      }
      resetForm()
      props.setShowModal(false)
    },
  })

  // console.log(formik.values)
  // const originalVal = formValues._employment_education.qualifications?.filter(
  //   (each: any) => each.id === formik.values.id
  // )
  // console.log(originalVal)

  useEffect(() => {
    if ((props.type === 'edit' || props.type === 'editNewQual') && props.initialVals) {
      formik.setValues(props.initialVals)
    } else {
      const initialValues = {
        id: '',
        educationLevel: '',
        discipline: '',
        school: '',
        yearOfPassing: '',
        mode: '',
        score: '',
        scoreType: '',
      }
      formik.setValues(initialValues)
    }
  }, [props.type, props.initialVals])

  useEffect(() => {
    if (formik.values.educationLevel === 'Class X (10)') {
      formik.setFieldValue('discipline', 'N.A')
    }
  }, [formik.values.educationLevel])

  const handleInputChange = (event: any, id: string) => {
    const value = event.target.value
    const numericValue = value.replace(/\D/g, '')
    formik.setFieldValue(id, numericValue)
  }

  return (
    <Modal
      show={props.showModal}
      onHide={() => props.setShowModal(false)}
      centered
      dialogClassName='custom-modal modal-dialog-centered'
      contentClassName='custom-modal-content'
      backdrop='static'
    >
      <Modal.Body className='overflow-auto w-100'>
        <div className='d-flex justify-content-between mb-10'>
          <h3 className='fs-3 fw-semibold'>
            {props.type === 'edit' ? 'Edit Qualification' : 'Add Qualification'}
          </h3>
          <button
            type='button'
            className='d-block btn btn-sm btn-icon btn-light'
            onClick={() => props.setShowModal(false)}
          >
            <KTIcon iconName='cross' className='fs-1' />
          </button>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div className=''>
            <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
              <span className='required'>Select Education Level</span>
            </label>
            <div className='mb-4'>
              <select
                className='form-select form-select-solid'
                name='educationLevel'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.educationLevel}
              >
                <option value=''>Select option</option>
                {qualifications?.map((each: any) => (
                  <option key={each.qualification_id} value={each.qualification_name}>
                    {each.qualification_name}
                  </option>
                ))}
              </select>
              {formik.touched.educationLevel && formik.errors.educationLevel ? (
                <div className='text-danger'>{formik.errors.educationLevel}</div>
              ) : null}
            </div>
          </div>
          <div className=''>
            <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
              <span className='required'>Discipline</span>
            </label>
            <div className='mb-4'>
              <input
                id='discipline'
                type='text'
                name='discipline'
                className='form-control form-control-solid'
                placeholder='e.g. Computer Science, Biology etc.'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.discipline}
              />
              {formik.touched.discipline && formik.errors.discipline ? (
                <div className='text-danger'>{formik.errors.discipline}</div>
              ) : null}
            </div>
          </div>
          <div className=''>
            <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
              <span className='required'>School / University</span>
            </label>
            <div className='mb-4'>
              <input
                id='school'
                type='text'
                name='school'
                className='form-control form-control-solid'
                placeholder='School / University'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.school}
              />
              {formik.touched.school && formik.errors.school ? (
                <div className='text-danger'>{formik.errors.school}</div>
              ) : null}
            </div>
          </div>

          <div className='w-100 d-flex flex-column flex-md-row justify-content-between'>
            <div className='w-100 w-md-50 me-md-2'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Year of Passing</span>
              </label>
              <div className='mb-4'>
                <input
                  id='yearOfPassing'
                  type='number'
                  name='yearOfPassing'
                  className='form-control form-control-solid'
                  placeholder='Year of Passing'
                  onChange={(e) => handleInputChange(e, 'yearOfPassing')}
                  onBlur={formik.handleBlur}
                  value={formik.values.yearOfPassing}
                />
                {formik.touched.yearOfPassing && formik.errors.yearOfPassing ? (
                  <div className='text-danger'>{formik.errors.yearOfPassing}</div>
                ) : null}
              </div>
            </div>
            <div className='w-100 w-md-50 ms-md-2'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Mode</span>
              </label>
              <div className='mb-4'>
                <button
                  type='button'
                  className={`btn btn-${
                    formik.values.mode === 'Full-time' ? 'primary' : 'light-primary'
                  } btn-sm rounded-pill me-1`}
                  onClick={() => formik.setFieldValue('mode', 'Full-time')}
                >
                  Full-time
                </button>
                <button
                  type='button'
                  className={`btn btn-${
                    formik.values.mode === 'Part-time' ? 'primary' : 'light-primary'
                  } btn-sm rounded-pill me-1`}
                  onClick={() => formik.setFieldValue('mode', 'Part-time')}
                >
                  Part-time
                </button>
                <button
                  type='button'
                  className={`btn btn-${
                    formik.values.mode === 'Correspondence' ? 'primary' : 'light-primary'
                  } btn-sm rounded-pill`}
                  onClick={() => formik.setFieldValue('mode', 'Correspondence')}
                >
                  Correspondence
                </button>
                {formik.touched.mode && formik.errors.mode ? (
                  <div className='text-danger'>{formik.errors.mode}</div>
                ) : null}
              </div>
            </div>
          </div>
          <div className='w-100 d-flex flex-column flex-md-row justify-content-between'>
            <div className='w-100 w-md-50 me-md-2'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Score</span>
              </label>
              <div className='mb-4'>
                <input
                  id='score'
                  type='number'
                  name='score'
                  className='form-control form-control-solid'
                  placeholder='Score'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.score}
                  onKeyDown={(e) => {
                    if (e.key === 'e' || e.key === 'E') {
                      e.preventDefault()
                    }
                  }}
                />
                {formik.touched.score && formik.errors.score ? (
                  <div className='text-danger'>{formik.errors.score}</div>
                ) : null}
              </div>
            </div>
            <div className='w-100 w-md-50 ms-md-2'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Score-Type</span>
              </label>
              <div className='mb-4'>
                {/* <select
                  className='form-select form-select-solid'
                  name='scoreType'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.scoreType}
                >
                  <option value=''>Select option</option>
                  <option value='Percentage'>Percentage</option>
                  <option value='CGPA (5.0)'>CGPA (5.0)</option>
                  <option value='CGPA (10.0)'>CGPA (10.0)</option>
                </select> */}
                <button
                  type='button'
                  className={`btn btn-${
                    formik.values.scoreType === 'Percentage' ? 'primary' : 'light-primary'
                  } btn-sm rounded-pill me-1`}
                  onClick={() => formik.setFieldValue('scoreType', 'Percentage')}
                >
                  Percentage
                </button>
                <button
                  type='button'
                  className={`btn btn-${
                    formik.values.scoreType === 'CGPA (5.0)' ? 'primary' : 'light-primary'
                  } btn-sm rounded-pill me-1`}
                  onClick={() => formik.setFieldValue('scoreType', 'CGPA (5.0)')}
                >
                  CGPA (5.0)
                </button>
                <button
                  type='button'
                  className={`btn btn-${
                    formik.values.scoreType === 'CGPA (10.0)' ? 'primary' : 'light-primary'
                  } btn-sm rounded-pill`}
                  onClick={() => formik.setFieldValue('scoreType', 'CGPA (10.0)')}
                >
                  CGPA (10.0)
                </button>
                {formik.touched.scoreType && formik.errors.scoreType ? (
                  <div className='text-danger'>{formik.errors.scoreType}</div>
                ) : null}
              </div>
            </div>
          </div>

          <div className='w-100 d-flex justify-content-end mt-5'>
            <button
              type='button'
              className='btn btn-light btn-sm me-2'
              onClick={() => props.setShowModal(false)}
            >
              Close
            </button>

            <button type='submit' className='btn btn-sm btn-light-primary ms-2'>
              <KTIcon iconName='check' className='fs-3 ms-1' />
              {props.type === 'edit' ? 'Update' : 'Add'}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default AddNewQualification
