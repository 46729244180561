import {Dispatch, SetStateAction, useEffect, useRef, useContext} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import {RenderFields} from './RenderFields'
import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import {UseToast} from '../../../useToast'
import {useAuth} from '../../../auth'
import CandidateInfoContext from '../CandidateInfoContext'

type Data = {
  pod_business_name: string
  pod_brand_name: string
  industry_name: string
  pod_team_size: number
  pod_description: string
  country: string
}

interface Step1Props {
  data: any
  dummyData: any
  updateFormValues: any
  postLoading: boolean
  edit: boolean
  closeEditSec: Dispatch<SetStateAction<string>>
  candidateData: any
}

const transformNestedObject1 = (nestedObject: any) => {
  const result: any = {}
  try {
    Object.keys(nestedObject).forEach((key) => {
      result[key] = nestedObject[key].value
    })
    return result
  } catch {
    return {}
  }
}

const Step1 = (props: Step1Props) => {
  const {
    setCorrections: contextSetCorrections,
    corrections: contextCorrections,
    deleteCorrections,
    updateDataOnDB,
  } = useContext(CandidateInfoContext)
  const corrections = contextCorrections['_basic_info']
  const {currentUser} = useAuth()
  const {data} = props

  const formValues1 = transformNestedObject1(data)
  const formValues = transformNestedObject1(data)

  // console.log(props.data)

  const initialValues = formValues || {}

  props.dummyData?.items?.forEach((item: any) => {
    const itemId = item.id

    // Set initial value based on field_type
    if (!initialValues[itemId]) {
      initialValues[itemId] =
        item.field_type === null ? false : item.field_type === 'Separator' ? '_sep_a_ra_tor' : ''
    }

    // Set confirmation value if required
    if (item.require_confirmation && !initialValues[`${itemId}_confirmation`]) {
      initialValues[`${itemId}_confirmation`] = ''
    }

    // Update initialValues with corrections if available
    if (corrections[itemId]) {
      initialValues[itemId] = corrections[itemId].value
    }
  })

  const validationSchema = Yup.object().shape(
    props.dummyData?.items?.reduce((schema: any, item: any) => {
      if (item.is_required) {
        switch (item.field_type) {
          case 'Single Line': {
            let validator = Yup.string()
              .min(
                item?.validations?.min || 0,
                `Minimum ${item?.validations?.min} characters required`
              )
              .max(
                item?.validations?.max || 250,
                `Maximum ${item?.validations?.max} characters allowed`
              )
              .required(`${item.field_name} is required`)

            if (item.validations?.regex) {
              validator = validator.matches(new RegExp(item.validations.regex), 'Invalid format')
            }

            schema[item.id] = validator

            if (item.require_confirmation) {
              schema[`${item.id}_confirmation`] = Yup.string()
                .oneOf([Yup.ref(item.id)], `${item.confirmation_prompt} does not match`)
                .required(`${item.confirmation_prompt} is required`)
            }
            break
          }
          case 'Multi Line': {
            let validator = Yup.string()
              .min(
                item?.validations?.min || 0,
                `Minimum ${item?.validations?.min} characters required`
              )
              .max(
                item?.validations?.max || 1000, // Adjust the max length as needed
                `Maximum ${item?.validations?.max} characters allowed`
              )
              .required(`${item.field_name} is required`)

            if (item.validations?.regex) {
              validator = validator.matches(new RegExp(item.validations.regex), 'Invalid format')
            }

            schema[item.id] = validator
            break
          }
          case 'Numeric': {
            let validator = Yup.number()
              .typeError(`${item.field_name} must be a number`)
              .required(`${item.field_name} is required`)

            if (item.validations?.min !== undefined && item.validations?.min !== null) {
              validator = validator.min(
                item.validations.min,
                `${item.field_name} must be at least ${item.validations.min}`
              )
            }

            if (item.validations?.max !== undefined && item.validations?.max !== null) {
              validator = validator.max(
                item.validations.max,
                `${item.field_name} must be at most ${item.validations.max}`
              )
            }

            schema[item.id] = validator
            break
          }
          case 'Date Time': {
            let validator = Yup.date().required(`${item.field_name} is required`)

            const {allow_past_date, allow_future_date, allow_current_date, min_date, max_date} =
              item.validations || {}

            const today = new Date()
            today.setHours(0, 0, 0, 0) // Reset hours to ensure proper comparison

            if (min_date) {
              validator = validator.min(new Date(min_date), `Date must be after ${min_date}`)
            }

            if (max_date) {
              validator = validator.max(new Date(max_date), `Date must be before ${max_date}`)
            }

            if (allow_past_date === false) {
              const minValidDate = allow_current_date ? today : new Date(today.getTime() + 86400000) // Next day if current date is not allowed
              validator = validator.min(
                minValidDate,
                allow_current_date
                  ? 'Past dates are not allowed except today'
                  : 'Past dates are not allowed'
              )
            }

            if (allow_future_date === false) {
              const maxValidDate = allow_current_date ? today : new Date(today.getTime() - 86400000) // Previous day if current date is not allowed
              validator = validator.max(
                maxValidDate,
                allow_current_date
                  ? 'Future dates are not allowed except today'
                  : 'Future dates are not allowed'
              )
            }

            schema[item.id] = validator
            break
          }

          case 'Dropdown':
            schema[item.id] = Yup.string().required(`${item.field_name} is required`)
            break
          case 'Toggle':
            schema[item.id] = Yup.string()
              .oneOf(['Yes', 'No'])
              .required(`${item.field_name} is required`)
            break
          case 'Media':
            schema[item.id] = Yup.mixed().required(`${item.field_name} is required`)
            break
          default:
            break
        }
      }
      return schema
    }, {})
  )

  const getEditedValues = (currentValues: any) => {
    const initialValues = formValues1
    const editedValues: any = corrections || {}
    const now = new Date()
    const year = now.getFullYear()
    const month = String(now.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
    const day = String(now.getDate()).padStart(2, '0')
    const hours = String(now.getHours()).padStart(2, '0')
    const minutes = String(now.getMinutes()).padStart(2, '0')
    const seconds = String(now.getSeconds()).padStart(2, '0')

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`

    Object.keys(currentValues).forEach((key) => {
      if (currentValues[key] !== initialValues[key]) {
        editedValues[key] = {
          value: currentValues[key],
          by: `${currentUser?.first_name} ${currentUser?.last_name} (${currentUser?.email})`,
          at: formattedDateTime,
        }
      } else if (currentValues[key] === initialValues[key] && editedValues[key]) {
        // If the value is the same, and it exists in editedValues, remove it
        delete editedValues[key]
      }
    })

    return editedValues
  }

  const onSubmit = async (values: any) => {
    const editedValues = getEditedValues(values)
    // console.log('Initial vals ', formValues1)
    // console.log('Corrections', corrections)
    // console.log('candidate Data', props.candidateData)
    // console.log('Total corrections', {
    //   ...contextCorrections,
    //   _basic_info: {...editedValues},
    // })
    // return

    contextSetCorrections({
      ...contextCorrections,
      _basic_info: {...editedValues},
    })

    const corr = {
      ...contextCorrections,
      _basic_info: {...editedValues},
    }
    await updateDataOnDB({
      ...props.candidateData,
      corrections: corr,
    })
    // const corr = {
    //   ...contextCorrections,
    //   _basic_info: {...contextCorrections._basic_info, ...editedValues},
    // }
    // await props.updateFormValues('_basic_info', formValues1, corr)
    // await props.updateFormValues('_basic_info', values)
    props.closeEditSec('personalInfo')
  }

  const initialValuesRef = useRef(formValues1)

  useEffect(() => {
    // Update the ref when initialValues change
    initialValuesRef.current = formValues1
  }, [formValues1])

  return (
    <>
      {props.data && Object.keys(props.data).length > 0 ? (
        <>
          {props.edit ? (
            <div className='d-flex justify-content-between flex-wrap'>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {({setFieldValue, values, errors, touched, isValid}) => {
                  return (
                    <Form className='w-100 d-flex flex-wrap'>
                      {RenderFields(
                        props?.dummyData?.items,
                        setFieldValue,
                        values,
                        errors,
                        touched
                      )}
                      <div className='w-100 d-flex justify-content-center mt-5'>
                        <button
                          className='btn btn-light btn-sm me-2'
                          onClick={() => props.closeEditSec('personalInfo')}
                        >
                          Cancel
                        </button>
                        <button
                          type='submit'
                          className='btn btn-primary btn-md'
                          onClick={() => {
                            if (!isValid) {
                              return UseToast(
                                'Please fill all the mandatory fields.',
                                {
                                  position: 'bottom-right',
                                  theme: 'colored',
                                  autoClose: true,
                                },
                                'error'
                              )
                            }
                          }}
                        >
                          {props.postLoading ? (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              Please wait...{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          ) : (
                            'Save Information'
                          )}
                        </button>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          ) : (
            <>
              {/* {!editMode && (
                <div className='d-flex justify-content-end mb-5'>
                  <button
                    className='btn btn-light-primary btn-sm ms-2'
                    onClick={() => setEditMode(true)}
                  >
                    <KTIcon iconName='pencil' className='fs-5' />
                    Edit
                  </button>
                </div>
              )} */}
              <div className='min-w-100 d-flex justify-content-between flex-wrap m-auto'>
                {props.data &&
                  Object.entries(props.data).map(([key, value]: any, index) => {
                    const {label, value: val} = value
                    const correction = corrections[key]
                    const isDefault = correction ? correction['value'] !== val : false

                    if (val === '_sep_a_ra_tor') {
                      return (
                        <div key={index} className='w-100'>
                          <h3 className='mb-3 text-primary'>
                            <p
                              className='alert bg-light text-center text-muted'
                              style={{
                                width: '100%',
                                verticalAlign: 'middle',
                                fontSize: '12px',
                                textTransform: 'uppercase',
                              }}
                            >
                              <span className='fw-semibold'>{label}</span>
                            </p>
                          </h3>
                        </div>
                      )
                    } else {
                      return (
                        <div key={index} className='w-50 row mb-5 align-items-center'>
                          <label className='col-lg-4 fw-semibold text-muted'>
                            {label.replace(/_/g, ' ')}
                          </label>
                          <div className='col-lg-8'>
                            {correction && isDefault ? (
                              <>
                                <span className='fs-6 default-cursor text-decoration-line-through text-danger'>
                                  {val || '-'}
                                </span>
                                <br />
                                <div className='d-flex align-items-center'>
                                  <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                    {correction?.value || '-'}
                                  </span>
                                  <button
                                    className='btn btn-unstyled m-0 p-0'
                                    data-bs-toggle='tooltip'
                                    title='Revert changes'
                                    onClick={() => deleteCorrections('_basic_info', key)}
                                  >
                                    <KTIcon iconName='cross-circle' className='text-danger' />
                                  </button>
                                </div>
                              </>
                            ) : (
                              <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                {val || '-'}
                              </span>
                            )}
                          </div>
                        </div>
                      )
                    }
                  })}
              </div>
            </>
          )}
        </>
      ) : (
        <div
          className='card d-flex flex-column justify-content-center align-items-center'
          style={{height: '228px'}}
        >
          <h3 className='fw-semibold fs-3'>Candidate data not filled yet.</h3>
        </div>
      )}
    </>
  )
}

export default Step1
