import {useEffect, useRef, useState, useContext} from 'react'
import clsx from 'clsx'
import {ILayout, useLayout} from '../../core'
import {SidebarMenu} from './sidebar-menu/SidebarMenu'
import {SidebarFooter} from './SidebarFooter'
import {SidebarLogo} from './SidebarLogo'
import './index.css'
import CreateNewPodCustomModal from '../../../../app/modules/create-new-pod-wizard/CreateNewPodModal'
import {useAuth} from '../../../../app/modules/auth'
import {Link, useParams} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {KTIcon} from '../../../helpers'
import AppContext from '../../../../app/AppContext'
interface Pod {
  pod_id: number
  pod_brand_name: string
  pod_code: string
  pod_pref_color: string
  pod_logo: string
}

interface AppContext {
  podsData: any[]
  getPodsData: (showToaster?: boolean) => void
}

const Sidebar = () => {
  const {podsData, getPodsData}: AppContext = useContext(AppContext)
  const {currentUser} = useAuth()
  const {pathname} = useLocation()
  const {id} = useParams()
  useEffect(() => {
    getPodsData()
  }, [])

  useEffect(() => {
    if (id) {
      localStorage.setItem('currentPodCode', JSON.stringify(id))
    }
  }, [id])

  const isFirstPodAvailable =
    currentUser && currentUser.pods_list && currentUser.pods_list.length > 0

  const information: any = podsData?.find((pod: any) => String(pod.pod_code) === id)
  if (information) {
    const dateFormat = information?.pod_date_format ? information?.pod_date_format : 'yyyy-MM-dd'
    localStorage.setItem('dateFormat', dateFormat)
  }
  const {config} = useLayout()
  const sidebarRef = useRef<HTMLDivElement>(null)
  const [showModal, setShowModal] = useState(false)
  const handleOpenModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  useEffect(() => {
    updateDOM(config)
  }, [config])

  if (!config.app?.sidebar?.display) {
    return null
  }

  return (
    <>
      {(config.layoutType === 'dark-sidebar' || config.layoutType === 'light-sidebar') && (
        <div
          ref={sidebarRef}
          id='kt_app_sidebar'
          className={clsx('app-sidebar', config.app?.sidebar?.default?.class)}
        >
          <SidebarLogo sidebarRef={sidebarRef} />

          <div className='h-100 d-flex'>
            {pathname !== '/my-pods' && pathname !== '/my-invites' && pathname !== '/my-profile' && (
              <div className='w-25 mt-3 mb-20 pb-5 overflow-auto'>
                {(Array.isArray(podsData) ? podsData : []).map((each: Pod) => {
                  let color = each.pod_pref_color ? each.pod_pref_color.substring(1) : ''
                  const name = encodeURIComponent(each.pod_brand_name)
                  return (
                    <Link
                      key={each.pod_code}
                      to={`/${each.pod_code}/dashboard`}
                      className={`d-flex justify-content-center align-items-center ${
                        id === `${each.pod_code}` ? 'test-active2' : ''
                      }`}
                      data-toggle='tooltip'
                      title={each.pod_brand_name}
                    >
                      <div
                        className={`plus-icon d-flex justify-content-center align-items-center ${
                          id === `${each.pod_code}` ? 'test-active' : ''
                        }`}
                        style={{borderRadius: '3px'}}
                      >
                        <img
                          className='w-100 h-100'
                          style={{borderRadius: '3px'}}
                          src={
                            each.pod_logo
                              ? each.pod_logo
                              : `${process.env.REACT_APP_API_BASE_URL}/get/profile?name=${name}&color=${color}&size=128}`
                          }
                          alt='profile'
                        />
                      </div>
                    </Link>
                  )
                })}
                <div
                  className='d-flex justify-content-center align-items-center'
                  data-toggle='tooltip'
                  title='Add New Pod'
                >
                  <div
                    className='plus-icon d-flex flex-column justify-content-center align-items-center'
                    style={{borderRadius: '3px'}}
                    onClick={handleOpenModal}
                  >
                    <KTIcon iconName='plus' className='fs-2 text-white' />
                  </div>
                </div>

                <div
                  className='d-flex justify-content-center align-items-center'
                  data-toggle='tooltip'
                  title='Reload PODs'
                >
                  <div
                    className='plus-icon d-flex flex-column justify-content-center align-items-center'
                    style={{borderRadius: '3px'}}
                    onClick={() => getPodsData(true)}
                  >
                    <KTIcon iconName='arrows-circle' className='fs-2 text-white' />
                  </div>
                </div>
              </div>
            )}

            {/* <div style={{position: 'relative'}}> */}
            <div className='w-100'>
              <SidebarMenu />
            </div>
          </div>
        </div>
      )}
      <CreateNewPodCustomModal
        show={showModal}
        onClose={handleCloseModal}
        fetchPODsList={getPodsData}
      />
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.layoutType === 'dark-sidebar' || config.layoutType === 'light-sidebar') {
    if (config.app?.sidebar?.default?.minimize?.desktop?.enabled) {
      if (config.app?.sidebar?.default?.minimize?.desktop?.default) {
        document.body.setAttribute('data-kt-app-sidebar-minimize', 'on')
      }

      if (config.app?.sidebar?.default?.minimize?.desktop?.hoverable) {
        document.body.setAttribute('data-kt-app-sidebar-hoverable', 'true')
      }
    }

    if (config.app?.sidebar?.default?.minimize?.mobile?.enabled) {
      if (config.app?.sidebar?.default?.minimize?.mobile?.default) {
        document.body.setAttribute('data-kt-app-sidebar-minimize-mobile', 'on')
      }

      if (config.app?.sidebar?.default?.minimize?.mobile?.hoverable) {
        document.body.setAttribute('data-kt-app-sidebar-hoverable-mobile', 'true')
      }
    }

    if (config.app?.sidebar?.default?.collapse?.desktop?.enabled) {
      if (config.app?.sidebar?.default?.collapse?.desktop?.default) {
        document.body.setAttribute('data-kt-app-sidebar-collapse', 'on')
      }
    }

    if (config.app?.sidebar?.default?.collapse?.mobile?.enabled) {
      if (config.app?.sidebar?.default?.collapse?.mobile?.default) {
        document.body.setAttribute('data-kt-app-sidebar-collapse-mobile', 'on')
      }
    }

    if (config.app?.sidebar?.default?.push) {
      if (config.app?.sidebar?.default?.push?.header) {
        document.body.setAttribute('data-kt-app-sidebar-push-header', 'true')
      }

      if (config.app?.sidebar?.default?.push?.toolbar) {
        document.body.setAttribute('data-kt-app-sidebar-push-toolbar', 'true')
      }

      if (config.app?.sidebar?.default?.push?.footer) {
        document.body.setAttribute('data-kt-app-sidebar-push-footer', 'true')
      }
    }

    if (config.app?.sidebar?.default?.stacked) {
      document.body.setAttribute('app-sidebar-stacked', 'true')
    }

    document.body.setAttribute('data-kt-app-sidebar-enabled', 'true')
    document.body.setAttribute(
      'data-kt-app-sidebar-fixed',
      config.app?.sidebar?.default?.fixed?.desktop?.toString() || ''
    )

    const appSidebarDefaultDrawerEnabled = config.app?.sidebar?.default?.drawer?.enabled
    let appSidebarDefaultDrawerAttributes: {[attrName: string]: string} = {}
    if (appSidebarDefaultDrawerEnabled) {
      appSidebarDefaultDrawerAttributes = config.app?.sidebar?.default?.drawer?.attributes as {
        [attrName: string]: string
      }
    }

    const appSidebarDefaultStickyEnabled = config.app?.sidebar?.default?.sticky?.enabled
    let appSidebarDefaultStickyAttributes: {[attrName: string]: string} = {}
    if (appSidebarDefaultStickyEnabled) {
      appSidebarDefaultStickyAttributes = config.app?.sidebar?.default?.sticky?.attributes as {
        [attrName: string]: string
      }
    }

    setTimeout(() => {
      const sidebarElement = document.getElementById('kt_app_sidebar')
      // sidebar
      if (sidebarElement) {
        const sidebarAttributes = sidebarElement
          .getAttributeNames()
          .filter((t) => t.indexOf('data-') > -1)
        sidebarAttributes.forEach((attr) => sidebarElement.removeAttribute(attr))

        if (appSidebarDefaultDrawerEnabled) {
          for (const key in appSidebarDefaultDrawerAttributes) {
            if (appSidebarDefaultDrawerAttributes.hasOwnProperty(key)) {
              sidebarElement.setAttribute(key, appSidebarDefaultDrawerAttributes[key])
            }
          }
        }

        if (appSidebarDefaultStickyEnabled) {
          for (const key in appSidebarDefaultStickyAttributes) {
            if (appSidebarDefaultStickyAttributes.hasOwnProperty(key)) {
              sidebarElement.setAttribute(key, appSidebarDefaultStickyAttributes[key])
            }
          }
        }
      }
    }, 0)
  }
}

export {Sidebar}
