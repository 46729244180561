import {Modal} from 'react-bootstrap'

import {EditVacancy2} from './EditVacancy2'

function EditVacancyModal2(props: any) {
  const handleClose = () => {
    props.onClose()
  }

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
      centered
      dialogClassName='custom-modal modal-dialog-centered'
      contentClassName='custom-modal-content'
      backdrop='static'
    >
      <Modal.Body className='overflow-auto'>
        <EditVacancy2
          closeModal={props.onClose}
          getvacanciesData={props.getVacancies}
          vacanciesgetData={props.data}
        />
      </Modal.Body>
    </Modal>
  )
}

export default EditVacancyModal2
