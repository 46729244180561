import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {FormikHelpers} from 'formik'
import {FindPodIdByCode} from '../../FindPodIdByPodCode'
import usePostApi from '../../custom_hooks/usePostApi'
import {UseToast} from '../../useToast'
import {KTIcon} from '../../../../_metronic/helpers'

const Careers = (props: any) => {
  const podId = FindPodIdByCode()
  const intl = useIntl()
  const {execute} = usePostApi()
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .required('Business Unit Name is required'),
    buCode: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .required('BU Code is required'),
    erpId: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .required('ERP ID is required'),
    status: Yup.boolean().required(intl.formatMessage({id: 'LABEL.STATUS_REQUIRED'})),
  })
  const handleSubmit = async (values: any, {resetForm}: FormikHelpers<any>) => {
    const url = `/pod/business_unit/create`
    try {
      const requestData = {
        pod_id: podId,
        business_unit_name: values.name,
        bu_code: values.buCode,
        erp_id: values.erpId,
        status: values.status ? 1 : 0,
      }
      const response = await execute(url, 'POST', requestData)
      resetForm()
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
    } catch (error: any) {
      console.error(error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }
  const handleCancel = (resetForm: any) => {
    resetForm()
  }
  return (
    <div>
      <Formik
        initialValues={{
          name: '',
          buCode: '',
          erpId: '',
          status: true,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({values, resetForm}) => {
          return (
            <Form>
              <div className='card'>
                <div className='card-header'>
                  <h3 className='card-title fw-semibold fs-3 text-dark'>Careers</h3>
                </div>
                <div className='px-7 py-5'>
                  <div className='modal-body'>
                    <div>
                      <div className='d-flex mb-4'>
                        <div className='flex-fill'>
                          <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 required'>
                            First Name
                          </label>
                          <Field
                            type='text'
                            className='form-control form-control-solid mb-1'
                            id='firstName'
                            placeholder='Enter First Name'
                            name='firstName'
                          />
                        </div>
                        <div className='ms-2 flex-fill'>
                          <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 required'>
                            Last Name
                          </label>
                          <Field
                            type='text'
                            className='form-control form-control-solid mb-1'
                            id='erpId'
                            placeholder='Enter Last Name'
                            name='erpId'
                          />
                        </div>
                      </div>
                      <div className='mb-4 flex-fill'>
                        <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 required'>
                          Email
                        </label>
                        <Field
                          className='form-control form-control-solid mb-1'
                          id='floatingInput'
                          placeholder='Enter Email'
                          name='email'
                          type='email'
                          autoComplete='off'
                        />
                        <ErrorMessage component='div' className='text-danger' name='email' />
                      </div>
                      <div className='d-flex mb-4'>
                        <div className='flex-fill'>
                          <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 required'>
                            Phone Number
                          </label>
                          <Field
                            type='text'
                            className='form-control form-control-solid mb-1'
                            id='phnumber'
                            placeholder='Enter Phone Number'
                            name='phnumber'
                          />
                        </div>
                        <div className='ms-2 flex-fill'>
                          <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 required'>
                            CV/Resume
                          </label>
                          <input
                            type='file'
                            className='form-control form-control-solid mb-1'
                            id='file'
                            name='file'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex justify-content-end'>
                    <button
                      type='reset'
                      onClick={() => {
                        handleCancel(resetForm)
                      }}
                      className='btn btn-sm btn-light me-2'
                    >
                      {intl.formatMessage({id: 'LABEL.CANCEL'})}
                    </button>
                    <button
                      type='submit'
                      className='btn btn-sm btn-light-primary'
                      data-kt-menu-dismiss='true'
                    >
                      <KTIcon iconName='check' className='fs-7' />
                      {intl.formatMessage({id: 'BTN.SAVE'})}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default Careers
