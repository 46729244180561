/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {SketchPicker} from 'react-color'
import PropTypes from 'prop-types'

const ThemeColors = ({updateData, data}) => {
  const [selectedColor, setSelectedColor] = useState(data?.pod_pref_color || '')
  const [showPicker, setShowPicker] = useState(false)

  const handleClick = (color) => {
    setSelectedColor(color)
    setShowPicker(!showPicker)
  }

  const handleChange = (newColor) => {
    updateData(newColor.hex, 'pod_pref_color')
    setSelectedColor(newColor.hex)
  }

  return (
    <div className={`w-100 card card-xl-stretch mb-10 ps-2 mt-2`}>
      <label
        className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'
        htmlFor='brand_color'
      >
        <span className=''>Brand Color</span>
        <i
          className='fas fa-exclamation-circle ms-2 fs-7'
          data-bs-toggle='tooltip'
          title='Choose the color scheme or palette that best represents your organization.'
        ></i>
      </label>
      <div className='w-100'>
        <div className='d-flex'>
          <div className='d-flex align-items-center me-2'>
            <button
              type='button'
              className='btn btn-unstyled color-container'
              style={{
                backgroundColor: selectedColor,
                width: '40px',
                height: '40px',
                borderRadius: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                verticalAlign: 'middle',
                cursor: 'pointer',
                outline: '0px',
              }}
              onClick={() => handleClick(selectedColor)}
            ></button>
          </div>
          <div className='d-flex align-items-center'>
            <div className='row'>
              <div className='col-12'>
                <p className='text-dark fw-normal text-hover-primary mb-1 fs-6 default-cursor'>
                  Selected Color
                </p>
              </div>
              <div className='col-12'>
                <div className='d-flex align-items-center'>
                  <span className='text-muted fw-normal fs-7'>{selectedColor}</span>
                  <button
                    type='button'
                    className='btn btn-unstyled badge bg-light-primary fw-normal fs-7 ms-2 d-flex flex-column justify-content-center'
                    style={{
                      borderRadius: '50px',
                      height: '12px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                    }}
                    onClick={() => handleClick(selectedColor)}
                  >
                    Change
                  </button>
                  {showPicker && (
                    <div style={{position: 'relative'}}>
                      <button
                        className='btn btn-unstyled'
                        style={{
                          position: 'fixed',
                          top: 0,
                          right: 0,
                          bottom: 0,
                          left: 0,
                        }}
                        onClick={() => setShowPicker(false)}
                      />
                      <div style={{position: 'absolute', top: '-280px', zIndex: '2'}}>
                        <SketchPicker
                          color={selectedColor}
                          onChange={(newColor) => handleChange(newColor)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ThemeColors.propTypes = {
  updateData: PropTypes.func.isRequired,
  data: PropTypes.shape({
    pod_pref_color: PropTypes.string,
  }),
}

export default ThemeColors
