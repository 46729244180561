import {useEffect, useState} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import './index.css'
import clsx from 'clsx'
import Select, {components} from 'react-select'
import {KTIcon} from '../../../../_metronic/helpers'
import usePostApi from '../../custom_hooks/usePostApi'
import {useThemeMode} from '../../../../_metronic/partials'
import ThemeColors from './EditThemePref'
import {UseToast} from '../../useToast'
import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'
import {FindPodIdByCode} from '../../FindPodIdByPodCode'

const registrationSchema = Yup.object().shape({
  pod_business_name: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(250, 'Maximum 250 characters')
    .required('Organization Name is required'),
  pod_brand_name: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(250, 'Maximum 250 characters')
    .required('Brand Name is required'),
})

type Data = {
  pod_business_name: string
  pod_brand_name: string
  pod_industry_id: number | string
  pod_team_size: any
  pod_description: string
  pod_website_url: string
  dateFormat: string
  timeZone: string
  pod_pref_color: string
  pod_currency_code: string
}
function EditPOD({
  closeModal = () => {},
  podgetData,
  getPodsData = () => {},
  refreshData = () => {},
}: any) {
  const {
    pod_brand_name,
    pod_business_name,
    pod_description,
    pod_industry,
    pod_team_size_code,
    url,
    pod_code,
    pod_country,
    pod_timezone,
    pod_date_format,
    pod_pref_color,
    pod_currency,
  } = podgetData
  const intl = useIntl()
  const podId = FindPodIdByCode()
  const {execute} = usePostApi()
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [data, setData] = useState<Data>({
    pod_business_name: '' || pod_business_name,
    pod_brand_name: '' || pod_brand_name,
    pod_industry_id: '' || pod_industry,
    pod_team_size: '' || pod_team_size_code,
    pod_description: '' || pod_description,
    pod_website_url: '' || url,
    dateFormat: '' || pod_date_format,
    timeZone: '' || pod_timezone,
    pod_pref_color: '' || pod_pref_color,
    pod_currency_code: '' || pod_currency,
  })
  const set2ndIndex = () => {
    setCurrentStep(1)
  }
  const set3rdIndex = () => {
    setCurrentStep(2)
  }

  const handleSaveClick = async () => {
    try {
      const url = `/pod/update`
      const result = await Swal.fire({
        title: 'Updating the Pod Information',
        text: 'Press the Confirm button to update the Pod Information.',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: intl.formatMessage({id: 'BTN.CONFIRM'}),
        cancelButtonText: intl.formatMessage({id: 'LABEL.CANCEL'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
          cancelButton: 'btn btn-sm btn-secondary',
        },
      })
      if (result.isConfirmed) {
        const organizationdata = {
          pod_id: podId,
          pod_code: pod_code,
          pod_business_name: data.pod_business_name,
          pod_brand_name: data.pod_brand_name,
          pod_description: data.pod_description,
          pod_country_code: pod_country,
          pod_industry_id: data.pod_industry_id,
          pod_team_size: data.pod_team_size,
          pod_pref_color: data.pod_pref_color,
          pod_date_format: data.dateFormat,
          pod_timezone: data.timeZone,
          url: data.pod_website_url,
          pod_currency_code: data.pod_currency_code,
        }
        const response = await execute(url, 'POST', organizationdata)
        closeModal()
        getPodsData()
        refreshData()
        UseToast(
          response.status_message,
          {
            position: 'bottom-right',
            theme: 'colored',
            autoClose: false,
          },
          'success'
        )
      }
    } catch (error: any) {
      console.error(error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }

  const steps = [
    <Step1 set2ndIndex={set2ndIndex} data={data} setData={setData} key='step_1' />,
    <Step2 set3rdIndex={set3rdIndex} data={data} setData={setData} key='step_2' />,
    <Step3 data={data} setData={setData} handleSaveClick={handleSaveClick} key='step_3' />,
  ]
  const nextStep = async () => {
    if (currentStep === steps.length - 1) {
      const element = document.getElementById('step_3_submit')
      if (element) {
        element.click()
      }
    } else if (currentStep === 0) {
      const element = document.getElementById('verify-sign-up-btn')
      if (element) {
        element.click()
      }
    } else if (currentStep === 1) {
      const element = document.getElementById('step_2_submit')
      if (element) {
        element.click()
      }
    } else {
      const totalSteps = steps.length - 1
      if (currentStep < totalSteps) {
        setCurrentStep(currentStep + 1)
      }
    }
  }

  const prevStep = (): void => {
    if (currentStep !== 0) {
      setCurrentStep(currentStep - 1)
    }
  }

  let buttonText: string
  if (currentStep === 2) {
    buttonText = 'Confirm'
  } else {
    buttonText = 'Continue'
  }

  return (
    <div className='card py-0 overflow-auto'>
      <div className='w-100 m-auto'>
        <div className='d-flex justify-content-between mb-10'>
          <h3 className='fs-3 fw-semibold'>Edit POD</h3>
          <button
            type='button'
            className='d-block btn btn-sm btn-icon btn-light'
            onClick={closeModal}
          >
            <KTIcon iconName='cross' className='fs-1' />
          </button>
        </div>
        <div className='w-100 row m-0'>
          <div className='col-lg-5 p-0 mb-5 mb-lg-0'>
            <div className=''>
              <div className='d-flex'>
                <div
                  style={{borderRadius: '10px'}}
                  className={clsx(
                    'w-40px h-40px btn d-flex justify-content-center align-items-center',
                    {
                      'btn-primary': currentStep === 0,
                      'btn-light-primary': currentStep !== 0,
                    }
                  )}
                >
                  {currentStep === 0 ? 1 : <i className='stepper-check fas fa-check'></i>}
                </div>
                <div className='ms-5'>
                  <h6
                    className={clsx('mb-0 fw-semibold', {
                      'text-muted': currentStep >= 0,
                    })}
                  >
                    Step 1
                  </h6>
                  <p
                    className={clsx('fs-6 mb-0', {
                      'text-muted': currentStep >= 0,
                    })}
                  >
                    Company & Brand
                  </p>
                </div>
              </div>
              <div className='ms-6 h-30px border-left border-dashed border-muted border-top-0 border-bottom-0 border-right-0 border-left-1'></div>
              <div className='d-flex'>
                <div
                  style={{borderRadius: '10px'}}
                  className={clsx(
                    'w-40px h-40px btn d-flex justify-content-center align-items-center',
                    {
                      'btn-primary': currentStep === 1,
                      'btn-light-primary': currentStep !== 1,
                    }
                  )}
                >
                  {currentStep > 1 ? <i className='stepper-check fas fa-check'></i> : 2}
                </div>
                <div className='ms-5'>
                  <h6
                    className={clsx('mb-0 fw-semibold', {
                      'text-muted': currentStep >= 1,
                    })}
                  >
                    Step 2
                  </h6>
                  <p
                    className={clsx('fs-6 mb-0', {
                      'text-muted': currentStep >= 1,
                    })}
                  >
                    Industry & Team
                  </p>
                </div>
              </div>
              {/* Add more steps here */}
              <div className='ms-6 h-30px border-left border-dashed border-muted border-top-0 border-bottom-0 border-right-0 border-left-1'></div>
              <div className='d-flex'>
                <div
                  style={{borderRadius: '10px'}}
                  className={clsx(
                    'w-40px h-40px btn d-flex justify-content-center align-items-center',
                    {
                      'btn-primary': currentStep === 2,
                      'btn-light-primary': currentStep !== 2,
                    }
                  )}
                >
                  {currentStep > 2 ? <i className='stepper-check fas fa-check'></i> : 3}
                </div>
                <div className='ms-5'>
                  <h6
                    className={clsx('mb-0 fw-semibold', {
                      'text-muted': currentStep >= 2,
                    })}
                  >
                    Step 3
                  </h6>
                  <p
                    className={clsx('fs-6 mb-0', {
                      'text-muted': currentStep > 1,
                    })}
                  >
                    Regional Preferences
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-7 p-0 m-0'>{steps[currentStep]}</div>
        </div>

        <div className='w-100 d-flex flex-stack mt-5'>
          <div className='mr-2'>
            {currentStep > 0 && (
              <button onClick={prevStep} type='button' className='btn btn-sm btn-light me-3'>
                <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                Back
              </button>
            )}
          </div>

          <div>
            {currentStep !== 3 && (
              <button type='submit' className='btn btn-sm btn-light-primary' onClick={nextStep}>
                <>
                  {buttonText}
                  <KTIcon iconName='arrow-right' className='fs-3 ms-1' />
                </>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

interface Step1Props {
  set2ndIndex(): void
  data: Data
  setData: (values: Data) => void
}

const Step1 = (props: Step1Props) => {
  const onSubmit = async (Values: Data) => {
    props.setData({...Values})
    props.set2ndIndex()
  }

  return (
    <Formik validationSchema={registrationSchema} initialValues={props.data} onSubmit={onSubmit}>
      {({values}: any) => {
        return (
          <Form className='w-100'>
            <div className='w-100 mb-5'>
              <label
                className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'
                htmlFor='org_name'
              >
                <span className='required'>Organization Name</span>
                <i
                  className='fas fa-info-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='This is the name of your company or organization.'
                ></i>
              </label>
              <Field
                id='org_name'
                type='text'
                name='pod_business_name'
                className='form-control form-control-solid'
                placeholder='Enter your organization name'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='pod_business_name' />
              </div>
            </div>
            <div className='w-100 mb-5'>
              <label
                className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'
                htmlFor='brand_name'
              >
                <span className='required'>Brand Name</span>
                <i
                  className='fas fa-info-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='The name of your brand or product.'
                ></i>
              </label>
              <Field
                id='brand_name'
                type='text'
                name='pod_brand_name'
                className='form-control form-control-solid'
                placeholder='Enter your brand name'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='pod_brand_name' />
              </div>
            </div>
            <div className='w-100 d-flex flex-column mb-4 fv-row'>
              <label
                className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'
                htmlFor='brand_des'
              >
                <span className=''>About {values?.pod_brand_name}</span>
                <i
                  className='fas fa-info-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Provide a short description of your organization, its mission, or what it does.'
                ></i>
              </label>
              <Field
                id='brand_des'
                as='textarea'
                className={`form-control form-control form-control-solid`}
                name='pod_description'
                placeholder='Briefly describe your organization'
                rows={3}
              />
            </div>
            <button
              type='submit'
              className='d-none btn btn-lg btn-primary w-100'
              id='verify-sign-up-btn'
            >
              Submit
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}

const step2Schema = Yup.object().shape({
  pod_industry_id: Yup.string().required('Industry is required'),
})

const Step2 = ({set3rdIndex, data, setData}: any) => {
  const {execute, loading} = usePostApi()
  const {mode} = useThemeMode()
  const [industries, setIndustries] = useState([])

  const getIndustries = async () => {
    try {
      const url = '/master/industries'
      const formData = {}

      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.industry_id,
        label: each.industry_en,
      }))
      setIndustries(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const [teamsizeOptions, setTeamSizeOptions] = useState([])

  const getDifferentTeamSizes = async () => {
    try {
      const url = '/lookup/TEAM_SIZE'
      const formData = {}
      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.lookup_code,
        label: each.meaning_en,
      }))
      setTeamSizeOptions(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getIndustries()
    getDifferentTeamSizes()
  }, [])

  const [urlValid, setUrlValid] = useState(false)
  const [urlInput, setUrlInput] = useState(data.pod_website_url)

  const regex =
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/

  useEffect(() => {
    if (urlInput) {
      const isValid = regex.test(urlInput)
      if (isValid === false) {
        setUrlValid(true)
        return
      }
      setUrlValid(false)
    } else {
      setUrlValid(false)
    }
  }, [urlInput])

  const onSubmit = (values: any) => {
    if (urlValid === true) {
      return
    }
    setData({...values})
    set3rdIndex()
  }

  const handleIndustryChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('pod_industry_id', selectedOption.value)
    } else {
      setFieldValue('pod_industry_id', '') // Handle case where no option is selected
    }
  }

  const handleTeamsizeChange = (setFieldValue: any, selectedOption: any) => {
    if (selectedOption && selectedOption.value) {
      setFieldValue('pod_team_size', selectedOption.value)
    } else {
      setFieldValue('pod_team_size', '') // Handle case where no option is selected
    }
  }

  return (
    <Formik validationSchema={step2Schema} initialValues={data} onSubmit={onSubmit}>
      {({setFieldValue, values}: any) => {
        return (
          <>
            {loading ? (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <div className='spinner-border spinner-primary mr-15'></div>
                </div>
              </div>
            ) : (
              <Form className='w-100'>
                <div className='mb-5'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Industry</span>
                    <i
                      className='fas fa-info-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Choose the industry your organization belongs to.'
                    ></i>
                  </label>
                  <Select
                    value={industries.find(
                      (option: any) => option.value === values.pod_industry_id
                    )}
                    styles={{
                      control: (baseStyles: any, state: any) => ({
                        ...baseStyles,
                        backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                        color: '#ffffff',
                        borderWidth: '0px',
                      }),
                      menu: (baseStyles: any) => ({
                        ...baseStyles,
                        backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                      }),
                      singleValue: (baseStyles: any, state: any) => ({
                        ...baseStyles,
                        color: mode === 'dark' ? '#92929f' : '#000000',
                      }),
                      input: (baseStyles: any) => ({
                        ...baseStyles,
                        color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                      }),
                      option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                        ...baseStyles,
                        backgroundColor: isSelected
                          ? '#C2C2C2'
                          : isFocused
                          ? '#e0e0e0'
                          : baseStyles.backgroundColor,
                        color: isSelected ? '#000000' : isFocused ? '#000000' : baseStyles.color,
                      }),
                    }}
                    theme={(theme: any) => ({
                      ...theme,
                      borderRadius: 5,
                      backgroundColor: '#000000',
                      colors: {
                        ...theme.colors,
                        primary25: 'rgba(80, 107, 236, 0.5)',
                        primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                      },
                    })}
                    className='react-select-container'
                    classNamePrefix='react-select'
                    options={industries}
                    isSearchable
                    placeholder='Select your industry'
                    onChange={(val: any) => handleIndustryChange(setFieldValue, val)}
                  />

                  <div className='text-danger mt-2'>
                    <ErrorMessage name='pod_industry_id' />
                  </div>
                </div>
                <div className='mb-5'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className=''>Team Size</span>
                    <i
                      className='fas fa-info-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='The number of people in your organization.'
                    ></i>
                  </label>
                  <Select
                    value={teamsizeOptions.find(
                      (option: any) => option.value === values.pod_team_size
                    )}
                    styles={{
                      control: (baseStyles: any, state: any) => ({
                        ...baseStyles,
                        backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                        color: '#ffffff',
                        borderWidth: '0px',
                      }),
                      menu: (baseStyles: any) => ({
                        ...baseStyles,
                        backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                      }),
                      singleValue: (baseStyles: any, state: any) => ({
                        ...baseStyles,
                        color: mode === 'dark' ? '#92929f' : '#000000',
                      }),
                      input: (baseStyles: any) => ({
                        ...baseStyles,
                        color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                      }),
                      option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                        ...baseStyles,
                        backgroundColor: isSelected
                          ? '#C2C2C2'
                          : isFocused
                          ? '#e0e0e0'
                          : baseStyles.backgroundColor,
                        color: isSelected ? '#000000' : isFocused ? '#000000' : baseStyles.color,
                      }),
                    }}
                    theme={(theme: any) => ({
                      ...theme,
                      borderRadius: 5,
                      backgroundColor: '#000000',
                      colors: {
                        ...theme.colors,
                        primary25: 'rgba(80, 107, 236, 0.5)',
                        primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                      },
                    })}
                    className='react-select-container'
                    classNamePrefix='react-select'
                    options={teamsizeOptions}
                    isSearchable
                    placeholder='Select your team size'
                    onChange={(val: any) => handleTeamsizeChange(setFieldValue, val)}
                  />
                </div>
                <div className='mb-5'>
                  <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                    <span className=''>Website</span>
                    <i
                      className='fas fa-info-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title="The web address of your organization's website."
                    ></i>
                  </label>
                  <input
                    className='form-control form-control-solid'
                    placeholder='Enter your website URL'
                    value={urlInput}
                    onChange={(e: any) => {
                      setUrlInput(e.target.value)
                      setFieldValue('pod_website_url', e.target.value)
                    }}
                  />

                  <div className='text-danger'>{urlValid && 'Please enter a valid URL'}</div>
                </div>

                <button
                  type='submit'
                  className='d-none btn btn-lg btn-primary w-100'
                  id='step_2_submit'
                >
                  Submit
                </button>
              </Form>
            )}
          </>
        )
      }}
    </Formik>
  )
}

interface Step3Props {
  data: Data
  setData(data: any): void
  handleSaveClick(): void
}

const step3Schema = Yup.object().shape({
  pod_currency_code: Yup.string().required('Currency is required'),
})

const Step3 = ({data, setData, handleSaveClick}: Step3Props) => {
  const {mode} = useThemeMode()
  const {execute, loading} = usePostApi()
  const [timezones, setTimezones] = useState([])
  const [currencies, setCurrencies] = useState([])
  const getTimezones = async () => {
    try {
      const url = '/master/timezone'
      const formData = {}
      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.tz_name,
        label: each.tz_label,
      }))
      setTimezones(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const getCurrencies = async () => {
    try {
      const url = `/master/currency`
      const formData = {}
      const response = await execute(url, 'POST', formData)
      const options = (response.data || []).map((each: any) => ({
        value: each.currency_code,
        label: each.currency_name,
      }))
      setCurrencies(options)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getTimezones()
    getCurrencies()
  }, [])

  const dateFormatOptions = [
    {value: 'dd/MM/yyyy', label: 'DD/MM/YYYY'},
    {value: 'MM/dd/yyyy', label: 'MM/DD/YYYY'},
    {value: 'yyyy/MM/dd', label: 'YYYY/MM/DD'},
    {value: 'dd-MM-yyyy', label: 'DD-MM-YYYY'},
    {value: 'MM dd, yyyy', label: 'MM DD, YYYY'},
    {value: 'dd MMMM yyyy', label: 'DD Month YYYY'},
    {value: 'MM dd yyyy', label: 'MM DD YYYY'},
    {value: 'dd-MM-yy', label: 'DD-MM-YY'},
    {value: 'yy/MM/dd', label: 'YY/MM/DD'},
  ]

  const addCurrentDateToLabel = (format: any) => {
    const currentDate = new Date()

    const formattedDate = format
      .replace('dd', currentDate.getDate().toString().padStart(2, '0'))
      .replace('DD', currentDate.getDate().toString().padStart(2, '0'))
      .replace('MM', (currentDate.getMonth() + 1).toString().padStart(2, '0'))
      .replace('yyyy', currentDate.getFullYear())
      .replace('YYYY', currentDate.getFullYear())
      .replace('yy', currentDate.getFullYear().toString().slice(-2))
      .replace('YY', currentDate.getFullYear().toString().slice(-2))
      .replace('Month', new Intl.DateTimeFormat('en-US', {month: 'long'}).format(currentDate))

    return `${format} (${formattedDate})`
  }
  const dateFormatOptionsWithDate = dateFormatOptions.map((option) => ({
    ...option,
    label: addCurrentDateToLabel(option.label),
  }))

  const updateData = (value: string, key: string) => {
    setData({...data, [key]: value})
  }

  const MultiLineOption = (props: any) => (
    <components.Option {...props}>
      <div>{props.data.label}</div>
      <div style={{fontSize: '12px', color: '#92929f'}}>{props.data.value}</div>
    </components.Option>
  )

  const submitStep3 = (values: any) => {
    handleSaveClick()
  }

  return (
    <>
      <Formik
        validationSchema={step3Schema}
        initialValues={data}
        onSubmit={submitStep3}
        enableReinitialize={true}
      >
        {({setFieldValue, values}: any) => {
          return (
            <>
              {loading ? (
                <div className='card h-250px'>
                  <div className='m-auto d-flex flex-column align-items-center'>
                    <div className='spinner-border spinner-primary mr-15'></div>
                  </div>
                </div>
              ) : (
                <Form className='w-100'>
                  <div className='mb-5 w-100'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                      <span className=''>Date Format</span>
                      <i
                        className='fas fa-info-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Choose how you want dates to be displayed (e.g., DD/MM/YYYY, MM/DD/YYYY).'
                      ></i>
                    </label>
                    <Select
                      value={dateFormatOptionsWithDate.find(
                        (option: any) => option.value === data.dateFormat
                      )}
                      styles={{
                        control: (baseStyles: any, state: any) => ({
                          ...baseStyles,
                          backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                          color: '#ffffff',
                          borderWidth: '0px',
                        }),
                        menu: (baseStyles: any) => ({
                          ...baseStyles,
                          backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                        }),
                        singleValue: (baseStyles: any, state: any) => ({
                          ...baseStyles,
                          color: mode === 'dark' ? '#92929f' : '#000000',
                        }),
                        input: (baseStyles: any) => ({
                          ...baseStyles,
                          color: mode === 'dark' ? '#92929f' : '#000000',
                        }),
                        option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                          ...baseStyles,
                          backgroundColor: isSelected
                            ? '#C2C2C2'
                            : isFocused
                            ? '#e0e0e0'
                            : baseStyles.backgroundColor,
                          color: isSelected ? '#000000' : isFocused ? '#000000' : baseStyles.color,
                        }),
                      }}
                      theme={(theme: any) => ({
                        ...theme,
                        borderRadius: 5,
                        backgroundColor: '#000000',
                        colors: {
                          ...theme.colors,
                          primary25: 'rgba(80, 107, 236, 0.5)',
                          primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                        },
                      })}
                      className='react-select-container'
                      classNamePrefix='react-select'
                      options={dateFormatOptionsWithDate}
                      isSearchable
                      placeholder='Select your preferred date format'
                      onChange={(val: any) => {
                        updateData(val?.value, 'dateFormat')
                      }}
                      // components={{Option: MultiLineOption}}
                    />
                  </div>
                  <div className='mb-5'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                      <span className=''>Timezone</span>
                      <i
                        className='fas fa-info-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Choose the timezone your organization operates in.'
                      ></i>
                    </label>
                    <Select
                      value={timezones.find((option: any) => option.value === data.timeZone)}
                      styles={{
                        control: (baseStyles: any, state: any) => ({
                          ...baseStyles,
                          backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                          color: '#ffffff',
                          borderWidth: '0px',
                        }),
                        menu: (baseStyles: any) => ({
                          ...baseStyles,
                          backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                          // maxHeight: '160px',
                          // overflowY: 'scroll',
                        }),
                        singleValue: (baseStyles: any, state: any) => ({
                          ...baseStyles,
                          color: mode === 'dark' ? '#92929f' : '#000000',
                        }),
                        input: (baseStyles: any) => ({
                          ...baseStyles,
                          color: mode === 'dark' ? '#92929f' : '#000000', // Color for the search text
                        }),
                        option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                          ...baseStyles,
                          backgroundColor: isSelected
                            ? '#C2C2C2'
                            : isFocused
                            ? '#e0e0e0'
                            : baseStyles.backgroundColor,
                          color: isSelected ? '#000000' : isFocused ? '#000000' : baseStyles.color,
                        }),
                      }}
                      theme={(theme: any) => ({
                        ...theme,
                        borderRadius: 5,
                        backgroundColor: '#000000',
                        colors: {
                          ...theme.colors,
                          primary25: 'rgba(80, 107, 236, 0.5)',
                          primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                        },
                      })}
                      className='react-select-container'
                      classNamePrefix='react-select'
                      options={timezones}
                      isSearchable
                      placeholder='Select your timezone'
                      onChange={(val: any) => {
                        const isAlreadySelected = data.timeZone === val?.value
                        if (!isAlreadySelected) {
                          updateData(val?.value, 'timeZone')
                        }
                      }}
                      components={{Option: MultiLineOption}}
                    />
                  </div>

                  <div className='mb-5'>
                    <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                      <span className=''>Currency</span>
                      <i
                        className='fas fa-info-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Select the currency your organization uses for transactions.'
                      ></i>
                    </label>
                    <Select
                      value={currencies.find(
                        (option: any) => option.value === data.pod_currency_code
                      )}
                      styles={{
                        control: (baseStyles: any, state: any) => ({
                          ...baseStyles,
                          backgroundColor: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                          color: '#ffffff',
                          borderWidth: '0px',
                        }),
                        menu: (baseStyles: any) => ({
                          ...baseStyles,
                          backgroundColor: mode === 'dark' ? '#2b2b40' : '#f9f9f9',
                        }),
                        singleValue: (baseStyles: any, state: any) => ({
                          ...baseStyles,
                          color: mode === 'dark' ? '#92929f' : '#000000',
                        }),
                        input: (baseStyles: any) => ({
                          ...baseStyles,
                          color: mode === 'dark' ? '#92929f' : '#000000',
                        }),
                        option: (baseStyles: any, {isFocused, isSelected}: any) => ({
                          ...baseStyles,
                          backgroundColor: isSelected
                            ? '#C2C2C2'
                            : isFocused
                            ? '#e0e0e0'
                            : baseStyles.backgroundColor,
                          color: isSelected ? '#000000' : isFocused ? '#000000' : baseStyles.color,
                        }),
                      }}
                      theme={(theme: any) => ({
                        ...theme,
                        borderRadius: 5,
                        backgroundColor: '#000000',
                        colors: {
                          ...theme.colors,
                          primary25: 'rgba(80, 107, 236, 0.5)',
                          primary: mode === 'dark' ? '#1b1b29' : '#f9f9f9',
                        },
                      })}
                      className='react-select-container'
                      classNamePrefix='react-select'
                      options={currencies}
                      isSearchable
                      placeholder='Select your currency'
                      onChange={(val: any) => {
                        setFieldValue('pod_currency_code', val?.value || '')

                        const isAlreadySelected = data.pod_currency_code === val?.value

                        if (!isAlreadySelected) {
                          updateData(val?.value, 'pod_currency_code')
                        }
                      }}
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='pod_currency_code' />
                    </div>
                  </div>
                  <ThemeColors updateData={updateData} data={data} />
                  <button
                    type='submit'
                    className='d-none btn btn-lg btn-primary w-100'
                    id='step_3_submit'
                  >
                    Submit
                  </button>
                </Form>
              )}
            </>
          )
        }}
      </Formik>
    </>
  )
}

export {EditPOD}
