import {useState, useEffect, useRef} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {resetPassword} from '../core/_requests'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import './index.css'
import {KTIcon} from '../../../../_metronic/helpers'
import {motion, AnimatePresence} from 'framer-motion'
import {useNavigate, Link} from 'react-router-dom'
import usePostApi from '../../custom_hooks/usePostApi'
import ReCAPTCHA from 'react-google-recaptcha'

const registrationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
  not_robot: Yup.string().required('Please verify that you are not a robot.'),
})

const step3Schema = Yup.object().shape({
  newPassword: Yup.string()
    .required('New Password is required')
    .min(8, 'Password must be 8 characters'),
  confirmPassword: Yup.string()
    .test('passwords-match', 'Passwords must match', function (this: any, value: any) {
      return this.parent.newPassword === value
    })
    .required('Confirm New Password is required'),
})

export function ForgotPassword() {
  const [data, setData] = useState({
    email: '',
    newPassword: '',
    confirmPassword: '',
    not_robot: '',
  })
  const handleNextStep = (newData: any) => {
    setData((prev) => ({...prev, ...newData}))
    setCurrentStep((prev) => prev + 1)
  }

  const [currentStep, setCurrentStep] = useState(0)
  const steps = [
    <Step1 next={handleNextStep} data={data} key='step_1' />,
    <Step2 data={data} key='step_2' />,
  ]

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <div
      className='min-w-350px d-flex flex-column justify-content-center align-items-center reg-con con-max-width'
      style={{maxWidth: '350px'}}
    >
      {steps[currentStep]}
    </div>
  )
}

const Step1 = (props: any) => {
  const {execute} = usePostApi()
  const [statusMsg, setStatusMsg] = useState('')
  const recaptchaRef = useRef<ReCAPTCHA | null>(null)
  const [statusCode, setStatusCode] = useState(null)

  const onSubmit = async (Values: any) => {
    try {
      const url = '/auth/forgot'
      const formData = {
        email: Values.email,
        recaptcha_validation_token: Values.not_robot,
      }

      const response = await execute(url, 'POST', formData)
      const result = response.status_message
      setStatusCode(response.status_code)
      setStatusMsg(result)
      if (response.status === 200) {
        // props.next({...Values})
      }
    } catch (error: any) {
      console.error(error)
      if (error.response) {
        const statusCode = error.response.data.status_code
        const statusMsg = error.response.data.status_message
        setStatusMsg(statusMsg)
        if (statusCode === 'SC0014') {
          // props.next({...Values})
        }
      } else {
        console.error('An unexpected error occurred:', error)
      }
    }
  }

  return (
    <AnimatePresence mode='wait'>
      <motion.div
        initial={{y: 10, opacity: 0}}
        animate={{y: 0, opacity: 1}}
        exit={{y: -10, opacity: 0}}
        transition={{duration: 0.5}}
      >
        <div className='login-light-mode'>
          <Formik
            validationSchema={registrationSchema}
            initialValues={props.data}
            onSubmit={onSubmit}
          >
            {({setFieldValue, values}: any) => {
              return (
                <Form className='min-w-350px'>
                  <div className='text-center mb-10'>
                    <h1 className='fw-semibold mb-3' style={{color: '#181c32'}}>
                      Forgot Password
                    </h1>
                    <div className='text-gray-500 fw-normal fs-6'>
                      Enter your email to reset your password.
                    </div>
                  </div>
                  <div className='fv-row mb-8'>
                    <p className='mb-0 form-label fs-6 fw-semibold' style={{color: '#181c32'}}>
                      Email
                    </p>
                    <Field name='email' className='form-control' placeholder='Email' autoFocus />
                    <ErrorMessage name='email' component='div' className='text-danger' />
                  </div>

                  <div className='mb-8 w-100 d-flex justify-content-center'>
                    <div>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
                        // sitekey='6LeDB74pAAAAACtM7mxTw8d0onUarTtpaZBPUfAu'
                        onChange={(val: any) => {
                          setFieldValue('not_robot', val)
                        }}
                        onExpired={() => {
                          setFieldValue('not_robot', '')
                        }}
                        // onErrored={() => console.log('reCAPTCHA error')}
                      />
                      <ErrorMessage name='not_robot' component='div' className='text-danger' />
                    </div>
                  </div>

                  <div className='d-flex flex-wrap justify-content-center pb-lg-0 mt-10'>
                    <button
                      type='submit'
                      id='kt_password_reset_submit'
                      className='btn login-btn me-4'
                    >
                      <span className='indicator-label' style={{color: '#fff'}}>
                        Submit
                      </span>
                    </button>
                    <Link to='/auth/login'>
                      <button
                        type='button'
                        className='btn btn-light btn-no-hover'
                        style={{backgroundColor: '#f9f9f9'}}
                      >
                        Cancel
                      </button>
                    </Link>{' '}
                  </div>
                  {statusMsg && (
                    <div
                      className={`alert border border-dashed border-gray-300 rounded px-5 py-3 badge-light-${
                        statusCode === 'SC0011' ? 'success' : 'danger'
                      } d-flex align-items-center p-2 mt-4 d-flex flex-row align-items-center`}
                    >
                      <KTIcon
                        iconName='information'
                        className={`fs-3 me-1 text-${
                          statusCode === 'SC0011' ? 'success' : 'danger'
                        }`}
                      />
                      <div className='d-flex flex-column'>
                        <span className='fs-6 text-dark'>{statusMsg}</span>
                      </div>
                    </div>
                  )}
                </Form>
              )
            }}
          </Formik>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}

const Step2 = (props: any) => {
  const {execute} = usePostApi()
  const navigate = useNavigate()
  const [otp, setOtp] = useState(['', '', '', '', '', ''])
  const [codeErr, setCodeErr] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [errorMsg, setErrorMessage] = useState('')

  const [showNewPW, setNewPW] = useState(false)
  const [showConfirmPW, setConfirNewPW] = useState(false)

  const toggleNewPWVisibility = () => {
    setNewPW(!showNewPW)
  }

  const toggleConfirmPWVisibility = () => {
    setConfirNewPW(!showConfirmPW)
  }

  const handleInputChange = (index: any, value: any) => {
    // Ensure that the input is a number and not empty
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newOtp = [...otp]
      newOtp[index] = value
      setOtp(newOtp)

      // If the current input is not empty and the next element exists, focus on it
      if (value && index < 5) {
        const nextInput = document.getElementById(`otp-input-${index + 1}`)
        if (nextInput) {
          nextInput.focus()
        }
      }
    }
  }

  const scrambleX = (str: string, numTimes = 2) => {
    let encoded = str
    for (let i = 0; i < numTimes; i++) {
      encoded = btoa(encoded)
    }
    return encoded
  }

  const onSubmit = async (values: any) => {
    const code = otp.join('')
    if (code.length !== 6) {
      setCodeErr(true)
    }
    if (code.length === 6) {
      try {
        const url = '/auth/forgot'
        const formData = {
          verify_user: props.data.email,
          verify_token: code,
          verify_type: 'OTP',
          new_password: scrambleX(values.newPassword),
        }

        const response = await execute(url, 'POST', formData)
        // const {data: auth} = await resetPassword(props.data.email, code, 'OTP', values.newPassword)
        // saveAuth(auth)
        // setCurrentUser(auth.data)
        navigate('/auth/login')
      } catch (error: any) {
        console.error(error)
        if (error.response) {
          const errorMessage = error.response.data.status_message
          // console.log(errorMessage)
          setErrorMessage(errorMessage)
        } else {
          console.error('An unexpected error occurred:', error)
        }
        // saveAuth(undefined)
      }
    }
  }

  return (
    <AnimatePresence mode='wait'>
      <motion.div
        initial={{y: 10, opacity: 0}}
        animate={{y: 0, opacity: 1}}
        exit={{y: -10, opacity: 0}}
        transition={{duration: 0.5}}
      >
        <Formik validationSchema={step3Schema} initialValues={props.data} onSubmit={onSubmit}>
          <Form className='min-w-350px d-flex flex-column justify-content-center align-items-center'>
            <h3 className='text-dark fw-semibold mb-3'>Reset Password</h3>
            <div className='text-gray-500 text-center fw-semibold fs-6 mb-3'>
              Please enter the verification code we sent to
            </div>
            <h5 className='text-dark fw-semibold mb-10 text-center'>{props.data.email}</h5>

            <div className='fv-row mb-1'>
              <p className='text-dark fw-bold mb-0 fs-6 mb-1'>Type your 6 digit security code</p>
              <div className='w-350px d-flex justify-content-between'>
                {otp.map((digit, index) => (
                  <input
                    className='otp-input m-0'
                    key={index}
                    id={`otp-input-${index}`}
                    type='text'
                    maxLength={1}
                    value={digit}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                  />
                ))}
              </div>
              {codeErr && otp.join('').length !== 6 && (
                <p className='text-danger'>Enter Verification Code</p>
              )}
            </div>

            <div className='text-gray-500 text-center fw-semibold fs-6 mt-5'>
              You will also need to enter your new password.
            </div>

            <div className='w-100 fv-row mb-8 mt-3'>
              <p className='mb-0 form-label fs-6 fw-semibold text-dark'>Password</p>
              <div className='position-relative'>
                <Field
                  name='newPassword'
                  type={showNewPW ? 'text' : 'password'}
                  className='form-control pr-10'
                  placeholder='New Password'
                />
                <span onClick={toggleNewPWVisibility}>
                  <KTIcon iconName={showNewPW ? 'eye-slash' : 'eye'} className='fs-2 icon' />
                </span>
              </div>
              <ErrorMessage name='newPassword' component='div' className='text-danger' />
            </div>

            <div className='w-100 fv-row mb-8'>
              <p className='mb-0 form-label fs-6 fw-semibold text-dark'>Confirm Password</p>
              <div className='position-relative'>
                <Field
                  name='confirmPassword'
                  type={showConfirmPW ? 'text' : 'password'}
                  className='form-control pr-10'
                  placeholder='Confirm New Password'
                />
                <span onClick={toggleConfirmPWVisibility}>
                  <KTIcon iconName={showConfirmPW ? 'eye-slash' : 'eye'} className='fs-2 icon' />
                </span>
              </div>
              <ErrorMessage name='confirmPassword' component='div' className='text-danger' />
            </div>

            <div className='d-flex flex-wrap justify-content-center pb-lg-0 mt-10'>
              <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
                <span className='indicator-label'>Submit</span>
              </button>
              <Link to='/auth/login'>
                <button type='button' className='btn btn-light'>
                  Cancel
                </button>
              </Link>
            </div>
            <p className='text-danger'>{errorMsg}</p>
          </Form>
        </Formik>
      </motion.div>
    </AnimatePresence>
  )
}
