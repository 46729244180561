import React, {useState} from 'react'
import TrackReqImg from '../../master-data-management/no_data_found/time-tracking.png'
import {KTIcon} from '../../../../_metronic/helpers'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import NoDataIcon from '../../master-data-management/master-data/interview_types/job-interview.png'
import {AddLookup} from './AddLookup'
import LookupValues from './LookupValues'

function LookupValueMain({props}: any) {
  const {activeLookup, fetchData: lookupfetchData} = props
  const [popover, setPopover]: any = useState(null)
  const [id, updateId]: any = useState('')
  const CloseLookupPopover = () => {
    setPopover(null)
  }

  const openLookupPopover = (id: any) => (
    <Popover placement='auto' className='min-w-400px'>
      <AddLookup props={id} />
    </Popover>
  )
  return (
    <div>
      {activeLookup.pod_lookup_id === 0 ? (
        <div className='m-2 p-4'>
          <h3 className='fw-semibold fs-3'>Managing Lookup Page</h3>
          <p className='fw-normal'>
            Welcome to the Lookup page! Here, you have the ability to create new lookup, inactive
            existing ones, and modify lookup details as needed. We've designed this section to guide
            you through the process, ensuring that your lookup are effectively managed and up to
            date.
          </p>
          <div className='row mt-6'>
            <div className='col-lg-4 mb-6'>
              <div className='shadow-sm p-3 rounded h-100 d-flex flex-column justify-content-center align-items-center'>
                <img src={NoDataIcon} className='track-req-icon mb-5' alt='Add Friend' />
                <p className='text-center'>
                  Click on the '+ Add Lookup' button to Creat the Lookup.
                </p>
              </div>
            </div>
            <div className='col-lg-4 mb-6'>
              <div className='shadow-sm p-3 rounded h-100 d-flex flex-column justify-content-center align-items-center'>
                <img src={TrackReqImg} alt='Track Request' className='track-req-icon mb-5' />
                <p className='text-center'>
                  Keep track of the status of your created lookup. See what is active, and inactives
                  if needed.
                </p>
              </div>
            </div>
          </div>
          <div className='m-auto d-flex flex-column align-items-center'>
            <OverlayTrigger
              trigger='click'
              placement='top'
              overlay={openLookupPopover(id)}
              show={popover === 'add_lookup'}
            >
              <button
                className='btn btn-sm btn-light-primary fw-normal'
                onClick={(e) => {
                  e.stopPropagation()
                  setPopover('add_lookup')
                  updateId({lookupfetchData, CloseLookupPopover})
                }}
              >
                <KTIcon iconName='plus' className='fs-3' />
                Add Lookup
              </button>
            </OverlayTrigger>
          </div>
        </div>
      ) : (
        <LookupValues props={activeLookup} />
      )}
    </div>
  )
}

export default LookupValueMain
