import React from 'react'
// import AddFriendIcon from './user.png'
import TrackReqImg from '../../../../modules/know_more_modals/team_members_KM/time-tracking.png'
import SearchIcon from '../../../../modules/know_more_modals/team_members_KM/search.png'
import RequestIcon from '../../../../modules/know_more_modals/onboardings_KM/request.png'

function Offersknowmore() {
  return (
    <>
      <div className='row'>
        <div className='col-4 p-0'>
          <div className='w-100 h-100 p-5'>
            <div className='shadow-sm p-3 rounded h-100 d-flex flex-column justify-content-center align-items-center'>
              <img src={RequestIcon} className='track-req-icon mb-5' alt='Add Friend' />
              <p className='text-center'>
                Click on the "+OFFER" button to Create new Offers for the candidates by specifying
                the details like offer letter,location,joining data etc..
              </p>
            </div>
          </div>
        </div>
        <div className='col-4 p-0'>
          <div className='w-100 h-100 p-5'>
            <div className='shadow-sm p-3 rounded h-100 d-flex flex-column justify-content-center align-items-center'>
              <img src={TrackReqImg} alt='Track Request' className='track-req-icon mb-5' />
              <p className='text-center'>
                Monitor the status of each offer to stay informed about the progress.
              </p>
            </div>
          </div>
        </div>
        <div className='col-4 p-0'>
          <div className='w-100 h-100 p-5'>
            <div className='shadow-sm p-3 rounded h-100 d-flex flex-column justify-content-center align-items-center'>
              <img src={SearchIcon} className='track-req-icon mb-5' alt='Add Friend' />
              <p className='text-center'>
                Use the search option to narrow down your search. You can search by candidate name
                to find the details of the interview you need quickly.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Offersknowmore
