import {useEffect, useState, useContext} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../../../../_metronic/helpers'
// import '../.././index.css'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useThemeMode} from '../../../../../../../_metronic/partials'
import usePostApi from '../../../../../custom_hooks/usePostApi'
import {useAuth} from '../../../../../auth'
import {useParams} from 'react-router-dom'
import CandidateInfoContext from '../../../CandidateInfoContext'
import _ from 'lodash'

function AddExperience(props) {
  const {setCorrections, corrections, formValues, updateDataOnDB, setFormData} =
    useContext(CandidateInfoContext)
  // console.log(formValues)
  const {type, initialVals, timeStamp} = props
  const {jobJoinCode, pin} = useParams()
  const {mode} = useThemeMode()
  const {currentUser} = useAuth()

  const {execute} = usePostApi()

  const [employmentTypes, setEmploymentTypes] = useState([])
  const getEmplomentTypes = async () => {
    try {
      const url = `/employment_types/seeded`
      const formData = {
        onbr_code: jobJoinCode,
        onbr_pin: pin,
      }
      const response = await execute(url, 'POST', formData)
      setEmploymentTypes(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const [currencies, setCurrencies] = useState([])
  const getCurrencies = async () => {
    try {
      const url = `/master/currencies/get`
      const formData = {
        onbr_code: jobJoinCode,
        onbr_pin: pin,
      }
      const response = await execute(url, 'POST', formData)
      setCurrencies(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getEmplomentTypes()
    getCurrencies()
  }, [])

  const formik = useFormik({
    initialValues: {
      id: '',
      company: '',
      designation: '',
      workType: '',
      isCurrentJob: true,
      startDate: '',
      endDate: '',
      currency: '',
      annualCTC: '',
      currencyIcon: '',
    },
    validationSchema: Yup.object({
      company: Yup.string().required('Required'),
      designation: Yup.string().required('Required'),
      workType: Yup.string().required('Required'),
      startDate: Yup.string().required('Required'),
      currency: Yup.string().required('Required'),
      annualCTC: Yup.string().required('Required'),
      endDate: Yup.string()
        .when(['isCurrentJob'], {
          is: (isCurrentJob) => isCurrentJob === false,
          then: (schema) => schema.required('Required'),
          otherwise: (schema) => schema.notRequired(),
        })
        .test(
          'is-greater-than-or-equal',
          'End date must be greater than or equal to start date',
          function (endDate) {
            const {startDate} = this.parent
            return !endDate || new Date(endDate) >= new Date(startDate)
          }
        ),
    }),
    onSubmit: async (values, {resetForm}) => {
      const currIcon = encodeURI(values.currencyIcon)
      if (props.type === 'edit') {
        const originalVal = formValues._employment_education.experiences?.filter(
          (each) => each.id === formik.values.id
        )
        delete originalVal[0].status

        const currentVal = values
        delete currentVal['status']

        // console.log(_.isEqual(originalVal[0], currentVal))
        // console.log(originalVal, currentVal, values)
        // return

        // if (!_.isEqual(props.initialVals, values)) {
        // console.log(originalVal[0], currentVal)
        if (!_.isEqual(originalVal[0], currentVal)) {
          const updatedCorrections = {
            ...corrections,
            _employment_education: {
              ...corrections._employment_education,
              experiences: {
                ...corrections._employment_education.experiences,
                [values.id]: {...values, status: 'edited'},
              },
            },
          }

          setCorrections(updatedCorrections)
          await updateDataOnDB({...formValues, corrections: updatedCorrections})
        } else {
          setCorrections((prevCorrections) => {
            const newCorrections = {...prevCorrections}
            const qualifications = newCorrections._employment_education.experiences
            const newQualifications = {...qualifications}

            delete newQualifications[values.id]

            newCorrections._employment_education.experiences = newQualifications
            return newCorrections
          })
          await updateDataOnDB({...formValues, corrections: corrections})
        }
      } else if (props.type === 'editNewExp') {
        await props.editQualification(values)
      } else {
        const iniqueId = '_' + Math.random().toString(36).substr(2, 9)
        const updatedCorrections = {
          ...corrections,
          _employment_education: {
            ...corrections._employment_education,
            experiences: {
              ...corrections._employment_education.experiences,
              [iniqueId]: {
                ...values,
                id: iniqueId,
                currencyIcon: currIcon,
                status: 'New',
              },
            },
          },
        }

        await updateDataOnDB({...formValues, corrections: updatedCorrections})
        // props.addQualification(
        //   {
        //     ...values,
        //     id: '_' + Math.random().toString(36).substr(2, 9),
        //     currencyIcon: encodeURI(values.currencyIcon),
        //   },
        //   'experiences'
        // )
      }
      resetForm()
      props.setShowModal(false)
    },
  })

  useEffect(() => {
    if ((props.type === 'edit' || props.type === 'editNewExp') && props.initialVals) {
      formik.setValues({
        ...props.initialVals,
        // currencyIcon: decodeURI(props.initialVals.currencyIcon),
      })
    } else {
      const initialValues = {
        id: '',
        company: '',
        designation: '',
        workType: '',
        isCurrentJob: true,
        startDate: '',
        endDate: '',
        currency: '',
        annualCTC: '',
        currencyIcon: '',
      }
      formik.setValues(initialValues)
    }
  }, [type, initialVals, timeStamp])

  return (
    <Modal
      show={props.showModal}
      onHide={() => props.setShowModal(false)}
      centered
      dialogClassName='custom-modal modal-dialog-centered'
      contentClassName='custom-modal-content'
      backdrop='static'
    >
      <Modal.Body className='overflow-auto w-100'>
        <div className='d-flex justify-content-between mb-10'>
          <h3 className='fs-3 fw-semibold'>
            {props.type === 'edit' ? 'Edit mployment Info.' : 'Add Employment Info.'}
          </h3>
          <button
            type='button'
            className='d-block btn btn-sm btn-icon btn-light'
            onClick={() => props.setShowModal(false)}
          >
            <KTIcon iconName='cross' className='fs-1' />
          </button>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div className='d-flex flex-column flex-md-row'>
            <div className='w-100 w-md-75 me-md-2'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Company</span>
              </label>
              <div className='mb-4'>
                <input
                  id='company'
                  type='text'
                  name='company'
                  className='form-control form-control-solid'
                  placeholder='Enter company name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.company}
                />
                {formik.touched.company && formik.errors.company ? (
                  <div className='text-danger'>{formik.errors.company}</div>
                ) : null}
              </div>
            </div>
            <div className='w-100 w-md-25 ms-md-2 mb-4 md-mb-0'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className=''>Current/Active Job</span>
              </label>

              <div className='form-floating flex-fill'>
                <div className='form-check form-check-solid form-switch mb-0'>
                  <input
                    className='form-check-input w-40px h-20px'
                    type='checkbox'
                    id='toggle'
                    name='isCurrentJob'
                    checked={formik.values.isCurrentJob}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={{
                      backgroundColor: formik.values.isCurrentJob
                        ? '#009688'
                        : mode === 'dark'
                        ? '#1b1b29'
                        : '#f4f4f4',
                    }}
                  />
                  <label className='form-check-label' htmlFor='toggle'>
                    <span
                      className={`me-4 fw-semibold ${
                        formik.values.isCurrentJob ? 'text-success' : 'text-danger'
                      }`}
                    >
                      {formik.values.isCurrentJob ? 'Yes' : 'No'}
                    </span>
                  </label>
                </div>
                {formik.touched.isCurrentJob && formik.errors.isCurrentJob ? (
                  <div className='text-danger'>{formik.errors.isCurrentJob}</div>
                ) : null}
              </div>
            </div>
          </div>
          <div className='d-flex flex-column flex-md-row'>
            <div className='w-100 w-md-50 me-md-2'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Designation</span>
              </label>
              <div className='mb-4'>
                <input
                  id='designation'
                  type='text'
                  name='designation'
                  className='form-control form-control-solid'
                  placeholder='Enter your designation'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.designation}
                />
                {formik.touched.designation && formik.errors.designation ? (
                  <div className='text-danger'>{formik.errors.designation}</div>
                ) : null}
              </div>
            </div>
            <div className='w-100 w-md-50 ms-md-2'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Work Type</span>
              </label>
              <div className='mb-4'>
                <select
                  className='form-select form-select-solid'
                  name='workType'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.workType}
                >
                  <option value=''>Select option</option>
                  {props.isFresher === 'Yes' ? (
                    <option value='Internship/Apprenticeship'>Internship/Apprenticeship</option>
                  ) : (
                    employmentTypes?.map((each) => (
                      <option key={each.emp_type_id} value={each.emp_type_name}>
                        {each.emp_type_name}
                      </option>
                    ))
                  )}
                  {/* {employmentTypes?.map((each: any) => (
                    <option key={each.emp_type_id} value={each.emp_type_name}>
                      {each.emp_type_name}
                    </option>
                  ))} */}
                </select>
                {formik.touched.workType && formik.errors.workType ? (
                  <div className='text-danger'>{formik.errors.workType}</div>
                ) : null}
              </div>
            </div>
          </div>

          <div className='d-flex flex-column flex-md-row justify-content-between'>
            <div className={`${!formik.values.isCurrentJob ? 'w-100 w-md-50 me-md-2' : 'w-100'}`}>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Start Data</span>
              </label>
              <div className='mb-4'>
                <input
                  id='startDate'
                  type='date'
                  name='startDate'
                  className='form-control form-control-solid'
                  placeholder='Pick Job Start Date'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.startDate}
                />
                {formik.touched.startDate && formik.errors.startDate ? (
                  <div className='text-danger'>{formik.errors.startDate}</div>
                ) : null}
              </div>
            </div>
            {!formik.values.isCurrentJob && (
              <div className='w-100 w-md-50 ms-md-2'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>End Date</span>
                </label>
                <div className='mb-4'>
                  <input
                    id='endDate'
                    type='date'
                    name='endDate'
                    className='form-control form-control-solid'
                    placeholder='Pick Job End Date'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.endDate}
                  />
                  {formik.touched.endDate && formik.errors.endDate ? (
                    <div className='text-danger'>{formik.errors.endDate}</div>
                  ) : null}
                </div>
              </div>
            )}
          </div>
          <div className='d-flex flex-column flex-md-row justify-content-between'>
            <div className='w-100 w-md-50 me-md-2'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Currency</span>
              </label>
              <div className='mb-4'>
                <select
                  className='form-select form-select-solid'
                  name='currency'
                  onBlur={formik.handleBlur}
                  value={formik.values.currency}
                  onChange={(e) => {
                    const selectedCurrencyCode = e.target.value
                    const selectedCurrency = currencies.find(
                      (currency) => currency.currency_code === selectedCurrencyCode
                    )
                    formik.setFieldValue('currency', selectedCurrencyCode)
                    formik.setFieldValue(
                      'currencyIcon',
                      selectedCurrency ? selectedCurrency.currency_symbol : ''
                    )
                  }}
                >
                  <option value=''>Select an Option</option>
                  {currencies?.map((each) => (
                    <option key={each.currency_id} value={each.currency_code}>
                      {each.currency_code} - {each.currency_name}
                    </option>
                  ))}
                </select>

                {formik.touched.currency && formik.errors.currency ? (
                  <div className='text-danger'>{formik.errors.currency}</div>
                ) : null}
              </div>
            </div>
            <div className='w-100 w-md-50 ms-md-2'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Annual CTC</span>
              </label>
              <div className='mb-4'>
                <input
                  id='annualCTC'
                  type='text'
                  name='annualCTC'
                  className='form-control form-control-solid'
                  placeholder='Enter your annual CTC'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.annualCTC}
                />
                {formik.touched.annualCTC && formik.errors.annualCTC ? (
                  <div className='text-danger'>{formik.errors.annualCTC}</div>
                ) : null}
              </div>
            </div>
          </div>

          <div className='w-100 d-flex justify-content-end mt-5'>
            <button
              type='button'
              className='btn btn-light btn-sm me-2'
              onClick={() => props.setShowModal(false)}
            >
              Close
            </button>

            <button type='submit' className='btn btn-sm btn-light-primary ms-2'>
              <KTIcon iconName='check' className='fs-3 ms-1' />
              {props.type === 'edit' ? 'Update' : 'Add'}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default AddExperience
