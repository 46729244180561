import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import usePostApi from '../../../custom_hooks/usePostApi'
import {KTIcon} from '../../../../../_metronic/helpers'
import {UseToast} from '../../../useToast'
import {useThemeMode} from '../../../../../_metronic/partials'
const EditEmploymentType = (props: any) => {
  const {
    id: employmentTypeId,
    refreshData,
    ClosePopover,
    name,
    getstatus,
    emp_type_pod_id,
  } = props.props
  const intl = useIntl()
  const {execute, postLoading} = usePostApi()
  const {mode} = useThemeMode()
  const validationSchema = Yup.object().shape({
    employment_type_name: Yup.string().required('Employment Type Name is required'),
    status: Yup.string().required(intl.formatMessage({id: 'LABEL.STATUS_REQUIRED'})),
  })
  const initialValues = {
    employment_type_name: name || '',
    status: getstatus === 1 || '',
  }

  const handleEditSubmit = async (values: any) => {
    const url = `/pod/employment_type/update`
    const requestData = {
      emp_type_pod_id: emp_type_pod_id,
      emp_type_id: employmentTypeId,
      emp_type_name: values.employment_type_name,
      is_active: values.status ? 1 : 0,
    }
    try {
      const response = await execute(url, 'POST', requestData, 'post')
      refreshData()
      ClosePopover()
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
    } catch (error: any) {
      console.error(error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleEditSubmit}
      >
        {({values, handleChange}) => {
          return (
            <Form>
              <div className='card'>
                <div className='card-header'>
                  <h3 className='card-title fw-semibold fs-3 text-dark'>Edit Employment Type</h3>
                  <div className='card-toolbar me-2'>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-active-light me-n5'
                      onClick={() => ClosePopover()}
                    >
                      <KTIcon iconName='cross' className='fs-1' />
                    </button>
                  </div>
                </div>
                <div className='px-7 py-5'>
                  <div className='modal-body position-relative'>
                    <div>
                      <div className='mb-4'>
                        <label
                          htmlFor='floatingInput'
                          className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 required'
                        >
                          Employment Type Name
                        </label>
                        <Field
                          type='text'
                          className='form-control form-control-solid mb-1'
                          id='floatingInput'
                          placeholder='Enter Employment Type Name'
                          name='employment_type_name'
                          value={values.employment_type_name}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          component='div'
                          className='text-danger'
                          name='employment_type_name'
                        />
                      </div>
                      <div className='form-floating mb-4'>
                        <div className='form-check form-check-solid form-switch'>
                          <Field
                            className='form-check-input w-40px h-20px'
                            type='checkbox'
                            id='toggle'
                            name='status'
                            checked={values.status}
                            onChange={handleChange}
                            style={{
                              backgroundColor: values.status
                                ? '#009688'
                                : mode === 'dark'
                                ? '#1b1b29'
                                : '#f4f4f4',
                            }}
                          />
                          <span
                            className={`me-4 fw-semibold ${
                              values.status ? 'text-success' : 'text-danger'
                            }`}
                          >
                            {values.status ? 'Active' : 'Inactive'}
                          </span>
                        </div>
                        <ErrorMessage component='div' className='text-danger' name='status' />
                      </div>
                    </div>
                  </div>
                  <div className='d-flex justify-content-end'>
                    <button
                      type='reset'
                      className='btn btn-sm btn-light  me-2'
                      onClick={() => {
                        ClosePopover()
                      }}
                    >
                      {intl.formatMessage({id: 'LABEL.CANCEL'})}
                    </button>

                    <button type='submit' className='btn btn-sm btn-light-primary'>
                      {postLoading ? (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      ) : (
                        <>
                          <KTIcon iconName='check' className='fs-7' />
                          Save
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export {EditEmploymentType}
