import {useEffect, useMemo, useState} from 'react'
import {useTable, usePagination} from 'react-table'
import {Pagination} from 'react-bootstrap'
import Context from '../../../side-drawer/context'
import {FormattedMessage, useIntl} from 'react-intl'
import usePageTitle from '../../../custom_hooks/usePageTitle'
import {KTIcon} from '../../../../../_metronic/helpers'
import {SideDrawer} from '../../../side-drawer/Side-drawer'
import {FindPodIdByCode} from '../../../FindPodIdByPodCode'
import NoDataFound from '../../no_data_found/NoDataFound'
import NoDataIcon from './map.png'
import usePostApi from '../../../custom_hooks/usePostApi'
import AddLocation from './AddLocation'
import EditLocation from './EditLocation'
const DataTable = ({columns, data, loading, fetchData}) => {
  const [inputVal, setInputVal] = useState('')
  const [showModal, setShowModal] = useState(false)
  const intl = useIntl()
  usePageTitle(intl.formatMessage({id: 'MENU.ORGANIZATION_CONFIGURATION.LOCATIONS'}))
  const filteredData = useMemo(() => {
    return data.filter(
      (each) =>
        each.location_name?.toLowerCase().includes(inputVal.toLowerCase()) ||
        each.location_code?.toLowerCase().includes(inputVal.toLowerCase())
    )
  }, [data, inputVal])
  const handleOpenModal = () => {
    setShowModal(true)
  }
  const handleCloseModal = () => {
    setShowModal(false)
  }
  // Create separate datasets for active and inactive records
  const activeData = useMemo(() => {
    return filteredData.filter((record) => record.status === 1)
  }, [filteredData])

  const inactiveData = useMemo(() => {
    return filteredData.filter((record) => record.status === 0)
  }, [filteredData])

  // Table instances for active and inactive datasets
  const activeTableInstance = useTable({columns, data: activeData}, usePagination)
  const inactiveTableInstance = useTable({columns, data: inactiveData}, usePagination)

  // Extract required values from table instances
  const activePage = activeTableInstance.page
  const activePageCount = activeTableInstance.pageCount
  const activePageIndex = activeTableInstance.state.pageIndex
  const activePageSize = activeTableInstance.state.pageSize
  const inactivePage = inactiveTableInstance.page
  const inactivePageCount = inactiveTableInstance.pageCount
  const inactivePageIndex = inactiveTableInstance.state.pageIndex
  const inactivePageSize = inactiveTableInstance.state.pageSize

  // Handle pagination for active dataset
  const gotoActivePage = (pageIndex) => {
    activeTableInstance.gotoPage(pageIndex)
  }
  const setActivePageSize = (pageSize) => {
    activeTableInstance.setPageSize(pageSize)
  }
  const gotoInactivePage = (pageIndex) => {
    inactiveTableInstance.gotoPage(pageIndex)
  }

  const setInactivePageSize = (pageSize) => {
    inactiveTableInstance.setPageSize(pageSize)
  }

  const pageOptions = [5, 10, 20, 30, 40, 50]

  return (
    <Context.Consumer>
      {(value) => {
        const {id} = value
        return (
          <>
            {loading ? (
              <div className='mt-6 h-250px d-flex justify-content-center align-items-center'>
                <div className='spinner-border spinner-primary mr-15'></div>
              </div>
            ) : data.length > 0 ? (
              <div className='card  p-10 py-5 pt-0'>
                <div className='card-header border-0 px-0'>
                  <div className='d-flex flex-wrap flex-stack'>
                    <div className='fw-normal'>
                      <ul className='nav'>
                        <li className='nav-item'>
                          <a
                            className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-success  active fw-normal px-4 me-1'
                            data-bs-toggle='tab'
                            href='#kt_table_widget_4_tab_1'
                          >
                            {intl.formatMessage({id: 'LABEL.ACTIVE'})}
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a
                            className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-danger fw-normal px-4 me-1'
                            data-bs-toggle='tab'
                            href='#kt_table_widget_4_tab_2'
                          >
                            {intl.formatMessage({id: 'LABEL.INACTIVE'})}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='card-toolbar'>
                    <div className='d-flex align-items-center position-relative me-2'>
                      <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                      <input
                        type='search'
                        id='kt_filter_search'
                        className='form-control form-control-white form-control-sm w-150px ps-9 fw-normal'
                        placeholder={intl.formatMessage({id: 'LABEL.SEARCH'})}
                        value={inputVal}
                        onChange={(e) => setInputVal(e.target.value)}
                        autoComplete='off'
                      />
                    </div>
                    <div
                      className='btn btn-sm btn-light-primary fw-normal'
                      onClick={handleOpenModal}
                    >
                      <KTIcon iconName='plus' className='fs-3' />
                      Add Location
                    </div>
                  </div>
                </div>
                <div className='tab-content'>
                  <div
                    className='tab-pane fade show active table-responsive'
                    id='kt_table_widget_4_tab_1'
                  >
                    <table
                      className='table table-row-dashed align-middle table-row-gray-300'
                      {...activeTableInstance.getTableProps()}
                    >
                      <thead>
                        <tr className='fw-semibold text-muted default-cursor bg-light rounded'>
                          <th className='min-w-150px ps-3'>
                            {intl.formatMessage({id: 'LABEL.LOCATION_NAME'})}
                          </th>
                          <th className='min-w-150px'>Location Code</th>
                          <th className='min-w-100px'>
                            {intl.formatMessage({id: 'LABEL.ADDRESS'})}
                          </th>
                          <th className='min-w-100px'>
                            {intl.formatMessage({id: 'LABEL.STATUS'})}
                          </th>
                          <th className='min-w-100px'>
                            {intl.formatMessage({id: 'LABEL.ACTION'})}
                          </th>
                        </tr>
                      </thead>
                      <tbody {...activeTableInstance.getTableBodyProps()}>
                        {activePage.length > 0 ? (
                          activePage.map((row) => {
                            activeTableInstance.prepareRow(row)
                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                  <td className='' {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                  </td>
                                ))}
                              </tr>
                            )
                          })
                        ) : (
                          <tr>
                            <td className='' colSpan={5}>
                              <div className='d-flex justify-content-center'>
                                <p className='text-dark default-cursor fs-6 mt-5'>
                                  {inputVal === '' ? (
                                    intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
                                  ) : (
                                    <FormattedMessage
                                      id='MSG.NO_DATA_MATCH'
                                      values={{
                                        inputVal: <span className='text-primary'>{inputVal}</span>,
                                      }}
                                    />
                                  )}
                                </p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {activeData.length > 10 && (
                      <Pagination>
                        <Pagination.First
                          onClick={() => gotoActivePage(0)}
                          disabled={!activeTableInstance.canPreviousPage}
                        />
                        <Pagination.Prev
                          onClick={activeTableInstance.previousPage}
                          disabled={!activeTableInstance.canPreviousPage}
                        />
                        {[...Array(activePageCount)].map((_, i) => {
                          const pageToDisplay = i + 1
                          const isFirstPage = i === 0
                          const isLastPage = i === activePageCount - 1

                          if (isFirstPage || isLastPage || pageToDisplay === activePageIndex + 1) {
                            return (
                              <Pagination.Item
                                key={i}
                                active={pageToDisplay === activePageIndex + 1}
                                onClick={() => gotoActivePage(i)}
                              >
                                {pageToDisplay}
                              </Pagination.Item>
                            )
                          }
                          if (isFirstPage && pageToDisplay === 2) {
                            return <Pagination.Ellipsis key={`ellipsis-${i}`} />
                          }
                          if (isLastPage && pageToDisplay === activePageCount) {
                            return <Pagination.Ellipsis key={`ellipsis-${i}`} />
                          }
                          return null
                        })}
                        <Pagination.Next
                          onClick={activeTableInstance.nextPage}
                          disabled={!activeTableInstance.canNextPage}
                        />
                        <Pagination.Last
                          onClick={() => gotoActivePage(activePageCount - 1)}
                          disabled={!activeTableInstance.canNextPage}
                        />
                        <div className='d-flex align-items-center mx-5'>
                          <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                          <select
                            className='form-select form-select-solid fw-bold w-75px me-2'
                            value={activePageSize}
                            onChange={(e) => setActivePageSize(Number(e.target.value))}
                          >
                            {pageOptions.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Pagination>
                    )}
                  </div>
                  <div className='tab-pane fade table-responsive' id='kt_table_widget_4_tab_2'>
                    <table
                      className='table table-row-dashed align-middle table-row-gray-300'
                      {...inactiveTableInstance.getTableProps()}
                    >
                      <thead>
                        <tr className='fw-semibold text-muted default-cursor bg-light rounded'>
                          <th className='min-w-150px ps-3'>
                            {intl.formatMessage({id: 'LABEL.LOCATION_NAME'})}
                          </th>
                          <th className='min-w-150px'>Location Code</th>
                          <th className='min-w-100px'>
                            {intl.formatMessage({id: 'LABEL.ADDRESS'})}
                          </th>
                          <th className='min-w-100px'>
                            {intl.formatMessage({id: 'LABEL.STATUS'})}
                          </th>
                          <th className='min-w-100px'>
                            {intl.formatMessage({id: 'LABEL.ACTION'})}
                          </th>
                        </tr>
                      </thead>
                      <tbody {...inactiveTableInstance.getTableBodyProps()}>
                        {inactivePage.length > 0 ? (
                          inactivePage.map((row) => {
                            inactiveTableInstance.prepareRow(row)
                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                  <td className=' ' {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                  </td>
                                ))}
                              </tr>
                            )
                          })
                        ) : (
                          <tr>
                            <td className='' colSpan={5}>
                              <div className='d-flex justify-content-center'>
                                <p className='text-dark default-cursor fs-6 mt-5'>
                                  {inputVal === '' ? (
                                    intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
                                  ) : (
                                    <FormattedMessage
                                      id='MSG.NO_DATA_MATCH'
                                      values={{
                                        inputVal: <span className='text-primary'>{inputVal}</span>,
                                      }}
                                    />
                                  )}
                                </p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {inactiveData.length > 10 && (
                      <Pagination>
                        <Pagination.First
                          onClick={() => gotoInactivePage(0)}
                          disabled={!inactiveTableInstance.canPreviousPage}
                        />
                        <Pagination.Prev
                          onClick={inactiveTableInstance.previousPage}
                          disabled={!inactiveTableInstance.canPreviousPage}
                        />
                        {[...Array(inactivePageCount)].map((_, i) => {
                          const pageToDisplay = i + 1
                          const isFirstPage = i === 0
                          const isLastPage = i === inactivePageCount - 1

                          if (
                            isFirstPage ||
                            isLastPage ||
                            pageToDisplay === inactivePageIndex + 1
                          ) {
                            return (
                              <Pagination.Item
                                key={i}
                                active={pageToDisplay === inactivePageIndex + 1}
                                onClick={() => gotoInactivePage(i)}
                              >
                                {pageToDisplay}
                              </Pagination.Item>
                            )
                          }
                          if (isFirstPage && pageToDisplay === 2) {
                            return <Pagination.Ellipsis key={`ellipsis-${i}`} />
                          }
                          if (isLastPage && pageToDisplay === inactivePageCount) {
                            return <Pagination.Ellipsis key={`ellipsis-${i}`} />
                          }
                          return null
                        })}
                        <Pagination.Next
                          onClick={inactiveTableInstance.nextPage}
                          disabled={!inactiveTableInstance.canNextPage}
                        />
                        <Pagination.Last
                          onClick={() => gotoInactivePage(inactivePageCount - 1)}
                          disabled={!inactiveTableInstance.canNextPage}
                        />
                        <div className='d-flex align-items-center mx-5'>
                          <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                          <select
                            className='form-select form-select-solid fw-semibold w-75px me-2'
                            value={inactivePageSize}
                            onChange={(e) => setInactivePageSize(Number(e.target.value))}
                          >
                            {pageOptions.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Pagination>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className='m-2 p-4'>
                <NoDataFound
                  data={{
                    name: 'Locations',
                    smallname: 'locations',
                    icon: {NoDataIcon},
                    buttonLabel: '"+ Add Location"',
                  }}
                />
                <div className='m-auto d-flex flex-column align-items-center'>
                  <button
                    className='btn btn-sm btn-light-primary fw-normal'
                    onClick={handleOpenModal}
                  >
                    <KTIcon iconName='plus' className='fs-3' />
                    Add Location
                  </button>
                </div>
              </div>
            )}
            <SideDrawer value={id} />
            <AddLocation
              className='modal-backdrop'
              showModal={showModal}
              setShowModal={setShowModal}
              fetchData={fetchData}
              closeModal={handleCloseModal}
            />
          </>
        )
      }}
    </Context.Consumer>
  )
}

const Locations = () => {
  const podId = FindPodIdByCode()
  const [TableData, setTableData] = useState([])
  const {execute, loading: isLoading} = usePostApi()
  const fetchData = async () => {
    try {
      const url = `/pod/locations/list`
      const formData = {pod_id: podId}
      const response = await execute(url, 'POST', formData)
      setTableData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [showModal, setShowModal] = useState(false)
  const [selectedLocationData, setSelectedLocationData] = useState(null)
  const handleOpenModal = (locationData) => {
    setSelectedLocationData(locationData)
    setShowModal(true)
  }
  const handleCloseModal = () => {
    setShowModal(false)
  }
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'location_name',
        Cell: ({row}) => {
          return (
            <div className='d-flex align-items-center'>
              <div className='d-flex justify-content-start flex-column'>
                <p className='fw-semibold text-dark text-hover-primary fs-6 default-cursor ps-3'>
                  {row.original.location_name}
                </p>
              </div>
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'CODE',
        accessor: 'code',
        Cell: ({row}) => {
          return (
            <p className='fw-normal text-dark text-hover-primary d-block fs-6 default-cursor'>
              {row.original.location_code}
            </p>
          )
        },
      },
      {
        Header: 'Address',
        accessor: 'Address',
        Cell: ({row}) => {
          return (
            <div className='d-flex align-items-center'>
              <div className='d-flex justify-content-start flex-column'>
                <p className='text-dark text-hover-primary fs-6 default-cursor fw-normal'>
                  {row.original.address}
                </p>
              </div>
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({row}) => {
          const statusColor = row.original.status === 1 ? 'success' : 'danger'
          return (
            <p className={`text-${statusColor} fw-normal text-hover-primary fs-6 default-cursor`}>
              {row.original.status === 1 ? 'Active' : 'Inactive'}
            </p>
          )
        },
      },
      {
        Header: 'Actions',
        accessor: 'Actions',
        Cell: ({row}) => {
          return (
            <div
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
              onClick={() =>
                handleOpenModal({
                  location_name: row.original.location_name,
                  address: row.original.address,
                  city: row.original.city,
                  state: row.original.state,
                  status: row.original.status,
                  place: row.original.place,
                  postal_code: row.original.postal_code,
                  country: row.original.country,
                  location_code: row.original.location_code,
                  location_id: row.original.location_id,
                  latitude: row.original.loc_lat,
                  longitude: row.original.loc_lng,
                })
              }
            >
              <KTIcon iconName='pencil' className='fs-3' />
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <>
      <DataTable
        columns={columns}
        data={TableData ? TableData : []}
        loading={isLoading}
        fetchData={fetchData}
      />
      <EditLocation
        showModal={showModal}
        className='modal-backdrop'
        setShowModal={setShowModal}
        locationData={selectedLocationData}
        fetchData={fetchData}
        closeModal={handleCloseModal}
      />
    </>
  )
}

export default Locations
