import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {FormikHelpers} from 'formik'
import usePostApi from '../../custom_hooks/usePostApi'
import {FindPodIdByCode} from '../../FindPodIdByPodCode'
import {UseToast} from '../../useToast'
import {KTIcon} from '../../../../_metronic/helpers'
const AddLookupValue = ({props}: any) => {
  const {fetchData, ClosePopover, pod_lookup_id} = props
  const podId = FindPodIdByCode()
  const {execute, postLoading} = usePostApi()
  const validationSchema = Yup.object().shape({
    lookup_name: Yup.string().required('Lookup Name is required'),
  })

  const handleSubmit = async (values: any, {resetForm}: FormikHelpers<any>) => {
    const url = `/pod/lookup-values/create`
    try {
      const requestData = {
        pod_id: podId,
        pod_lookup_id: pod_lookup_id,
        lookup_value: values.lookup_name,
      }
      const response = await execute(url, 'POST', requestData, 'post')
      resetForm()
      fetchData()
      ClosePopover()
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
    } catch (error: any) {
      console.error(error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }
  const handleCancel = (resetForm: any) => {
    resetForm()
  }
  return (
    <div>
      <Formik
        initialValues={{
          lookup_name: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({values, resetForm}) => {
          return (
            <Form>
              <div className='card'>
                <div className='card-header'>
                  <h3 className='card-title fw-semibold fs-3 text-dark'>Add Lookup Value</h3>
                  <div className='card-toolbar me-2'>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-active-light me-n5'
                      onClick={() => ClosePopover()}
                    >
                      <KTIcon iconName='cross' className='fs-1' />
                    </button>
                  </div>
                </div>
                <div className='px-7 py-5'>
                  <div className='modal-body position-relative'>
                    <div>
                      <div className='mb-4'>
                        <label
                          htmlFor='floatingInput'
                          className='d-flex align-items-center fs-6 fw-semibold form-label mb-2 required'
                        >
                          Lookup Name
                        </label>
                        <Field
                          type='text'
                          className='form-control form-control-solid mb-1'
                          id='floatingInput'
                          placeholder='Enter Lookup Name'
                          name='lookup_name'
                        />
                        <ErrorMessage component='div' className='text-danger' name='lookup_name' />
                      </div>
                      <div className='d-flex justify-content-end'>
                        <button
                          type='reset'
                          onClick={() => {
                            handleCancel(resetForm)
                            ClosePopover()
                          }}
                          className='btn btn-sm btn-light me-2'
                        >
                          Cancel
                        </button>
                        <button
                          type='submit'
                          className='btn btn-sm btn-light-primary'
                          data-kt-menu-dismiss='true'
                        >
                          {postLoading ? (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          ) : (
                            <>
                              <KTIcon iconName='check' className='fs-7' />
                              Save
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export {AddLookupValue}
