import {useState, useContext, useMemo} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../_metronic/helpers'
import CreateNewPodCustomModal from '../create-new-pod-wizard/CreateNewPodModal'
import PodCreateKM from '../know_more_modals/pod_create_KM/PodCreateKM'
import {PageTitle} from '../../../_metronic/layout/core'
import usePostApi from '../custom_hooks/usePostApi'
import secureLocalStorage from 'react-secure-storage'
import AppContext from '../../AppContext'
import {useIntl, FormattedMessage} from 'react-intl'

function MyPodsMain() {
  const {podsData, getPodsData, isLoading} = useContext(AppContext)
  const intl = useIntl()
  // const podsData: any = []
  // const {execute, loading: isLoading} = usePostApi()
  // const [podsData, setPodsData] = useState([])
  // const getPodsData = async () => {
  //   try {
  //     const url = '/pod/list'
  //     const formData = {}

  //     const response = await execute(url, 'POST', formData)
  //     setPodsData(response.data)
  //     secureLocalStorage.setItem('jb_pods_list', response?.data)
  //   } catch (error) {
  //     console.error('Error:', error)
  //   }
  // }

  const [showModal, setShowModal] = useState(false)
  const handleOpenModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }
  const myPods = [
    {
      title: 'My Account',
      path: `/my-pods`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: ``,
      path: `/my-pods`,
      isSeparator: true,
      isActive: false,
    },
    {
      title: `My PODs`,
      path: `/my-pods`,
      isSeparator: false,
      isActive: false,
    },
  ]

  const [inputVal, setInputVal] = useState('')

  const filteredData = useMemo(() => {
    return podsData
      ? podsData.filter((each: any) => {
          const pod_brand_name =
            each.pod_brand_name &&
            each.pod_brand_name.toLowerCase().includes(inputVal.toLowerCase())
          const pod_business_name =
            each.pod_business_name &&
            each.pod_business_name.toLowerCase().includes(inputVal.toLowerCase())
          const url = each.url && each.url.toLowerCase().includes(inputVal.toLowerCase())
          const pod_industry_name =
            each.pod_industry_name &&
            each.pod_industry_name.toLowerCase().includes(inputVal.toLowerCase())
          return pod_brand_name || url || pod_business_name || pod_industry_name
        })
      : []
  }, [inputVal, podsData])

  return (
    <>
      <PageTitle breadcrumbs={myPods}>My PODs</PageTitle>
      {isLoading ? (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : (
        <>
          {isLoading === false && (podsData || []).length === 0 ? (
            <>
              <PodCreateKM />
              <div className='d-flex justify-content-center pt-5'>
                <div className='d-flex flex-column justify-content-center'>
                  <h6 className='fw-normal'>Select a POD from above or</h6>
                  <button className='btn btn-light-primary btn-sm' onClick={handleOpenModal}>
                    <KTIcon iconName='plus' className='fs-2' />
                    Create New POD
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='d-flex flex-wrap justify-content-between align-items-center mb-5'>
                <h3 className='fw-semibold'>Available PODs in Your Account</h3>
                <div className='d-flex flex-wrap mt-3 mt-md-0'>
                  {' '}
                  <div className='d-flex align-items-center position-relative me-4 mb-1 mb-md-0'>
                    <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                    <input
                      type='search'
                      id='kt_filter_search'
                      className='form-control form-control-white form-control-sm w-150px ps-9 fw-normal'
                      placeholder='Search'
                      value={inputVal}
                      onChange={(e) => setInputVal(e.target.value)}
                    />
                  </div>
                  <div className='d-flex mt-3 mt-md-0'>
                    {' '}
                    <button
                      className='btn btn-sm btn-light-primary fw-normal mb-1 mb-md-0'
                      onClick={handleOpenModal}
                    >
                      <KTIcon iconName='plus' className='fs-3' /> Create New POD
                    </button>
                    <button
                      className='btn btn-sm btn-light-primary fw-normal mb-1 mb-md-0 ms-4'
                      onClick={() => getPodsData()}
                      data-bs-toggle='tooltip'
                      title='Click to load the latest data.'
                    >
                      <i className='ki-solid ki-arrows-circle fs-2'></i>
                    </button>
                  </div>
                </div>
              </div>

              <div className='row g-5 g-xl-5'>
                {/* {(podsData || []).map((each: any, index: number) => { */}
                {(filteredData || []).map((each: any, index: number) => {
                  let color = each.pod_pref_color ? each.pod_pref_color.substring(1) : ''
                  const brandName = encodeURIComponent(each.pod_brand_name)
                  return (
                    <div key={index} className='col-xl-4'>
                      <Link
                        to={`/${each.pod_code}/dashboard`}
                        className={`card bgi-no-repeat card-xl-stretch mb-xl-6`}
                        style={{
                          backgroundPosition: 'right top',
                          backgroundSize: '30% auto',
                          backgroundImage: `url(${toAbsoluteUrl(
                            '/media/svg/shapes/abstract-2.svg'
                          )})`,
                        }}
                      >
                        <div className='card-body d-flex'>
                          <div>
                            <img
                              src={
                                each.pod_logo
                                  ? each.pod_logo
                                  : `https://api-dev.jetboard.site/get/profile?name=${brandName}&color=${color}&size=128}`
                              }
                              alt=''
                              className='h-100px w-100px me-3'
                              style={{borderRadius: '8px'}}
                            />
                          </div>
                          <div>
                            <p className='card-title fw-semibold fs-4 m-0 text-primary'>
                              {each.pod_brand_name}
                            </p>
                            <div className='fw-normal text-muted mb-2 mt-0'>
                              {each?.pod_business_name || ''}
                            </div>
                            <div className='d-flex flex-column flex-wrap fw-normal fs-6 pe-2 mb-2'>
                              <p className='d-flex align-items-start text-muted text-hover-primary mb-0 default-cursor'>
                                <i className='ki-duotone ki-bank fs-4 me-1'>
                                  <span className='path1'></span>
                                  <span className='path2'></span>
                                </i>
                                {each?.pod_industry_name || ''}
                              </p>
                              {each.url && (
                                <p className='d-flex align-items-center text-muted text-hover-primary mb-0 default-cursor fw-normal'>
                                  <i className='ki-duotone ki-disconnect fs-4 me-1'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                    <span className='path3'></span>
                                    <span className='path4'></span>
                                    <span className='path5'></span>
                                  </i>
                                  <span>{each?.url || '-'}</span>
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* end::Body */}
                      </Link>
                    </div>
                  )
                })}
                {filteredData.length === 0 && (
                  <div className='card d-flex justify-content-center align-items-center h-250px'>
                    <h3 className='fw-semibold'>
                      {inputVal === '' ? (
                        intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
                      ) : (
                        <FormattedMessage
                          id='MSG.NO_DATA_MATCH'
                          values={{
                            inputVal: <span className='text-primary'>{inputVal}</span>,
                          }}
                        />
                      )}
                    </h3>
                  </div>
                )}
              </div>
            </>
          )}

          {/* <div className='d-flex justify-content-center pt-5'>
            <div className='d-flex flex-column justify-content-center'>
              <h6 className='fw-normal'>Select a POD from above or</h6>
              <button className='btn btn-light-primary btn-sm' onClick={handleOpenModal}>
                <KTIcon iconName='plus' className='fs-2' />
                Create New POD
              </button>
            </div>
          </div> */}
          <CreateNewPodCustomModal
            show={showModal}
            onClose={handleCloseModal}
            fetchPODsList={getPodsData}
          />
        </>
      )}
    </>
  )
}

export default MyPodsMain
