import clsx from 'clsx'
import {Link, useLocation} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {KTIcon, checkIsActive} from '../../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
const MasterData = () => {
  const {pathname} = useLocation()
  const {id} = useParams()
  const intl = useIntl()
  const isActiveBusinessUnit = () => {
    return (
      pathname.startsWith(`/${id}/configuration/master-data`) &&
      !pathname.startsWith(`/${id}/configuration/master-data/qualifications`) &&
      !pathname.startsWith(`/${id}/configuration/master-data/employment-type`) &&
      !pathname.startsWith(`/${id}/configuration/master-data/designations`) &&
      !pathname.startsWith(`/${id}/configuration/master-data/locations`) &&
      !pathname.startsWith(`/${id}/configuration/master-data/departments`) &&
      !pathname.startsWith(`/${id}/configuration/master-data/job-roles`) &&
      !pathname.startsWith(`/${id}/configuration/master-data/job-titles`) &&
      !pathname.startsWith(`/${id}/configuration/master-data/interview-types`)
    )
  }

  return (
    <div>
      <div className={`card mb-4 p-5`}>
        <div className='menu-item'>
          <Link
            className={clsx(
              'menu-link without-sub btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-semibold',
              {
                active: isActiveBusinessUnit(),
              }
            )}
            to={`/${id}/configuration/master-data/business-unit`}
            style={{padding: '10px', marginBottom: '0px !important'}}
          >
            <span className='menu-icon'>
              <KTIcon iconName='bank' className='fs-2' />
            </span>
            <span className='menu-title fw-semibold'>Business Units</span>
          </Link>
        </div>
        <div className='menu-item'>
          <Link
            className={clsx(
              'menu-link without-sub btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-semibold',
              {
                active: checkIsActive(pathname, `/${id}/configuration/master-data/departments`),
              }
            )}
            to={`/${id}/configuration/master-data/departments`}
            style={{padding: '10px', marginBottom: '0px !important'}}
          >
            <span className='menu-icon'>
              <KTIcon iconName='home' className='fs-2' />
            </span>
            <span className='menu-title fw-semibold'>
              {intl.formatMessage({id: 'MENU.ORGANIZATION_CONFIGURATION.DEPARTMENTS'})}
            </span>
          </Link>
        </div>
        <div className='menu-item'>
          <Link
            className={clsx(
              'menu-link without-sub btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-semibold',
              {
                active: checkIsActive(pathname, `/${id}/configuration/master-data/locations`),
              }
            )}
            style={{padding: '10px', marginBottom: '0px !important'}}
            to={`/${id}/configuration/master-data/locations`}
          >
            <span className='menu-icon'>
              <KTIcon iconName='geolocation' className='fs-2' />
            </span>
            <span className='menu-title fw-semibold'>
              {intl.formatMessage({id: 'MENU.ORGANIZATION_CONFIGURATION.LOCATIONS'})}
            </span>
          </Link>
        </div>
        <div className='menu-item'>
          <Link
            className={clsx(
              'menu-link without-sub btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-semibold',
              {
                active: checkIsActive(pathname, `/${id}/configuration/master-data/designations`),
              }
            )}
            style={{padding: '10px', marginBottom: '0px !important'}}
            to={`/${id}/configuration/master-data/designations`}
          >
            <span className='menu-icon'>
              <KTIcon iconName='teacher' className='fs-2' />
            </span>
            <span className='menu-title fw-semibold'>
              {intl.formatMessage({id: 'MENU.HR_CONFIGURATION.DESIGNATIONS'})}
            </span>
          </Link>
        </div>
        <div className='menu-item'>
          <Link
            className={clsx(
              'menu-link without-sub btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-semibold',
              {
                active: checkIsActive(pathname, `/${id}/configuration/master-data/job-roles`),
              }
            )}
            to={`/${id}/configuration/master-data/job-roles`}
            style={{padding: '10px', marginBottom: '0px !important'}}
          >
            <span className='menu-icon'>
              <KTIcon iconName='user-square' className='fs-2' />
            </span>
            <span className='menu-title fw-semibold'>
              {intl.formatMessage({id: 'MENU.HR_CONFIGURATION.JOB_ROLES'})}
            </span>
          </Link>
        </div>
        <div className='menu-item'>
          <Link
            className={clsx(
              'menu-link without-sub btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-semibold',
              {
                active: checkIsActive(pathname, `/${id}/configuration/master-data/job-titles`),
              }
            )}
            to={`/${id}/configuration/master-data/job-titles`}
            style={{padding: '10px', marginBottom: '0px !important'}}
          >
            <span className='menu-icon'>
              <KTIcon iconName='subtitle' className='fs-2' />
            </span>
            <span className='menu-title fw-semibold'>
              {intl.formatMessage({id: 'MENU.HR_CONFIGURATION.JOB_TITLES'})}
            </span>
          </Link>
        </div>
        <div className='menu-item'>
          <Link
            className={clsx(
              'menu-link without-sub btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-semibold',
              {
                active: checkIsActive(pathname, `/${id}/configuration/master-data/interview-types`),
              }
            )}
            to={`/${id}/configuration/master-data/interview-types`}
            style={{padding: '10px', marginBottom: '0px !important'}}
          >
            <span className='menu-icon'>
              <KTIcon iconName='subtitle' className='fs-2' />
            </span>
            <span className='menu-title fw-semibold'>Interview Types</span>
          </Link>
        </div>
        <div className='menu-item'>
          <Link
            className={clsx(
              'menu-link without-sub btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-semibold',
              {
                active: checkIsActive(pathname, `/${id}/configuration/master-data/qualifications`),
              }
            )}
            to={`/${id}/configuration/master-data/qualifications`}
            style={{padding: '10px', marginBottom: '0px !important'}}
          >
            <span className='menu-icon'>
              <KTIcon iconName='book-square' className='fs-2' />
            </span>
            <span className='menu-title fw-semibold'>Qualifications</span>
          </Link>
        </div>
        <div className='menu-item'>
          <Link
            className={clsx(
              'menu-link without-sub btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-semibold',
              {
                active: checkIsActive(pathname, `/${id}/configuration/master-data/employment-type`),
              }
            )}
            to={`/${id}/configuration/master-data/employment-type`}
            style={{padding: '10px', marginBottom: '0px !important'}}
          >
            <span className='menu-icon'>
              <KTIcon iconName='user-edit' className='fs-2' />
            </span>
            <span className='menu-title fw-semibold'>Employment Type</span>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default MasterData
