import React, {useContext, useState} from 'react'
import {FamilyEmployerRef} from './family_emp_ref/FamilyEmployerRef'
import {KTIcon} from '../../../../../_metronic/helpers'
import CandidateInfoContext from '../CandidateInfoContext'
import AddFamilyMember from './family_emp_ref/family-emp-ref/AddFamilyMember'
import AddEmpReference from './family_emp_ref/family-emp-ref/AddReferenceEmp'
import _ from 'lodash'

const FamilyAndEmpRef = ({
  data,
  formValues,
  setFormValues,
  addFamilyMember,
  deleteFamilyMember,
  editFamilyMember,
  candidateData,
  updateData,
  postLoading,
  structure,
  edit: editMode,
  closeEditSec,
}: any) => {
  const {employer_references, family_members, customFields} = data || {}
  const {
    corrections: contextCorrections,
    setCorrections: setContextCorrections,
    updateDataOnDB,
  } = useContext(CandidateInfoContext)
  // console.log(contextCorrections)
  const {_family_employer_reference} = contextCorrections
  const {
    family_members: correctionFamMems,
    employer_references: correctionReferences,
    customFields: correctionCustomFields,
  } = _family_employer_reference
  // console.log(candidateData)

  // const [editMode, setEditMode] = useState(false)
  const isDataAvailable =
    employer_references.length === 0 &&
    family_members.length === 0 &&
    Object.keys(customFields).length === 0

  const [showAddFamModal, setShowAddFamModal] = useState(false)
  const [showAddRefEmp, setShowAddRefEmp] = useState(false)

  const [famIndex, setFamIndex] = useState<null | number>(null)
  const [famInitialVals, setFamInitialVals]: any = useState({})

  const [empRefIndex, setEmpRefIndex] = useState<null | number>(null)
  const [refEmpInitialVals, setRefEmpInitialVals]: any = useState({})

  const editAddedFamilyMember = async (values: any, index: number) => {
    const updatedCorrections = {
      ...contextCorrections,
      _family_employer_reference: {
        ...contextCorrections._family_employer_reference,
        family_members: {
          ...contextCorrections._family_employer_reference.family_members,
          [values.id]: {
            ...contextCorrections._family_employer_reference.family_members[values.id],
            ...values,
          },
        },
      },
    }

    await updateDataOnDB({...candidateData, corrections: updatedCorrections})
  }

  const editAddedEmpRef = async (values: any, index: number) => {
    const updatedCorrections = {
      ...contextCorrections,
      _family_employer_reference: {
        ...contextCorrections._family_employer_reference,
        employer_references: {
          ...contextCorrections._family_employer_reference.employer_references,
          [values.id]: {
            ...contextCorrections._family_employer_reference.employer_references[values.id],
            ...values,
          },
        },
      },
    }

    await updateDataOnDB({...candidateData, corrections: updatedCorrections})
  }

  const updateFamilyOrReferences = async (
    sectionName: string, // 'qualifications' or 'certifications'
    itemId: string, // qualificationId or certificationId
    key: string,
    newValue: any
  ) => {
    // Create a copy of the current contextCorrections
    const updatedCorrections = {...contextCorrections}
    const section = updatedCorrections._family_employer_reference[sectionName]

    if (!section[itemId]) {
      console.warn(`${sectionName.slice(0, -1)} with ID "${itemId}" not found.`)
      return
    }

    section[itemId][key] = newValue

    setContextCorrections(updatedCorrections)

    const originalVal = candidateData._family_employer_reference[sectionName]?.find(
      (each: any) => each.id === itemId
    )

    const currentVal = {...updatedCorrections._family_employer_reference[sectionName][itemId]}

    delete originalVal?.status
    delete currentVal.status

    if (_.isEqual(originalVal, currentVal)) {
      delete updatedCorrections._family_employer_reference[sectionName][itemId]
      setContextCorrections(updatedCorrections)
    }

    await updateDataOnDB({...candidateData, corrections: updatedCorrections})
  }

  const deleteCustomFieldsInEmpEdu = async (id: string) => {
    const updatedCorrections = {
      ...contextCorrections,
      _family_employer_reference: {
        ...contextCorrections._family_employer_reference,
        customFields: Object.fromEntries(
          Object.entries(contextCorrections._family_employer_reference.customFields).filter(
            ([key]) => key !== id
          )
        ),
      },
    }

    // setCorrections(updatedCorrections)
    // await updateData({...formValues, corrections: updatedCorrections})
    await updateData({...candidateData, corrections: updatedCorrections})
  }

  const getCurrentUI = () => {
    if (editMode) {
      return (
        <FamilyEmployerRef
          formValues={formValues}
          setFormValues={setFormValues}
          addFamilyMember={addFamilyMember}
          deleteFamilyMember={deleteFamilyMember}
          editFamilyMember={editFamilyMember}
          candidateData={candidateData}
          updateData={updateData}
          postLoading={postLoading}
          structure={structure}
          setEditMode={closeEditSec}
        />
      )
    } else {
      return (
        <>
          {/* <div className='d-flex justify-content-end mb-5'>
            <button className='btn btn-light-primary btn-sm ms-2' onClick={() => setEditMode(true)}>
              <KTIcon iconName='pencil' className='fs-5' />
              Edit
            </button>
          </div> */}
          <div className='min-w-100 d-flex flex-wrap m-auto'>
            {customFields &&
              Object.entries(customFields).map(([key, value]: any, index) => {
                const {label, value: val} = value
                const correction = correctionCustomFields[key]
                const isDefault = correction ? correction['value'] !== val : false
                if (val === '_sep_a_ra_tor') {
                  return (
                    <div key={index} className='w-100'>
                      <h3 className='mb-3 text-primary'>
                        <p
                          className='alert bg-light text-center text-muted'
                          style={{
                            width: '100%',
                            verticalAlign: 'middle',
                            fontSize: '12px',
                            textTransform: 'uppercase',
                          }}
                        >
                          <span className='fw-semibold'>{label}</span>
                        </p>
                      </h3>
                    </div>
                  )
                } else {
                  return (
                    <div key={index} className='w-50 row mb-5'>
                      <label className='col-lg-4 fw-semibold text-muted'>
                        {label.replace(/_/g, ' ')}
                      </label>
                      <div className='col-lg-8'>
                        {correction && isDefault ? (
                          <>
                            <span className='fs-6 default-cursor text-decoration-line-through text-danger'>
                              {val || '-'}
                            </span>
                            <br />
                            <div className='d-flex align-items-center'>
                              <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                {correction?.value || '-'}
                              </span>
                              <button
                                className='btn btn-unstyled m-0 p-0'
                                data-bs-toggle='tooltip'
                                title='Revert changes'
                                onClick={() => deleteCustomFieldsInEmpEdu(key)}
                              >
                                <KTIcon iconName='cross-circle' className='text-danger' />
                              </button>
                            </div>
                          </>
                        ) : (
                          <span className='fs-6 text-dark text-hover-primary default-cursor'>
                            {val || '-'}
                          </span>
                        )}
                        {/* <span className='fs-6 text-dark text-hover-primary default-cursor'>
                          {val || '-'}
                        </span> */}
                      </div>
                    </div>
                  )
                }
              })}
          </div>
          <h3 className='mb-0 text-primary'>
            <p
              className='alert bg-light text-center text-muted'
              style={{
                width: '100%',
                verticalAlign: 'middle',
                fontSize: '12px',
                textTransform: 'uppercase',
              }}
            >
              <span className='fw-semibold'>Family Members</span>
            </p>
          </h3>

          <table className='table table-row-dashed table-row-gray-300 align-middle'>
            <thead>
              <tr className='fw-semibold text-muted default-cursor bg-light-primary rounded'>
                <th className='fw-semibold text-muted ps-3'>Name</th>
                <th className='fw-semibold text-muted'>Relationship</th>
                <th className='fw-semibold text-muted'>age</th>
                <th className='fw-semibold text-muted'>Occupation</th>
              </tr>
            </thead>
            <tbody>
              {family_members.length ? (
                family_members.map((member: any, index: number) => {
                  const correction = correctionFamMems[member.id]
                  // const correction =
                  //   correctionQuals.find((cor: any) => cor.id === qualification.id) || {}

                  const condition =
                    correction &&
                    Object.keys(correction).length > 0 &&
                    !_.isEqual(member, correction)
                  // console.log(qualification, correction, condition)

                  if (member.status && member.status === 'deleted') {
                    return null
                  }

                  return (
                    <tr key={index} style={{marginTop: '10px'}}>
                      <td className='ps-3'>
                        <div>
                          {condition && correction['name'] !== member['name'] ? (
                            <>
                              <span className='text-decoration-line-through text-danger'>
                                {member.name}
                              </span>
                              <br />

                              <div className='d-flex align-items-center'>
                                <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                  {correction?.name || '-'}
                                </span>
                                <button
                                  className='btn btn-unstyled m-0 p-0'
                                  data-bs-toggle='tooltip'
                                  title='Revert changes'
                                  onClick={() =>
                                    updateFamilyOrReferences(
                                      'family_members',
                                      member.id,
                                      'name',
                                      member.name
                                    )
                                  }
                                >
                                  <KTIcon iconName='cross-circle' className='text-danger' />
                                </button>
                              </div>
                            </>
                          ) : (
                            <span>{member.name}</span>
                          )}
                        </div>
                      </td>
                      <td>
                        <div>
                          {condition && correction['relationship'] !== member['relationship'] ? (
                            <>
                              <span className='text-decoration-line-through text-danger'>
                                {member.relationship}
                              </span>
                              <br />

                              <div className='d-flex align-items-center'>
                                <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                  {correction?.relationship || '-'}
                                </span>
                                <button
                                  className='btn btn-unstyled m-0 p-0'
                                  data-bs-toggle='tooltip'
                                  title='Revert changes'
                                  onClick={() =>
                                    updateFamilyOrReferences(
                                      'family_members',
                                      member.id,
                                      'relationship',
                                      member.relationship
                                    )
                                  }
                                >
                                  <KTIcon iconName='cross-circle' className='text-danger' />
                                </button>
                              </div>
                            </>
                          ) : (
                            <span>{member.relationship}</span>
                          )}
                        </div>
                      </td>
                      <td>
                        <div>
                          {condition && correction['age'] !== member['age'] ? (
                            <>
                              <span className='text-decoration-line-through text-danger'>
                                {member.age}
                              </span>
                              <br />

                              <div className='d-flex align-items-center'>
                                <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                  {correction?.age || '-'}
                                </span>
                                <button
                                  className='btn btn-unstyled m-0 p-0'
                                  data-bs-toggle='tooltip'
                                  title='Revert changes'
                                  onClick={() =>
                                    updateFamilyOrReferences(
                                      'family_members',
                                      member.id,
                                      'age',
                                      member.age
                                    )
                                  }
                                >
                                  <KTIcon iconName='cross-circle' className='text-danger' />
                                </button>
                              </div>
                            </>
                          ) : (
                            <span>{member.age}</span>
                          )}
                        </div>
                      </td>
                      <td>
                        <div>
                          {condition && correction['occupation'] !== member['occupation'] ? (
                            <>
                              <span className='text-decoration-line-through text-danger'>
                                {member.occupation}
                              </span>
                              <br />

                              <div className='d-flex align-items-center'>
                                <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                  {correction?.occupation || '-'}
                                </span>
                                <button
                                  className='btn btn-unstyled m-0 p-0'
                                  data-bs-toggle='tooltip'
                                  title='Revert changes'
                                  onClick={() =>
                                    updateFamilyOrReferences(
                                      'family_members',
                                      member.id,
                                      'occupation',
                                      member.occupation
                                    )
                                  }
                                >
                                  <KTIcon iconName='cross-circle' className='text-danger' />
                                </button>
                              </div>
                            </>
                          ) : (
                            <span>{member.occupation}</span>
                          )}
                        </div>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr style={{marginTop: '10px'}}>
                  <td colSpan={4}>
                    <div className='d-flex justify-content-center'>
                      <p className='text-dark default-cursor d-block fs-6 m-0 mt-5 ps-3'>N.A</p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {Object.values(correctionFamMems).map((each: any, index: number) => {
            if (each.status && each.status === 'New') {
              return (
                <div key={each.id} className='bg-light-success p-3 mb-3 rounded'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='w-75 d-flex align-items-center'>
                      <div className='d-flex flex-row align-items-center'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          height='44px'
                          viewBox='0 -960 960 960'
                          width='54px'
                          fill='#5f6368'
                        >
                          <path d='M720-720q-33 0-56.5-23.5T640-800q0-33 23.5-56.5T720-880q33 0 56.5 23.5T800-800q0 33-23.5 56.5T720-720ZM680-80v-320q0-40-20.5-72T607-522l35-103q8-25 29.5-40t48.5-15q27 0 48.5 15t29.5 40l102 305H800v240H680ZM500-500q-25 0-42.5-17.5T440-560q0-25 17.5-42.5T500-620q25 0 42.5 17.5T560-560q0 25-17.5 42.5T500-500ZM220-720q-33 0-56.5-23.5T140-800q0-33 23.5-56.5T220-880q33 0 56.5 23.5T300-800q0 33-23.5 56.5T220-720ZM140-80v-280H80v-240q0-33 23.5-56.5T160-680h120q33 0 56.5 23.5T360-600v240h-60v280H140Zm300 0v-160h-40v-160q0-25 17.5-42.5T460-460h80q25 0 42.5 17.5T600-400v160h-40v160H440Z' />
                        </svg>
                        <div className='text-muted ms-5'>
                          <h6 className='fw-semibold m-0'>
                            {each.name}, {each.age}
                          </h6>
                          <span className='m-0'>{each.relationship}</span>
                          <br />
                          <span className='m-0'>{each.occupation}</span>
                        </div>
                      </div>
                    </div>
                    <div className=''>
                      <button
                        className='btn btn-light-primary btn-sm me-2'
                        onClick={() => {
                          setFamIndex(index)
                          setFamInitialVals(each)
                          setShowAddFamModal(true)
                        }}
                      >
                        <KTIcon iconName='pencil' className='fs-2' />
                        Edit
                      </button>
                      <button
                        className='btn btn-light-danger btn-sm ms-2'
                        onClick={async () => {
                          delete correctionFamMems[each.id]
                          const updatedCorrections = {
                            ...contextCorrections,
                            _family_employer_reference: {
                              ...contextCorrections._family_employer_reference,
                              family_members: correctionFamMems,
                            },
                          }
                          await updateDataOnDB({
                            ...candidateData,
                            corrections: updatedCorrections,
                          })
                        }}
                      >
                        <KTIcon iconName='trash' className='fs-2' />
                      </button>
                    </div>
                  </div>
                </div>
              )
            } else if (each.status && each.status === 'deleted') {
              return (
                <div key={each.id} className='bg-light-danger p-3 mb-3 rounded'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='w-75 d-flex align-items-center'>
                      <div className='d-flex flex-row align-items-center'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          height='44px'
                          viewBox='0 -960 960 960'
                          width='54px'
                          fill='#5f6368'
                        >
                          <path d='M720-720q-33 0-56.5-23.5T640-800q0-33 23.5-56.5T720-880q33 0 56.5 23.5T800-800q0 33-23.5 56.5T720-720ZM680-80v-320q0-40-20.5-72T607-522l35-103q8-25 29.5-40t48.5-15q27 0 48.5 15t29.5 40l102 305H800v240H680ZM500-500q-25 0-42.5-17.5T440-560q0-25 17.5-42.5T500-620q25 0 42.5 17.5T560-560q0 25-17.5 42.5T500-500ZM220-720q-33 0-56.5-23.5T140-800q0-33 23.5-56.5T220-880q33 0 56.5 23.5T300-800q0 33-23.5 56.5T220-720ZM140-80v-280H80v-240q0-33 23.5-56.5T160-680h120q33 0 56.5 23.5T360-600v240h-60v280H140Zm300 0v-160h-40v-160q0-25 17.5-42.5T460-460h80q25 0 42.5 17.5T600-400v160h-40v160H440Z' />
                        </svg>
                        <div className='text-muted ms-5'>
                          <h6 className='fw-semibold m-0'>
                            {each.name}, {each.age}
                          </h6>
                          <span className='m-0'>{each.relationship}</span>
                          <br />
                          <span className='m-0'>{each.occupation}</span>
                        </div>
                      </div>
                    </div>
                    <div className=''>
                      <button
                        className='btn btn-light-primary btn-sm me-2'
                        onClick={async () => {
                          delete correctionFamMems[each.id]

                          const updatedData = {
                            ...candidateData,
                            _family_employer_reference: {
                              ...candidateData._family_employer_reference,
                              family_members: candidateData._family_employer_reference[
                                'family_members'
                              ].map((item: any, i: number) =>
                                item.id === each.id ? {...item, status: 'original'} : item
                              ),
                            },
                          }

                          const updatedCorrections = {
                            ...contextCorrections,
                            _family_employer_reference: {
                              ...contextCorrections._family_employer_reference,
                              family_members: correctionFamMems,
                            },
                          }

                          await updateDataOnDB({
                            ...updatedData,
                            corrections: updatedCorrections,
                          })
                        }}
                      >
                        <KTIcon iconName='arrow-circle-left' className='fs-2' />
                        Restore
                      </button>
                    </div>
                  </div>
                </div>
              )
            }
          })}

          <h3 className='mt-5 mb-0 text-primary'>
            <p
              className='alert bg-light text-center text-muted'
              style={{
                width: '100%',
                verticalAlign: 'middle',
                fontSize: '12px',
                textTransform: 'uppercase',
              }}
            >
              <span className='fw-semibold'>Employer Reference</span>
            </p>
          </h3>

          <table className='table table-row-dashed table-row-gray-300 align-middle'>
            <thead>
              <tr className='fw-semibold text-muted default-cursor bg-light-primary rounded'>
                <th className='fw-semibold text-muted ps-3'>Name</th>
                <th className='fw-semibold text-muted'>Designation</th>
                <th className='fw-semibold text-muted'>Company</th>
                <th className='fw-semibold text-muted'>Email</th>
                <th className='fw-semibold text-muted'>Phone No.</th>
              </tr>
            </thead>
            <tbody>
              {employer_references.length ? (
                employer_references.map((member: any, index: number) => {
                  const correction = correctionReferences[member.id]

                  const condition =
                    correction &&
                    Object.keys(correction).length > 0 &&
                    !_.isEqual(member, correction)
                  // console.log(qualification, correction, condition)

                  if (member.status && member.status === 'deleted') {
                    return null
                  }
                  return (
                    <tr key={index} style={{marginTop: '10px'}}>
                      <td className='ps-3'>
                        <div>
                          {condition && correction['name'] !== member['name'] ? (
                            <>
                              <span className='text-decoration-line-through text-danger'>
                                {member.name}
                              </span>
                              <br />

                              <div className='d-flex align-items-center'>
                                <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                  {correction?.name || '-'}
                                </span>
                                <button
                                  className='btn btn-unstyled m-0 p-0'
                                  data-bs-toggle='tooltip'
                                  title='Revert changes'
                                  onClick={() =>
                                    updateFamilyOrReferences(
                                      'employer_references',
                                      member.id,
                                      'name',
                                      member.name
                                    )
                                  }
                                >
                                  <KTIcon iconName='cross-circle' className='text-danger' />
                                </button>
                              </div>
                            </>
                          ) : (
                            <span>{member.name}</span>
                          )}
                        </div>
                      </td>
                      <td>
                        <div>
                          {condition && correction['designation'] !== member['designation'] ? (
                            <>
                              <span className='text-decoration-line-through text-danger'>
                                {member.designation}
                              </span>
                              <br />

                              <div className='d-flex align-items-center'>
                                <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                  {correction?.designation || '-'}
                                </span>
                                <button
                                  className='btn btn-unstyled m-0 p-0'
                                  data-bs-toggle='tooltip'
                                  title='Revert changes'
                                  onClick={() =>
                                    updateFamilyOrReferences(
                                      'employer_references',
                                      member.id,
                                      'designation',
                                      member.designation
                                    )
                                  }
                                >
                                  <KTIcon iconName='cross-circle' className='text-danger' />
                                </button>
                              </div>
                            </>
                          ) : (
                            <span>{member.designation}</span>
                          )}
                        </div>
                      </td>
                      <td>
                        <div>
                          {condition && correction['company'] !== member['company'] ? (
                            <>
                              <span className='text-decoration-line-through text-danger'>
                                {member.company}
                              </span>
                              <br />

                              <div className='d-flex align-items-center'>
                                <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                  {correction?.company || '-'}
                                </span>
                                <button
                                  className='btn btn-unstyled m-0 p-0'
                                  data-bs-toggle='tooltip'
                                  title='Revert changes'
                                  onClick={() =>
                                    updateFamilyOrReferences(
                                      'employer_references',
                                      member.id,
                                      'company',
                                      member.company
                                    )
                                  }
                                >
                                  <KTIcon iconName='cross-circle' className='text-danger' />
                                </button>
                              </div>
                            </>
                          ) : (
                            <span>{member.company}</span>
                          )}
                        </div>
                      </td>
                      <td>
                        <div>
                          {condition && correction['email'] !== member['email'] ? (
                            <>
                              <span className='text-decoration-line-through text-danger'>
                                {member.email}
                              </span>
                              <br />

                              <div className='d-flex align-items-center'>
                                <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                  {correction?.email || '-'}
                                </span>
                                <button
                                  className='btn btn-unstyled m-0 p-0'
                                  data-bs-toggle='tooltip'
                                  title='Revert changes'
                                  onClick={() =>
                                    updateFamilyOrReferences(
                                      'employer_references',
                                      member.id,
                                      'email',
                                      member.email
                                    )
                                  }
                                >
                                  <KTIcon iconName='cross-circle' className='text-danger' />
                                </button>
                              </div>
                            </>
                          ) : (
                            <span>{member.email}</span>
                          )}
                        </div>
                      </td>
                      <td>
                        <div>
                          {condition && correction['mobile'] !== member['mobile'] ? (
                            <>
                              <span className='text-decoration-line-through text-danger'>
                                {member.mobile}
                              </span>
                              <br />

                              <div className='d-flex align-items-center'>
                                <span className='fs-6 text-dark text-hover-primary default-cursor'>
                                  {correction?.mobile || '-'}
                                </span>
                                <button
                                  className='btn btn-unstyled m-0 p-0'
                                  data-bs-toggle='tooltip'
                                  title='Revert changes'
                                  onClick={() =>
                                    updateFamilyOrReferences(
                                      'employer_references',
                                      member.id,
                                      'mobile',
                                      member.mobile
                                    )
                                  }
                                >
                                  <KTIcon iconName='cross-circle' className='text-danger' />
                                </button>
                              </div>
                            </>
                          ) : (
                            <span>{member.mobile}</span>
                          )}
                        </div>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr style={{marginTop: '10px'}}>
                  <td colSpan={5}>
                    <div className='d-flex justify-content-center'>
                      <p className='text-dark default-cursor d-block fs-6 m-0 mt-5 ps-3'>N.A</p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {Object.values(correctionReferences).map((each: any, index: number) => {
            if (each.status && each.status === 'New') {
              return (
                <div key={each.id} className='bg-light-success p-3 mb-3 rounded'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='w-75 d-flex align-items-center'>
                      <div className='d-flex flex-row align-items-center'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          height='44px'
                          viewBox='0 -960 960 960'
                          width='54px'
                          fill='#5f6368'
                        >
                          <path d='M720-720q-33 0-56.5-23.5T640-800q0-33 23.5-56.5T720-880q33 0 56.5 23.5T800-800q0 33-23.5 56.5T720-720ZM680-80v-320q0-40-20.5-72T607-522l35-103q8-25 29.5-40t48.5-15q27 0 48.5 15t29.5 40l102 305H800v240H680ZM500-500q-25 0-42.5-17.5T440-560q0-25 17.5-42.5T500-620q25 0 42.5 17.5T560-560q0 25-17.5 42.5T500-500ZM220-720q-33 0-56.5-23.5T140-800q0-33 23.5-56.5T220-880q33 0 56.5 23.5T300-800q0 33-23.5 56.5T220-720ZM140-80v-280H80v-240q0-33 23.5-56.5T160-680h120q33 0 56.5 23.5T360-600v240h-60v280H140Zm300 0v-160h-40v-160q0-25 17.5-42.5T460-460h80q25 0 42.5 17.5T600-400v160h-40v160H440Z' />
                        </svg>
                        <div className='text-muted ms-5'>
                          <h6 className='fw-semibold m-0'>{each.name}</h6>
                          <span className='m-0'>
                            {each.designation} - {each.company}
                          </span>
                          <br />
                          <span className='m-0'>{each.email}</span>
                          <br />
                          <span className='m-0'>{each.mobile}</span>
                        </div>
                      </div>
                    </div>
                    <div className=''>
                      <button
                        className='btn btn-light-primary btn-sm me-2'
                        onClick={() => {
                          setEmpRefIndex(index)
                          setRefEmpInitialVals(each)
                          setShowAddRefEmp(true)
                        }}
                      >
                        <KTIcon iconName='pencil' className='fs-2' />
                        Edit
                      </button>
                      <button
                        className='btn btn-light-danger btn-sm ms-2'
                        onClick={async () => {
                          delete correctionReferences[each.id]
                          const updatedCorrections = {
                            ...contextCorrections,
                            _family_employer_reference: {
                              ...contextCorrections._family_employer_reference,
                              employer_references: correctionReferences,
                            },
                          }
                          await updateDataOnDB({
                            ...candidateData,
                            corrections: updatedCorrections,
                          })
                        }}
                      >
                        <KTIcon iconName='trash' className='fs-2' />
                      </button>
                    </div>
                  </div>
                </div>
              )
            } else if (each.status && each.status === 'deleted') {
              return (
                <div key={each.id} className='bg-light-danger p-3 mb-3 rounded'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='w-75 d-flex align-items-center'>
                      <div className='d-flex flex-row align-items-center'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          height='44px'
                          viewBox='0 -960 960 960'
                          width='54px'
                          fill='#5f6368'
                        >
                          <path d='M720-720q-33 0-56.5-23.5T640-800q0-33 23.5-56.5T720-880q33 0 56.5 23.5T800-800q0 33-23.5 56.5T720-720ZM680-80v-320q0-40-20.5-72T607-522l35-103q8-25 29.5-40t48.5-15q27 0 48.5 15t29.5 40l102 305H800v240H680ZM500-500q-25 0-42.5-17.5T440-560q0-25 17.5-42.5T500-620q25 0 42.5 17.5T560-560q0 25-17.5 42.5T500-500ZM220-720q-33 0-56.5-23.5T140-800q0-33 23.5-56.5T220-880q33 0 56.5 23.5T300-800q0 33-23.5 56.5T220-720ZM140-80v-280H80v-240q0-33 23.5-56.5T160-680h120q33 0 56.5 23.5T360-600v240h-60v280H140Zm300 0v-160h-40v-160q0-25 17.5-42.5T460-460h80q25 0 42.5 17.5T600-400v160h-40v160H440Z' />
                        </svg>
                        <div className='text-muted ms-5'>
                          <h6 className='fw-semibold m-0'>{each.name}</h6>
                          <span className='m-0'>
                            {each.designation} - {each.company}
                          </span>
                          <br />
                          <span className='m-0'>{each.email}</span>
                          <br />
                          <span className='m-0'>{each.mobile}</span>
                        </div>
                      </div>
                    </div>
                    <div className=''>
                      <button
                        className='btn btn-light-primary btn-sm me-2'
                        onClick={async () => {
                          delete correctionReferences[each.id]

                          const updatedData = {
                            ...candidateData,
                            _family_employer_reference: {
                              ...candidateData._family_employer_reference,
                              employer_references: candidateData._family_employer_reference[
                                'employer_references'
                              ].map((item: any, i: number) =>
                                item.id === each.id ? {...item, status: 'original'} : item
                              ),
                            },
                          }

                          const updatedCorrections = {
                            ...contextCorrections,
                            _family_employer_reference: {
                              ...contextCorrections._family_employer_reference,
                              employer_references: correctionReferences,
                            },
                          }

                          await updateDataOnDB({
                            ...updatedData,
                            corrections: updatedCorrections,
                          })
                        }}
                      >
                        <KTIcon iconName='arrow-circle-left' className='fs-2' />
                        Restore
                      </button>
                    </div>
                  </div>
                </div>
              )
            }
          })}
        </>
      )
    }
  }

  return (
    <>
      <AddFamilyMember
        showModal={showAddFamModal}
        setShowModal={setShowAddFamModal}
        addFamilyMember={() => {}}
        editFamilyMember={editAddedFamilyMember}
        type={'editNewFamMem'}
        index={famIndex}
        initialVals={famInitialVals}
      />

      <AddEmpReference
        showModal={showAddRefEmp}
        setShowModal={setShowAddRefEmp}
        addFamilyMember={() => {}}
        editFamilyMember={editAddedEmpRef}
        type={'editNewReference'}
        index={empRefIndex}
        initialVals={refEmpInitialVals}
      />
      {!isDataAvailable ? (
        <>{getCurrentUI()}</>
      ) : (
        <div
          className='card d-flex flex-column justify-content-center align-items-center'
          style={{height: '228px'}}
        >
          <h3 className='fw-semibold fs-3'>Candidate data not filled yet.</h3>
        </div>
      )}
    </>
  )
}

export default FamilyAndEmpRef
