import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import clsx from 'clsx'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import './index.css'
import usePostApi from '../../../custom_hooks/usePostApi'
import {FindPodIdByCode} from '../../../FindPodIdByPodCode'
import {KTIcon} from '../../../../../_metronic/helpers'
import MapComponent from './MapComponent'
import {useIntl} from 'react-intl'
import {UseToast} from '../../../useToast'
import {useThemeMode} from '../../../../../_metronic/partials'
function CreateLocation(props: any) {
  const {execute} = usePostApi()
  const intl = useIntl()
  const podId = FindPodIdByCode()
  const [currentStep, setCurrentStep] = useState(0)
  const [data, setData]: any = useState<any>({
    location_name: '',
    location_code: '',
    status: true,
    latitude: '',
    longitude: '',
    countryCode: '',
    address: '',
    state: '',
    city: '',
  })
  const set2ndIndex = () => {
    setCurrentStep(1)
  }
  const handleSubmit = async (data: any) => {
    const url = `/pod/locations/create`
    const requestData = {
      pod_id: podId,
      location_name: data.location_name,
      location_code: data.location_code,
      country: data.countryCode,
      state: data.state ? data.state : null,
      address: data.address,
      city: data.city ? data.city : null,
      latitude: `${data.latitude}`,
      longitude: `${data.longitude}`,
      status: data.status ? 1 : 0,
    }
    try {
      const response = await execute(url, 'POST', requestData)
      props.fetchData()
      props.closeModal()
      setData({
        location_name: '',
        location_code: '',
        status: true,
        latitude: '',
        longitude: '',
        countryCode: '',
        address: '',
        state: '',
        city: '',
      })
      setCurrentStep(0)
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
    } catch (error: any) {
      console.error(error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }

  const steps = [
    <Step1 data={data} setData={setData} set2ndIndex={set2ndIndex} />,
    <Step2 data={data} setData={setData} handleSubmit={handleSubmit} />,
  ]

  const prevStep = (): void => {
    if (currentStep !== 0) {
      setCurrentStep(currentStep - 1)
    }
  }

  const nextStep = () => {
    if (currentStep === steps.length - 1) {
      const step2Submit = document.getElementById('step2_submit_btn')
      if (step2Submit) {
        step2Submit.click()
      }
      if (data.countryCode) {
        handleSubmit(data)
      }
    }
    if (currentStep === 0) {
      const step1Submit = document.getElementById('step1_submit_btn')
      if (step1Submit) {
        step1Submit.click()
      }
    }
  }

  return (
    <Modal
      show={props.showModal}
      onHide={() => props.setShowModal(false)}
      centered
      dialogClassName='custom-modal modal-dialog-centered'
      contentClassName='custom-modal-content'
      style={{backgroundColor: 'rgba(0, 0, 0, 0.3)'}}
    >
      <Modal.Body className='overflow-auto w-100'>
        <div className='card py-0 overflow-auto'>
          <div className='w-100 m-auto'>
            <div className='d-flex justify-content-between mb-10'>
              <h4 className='fw-semibold'>Add Location</h4>
              <button
                type='button'
                className='d-block btn btn-sm btn-icon btn-light'
                onClick={() => {
                  props.setShowModal(false)
                  setCurrentStep(0)
                }}
              >
                <KTIcon iconName='cross' className='fs-1' />
              </button>
            </div>
            <div className='w-100 row m-0'>
              <div className='col-lg-5 p-0 mb-5 mb-lg-0'>
                <div className=''>
                  <div className='d-flex'>
                    <div
                      style={{borderRadius: '10px'}}
                      className={clsx(
                        'w-40px h-40px btn d-flex justify-content-center align-items-center',
                        {
                          'btn-primary': currentStep === 0,
                          'btn-light-primary': currentStep !== 0,
                        }
                      )}
                    >
                      {currentStep === 0 ? 1 : <i className='stepper-check fas fa-check'></i>}
                    </div>
                    <div className='ms-5'>
                      <h6
                        className={clsx('mb-0 fw-semibold', {
                          'text-muted': currentStep >= 0,
                        })}
                      >
                        Step 1
                      </h6>
                      <p
                        className={clsx('fs-6 mb-0', {
                          'text-muted': currentStep >= 0,
                        })}
                      >
                        About Location
                      </p>
                    </div>
                  </div>
                  <div className='ms-6 h-30px border-left border-dashed border-muted border-top-0 border-bottom-0 border-right-0 border-left-1'></div>
                  <div className='d-flex'>
                    <div
                      style={{borderRadius: '10px'}}
                      className={clsx(
                        'w-40px h-40px btn d-flex justify-content-center align-items-center',
                        {
                          'btn-primary': currentStep === 1,
                          'btn-light-primary': currentStep !== 1,
                        }
                      )}
                    >
                      {currentStep > 1 ? <i className='stepper-check fas fa-check'></i> : 2}
                    </div>
                    <div className='ms-5'>
                      <h6
                        className={clsx('mb-0 fw-semibold', {
                          'text-muted': currentStep >= 1,
                        })}
                      >
                        Step 2
                      </h6>
                      <p
                        className={clsx('fs-6', {
                          'text-muted': currentStep >= 1,
                        })}
                      >
                        Pick Location & Status
                      </p>
                    </div>
                  </div>
                  {/* Add more steps here */}
                </div>
              </div>
              <div className='col-lg-7 p-0 m-0'>{steps[currentStep]}</div>
            </div>
            <div className='w-100 d-flex flex-stack mt-5'>
              <div className='mr-2'>
                {currentStep > 0 && (
                  <button onClick={prevStep} type='button' className='btn btn-sm btn-light me-3'>
                    <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                    Back
                  </button>
                )}
              </div>
              <div>
                <button type='submit' className='btn btn-sm btn-light-primary' onClick={nextStep}>
                  {currentStep === steps.length - 1 ? (
                    <>
                      <KTIcon iconName='check' className='fs-7' />
                      {intl.formatMessage({id: 'BTN.SAVE'})}
                    </>
                  ) : (
                    <>
                      Continue
                      <KTIcon iconName='arrow-right' className='fs-3 ms-1' />
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const registrationSchema = Yup.object().shape({
  location_name: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Location Name is required'),
  location_code: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Location Code is required'),
})

const Step1 = (props: any) => {
  const onSubmit = (values: Partial<any>) => {
    props.setData({...props.data, ...values})
    props.set2ndIndex()
  }
  return (
    <Formik validationSchema={registrationSchema} initialValues={props.data} onSubmit={onSubmit}>
      {() => {
        return (
          <Form className='w-100'>
            <div className='w-100 mb-5'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Location Name</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Specify your Location Name'
                ></i>
              </label>
              <Field
                type='text'
                name='location_name'
                className='form-control form-control-solid'
                placeholder='Location Name'
              />
              <ErrorMessage component='div' className='text-danger' name='location_name' />
            </div>
            <div className='w-100 mb-5'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Location Code</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Specify your Location Code'
                ></i>
              </label>
              <Field
                type='text'
                name='location_code'
                className='form-control form-control-solid'
                placeholder='Location Code'
              />
              <ErrorMessage component='div' className='text-danger' name='location_code' />
            </div>
            <button
              type='submit'
              className='d-none btn btn-lg btn-primary w-100'
              id='step1_submit_btn'
            >
              Submit
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}
const registrationSchemaStep2 = Yup.object().shape({
  countryCode: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Location address is required'),
})
const Step2 = (props: any) => {
  const {setData, data} = props
  const {mode} = useThemeMode()
  const [status, setStatus] = useState(data.status)
  const [error, setError] = useState(true)
  const onSubmit = () => {
    setData({...data})
  }
  const updateItemId = (address: any) => {
    const countryCode =
      address.context?.find((contextItem: any) => contextItem.id.startsWith('country.'))
        ?.short_code || ''
    const state =
      address.context?.find((contextItem: any) => contextItem.id.startsWith('region.'))?.text || ''
    const city =
      address.context?.find((contextItem: any) => contextItem.id.startsWith('place.'))?.text || ''
    setError(false)
    setData({
      ...data,
      countryCode: countryCode,
      address: address.place_name,
      latitude: address.center[1],
      longitude: address.center[0],
      state: state,
      city: city,
    })
  }
  const updateItemAddress = (address: any) => {
    setData({
      ...data,
      address: address,
    })
  }

  return (
    <Formik validationSchema={registrationSchemaStep2} initialValues={data} onSubmit={onSubmit}>
      {({values}) => {
        return (
          <Form className='w-100'>
            <div className='w-100 mb-5'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>Pick Location</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Specify your Location Name'
                ></i>
              </label>
              <MapComponent updateItemId={updateItemId} />
              {error && (
                <div className='text-danger'>
                  <ErrorMessage name='countryCode' />
                </div>
              )}
            </div>
            <div className='w-100 mb-5'>
              <div className='form-check form-check-solid form-switch mb-0'>
                <input
                  className='form-check-input w-40px h-20px'
                  type='checkbox'
                  name='status'
                  id='toggle'
                  checked={status}
                  style={{
                    backgroundColor: status ? '#009688' : mode === 'dark' ? '#1b1b29' : '#f4f4f4',
                  }}
                  onChange={(e) => {
                    const isChecked = e.target.checked
                    setStatus(isChecked)
                    setData({
                      ...data,
                      status: isChecked,
                    })
                  }}
                />
                <span className={`me-4 fw-semibold ${status ? 'text-success' : 'text-danger'}`}>
                  {status ? 'Active' : 'Inactive'}
                </span>
                <ErrorMessage component='div' className='text-danger' name='status' />
              </div>
            </div>
            <button
              type='submit'
              className='d-none btn btn-lg btn-primary w-100'
              id='step2_submit_btn'
            >
              Submit
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}

export default CreateLocation
