import {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {KTIcon} from '../../../../_metronic/helpers'
import EmpDetailsWizard from './EmpDetailsWizard'
import {useParams} from 'react-router-dom'
import usePostApi from '../../custom_hooks/usePostApi'
import CandidateInfoContext from './CandidateInfoContext'
import {UseToast} from '../../useToast'
import {toast} from 'react-toastify'

function CandidateBasicInfo() {
  const {execute, loading, postLoading} = usePostApi()
  const [currentStep, setCurrentStep] = useState(0)
  const {id, jobJoinCode, pin} = useParams()

  const [basicInfo, setBasicInfo] = useState({
    candidate_email: '',
    pod_brand_name: '',
    pod_business_name: '',
    candidate_name: '',
  })
  const [candidateInitialVals, setCandidateInitialVals]: any = useState({})

  const initialFormVals = {
    _basic_info: {},
    _address_contacts: {},
    same_as_current_add: false,
    _employment_education: {
      qualifications: [],
      certifications: [],
      isFresher: 'No',
      experiences: [],
      customFields: {},
    },
    _family_employer_reference: {
      family_members: [],
      employer_references: [],
      customFields: {},
    },
    _documents_attachments: {
      documents: {},
      customFields: {},
    },
    _policy_declarations: {
      policies: [],
      declarationVals: [],
      notPolicyOrDeclarations: {},
    },
    signature: '',
    accuracyDeclaration: '',
    locked_sections: {},
  }

  const [corrections, setCorrections]: any = useState({
    _basic_info: {},
    _address_contacts: {},
    _employment_education: {
      qualifications: {},
      certifications: {},
      isFresher: 'No',
      experiences: {},
      customFields: {},
    },
    _family_employer_reference: {
      family_members: {},
      employer_references: {},
      customFields: {},
    },
  })

  const deleteCorrections = async (section: string, id: string) => {
    const updatedCorrections = {
      ...corrections,
      [section]: Object.fromEntries(
        Object.entries(corrections[section]).filter(([key]) => key !== id)
      ),
    }

    setCorrections(updatedCorrections)
    await updateData({...candidateInitialVals, corrections: updatedCorrections})
  }

  const [formValues, setFormValues] = useState(initialFormVals)

  const [ids, setIds] = useState({onbr_id: '', onbr_data_id: ''})

  const decodeFromBase64 = (data: string): any => {
    try {
      const jsonString = atob(data)
      return JSON.parse(jsonString)
    } catch (error) {
      console.error('Failed to decode and parse base64 string:', error)
      return null
    }
  }

  const getCandidateData = async () => {
    try {
      const url = '/pod/onbr/data/get'
      const formData = {
        onbr_code: jobJoinCode,
        onbr_pin: pin || '',
      }
      const response = await execute(url, 'POST', formData)
      const data = response.data[0]
      const formattedData = decodeFromBase64(response.data[0].candidate_info)
      if (formattedData.corrections && Object.keys(formattedData.corrections.length > 0)) {
        setCorrections(formattedData.corrections)
      }
      setCandidateInitialVals(formattedData)
      setFormValues(formattedData ? formattedData : initialFormVals)
      setIds({onbr_id: data.onbr_id, onbr_data_id: data.onbr_data_id})
      setBasicInfo({
        candidate_email: data.candidate_email,
        candidate_name: data.candidate_name,
        pod_brand_name: data.pod_brand_name,
        pod_business_name: data.pod_business_name,
      })
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const [template, setTemplate] = useState({})

  const getTemplate = async () => {
    const url = '/onbr/basic-info/get'
    // const url = '/pod/onboarding/basic-info/get'
    const formData = {
      onbr_code: jobJoinCode,
      onbr_pin: pin,
    }
    try {
      const response = await execute(url, 'POST', formData)
      const stucturedData = decodeFromBase64(response.data[0].template_structure)
      setTemplate(stucturedData?.fieldsData)
    } catch (error: any) {
      console.error(error)
    }
  }

  useEffect(() => {
    getCandidateData()
    getTemplate()
  }, [])

  const [editSections, setEditSections] = useState({
    personalInfo: false,
    addressContact: false,
    educationEmployment: false,
    familyEmpRef: false,
  })

  const updateEditSec = (sec: string) => {
    setEditSections((prev: any) => ({
      ...prev,
      [sec]: !prev[sec],
    }))
  }

  const closeEditSec = (sec: string) => {
    setEditSections((prev: any) => ({
      ...prev,
      [sec]: false,
    }))
  }

  useEffect(() => {
    setEditSections({
      personalInfo: false,
      addressContact: false,
      educationEmployment: false,
      familyEmpRef: false,
    })
  }, [currentStep])

  useEffect(() => {
    return () => {
      toast.dismiss()
    }
  }, [currentStep])

  const encodeToBase64 = (data: any): string | null => {
    try {
      const jsonString = JSON.stringify(data)
      return btoa(jsonString)
    } catch (error) {
      console.error('Failed to encode and stringify data to base64 string:', error)
      return null
    }
  }

  const updateData = async (updatedData: any, status = 'I') => {
    const encodedData = encodeToBase64({...updatedData})
    // return
    try {
      const url = '/pod/onbr/data/update'
      // const url = '/pod/onboarding/data/update'
      const formData = {
        onbr_data_id: ids?.onbr_data_id,
        onbr_id: ids?.onbr_id,
        onbr_code: jobJoinCode,
        onbr_pin: pin || '',
        candidate_info: encodedData,
        status: status,
      }
      const response = await execute(url, 'POST', formData, 'post')
      await getCandidateData()
      setEditSections({
        personalInfo: false,
        addressContact: false,
        educationEmployment: false,
        familyEmpRef: false,
      })
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
    } catch (error: any) {
      console.error('Error:', error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }

  const getCorrectionsData = () => {
    const countKeys = (obj: any) => (obj ? Object.keys(obj).length : 0)

    const personalInfoCount = countKeys(corrections._basic_info)
    const addressContactCount = countKeys(corrections._address_contacts)

    const qualCount = countKeys(corrections._employment_education?.qualifications)
    const certificationsCount = countKeys(corrections._employment_education?.certifications)
    const experienceCount = countKeys(corrections._employment_education?.experiences)
    const empEduCustomCount = countKeys(corrections._employment_education?.customFields)

    const famCount = countKeys(corrections._family_employer_reference?.family_members)
    const empRefCount = countKeys(corrections._family_employer_reference?.employer_references)
    const famEmpRefCustomCount = countKeys(corrections._family_employer_reference?.customFields)

    const totalCorrections =
      personalInfoCount +
      addressContactCount +
      qualCount +
      certificationsCount +
      experienceCount +
      empEduCustomCount +
      famCount +
      empRefCount +
      famEmpRefCustomCount

    const isCorrectionsAvailable = totalCorrections > 0

    return {
      isCorrectionsAvailable,
      totalCorrections,
    }
  }
  const sendDCReqEmail = async () => {
    const url = '/onbr/candidate-info/review'
    const formData = {
      onbr_code: jobJoinCode,
      onbr_pin: pin,
      candidate_name: basicInfo?.candidate_name,
      candidate_email: basicInfo?.candidate_email || '',
      pod_business_name: basicInfo?.pod_business_name || '',
      pod_brand_name: basicInfo?.pod_brand_name || '',
    }

    try {
      const response = await execute(url, 'POST', formData)
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'success'
      )
    } catch (error: any) {
      console.error('Error:', error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }

  const {isCorrectionsAvailable, totalCorrections} = getCorrectionsData()

  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <div className=''>
          <h3 className='fw-semibold my-2'>Onboarding Info</h3>
        </div>
        <div className='d-flex'>
          <Link className='btn btn-sm btn-light-primary ms-2 fw-normal' to={`/${id}/onboarding`}>
            <KTIcon iconName='arrow-left' className='fs-3' />
            Back
          </Link>
        </div>
      </div>

      <div className='w-100 d-flex flex-column flex-md-row justify-content-between'>
        <div className='w-100 me-5 w-md-25 mb-5 h-100'>
          <div className='card w-100 d-flex flex-column justify-content-between text-dark p-5'>
            <div
              className={`menu-item ${currentStep === 0 ? 'bg-light-primary' : ''}`}
              style={{position: 'relative', paddingRight: '60px', borderRadius: '3px'}}
              onClick={() => setCurrentStep(0)}
            >
              <p
                className={clsx(
                  'menu-link btn btn-sm btn-color-muted btn-active btn-active-light-primary text-hover-primary mb-0',
                  {
                    active: currentStep === 0,
                  }
                )}
                style={{padding: '10px', marginBottom: '0px !important'}}
              >
                <span className='menu-icon'>
                  <KTIcon iconName='badge' className='fs-2' />
                </span>
                <span className='text-start menu-title fw-semibold'>Personal Info</span>
              </p>
              {currentStep === 0 && (
                <button
                  className='btn btn-primary btn-sm'
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                  onClick={() => updateEditSec('personalInfo')}
                >
                  {editSections.personalInfo ? 'Cancel' : 'Edit'}
                </button>
              )}
            </div>

            <div
              className={`menu-item ${currentStep === 1 ? 'bg-light-primary' : ''}`}
              style={{position: 'relative', paddingRight: '60px', borderRadius: '3px'}}
              onClick={() => setCurrentStep(1)}
            >
              <p
                className={clsx(
                  'menu-link btn btn-sm btn-color-muted btn-active btn-active-light-primary text-hover-primary mb-0',
                  {
                    active: currentStep === 1,
                  }
                )}
                style={{padding: '10px', marginBottom: '0px !important'}}
              >
                <span className='menu-icon'>
                  <KTIcon iconName='address-book' className='fs-2' />
                </span>
                <span className='text-start menu-title fw-semibold'>Address & Contacts</span>
              </p>
              {currentStep === 1 && (
                <button
                  className='btn btn-primary btn-sm'
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                  onClick={() => updateEditSec('addressContact')}
                >
                  {editSections.addressContact ? 'Cancel' : 'Edit'}
                </button>
              )}
            </div>

            <div
              className={`menu-item ${currentStep === 2 ? 'bg-light-primary' : ''}`}
              style={{position: 'relative', paddingRight: '60px', borderRadius: '3px'}}
              onClick={() => setCurrentStep(2)}
            >
              <p
                className={clsx(
                  'menu-link btn btn-sm btn-color-muted btn-active btn-active-light-primary text-hover-primary mb-0',
                  {
                    active: currentStep === 2,
                  }
                )}
                style={{padding: '10px', marginBottom: '0px !important'}}
              >
                <span className='menu-icon'>
                  <KTIcon iconName='book-open' className='fs-2' />
                </span>
                <span className='text-start menu-title fw-semibold'>Education & Employment</span>
              </p>
              {currentStep === 2 && (
                <button
                  className='btn btn-primary btn-sm'
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                  onClick={() => updateEditSec('educationEmployment')}
                >
                  {editSections.educationEmployment ? 'Cancel' : 'Edit'}
                </button>
              )}
            </div>

            <div
              className={`menu-item ${currentStep === 3 ? 'bg-light-primary' : ''}`}
              style={{position: 'relative', paddingRight: '60px', borderRadius: '3px'}}
              onClick={() => setCurrentStep(3)}
            >
              <p
                className={clsx(
                  'menu-link btn btn-sm btn-color-muted btn-active btn-active-light-primary text-hover-primary mb-0',
                  {
                    active: currentStep === 3,
                  }
                )}
                style={{padding: '10px', marginBottom: '0px !important'}}
              >
                <span className='menu-icon'>
                  <KTIcon iconName='profile-user' className='fs-2' />
                </span>
                <span className='text-start menu-title fw-semibold'>Family & References</span>
              </p>
              {currentStep === 3 && (
                <button
                  className='btn btn-primary btn-sm'
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                  onClick={() => updateEditSec('familyEmpRef')}
                >
                  {editSections.familyEmpRef ? 'Cancel' : 'Edit'}
                </button>
              )}
            </div>

            <div className='menu-item' onClick={() => setCurrentStep(5)}>
              <p
                className={clsx(
                  'menu-link btn btn-sm btn-color-muted btn-active btn-active-light-primary text-hover-primary mb-0',
                  {
                    active: currentStep === 5,
                  }
                )}
                style={{padding: '10px', marginBottom: '0px !important'}}
              >
                <span className='menu-icon'>
                  <KTIcon iconName='paper-clip' className='fs-2' />
                </span>
                <span className='text-start menu-title fw-semibold'>Attachments</span>
              </p>
            </div>

            <div className='menu-item' onClick={() => setCurrentStep(4)}>
              <p
                className={clsx(
                  'menu-link btn btn-sm btn-color-muted btn-active btn-active-light-primary text-hover-primary mb-0',
                  {
                    active: currentStep === 4,
                  }
                )}
                style={{padding: '10px', marginBottom: '0px !important'}}
              >
                <span className='menu-icon'>
                  <KTIcon iconName='pencil' className='fs-2' />
                </span>
                <span className='text-start menu-title fw-semibold'>Signs</span>
              </p>
            </div>
          </div>

          {isCorrectionsAvailable === true && (
            <div className='alert alert-danger d-flex align-items-center p-1 mt-3'>
              <KTIcon iconName='information' className='fs-2 text-danger me-2' />
              <div className='d-flex flex-column'>
                <h6 className='mb-0 fw-semibold fs-7 text-dark'>Important:</h6>
                <span className='fs-8 text-dark'>
                  You have made {totalCorrections} changes in the data. Please request the
                  candidate's approval and signature.{' '}
                  <span className='text-primary cursor-pointer' onClick={sendDCReqEmail}>
                    Click to send a request email
                  </span>
                </span>
              </div>
            </div>
          )}
        </div>

        <CandidateInfoContext.Provider
          value={{
            corrections: corrections,
            setCorrections: setCorrections,
            deleteCorrections: deleteCorrections,
            updateDataOnDB: updateData,
            formValues: formValues,
            setFormData: setFormValues,
          }}
        >
          <div className='w-100 w-md-75 h-100 overflow-auto'>
            <EmpDetailsWizard
              setLocalStep={setCurrentStep}
              currentStep={currentStep}
              candidateData={candidateInitialVals}
              isLoading={loading}
              template={template}
              formValues={formValues}
              setFormValues={setFormValues}
              ids={ids}
              getCandidateData={getCandidateData}
              editSections={editSections}
              closeEditSec={closeEditSec}
              corrections={corrections}
              setCorrections={setCorrections}
            />
          </div>
        </CandidateInfoContext.Provider>
      </div>
    </>
  )
}

export default CandidateBasicInfo
