/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useEffect, useRef, useState} from 'react'
import {Toggle} from './LogosBannerToggle'
import {useIntl} from 'react-intl'
import Swal from 'sweetalert2'
import usePostApi from '../../custom_hooks/usePostApi'
import {FindPodIdByCode} from '../../FindPodIdByPodCode'
import {useParams} from 'react-router-dom'
import AppContext from '../../../AppContext'
import {UseToast} from '../../useToast'
import {OverlayTrigger, Popover} from 'react-bootstrap'
const Logos = (props: any) => {
  const intl = useIntl()
  const {getPodsData}: any = useContext(AppContext)
  const {id}: any = useParams()
  const {execute} = usePostApi()
  const podId = FindPodIdByCode()
  let BrandlogosData = props.data && props.data[0]
  let color = BrandlogosData?.pod_pref_color ? BrandlogosData?.pod_pref_color.substring(1) : ''
  const name = encodeURIComponent(BrandlogosData?.pod_brand_name)
  const [popover, setPopover]: any = useState(null)
  const [value, updateId]: any = useState('')
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const [headerFile, setHeaderFile] = useState<File | null>(null)
  const [uploadError, setUploadError] = useState<string | null>(null)
  const [headerUploadError, setHeaderUploadError] = useState<string | null>(null)
  interface LogoUrls {
    pod_logo: string
    pod_header: string
  }

  const [data, setData] = useState<LogoUrls>({
    pod_logo: '',
    pod_header: '',
  })
  useEffect(() => {
    if (BrandlogosData) {
      setData(BrandlogosData)
    }
  }, [BrandlogosData])

  const brandLogoInputRef = useRef<HTMLInputElement>(null)
  const brandHeaderInputRef = useRef<HTMLInputElement>(null)
  const handleUploadClick = (inputRef: React.RefObject<HTMLInputElement>) => {
    if (inputRef.current !== null) {
      inputRef.current.click()
    }
  }

  const handleImageChange = (field: keyof LogoUrls, file: File) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const previewImage = reader.result as string
      setData((prevData) => ({
        ...prevData,
        [field]: previewImage,
      }))
    }
    reader.readAsDataURL(file)
  }

  const handleLogoEditSubmit = async () => {
    const url = `/pod/logo/upload`
    try {
      const result = await Swal.fire({
        title: intl.formatMessage({id: 'LABEL.UPDATING_BRAND_LOGO'}),
        text: intl.formatMessage({id: 'LABEL.CONFIRM_UPDATE_BRAND_LOGO'}),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: intl.formatMessage({id: 'BTN.CONFIRM'}),
        cancelButtonText: intl.formatMessage({id: 'LABEL.CANCEL'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
          cancelButton: 'btn btn-sm btn-secondary',
        },
      })

      if (result.isConfirmed) {
        const formData = new FormData()
        const brandLogoField = 'pod_logo'
        if (data[brandLogoField]) {
          const file = dataURItoFile(data[brandLogoField], `image_${brandLogoField}`)
          formData.append(brandLogoField, file)
          formData.append('pod_code', id)
          formData.append('pod_id', podId)
        }
        const response = await execute(url, 'POST', formData)
        getPodsData()
        props.fetchData()
        UseToast(
          response.status_message,
          {
            position: 'bottom-right',
            theme: 'colored',
            autoClose: false,
          },
          'success'
        )
      }
    } catch (error: any) {
      console.error(error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }

  const handleHeaderEditSubmit = async () => {
    const url = `/pod/header/upload`
    try {
      const result = await Swal.fire({
        title: intl.formatMessage({id: 'LABEL.UPDATING_HEADER_LOGO'}),
        text: intl.formatMessage({id: 'LABEL.CONFIRM_UPDATE_HEADER_LOGO'}),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: intl.formatMessage({id: 'BTN.CONFIRM'}),
        cancelButtonText: intl.formatMessage({id: 'LABEL.CANCEL'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
          cancelButton: 'btn btn-sm btn-secondary',
        },
      })

      if (result.isConfirmed) {
        const formData = new FormData()
        const brandHeaderField = 'pod_header'
        if (data[brandHeaderField]) {
          const file = dataURItoFile(data[brandHeaderField], `image_${brandHeaderField}`)
          formData.append(brandHeaderField, file)
          formData.append('pod_code', id)
          formData.append('pod_id', podId)
        }
        const response = await execute(url, 'POST', formData)
        getPodsData()
        props.fetchData()
        UseToast(
          response.status_message,
          {
            position: 'bottom-right',
            theme: 'colored',
            autoClose: false,
          },
          'success'
        )
      }
    } catch (error: any) {
      console.error(error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: false,
        },
        'error'
      )
    }
  }

  const handleBrandLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      if (file.size > 1048576) {
        setUploadError('Brand Logo should be less than 1MB.')
        setUploadedFile(null)
      } else {
        setUploadError(null)
        setUploadedFile(file)
        handleImageChange('pod_logo', file)
      }
    }
  }
  const handleHeaderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      if (file.size > 1048576) {
        setHeaderUploadError('Header Logo size should be less than 1MB.')
        setHeaderFile(null)
      } else {
        setHeaderUploadError(null)
        setHeaderFile(file)
        handleImageChange('pod_header', file)
      }
    }
  }

  const handleCancelClick = () => {
    setUploadedFile(null)
    setHeaderFile(null)
  }
  const isSaveEnabled = !!uploadedFile
  const isSaveHeaderEnabled = !!headerFile
  const dataURItoFile = (dataURI: string, fileName: string): File => {
    const byteString = atob(dataURI.split(',')[1])
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    return new File([ab], fileName, {type: mimeString})
  }
  const ClosePopover = () => {
    setPopover(null)
  }
  const openPopover = (value: any) => (
    <Popover placement='auto' className='min-w-200px'>
      <Toggle props={value} />
    </Popover>
  )
  return (
    <>
      <div className='col-md-12 col-sm-12 mb-3'>
        <div className='row'>
          <div className='col-8 d-flex'>
            <div className='d-flex'>
              {isSaveEnabled ? (
                <div className='symbol symbol-50px me-2'>
                  <img src={data?.pod_logo} alt='Brand Logo' />
                </div>
              ) : (
                <div className='symbol symbol-50px me-2'>
                  <img
                    src={
                      data?.pod_logo
                        ? data?.pod_logo
                        : `${process.env.REACT_APP_API_BASE_URL}/get/profile?name=${name}&color=${color}&size=128}`
                    }
                    alt='profile'
                  />
                </div>
              )}
            </div>
            <div className='ms-4 d-flex'>
              <div className='row'>
                <h1 className='text-dark fw-semibold text-hover-primary fs-6'>
                  {intl.formatMessage({id: 'LABEL.ORGANIZATION_BRAND_LOGO'})}
                </h1>
                <p className='text-dark text-hover-primary fs-8'>
                  {intl.formatMessage({id: 'LABEL.ACCEPTS_JPG/PNG'})}
                </p>
              </div>
            </div>
          </div>
          <div className='col-4 d-flex justify-content-end '>
            <div className='btn-toolbar' role='toolbar' aria-label='...'>
              <div className='btn-group mr-2' role='group' aria-label='...'>
                <OverlayTrigger
                  trigger='click'
                  placement='auto'
                  overlay={openPopover(value)}
                  show={popover === 'toggle'}
                >
                  <button
                    type='button'
                    className='btn btn-active-color-primary btn-icon border'
                    onClick={(e) => {
                      e.stopPropagation()
                      setPopover('toggle')
                      updateId({
                        ClosePopover,
                        pod_logo: data?.pod_logo,
                        brandName: BrandlogosData?.pod_brand_name,
                        pod_pref_color: BrandlogosData?.pod_pref_color,
                        name: intl.formatMessage({id: 'LABEL.ORGANIZATION_BRAND_LOGO'}),
                      })
                    }}
                  >
                    <i className='fs-1 la la-eye'></i>
                  </button>
                </OverlayTrigger>
                <button
                  type='button'
                  className='btn btn-active-color-primary btn-icon border'
                  onClick={() => handleUploadClick(brandLogoInputRef)}
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-start'
                  data-kt-menu-flip='top-end'
                >
                  <i className='fs-1 la la-paperclip'></i>
                </button>
                <input
                  ref={brandLogoInputRef}
                  type='file'
                  accept='image/*'
                  onChange={handleBrandLogoChange}
                  style={{display: 'none'}}
                />
                {isSaveEnabled && (
                  <>
                    <button
                      type='button'
                      className={`btn btn-active-color-primary btn-icon border`}
                      onClick={handleLogoEditSubmit}
                    >
                      <i className='fs-1 la la-floppy-o'></i>
                    </button>
                    <button
                      type='button'
                      className={`btn btn-active-color-primary btn-icon border`}
                      onClick={handleCancelClick}
                    >
                      <i className='fs-1 la la-times'></i>
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {uploadError && (
          <div
            style={{
              color: 'red',
              marginTop: '10px',
              fontSize: '12px',
            }}
          >
            {uploadError}
          </div>
        )}
      </div>
      <div className='col-md-12 col-sm-12 mb-3'>
        <div className='row'>
          <div className='col-8 d-flex'>
            <div className='d-flex'>
              {isSaveHeaderEnabled ? (
                <div className='symbol symbol-50px me-2 '>
                  <img src={data.pod_header} alt='header Logo' />
                </div>
              ) : (
                <div className='symbol symbol-50px me-2 '>
                  <img
                    src={
                      data?.pod_header
                        ? data?.pod_header
                        : `${process.env.REACT_APP_API_BASE_URL}/get/profile?name=${name}&color=${color}&size=128}`
                    }
                    alt='profile'
                  />
                </div>
              )}
            </div>
            <div className='ms-4 d-flex'>
              <div className='row'>
                <div className='col-12'>
                  <h1 className='text-dark fw-semibold text-hover-primary fs-6'>
                    {intl.formatMessage({id: 'LABEL.ORGANIZATION_HEADER_IMAGE'})}
                  </h1>
                </div>
                <div className='col-12'>
                  <div className='d-flex'>
                    <p className='text-dark text-hover-primary fs-8'>
                      {intl.formatMessage({id: 'LABEL.ACCEPTS_JPG/PNG'})}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-4 d-flex justify-content-end '>
            <div className='btn-toolbar' role='toolbar' aria-label='...'>
              <div className='btn-group mr-2' role='group' aria-label='...'>
                <OverlayTrigger
                  trigger='click'
                  placement='auto'
                  overlay={openPopover(value)}
                  show={popover === 'header_toggle'}
                >
                  <button
                    type='button'
                    className='btn btn-active-color-primary btn-icon border'
                    onClick={(e) => {
                      e.stopPropagation()
                      setPopover('header_toggle')
                      updateId({
                        ClosePopover,
                        pod_logo: data?.pod_header,
                        brandName: BrandlogosData?.pod_brand_name,
                        pod_pref_color: BrandlogosData?.pod_pref_color,
                        name: intl.formatMessage({id: 'LABEL.ORGANIZATION_HEADER_IMAGE'}),
                      })
                    }}
                  >
                    <i className='fs-1 la la-eye'></i>
                  </button>
                </OverlayTrigger>
                <button
                  type='button'
                  className='btn btn-active-color-primary btn-icon border'
                  onClick={() => handleUploadClick(brandHeaderInputRef)}
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-start'
                  data-kt-menu-flip='top-end'
                >
                  <i className='fs-1 la la-paperclip'></i>
                </button>
                <input
                  ref={brandHeaderInputRef}
                  type='file'
                  accept='image/*'
                  onChange={handleHeaderChange}
                  style={{display: 'none'}}
                />

                {isSaveHeaderEnabled && (
                  <>
                    <button
                      type='button'
                      className={`btn btn-active-color-primary btn-icon border`}
                      onClick={handleHeaderEditSubmit}
                    >
                      <i className='fs-1 la la-floppy-o'></i>
                    </button>
                    <button
                      type='button'
                      className={`btn btn-active-color-primary btn-icon border`}
                      onClick={handleCancelClick}
                    >
                      <i className='fs-1 la la-times'></i>
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {headerUploadError && (
          <div
            style={{
              color: 'red',
              marginTop: '10px',
              fontSize: '12px',
            }}
          >
            {headerUploadError}
          </div>
        )}
      </div>
    </>
  )
}

export {Logos}
