import React, {useState, useEffect} from 'react'
import {Link, Outlet, useLocation, useParams} from 'react-router-dom'
import {KTIcon} from '../../../../../_metronic/helpers'
import {CheckPodAccess, FindFirstPodCode, FindPodIdByCode} from '../../../FindPodIdByPodCode'
import usePostApi from '../../../custom_hooks/usePostApi'
import clsx from 'clsx'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {PhoneNumberUtil} from 'google-libphonenumber'
import DateFormatter from '../../../DateFormatter'
import {UseToast} from '../../../useToast'
import {defaultProfileImage} from '../../../core'

function CandidateCard() {
  const [showModalChange, setShowChange] = useState<boolean>(false)

  const handleCloseChange = () => {
    setShowChange(false)
  }
  const podHaveAccess = CheckPodAccess()
  const {execute, loading} = usePostApi()
  const {id, candidate_id} = useParams()
  const {pathname} = useLocation()
  const firstPodCode = FindFirstPodCode()

  const [candidatesData, setcandidatesData] = useState<any>(null)

  const fetchData = async () => {
    try {
      const url = `/pod/candidate/get`
      const formData = {
        candidate_id: candidate_id,
      }

      const response = await execute(url, 'POST', formData)
      setcandidatesData(response.data)
      console.log(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const userData = candidatesData ? candidatesData[0] : null

  function getGenderDescription(genderCode: any) {
    switch (genderCode) {
      case 'M':
        return 'Male'
      case 'F':
        return 'Female'
      case 'T':
        return 'Transgender'
      case 'O':
        return 'Others'
      default:
        return '-'
    }
  }
  return (
    <>
      {podHaveAccess ? (
        loading ? (
          <div className='card h-250px mb-5'>
            <div className='m-auto d-flex flex-column align-items-center'>
              <div className='spinner-border spinner-primary mr-15'></div>
            </div>
          </div>
        ) : (
          <>
            <UpdateCandidate
              showModalChange={showModalChange}
              handleCloseChange={handleCloseChange}
              candidateData={userData}
              getCandidateData={fetchData}
            />
            <div className='card mb-4 mb-xl-6 h-200px'>
              {userData ? (
                <div className='card-body min-h-150px row  pt-8 pb-0'>
                  <div className='row col-12 col-md-6 col-lg-7'>
                    <div className='d-flex flex-column flex-md-row mb-0 pb-0'>
                      <div className='me-md-4 mb-4 mb-md-0'>
                        <div className='symbol symbol-100px symbol-lg-150px symbol-fixed position-relative'>
                          <img
                            style={{height: '140px', width: '140px'}}
                            src={userData?.candidate_image ?? defaultProfileImage}
                            alt='profile'
                          />
                        </div>
                      </div>
                      <div className='w-100'>
                        <div className='d-flex align-items-start mb-3'>
                          <span className='text-gray-800 text-hover-primary default-cursor fs-2 fw-semibold me-1'>
                            {userData?.candidate_first_name} {''} {userData?.candidate_last_name}
                          </span>
                        </div>
                        <div className='row mb-3 d-flex align-items-start'>
                          <label className='col-lg-4 col-6 fw-semibold text-muted'>Gender</label>
                          <div className='col-lg-8 col-6'>
                            <span className='fs-6 text-dark text-hover-primary default-cursor'>
                              {getGenderDescription(userData?.gender)}
                            </span>
                          </div>
                        </div>

                        <div className='row mb-3 d-flex align-items-start'>
                          <label className='col-lg-4 col-6 fw-semibold text-muted'>Email</label>
                          <div className='col-lg-8 col-6'>
                            <span className='fs-6 text-dark text-hover-primary default-cursor'>
                              {userData?.email_address || ''}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row col-12 col-md-6 col-lg-5 pt-md-12'>
                    <div>
                      <div className='row mb-3 d-flex align-items-start'>
                        <label className='col-lg-3 col-6 fw-semibold text-muted'>Phone No.</label>
                        <div className='col-lg-9 col-6'>
                          <span className='fs-6 text-dark text-hover-primary default-cursor'>
                            {userData?.mobile || ''}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-3 d-flex align-items-start'>
                        <label className='col-lg-3 col-6 fw-semibold text-muted'>
                          Phone No. (Alt)
                        </label>
                        <div className='col-lg-9 col-6'>
                          <span className='fs-6 text-dark text-hover-primary default-cursor'>
                            {userData?.alt_phone || ''}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='d-flex flex-column justify-content-center align-items-center h-150px'>
                  <div>
                    <h3 className='fs-3 fw-normal'>Not found</h3>
                    <button className='btn btn-sm btn-light-primary' onClick={fetchData}>
                      Retry
                    </button>
                  </div>
                </div>
              )}
            </div>
          </>
        )
      ) : (
        <div className='card min-h-250px d-flex flex-column justify-content-center align-items-center bg-image'>
          <h1 className='fw-semibold fs-2hx text-gray-900 mb-4'>Oops!</h1>
          <div className='fs-6 text-gray-500 mb-7'>You don't have access to this POD.</div>
          <div className='mb-0'>
            <Link to={`/${firstPodCode}/dashboard`} className='btn btn-sm btn-light-primary'>
              Return Home
            </Link>
          </div>
        </div>
      )}
      {podHaveAccess && (
        <>
          <div className='d-flex flex-wrap flex-stack mb-6'>
            <div>
              <h3 className='fs-3 fw-semibold my-2'>Candidate Info</h3>
            </div>
            <div className='d-flex'>
              <Link
                className='btn btn-sm btn-light-primary ms-4 fw-normal'
                to={`/${id}/candidates`}
              >
                <KTIcon iconName='arrow-left' className='fs-3' />
                Back
              </Link>
            </div>
          </div>
          <div className='w-100 d-flex flex-column flex-md-row justify-content-between'>
            <div className='w-100 card me-5 w-md-25 mb-5 h-100'>
              <div className='w-100 d-flex flex-column justify-content-between text-dark p-5'>
                <Link className='menu-item' to={`/${id}/candidates/interviews/${candidate_id}`}>
                  <p
                    className={clsx(
                      'menu-link btn btn-sm btn-color-muted btn-active btn-active-light-primary text-hover-primary mb-0 text-start',
                      {
                        active: pathname === `/${id}/candidates/interviews/${candidate_id}`,
                      }
                    )}
                    style={{padding: '10px', marginBottom: '0px !important'}}
                  >
                    <span className='menu-icon'>
                      <KTIcon iconName='badge' className='fs-2' />
                    </span>
                    <span className='menu-title fw-semibold'>Interviews</span>
                  </p>
                </Link>

                <Link className='menu-item' to={`/${id}/candidates/offers/${candidate_id}`}>
                  <p
                    className={clsx(
                      'menu-link btn btn-sm btn-color-muted btn-active btn-active-light-primary text-hover-primary mb-0 text-start',
                      {
                        active: pathname === `/${id}/candidates/offers/${candidate_id}`,
                      }
                    )}
                    style={{padding: '10px', marginBottom: '0px !important'}}
                  >
                    <span className='menu-icon'>
                      <KTIcon iconName='address-book' className='fs-2' />
                    </span>
                    <span className='menu-title fw-semibold'>Offers</span>
                  </p>
                </Link>

                {/* <Link className='menu-item' to={`/${id}/policies/archive/${policy_id}`}>
                  <p
                    className={clsx(
                      'menu-link btn btn-sm btn-color-muted btn-active btn-active-light-primary text-hover-primary mb-0 text-start',
                      {
                        active: pathname === `/${id}/policies/archive/${policy_id}`,
                      }
                    )}
                    style={{padding: '10px', marginBottom: '0px !important'}}
                  >
                    <span className='menu-icon'>
                      <KTIcon iconName='book-open' className='fs-2' />
                    </span>
                    <span className='menu-title fw-semibold'>Archive</span>
                  </p>
                </Link> */}
              </div>
            </div>

            <div className='w-100 w-md-75 h-100 overflow-auto'>
              <Outlet />
            </div>
          </div>
        </>
      )}
    </>
  )
}

// export {PolicyCard, PolicyDataContext}

export {CandidateCard}

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('First Name is Required'),
  last_name: Yup.string().required('Last Name is Required'),

  gender: Yup.string().required('Gender is Required'),
  email: Yup.string().email('Invalid E-mail Address').required('E-mail is Required'),
  mobile: Yup.string()
    .test('is-valid-phone-number', 'Invalid phone number', (value: any) => {
      const phoneUtil = PhoneNumberUtil.getInstance()
      try {
        const phoneNumber = phoneUtil.parse(value)
        return phoneUtil.isValidNumber(phoneNumber)
      } catch (error) {
        return false
      }
    })
    .required('Phone No. is required'),
})

export const UpdateCandidate = ({
  showModalChange,
  handleCloseChange,
  candidateData,
  getCandidateData,
}: any) => {
  const {execute} = usePostApi()
  const podId = FindPodIdByCode()
  const {candidate_id} = useParams()
  const intl = useIntl()
  let initialValues = {
    pod_id: candidateData?.pod_id || '',
    first_name: candidateData?.candidate_first_name || '',
    last_name: candidateData?.candidate_last_name || '',
    gender: candidateData?.gender || '', // M,F,T,O
    email: candidateData?.email_address || '',
    mobile: candidateData?.mobile || '',
    alternate_mobile: candidateData?.alt_phone || '',
  }

  const handleSubmit = (values: any) => {
    const updateCandidate = () => {
      const formData = {
        candidate_id: candidate_id,
        pod_id: podId,
        first_name: values.first_name,
        last_name: values.last_name,
        gender: values.gender, // M,F,T,O
        email: values.email,
        mobile: values.mobile,
        alternate_mobile: values.alternate_mobile,
      }
      const updateCandidate = async () => {
        try {
          const url = `/pod/candidate/update`

          const response = await execute(url, 'POST', formData)
          if (response?.status === true) {
            getCandidateData()
            handleCloseChange()
          }
          UseToast(
            response?.status_message,
            {
              position: 'bottom-right',
              autoClose: false,
              theme: 'colored',
            },
            'success'
          )
        } catch (error: any) {
          console.error('Error:', error)

          UseToast(
            error.response?.data?.status_message,
            {
              position: 'bottom-right',
              autoClose: false,
              theme: 'colored',
            },
            'error'
          )
        }
      }
      updateCandidate()
      // setEditMode(false)
    }
    updateCandidate()
  }
  return (
    <Modal show={showModalChange} onHide={handleCloseChange}>
      <Modal.Header>
        <div className='w-100 d-flex justify-content-between align-items-centers'>
          <h3 className='fs-3 fw-semibold mt-3'>Update Candidate</h3>

          <div className='card-toolbar me-3'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-active-light-primary me-n5'
              id='kt_drawer_chat_close'
              onClick={handleCloseChange}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className='pt-0'>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({setFieldValue, values}) => {
            return (
              <Form>
                <div className='modal-body p-0'>
                  <div className='row mb-4 d-flex align-items-center'>
                    <label className='col-lg-4 fw-bold text-muted text-start'>First Name</label>

                    <div className='col-lg-8'>
                      <>
                        <Field
                          type='text'
                          className='form-control form-control-solid'
                          id='floatingInput'
                          name='first_name'
                          placeholder='First Name'
                        ></Field>
                        <ErrorMessage name='first_name' component='div' className='text-danger' />
                      </>
                    </div>
                  </div>
                  <div className='row mb-4 d-flex align-items-center'>
                    <label className='col-lg-4 fw-bold text-muted text-start'>Last Name</label>

                    <div className='col-lg-8'>
                      <>
                        <Field
                          type='text'
                          className='form-control form-control-solid'
                          id='floatingInput'
                          name='last_name'
                          placeholder='Last Name'
                        ></Field>

                        <ErrorMessage name='last_name' component='div' className='text-danger' />
                      </>
                    </div>
                  </div>

                  <div className='row mb-4 d-flex align-items-center'>
                    <label className='col-lg-4 fw-bold text-muted'>Gender</label>
                    <div className='col-lg-8'>
                      <Field as='select' id='floatingInput' className='form-select' name='gender'>
                        <option value=''>Select Gender</option>
                        <option value='M'>Male</option>
                        <option value='F'>Female</option>
                        <option value='T'>Transgender</option>
                        <option value='O'>Others</option>
                      </Field>
                      <ErrorMessage name='gender' component='div' className='text-danger' />
                    </div>
                  </div>
                  <div className='row mb-4 d-flex align-items-center'>
                    <label className='col-lg-4 fw-bold text-muted'>E-mail</label>
                    <div className='col-lg-8'>
                      <Field
                        type='text'
                        name='email'
                        className='form-control'
                        placeholder='E-mail'
                      />
                      <ErrorMessage name='email' component='div' className='text-danger' />
                    </div>
                  </div>
                  <div className='row mb-4 d-flex align-items-center'>
                    <label className='col-lg-4 fw-bold text-muted text-start'>Phone No.</label>

                    <div className='col-lg-8'>
                      <Field
                        type='text'
                        name='mobile'
                        className='form-control form-control-solid'
                        value={values.mobile}
                        placeholder='Contact No.'
                      />

                      <ErrorMessage name='mobile' component='div' className='text-danger' />
                    </div>
                  </div>

                  <div className='row mb-4 d-flex align-items-center'>
                    <label className='col-lg-4 fw-bold text-muted text-start'>
                      Phone No. (Alt)
                    </label>

                    <div className='col-lg-8'>
                      <Field
                        type='text'
                        name='alternate_mobile'
                        className='form-control form-control-solid'
                        placeholder='Contact No(Alt)'
                      />

                      <ErrorMessage
                        name='alternate_mobile'
                        component='div'
                        className='text-danger'
                      />
                    </div>
                  </div>
                  <button
                    type='submit'
                    className='d-none btn btn-light-primary btn-sm'
                    id='save_policy_updates'
                  >
                    Save
                  </button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <button type='button' className='btn btn-light btn-sm' onClick={handleCloseChange}>
          {intl.formatMessage({id: 'BTN.CLOSE'})}
        </button>

        <button
          className='btn btn-light-primary btn-sm'
          onClick={() => {
            const submitBtn = document.getElementById('save_policy_updates')
            if (submitBtn) {
              submitBtn.click()
            }
          }}
          type='submit'
        >
          {intl.formatMessage({id: 'BTN.SAVE'})}
        </button>
      </Modal.Footer>
    </Modal>
  )
}
