import {useMemo, useState, useEffect} from 'react'
import {useTable, usePagination} from 'react-table'
import {useIntl, FormattedMessage} from 'react-intl'
import {Pagination} from 'react-bootstrap'
import {KTIcon} from '../../../_metronic/helpers'
import {useParams, Link} from 'react-router-dom'
import {FindPodIdByCode, CheckPodAccess, FindFirstPodCode} from '../FindPodIdByPodCode'
import usePostApi from '../custom_hooks/usePostApi'
import {defaultProfileImage} from '../core'
import {PageTitle} from '../../../_metronic/layout/core'
import TeamMembersKnowMore from '../know_more_modals/TeamMembersKmMain'
import TeamMembersKM from '../know_more_modals/team_members_KM/TeamMembers'
import 'react-datepicker/dist/react-datepicker.css'
import AddTeamMemberModal from './AddTeamMemberModal'
import DateFormatter from '../DateFormatter'
import './index.css'
import PropTypes from 'prop-types'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import ChangeRole from './ChangeRole'
import {UseToast} from '../useToast'

const DataTable = ({columns, data, isLoading, fetchData, refresh}) => {
  const {execute} = usePostApi()
  const [rolesData, setRolesData] = useState([])
  const getRolesData = async () => {
    try {
      const url = '/master/roles'
      const formData = {}

      const response = await execute(url, 'POST', formData)
      setRolesData(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getRolesData()
  }, [])

  const [addModal, setAddModal] = useState(false)
  const havePodAccess = CheckPodAccess()
  const firstPodCode = FindFirstPodCode()
  const [showKM, setShowKM] = useState(false)
  const intl = useIntl()
  const {id} = useParams()
  const teammembers = [
    {
      title: `(${id})`,
      path: `/${id}/team-members`,
      isSeparator: false,
      isActive: false,
    },
  ]

  const [inputVal, setInputVal] = useState('')
  const [currentStatus, setCurrentStatus] = useState('1')

  const [searchByRole, setSearchByRole] = useState(null)
  const filteredData = useMemo(() => {
    return data
      ? data.filter((each) => {
          const fullName = each.member_name
          const status = each.status.toString()

          const fullNameMatches = fullName?.toLowerCase()?.includes(inputVal?.toLowerCase())

          let statusMatches = false
          if (currentStatus === 'I') {
            statusMatches = status.toLowerCase() === 'i'
          } else if (currentStatus === '1') {
            statusMatches = status.toLowerCase() === '1'
          } else {
            statusMatches = status.toLowerCase() !== 'i' && status.toLowerCase() !== '1'
          }

          const roleIDMatches = searchByRole ? each.role_id === searchByRole : true

          return fullNameMatches && roleIDMatches && statusMatches
        })
      : []
  }, [inputVal, data, searchByRole, currentStatus])

  const tableInstance = useTable({columns, data: filteredData}, usePagination)
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    state: {pageIndex, pageSize},
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    gotoPage,
    setPageSize,
  } = tableInstance

  const pageOptions = [10, 20, 30, 40, 50]

  const handleDifferentViews = () => {
    if (isLoading && refresh === false) {
      return (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      )
    } else if (data && data.length > 0) {
      return (
        <>
          <div className='d-flex flex-wrap flex-stack'>
            <div className='d-flex'>
              <h1 className='fs-3 fw-semibold'>Manage Team Members</h1>
            </div>
            <div className='d-flex'>
              <div className='m-auto d-flex align-items-center'>
                <button className='btn btn-sm btn-light fw-normal' onClick={() => setShowKM(true)}>
                  <i className='ki-duotone ki-information-3 fs-3'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>{' '}
                  Know More
                </button>
              </div>
            </div>
          </div>
          <p className='fs-6 text-gray-600 mb-4'>
            Effortlessly send and manage team invites, whether they're within or outside the
            organization, ensuring a smooth collaboration experience for everyone involved.
          </p>
          <ul className='w-100 nav nav-pills nav-pills-custom list-unstyled d-flex mb-3'>
            {(rolesData || []).map((each) => (
              <li
                className='nav-ite mb-3 me-3'
                key={each.role_id}
                data-bs-toggle='tooltip'
                title={`${each.role_name}`}
              >
                <button
                  className={`card nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-70px overflow-auto p-2 ${
                    searchByRole === each.role_id ? 'active-border bg-light-primary' : ''
                  }`}
                  onClick={() => {
                    if (searchByRole === each.role_id) {
                      setSearchByRole(null)
                    } else {
                      setSearchByRole(each.role_id)
                    }
                  }}
                >
                  <div className=''>
                    <KTIcon
                      iconName={each?.role_icon || 'information-2'}
                      className={`fs-1 ${searchByRole === each.role_id ? 'text-primary' : ''}`}
                    />
                  </div>
                  <span className='fw-semibold text-dark fs-8'>{each.role_name}</span>
                </button>
              </li>
            ))}
          </ul>

          {refresh ? (
            <div className='card h-250px'>
              <div className='m-auto d-flex flex-column align-items-center'>
                <div className='spinner-border spinner-primary mr-15'></div>
              </div>
            </div>
          ) : (
            <>
              <div className='card py-2 px-10 mb-5'>
                <div className='card-header border-0 px-0'>
                  <div className='card-title align-items-start flex-column'>
                    <ul className='nav'>
                      <li className='nav-item'>
                        <span
                          className={`nav-link btn btn-sm btn-color-muted ${
                            currentStatus === 'I' ? 'active btn-active-light-primary' : ''
                          } px-4 me-1`}
                          onClick={() => setCurrentStatus('I')}
                        >
                          Pending Invites
                        </span>
                      </li>

                      <li className='nav-item'>
                        <span
                          className={`nav-link btn btn-sm btn-color-muted ${
                            currentStatus === '1' ? 'active btn-active-light-success' : ''
                          } px-4 me-1`}
                          onClick={() => setCurrentStatus('1')}
                        >
                          Active
                        </span>
                      </li>
                      <li className='nav-item'>
                        <span
                          className={`nav-link btn btn-sm btn-color-muted ${
                            currentStatus !== 'I' && currentStatus !== '1'
                              ? 'active btn-active-light-danger'
                              : ''
                          } px-4 me-1`}
                          onClick={() => setCurrentStatus('0')}
                        >
                          Inactive
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className='card-toolbar'>
                    <div className='d-flex align-items-center position-relative me-4 mb-1 mb-md-0'>
                      <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                      <input
                        type='search'
                        id='kt_filter_search'
                        className='form-control form-control-white form-control-sm w-150px ps-9 fw-normal'
                        placeholder={intl.formatMessage({id: 'ECOMMERCE.COMMON.SEARCH'})}
                        value={inputVal}
                        onChange={(e) => setInputVal(e.target.value)}
                      />
                    </div>

                    <button
                      className='btn btn-sm btn-light-primary fw-normal mb-1 mb-md-0'
                      onClick={() => setAddModal(true)}
                    >
                      <KTIcon iconName='plus' className='fs-3' />
                      New Member
                    </button>
                    <button
                      className='btn btn-sm btn-light-primary fw-normal mb-1 mb-md-0 ms-4'
                      onClick={() => fetchData(true)}
                      data-bs-toggle='tooltip'
                      title='Click to load the latest data.'
                    >
                      <i className='ki-solid ki-arrows-circle fs-2'></i>
                    </button>
                  </div>
                </div>
                <div className='mb-0 mb-xl-8'>
                  <div className='overflow-auto'>
                    <table
                      className='table table-row-dashed table-row-gray-300 align-middle'
                      {...getTableProps()}
                    >
                      <thead>
                        <tr className='fw-semibold text-muted bg-light rounded'>
                          <th className='min-w-150px ps-3'>Team Member</th>
                          <th className='min-w-150px'>Role</th>
                          <th className='min-w-140px text-center'>Status & Expiry</th>
                          <th className='min-w-150px text-center pe-3'>Actions</th>
                        </tr>
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.length > 0 ? (
                          page.map((row) => {
                            prepareRow(row)
                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                  <td className='text-center' {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                  </td>
                                ))}
                              </tr>
                            )
                          })
                        ) : (
                          <tr>
                            <td className='' colSpan={4}>
                              <div className='d-flex justify-content-center'>
                                <p className='text-dark default-cursor d-block fs-6 m-0 my-5 ps-3'>
                                  {inputVal === '' ? (
                                    intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})
                                  ) : (
                                    <FormattedMessage
                                      id='MSG.NO_DATA_MATCH'
                                      values={{
                                        inputVal: <span className='text-primary'>{inputVal}</span>,
                                      }}
                                    />
                                  )}
                                </p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {filteredData.length > 10 && (
                <Pagination>
                  <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                  <Pagination.Prev onClick={previousPage} disabled={!canPreviousPage} />

                  {[...Array(pageCount)].map((_, i) => (
                    <Pagination.Item key={i} active={i === pageIndex} onClick={() => gotoPage(i)}>
                      {i + 1}
                    </Pagination.Item>
                  ))}

                  <Pagination.Next onClick={nextPage} disabled={!canNextPage} />
                  <Pagination.Last
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  />

                  <div className='d-flex align-items-center mx-5'>
                    <span className='me-2'>{intl.formatMessage({id: 'BTN.SHOW'})}</span>
                    <select
                      className='form-select form-select-solid fw-bold w-75px me-2'
                      value={pageSize}
                      onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                      {pageOptions.map((option) => (
                        <option key={option} value={option} className='select-bg'>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </Pagination>
              )}
            </>
          )}
        </>
      )
    } else {
      return (
        <>
          <h3 className='mb-4 fs-3 fw-semibold'>Stop wondering where work stands</h3>

          <p>
            Welcome to the Team Members page! This section is designed to help you manage your team
            effortlessly, whether they're inside or outside the organization. Here, you can send
            invites to new members, track their status, and ensure seamless collaboration.
          </p>

          <div className='text-center mb-4'>
            <TeamMembersKM />

            <button className='btn btn-light-primary fw-normal' onClick={() => setAddModal(true)}>
              <KTIcon iconName='plus' className='fs-3' />
              New Member
            </button>
          </div>
        </>
      )
    }
  }

  return havePodAccess ? (
    <>
      <TeamMembersKnowMore showModal={showKM} setShowModal={setShowKM} />
      <AddTeamMemberModal
        showModal={addModal}
        setShowModal={setAddModal}
        fetchAllTeamMembers={fetchData}
      />

      <PageTitle breadcrumbs={teammembers}>Team Members</PageTitle>
      {handleDifferentViews()}
    </>
  ) : (
    <div className='card min-h-250px d-flex flex-column justify-content-center align-items-center bg-image'>
      <h1 className='fw-semibold fs-2hx text-gray-900 mb-4'>Oops!</h1>
      <div className='fs-6 text-gray-500 mb-7'>You don't have access to this POD.</div>
      <div className='mb-0'>
        <Link to={`/${firstPodCode}/dashboard`} className='btn btn-sm btn-light-primary'>
          Return Home
        </Link>
      </div>
    </div>
  )
}

DataTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
      Cell: PropTypes.func.isRequired,
      disableSortBy: PropTypes.bool,
    })
  ).isRequired,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchData: PropTypes.func.isRequired,
}

const TeamMembersMain = () => {
  const podId = FindPodIdByCode()
  const {execute, loading} = usePostApi()
  const [teamMembersData, setTeamMembersData] = useState([])
  const [refresh, setRefresh] = useState(false)

  const getTeamMembers = async (show) => {
    if (show) {
      setRefresh(true)
    }
    try {
      const url = '/pod/members/list/new'
      const formData = {
        pod_id: podId,
      }

      const response = await execute(url, 'POST', formData)
      setTeamMembersData(response.data)
      setRefresh(false)
      if (show) {
        return UseToast(
          response.status_message,
          {
            position: 'bottom-right',
            theme: 'colored',
            autoClose: true,
          },
          'success'
        )
      }
    } catch (error) {
      console.error('Error:', error)
      setRefresh(false)
      if (show) {
        return UseToast(
          error.response?.data?.status_message,
          {
            position: 'bottom-right',
            theme: 'colored',
            autoClose: true,
          },
          'error'
        )
      }
    }
  }

  useEffect(() => {
    getTeamMembers()
  }, [])

  const getStatus = (status) => {
    switch (status.toString()) {
      case 'I':
        return 'Invited'
      case '1':
        return 'Active'
      case 'E':
        return 'Expired'
      case 'R':
        return 'Rejected'
      case '0':
        return 'Inactive'
      default:
        return 'Invited'
    }
  }

  const getStatusColor = (status) => {
    switch (status.toString()) {
      case 'I':
        return 'info'
      case '1':
        return 'success'
      case 'E':
        return 'danger'
      case 'R':
        return 'danger'
      case '0':
        return 'danger'
      default:
        return 'info'
    }
  }

  const disableTeamMember = async (memberId) => {
    try {
      const url = '/pod/members/disable'
      const formData1 = {
        pod_id: podId,
        member_id: memberId,
      }

      const response = await execute(url, 'POST', formData1)
      getTeamMembers()
      UseToast(
        response.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: true,
        },
        'success'
      )
    } catch (error) {
      console.error('Error:', error)
      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          theme: 'colored',
          autoClose: true,
        },
        'error'
      )
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({row}) => {
          return (
            <div className='d-flex align-items-center ps-3'>
              <div className='symbol symbol-50px me-5'>
                <img src={row.original.profile_pic || defaultProfileImage} alt='' />
              </div>
              <div className='d-flex justify-content-start flex-column text-start'>
                <p className='text-dark fw-semibold text-hover-primary mb-1 fs-6 default-cursor'>
                  {`${row.original.member_name}`}
                  {/* {row.original.member_first_name} {row.original.member_last_name} */}
                </p>
                <span className='text-muted text-muted d-block fs-7 default-cursor'>
                  {row.original?.email}
                </span>
              </div>
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'DateOfBirth',
        accessor: 'dateOfBirth',
        Cell: ({row}) => {
          return (
            <p className='text-dark text-hover-primary default-cursor d-block fs-6 text-start'>
              {row.original.role_name}
            </p>
          )
        },
      },
      {
        Header: 'Relation',
        accessor: 'relation',
        Cell: ({row}) => {
          return (
            <div className=''>
              <p
                className={`badge badge-light-${getStatusColor(
                  row.original.status
                )} fs-7 default-cursor fw-normal m-0`}
              >
                {getStatus(row.original.status)}
              </p>
              <p className='m-0'>
                {row.original.expiry_ts ? DateFormatter(row.original.expiry_ts) : ''}
                {row.original.expiry_ts && ', '}

                {row.original.expiry_ts &&
                  row.original.expiry_ts.length >= 16 &&
                  row.original.expiry_ts.slice(11, 16)}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({row}) => {
          const [data, updateData] = useState({
            refreshData: () => {},
            ClosePopover: () => {},
            memberId: '',
            role_id: '',
          })
          const [popover, setPopover] = useState(null)
          const ClosePopover = () => {
            setPopover(null)
          }

          const openPopover = (taskId) => {
            // const {id: teamMemberId, refreshData, ClosePopover} = id

            return (
              <Popover
                id={`delete-popover-${taskId}`}
                flip
                placement='auto'
                className='min-w-300px'
              >
                <Popover.Header as='div'>
                  <div className='card-toolbar me-3 d-flex justify-content-between align-items-center'>
                    <h3 className='fs-4 fw-semibold'>Modify Team Member Role</h3>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-active-light me-n5'
                      onClick={ClosePopover}
                    >
                      <KTIcon iconName='cross' className='fs-1' />
                    </button>
                  </div>
                </Popover.Header>
                <Popover.Body>
                  <ChangeRole data={data} />
                </Popover.Body>
              </Popover>
            )
          }

          const [disableMemberPopover, setDisableMemberPopover] = useState(null)
          const closeDisableMemberPopover = () => {
            setDisableMemberPopover(null)
          }

          const openDisableMemberPopover = (taskId) => {
            return (
              <Popover
                id={`delete-popover-${taskId}`}
                flip
                placement='auto'
                className='min-w-300px'
              >
                <Popover.Header as='div'>
                  <div className='card-toolbar me-3 d-flex justify-content-between align-items-center'>
                    <h3 className='fs-4 fw-semibold'>Disable Team Member</h3>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-active-light me-n5'
                      onClick={closeDisableMemberPopover}
                    >
                      <KTIcon iconName='cross' className='fs-1' />
                    </button>
                  </div>
                </Popover.Header>
                <Popover.Body>
                  <p>
                    Are you sure you want to disable{' '}
                    <span className='text-primary'>{row.original.member_name}</span>?
                  </p>
                  <div className='w-100 d-flex justify-content-end'>
                    <button
                      className='btn btn-sm btn-light me-2'
                      onClick={(e) => {
                        e.stopPropagation()
                        closeDisableMemberPopover(null)
                      }}
                    >
                      No
                    </button>
                    <button
                      className='btn btn-sm btn-light-danger ms-2'
                      onClick={(e) => {
                        e.stopPropagation()
                        disableTeamMember(taskId)
                        closeDisableMemberPopover(null)
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </Popover.Body>
              </Popover>
            )
          }

          return (
            <div className='text-center'>
              <OverlayTrigger
                trigger='click'
                placement='auto'
                overlay={openPopover(row.original.member_id)}
                show={popover === 'edit_jobTitle'}
              >
                <button
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  onClick={(e) => {
                    e.stopPropagation()
                    setPopover('edit_jobTitle')
                    updateData({
                      refreshData: getTeamMembers,
                      ClosePopover,
                      memberId: row.original.member_id,
                      role_id: row.original.role_id,
                    })
                  }}
                >
                  <KTIcon iconName='pencil' className='fs-3' />
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                trigger='click'
                placement='auto'
                overlay={openDisableMemberPopover(row.original.member_id)}
                show={disableMemberPopover === 'disable_member'}
              >
                <button
                  className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                  onClick={(e) => {
                    e.stopPropagation()
                    setDisableMemberPopover('disable_member')
                  }}
                >
                  <KTIcon iconName='trash' className='fs-3' />
                </button>
              </OverlayTrigger>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <DataTable
      columns={columns}
      data={teamMembersData || []}
      isLoading={loading}
      fetchData={getTeamMembers}
      refresh={refresh}
    />
  )
}

export default TeamMembersMain
