import TrackReqImg from './time-tracking.png'
import SearchIcon from './search.png'

function NoDataFound(props: any) {
  const {name, smallname, icon, buttonLabel} = props.data
  return (
    <>
      <h3 className='fw-semibold fs-3'>Managing {name}</h3>
      <p className='fw-normal'>
        Welcome to the {name} page! Here, you have the ability to create new {smallname}, inactive
        existing ones, and modify {smallname} details as needed. We've designed this section to
        guide you through the process, ensuring that your {smallname} are effectively managed and up
        to date.
      </p>
      <div className='row mt-6'>
        <div className='col-lg-4 mb-6'>
          <div className='shadow-sm p-3 rounded h-100 d-flex flex-column justify-content-center align-items-center'>
            <img src={icon?.NoDataIcon} className='track-req-icon mb-5' alt='Add Friend' />
            <p className='text-center'>
              Click on the {buttonLabel} button to Creat the {name}.
            </p>
          </div>
        </div>
        <div className='col-lg-4 mb-6'>
          <div className='shadow-sm p-3 rounded h-100 d-flex flex-column justify-content-center align-items-center'>
            <img src={TrackReqImg} alt='Track Request' className='track-req-icon mb-5' />
            <p className='text-center'>
              Keep track of the status of your created {name}. See what is active, and inactives if
              needed.
            </p>
          </div>
        </div>
        <div className='col-lg-4 mb-6'>
          <div className='shadow-sm p-3 rounded h-100 d-flex flex-column justify-content-center align-items-center'>
            <img src={SearchIcon} className='track-req-icon mb-5' alt='Add Friend' />
            <p className='text-center'>
              Use the search option to narrow down your search. You can search by name to find the
              {name} you need quickly.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default NoDataFound
