import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import usePostApi from '../../custom_hooks/usePostApi'
import {UseToast} from '../../useToast'
import {FindPodIdByCode} from '../../FindPodIdByPodCode'

const UpdateStatus = ({data}: any) => {
  const podId = FindPodIdByCode()
  const {memberId, refreshData, ClosePopover, status} = data
  const {execute, loading: rolesLoading, postLoading} = usePostApi()
  const [selectRole, setSeletRole] = useState(status)
  const [showErr, setShowErr] = useState(false)

  const [allStatus, setStatus] = useState([
    {value: 'N', label: 'New', icon: 'star'},
    {value: 'O', label: 'Open', icon: 'book-open'},
    {value: 'I', label: 'In Progress', icon: 'loading'},
    {value: 'S', label: 'Submitted', icon: 'check-square'},
    {value: 'P', label: 'Processing', icon: 'setting-2'},
    {value: 'C', label: 'Completed', icon: 'verify'},
    {value: 'X', label: 'Cancelled', icon: 'cross-square'},
    {value: 'E', label: 'Expired', icon: 'calendar-remove'},
    {value: 'R', label: 'Revoked', icon: 'arrow-circle-left'},
    // {value: 'A', label: 'Active', icon: 'check-circle'},
  ])

  useEffect(() => {
    setShowErr(false)
  }, [selectRole])

  const updateMember = async () => {
    if (selectRole === '') {
      setShowErr(true)
      return
    } else {
      setShowErr(false)
    }

    try {
      const url = '/pod/onboarding/status/update'

      const formData = {
        onbr_id: memberId,
        pod_id: podId,
        status: selectRole,
      }

      const response = await execute(url, 'POST', formData, 'post')
      ClosePopover()
      refreshData()

      UseToast(
        response?.status_message,
        {
          position: 'bottom-right',
          autoClose: false,
          theme: 'colored',
        },
        'success'
      )
    } catch (error: any) {
      console.error('Error:', error)

      UseToast(
        error.response?.data?.status_message,
        {
          position: 'bottom-right',
          autoClose: false,
          theme: 'colored',
        },
        'error'
      )
    }
  }

  return (
    <>
      <ul
        className='w-100 nav nav-pills nav-pills-custom list-unstyled d-flex flex-row justify-content-between'
        id='roles'
      >
        {allStatus.map((each: any) => (
          <li
            style={{width: '29%'}}
            className='nav-ite mb-3'
            key={each.value}
            data-bs-toggle='tooltip'
            title={`${each.label}`}
          >
            <div
              className={`nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-60px overflow-auto p-2 ${
                selectRole === each.value ? 'active-border bg-light-primary' : ''
              }`}
              onClick={() => setSeletRole(each.value)}
            >
              <div className='m-auto'>
                <div className=''>
                  <KTIcon
                    iconName={each?.icon || 'information-2'}
                    className={`fs-1 ${selectRole === each.value ? 'text-primary' : ''}`}
                  />
                </div>
                <span className='fw-semibold text-dark fs-8'>{each.label}</span>
              </div>
            </div>
          </li>
        ))}
        {showErr && <div className='text-danger'>Role is required</div>}
      </ul>
      <div className='d-flex justify-content-end'>
        <button className='btn btn-light-primary btn-sm' type='button' onClick={updateMember}>
          {postLoading ? (
            <span className='indicator-progress' style={{display: 'block'}}>
              wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            'Update'
          )}
        </button>
      </div>
    </>
  )
}

export default UpdateStatus
